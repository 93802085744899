import { FMEA_RELATION } from "../Common/EndPoints";
import { apiAlarms } from "../features/api/apiAlarms";

export const generic_apis = apiAlarms.injectEndpoints({
  endpoints: (builder) => ({
    getFMEARelation: builder.query({
      query: (request) => {
        const DEFAULT_PARAMS = { with_mode: true, with_cause: true, with_effect: true };
        const params = { ...request?.params, ...DEFAULT_PARAMS };
        return {
          url: FMEA_RELATION,
          params,
        };
      },
    }),
  }),
});

export const { useGetFMEARelationQuery } = generic_apis;
