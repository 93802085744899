import { memo, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useGetCustomersAccessList } from "../../../SearchFilters/useGetCustomersAccessList";
import {
  useGetDeviceTypesQuery,
  useGetDevicesNameQuery,
  useGetSubGearQuery,
} from "../../../SearchFilters/searchFiltersSlice";
import { useRulesFormValues } from "./useRulesFormValues";
import "../../AlarmRules.scss";
import { getAlarmCategoryList } from "../../../../Utils/Utils";
import { Transition } from "../../../../Modal/ModalConstants";
import { useGetRulesKeysQuery } from "../../alarmRulesSlice";

const SEVERITY_OPTIONS = ["WARNING", "CRITICAL"];
const HISTORY_WINDOW_OPTIONS = [0, 2, 5, 10, 15];
const EVENT_TYPE_OPTIONS = getAlarmCategoryList();
const UNIT_DROPDOWN_OPTIONS = ["mS", "s", "mA", "A", "mv", "V"];
const RULE_CONDITION_OPTIONS = ["and", "or"];
const PLACEHOLDERS = { key: "Info Title", value: "Info Description", name: "key" };

const ConfirmationPopup = ({ submitRule, closeConfirmationPopup, actionButtonText }) => {
  const title =
    actionButtonText === "Edit Rule" ? "Do you want to Edit this Rule?" : "Do you want to Create this Rule?";

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={true}
        onClose={closeConfirmationPopup}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="responsive-dialog-title" className="flex items-center">
          {actionButtonText}
          <CloseIcon className="pointer" sx={{ ml: "auto" }} onClick={closeConfirmationPopup} />
        </DialogTitle>

        <DialogContent dividers>{title}</DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={closeConfirmationPopup}>
            Cancel
          </Button>
          <Button variant="contained" autoFocus onClick={submitRule}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function getDynamicKeys(formValues) {
  const params = {};
  if (formValues?.device_type_id) params.device_type_id = formValues.device_type_id.id;
  if (formValues?.customer_id) params.customer_id = formValues.customer_id.id;
  return { params };
}

const DynamicRuleCondition = ({ type, fieldKey, initialObject, required, title }) => {
  const { formValues, handleFilterChange, formErrors } = useRulesFormValues();
  const error = useMemo(() => formErrors[fieldKey], [fieldKey, formErrors]);
  const fields = useMemo(() => formValues[fieldKey] ?? [], [formValues, fieldKey]);
  const renderFields = useMemo(() => Object.keys(initialObject).map((key) => key), [initialObject]);

  function handleRemove(index) {
    const updatedState = [...fields];
    updatedState.splice(index, 1);
    handleFilterChange(fieldKey, updatedState);
  }
  function handleFieldValueChange(index, fieldType, event) {
    const updatedState = [...formValues[fieldKey]];
    const value = fieldType === "name" ? event : event.target.value;
    updatedState[index] = { ...updatedState[index], [fieldType]: value };
    handleFilterChange(fieldKey, updatedState);
  }
  function handleAddInfo() {
    handleFilterChange(fieldKey, [...fields, initialObject]);
  }
  const FLOAT_VALUES = /^[+-]?\d+(\.\d+)?$/;
  function isNumber(value) {
    if (value?.toString().match(FLOAT_VALUES) && !isNaN(value)) {
      return true;
    }
    return false;
  }
  function showError(error, value, type) {
    if (!error) return false;
    if (!value) return true;
    if (type === "min" || type === "max") {
      return !isNumber(value);
    }
    return false;
  }
  function errorMessage(field) {
    if (!renderFields.includes("min") && !renderFields.includes("max")) return false;
    const _min = field.min;
    const _max = field.max;
    if (_min && !isNumber(_min)) return "Min is not a Valid Number";
    if (_max && !isNumber(_max)) return "Max is not a Valid Number";

    return false;
  }

  const NameField = (props) => {
    const { field, index } = props;
    const [value, setValue] = useState(fields[index][field] || null);
    const { data = [], isFetching: fetchingNameOptions } = useGetRulesKeysQuery(getDynamicKeys(formValues), {
      skip: formValues.device_type_id ? false : true,
    });

    function getValue() {
      return data?.includes(value) ? value : null;
    }

    useEffect(() => {
      if (!fields[index][field]) return;
      if (data.length === 0) return;
      let _value = fields[index][field];
      if (!data.includes(value)) {
        handleFieldValueChange(index, field, null);
        _value = "";
      }
      setValue(_value);
    }, [data]);

    useEffect(() => {
      setValue(fields[index][field]);
    }, [fields[index][field]]);

    return (
      <FormControl className="flex-1">
        <Autocomplete
          fullWidth
          loading={fetchingNameOptions}
          value={getValue()}
          options={data ?? []}
          onChange={(_, value) => handleFieldValueChange(index, field, value)}
          renderInput={(params) => (
            <TextField {...params} error={showError(error, fields[index][field], field)} placeholder="key" />
          )}
          size="small"
        />
      </FormControl>
    );
  };

  return (
    <div>
      <div className="rule-dynamic-fields--wrapper">
        {fields.map((field, index) => (
          <div className="w-full" key={"rule-condition-field-" + index}>
            <div className="rule-field rule-field--dynamic">
              {renderFields.map((field) => {
                if (field === "unit")
                  return (
                    <FormControl className="flex-1" key={"rule-field-select-" + field + index}>
                      <Select
                        variant="outlined"
                        value={fields[index][field] ?? ""}
                        onChange={(event) => handleFieldValueChange(index, field, event)}
                        size="small"
                        displayEmpty
                        error={showError(error, fields[index][field], field)}
                      >
                        <MenuItem disabled value="">
                          <em>{PLACEHOLDERS[field] ?? field}</em>
                        </MenuItem>
                        {UNIT_DROPDOWN_OPTIONS.map((option, _index) => (
                          <MenuItem key={`unit-dropdown-options-${index}-${_index}`} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                if (field === "name")
                  return <NameField key={"rule-field-name-" + field + index} field={field} index={index} />;
                return (
                  <TextField
                    className="flex-1"
                    key={"rule-field-" + field + index}
                    value={fields[index][field] ?? ""}
                    placeholder={PLACEHOLDERS[field] ?? field}
                    size="small"
                    variant="outlined"
                    onChange={(event) => handleFieldValueChange(index, field, event)}
                    error={showError(error, fields[index][field], field)}
                  />
                );
              })}
              <span onClick={() => handleRemove(index)} className="cursor-pointer remove-dynamic-field">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g fill="none">
                    <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                    <path
                      fill="currentColor"
                      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2Zm0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16ZM9.879 8.464L12 10.586l2.121-2.122a1 1 0 1 1 1.415 1.415l-2.122 2.12l2.122 2.122a1 1 0 0 1-1.415 1.415L12 13.414l-2.121 2.122a1 1 0 0 1-1.415-1.415L10.586 12L8.465 9.879a1 1 0 0 1 1.414-1.415Z"
                    />
                  </g>
                </svg>
              </span>
            </div>
            {Boolean(errorMessage(fields[index])) && <FormHelperText error>{errorMessage(field)}</FormHelperText>}
          </div>
        ))}
      </div>
      <span className="mt-2 mb-2">
        {error && fields.length === 0 && title !== "Clear" && (
          <p className="rule-field-error-message">*At least one {type} field must be added</p>
        )}
        <Button variant="contained" onClick={handleAddInfo} fullWidth className="rule-field-add-type">
          Add {type}
          {required && "*"}
        </Button>
      </span>
    </div>
  );
};

const INITIAL_RULE_CONDITION_OBJECT = { name: "", min: null, max: null, unit: "" };
const INITIAL_RULE_INFO_OBJECT = { key: "", value: "" };
const RuleInfoComponent = ({ type }) => {
  const { formValues, handleFilterChange, formErrors } = useRulesFormValues();
  const key = `${type}_condition`;
  const required = type === "create" ? true : false;
  const title = type.charAt(0).toUpperCase() + type.slice(1);
  return (
    <>
      <section className="rule--section">
        <h4>
          {title} Condition{required && "*"}
        </h4>
        <DynamicRuleCondition
          required={required}
          type="condition"
          fieldKey={`${type}_keys`}
          initialObject={INITIAL_RULE_CONDITION_OBJECT}
          title={title}
        />
        {formValues[`${type}_keys`].length > 1 && (
          <div className="flex items-center gap-3 rule--section-condition-wrapper">
            <p>{title} Condition*</p>
            <Select
              className="flex-1"
              size="small"
              value={formValues[key]}
              error={formErrors[key]}
              onChange={(event) => handleFilterChange(key, event.target.value)}
            >
              {RULE_CONDITION_OPTIONS.map((option, index) => (
                <MenuItem key={`rule-condition-menu-item-${index}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        <h4>
          {title} Information{required && "*"}
        </h4>
        <DynamicRuleCondition
          required={required}
          type="info"
          fieldKey={`${type}_meta`}
          initialObject={INITIAL_RULE_INFO_OBJECT}
          title={title}
        />
      </section>
    </>
  );
};

const INTEGER_MATCH = /^-?\d+$/;
function isValidInteger(value) {
  const match = value.match(INTEGER_MATCH);
  return Boolean(match);
}

const CreateOrEditRule = () => {
  const { data: customer } = useGetCustomersAccessList();
  const { data: gearTypesList = [] } = useGetDeviceTypesQuery();

  const {
    formValues,
    handleFilterChange,
    handleSubmit,
    formErrors,
    actionButtonText,
    showConfirmationPopup,
    closeConfirmationPopup,
    getSubGearParams,
    submitRule,
  } = useRulesFormValues();

  const { data: subGearList = [], isFetching: fetchingSubGear } = useGetSubGearQuery(getSubGearParams, {
    skip: formValues.device_type_id ? false : true,
  });

  const { data: devicesList = [], isFetching: fetchingDevicesList } = useGetDevicesNameQuery(getSubGearParams, {
    skip: formValues.customer_id ? false : true,
  });

  return (
    <>
      <section className="rule--section rule-static-fields--wrapper">
        <div className="flex items-center gap-3">
          <p>Alarm Name</p>
          <TextField
            error={formErrors.alarm_name}
            fullWidth
            size="small"
            variant="outlined"
            value={formValues.alarm_name}
            onChange={(event) => handleFilterChange("alarm_name", event.target.value)}
          />
        </div>
        <div className="flex items-center gap-3">
          <p>Station</p>
          <Autocomplete
            fullWidth
            isOptionEqualToValue={(option, value) => option.customerId === value.customerId}
            value={formValues.customer_id}
            options={customer}
            getOptionLabel={(customer) => customer.customerName}
            onChange={(_, value) => handleFilterChange("customer_id", value)}
            renderInput={(params) => <TextField {...params} error={formErrors.customer_id} />}
            size="small"
          />
        </div>
        <div className="flex items-center gap-3">
          <p>Gear Type</p>
          <Autocomplete
            fullWidth
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={formValues.device_type_id}
            options={gearTypesList}
            getOptionLabel={(gearType) => gearType.value}
            onChange={(_, value) => handleFilterChange("device_type_id", value)}
            renderInput={(params) => <TextField {...params} error={formErrors.device_type_id} />}
            size="small"
          />
        </div>
        {formValues.device_type_id && (
          <div className="flex items-center gap-3">
            <span>
              Sub Gear
              <FormHelperText>(Optional)</FormHelperText>
            </span>
            <Autocomplete
              fullWidth
              loading={fetchingSubGear}
              value={formValues.mqtt_subgear}
              options={subGearList ?? []}
              onChange={(_, value) => handleFilterChange("mqtt_subgear", value)}
              renderInput={(params) => <TextField {...params} error={formErrors.mqtt_subgear} />}
              size="small"
            />
          </div>
        )}
        <div className="flex gap-3">
          <span>
            Devices
            <FormHelperText>(Optional)</FormHelperText>
          </span>
          <div className="w-full">
            <Autocomplete
              disableCloseOnSelect
              fullWidth
              multiple
              loading={fetchingDevicesList}
              value={formValues.device_ids}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={devicesList}
              getOptionLabel={(device) => device.value}
              onChange={(_, value) => handleFilterChange("device_ids", value)}
              renderInput={(params) => <TextField {...params} error={formErrors.device_ids} />}
              size="small"
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
                    {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
                  </div>
                );
              }}
            />
            <FormHelperText>
              Rule will be applied to selected devices, if not selected rule will be applied to all devices
            </FormHelperText>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <p>Severity</p>
          <Select
            fullWidth
            value={formValues.severity}
            onChange={(event) => handleFilterChange("severity", event.target.value)}
            size="small"
            error={formErrors.severity}
          >
            {SEVERITY_OPTIONS.map((option, index) => (
              <MenuItem key={`severity-menu-item-${index}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="flex items-center gap-3">
          <p>Category</p>
          <Select
            error={formErrors.event_type}
            fullWidth
            size="small"
            value={formValues.event_type}
            onChange={(event) => handleFilterChange("event_type", event.target.value)}
          >
            {EVENT_TYPE_OPTIONS.map((option, index) => (
              <MenuItem key={`history-window-menu-item-${index}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="flex items-center gap-3">
          <p>History Window</p>
          <TextField
            type="number"
            error={formErrors.history_window}
            fullWidth
            size="small"
            variant="outlined"
            value={formValues.history_window}
            onChange={(event) => {
              const is_valid_integer = isValidInteger(event.target.value);
              if (!is_valid_integer && event.target.value !== "") return;
              handleFilterChange("history_window", event.target.value === "" ? "" : Number(event.target.value));
            }}
          />
        </div>
        <div className="flex items-center gap-3">
          <p>Enable</p>
          <input
            type="checkbox"
            checked={formValues.enable}
            onChange={(event) => handleFilterChange("enable", event.target.checked)}
          />
        </div>
      </section>
      <RuleInfoComponent type="create" />
      <RuleInfoComponent type="clear" />
      <div className="rule-action-button--wrapper">
        <Button variant="contained" onClick={handleSubmit}>
          {actionButtonText}
        </Button>
      </div>

      {showConfirmationPopup && (
        <ConfirmationPopup
          submitRule={submitRule}
          closeConfirmationPopup={closeConfirmationPopup}
          actionButtonText={actionButtonText}
        />
      )}
    </>
  );
};
export default memo(CreateOrEditRule);
