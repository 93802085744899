import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import './SfrInfoPopup.scss'
import { failurStateToLabelMap } from "../Constants";
import { convertToSentenceCase } from "../Utils";


const ObjectDataViewer = ({ object }) => {
    return (
        <ul>
            {Object.entries(object).map(([key, value], index) => {
                // if (key === 'rectificationBy') {
                // console.log('rectificationBy', key, value)
                // }

                if (key !== 'affectedAssets' && key !== 'detainedTrains' && key !== 'id') {
                    return (
                        <li key={key + index} className="object-data-viewer-list-item flex spaceBetween">
                            <span className="object-data-viewer-list-item-key">{failurStateToLabelMap[key]?.label}</span>
                            <div className="object-data-viewer-list-item-separator">:</div>
                            <span className="object-data-viewer-list-item-value">{failurStateToLabelMap[key]?.showInSentenceCase ? convertToSentenceCase(value) : value}</span>
                        </li>
                    );
                }

                if (key === 'affectedAssets') {
                    let assetNames = []
                    for (let i = 0; i < value.length; i += 2) {
                        let asset1 = value[1]
                        let asset2 = value[i + 1]

                        console.log("mergeAsset", asset1, asset2)
                        if (asset1 || asset2) {
                            let mergeAsset = asset1.asset.value + ' (' + asset1.assetType.value + '),' + (asset2 ? (' ' + asset1.asset.value + ' (' + asset1.assetType.value + ')') : '')

                            assetNames.push(mergeAsset)
                        }

                    }
                    return (
                        <li key={key + index} className="object-data-viewer-list-item flex spaceBetween">
                            <span className="object-data-viewer-list-item-key">{failurStateToLabelMap[key]?.label}</span>
                            <div className="object-data-viewer-list-item-separator">:</div>
                            <span className="object-data-viewer-list-item-value">{
                                assetNames.map((affectedAsset, i) => {
                                    return <div>{affectedAsset}</div>
                                })

                            }</span>
                        </li>
                    )
                }

                if (key === 'detainedTrains') {
                    return (
                        <li key={key + index} className="object-data-viewer-list-item flex spaceBetween detainedTrain">
                            <span className="object-data-viewer-list-item-key">{failurStateToLabelMap[key]?.label}</span>
                            <div className="object-data-viewer-list-item-separator">:</div>
                            <span className="object-data-viewer-list-item-value">{
                                value.map((detainedTrain, i) => {
                                    // <div key={detainedTrain + i} className="detainedTrainRow"></div>
                                    return <div>{`${detainedTrain.trainNumber.value} (${detainedTrain.detention} minutes)`}</div>
                                })

                            }</span>
                        </li>
                    )
                }


            })}
        </ul>
    );
};


function SfrInfoPopup({ open, onClose, info }) {
    console.log('info', info)
    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="sfr-information-popup"
            aria-labelledby="responsive-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle id="responsive-dialog-title" className="popHeader">
                SFR details
                <CloseIcon
                    className="sfr-info-popup-close-icon pointer"
                    onClick={() => {
                        onClose();
                    }}
                />
            </DialogTitle>
            <DialogContent className="sfr-popup-content" dividers>
                <div className="heading">SFR INFORMATION</div>
                <div className="objDataContainer">
                    <ObjectDataViewer object={info} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    autoFocus
                    onClick={() => {
                        onClose();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SfrInfoPopup;
