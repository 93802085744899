/**
 * returns the params required to be sent to useGetDevicesNameQuery
 * which takes selected customer ids array and local filters state as params
 */
export function getDeviceNameSearchParams({ filterState, customerIdList }) {
  let params = { customer_ids: customerIdList, relations: ["CUSTOMER"] };
  const _customer_ids = filterState.customer_ids;
  if (_customer_ids && _customer_ids?.length > 0) {
    params.customer_ids = _customer_ids?.map((customer) => customer.id);
  }
  const _device_type_ids = filterState.device_type_ids;
  if (_device_type_ids && _device_type_ids.length > 0) {
    params.device_type_ids = _device_type_ids.map((type) => type.id);
    params.relations.push("DEVICE_TYPE");
  }
  return { params };
}

/**
 * returns the params required to be sent to useGetDeviceTypesQuery
 * which takes selected customer ids array and local filters state as params
 */
export function getDeviceTypeSearchParams({ filterState, customerIdList }) {
  let params = { customer_ids: customerIdList, relations: "CUSTOMER" };
  if (filterState?.customer_ids?.length > 0) {
    params.customer_ids = filterState.customer_ids.map((customer) => customer.id);
  }
  return { params };
}
