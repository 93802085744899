import React from 'react'
import { Slide, Zoom } from '@mui/material'
import { theme } from '../Theme/BaseTheme';


export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide 
    direction="up" 
    ref={ref} 
    {...props} />;
    // return  <Zoom in={true} ref={ref} 
    // {...props} />

  });