import { createSlice } from "@reduxjs/toolkit";
const filterList = {
    filterCustomerIds: [],
    device_type_ids: [],
    alarm_types: [],
    categories: [],
    device_ids: [],
    severity: null,
    is_active: null,
    from_ts: null,
    to_ts: null,
}

const initialState = { currentReportFilters: filterList, globelReportsFilter: filterList, tempReportFilter: [] }
const reportSlice = createSlice({
    name: "reporting",
    initialState,
    reducers: {
        updateCurrentReportFilters: (state, { payload }) => {
            const updatedPayload = { ...state.currentReportFilters, ...payload };
            state.currentReportFilters = updatedPayload;
        },
        updateGlobelReportFilters: (state, { payload }) => {
            const updatedPayload = { ...state.globelReportsFilter, ...payload };
            state.globelReportsFilter = updatedPayload;
        },

        implementTempReportFilters: (state, { payload }) => {
            const updatedPayload = { ...state.tempReportFilter?.[state.tempReportFilter.length - 1], ...payload };
            state.tempReportFilter = [...state.tempReportFilter, updatedPayload];
        },
        updateTempFilterOnNavBack: (state) => {
            if (state.tempReportFilter.length > 0) {

                state.tempReportFilter = state.tempReportFilter.slice(0, -1)
            } else {
                state.tempReportFilter = []
            }
        },
        replaceTempFilters: (state, { payload }) => {
            state.tempReportFilter = payload
        },
        claerAllReportingFilters: (state) => {
            state.currentReportFilters = filterList
            state.tempReportFilter = []
            state.globelReportsFilter = filterList
        }
    }
})
export const {
    updateGlobelReportFilters, implementTempReportFilters, updateTempFilterOnNavBack, updateCurrentReportFilters, replaceTempFilters, claerAllReportingFilters
} = reportSlice.actions;
export const selectGlobelReportFilter = state => (state.reporting.globelReportsFilter)
export const selectTempReportFilter = state => (state.reporting.tempReportFilter)
export const selectCurrentReportFilters = state => (state.reporting.currentReportFilters)
export default reportSlice.reducer;
