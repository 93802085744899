import { useDispatch, useSelector } from "react-redux";
import { memo, useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import {
  resetFormValues,
  rulesDelete,
  ruleShowDeletePopupSelector,
  showCopyRulesPopup,
  triggerCopyRulesPopup,
  updateRulesActionType,
  updateRulesFormValues,
  useDeleteRuleMutation,
  useGetRulesQuery,
} from "../../alarmRulesSlice";
import { Transition } from "../../../../Modal/ModalConstants";
import DataTable from "../../../../Components/DataGrid/DataGrid";
import moment from "moment";
import { CopyAlarmRulesPopup } from "./CopyAlarmRulesPopup";
import { trimAssetName } from "../../../../Common/EndPoints";
import { selectedCustomersIdsSelector } from "../../../authentication/auth-slice";

const FORM_FIELDS_KEYS = {
  alarm_name: true,
  clear_condition: true,
  clear_keys: true,
  clear_meta: true,
  create_condition: true,
  create_meta: true,
  create_keys: true,
  customer_id: true,
  device_type_id: true,
  enable: true,
  event_type: true,
  history_window: true,
  severity: true,
  mqtt_subgear: true,
  id: true,
};

export const RULES_TABLE_INFO = {
  id: `rules-table`,
  label: `Rules`,
  requireAuthSign: false,
  timestamp: { startTs: null, endTs: null },
};
function updateFormValues(values) {
  const formValuesObject = { ...values };
  if (values.create_meta) {
    formValuesObject.create_meta = Object.entries(values.create_meta).map(([key, value]) => ({
      key: key,
      value: value,
    }));
  }
  if (values.clear_meta) {
    formValuesObject.clear_meta = Object.entries(values.clear_meta).map(([key, value]) => ({ key: key, value: value }));
  }
  if (values.customer) {
    formValuesObject.customer_id = { customerId: values.customer.customerid, customerName: values.customer.stncode };
  }
  if (values.device_type) {
    formValuesObject.device_type_id = { id: values.device_type.typeid, value: values.device_type.type };
  }
  for (const key in formValuesObject) {
    if (!FORM_FIELDS_KEYS[key]) {
      delete formValuesObject[key];
    }
  }

  formValuesObject.device_ids = [];
  if (values.devices) {
    formValuesObject.device_ids = values.devices.map((device) => ({
      id: device.deviceid,
      value: trimAssetName(device.name),
    }));
  }

  return formValuesObject;
}
const RulesActionsIcons = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEdit = useCallback(() => {
    const updatedFormValues = updateFormValues(row);
    dispatch(updateRulesActionType("edit"));
    dispatch(updateRulesFormValues(updatedFormValues));
    navigate("/alarm-rules/edit");
  }, []);
  const handleDelete = useCallback(() => {
    dispatch(rulesDelete({ showPopup: true, id: row.id }));
  }, []);

  return (
    <>
      <svg
        onClick={handleEdit}
        className="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575q.837 0 1.412.575l1.4 1.4q.575.575.6 1.388q.025.812-.55 1.387ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6Z"
        />
      </svg>
      <svg
        onClick={handleDelete}
        className="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="currentColor" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
      </svg>
    </>
  );
};
export const RULES_COLUMNS = [
  {
    field: "editActions",
    type: "actions",
    headerName: "",
    sortable: true,

    renderCell: (params) => <RulesActionsIcons {...params} />,
  },
  {
    field: "updated_dt",
    headerName: "Updated Time",
    headerAlign: "center",
    align: "center",
    sortable: true,
    minWidth: 110,
    hideable: true,
    valueFormatter: (params) => {
      const _value = params.value;
      if (!_value) return "-";
      return moment(_value).format("DD-MM-YYYY HH:mm:ss");
    },
    flex: 1,
  },
  {
    field: "customer_id",
    headerName: "Station",
    headerAlign: "center",
    align: "center",
    sortable: true,
    minWidth: 110,
    hideable: true,
    valueGetter: (params) => params.row.customer.stncode,
    flex: 1,
  },
  {
    field: "device_type_id",
    headerName: "Device Type",
    headerAlign: "center",
    align: "center",
    sortable: true,
    hideable: true,
    valueGetter: (params) => params.row.device_type.label,
    flex: 1,
  },
  {
    field: "mqtt_subgear",
    headerName: "Subgear",
    headerAlign: "center",
    align: "center",
    sortable: true,
    hideable: true,
    flex: 1,
  },
  {
    field: "alarm_name",
    headerName: "Alarm Name",
    headerAlign: "center",
    align: "center",
    sortable: true,
    minWidth: 110,
    hideable: true,
    flex: 1,
  },
  {
    field: "event_type",
    headerName: "Category",
    headerAlign: "center",
    align: "center",
    sortable: true,
    hideable: true,
    flex: 1,
  },
  {
    field: "history_window",
    headerName: "History Window",
    headerAlign: "center",
    align: "center",
    sortable: true,
    minWidth: 120,
    hideable: true,
  },
  {
    field: "severity",
    headerName: "Severity",
    headerAlign: "center",
    align: "center",
    sortable: true,
    minWidth: 80,
    hideable: true,
  },
  {
    field: "enable",
    headerName: "Enabled",
    headerAlign: "center",
    align: "center",
    sortable: true,
    minWidth: 80,
    hideable: true,
  },
];

const DeleteAlarmRulesPopup = () => {
  const dispatch = useDispatch();
  const { id } = useSelector(ruleShowDeletePopupSelector);
  const [deleteRule] = useDeleteRuleMutation();
  function closeDeleteRulesPopup() {
    dispatch(rulesDelete({ showPopup: false, id: "" }));
  }
  function handleDelete() {
    deleteRule(id).then((res) => {
      if (res?.error) {
        console.error("delete rule", res);
        toast.error(res?.error?.data ?? "Something went wrong! Try again later");
        return;
      }
      const message = "Rule Deleted Successfully";
      toast.success(message);
      closeDeleteRulesPopup();
    });
  }
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={true}
        onClose={closeDeleteRulesPopup}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="responsive-dialog-title" className="flex items-center">
          Delete Rule
          <CloseIcon className="pointer" sx={{ ml: "auto" }} onClick={closeDeleteRulesPopup} />
        </DialogTitle>

        <DialogContent dividers>Do you want to delete this rule?</DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={closeDeleteRulesPopup}>
            Cancel
          </Button>
          <Button variant="contained" autoFocus onClick={handleDelete}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function useAlarmRules() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const handlePageSizeChange = useCallback((size) => setPageSize(size), []);
  function handleCreateRule() {
    dispatch(updateRulesActionType("create"));
    dispatch(resetFormValues());
    navigate("/alarm-rules/create");
  }
  function handleShowCopyRulesPopup() {
    dispatch(triggerCopyRulesPopup(true));
  }
  return useMemo(
    () => ({ pageSize, handlePageSizeChange, handleCreateRule, handleShowCopyRulesPopup }),
    [pageSize, handlePageSizeChange],
  );
}

const ViewAllRules = () => {
  const showCopyRulePopup = useSelector(showCopyRulesPopup);
  const { showPopup: showDeleteRulePopup } = useSelector(ruleShowDeletePopupSelector);
  const { pageSize, handlePageSizeChange, handleCreateRule, handleShowCopyRulesPopup } = useAlarmRules();
  const selectedCustomerIds = useSelector(selectedCustomersIdsSelector);
  const { data: rulesList = [], isFetching } = useGetRulesQuery({ customer_ids: selectedCustomerIds.toString() });

  return (
    <div className="alarm-rules--container">
      <div className="flex justify-end gap-2 mb-2">
        <Button variant="outlined" onClick={handleShowCopyRulesPopup}>
          Copy Rules
        </Button>
        <Button variant="contained" onClick={handleCreateRule}>
          Create Rule
        </Button>
      </div>
      <div>
        <DataTable
          pageSize={pageSize}
          pageSizeChange={handlePageSizeChange}
          tableInfo={RULES_TABLE_INFO}
          loading={isFetching}
          rows={rulesList}
          columns={RULES_COLUMNS}
        />
      </div>
      {showCopyRulePopup && <CopyAlarmRulesPopup />}
      {showDeleteRulePopup && <DeleteAlarmRulesPopup />}
    </div>
  );
};

export default memo(ViewAllRules);
