import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./FormPreview.scss";
import { ALLOWED_MODES, itemTypes } from "../Constats";
import { useImmer } from "use-immer";
import PopUp from "./PopUp/PopUp";
import { FormControl, Input, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormSection from "./Section/FormSection";
import { FormContext } from "../FormCreator";
import EditToolBox from "./EditToolBox/EditToolBox";
import { Edit, Preview } from "@mui/icons-material";
import DragHandleIcon from '@mui/icons-material/DragHandle';


const FieldCard = ({ fieldData, index, onFieldRowClick, onEditClick, selectedFieldRowKey, fieldKey, handleOnDrop, moveCard, isSection }) => {

  const ref = useRef(null)
  let FIELD_VISIBILITY = true
  if ('reactiveKey' in fieldData) {
    if (fieldData.type === 'reactive_section' || fieldData.type === 'reactive-section') {
      FIELD_VISIBILITY = true
    } else {

      FIELD_VISIBILITY = fieldData.showInitially
    }
  }
  // ( fieldData, index ) => {
  const { manipulateReactiveFields, formData, formCreatorMode } = useContext(FormContext);
  const [{ isOverCurrent }, dropRef] = useDrop({
    accept: itemTypes.ADD_COMPONENT,
    drop: (item, monitor) => {
      handleOnDrop(item, fieldKey)
    },
    collect: monitor => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    })
  })

  const [{ handlerId }, drop] = useDrop({
    accept: itemTypes.FORM_FIELDS,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    type: itemTypes.FORM_FIELDS,
    item: () => {
      return { fieldKey, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  let fieldLabel = fieldData.label;
  if (fieldData.multiple) {
    fieldLabel = fieldLabel + "(Multiple)";
  }
  if (fieldData.required) {
    fieldLabel = fieldLabel + "*";
  }
  let LabelProps = {};
  let secLabel = fieldData.label
  let secSubLabel = ''
  let secVisibility = true
  if (fieldData.type === "date") {
    LabelProps = {
      InputLabelProps: {
        shrink: fieldData.type === "date",
      },
    };
  }
  if (fieldData.type === 'section' || fieldData.type === 'reactive_section') {

    if (fieldData.reactiveKey) {
      if (fieldData.showInitially) {
        secVisibility = true
      } else {
        secVisibility = false
        secSubLabel = '(This will be not visible until parant field value is not set)'
      }
    }
  }
  drag(drop(ref))
  // console.log('fieldData',fieldData)
  // const opacity = isDragging ? 0 : 1
  return (

    <div
      ref={preview}
      data-handler-id={handlerId}
      onDoubleClick={(e) => onFieldRowClick(e, fieldData.key)}
      className={`filed-row ${fieldData.key === selectedFieldRowKey ? "selectedFieldRow" : ""} ${isDragging ? 'fieldDragging' : ''} ${!FIELD_VISIBILITY ? 'hideField' : ''}`}
    >
      {!isSection && ALLOWED_MODES.draggFields.includes(formCreatorMode) && (<div ref={ref} className="fieldHandle"> <DragHandleIcon /></div>)}

      {fieldData.type === "heading" && (<h2 key={fieldData.key} className="heading field-item">{fieldLabel}</h2>)}
      {(fieldData.type === "sub_heading" || fieldData.type === "sub-heading") && (
        <h4 key={fieldData.key} className="sub-heading field-item">{fieldLabel}</h4>
      )}
      {(fieldData.type === "reactive-dropdown" || fieldData.type === "reactive_dropdown") && (
        <FormControl key={fieldData.key} size="small" className="field-item" fullWidth>
          <InputLabel id="standard-label">{fieldLabel}</InputLabel>
          <Select
            // key={fieldData.key}
            defaultValue={[]}
            id="select-standard"
            label={fieldLabel}
            multiple={fieldData.multiple}
            placeholder={fieldData.placeholder}
            onChange={(e) => {
              if (true) {
                manipulateReactiveFields(fieldData.key, e.target.value, formData);
              }
            }}

          >
            {fieldData?.["DEFAULT_VALUE"].map((item, i) => {
              return (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {(fieldData.type === "section" || fieldData.type === 'reactive_section') && (
        <div ref={dropRef} style={{ backgroundColor: isOverCurrent ? '#fcff0212' : '' }} className="scetionField field-item">
          <div className='header'>   <div className='heading'>{fieldLabel}</div>
            <div className='sub-heading'>{secSubLabel}</div></div>
          {fieldData.sectionFields && (fieldData.sectionFields.map((fieldData, i) => {
            return (
              <FieldCard
                index={i}
                key={fieldData.key}
                fieldData={fieldData}
                onFieldRowClick={onFieldRowClick}
                onEditClick={onEditClick}
                fieldKey={fieldData.key}
                selectedFieldRowKey={selectedFieldRowKey}
                isSection={true}
              // moveCard={moveCard}
              />
            )
            // fieldCard(fieldData, i)
          }))}
        </div>
        // <FormSection
        //   sectionIndex={i}
        //   label={secLabel}
        //   setPopUp={(item, sectionId) => {
        //     handleOpenPopUp(item, sectionId);
        //   }}
        //   secSubLabel={secSubLabel}
        //   sectionId={fieldData.key}
        //   sectionFields={fieldData?.sectionFields}
        //   secVisibility={secVisibility}
        // />
      )}
      {fieldData.type === "dropdown" && (
        <FormControl key={fieldData.key} size="small" className="field-item" fullWidth>
          <InputLabel id="standard-label">{fieldLabel}</InputLabel>
          <Select
            // key={fieldData.key}
            defaultValue={[]}
            id="select-standard"
            label={fieldLabel}
            multiple={fieldData.multiple}
            onChange={(e) => {
              if (true) {
                manipulateReactiveFields(fieldData.key, e.target.value, formData)
              }
            }}
          >
            {fieldData?.options?.map((item, i) => {
              return (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {fieldData.type === 'attachment' && (
        <Input key={fieldData.key}
          size="small"
          className="field-item"
          type={'file'}
          label={fieldLabel}
        // multiple
        />
      )}
      {fieldData.type === 'image_capture' && (
        <h4 className="sub-heading field-item">{`${fieldLabel}(Image Capture Field)`}</h4>
        // <Input key={fieldData.key}
        //   size="small"
        //   className="field-item"
        //   type={'file'}
        //   label={fieldLabel}
        // // multiple
        // />
      )}
      {(fieldData.type === 'textfield' || fieldData.type === 'reactive_textfield' || fieldData.type === 'reactive_number' || fieldData.type === 'reactive_date' || fieldData.type === 'number' || fieldData.type === 'iot_field' || fieldData.type === 'iot-field' || fieldData.type === 'date') && (
        <TextField key={fieldData.key}
          size="small"
          className="field-item"
          type={fieldData.type}
          placeholder={fieldData.placeholder}
          label={fieldLabel}
          onChange={(e) => {
            if (true) {
              manipulateReactiveFields(fieldData.key, e.target.value, formData);
            }
          }}
          {...LabelProps}
        />
      )}
      {/* <div>{fieldData.label}</div> */}

      <EditToolBox
        onEditClick={(fieldNode, targetId, editData) => {
          onEditClick(fieldNode, targetId, editData);
        }}
        index={index}
        fieldInfo={fieldData}
      />
    </div>
  );
  // };
}

const initialPopState = {
  open: false,
  data: null,
  targetId: null,
};
function FormPreview({ formJson }) {
  const [popUp, setPopUp] = useImmer({
    open: false,
    data: null,
    targetId: null,
  });
  const [selectedFieldRowKey, setSelectedFieldRowKey] = useState(null);
  const { manipulateReactiveFields, formData, formCreatorMode, replaceFormData } = useContext(FormContext);
  const [{ isOver, isOverCurrent }, dropRef] = useDrop({
    accept: itemTypes.ADD_COMPONENT,
    drop: (item, monitor) => {
      if (isOverCurrent) {
        handleOpenPopUp(item);
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    if (!ALLOWED_MODES.editToolBox.includes(formCreatorMode)) {
      setSelectedFieldRowKey("");
    }
  }, [formCreatorMode]);


  const handleOpenPopUp = useCallback(
    (item, targetId) => {
      setPopUp((prev) => {
        prev.open = true;
        prev.data = item;
        prev.targetId = targetId;
        prev.editInfo = null;
      });
    },
    [popUp],
  );

  const handleClosePopUp = useCallback(() => {
    setPopUp((prev) => {
      prev.open = false;
      prev.data = null;
      prev.targetId = null;
      prev.editInfo = null;
    });
  }, [popUp]);

  const handleFieldRowClick = (e, fieldRowKey) => {

    e.stopPropagation();
    if (ALLOWED_MODES.editToolBox.includes(formCreatorMode)) {
      if (selectedFieldRowKey === fieldRowKey) {
        setSelectedFieldRowKey("");
      } else {
        setSelectedFieldRowKey(fieldRowKey);
      }
    }
  };

  const handleEditClick = (fieldNode, targetId, editData) => {
    setPopUp((prev) => {
      prev.open = true;
      prev.data = fieldNode;
      prev.targetId = targetId;
      prev.editInfo = editData;
    });
  };

  function exchangeIndexes(array, index1, index2) {
    if (index1 < 0 || index1 >= array.length || index2 < 0 || index2 >= array.length) {
      return array; // Return the original array if indexes are invalid
    }

    const newArray = [...array]; // Create a new array with the elements of the original array

    const temp = newArray[index1];
    newArray[index1] = newArray[index2];
    newArray[index2] = temp;

    return newArray; // Return the modified array
  }

  function updateArray(prevCards, dragIndex, hoverIndex) {
    if (dragIndex < 0 || dragIndex >= prevCards.length || hoverIndex < 0 || hoverIndex >= prevCards.length) {
      return prevCards; // Return the original array if indexes are invalid
    }

    const newArray = Array.from(prevCards); // Create a new array with the elements of the original array

    const [removedElement] = newArray.splice(dragIndex, 1); // Remove one element at dragIndex
    newArray.splice(hoverIndex, 0, removedElement); // Insert the removed element at hoverIndex

    return newArray; // Return the modified array
  }

  const moveCard = (dragIndex, hoverIndex) => {
    let exchangedFormData = updateArray(formData, dragIndex, hoverIndex)
    replaceFormData(exchangedFormData)
  }
  //   const moveCard = useCallback((dragIndex, hoverIndex) => {
  //     console.log('function to move cards',formData, dragIndex, hoverIndex)
  // // let exchangedFormData = updateArray(formData,dragIndex,hoverIndex)
  // // replaceFormData(exchangedFormData)
  // // console.log('exchangedFormData',exchangedFormData)

  //   }, [hoverIndex])

  return (
    <>
      <div
        onClick={(e) => {
          handleFieldRowClick(e, null);
        }}
        ref={dropRef}
        style={{ backgroundColor: isOverCurrent ? "#7cff7c2b" : "" }}
        className={`form-preview-container ${isOverCurrent ? "draggingOver" : "draggingOver"}`}
      >
        {formJson.length === 0 && <h2>Please Drag any form element to create a form</h2>}
        {formJson.length > 0 &&
          formJson.map((fieldData, i) => {
            // return fieldCard(fieldData, i)
            return (
              <FieldCard
                index={i}
                key={fieldData.key}
                fieldData={fieldData}
                onFieldRowClick={handleFieldRowClick}
                onEditClick={handleEditClick}
                fieldKey={fieldData.key}
                selectedFieldRowKey={selectedFieldRowKey}
                handleOnDrop={(item, fieldKey) => { handleOpenPopUp(item, fieldKey) }}
                moveCard={moveCard}
                isSection={false}
              />
            )

          })}
        {/*   {formJson.length > 0 &&
          formJson.map((fieldData, i) => {
            let fieldLabel = fieldData.label;
            if (fieldData.multiple) {
              fieldLabel = fieldLabel + "(Multiple)";
            }
            if (fieldData.required) {
              fieldLabel = fieldLabel + "*";
            }
            if (fieldData.type === "heading") {
              return (
                <div onDoubleClick={(e) => { handleFieldRowClick(e, fieldData.key) }} className={`filed-row ${fieldData.key === selectedFieldRowKey ? 'selectedFieldRow' : ''}`}>
                  <h2 className="heading field-item">{fieldLabel}</h2>
                  <EditToolBox onEditClick={(fieldNode, targetId, editData) => { handleEditClick(fieldNode, targetId, editData) }} index={i} fieldInfo={fieldData} />
                </div>
              );
            } else if (fieldData.type === "sub_heading" || fieldData.type === "sub-heading") {
              return (
                <div onDoubleClick={(e) => { handleFieldRowClick(e, fieldData.key) }} className={`filed-row ${fieldData.key === selectedFieldRowKey ? 'selectedFieldRow' : ''}`}>
                  <h4 className="sub-heading field-item">{fieldLabel}</h4>
                  <EditToolBox onEditClick={(fieldNode, targetId, editData) => { handleEditClick(fieldNode, targetId, editData) }} index={i} fieldInfo={fieldData} />
                </div>
              );
            } else if (fieldData.type === "dropdown") {
              return (
                <div onDoubleClick={(e) => { handleFieldRowClick(e, fieldData.key) }} className={`filed-row ${fieldData.key === selectedFieldRowKey ? 'selectedFieldRow' : ''}`}>
                  <FormControl size="small" className="field-item" fullWidth>
                    <InputLabel id="standard-label">{fieldLabel}</InputLabel>
                    <Select
                      key={fieldData.key}
                      defaultValue={fieldData.multiple ? [] : ""}
                      id="select-standard"

                      label={fieldLabel}
                      multiple={fieldData.multiple}
                      onChange={(e) => {
                        if (true) {
                          manipulateReactiveFields(fieldData.key, e.target.value, formData);
                        }
                      }}
                    >
                      {fieldData?.options?.map((item, i) => {
                        return (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <EditToolBox onEditClick={(fieldNode, targetId, editData) => { handleEditClick(fieldNode, targetId, editData) }} index={i} fieldInfo={fieldData} />
                </div>
              );
              // }
            } else if (fieldData.type === "reactive-dropdown" || fieldData.type === "reactive_dropdown") {
              if (fieldData.showInitially) {
                return (
                  <div onDoubleClick={(e) => { handleFieldRowClick(e, fieldData.key) }} className={`filed-row ${fieldData.key === selectedFieldRowKey ? 'selectedFieldRow' : ''}`}>
                    <FormControl size="small" className="field-item" fullWidth>
                      <InputLabel id="standard-label">{fieldLabel}</InputLabel>
                      <Select
                        key={fieldData.key}
                        defaultValue={""}
                        id="select-standard"

                        label={fieldLabel}
                        multiple={fieldData.multiple}
                        placeholder={fieldData.placeholder}
                        onChange={(e) => {
                          if (true) {
                            manipulateReactiveFields(fieldData.key, e.target.value, formData);
                          }
                        }}
                      >
                        {fieldData?.["DEFAULT_VALUE"].map((item, i) => {
                          return (
                            <MenuItem key={i} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <EditToolBox onEditClick={(fieldNode, targetId, editData) => { handleEditClick(fieldNode, targetId, editData) }} index={i} fieldInfo={fieldData} />
                  </div>
                );
              }
            } else if (fieldData.type === "section" || fieldData.type === 'reactive_section') {
              let secLabel = fieldData.label
              let secSubLabel = ''
              let secVisibility = true
              if (fieldData.reactiveKey) {
                if (fieldData.showInitially) {
                  secVisibility = true
                } else {
                  secVisibility = false
                  secSubLabel = '(This will be not visible until parant field value is not set)'
                }
              }
              return (
                <div onDoubleClick={(e) => { handleFieldRowClick(e, fieldData.key) }} className={`filed-row ${fieldData.key === selectedFieldRowKey ? 'selectedFieldRow' : ''}`}>
                  <FormSection
                    sectionIndex={i}
                    label={secLabel}
                    setPopUp={(item, sectionId) => {
                      handleOpenPopUp(item, sectionId);
                    }}
                    secSubLabel={secSubLabel}
                    sectionId={fieldData.key}
                    sectionFields={fieldData?.sectionFields}
                    secVisibility={secVisibility}
                  />
                  <EditToolBox onEditClick={(fieldNode, targetId, editData) => { handleEditClick(fieldNode, targetId, editData) }} index={i} fieldInfo={fieldData} />
                </div>
              )


            } else if (fieldData.type === 'attachment') {
              return (
                <div onDoubleClick={(e) => { handleFieldRowClick(e, fieldData.key) }} className={`filed-row ${fieldData.key === selectedFieldRowKey ? 'selectedFieldRow' : ''}`}>
                  <Input
                    size="small"
                    className="field-item"
                    type={'file'}
                    key={fieldData.key}
                    label={fieldLabel}
                    multiple
                  />
                  <EditToolBox onEditClick={(fieldNode, targetId, editData) => { handleEditClick(fieldNode, targetId, editData) }} index={i} fieldInfo={fieldData} />
                </div>
              )
            }
            else {
              let LabelProps = {};
              if (fieldData.type === "date") {
                LabelProps = {
                  InputLabelProps: {
                    shrink: fieldData.type === "date",
                  },
                };
              }
              return (
                <div onDoubleClick={(e) => { handleFieldRowClick(e, fieldData.key) }} className={`filed-row ${fieldData.key === selectedFieldRowKey ? 'selectedFieldRow' : ''}`}>
                  <TextField
                    size="small"
                    className="field-item"
                    type={fieldData.type}
                    key={fieldData.key}
                    placeholder={fieldData.placeholder}
                    label={fieldLabel}
                    onChange={(e) => {
                      if (true) {
                        manipulateReactiveFields(fieldData.key, e.target.value, formData);
                      }
                    }}
                    {...LabelProps}
                  />
                  <EditToolBox onEditClick={(fieldNode, targetId, editData) => { handleEditClick(fieldNode, targetId, editData) }} index={i} fieldInfo={fieldData} />
                </div>

              );
            }
          })} */}
      </div>
      {popUp.open && (
        <PopUp
          open={popUp.open}
          onClose={() => {
            handleClosePopUp();
          }}
          data={popUp.data}
          targetId={popUp.targetId}
          editInfo={popUp.editInfo}
        />
      )}
    </>
  );
}

export default FormPreview;
