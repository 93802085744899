import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PointOperationChart from "./PointOperationChart";
import { pmOperationDataFilter } from "./../Constant";
import { Transition } from "../../../Modal/ModalConstants";
function PointChartPopup({ open, onClose, machineData, deviceId, deviceName, currentTs, disableFilter }) {
  console.log(open, onClose, machineData, deviceId, deviceName, currentTs, disableFilter)
  // const processedEventTime = [];
  // const processedEventData = [];

  const processedMachineData = pmOperationDataFilter(
    machineData.eventData,
    machineData.eventTime,
    machineData.eventData2,
    machineData.eventData3,
  );
  // console.log(processedMachineData)
  // if (machineData.eventTime.length > 0 && machineData.eventData.length > 0) {
  //   const eventTime = machineData.eventTime.split(" ");
  //   const eventData = machineData.eventData.split(" ");

  //   eventTime.forEach((value, index) => {
  //     if (Number(value) !== 0 || index === 0) {
  //       processedEventTime.push(Number(value));
  //     }
  //   });
  //   eventData.filter((value, index) => {
  //     if (Number(value) !== 0 || index === 0) {
  //       processedEventData.push(Number(value));
  //     }
  //   });
  // }
  console.log("processedMachineData", processedMachineData);
  return (
    <Dialog
      fullScreen
      open={open}
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="responsive-dialog-title" className="flex">
        {`Point Machine Operation Chart (${deviceName})`}
        <CloseIcon
          className="pointer"
          sx={{ ml: "auto" }}
          onClick={() => {
            onClose(false);
          }}
        />
      </DialogTitle>
      <DialogContent
        // sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        dividers
      >
        <PointOperationChart
          deviceId={deviceId}
          // deviceName={deviceName}
          currentTs={currentTs}
          axisData={[...processedMachineData.time, Number(processedMachineData.time.slice(-1)) + 500]}
          lineData={[...processedMachineData.data, 0]}
          lineData2={[...processedMachineData.data2, 0]}
          lineData3={[...processedMachineData.data3, 0]}
          disableFilter={disableFilter}
        />
      </DialogContent>
      {/* <DialogActions>
        <Button
          variant="contained"
          autoFocus
          onClick={() => {
            onClose(false);
          }}
        >
          close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default PointChartPopup;
