import axios from "axios";
import { APP_BASE_URL } from "../Common/EndPoints";
import { messageService } from "./MessageService";

const instance = axios.create({
  baseURL: APP_BASE_URL,
});

instance.interceptors.request.use((value) => {
  value.headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return value;
});

// Adding a request interceptor
instance.interceptors.request.use(
  (config) => {
    const _token = localStorage.getItem("token");
    if (_token) {
      config.headers = {
        Authorization: _token,
      };
    }
    // applicationLoader(true);
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
// Adding a response interceptor
instance.interceptors.response.use(
  (response) => {
    // applicationLoader(false);
    return response;
  },
  (error) => {
    // applicationLoader(false);
    // Do something with request error
    return error;
  }
);

let pendingRequests = 0;
const applicationLoader = (status: boolean) => {
  if (status) {
    pendingRequests++;
    messageService.sendMessage("startLoader");
  } else {
    pendingRequests--;
    if (pendingRequests === 0) {
      messageService.sendMessage("endLoader");
    }
  }
};
const http = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  patch: instance.patch,
};

export default http;
