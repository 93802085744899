import { useCallback, useMemo } from "react";
import { useImmer } from "use-immer";
import { useEffect, useContext, useState } from "react";
import { APP_BASE_URL, DELETE_USER, GET_ALL_USER } from "../../Common/EndPoints";
import { Alert, Button } from "@mui/material";
import axios from "axios";
import DataTable from "../DataGrid/DataGrid";

import AddUser from "./AddUser";
import DeleteModal from "../../Modal/DeleteModal";
import Snackbar from "@mui/material/Snackbar";
import "./User.scss";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { USERS_COLUMNS } from "./Constants/UserColumns.jsx";
import { extractMasterRoleIds } from "../../Utils/Utils";

const initialState = {
  userData: [],
  userActionLabel: "Create USER",
  showUserModal: false,
  showDeleteModal: false,
  id: null,
  selectedRow: null,
  loader: true,
  severityType: null,
  snackbarMessage: null,
  snackbar: false,
};
const addUser = (setState) => {
  setState((draft) => {
    draft.showUserModal = true;
  });
};
function getAllUsers({ params, signal }) {
  const url = APP_BASE_URL + GET_ALL_USER;
  return axios.get(url, { params, signal });
}

const User = () => {
  const USER_ID = localStorage.getItem("userId");
  let roleHr = JSON.parse(localStorage.getItem('roleHierarchies'))
  let MASTER_ROLE_IDS = extractMasterRoleIds(roleHr)
  const [state, setState] = useImmer(initialState);
  const { newUserData, customerIdList } = useContext(ActiveAlarmsData);
  const [userStationsData, setUserStationsData] = useState([]);
  const access = window.frontendAccess?.USER;
  const allowCreateUser = access?.create ?? true;
  const allowUpdateUser = access?.update ?? true;
  useEffect(() => {
    const controller = new AbortController();
    if (customerIdList.length > 0) {
      GetAllUser(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [customerIdList.length]);
  const GetAllUser = (signal) => {
    setState((state) => {
      state.loader = true;
    });
    const params = { customerIds: customerIdList.toString(), roleIdList: MASTER_ROLE_IDS.toString() };
    getAllUsers({ params, signal })
      .then((res) => {
        if (res?.data?.data) {
          const data = [];

          res?.data?.data?.forEach(
            ({ id, firstName, lastName, email, authority, updatedTime, permissions, mobileNo }) => {
              const zone_list = [];
              let stations = [];
              let div = [];
              permissions?.forEach((zone) => {
                if (Object.keys(zone).length > 0) {
                  const zones = zone;
                  const _zone = Object.keys(zones)[0];
                  zone_list.push(_zone);
                  let divisions = Object.values(zones);

                  divisions.forEach((data) => {
                    data.forEach((dt) => {
                      Object.entries(dt).forEach(([key, value]) => {
                        div.push(key);
                        value.forEach((v) => {
                          stations.push(v);
                        });
                      });
                    });
                  });
                }
              });
              if (id === USER_ID) {
                newUserData.customerDetails = stations;
                localStorage.setItem("userData", JSON.stringify(newUserData));
                setUserStationsData(permissions);
                // console.log()
              }
              stations = stations.map(({ customerName }) => customerName);
              // if (authority !== "admin") {
              let row = {
                id: id ?? "-",
                firstName: firstName ?? "-",
                lastName: lastName ?? "-",
                name: firstName + " " + lastName ?? "-",
                email: email ?? "-",
                authority: authority ?? "-",
                stations: stations ?? [],
                createdTime: updatedTime,
                permissions: permissions?.[0],
                mobileNumber: mobileNo,
                divisions: div ?? [],
                zones: zone_list ?? [],
              };

              data.push(row);
              // }
            },
          );
          setState((previousState) => {
            previousState.userData = data;
            previousState.loader = false;
          });
        }
      })
      .catch((err) => {
        setState((previousState) => {
          previousState.loader = false;
        });
      });
  };

  const deleteUser = () => {
    const url = `${APP_BASE_URL}${DELETE_USER}${state.id}`;
    axios
      .delete(url)
      .then(() => {
        setState((previousState) => {
          previousState.showDeleteModal = false;
          previousState.snackbar = true;
          previousState.severityType = "success";
          previousState.snackbarMessage = "User has been deleted";
        });
        GetAllUser();
      })
      .catch(() => {
        setState((previousState) => {
          previousState.showDeleteModal = false;
          previousState.snackbar = true;
          previousState.severityType = "error";
          previousState.snackbarMessage = "Error Deleting User";
        });
      });
  };

  const handleCloseUserModal = () => {
    setState((previousState) => {
      previousState.showUserModal = false;
      previousState.showDeleteModal = false;
      previousState.selectedRow = null;
      previousState.userActionLabel = "Create User";
    });
  };

  const userColumn = useMemo(() => USERS_COLUMNS(setState, allowUpdateUser), []);
  const [pageSize, setPageSize] = useState(20);
  const handlePageSizeChange = useCallback((size) => setPageSize(size));
  const tableSize = useMemo(() => pageSize, [pageSize]);

  return (
    <div className="users">
      <div className="flex flexEnd">
        {allowCreateUser && (
          <Button variant="contained" onClick={() => addUser(setState)} className="create-user-button">
            Create
          </Button>
        )}
      </div>
      <DataTable
        tableInfo={{
          id: `user-table`,
          label: `User`,
          requireAuthSign: false,
          timestamp: { startTs: null, endTs: null },
        }}
        pageSize={tableSize}
        pageSizeChange={handlePageSizeChange}
        loading={state.loader}
        rows={state?.userData ?? []}
        columns={userColumn}
      />

      {state.showUserModal && (
        <AddUser
          open={state.showUserModal}
          label={state.userActionLabel}
          onClose={handleCloseUserModal}
          editRow={state.selectedRow}
          userStationsData={userStationsData}
          update={(type, severity) => {
            GetAllUser();
            setState((previousState) => {
              previousState.snackbar = true;
              previousState.severityType = severity;
              previousState.snackbarMessage = type;
            });
          }}
        />
      )}
      {state.showDeleteModal && (
        <DeleteModal
          open={state.showDeleteModal}
          deleteMessageFor={"User"}
          onClose={handleCloseUserModal}
          willDelete={deleteUser}
        />
      )}
      {state.snackbar && (
        <Snackbar
          open={state.snackbar}
          autoHideDuration={6000}
          onClose={() => {
            setState((draft) => {
              draft.snackbar = false;
            });
          }}
        >
          <Alert
            onClose={() => {
              setState((draft) => {
                draft.snackbar = false;
              });
            }}
            severity={state.severityType}
            className="fullWidth"
          >
            {state.snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default User;
