import TitleIcon from "@mui/icons-material/Title";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Crop32Icon from "@mui/icons-material/Crop32";
import { IOT_ICON, REACIVE_SEC_ICON } from "../../ICONS/Icons";
import AttachmentIcon from "@mui/icons-material/Attachment";
import EventNoteIcon from '@mui/icons-material/EventNote';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import Filter1Icon from '@mui/icons-material/Filter1';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export const FORM_COMPONENTS_OPTIONS = [
  {
    label: "Heading",
    id: "",
    type: "heading",
    icon: <TitleIcon />,
    bgColor: "#FFF9DB",
  },
  {
    label: "Sub Heading",
    id: "",
    type: "sub_heading",
    icon: <TitleIcon />,
    bgColor: "#E9FDFD",
  },
  {
    label: "Plain Text Field",
    id: "",
    type: "textfield",
    icon: <TextFieldsIcon />,
    bgColor: "#F6E9FD",
  },
  {
    label: "Number Field",
    id: "",
    type: "number",
    icon: <LooksOneIcon />,
    bgColor: "#F6E9FD",
  },
  {
    label: "Date Time Field",
    id: "",
    type: "date",
    icon: <DateRangeIcon />,
    bgColor: "#EBFBEE",
  },
  {
    label: "Dropdown",
    id: "",
    type: "dropdown",
    icon: <ArrowDropDownIcon />,
    bgColor: "#E9F7FD",
  },
  {
    label: "Attachment",
    id: "",
    type: "attachment",
    icon: <AttachmentIcon />,
    bgColor: "#f9f1e4",
  },
  {
    label: "Image Capture",
    id: "",
    type: "image_capture",
    icon: <CameraAltIcon />,
    bgColor: "#f9f1e4",
  },
  // {
  //   label: "Multiple Select",
  //   id: "",
  //   type:'multiselect',
  //   icon: <CheckBoxIcon />,
  //   bgColor: "#FDF5E9",
  // },
  {
    label: "Section",
    id: "",
    type: "section",
    icon: <Crop32Icon />,
    bgColor: "#FDF5E9",
  },
];

export const ALLOWED_MODES = {
  actionButton: ["create", "edit"],
  formComponents: ["create", "edit"],
  editToggle: ["view", "edit"],
  editHeading: ["create", "edit"],
  editToolBox: ["create", "edit"],
  draggFields: ["create", "edit"]
};

export const getNodeByType = {
  heading: {
    label: "Heading",
    id: "",
    type: "heading",
  },
  sub_heading: {
    label: "Sub Heading",
    id: "",
    type: "sub_heading",
  },
  "sub-heading": {
    label: "Sub Heading",
    id: "",
    type: "sub_heading",
  },
  textfield: {
    label: "Plain Text Field",
    id: "",
    type: "textfield",
  },
  number: {
    label: "Number Field",
    id: "",
    type: "number",
  },
  date: {
    label: "Date Time Field",
    id: "",
    type: "date",
  },
  dropdown: {
    label: "Dropdown",
    id: "",
    type: "dropdown",
  },
  attachment: {
    label: "Attachment",
    id: "",
    type: "attachment",
  },
  section: {
    label: "Section",
    id: "",
    type: "section",
  },
  reactive_dropdown: {
    label: "Reactive Dropdown",
    id: "",
    type: "reactive_dropdown",
  },
  "reactive-dropdown": {
    label: "Reactive Dropdown",
    id: "",
    type: "reactive_dropdown",
  },
  reactive_section: {
    label: "Reactive Section",
    id: "",
    type: "reactive_section",
  },
  reactive_textfield: {
    label: "Reactive Textfield",
    id: "",
    type: "reactive_textfield",
  },
  reactive_number: {
    label: "Reactive Number",
    id: "",
    type: "reactive_number",
  },
  reactive_date: {
    label: "Reactive Date",
    id: "",
    type: "reactive_date",
  },
  "reactive-section": {
    label: "Reactive Section",
    id: "",
    type: "reactive_section",
  },
  iot_field: {
    label: "IOT Field",
    id: "",
    type: "iot_field",
  },
  "iot-field": {
    label: "IOT Field",
    id: "",
    type: "iot_field",
  },
  'image_capture': {
    label: "Image Capture",
    id: "",
    type: "image_capture",
  }
};

export const REACTIVE_FORM_COMPONENTS_OPTIONS = [
  {
    label: "Reactive Dropdown",
    id: "",
    type: "reactive_dropdown",
    icon: <ArrowDropDownIcon />,
    bgColor: "#E9F7FD",
  },
  {
    label: "Reactive Textfield",
    id: "",
    type: "reactive_textfield",
    icon: <TextIncreaseIcon />,
    bgColor: "#FFFFFF",
  },
  {
    label: "Reactive Number",
    id: "",
    type: "reactive_number",
    icon: <Filter1Icon />,
    bgColor: "#ffffff",
  },
  {
    label: "Reactive Date",
    id: "",
    type: "reactive_date",
    icon: <EventNoteIcon />,
    bgColor: "#fffffff",
  },
  {
    label: "Reactive Section",
    id: "",
    type: "reactive_section",
    icon: REACIVE_SEC_ICON,
    bgColor: "#FDF5E9",
  },
  {
    label: "IOT Field",
    id: "",
    type: "iot_field",
    icon: IOT_ICON,
    bgColor: "#c9ecc7",
  },
];
const createObjByarr = (arr) => {
  let ab = {};
  arr.forEach((val, i) => {
    // console.log('1')
    ab[val.type] = val
  })
  return ab
}
export const itemTypes = {
  ADD_COMPONENT: "addComponent",
  FORM_FIELDS: 'formFields'
};
