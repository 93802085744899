import React, { useState } from "react";
import './TcCharts.scss'
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import Card from "./Card";

function TcCharts({ close, telemetryData, avalibleStation }) {

  const [allowedStations, setAllowedStations] = useState([avalibleStation[0]])

  const labels = []
  const RELAY_CURRENT = []
  const FEED_CURRENT = []
  const RELAY_ENERGIZATION = []
  const CHARGER_OUTPUT = []
  telemetryData.forEach((row) => {
    let station = row.NAME.split('-')[0]
    if (allowedStations.includes(station)) {

      labels.push(row.NAME);
      RELAY_CURRENT.push(row.IR)
      FEED_CURRENT.push(row.IF)
      CHARGER_OUTPUT.push(row.VCO)
      if (row.VR) {
        let value = Number(row.VR)
        let eng = (value / 1400) * 100;
        if (station === 'MBG' || station === 'NHGJ' || station === 'NHLG' || station === 'DLO') {
          eng = (value / 500) * 100;
        }

        RELAY_ENERGIZATION.push(eng.toFixed(0))
      }

    }
  });


  const RELAY_CURRENT_DATA = {
    labels: labels,
    datasets: [
      {
        data: RELAY_CURRENT,
        borderColor: "#ab3da9",
        id: 'Relay Current',
        backgroundColor: 'rgb(174,69,172, 0.15)',
      },
    ],
  };

  const FEED_CURRENT_DATA = {
    labels: labels,
    datasets: [
      {
        data: FEED_CURRENT,
        borderColor: "#ffb400",
        id: 'Feed Current',
        backgroundColor: 'rgb(255,180,0,0.15)',
      },
    ],
  };

  const RELAY_ENERGIZATION_DATA = {
    labels: labels,
    datasets: [
      {
        data: RELAY_ENERGIZATION,
        borderColor: "#76c8c8",
        id: 'enrgization',
        backgroundColor: 'rgb(118,200,200,0.15)',
      },
    ],
  };
  const CHARGER_OUTPUT_DATA = {
    labels: labels,
    datasets: [
      {
        data: CHARGER_OUTPUT,
        borderColor: "rgb(104,154,255)",
        id: 'Charger Output',
        backgroundColor: 'rgb(104,154,255,0.15)',
      },
    ],
  };


  return (
    <div className="main-container-tc-charts">
      <div className="header">
        <h2 className="heading">Track Circuit At Glance</h2>
        <div className="filters">
          <h4>Viewing Data for :</h4>
          <div className="multi-station-select">
            <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 250 }} size="small">
              <Select
                value={allowedStations}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setAllowedStations(e.target.value)
                  }
                }}
                multiple
              >
                {avalibleStation.map((st, i) => {
                  return (
                    <MenuItem id={i} value={st}>{st}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>

          </div>
        </div>
        <div className="close-btn" >
          <Button variant="contained" onClick={() => close()}> Back to Telemetry</Button>
        </div>
      </div>
      <div className="chart-container ">
        <div className="upper part">

          <Card
            heading='Live Relay Current'
            CHART_DATA={RELAY_CURRENT_DATA}
          />
          <Card
            heading='Live Feed Current'
            CHART_DATA={FEED_CURRENT_DATA}
          />
        </div>
        <div className="lower part">
          <Card
            heading='Live Relay Energization'
            CHART_DATA={RELAY_ENERGIZATION_DATA}
          />
          <Card
            heading='Charger Output'
            CHART_DATA={CHARGER_OUTPUT_DATA}
          />
        </div>
      </div>
    </div>
  );
}

export default TcCharts;
