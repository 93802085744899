import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Chip, Grid, Snackbar, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import { useEffect } from "react";
import axios from "axios";
import { APP_BASE_URL, GET_USER_BY_ID } from "../Common/EndPoints";
import { useImmer } from "use-immer";
import ResetPasswordModal from "./ResetPasswordModal";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useDispatch } from "react-redux";
import { logoutSuccess } from "../features/authentication/auth-slice";

const initialState = {
  userActionLabel: "Update Profile",
  resetPasswordModal: false,
  // showDeleteModal: false,
  profileData: {},
};
const intitialSnackbarState = {
  open: false,
  severityType: "",
  snackbarMessage: "",
  passwordChanged: false,
};

function getCustomerNames() {
  let customerNames = localStorage.getItem("STN");
  if (!customerNames) return null;
  customerNames = customerNames
    .split(",")
    .sort()
    .map((station, index) => (
      <Chip
        className="users__table__station-chip"
        key={"station-" + station + index}
        label={station}
        size="small"
        variant="filled"
      />
    ));
  return customerNames;
}

function getRoleHierarchies() {
  let roleHierarchies = localStorage.getItem("roleHierarchies");
  if (!roleHierarchies) return null;
  const _hierarchies = JSON.parse(roleHierarchies);
  if (!_hierarchies.roleGroup) return null;
  roleHierarchies = _hierarchies?.roleGroup?.map((role) => (
    <Chip
      className="users__table__station-chip"
      key={role.masterRoleId}
      label={role.roleName}
      size="small"
      variant="filled"
    />
  ));
  return roleHierarchies;
}

const ProfileModal = ({ open, onClose, passwordChanged }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [state, setState] = useImmer(initialState);
  const [snackBarState, setSnackBarState] = useImmer(intitialSnackbarState);

  const name = localStorage.getItem("user");
  const email = localStorage.getItem("email");
  const authority = localStorage.getItem("authority");

  const userInfo = React.useMemo(
    () => ({
      name,
      email,
      authority,
      customerNames: getCustomerNames(),
      roleHierarchies: getRoleHierarchies(),
    }),
    [],
  );

  const handleCloseResetPasswordModal = () => {
    setState((state) => {
      state.resetPasswordModal = false;
    });
  };
  const showResetPasswordModal = () => {
    setState((state) => {
      state.resetPasswordModal = true;
    });
  };
  const resetAction = (updated) => {
    if (updated) {
      setSnackBarState((state) => {
        state.open = true;
        state.severityType = "success";
        state.snackbarMessage = "Password Updated Successfully..";
        state.passwordChanged = true;
      });
    } else {
      setSnackBarState((state) => {
        state.open = true;
        state.severityType = "error";
        state.snackbarMessage = "Failed to Updated Password! please Try again..";
      });
    }
  };
  const OverflowTip = ({ children }) => {
    const [isOverflowed, setIsOverflow] = React.useState(false);
    const textElementRef = React.useRef();
    useEffect(() => {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }, []);
    return (
      <Tooltip
        componentsProps={{ tooltip: { sx: { backgroundColor: "#F5F5F5" } } }}
        title={<div className="overflow-tooltip-hover">{children}</div>}
        disableHoverListener={!isOverflowed}
        disableInteractive
      >
        <div ref={textElementRef} className="overflow-tooltip">
          {children}
        </div>
      </Tooltip>
    );
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle
          id="responsive-dialog-title"
          className="flex spaceBetween "
          sx={{ backgroundColor: "rgb(179 163 163 / 40%)" }}
        >
          <div>PROFILE</div>
          <div className="flex items-center gap-2">
            <Button onClick={showResetPasswordModal} sx={{ color: "black", border: "1px solid" }}>
              Change Password
            </Button>

            <CloseIcon
              sx={{ cursor: "pointer", padding: "1px 3px" }}
              onClick={() => {
                onClose(false);
              }}
            />
          </div>
        </DialogTitle>

        <DialogContent dividers style={{ backgroundColor: "rgb(202 185 185 / 36%)" }}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-4">
              <div className="flex gap-1" style={{ width: "7rem" }}>
                <PersonIcon />
                <b>Name:</b>
              </div>
              <span>{userInfo.name}</span>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex gap-1" style={{ width: "7rem" }}>
                <EmailIcon />
                <b>Email:</b>
              </div>
              <span>{userInfo.email}</span>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex gap-1" style={{ width: "7rem" }}>
                <SupervisedUserCircleIcon />
                <b>Authority:</b>
              </div>
              <span>{userInfo.authority}</span>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex gap-1" style={{ width: "7rem" }}>
                <PortraitOutlinedIcon />
                <b>Station(s):</b>
              </div>
              <OverflowTip>{userInfo.customerNames}</OverflowTip>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex gap-1" style={{ width: "7rem" }}>
                <AdminPanelSettingsIcon />
                <b>Hierarchy:</b>
              </div>
              <OverflowTip>{userInfo.roleHierarchies}</OverflowTip>
            </div>
          </div>
        </DialogContent>
        {snackBarState.open && (
          <Snackbar
            open={snackBarState.open}
            autoHideDuration={4000}
            onClose={() => {
              setSnackBarState((draft) => {
                draft.open = false;
              });
              if (snackBarState.passwordChanged) {
                localStorage.clear();
                dispatch(logoutSuccess());
                navigate("/");
              }
            }}
          >
            <Alert severity={snackBarState.severityType} sx={{ width: "100%" }}>
              {snackBarState.snackbarMessage}
            </Alert>
          </Snackbar>
        )}
      </Dialog>

      {state.resetPasswordModal && (
        <ResetPasswordModal
          onClose={handleCloseResetPasswordModal}
          updated={(updated) => {
            resetAction(updated);
          }}
        />
      )}
    </div>
  );
};

export default ProfileModal;
