export const FORM_ASSIGNMENT_ICON = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#6F7172" stroke="none">
      <path
        d="M4270 5114 c-264 -40 -495 -220 -594 -463 -14 -35 -28 -79 -32 -97
l-6 -34 -245 0 c-225 0 -247 -2 -283 -20 -26 -13 -47 -34 -60 -60 -20 -38 -20
-57 -20 -885 l0 -845 -160 0 -160 0 0 330 0 330 -516 0 -516 0 -40 22 c-24 14
-48 38 -59 60 -18 35 -19 66 -19 553 l0 515 -720 0 c-703 0 -722 -1 -760 -20
-26 -13 -47 -34 -60 -60 -20 -39 -20 -55 -20 -1880 0 -1825 0 -1841 20 -1880
13 -26 34 -47 60 -60 39 -20 56 -20 1275 -20 1219 0 1236 0 1275 20 26 13 47
34 60 60 20 38 20 57 20 885 l0 845 160 0 160 0 0 -845 c0 -828 0 -847 20
-885 13 -26 34 -47 60 -60 36 -18 58 -20 283 -20 l245 0 6 -34 c26 -139 149
-319 286 -421 200 -148 481 -185 713 -94 131 51 284 179 360 300 155 245 155
553 0 798 -76 121 -229 249 -360 300 -232 91 -513 54 -713 -94 -137 -102 -260
-282 -286 -421 l-6 -34 -154 0 -154 0 0 755 0 755 154 0 154 0 6 -34 c26 -139
149 -319 286 -421 200 -148 481 -185 713 -94 131 51 284 179 360 300 155 245
155 553 0 798 -76 121 -229 249 -360 300 -232 91 -513 54 -713 -94 -137 -102
-260 -282 -286 -421 l-6 -34 -154 0 -154 0 0 755 0 755 154 0 154 0 6 -34 c26
-139 149 -319 286 -421 200 -148 481 -185 713 -94 131 51 284 179 360 300 155
245 155 553 0 798 -76 121 -229 249 -359 300 -106 41 -268 61 -374 45z m170
-614 c45 -23 80 -80 80 -130 0 -17 -9 -49 -20 -70 -23 -45 -80 -80 -130 -80
-50 0 -107 35 -130 80 -25 50 -25 90 0 140 37 73 124 99 200 60z m-2410 -1660
c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -38 -19 -58 -20 -675 -20
-617 0 -637 1 -675 20 -102 52 -104 196 -4 256 l39 24 638 0 c620 0 639 -1
677 -20z m2410 -150 c45 -23 80 -80 80 -130 0 -17 -9 -49 -20 -70 -23 -45 -80
-80 -130 -80 -50 0 -107 35 -130 80 -25 50 -25 90 0 140 37 73 124 99 200 60z
m-3320 -448 c107 -53 107 -211 0 -264 -30 -15 -64 -18 -219 -18 -171 0 -186 1
-223 22 -72 41 -96 123 -58 198 36 70 66 79 280 80 157 0 190 -3 220 -18z
m910 -2 c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -35 -18 -59 -20 -222
-20 -154 0 -188 3 -218 18 -107 53 -107 211 0 264 30 15 64 18 218 18 163 0
187 -2 222 -20z m0 -600 c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -38
-19 -58 -20 -675 -20 -617 0 -637 1 -675 20 -102 52 -104 196 -4 256 l39 24
638 0 c620 0 639 -1 677 -20z"
      />
      <path
        d="M1860 4025 l0 -355 357 0 358 0 -355 355 c-195 195 -356 355 -357
355 -2 0 -3 -160 -3 -355z"
      />
    </g>
  </svg>
);

export const ASSET_ASSIGNMENT_ICON = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2040 4710 c-139 -15 -221 -33 -246 -52 -22 -18 -24 -26 -24 -109 0
-94 -13 -142 -46 -170 -11 -9 -59 -31 -109 -49 -49 -18 -128 -50 -173 -71
-133 -61 -154 -59 -246 30 -39 38 -81 72 -94 76 -70 21 -413 -281 -588 -518
-96 -130 -96 -135 1 -237 68 -72 75 -82 75 -120 0 -26 -12 -64 -34 -108 -18
-37 -50 -112 -71 -167 -47 -128 -50 -132 -88 -155 -26 -16 -51 -20 -124 -20
-124 0 -136 -10 -160 -140 -25 -136 -25 -534 0 -675 24 -136 27 -139 167 -145
137 -6 143 -11 203 -170 24 -63 58 -143 76 -177 20 -40 31 -77 31 -103 0 -38
-7 -48 -75 -120 -97 -102 -97 -107 -1 -237 131 -178 379 -415 520 -498 59 -34
74 -29 162 56 92 89 113 91 246 30 45 -21 124 -53 173 -71 50 -18 98 -40 109
-49 33 -28 46 -76 46 -169 0 -75 3 -92 20 -107 44 -39 319 -71 540 -61 153 6
320 29 356 47 34 17 44 52 44 147 0 110 17 142 87 167 26 10 100 40 163 67
175 76 184 78 220 68 18 -5 63 -38 99 -73 36 -35 76 -67 89 -71 28 -9 61 7
154 77 178 134 359 320 471 486 54 78 51 98 -33 186 -73 78 -86 104 -76 160 7
34 7 34 -63 70 l-70 35 -16 -52 c-29 -99 -10 -185 60 -272 25 -30 45 -58 45
-61 0 -33 -242 -290 -368 -390 l-72 -57 -46 42 c-64 58 -124 83 -204 83 -58 0
-78 -6 -185 -55 -66 -31 -145 -63 -175 -73 -79 -25 -110 -42 -153 -84 -57 -53
-80 -104 -86 -191 l-6 -75 -50 -9 c-66 -11 -466 -11 -530 0 l-50 9 -7 63 c-18
161 -73 225 -248 287 -41 15 -124 50 -184 78 -97 44 -116 50 -175 50 -79 -1
-121 -18 -195 -78 l-55 -44 -53 41 c-134 105 -285 259 -365 371 l-26 36 41 46
c63 71 77 108 77 200 0 75 -3 87 -51 190 -28 61 -64 146 -80 189 -53 148 -133
215 -270 224 l-73 5 -8 56 c-4 31 -8 148 -8 261 0 113 4 230 8 261 l8 56 73 5
c137 9 217 76 270 224 16 43 52 129 80 189 48 103 51 115 51 190 0 92 -14 129
-77 200 l-41 46 26 36 c80 112 231 266 365 371 l53 41 55 -44 c74 -60 116 -77
195 -78 59 0 78 6 175 50 60 28 143 63 184 78 102 36 155 69 190 116 36 50 49
89 58 171 l7 63 50 9 c64 11 464 11 530 0 l50 -9 6 -75 c6 -87 29 -138 86
-191 43 -42 74 -59 153 -84 30 -10 109 -42 175 -73 107 -49 127 -55 185 -55
80 0 140 25 204 83 l46 42 72 -57 c126 -100 368 -357 368 -390 0 -3 -20 -31
-45 -61 -70 -87 -89 -173 -60 -272 l16 -52 70 35 c70 36 70 36 63 70 -10 56 3
82 76 160 84 88 87 108 33 186 -112 166 -293 352 -471 486 -93 70 -126 86
-154 77 -13 -4 -53 -36 -89 -71 -36 -35 -81 -68 -99 -73 -36 -10 -45 -8 -220
68 -63 27 -137 57 -163 67 -70 25 -87 57 -87 167 0 95 -10 130 -44 147 -67 34
-475 61 -646 41z"
      />
      <path
        d="M2109 4090 c-355 -32 -690 -190 -944 -445 -131 -131 -207 -235 -286
-392 -115 -227 -163 -430 -163 -693 0 -193 13 -284 65 -454 209 -680 875
-1133 1581 -1076 379 31 696 177 964 444 109 110 224 253 224 280 0 6 -28 28
-63 49 -71 43 -57 49 -137 -64 -25 -35 -86 -104 -135 -154 -412 -411 -1046
-516 -1570 -260 -259 126 -482 342 -614 594 -182 348 -208 739 -75 1113 117
328 387 623 705 772 189 88 372 129 584 129 291 0 543 -79 790 -247 125 -85
274 -238 355 -364 36 -56 67 -101 70 -102 3 0 36 16 73 36 l67 35 -51 82 c-71
114 -133 189 -247 299 -254 244 -571 386 -934 418 -124 11 -133 11 -259 0z"
      />
      <path
        d="M4722 3427 c-43 -13 -116 -78 -132 -117 l-12 -30 -100 0 c-55 0 -107
-4 -117 -9 -10 -6 -50 -60 -91 -120 l-73 -111 -258 0 -259 0 0 -80 0 -80 291
0 c162 0 298 4 308 9 10 6 50 60 91 120 l73 111 68 0 c60 0 68 -2 74 -21 11
-33 65 -88 109 -112 57 -30 155 -30 212 1 53 27 107 90 122 141 28 90 7 166
-63 236 -69 70 -153 91 -243 62z m133 -172 c16 -15 25 -36 25 -55 0 -19 -9
-40 -25 -55 -15 -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 -16 15 -25 36 -25 55
0 19 9 40 25 55 15 16 36 25 55 25 19 0 40 -9 55 -25z"
      />
      <path
        d="M1760 3347 c-49 -16 -133 -102 -148 -153 -15 -52 -16 -260 0 -305 6
-19 20 -46 30 -61 19 -28 19 -28 0 -55 -33 -48 -42 -93 -42 -213 0 -120 9
-165 42 -212 19 -28 19 -28 0 -55 -10 -16 -24 -43 -30 -62 -16 -45 -15 -253 0
-305 16 -55 99 -138 154 -154 30 -9 181 -12 556 -12 590 0 578 -2 651 80 56
62 67 102 67 242 0 118 -9 163 -42 211 -19 27 -19 27 0 55 33 47 42 92 42 212
0 120 -9 165 -42 213 -19 27 -19 27 0 55 33 47 42 92 42 210 0 140 -11 180
-67 242 -73 82 -60 80 -657 79 -349 -1 -533 -5 -556 -12z m1095 -172 c24 -23
25 -31 25 -135 0 -104 -1 -112 -25 -135 l-24 -25 -511 0 -511 0 -24 25 c-24
23 -25 31 -25 135 0 104 1 112 25 135 l24 25 511 0 511 0 24 -25z m0 -480 c24
-23 25 -31 25 -135 0 -104 -1 -112 -25 -135 l-24 -25 -511 0 -511 0 -24 25
c-24 23 -25 31 -25 135 0 104 1 112 25 135 l24 25 511 0 511 0 24 -25z m0
-480 c24 -23 25 -31 25 -135 0 -104 -1 -112 -25 -135 l-24 -25 -511 0 -511 0
-24 25 c-24 23 -25 31 -25 135 0 104 1 112 25 135 l24 25 511 0 511 0 24 -25z"
      />
      <path d="M1840 3040 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
      <path d="M2160 3040 l0 -80 320 0 320 0 0 80 0 80 -320 0 -320 0 0 -80z" />
      <path d="M1840 2560 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
      <path d="M2160 2560 l0 -80 320 0 320 0 0 80 0 80 -320 0 -320 0 0 -80z" />
      <path d="M1840 2080 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
      <path d="M2160 2080 l0 -80 320 0 320 0 0 80 0 80 -320 0 -320 0 0 -80z" />
      <path d="M3360 2960 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
      <path
        d="M4545 2776 c-51 -23 -108 -78 -120 -115 -6 -21 -9 -21 -576 -21
l-569 0 0 -80 0 -80 569 0 569 0 12 -30 c7 -17 32 -48 56 -69 100 -88 235 -80
327 18 91 97 91 225 0 322 -74 79 -173 100 -268 55z m150 -161 c50 -49 15
-135 -55 -135 -41 0 -80 39 -80 80 0 19 9 40 25 55 15 16 36 25 55 25 19 0 40
-9 55 -25z"
      />
      <path d="M3360 2160 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
      <path
        d="M3680 2160 l0 -80 259 0 258 0 73 -111 c41 -60 81 -114 91 -120 10
-5 63 -9 118 -9 92 0 100 -2 106 -21 11 -33 65 -88 109 -112 57 -30 155 -30
212 1 79 41 134 128 134 212 0 124 -116 240 -240 240 -81 0 -182 -62 -210
-130 -12 -29 -14 -30 -80 -30 l-67 0 -73 111 c-41 60 -81 114 -91 120 -10 5
-146 9 -308 9 l-291 0 0 -80z m1175 -185 c50 -49 15 -135 -55 -135 -41 0 -80
39 -80 80 0 41 39 80 80 80 19 0 40 -9 55 -25z"
      />
    </g>
  </svg>
);

export const ACTIVE_ICON = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="green" stroke="none">
      <path
        d="M1321 4784 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3660 0 -3746
45 -173 178 -307 350 -352 80 -20 310 -20 390 0 172 45 305 179 350 352 23 86
23 3660 0 3746 -45 173 -178 307 -350 352 -78 20 -318 20 -394 -1z"
      />
      <path
        d="M3401 4784 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3660 0 -3746
45 -173 178 -307 350 -352 80 -20 310 -20 390 0 172 45 305 179 350 352 23 86
23 3660 0 3746 -45 173 -178 307 -350 352 -78 20 -318 20 -394 -1z"
      />
    </g>
  </svg>
);

export const ASSET_TRANSFER_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none">
      <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
      <path
        fill="currentColor"
        d="M20 14a1 1 0 0 1 .117 1.993L20 16H6.414l2.293 2.293a1 1 0 0 1-1.32 1.497l-.094-.083l-3.83-3.83c-.665-.664-.239-1.783.663-1.871L4.241 14H20Zm-4.707-9.707a1 1 0 0 1 1.32-.083l.094.083l3.83 3.83c.665.664.239 1.783-.663 1.871l-.115.006H4a1 1 0 0 1-.117-1.993L4 8h13.586l-2.293-2.293a1 1 0 0 1 0-1.414Z"
      />
    </g>
  </svg>
);
export const ALARM_RULES_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M2 17v-2h9v2Zm0-8V7h9v2Zm12.4 11L13 18.6l2.6-2.6l-2.6-2.6l1.4-1.4l2.6 2.6l2.6-2.6l1.4 1.4l-2.6 2.6l2.6 2.6l-1.4 1.4l-2.6-2.6Zm2.15-9L13 7.45l1.4-1.4l2.125 2.125l4.25-4.25l1.4 1.425Z"
    />
  </svg>
);
export const INACTIVE_ICON = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red" stroke="none">
      <path
        d="M490 5111 c-69 -21 -121 -65 -156 -131 -18 -34 -19 -112 -19 -2420
l0 -2385 22 -40 c56 -105 184 -160 288 -123 61 22 4065 2336 4103 2372 54 50
76 101 76 176 0 75 -22 126 -76 176 -35 34 -4039 2349 -4098 2370 -39 14 -101
16 -140 5z"
      />
    </g>
  </svg>
);

export const REPORTS_ICON = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#6F7172" stroke="none">
      <path
        d="M4540 5104 c-201 -54 -335 -260 -300 -461 6 -34 13 -69 16 -77 3 -9
-131 -130 -395 -355 l-399 -341 -49 23 c-39 18 -66 22 -148 22 -91 0 -106 -3
-165 -31 -36 -17 -82 -45 -101 -62 l-36 -31 -345 141 -346 142 -6 60 c-8 87
-62 192 -129 252 -152 138 -369 148 -533 24 -98 -74 -151 -178 -162 -319 l-7
-83 -353 -209 -353 -210 -28 20 c-43 31 -123 59 -188 66 -167 19 -347 -84
-421 -242 -24 -52 -27 -69 -27 -168 0 -94 3 -117 23 -160 37 -80 73 -125 136
-172 202 -149 481 -90 610 129 30 52 58 172 54 231 l-3 40 354 210 354 209 61
-30 c84 -43 143 -55 234 -50 86 6 161 34 226 87 21 17 44 31 50 31 6 0 162
-62 346 -138 l335 -137 7 -59 c16 -126 104 -255 217 -314 301 -161 660 95 603
431 -10 57 -10 73 0 83 7 7 188 161 403 343 261 222 395 329 405 325 92 -35
186 -42 275 -18 116 30 212 110 268 223 30 62 32 72 32 176 0 104 -2 114 -32
176 -91 185 -288 275 -483 223z"
      />
      <path
        d="M4270 1800 l0 -1800 410 0 410 0 0 1800 0 1800 -410 0 -410 0 0
-1800z"
      />
      <path
        d="M1447 3093 c-4 -3 -7 -701 -7 -1550 l0 -1543 410 0 410 0 0 1550 0
1550 -403 0 c-222 0 -407 -3 -410 -7z"
      />
      <path
        d="M2860 1220 l0 -1220 410 0 410 0 0 1220 0 1220 -410 0 -410 0 0
-1220z"
      />
      <path
        d="M37 1923 c-4 -3 -7 -438 -7 -965 l0 -958 410 0 410 0 0 965 0 965
-403 0 c-222 0 -407 -3 -410 -7z"
      />
    </g>
  </svg>
);

export const IOT_ICON = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2195 5114 c-376 -39 -663 -120 -966 -275 -124 -63 -330 -195 -422
-270 -107 -88 -221 -195 -239 -224 -28 -47 -25 -126 8 -165 34 -39 74 -60 118
-60 55 0 76 12 171 100 314 289 668 469 1087 554 390 79 801 51 1182 -81 139
-47 380 -168 501 -249 109 -73 216 -158 310 -245 74 -69 93 -79 145 -79 96 0
163 82 145 177 -8 46 -98 141 -238 254 -365 294 -780 475 -1249 545 -99 14
-470 26 -553 18z"
      />
      <path
        d="M2235 4513 c-438 -38 -850 -219 -1169 -514 -71 -66 -96 -107 -96
-159 0 -60 52 -124 112 -141 62 -16 103 2 203 92 441 397 1047 528 1615 347
201 -64 416 -185 573 -324 146 -128 173 -140 251 -106 60 27 86 70 86 140 0
51 -2 56 -57 114 -229 240 -613 446 -968 517 -170 34 -390 48 -550 34z"
      />
      <path
        d="M2230 3910 c-283 -36 -552 -162 -756 -352 -73 -69 -92 -112 -75 -176
17 -60 81 -112 140 -112 47 0 77 16 154 81 166 144 326 219 539 255 307 51
626 -45 872 -264 72 -65 109 -79 170 -68 93 18 143 128 98 214 -39 73 -248
229 -405 302 -177 83 -330 119 -532 125 -77 2 -169 0 -205 -5z"
      />
      <path
        d="M2268 3300 c-74 -13 -146 -39 -233 -83 -112 -57 -244 -190 -302 -303
-64 -123 -87 -217 -87 -354 0 -213 67 -373 219 -525 100 -100 189 -154 325
-196 98 -31 290 -33 390 -5 256 70 452 260 532 516 19 63 23 96 23 210 0 114
-4 147 -23 210 -80 254 -277 447 -527 515 -70 19 -250 28 -317 15z m237 -306
c194 -50 335 -233 335 -434 0 -201 -141 -384 -335 -434 -254 -67 -513 104
-557 367 -51 312 249 582 557 501z"
      />
      <path
        d="M3765 3296 c-97 -42 -122 -167 -47 -239 41 -39 73 -47 188 -47 l104
0 0 -549 0 -548 23 -34 c61 -91 181 -89 251 5 21 27 21 40 24 577 l3 549 107
0 c85 0 114 4 142 19 55 29 75 64 75 131 0 67 -20 102 -75 131 -32 18 -63 19
-400 19 -281 -1 -372 -4 -395 -14z"
      />
      <path
        d="M561 3252 c-19 -9 -44 -30 -55 -45 -21 -28 -21 -40 -24 -645 -2 -422
0 -629 8 -654 16 -57 70 -93 136 -93 57 0 90 18 128 69 21 27 21 39 24 645 l2
617 -23 43 c-39 75 -122 101 -196 63z"
      />
      <path
        d="M1485 1838 c-41 -22 -76 -63 -86 -100 -17 -64 2 -107 76 -177 177
-165 399 -279 645 -332 147 -32 401 -32 540 -2 178 40 331 103 472 196 106 69
217 166 240 209 45 86 -5 196 -98 214 -61 11 -98 -3 -170 -68 -219 -194 -491
-292 -768 -274 -242 15 -449 99 -635 258 -45 37 -93 73 -107 78 -33 13 -84 12
-109 -2z"
      />
      <path
        d="M1076 1420 c-57 -18 -106 -83 -106 -140 0 -52 25 -93 96 -159 522
-482 1266 -644 1939 -420 279 93 558 262 744 453 51 52 55 59 59 113 5 75 -22
119 -90 147 -68 28 -108 13 -208 -76 -226 -203 -496 -342 -780 -402 -278 -59
-597 -39 -873 55 -201 68 -406 188 -562 329 -44 40 -89 78 -100 85 -28 19 -84
26 -119 15z"
      />
      <path
        d="M624 981 c-52 -32 -74 -68 -74 -123 0 -69 16 -94 125 -192 500 -452
1128 -684 1790 -662 248 8 416 34 640 98 237 68 499 190 705 328 191 128 401
316 421 377 31 96 -39 193 -141 193 -54 0 -70 -10 -160 -93 -84 -78 -262 -213
-361 -274 -384 -239 -877 -359 -1330 -325 -530 41 -990 239 -1374 592 -49 45
-100 86 -113 91 -38 15 -95 10 -128 -10z"
      />
    </g>
  </svg>
);
export const REACIVE_SEC_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="22"
    height="22" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M1161 4704 c-169 -45 -301 -180 -346 -351 -11 -41 -15 -111 -15 -245 l0 -188 -188 0 c-136 0 -204 -4 -247 -15 -172 -45 -305 -179 -350 -352 -23 -86 -23 -2700 0 -2786 45 -173 178 -307 350 -352 52 -13 269 -15 1795 -15 1911 0 1789 -4 1911 61 70 38 157 126 193 197 47 93 56 150 56 354 l0 188 188 0 c204 0 261 9 354 56 71 36 159 123 197 193 64 121 61 41 61 1511 0 1167 -2 1343 -15 1395 -45 172 -179 305 -352 350 -85 22 -3509 21 -3592 -1z m3565 -330 c15 -11 37 -33 48 -48 21 -27 21 -30 21 -1366 0 -1336 0 -1339 -21 -1366 -49 -66 -58 -69 -265 -72 l-189 -3 0 988 c0 1075 2 1041 -56 1155 -36 71 -123 159 -193 197 -121 64 -36 61 -1563 61 l-1388 0 0 174 c0 181 6 214 43 255 49 53 -49 50 1809 48 1727 -2 1727 -2 1754 -23z m-800 -800 c15 -11 37 -33 48 -48 21 -27 21 -30 21 -1366 0 -1336 0 -1339 -21 -1366 -11 -15 -33 -37 -48 -48 l-27 -21 -1739 0 -1739 0 -27 21 c-15 11 -37 33 -48 48 -21 27 -21 32 -24 1339 -1 722 0 1326 3 1344 7 40 49 91 90 109 25 11 336 13 1757 11 1727 -2 1727 -2 1754 -23z" />
      <path d="M2095 3106 c-37 -17 -70 -52 -84 -89 -7 -18 -11 -150 -11 -362 l0 -334 -349 -3 c-335 -3 -351 -4 -377 -24 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69 -134 26 -20 42 -21 376 -24 l349 -3 3 -349 c3 -334 4 -350 24 -376 39 -53 71 -69 134 -69 63 0 95 16 134 69 20 26 21 42 24 376 l3 349 349 3 c334 3 350 4 376 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -26 20 -42 21 -376 24 l-349 3 -3 349 c-3 334 -4 350 -24 376 -11 15 -32 37 -46 47 -34 25 -113 32 -153 13z" />
    </g>
  </svg>
);

export const FORM_RESPONSE_ICON = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M963 5105 c-170 -37 -302 -171 -338 -342 -22 -102 -22 -4304 0 -4406
36 -173 169 -306 342 -342 57 -12 307 -15 1600 -15 1089 0 1547 3 1585 11 171
36 310 175 347 347 8 38 11 518 11 1732 l0 1679 -557 3 -558 3 -51 23 c-64 29
-126 92 -156 157 -23 50 -23 50 -26 608 l-3 557 -1067 -1 c-859 0 -1079 -3
-1129 -14z m475 -2415 c128 -78 73 -280 -77 -280 -86 0 -151 64 -151 150 0 85
65 150 150 150 27 0 58 -8 78 -20z m2100 0 c96 -59 96 -201 0 -260 -32 -19
-52 -20 -828 -20 -776 0 -796 1 -828 20 -45 28 -72 76 -72 130 0 54 27 102 72
130 32 19 52 20 828 20 776 0 796 -1 828 -20z m-2100 -600 c128 -78 73 -280
-77 -280 -153 0 -209 200 -79 280 43 26 113 26 156 0z m2100 0 c96 -59 96
-201 0 -260 -32 -19 -52 -20 -828 -20 -776 0 -796 1 -828 20 -45 28 -72 76
-72 130 0 54 27 102 72 130 32 19 52 20 828 20 776 0 796 -1 828 -20z m-2100
-600 c128 -78 73 -280 -77 -280 -153 0 -209 200 -79 280 43 26 113 26 156 0z
m2100 0 c96 -59 96 -201 0 -260 -32 -19 -52 -20 -828 -20 -776 0 -796 1 -828
20 -45 28 -72 76 -72 130 0 54 27 102 72 130 32 19 52 20 828 20 776 0 796 -1
828 -20z"
      />
    </g>
  </svg>
);
export const FORM_VIEW_ICON = (<svg
  xmlns="http://www.w3.org/2000/svg"
  stroke='black'
  width="1.4em"
  height="1.4em"
  viewBox="0 0 24 24"
>
  <path
    fill="currentColor"
    d="M10.775 17.348H5.806a.281.281 0 1 0 0 .563h4.97a.281.281 0 1 0 0-.563zm7.32 0h-4.969a.281.281 0 1 0 0 .563h4.969a.281.281 0 0 0 0-.563zm-7.334-6.475H5.807a.281.281 0 1 0 0 .563h4.954a.281.281 0 1 0 0-.563zm7.32 0h-4.955a.281.281 0 1 0 0 .563h4.955a.281.281 0 0 0 0-.563zm.552-9.2h-4.341a2.404 2.404 0 0 0-4.58 0H5.366A3.097 3.097 0 0 0 2.27 4.769v16.134A3.097 3.097 0 0 0 5.367 24h13.266a3.097 3.097 0 0 0 3.096-3.097V4.77a3.097 3.097 0 0 0-3.096-3.096zm-8.705.563a.281.281 0 0 0 .281-.223a1.841 1.841 0 0 1 3.598 0a.281.281 0 0 0 .282.223h1.514V4.08a.845.845 0 0 1-.844.844H9.255a.845.845 0 0 1-.844-.844V2.236Zm11.238 18.667c0 1.4-1.134 2.534-2.533 2.534H5.367a2.534 2.534 0 0 1-2.533-2.534V4.77a2.533 2.533 0 0 1 2.533-2.533h2.48V4.08c0 .777.63 1.407 1.408 1.407h5.49c.777 0 1.407-.63 1.407-1.407V2.236h2.48c1.4 0 2.534 1.134 2.534 2.533z"
  />
</svg>)
export const MAINTENACE_FORM_RESPONSE_ICON = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="#6F7172" stroke="none">
      <path
        d="M47 454 c-4 -4 -7 -18 -7 -31 l0 -23 211 0 210 0 -3 28 -3 27 -201 3
c-110 1 -203 -1 -207 -4z m43 -24 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z m30 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m30 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10
0 6 5 10 10 10 6 0 10 -4 10 -10z"
      />
      <path
        d="M42 213 l3 -168 205 0 205 0 3 168 2 167 -210 0 -210 0 2 -167z m288
118 c0 -5 -7 -14 -15 -21 -8 -7 -15 -19 -15 -26 0 -19 24 -18 40 1 17 21 30
19 30 -5 0 -27 -35 -60 -63 -60 -14 0 -44 -22 -82 -60 -61 -62 -81 -71 -92
-44 -4 10 11 32 43 65 l49 50 -43 39 c-24 22 -40 45 -37 50 8 14 27 2 60 -37
32 -39 45 -42 45 -8 0 14 9 34 20 45 20 20 60 28 60 11z m10 -176 c26 -27 31
-38 22 -47 -9 -9 -20 -4 -47 22 -36 35 -45 60 -22 60 7 0 28 -16 47 -35z"
      />
    </g>
  </svg>
);


export const UTILISATION_ICON = (<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="25" height="25" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#6F7172" stroke="none">
    <path d="M2132 4848 c-166 -164 -191 -201 -170 -246 20 -42 52 -52 170 -52 l108 0 2 -1145 3 -1144 23 -23 23 -23 1189 -3 1190 -2 0 -114 c0 -99 3 -117 21 -140 16 -21 29 -26 62 -26 40 0 46 5 204 163 147 147 163 166 163 197 0 31 -16 50 -169 204 l-170 169 -38 -6 c-64 -11 -73 -31 -73 -168 l0 -119 -70 0 -70 0 0 489 c0 479 0 489 -21 515 l-20 26 -239 0 c-219 0 -240 -1 -261 -19 l-24 -19 -3 -496 -3 -496 -69 0 -70 0 0 1166 0 1166 -29 29 -29 29 -227 0 -227 0 -29 -29 -29 -29 0 -1166 0 -1166 -75 0 -75 0 0 734 c0 707 -1 735 -19 757 -19 24 -22 24 -237 27 -120 2 -229 1 -241 -3 -13 -3 -32 -14 -43 -25 -20 -20 -20 -36 -20 -755 l0 -735 -70 0 -70 0 0 1089 0 1089 120 4 c106 3 122 5 141 24 14 14 21 34 21 60 0 35 -9 47 -164 201 -147 146 -168 163 -198 163 -30 0 -50 -17 -188 -152z" />
    <path d="M1651 3954 c-539 -78 -1034 -393 -1333 -848 -116 -176 -212 -394 -261 -591 -46 -185 -52 -239 -52 -480 1 -212 3 -240 28 -360 94 -442 315 -814 654 -1103 130 -110 375 -264 393 -246 5 5 217 448 470 984 l460 974 -2 824 -3 824 -24 19 c-20 16 -39 19 -130 18 -58 -1 -148 -7 -200 -15z" />
    <path d="M2096 2098 c-8 -18 -206 -438 -440 -933 -234 -495 -424 -905 -422 -910 5 -14 187 -70 306 -95 870 -177 1743 260 2126 1065 115 242 184 537 184 785 0 62 -3 74 -25 95 l-24 25 -845 0 -846 0 -14 -32z" />
  </g>
</svg>)

export const SFR_ICON = (<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="25" height="25" viewBox="0 0 256.000000 256.000000" preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill="#6F7172" stroke="none">
    <path d="M1100 2460 c-675 -6 -640 -2 -697 -77 l-28 -36 0 -1062 0 -1061 25 -37 c15 -22 46 -48 74 -62 l50 -25 751 0 751 0 50 25 c28 14 59 40 74 62 l25 37 3 895 2 896 -228 228 c-125 125 -232 226 -237 225 -6 -2 -282 -5 -615 -8z m572 -228 c3 -138 5 -151 27 -184 13 -19 40 -46 59 -59 33 -22 46 -24 189 -27 l153 -3 -2 -856 -3 -855 -33 -29 -32 -29 -755 0 -755 0 -32 29 -33 29 -3 1021 c-2 914 -1 1025 14 1052 30 59 26 58 641 59 l561 0 4 -148z m276 -190 c-107 -4 -139 3 -169 34 -22 23 -24 35 -27 137 l-3 112 141 -140 140 -140 -82 -3z" />
    <path d="M640 2225 c-7 -8 -10 -25 -6 -40 l6 -25 433 2 432 3 0 35 0 35 -426 3 c-369 2 -428 0 -439 -13z" />
    <path d="M642 1968 c-18 -18 -14 -56 7 -68 11 -6 87 -10 175 -10 l156 0 0 26 c0 14 5 34 10 45 10 18 6 19 -163 19 -121 0 -177 -4 -185 -12z" />
    <path d="M1090 1748 c0 -118 8 -230 30 -413 17 -137 33 -262 36 -277 l6 -28 113 0 113 0 6 28 c26 129 68 548 70 689 l1 163 -187 0 -188 0 0 -162z" />
    <path d="M638 1709 c-24 -13 -23 -36 1 -49 11 -6 89 -10 180 -10 l161 0 0 35 0 35 -162 0 c-92 0 -171 -5 -180 -11z" />
    <path d="M1570 1685 l0 -35 158 0 c122 0 163 3 178 14 15 12 17 18 8 33 -10 16 -30 18 -178 21 l-166 2 0 -35z" />
    <path d="M629 1444 c-11 -14 -10 -19 10 -35 21 -17 42 -19 192 -19 l169 0 0 35 0 35 -179 0 c-152 0 -181 -2 -192 -16z" />
    <path d="M1550 1425 l0 -35 169 0 c150 0 171 2 192 19 20 16 21 21 10 35 -11 14 -40 16 -192 16 l-179 0 0 -35z" />
    <path d="M632 1178 c-18 -18 -15 -36 7 -48 12 -6 98 -10 212 -10 l191 0 -6 23 c-3 12 -6 28 -6 35 0 17 -380 18 -398 0z" />
    <path d="M1520 1178 c0 -7 -3 -23 -6 -35 l-6 -23 186 0 c186 0 226 6 226 35 0 29 -39 35 -219 35 -138 0 -181 -3 -181 -12z" />
    <path d="M1100 780 l0 -160 175 0 175 0 0 160 0 160 -175 0 -175 0 0 -160z" />
    <path d="M629 914 c-9 -11 -10 -20 -2 -32 9 -15 35 -17 187 -20 l176 -2 0 35 0 35 -174 0 c-146 0 -176 -2 -187 -16z" />
    <path d="M1560 895 l0 -35 161 0 c160 0 199 7 199 35 0 28 -39 35 -199 35 l-161 0 0 -35z" />
    <path d="M624 645 c-4 -8 -4 -22 0 -30 4 -13 35 -15 186 -15 l180 0 0 30 0 30 -180 0 c-151 0 -182 -2 -186 -15z" />
    <path d="M1560 630 l0 -30 169 0 c138 0 171 3 181 15 10 12 10 18 0 30 -10 12 -43 15 -181 15 l-169 0 0 -30z" />
    <path d="M627 393 c-16 -15 -5 -44 19 -53 17 -7 248 -9 635 -8 557 3 610 4 625 20 14 14 15 20 4 32 -11 14 -86 16 -645 16 -347 0 -635 -3 -638 -7z" />
  </g>
</svg>) 
