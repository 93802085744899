import { useContext, useMemo } from "react";
import { ActiveAlarmsData } from "../../Components/Alarms/ActiveAlarms";

export function useGetCustomersAccessList() {
  const { newUserData } = useContext(ActiveAlarmsData);
  const data = newUserData?.customerDetails ?? [];
  return useMemo(
    () => ({
      data,
    }),
    [newUserData.customerDetails],
  );
}
