import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  permittedCustomersSelector,
  selectedCustomersSelector,
  setSelectedCustomer,
  setSelectedCustomers,
  singleSelectedCustomerSelector,
} from "../../../features/authentication/auth-slice";
import { Fragment, SyntheticEvent, useContext, useEffect, useState } from "react";
import { ActiveAlarmsData } from "../../Alarms/ActiveAlarms";

import { useTheme, styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DoneIcon from "@mui/icons-material/Done";
import Autocomplete, { AutocompleteChangeReason, autocompleteClasses } from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";

type ActiveAlarmsContextProps = {
  updateCustomerIdList?: () => void;
  updateCustomerId?: () => void;
};

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

interface DropdownOptionType {
  customerName: string;
  customerId: string;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === "light" ? " #eaecef" : "#30363d"}`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"}`,
  borderRadius: 6,
  width: 250,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  borderBottom: `1px solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"}`,
  "& input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"}`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${theme.palette.mode === "light" ? "rgba(3, 102, 214, 0.3)" : "rgb(12, 45, 107)"}`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

export const StationsSelect = () => {
  const location = useLocation();
  const isSingleSelect = location.pathname.includes("yard-view");
  const permittedCustomers = useSelector(permittedCustomersSelector) ?? [];
  const selectedCustomers = useSelector(selectedCustomersSelector);
  const selectedCustomer = useSelector(singleSelectedCustomerSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const initialValue = isSingleSelect ? selectedCustomer : selectedCustomers;

  const [pendingValue, setPendingValue] = useState<DropdownOptionType[]>(initialValue);
  const dispatch = useDispatch();
  const { updateCustomerIdList, updateCustomerId }: ActiveAlarmsContextProps = useContext(ActiveAlarmsData);

  useEffect(() => {
    setPendingValue(initialValue);
  }, [isSingleSelect]);

  function getValue() {
    if (isSingleSelect && Array.isArray(pendingValue)) return initialValue;
    if (!isSingleSelect && !Array.isArray(pendingValue)) return initialValue;
    return pendingValue;
  }

  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPendingValue(initialValue);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);

    if (!isSingleSelect) {
      dispatch(setSelectedCustomers(pendingValue));
      const listStations: string[] = [];
      const customerIdList: string[] = [];
      pendingValue.forEach((customer) => {
        listStations.push(customer.customerName);
        customerIdList.push(customer.customerId);
      });
      localStorage.setItem("STN", listStations.toString());
      localStorage.setItem("customerIdList", JSON.stringify(customerIdList));
      updateCustomerIdList?.();
    }
  };

  function handleFilterChange(event: SyntheticEvent<Element, Event>, newValue: any, reason: AutocompleteChangeReason) {
    if (event.type === "keydown" && (event as React.KeyboardEvent).key === "Backspace" && reason === "removeOption") {
      return;
    }
    if (newValue.length === 0) return;
    setPendingValue(newValue);
    if (isSingleSelect) {
      localStorage.setItem("customerId", newValue.customerId);
      updateCustomerId?.();
      dispatch(setSelectedCustomer(newValue));
      return;
    }
  }

  function handleSelectAll() {
    if (isSingleSelect) return;
    if (pendingValue?.length === permittedCustomers.length) return;
    setPendingValue(permittedCustomers);
    dispatch(setSelectedCustomers(permittedCustomers));

    const listStations: string[] = [];
    const customerIdList: string[] = [];
    permittedCustomers.forEach((customer: DropdownOptionType) => {
      listStations.push(customer.customerName);
      customerIdList.push(customer.customerId);
    });
    localStorage.setItem("STN", listStations.toString());
    localStorage.setItem("customerIdList", JSON.stringify(customerIdList));
    updateCustomerIdList?.();
  }

  function handleDeselectAll() {
    if (isSingleSelect) return;
    if (pendingValue?.length === 1) return;
    const _newValue = [selectedCustomers[0]];
    setPendingValue(_newValue);
    dispatch(setSelectedCustomers(_newValue));

    const listStations: string[] = [];
    const customerIdList: string[] = [];
    _newValue.forEach((customer) => {
      listStations.push(customer.customerName);
      customerIdList.push(customer.customerId);
    });
    localStorage.setItem("STN", listStations.toString());
    localStorage.setItem("customerIdList", JSON.stringify(customerIdList));
    updateCustomerIdList?.();
  }

  const open = Boolean(anchorEl);
  const id = open ? "station-popover--button" : undefined;

  return (
    <Fragment>
      <Box>
        <button className="button--outlined" aria-describedby={id} onClick={handleClick}>
          <span>Stations</span>
        </button>
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={{
                borderBottom: `1px solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"}`,
                padding: "8px 10px",
                fontWeight: 600,
              }}
            >
              View data for Selected Stations
            </Box>

            <Autocomplete
              open
              multiple={!isSingleSelect}
              onClose={(_, reason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              value={getValue()}
              onChange={handleFilterChange}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No labels"
              isOptionEqualToValue={(option, value) => option.customerId === value.customerId}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ backgroundColor: selected ? "rgb(243, 244, 246)" : "transparent" }}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
                    style={{
                      visibility: selected ? "visible" : "hidden",
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      "& span": {
                        color: theme.palette.mode === "light" ? "#586069" : "#8b949e",
                      },
                    }}
                  >
                    {option.customerName}
                  </Box>
                </li>
              )}
              options={permittedCustomers}
              getOptionLabel={(option) => option.customerName}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Filter Stations"
                />
              )}
            />
            {!isSingleSelect && (
              <div className="flex justify-between gap-2 p-2">
                <button onClick={handleDeselectAll} className="button--outlined flex-1">
                  <span>Deselect All</span>
                </button>
                <button onClick={handleSelectAll} className="button--outlined flex-1">
                  <span>Select All</span>
                </button>
              </div>
            )}
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </Fragment>
  );
};

export default StationsSelect;
