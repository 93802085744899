import React, { useEffect, useMemo, useState } from "react";
import { Chart } from "react-chartjs-2";
import { useImmer } from "use-immer";
import "./DevTypeStatusCountAreaChart.scss";
import NoData from "../../../../Utils/NoDataComponent.js/NoData";

let delayed;

// const LINE_OPTIONS = {
//   responsive: true,
//   aspectRatio: 3.5 / 1,
//   interaction: {
//     mode: "nearest",
//     axis: "x",
//     intersect: false,
//     callbacks: {
//       intersect: function (a) { },
//     },
//   },
//   // scales: {
//   //   yAxes: [{
//   //     ticks: {
//   //       fontSize: 20
//   //     }
//   //   }],
//   //   xAxes: [{
//   //     ticks: {
//   //       // fontSize: 20,
//   //       callbacks: () => {
//   //         return 'gh'
//   //       }
//   //     }
//   //   }]
//   // },
//   scales: {
//     x: {
//       ticks: {
//         // Include a dollar sign in the ticks
//         callback: function (value, index, ticks) {
//           return value;
//         }
//       }
//     }
//   },
//   plugins: {

//     legend: {
//       // display: false,
//       display: true,
//       position: "bottom",
//       maxHeight: 25,
//       maxWidth: 5,
//       labels: {
//         // This more specific font property overrides the global property
//         font: {
//           size: 12
//         }
//       }
//     },
//     datalabels: {
//       color: "white",
//       formatter: function (count, chart) {
//         return "";
//       },
//     },
//     tooltip: {
//       yAlign: "center",
//       xAlign: "left",
//       responsive: true,
//     },

//     filler: {
//       propagate: true,
//     },
//   },

// };

const labels = [];
const InitialChartData = {
  labels: labels,
  datasets: [
    {
      label: "healthy",
      data: [],
      fill: true,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
};

function DevTypeStatusCountAreaChart({ COUNT_DATA }) {
  const [chartData, setChartData] = useImmer(InitialChartData);

  useEffect(() => {
    if (COUNT_DATA.length > 0) {
      let healthyCounts = [];
      let warniingCounts = [];
      let criticalCount = [];
      let maintenanceCount = [];
      let newLabels = [];
      COUNT_DATA.forEach((row) => {
        healthyCounts.push(row.healthy);
        warniingCounts.push(row.warning);
        criticalCount.push(row.critical);
        maintenanceCount.push(row.maintenance);
        newLabels.push(row?.deviceType);
      });
      let newDataSets = [
        {
          label: "Healthy",
          data: healthyCounts,
          // data: healthyCounts.sort(function (b, a) {
          //   return a - b;
          // }),
          fill: true,
          backgroundColor: "#0080003b",
          borderColor: "green",
          borderWidth: 1.5,
          tension: 0.1,
          pointRadius: 0,
          pointHoverRadius: 4,
        },
        {
          label: "Warning",
          data: warniingCounts,
          // data: warniingCounts.sort(function (b, a) {
          //   return a - b;
          // }),
          fill: true,
          backgroundColor: "#ffa50052",
          borderColor: "orange",
          borderWidth: 1.5,
          tension: 0.1,
          pointRadius: 0,
          pointHoverRadius: 4,
        },
        {
          label: "Critical",
          data: criticalCount,
          // data: criticalCount.sort(function (b, a) {
          //   return a - b;
          // }),
          fill: true,
          borderColor: "red",
          backgroundColor: "#ff000042",
          borderWidth: 1.5,
          tension: 0.1,
          pointRadius: 0,
          pointHoverRadius: 4,
        },
        {
          label: "Maintenance",
          data: maintenanceCount,
          // data: criticalCount.sort(function (b, a) {
          //   return a - b;
          // }),
          fill: true,
          borderColor: "brown",
          backgroundColor: "#ff000042",
          borderWidth: 1.5,
          tension: 0.1,
          pointRadius: 0,
          pointHoverRadius: 4,
        },
      ];

      setChartData((prev) => {
        // newLabels?.sort()
        prev.labels = newLabels;
        // prev.labels =
        prev.datasets = newDataSets;
      });
    } else {
      setChartData(InitialChartData);
    }
  }, [COUNT_DATA]);

  let LINE_OPTIONS = useMemo(() => {
    let xAxisLabelTicks = chartData.labels
    // console.log('chartdata in memo', chartData.labels)
    return {
      responsive: true,
      aspectRatio: 3.5 / 1,
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
        callbacks: {
          intersect: function (a) { },
        },
      },
      // scales: {
      //   yAxes: [{
      //     ticks: {
      //       fontSize: 20
      //     }
      //   }],
      //   xAxes: [{
      //     ticks: {
      //       // fontSize: 20,
      //       callbacks: () => {
      //         return 'gh'
      //       }
      //     }
      //   }]
      // },
      scales: {
        x: {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return xAxisLabelTicks[value].length > 11 ? xAxisLabelTicks[value].substring(0, 12) + '...' : xAxisLabelTicks[value]

            }
          }
        }
      },
      plugins: {

        legend: {
          // display: false,
          display: true,
          position: "bottom",
          maxHeight: 25,
          maxWidth: 5,
          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 12
            }
          }
        },
        datalabels: {
          color: "white",
          formatter: function (count, chart) {
            return "";
          },
        },
        tooltip: {
          yAlign: "center",
          xAlign: "left",
          responsive: true,
        },

        filler: {
          propagate: true,
        },
      },

    }
  }, [chartData])

  return (
    // <div className="type-status-count-container card">
    //   <div className="header">
    //     <div>Asset-Type Health Status</div>
    //   </div>
    //   <div className="chart-container">
    <>
      {chartData.labels.length > 0 ? (
        <Chart className="type-status-count-chart" type="line" data={chartData} options={LINE_OPTIONS} />
      ) : (
        <NoData msg={`Not enough data`} />
      )}
    </>
    //   </div>
    // </div>
  );
}

export default DevTypeStatusCountAreaChart;
