import { useEffect, useRef, useState } from "react";
import { ALARM_SOCKET, ALARM_WEBSOCKET_PATH, APP_BASE_URL, APP_BASE_URL_ALARM_SOCKET, DASHBOARD_MAINTENANCE_ROSTER, tsToDateFormatter } from "../../Common/EndPoints";
import AlarmCategoryFailureChart from "./DASHBOARD COMPONENTS/ALARM CATEGORY COUNT/AlarmCategoryFailureChart";
import {
  DASHBOARD_MAINTENANCE_ROASTER,
  DASHBOARD_MAINTENANCE_ROASTER_COLUMNS,
  DEVICETYPE_STATUS_COUNT_COLUMNS,
  dynamicSort,
  getFormattedDate,
  STATION_STATUS_COUNT_COLUMNS,
} from "./DASHBOARD COMPONENTS/Constants";
import DevTypeStatusCountAreaChart from "./DASHBOARD COMPONENTS/DEV TYPE STATUS COUNT CHART/DevTypeStatusCountAreaChart";
import MaintenanceDashBoard from "./DASHBOARD COMPONENTS/MAINTENANCE DASHBOARD/MaintenanceDashBoard";
import StationStatusCountCharts from "./DASHBOARD COMPONENTS/STATION STATUS COUNT CHARTS/StationStatusCountCharts";
import StatusCountTable from "./DASHBOARD COMPONENTS/StatusCount Table/StatusCountTable";
import "./MigratedDAshBoard.scss";
import DashboardCard from "./DASHBOARD COMPONENTS/DashboardCard/DashboardCard";
import DashboardMaintenanceRoster from "./DASHBOARD COMPONENTS/DASHBOARD MAINTENANCE ROSTER/DashboardMaintenanceRoster";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectedCustomersIdsSelector } from "../../features/authentication/auth-slice";

function MigratedDashBoard() {
  const deviceTypeWiseStatusSocket = useRef(null);
  const stationWiseStatusSocket = useRef(null);
  // const { customerIdList } = useContext(ActiveAlarmsData);
  const customerIdList = useSelector(selectedCustomersIdsSelector);
  // const customerList = useRef(customerIdList);
  const [deviceTypeStatusCount, setDeviceTypeStatusCount] = useState([]);
  const [stationStatusCount, setStationStatusCount] = useState([]);
  const [dashboardMaintRoster, setDashboardMaintRoaster] = useState([]);

  useEffect(() => {
    // if (customerIdList && customerIdList.length > 0) {
    if (customerIdList && customerIdList.length > 0) {
      getDeviceTypeWiseCount("DEVICETYPE_STATUS_COUNT", customerIdList);
      getStationStatusCount("CUSTOMER_STATUS_COUNT", customerIdList);
      getDashboardMaintRoster(customerIdList);
    }
    return () => {
      if (deviceTypeWiseStatusSocket?.current) {
        deviceTypeWiseStatusSocket.current.close();
        stationWiseStatusSocket.current.close();
      }
    };
  }, [customerIdList.length]);

  function sendWebSocketMessage() {
    let idMessage = { customer_ids: customerIdList.toString(), is_active: "true" };
    deviceTypeWiseStatusSocket.current.send(JSON.stringify(idMessage));
    stationWiseStatusSocket.current.send(JSON.stringify(idMessage));
  }
  useEffect(() => {
    // stationWiseStatusSocket.current.onopen = ()=>{
    //   sendWebSocketMessage(idMessage)
    // }

    if (deviceTypeWiseStatusSocket?.current?.readyState > 0 && customerIdList.length > 0) {
      sendWebSocketMessage();
    }
  }, [customerIdList]);

  const getStationStatusCount = (socketType, customerIdList) => {
    let socketUrl = `${APP_BASE_URL_ALARM_SOCKET + ALARM_WEBSOCKET_PATH}/${socketType}?is_active=true&customer_ids=${customerIdList}`;
    stationWiseStatusSocket.current = new WebSocket(socketUrl);
    stationWiseStatusSocket.current.onopen = () => {
      sendWebSocketMessage();
      // let idMessage = { customer_ids: customerIdList.toString() };
      // deviceTypeWiseStatusSocket.current.send(JSON.stringify(idMessage));
      // stationWiseStatusSocket.current.send(JSON.stringify(idMessage));
    };
    // stationWiseStatusSocket.current.onclose = (a) => { };
    stationWiseStatusSocket.current.onmessage = (res) => {
      let RESPONSE = JSON.parse(res.data);
      RESPONSE = RESPONSE.map((row, i) => {
        let warningCount = row.status.warning;
        let criticalCount = row.status.critical;
        let maintenanceCount = row.status.maintenance;
        let totalCount = row.status.total;
        return {
          id: row.customer_name,
          // key:i,
          healthy: totalCount - (warningCount + criticalCount + maintenanceCount),
          warning: warningCount,
          critical: criticalCount,
          maintenance: maintenanceCount,
          station: row.customer_name,
          customerId: row.customer_id,
        };
      });
      RESPONSE.sort(dynamicSort("station"));
      setStationStatusCount(RESPONSE);
    };
  };

  const getDeviceTypeWiseCount = (socketType) => {
    const socketUrl = `${APP_BASE_URL_ALARM_SOCKET + ALARM_WEBSOCKET_PATH}/${socketType}?is_active=true&customer_ids=${customerIdList}`;
    deviceTypeWiseStatusSocket.current = new WebSocket(socketUrl);
    deviceTypeWiseStatusSocket.current.onopen = (a) => { };
    deviceTypeWiseStatusSocket.current.onclose = (a) => { };
    deviceTypeWiseStatusSocket.current.onmessage = (res) => {
      let RESPONSE = JSON.parse(res.data);

      RESPONSE = RESPONSE.map((row, i) => {
        let warningCount = row.status.warning;
        let criticalCount = row.status.critical;
        let totalCount = row.status.total;
        let maintenanceCount = row.status.maintenance;
        return {
          id: row.device_type,
          healthy: totalCount - (warningCount + criticalCount + maintenanceCount),
          warning: warningCount,
          critical: criticalCount,
          maintenance: maintenanceCount,
          deviceType: row.device_type_label,
          typeId: row.device_type_id,
        };
      });
      RESPONSE.sort(dynamicSort("deviceType"));

      setDeviceTypeStatusCount(RESPONSE);
    };
  };
  const getDashboardMaintRoster = (customerIds) => {
    let USER_ID = localStorage.getItem("userId");
    let url = `${APP_BASE_URL}${DASHBOARD_MAINTENANCE_ROSTER}`;
    let params = {
      days: 7,
      customerIds: customerIds.toString(),
      userId: USER_ID,
    };
    axios
      .get(url, { params })
      .then((res) => {
        let RESPONSE = res?.data?.data;
        let tableRows = [];
        RESPONSE?.forEach((val, i) => {
          let station = val.station;

          val.assetTypeWise.forEach((assetWise, j) => {
            let currTs = new Date().getTime();
            let colesetNextMaintTS =
              assetWise.closestNextMaintenaceDate < currTs ? currTs : assetWise.closestNextMaintenaceDate;
            let row = {
              ...assetWise,
              station: station,
              key: station + j,
              id: station + j,
              closestNextMaintenaceDate: moment(colesetNextMaintTS).format("DD-MM-YYYY"),
            };

            tableRows.push(row);
          });
        });

        setDashboardMaintRoaster([...tableRows]);
      })
      .catch(() => {
        setDashboardMaintRoaster([]);
      });
  };
  return (
    <div className="dashboard-container">
      <DashboardCard
        cardId={"type-status-count-container"}
        cardName={"Asset Type Health Status"}
        initialViewMode={"chart"}
        chartChild={<DevTypeStatusCountAreaChart COUNT_DATA={deviceTypeStatusCount} />}
        gridChild={
          <StatusCountTable
            id="Status Count by Device Type"
            classId={"devType-count-table card"}
            rows={deviceTypeStatusCount}
            column={DEVICETYPE_STATUS_COUNT_COLUMNS}
          />
        }
      />
      <DashboardCard
        cardId={"station-count-container"}
        cardName={"Asset Health Status"}
        initialViewMode={"singleChild"}
        singleChildType={"dashboardChart"}
        singleChild={<StationStatusCountCharts stationStatusCounts={stationStatusCount} />}
      />
      <DashboardCard
        cardId={"alarm-category-failure-count-container"}
        cardName={"Alarm Category Failure Chart"}
        initialViewMode={"singleChild"}
        singleChildType={"dashboardChart"}
        singleChild={<AlarmCategoryFailureChart />}
      />
      <DashboardCard
        cardId={"station-wise-count"}
        cardName={"Station Wise Asset Failure"}
        initialViewMode={"singleChild"}
        singleChildType={"dashboardTable"}
        singleChild={
          <StatusCountTable
            classId={"station-count-table card"}
            id="Status Count by Station"
            rows={stationStatusCount}
            column={STATION_STATUS_COUNT_COLUMNS}
          />
        }
      />
      <DashboardCard
        cardId={"dashboard-maintenance"}
        cardName={"Predictive Maintenance in Next(7 days)"}
        initialViewMode={"singleChild"}
        singleChildType={"dashboardTable"}
        singleChild={
          <DashboardMaintenanceRoster
            classId={"dashboard-maintenance-roster card"}
            id="Predictive Next Maintenance"
            rows={dashboardMaintRoster}
            column={DASHBOARD_MAINTENANCE_ROASTER_COLUMNS}
          />
        }
      />
      <DashboardCard
        cardId={"periodic-maintenance-dashboard"}
        cardName={"Periodic Maintenance Dashboard"}
        initialViewMode={"singleChild"}
        singleChildType={"dashboardChart"}
        singleChild={<MaintenanceDashBoard />}
      />

      {/* <DashboardCard
        cardId={'maintenace-dashBoard-container'}
        cardName={'Alarm Category Failure Chart'}
        initialViewMode={'singleChild'}
        singleChildType={'dashboardChart'}
        singleChild={<MaintenanceDashBoard />}
      /> */}
      {/* <DashboardCard><StationStatusCountCharts stationStatusCounts={stationStatusCount} /></DashboardCard>
      <DashboardCard><StatusCountTable
        classId={'station-count-table card'}
        id="Status Count by Station"
        rows={stationStatusCount}
        column={STATION_STATUS_COUNT_COLUMNS}
      /></DashboardCard>
      <DashboardCard><DevTypeStatusCountAreaChart COUNT_DATA={deviceTypeStatusCount} /></DashboardCard>
      <DashboardCard>       <StatusCountTable
        id="Status Count by Device Type"
        classId={'devType-count-table card'}
        rows={deviceTypeStatusCount}
        column={DEVICETYPE_STATUS_COUNT_COLUMNS}
      /></DashboardCard>
      <DashboardCard><AlarmCategoryFailureChart /></DashboardCard>
      <DashboardCard>         <MaintenanceDashBoard /></DashboardCard> */}
      {/* <div className="hor hor-1">
        <div className="card ver-1">
          <StationStatusCountCharts stationStatusCounts={stationStatusCount} />
        </div>
        <div className="card ver-2 table">
          <StatusCountTable
            id="Status Count by Station"
            rows={stationStatusCount}
            column={STATION_STATUS_COUNT_COLUMNS}
          />
        </div>
      </div>
      <div className="hor hor-2">
        <div className="card ver-1">
          <DevTypeStatusCountAreaChart COUNT_DATA={deviceTypeStatusCount} />
        </div>
        <div className="card ver-2 table">
          <StatusCountTable
            id="Status Count by Device Type"
            rows={deviceTypeStatusCount}
            column={DEVICETYPE_STATUS_COUNT_COLUMNS}
          />
        </div>
      </div>
      <div className="hor hor-3">
        <div className="card ver-1">
          <AlarmCategoryFailureChart />
        </div>
        <div className="card ver-2">
          <MaintenanceDashBoard />
        </div>
      </div> */}
    </div>
  );
}

export default MigratedDashBoard;
