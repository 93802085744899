import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import NoData from '../../../Utils/NoDataComponent.js/NoData'
import './LineChart.scss'
import { Colors } from '../../MIGRATED DASHBOARD/DASHBOARD COMPONENTS/Constants'
import FullscreenIcon from "@mui/icons-material/Fullscreen";



let LINE_OPTIONS = (chartType) => {
    // console.log(chartType==='pie')
    return ({
        responsive: true,
        // aspectRatio: 4.8 / 1,
        // aspectRatio: 3.2 / 1,
        maintainAspectRatio: false,
        interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
            callbacks: {
                intersect: function (a) {
                },
            },
        },

        plugins: {
            legend: {
                // display: false,
                display: chartType === 'pie',
                position: chartType === 'pie' ? 'left' : '',
                // maxHeight: 25,
                // maxWidth: 5,
            },
            datalabels: {
                color: "white",
                formatter: function (count, chart) {
                    let totalCount = chart.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
                    let countPer = (count * 100) / totalCount;
                    return (countPer > 3 && chartType === 'pie') ? count : ''
                    // return count
                }
            },
            tooltip: {
                yAlign: "center",
                xAlign: "left",
                responsive: true,
            }
            ,
            scales: {
                xAxes: {
                    ticks: {
                        maxTicksLimit: 1
                    }
                },
                yAxes: [{
                    gridLines: {
                        display: false
                    }
                }]
            },
            filler: {
                propagate: true
            }

        }
    })
}


let initialChartData = {
    labels: ['a'],
    datasets: [{
        label: 'data',
        data: [0, 0],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
    }]
};

const labelKeys = {
    station: 'stn_code',
    alarmType: 'type',
    category: 'category',
    devices: 'device_type_label',
    gearType: 'device_type_label'

}

function LineChart({ data, dependsOn, dependsOnSignleData }) {
    const [chartData, setChartData] = useState(initialChartData)
    const [chartType, setChartType] = useState('line')
    const [fullScreen, setFullScreen] = useState(false)

    //     useLayoutEffect(()=>{

    // if(dependsOn==='devices'){
    // LINE_OPTIONS = {...LINE_OPTIONS,aspectRatio: 4.8 / 1,}
    // }else{
    //     LINE_OPTIONS=   {...LINE_OPTIONS,aspectRatio: 2.8 / 1,}
    // }
    //     },[dependsOn])

    useEffect(() => {
        if (data.length > 0) {
            let labelCountObj = {}
            data.forEach((row, i) => {
                if (labelCountObj[row[labelKeys[dependsOn]]]) {
                    labelCountObj[row[labelKeys[dependsOn]]] = Number(labelCountObj[row[labelKeys[dependsOn]]]) + Number(row.cnt)
                } else {
                    labelCountObj[row[labelKeys[dependsOn]]] = Number(row.cnt)
                }
            })
            let labels = Object.keys(labelCountObj)
            if (labels.length > 1 || dependsOn !== 'station' || dependsOnSignleData === 'devices') {
                setChartType('line')
                let newChartData = {
                    labels: labels,
                    datasets: [{
                        label: 'Counts',
                        data: Object.values(labelCountObj),
                        fill: false,
                        borderWidth: 1.5,
                        borderColor: dependsOn === 'station' ? 'blue' : 'red',
                        pointHoverBackgroundColor: dependsOn === 'station' ? 'blue' : 'red',
                        pointRadius: 3.3,
                        tension: 0.3,
                    }]
                }

                setChartData(newChartData)
            } else {
                setChartType('pie')

                let labelsSingleData = []
                let countsSignleData = []
                let backColorSignleData = []
                data.forEach((row, i) => {
                    labelsSingleData.push(row[labelKeys[dependsOnSignleData]])
                    countsSignleData.push(row.cnt)
                    backColorSignleData.push(Colors[i])
                })
                let newPieDataSignleData = {
                    labels: labelsSingleData,
                    datasets: [{
                        label: 'Maintenance Count',
                        data: countsSignleData,
                        backgroundColor: backColorSignleData,
                        hoverOffset: 4
                    }]
                };
                setChartData(newPieDataSignleData)
            }

        }
    }, [data])
    const handleFullSCreen = () => {
        setFullScreen(!fullScreen)
    }
    return (

        <div className={`eachStnChartComponent ${fullScreen ? 'fullScreen' : ''}`}>
            {(data && data?.length > 0) ? (<>    <div className='header'>
                <div className='heading'>{`Total Event Count Of Each ${dependsOn}`} </div>
                <FullscreenIcon sx={{ color: '#5398DD', cursor: 'pointer' }} onClick={() => handleFullSCreen()} />
            </div>
                <div className='chartContainer'>
                    <Chart
                        className="type-status-count-chart"
                        type={chartType}
                        data={chartData}
                        options={LINE_OPTIONS(chartType)}
                    />
                </div></>) : (<NoData msg={`Data for Total Event count of each  ${dependsOn} is not avalible`} />)}

        </div>
    )
}

export default LineChart