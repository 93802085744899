import React, { useEffect, useRef, useState } from "react";
import "./SfrAttachPopup.scss";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { APP_BASE_URL_ALARM, GENERIC_ATTACHMENT } from "../../../../Common/EndPoints";
import axios from "axios";
import { useImmer } from "use-immer";
import { downloadFile, formatDate } from "../../../../Utils/Utils";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';


import IconButton from '@mui/material/IconButton';
import ConfirmationModal from "../../../../Modal/ConfirmationModal";
import { toast } from "react-hot-toast";
import Loader from "../../../Loader/Loader";
import { isValidElementType } from "react-is";
import { getAlarmAttachmentURL } from "../../../../features/Alarms/alarmUtils";


const attachmentTypes = ['Design Drawing', 'Manual', 'Other', 'Photographes', 'Specification', ' Tender Doc']


const initInputData = {
    selecetedType: '',
    selecetedFiles: null
}
const initConfirmPop = {
    open: false,
    des: '',
    message: '',
    loading: false,
    deleteAttachId: null
}
function SfrAttachPopup({ open, onClose, entityId, entityName }) {
    const fileInput = useRef()
    const [selecetedType, setSelecetedType] = useState('')
    const [attachmentList, setAttachmentList] = useState([])
    const [newAttachments, setNewAttachments] = useState([])
    const [confirmPop, setConfirmPop] = useImmer(initConfirmPop)
    const [deleteAttachId, setDeleteAttachId] = useImmer(null)
    const [tableLoader, setTableLoader] = useImmer(true)
    useEffect(() => {
        getAttachments()
    }, [])
    const getAttachments = () => {
        setTableLoader(true)
        let URL = `${APP_BASE_URL_ALARM}${GENERIC_ATTACHMENT}`
        let params = {
            entity_id: entityId,
            entity: entityName
        }
        axios.get(URL, { params }).then((res) => {
            console.log('response', res.data)
            setAttachmentList(res.data)
        }).catch((err) => {

        }).then(() => {
            setTableLoader(false)
        })
    }
    const onAdd = () => {
        setConfirmPop((prev) => {
            prev.loading = true
        })
        const data = new FormData();
        let selecetdAttachments = [...fileInput.current.files]
        selecetdAttachments.forEach((file) => {
            data.append("files", file);
        });
        let attachmentCategory = selecetedType
        let params = {
            entity_id: entityId,
            entity: entityName,
            category: attachmentCategory
        }
        let url = `${APP_BASE_URL_ALARM}${GENERIC_ATTACHMENT}`
        axios.post(url, data, { params }).then((res) => {
            setSelecetedType('')
            fileInput.current.value = null
            toast.success('Attachment Uploaded Succesfully')
            getAttachments()
        }).catch(() => {

        }).then(() => {
            setConfirmPop(initConfirmPop)
        })

    }

    const onDelete = () => {
        setConfirmPop((prev) => {
            prev.loading = true
        })
        let params = {
            entity: entityName
        }
        let url = `${APP_BASE_URL_ALARM}${GENERIC_ATTACHMENT}/${confirmPop.deleteAttachId}`
        axios.delete(url, { params }).then((res) => {
            toast.success('Attachment Deleted Succesfully')
            getAttachments()
        }).catch(() => {

        }).then(() => {
            setConfirmPop(initConfirmPop)
        })
    }
    const handleDeleteClick = (id) => {
        setConfirmPop((prev) => {
            prev.open = true
            prev.message = 'Delete'
            prev.des = 'Do you want to delete selected attachments'
            prev.deleteAttachId = id
        })


    }
    const handleAddClick = () => {
        let isValid = true
        if (!selecetedType) {
            isValid = false
            toast.error('Please Select attachment type')
        } else if (!fileInput?.current?.files || ![...fileInput?.current?.files].length > 0) {
            isValid = false
            toast.error('Please select attachments')
        }
        if (isValid) {
            setConfirmPop((prev) => {
                prev.open = true
                prev.message = 'Upload'
                prev.des = 'Do you want to upload selected attachments'
            })
        }

    }
    const EXTENSIONS_TO_OPEN = ["PDF", "TXT"];
    function handleAttachmentClick(attachment) {
        const attachmentPath = getAlarmAttachmentURL(attachment.path);
        // if its possible to open file then open it in a new window
        if (EXTENSIONS_TO_OPEN.includes(attachment.extension.toUpperCase())) {
            window.open(attachmentPath, "_blank", "noreferrer");
        } else {
            // download the file if not possible to open
            const toastId = toast.loading("Exporting...");
            axios
                .get(attachmentPath, { responseType: "blob" })
                .then(
                    (response) => {
                        const name = attachment.name;
                        const data = response.data;
                        downloadFile({ name, data });
                    },
                    async (err) => {
                        const text = JSON.parse(await err.response.data.text());
                        toast.error(text.error);
                    },
                )
                .then(() => {
                    toast.dismiss(toastId);
                });
        }
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="sfr-attach-popup"
            aria-labelledby="responsive-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle id="responsive-dialog-title" className="attachpop-header">
                Attachments
                <CloseIcon
                    className="sfr-info-popup-close-icon pointer"
                    onClick={() => {
                        onClose();
                    }}
                />
            </DialogTitle>
            <DialogContent className="sfr-attach-content" dividers>
                {confirmPop && (
                    <ConfirmationModal
                        open={confirmPop.open}
                        onClose={() => {
                            setConfirmPop(initConfirmPop);
                        }}
                        onAction={(confirmation) => {
                            if (confirmation) {
                                //   handleConfirm()
                                if (confirmPop.message === 'Delete') {
                                    onDelete()
                                }
                                if (confirmPop.message === 'Upload') {
                                    onAdd()
                                }
                            } else {
                                setConfirmPop(initConfirmPop)
                            }

                        }}
                        message={confirmPop.message}
                        des={confirmPop.des}
                        loading={confirmPop.loading}
                    />
                )}
                <div className="sfr-attach-input-container">
                    {tableLoader && (<Loader />)}
                    <div className=" typeSelect">
                        <FormControl size="small" fullWidth>
                            <InputLabel id="type-select-label">Select Type *</InputLabel>
                            <Select
                                labelId="type-select-label"
                                id="demo-simple-select"
                                //   error={state.fileTypeError}
                                // ref={fileType}
                                value={selecetedType}
                                label="Select Type *"
                                required
                                // size="small"
                                onChange={({ target: { value } }) => {
                                    setSelecetedType(value)
                                }

                                }
                            >
                                {attachmentTypes.map((type, index) => {
                                    return (
                                        <MenuItem key={"attachment-type" + index} value={type}>
                                            {type}
                                        </MenuItem>
                                    );
                                })}

                            </Select>
                        </FormControl>
                    </div>
                    <div className="fileSelect">
                        <input
                            required
                            multiple
                            // onChange={({ target: { files } }) => {
                            //     fileInput.current = files
                            // }}
                            // value={''}
                            ref={fileInput}
                            type="file"
                            id="myfile"
                            name="myfile"
                            className="pointer"
                            style={{ padding: "0" }}
                        />
                    </div>
                    {/* </div> */}
                    <div className="btnContainer">
                        <Button variant="contained" onClick={() => {
                            // onAdd()
                            handleAddClick()
                        }} autoFocus>
                            ADD

                        </Button>
                    </div>
                </div>
                <div className="sfr-attach-table-container">
                    <table>
                        <thead>
                            <tr>
                                {/* <th>Uploaded On</th> */}
                                {/* <th>Doc Type</th> */}
                                <th>Name</th>
                                <th>Category</th>
                                {/* <th>Size</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {attachmentList.map(({ id, name, path, created_dt, extension, category }, index) => {
                                const uploadedTime = new Date(created_dt).getTime()
                                return (
                                    <tr key={index}>
                                        {/* <td>{formatDate(uploadedTime)}</td> */}
                                        {/* <td>{row.docType}</td> */}
                                        <td>
                                            <div className="attachment-popup__table__file-name">{name.length > 34 ? name.slice(0, 35) + '...' : name}</div>
                                        </td>
                                        <td>{category ?? '-'}</td>

                                        <td>
                                            <div className="flex actionColumn">
                                                <Tooltip title={'Open attachment in new tab'} disableInteractive>
                                                    <IconButton onClick={() => handleAttachmentClick({ id, name, path, created_dt, extension, category })} className="downloadSfrFile actionIcon"><FileDownloadIcon /></IconButton>
                                                </Tooltip>
                                                <Tooltip title={'Delete'} disableInteractive>
                                                    <IconButton onClick={() => { handleDeleteClick(id) }} className="deleteSfrFile actionIcon"><DeleteIcon /></IconButton>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {attachmentList.length === 0 && (<span>No Attachments Availible</span>)}

                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    autoFocus
                    onClick={() => {
                        onClose();
                    }}
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SfrAttachPopup;
