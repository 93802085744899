import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useMemo } from "react";

import {
  alarmFiltersSelector,
  alarmModalSelector,
  clearTemporaryFilters,
  closeModal,
  selectAlarmEntities,
  selectedAlarmRows,
  temporaryAlarmFiltersSelector,
  updateAlarmFilters,
  updateAlarmsSelectedRows,
  updateTemporaryAlarmFilters,
  useGetPaginatedAlarmsQuery,
} from "./alarmsSlice";
import AlarmsFilters from "./AlarmsFilters";
import AlarmActionsModal from "./AlarmActionsModal";
import { AlarmTableHeader } from "./AlarmTableHeader";
import DataTable from "../../Components/DataGrid/DataGrid";
import { ActiveAlarmsData } from "../../Components/Alarms/ActiveAlarms";
import { colorAlarmsTableRow, getAlarmsQueryParams, transformFilterRequestParams } from "./alarmUtils";
import { APP_BASE_URL_ALARM, GET_PAGINATED_ALARMS } from "../../Common/EndPoints";
import { ALARMS_COLUMNS, ALARMS_COLUMNS_NO_UPDATE, ALARMS_EXPORT_PARAMS, ALARMS_EXPORT_PARAMS_DYN, ALARM_TABLE_INFO } from "./AlarmColumns";
import { downloadFile } from "../../Utils/Utils";

const sortBasedOnDate = (a, b) => {
  return new Date(b.created_dt) - new Date(a.created_dt);
};

const Alarms = () => {
  const dispatch = useDispatch();
  const { customerIdList } = useContext(ActiveAlarmsData);
  const alarmFilters = useSelector(alarmFiltersSelector);
  const temporaryFilters = useSelector(temporaryAlarmFiltersSelector);
  const { isModalOpen } = useSelector(alarmModalSelector);
  const searchParams = getAlarmsQueryParams({ temporaryFilters, customerIdList, alarmFilters });
  const { isLoading, isFetching } = useGetPaginatedAlarmsQuery(searchParams);
  const alarms = useSelector(selectAlarmEntities);
  const rowCount = useSelector((state) => state.alarm.totalRecords);
  const selectedRows = useSelector(selectedAlarmRows);

  const handleAlarmFilterChange = useCallback((param) => {
    if (temporaryFilters) {
      dispatch(updateTemporaryAlarmFilters(param));
      return;
    }
    dispatch(updateAlarmFilters(param));
  }, []);
  const handleSelectedRowsChange = useCallback((ids) => dispatch(updateAlarmsSelectedRows(ids)), []);
  const alarmTableRows = useMemo(() => {
    if (alarms) {
      var tableRows = Object.values(alarms).sort(sortBasedOnDate);
      if (tableRows.length > alarmFilters.limit) {
        return tableRows.slice(0, alarmFilters.limit + 1);
      }
      return tableRows;
    }
    return [];
  }, [alarmFilters.limit, alarms]);
  const access = window.frontendAccess?.ALARM;
  const allowUpdateAlarms = useMemo(() => access?.update ?? true, [access]);
  const alarmTableColumns = allowUpdateAlarms ? ALARMS_COLUMNS : ALARMS_COLUMNS_NO_UPDATE;
  useEffect(() => {
    return () => {
      dispatch(clearTemporaryFilters());
      dispatch(closeModal());
    };
  }, []);
  function exportFromAPI(_exportType, visibleColumns) {
    let filters = temporaryFilters ?? alarmFilters;
    const params = transformFilterRequestParams({
      ...filters,
      ...ALARMS_EXPORT_PARAMS_DYN(visibleColumns),
    });
    if (_exportType === "pdf") {
      params.export_type = "pdf";
    }
    params.customer_ids = getAlarmsQueryParams({ temporaryFilters, customerIdList, alarmFilters }).customer_ids;
    console.log("exportFromAPI --> ", params); 
    axios.get(`${APP_BASE_URL_ALARM}${GET_PAGINATED_ALARMS}`, { params }).then((url) => {
      const fetchUrl = `${APP_BASE_URL_ALARM}/${url.data}`;
      axios.get(fetchUrl, { responseType: "blob" }).then((response) => {
        const data = response.data;
        const name = `ALARMS_DATA.${_exportType}`;
        downloadFile({ name, data });
      });
    });
  }
  return (
    <div className="alarms-container">
      <AlarmsFilters />
      <AlarmTableHeader allowUpdateAlarms={allowUpdateAlarms} />
      <DataTable
        tableInfo={ALARM_TABLE_INFO}
        loading={isLoading || isFetching}
        rows={alarmTableRows}
        columns={alarmTableColumns}
        pageChange={(index) =>
          handleAlarmFilterChange({ offset: index * (temporaryFilters?.limit ?? alarmFilters.limit), pageIndex: index })
        }
        pageSizeChange={(size) => handleAlarmFilterChange({ limit: size, offset: 0 })}
        pageIndex={temporaryFilters?.pageIndex ?? alarmFilters.pageIndex}
        pageSize={temporaryFilters?.limit ?? alarmFilters.limit}
        rowCount={rowCount ?? 0}
        paginationMode="server"
        selectionModel={selectedRows}
        selectionModelChange={handleSelectedRowsChange}
        rowColor={colorAlarmsTableRow}
        showFilterButton={false}
        serverSideExport={exportFromAPI}
      />
      {isModalOpen && <AlarmActionsModal />}
    </div>
  );
};

export default Alarms;
