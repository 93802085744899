import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import axios from "axios";
import { memo, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { downloadFile } from "../../Utils/Utils";
import { closeModal, useGetAlarmAttachmentsQuery } from "./alarmsSlice";
import DownloadIcon from "@mui/icons-material/Download";
import {
  calculateTimeDifference,
  getAlarmAttachmentURL,
  getNavigateToTelemetryHistoryURL,
  getRowData,
} from "./alarmUtils";
import noImage from "../../Components/Devices/AssetRegister/noImage.jpg";
import FMEView from "./components/fme-view";

const DEFAULT_ATTACHMENTS_DATA = { img_urls: [], other_attachments: [], img_attachments: [] };

const EXTENSIONS_TO_OPEN = ["PDF", "TXT"];
function handleAttachmentClick(attachment) {
  const attachmentPath = getAlarmAttachmentURL(attachment.path);
  // if its possible to open file then open it in a new window
  if (EXTENSIONS_TO_OPEN.includes(attachment.extension.toUpperCase())) {
    window.open(attachmentPath, "_blank", "noreferrer");
  } else {
    // download the file if not possible to open
    const toastId = toast.loading("Exporting...");
    axios
      .get(attachmentPath, { responseType: "blob" })
      .then(
        (response) => {
          const name = attachment.name;
          const data = response.data;
          downloadFile({ name, data });
        },
        async (err) => {
          const text = JSON.parse(await err.response.data.text());
          toast.error(text.error);
        },
      )
      .then(() => {
        toast.dismiss(toastId);
      });
  }
}

const ImageViewerContainer = (props) => {
  const { attachments } = props;
  const [currentPreviewImage, setCurrentPreviewImage] = useState(0);

  function handlePreviousPreviewImage() {
    if (currentPreviewImage !== 0) setCurrentPreviewImage((state) => state - 1);
  }
  function handleNextPreviewImage() {
    if (currentPreviewImage !== attachments.length - 1) setCurrentPreviewImage((state) => state + 1);
  }
  function handleImageDownload(image) {
    const toastId = toast.loading("Exporting...");
    axios
      .get(image.path, { responseType: "blob" })
      .then(
        (response) => {
          const name = image.name;
          const data = response.data;
          downloadFile({ name, data });
        },
        async (err) => {
          const text = JSON.parse(await err.response.data.text());
          toast.error(text.error);
        },
      )
      .then(() => {
        toast.dismiss(toastId);
      });
    // axios.get(image.path).then((res) => {
  }

  return (
    <div className="alarm-info-popup-attachment-info-container">
      <div className="alarm-info-popup-image">
        <a href={attachments[currentPreviewImage].path ?? null} target="_blank" rel="noreferrer">
          <img
            loading="lazy"
            key={attachments[currentPreviewImage].id}
            style={{ width: "20rem", height: "20rem", objectFit: "contain" }}
            src={attachments[currentPreviewImage].path ?? noImage}
            alt="Not Available"
          />
        </a>
      </div>
      {attachments.length > 0 && (
        <>
          <div className="alarm-images-preview-container flex alignCenter spaceBetween">
            <div
              className={`alarm-images-preview-previous-navigation ${
                currentPreviewImage === 0 ? "notAllowed" : "pointer"
              }`}
              onClick={handlePreviousPreviewImage}
            ></div>
            <div>
              {currentPreviewImage + 1} of {attachments.length}
            </div>
            <div
              className={`alarm-images-preview-forward-navigation ${
                currentPreviewImage === attachments.length - 1 ? "notAllowed" : "pointer"
              }`}
              onClick={handleNextPreviewImage}
            ></div>
          </div>
          <div className="flex items-center gap-2 p-2">
            <DownloadIcon
              onClick={() => handleImageDownload(attachments[currentPreviewImage])}
              className="cursor-pointer"
            />
            <a
              href={attachments[currentPreviewImage].path}
              target="_blank"
              className="alarm__info__image-view__image-name"
            >
              {attachments[currentPreviewImage].name}
            </a>
          </div>
        </>
      )}
    </div>
  );
};
export const DisplayImage = ({ id }) => {
  const { data: attachmentsData = DEFAULT_ATTACHMENTS_DATA } = useGetAlarmAttachmentsQuery({ entity_id: id });
  const { img_attachments } = attachmentsData;
  if (img_attachments?.length === 0) {
    return <></>;
  }

  return <ImageViewerContainer attachments={img_attachments} />;
};
export const ViewOtherAttachments = ({ id }) => {
  const { data: attachmentsData = DEFAULT_ATTACHMENTS_DATA } = useGetAlarmAttachmentsQuery({ entity_id: id });
  const { other_attachments } = attachmentsData;
  if (other_attachments.length === 0) return <></>;

  return (
    <>
      <DialogContentText>Attachments</DialogContentText>
      <div className="flex flex-col alarm__info__other-attachments">
        {other_attachments.map((attachment, index) => (
          <span key={"other-attachments-" + index} onClick={() => handleAttachmentClick(attachment)}>
            {index + 1}. <span className="alarm__info__other-attachments__attachment-name">{attachment.name}</span>
          </span>
        ))}
      </div>
    </>
  );
};
export const AlarmInfoSection = ({ title, data }) => (
  <>
    <DialogContentText>{title}</DialogContentText>
    <div className="alarm-info-table">{data}</div>
  </>
);

/** Main Component */
export const AlarmInfoPopup = ({ alarmInfo, open }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = useCallback(() => dispatch(closeModal()), []);

  const {
    ack_dt = null,
    created_dt = null,
    updated_dt = null,
    clear_dt = null,
    acked_info = null,
    clear_info = null,
    create_info = null,
    clear_value = null,
    create_value = null,
    update_value = null,
    acked_user = { first_name: "", last_name: "", email: "" },
    id = null,
    device = null,
    fmeas = [],
  } = alarmInfo;

  let ack_user_name = "";
  let ack_user_email = "";
  if (acked_user) {
    const { first_name = "", last_name = "", email = "" } = acked_user;
    ack_user_name = first_name + " " + last_name;
    ack_user_email = email;
  }

  const createInfo = { created_dt, create_info, create_value };
  const ackInfo = { ack_dt, acked_info, ack_by: ack_user_name, email: ack_user_email };
  const clearInfo = { clear_dt, clear_info, clear_value };
  const updateInfo = { updated_dt, update_value };
  const fmeasInfo = { fmeas };

  const createInfoData = getRowData(createInfo);
  const updateInfoData = getRowData(updateInfo);
  const clearInfoData = getRowData(clearInfo);
  const ackInfoData = getRowData(ackInfo);

  const alarmDuration = calculateTimeDifference(created_dt, clear_dt);

  const handleRelatedData = useCallback(() => {
    const deviceTypeId = device?.device_type?.typeid;
    const url = getNavigateToTelemetryHistoryURL(device);
    let start_date = new Date(created_dt);
    start_date.setMinutes(start_date.getMinutes() - 5);
    start_date = start_date.toISOString();
    const searchParams = {
      start_date,
      end_date: clear_dt ?? new Date().toISOString(),
      device_type_id: deviceTypeId,
    };

    navigate({
      pathname: url,
      search: createSearchParams(searchParams).toString(),
    });
  }, [device]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Alarm Info</DialogTitle>
      <DialogContent>
        <section className="flex justify-between gap-4 p-1" style={{ height: "60vh" }}>
          <div className="flex-1">
            <AlarmInfoSection data={createInfoData} title="Create Info" />
            {updated_dt && <AlarmInfoSection data={updateInfoData} title="Updated Info" />}
            {clear_dt && <AlarmInfoSection data={clearInfoData} title="Clear Info" />}
            {ack_dt && <AlarmInfoSection data={ackInfoData} title="Ack Info" />}
            {fmeas?.length > 0 && (
              <div className="alarm__fmea-view--wrapper">
                <DialogContentText>FME (Failure Mode Effect)</DialogContentText>
                <FMEView data={fmeas} />
              </div>
            )}
            <ViewOtherAttachments id={id} />
            <div className="flex justify-between items-center">
              <p>Alarm Duration: {alarmDuration}</p>
              <Button onClick={handleRelatedData} variant="contained">
                Related Data
              </Button>
            </div>
          </div>
          <DisplayImage id={id} />
        </section>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(AlarmInfoPopup);
