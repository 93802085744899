import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { memo } from "react";
import { getModalTitle } from "./AssetTransferUtils";
import { useGetAssignedAssets } from "./useGetAssignedAssets";
import { useHandleAssetTransferQuery } from "./useHandleAssetTransferQuery";
import { trimAssetName } from "../../Common/EndPoints";

const AssetTransferModal = (props) => {
  const { modalOpen, handleClose, type, user1 } = props;
  const {
    assignedDevicesList = {},
    isFetching: fetchingAssignedDevices,
    handleSelectedAssetsChange,
  } = useGetAssignedAssets({
    type,
    user1,
  });
  const { handleConfirmClick } = useHandleAssetTransferQuery(props, assignedDevicesList);
  const noAssignedAssets = Object.values(assignedDevicesList).length === 0;
  const showEmptyModal = noAssignedAssets && type === "Transfer";

  var modalContentTitle = getModalTitle(props);
  if (showEmptyModal) {
    modalContentTitle = "No assigned assets found";
  }

  const AssignedDevicesContainer = () => {
    return (
      <div className="flex flex-wrap items-center justify-between mt-4">
        {Object.entries(assignedDevicesList)?.map(([key, value], index) => (
          <label className="flex items-center flex-1 gap-2" key={`device-list` + index}>
            <input
              type="checkbox"
              checked={value.checked}
              onChange={({ target: { checked } }) => handleSelectedAssetsChange({ key, value, checked })}
            />
            <div>{trimAssetName(value.deviceName)}</div>
          </label>
        ))}
      </div>
    );
  };

  const modalContent = fetchingAssignedDevices ? (
    <div className="flex justify-center">
      <CircularProgress />
    </div>
  ) : (
    <>
      <DialogContentText className="mb-4">{modalContentTitle}</DialogContentText>
      <AssignedDevicesContainer />
    </>
  );

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle>{type} Assets</DialogTitle>
      <DialogContent>{modalContent}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {!showEmptyModal && (
          <Button onClick={handleConfirmClick} variant="contained" autoFocus type="submit">
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default memo(AssetTransferModal);
