import { Autocomplete, Button, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { set } from "react-hook-form";
import { useImmer } from "use-immer";
import {
  useGetAlarmTypesQuery,
  useGetDevicesNameQuery,
  useGetDeviceTypesQuery,
} from "../../../features/SearchFilters/searchFiltersSlice";
import { getAlarmCategoryList } from "../../../Utils/Utils";
import { ActiveAlarmsData } from "../../Alarms/ActiveAlarms";
import { useLocation } from "react-router-dom";
import { getDeviceNameSearchParams } from "../../../features/SearchFilters/searchFiltersUtils";

const initialFilterState = {
  device_type_ids: [],
  alarm_types: [],
  categories: [],
  device_ids: [],
  severity: null,
  isActive: null,
  from_ts: null,
  to_ts: null,
};
const getInitialFilters = (customerIdList, url) => { };
function ReportFilters({ getFilters }) {
  const { customerIdList } = useContext(ActiveAlarmsData);
  const location = useLocation();
  const [filterState, setFilterState] = useImmer(initialFilterState);

  // const { data: categoriesList = [] } = useGetCategoriesQuery(null);
  const categoriesList = getAlarmCategoryList();
  const { data: gearTypesList = [] } = useGetDeviceTypesQuery(null);
  const { data: deviceNamesList = [] } = useGetDevicesNameQuery(getDeviceNameSearchParams({ customerIdList }));
  const { data: alarmTypesList = [] } = useGetAlarmTypesQuery(null);
  const handleSearch = (filterObj) => {
    let newFilterState = { ...filterObj };
    // newfilterState?.device_type_ids = filterObj.device_ids ? filterObj.device_type_ids.map((value) => {
    //   return (value.id)
    // }).toString(): null
    // newfilterState?.categories = filterObj.categories ? filterObj.categories?.toString():null
    // newfilterState?.alarm_types = FilterRounded.alarm_types ? filterObj.alarm_types?.toString(): null
    // newfilterState?.device_ids = filterObj.device_ids ? filterObj.device_ids.map((value) => {
    //        return (value.id)
    //      }).toString(): null

    getFilters(newFilterState);
  };

  useEffect(() => {
    if (!localStorage.getItem("tempFilters")) {
      localStorage.setItem("permFilters", JSON.stringify({ ...filterState, customer_ids: customerIdList.toString() }));
      getFilters(filterState);
    } else {
      const tempFilter = JSON.parse(localStorage.getItem("tempFilters"));
      tempFilter[location.pathname] = { ...tempFilter[location.pathname], ...filterState };
      localStorage.setItem("tempFilters", JSON.stringify(tempFilter));
      getFilters(filterState);
    }
  }, [filterState, customerIdList]);

  useEffect(() => {
    if (localStorage.getItem("tempFilters")) {
      let url = location.pathname;
      const permFilters = JSON.parse(localStorage.getItem("permFilters"));
      const tempFilters = JSON.parse(localStorage.getItem("tempFilters"))[url];
      if (tempFilters) {
        let newFilters = { ...permFilters, ...tempFilters };
        setFilterState(newFilters);
      } else {
        let newFilters = JSON.parse(localStorage.getItem("permFilters")) ?? initialFilterState;
        setFilterState({ ...newFilters, customer_ids: customerIdList.toString() });
      }

      // getFilters(newFilters)
    } else {
      let newFilters = JSON.parse(localStorage.getItem("permFilters")) ?? initialFilterState;
      setFilterState({ ...newFilters, customer_ids: customerIdList.toString() });
    }
  }, [location.pathname, customerIdList]);

  const clearFilters = () => {
    setFilterState({ ...initialFilterState });
    getFilters({ ...initialFilterState });
  };

  // useEffect(()=>{
  // if(customerIdList.length>0){
  //   getFilters({ ...filterState, customer_ids: customerIdList.toString() });
  // }
  //   },[customerIdList])

  function handleChange(value, stateName) {
    setFilterState((prev) => {
      prev[stateName] = value;
    });
  }
  return (
    <>
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        multiple
        disableCloseOnSelect
        options={gearTypesList}
        renderInput={(params) => <TextField size="small" {...params} label="Gear Types" placeholder="Gear Types" />}
        getOptionLabel={(gearType) => gearType.value}
        value={filterState?.device_type_ids}
        onChange={(_, newValue) => {
          handleChange(newValue, "device_type_ids");
          // setFilterState((prev) => {
          //   prev.device_type_ids = b;
          // });
        }}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />{" "}
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
            </div>
          );
        }}
      />
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        multiple
        disableCloseOnSelect
        options={deviceNamesList}
        renderInput={(params) => <TextField size="small" {...params} label="Assets" placeholder="Assets" />}
        getOptionLabel={(asset) => asset.value}
        value={filterState?.device_ids}
        onChange={(a, b, c) => {
          handleChange(b, "device_ids");
        }}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />{" "}
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
            </div>
          );
        }}
      />

      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        multiple
        disableCloseOnSelect
        limitTags={1}
        options={categoriesList}
        value={filterState?.categories}
        // getOptionLabel={(alarmTypes) => alarmTypes.value}
        onChange={(event, newValue) => {
          handleChange(newValue, "categories");
          // setFilterState((prev) => {
          //   prev.categories = newValue;
          // });
        }}
        //   defaultValue={}
        renderInput={(params) => <TextField size="small" {...params} label="Categories" placeholder="Categories" />}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0]} {...getTagProps(0)} />{" "}
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
            </div>
          );
        }}
      //   sx={{ width: "500px" }}
      />

      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        multiple
        disableCloseOnSelect
        limitTags={1}
        //   id="multiple-limit-tags"
        options={alarmTypesList}
        value={filterState?.alarm_types}
        // getOptionLabel={(alarmTypes) => alarmTypes.value}
        onChange={(event, newValue) => {
          handleChange(newValue, "alarm_types");
          // setFilterState((prev) => {
          //   prev.alarm_types = newValue;
          // });
        }}
        //   defaultValue={}
        renderInput={(params) => <TextField size="small" {...params} label="Alarm Types" placeholder="Alarm Types" />}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0]} {...getTagProps(0)} />{" "}
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
            </div>
          );
        }}
      //   sx={{ width: "500px" }}
      />
      <FormControl size="small" className="filter-input">
        <InputLabel id="standard-label">{"Severity"}</InputLabel>
        <Select
          defaultValue=""
          id="select-standard"
          // className="filter-input"
          label="Severity"
          value={filterState?.severity}
          onChange={(event) => {
            handleChange(event.target.value, "severity");
            // setFilterState((prev) => {
            //   prev.severity = event.target.value;
            // });
          }}
        >
          <MenuItem key={1} value={"WARNING"}>
            WARNING
          </MenuItem>
          <MenuItem key={2} value={"CRITICAL"}>
            CRITICAL
          </MenuItem>
          <MenuItem key={2} value={"MAINTENANCE"}>
            MAINTENANCE
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" className="filter-input">
        <InputLabel id="standard-label">Active/InActive</InputLabel>
        <Select
          defaultValue=""
          id="select-standard"
          // className="filter-input"
          label="Active/InActive"
          value={filterState?.isActive}
          onChange={(event) => {
            handleChange(event.target.value, "isActive");
            // setFilterState((prev) => {
            //   prev.isActive = event.target.value;
            // });
          }}
        >
          <MenuItem key={1} value={true}>
            {/* <MenuItem key={item.key} value={item.value}> */}
            Active
          </MenuItem>
          <MenuItem key={2} value={false}>
            {/* <MenuItem key={item.key} value={item.value}> */}
            InActive
          </MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          size="small"
          disableFuture
          className="filter-input"
          // sx={{}}
          label="From Date"
          openTo="day"
          views={["year", "month", "day"]}
          value={filterState?.from_ts}
          onChange={(newValue) => {
            // let ts = newValue["$d"].getTime();
            // handleChange(ts, "from_ts");

            if (Date.parse(newValue && newValue["$d"])) {
              let ts = newValue["$d"].getTime();
              handleChange(ts, "from_ts");
            } else {
              if (newValue === null) {
                handleChange(newValue, "from_ts");
              }
            }
          }}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        <DatePicker
          disableFuture
          className="filter-input"
          label="To Date"
          openTo="day"
          views={["year", "month", "day"]}
          value={filterState?.to_ts}
          onChange={(newValue) => {
            if (Date.parse(newValue && newValue["$d"])) {
              let ts = newValue["$d"].getTime();
              handleChange(ts, "to_ts");
            } else {
              if (newValue === null) {
                handleChange(newValue, "to_ts");
              }
            }
          }}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
      {/* <Button
        variant="contained"
        onClick={() => {
          handleSearch(filterState);
        }}
      >
        Search
      </Button> */}
      <Button
        variant="outlined"
        onClick={() => {
          clearFilters();
        }}
      >
        Clear filters
      </Button>
    </>
  );
}

export default ReportFilters;
