import moment from "moment";

export const Colors = [
  "rgba(54, 162, 235)",
  "#e51c23",
  "#00876c",
  "#65b17a",
  "#ef8251",
  "#d43d51",
  "rgba(255, 99, 132)",
  "rgba(255, 159, 64)",
  "rgba(75, 192, 192)",
  "rgba(153, 102, 255)",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#5677fc",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#259b24",
  "#8bc34a",
  "#afb42b",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];
const HEALTH_COLUMN = [
  {
    field: "healthy",
    headerName: "Healthy",
    sortable: true,
    width: 130,
    flex: 1,
    cellClassName: (param) => {
      return "green cursor-pointer";
    },
  },
  {
    field: "warning",
    headerName: "Warning",
    sortable: true,
    width: 130,
    flex: 1,
    cellClassName: (param) => {
      return "orange cursor-pointer";
    },
  },
  {
    field: "critical",
    headerName: "Critical",
    sortable: true,
    width: 130,
    flex: 1,
    cellClassName: (param) => {
      return "red cursor-pointer";
    },
  },
  {
    field: "maintenance",
    headerName: "Maintenance",
    sortable: true,
    width: 130,
    flex: 1,
    cellClassName: (param) => {
      return "brown cursor-pointer";
    },
  },
];

export const DEVICETYPE_STATUS_COUNT_COLUMNS = [
  {
    field: "deviceType",
    headerName: "Device Type",
    sortable: true,
    width: 130,
    flex: 1,
  },
  ...HEALTH_COLUMN,
];
export const STATION_STATUS_COUNT_COLUMNS = [
  {
    field: "station",
    headerName: "Station",
    sortable: true,
    width: 130,
    flex: 1,
  },
  ...HEALTH_COLUMN,
];
export const DASHBOARD_MAINTENANCE_ROASTER_COLUMNS = [
  {
    field: "station",
    headerName: "Station",
    sortable: true,
    width: 130,
    flex: 1,
  },
  {
    field: "assetType",
    headerName: "Asset Type",
    sortable: true,
    width: 130,
    flex: 1,
  },
  {
    field: "totalCount",
    headerName: "Count of Assets",
    sortable: true,
    width: 130,
    flex: 1,
  },
  {
    field: "closestNextMaintenaceDate",
    headerName: "Closest Maint. Date",
    sortable: true,
    width: 130,
    flex: 1,
  },
];

export const dynamicSort = (property) => {
  return function (a, b) {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  };
};
// export const dynamicSort =(property)=> {
//   let sortOrder = 1;
//   if(property[0] === "-") {
//       sortOrder = -1;
//       property = property.substr(1);
//   }
//   return function (a,b) {

//       var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//       return result * sortOrder;
//   }
// }
export const getFormattedDateTime = (ts) => {
  const today = new Date(ts);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  const time = new Date(ts).toLocaleTimeString();
  return formattedToday + "(" + time + ")";
};

export const getFormattedDate = (ts) => {
  const today = new Date(ts);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  return formattedToday;
};

export function calculateDayDifference(fromTime, toTime) {
  const date1 = new Date(fromTime);
  const date2 = new Date(toTime);

  const diffInMs = date2.getTime() - date1.getTime();
  // const
  let diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));
  if (diffInDays < 0) {
    diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  }
  // const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  return diffInDays;
}
