import React, { useContext, useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import "./LiveStatusClock.scss";
import { Tooltip } from "@mui/material";
import { end } from "@popperjs/core";
import { APP_BASE_URL_SOCKET, TELEMETRY_SOCKET_URL, tsToDateFormatter } from "../../../Common/EndPoints";
import { ActiveAlarmsData } from "../../Alarms/ActiveAlarms";

function getLastUpdatedAt(ts) {
  const currentTs = Date.now();
  const elapsed = currentTs - ts;
  const seconds = Math.floor(elapsed / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  if (hours >= 1) {
    return `Last updated ${hours} ${hours === 1 ? "hr" : "hrs"} ago`;
  } else if (minutes >= 1) {
    return `Last updated ${minutes} ${minutes === 1 ? "min" : "mins"} ago`;
  } else {
    if (seconds <= 0) {
      return `Updating Continuously`;
    }
    return `Last updated ${seconds} ${seconds === 1 ? "sec" : "secs"} ago`;
  }
}

const initialLiveStatus = {
  isLive: true,
  time: "hh:mm:ss",
  lastUpdatedTime: null,
  tooltipMessage: "",
};
function hasInternetConnection() {
  if (navigator.onLine) {
    return true;
  } else {
    return false;
  }
}
let packetTimeOffset = 300000; //5 minuts
function LiveStatusClock() {
  const { setServerIsLive } = useContext(ActiveAlarmsData);
  let liveStatusWebSocket = useRef(null);
  let lastUpdatedTs = useRef(null);
  let isLive = useRef(false);
  let userId = localStorage.getItem("userId");
  const [tooltip, setTooltip] = useState(false);
  const [tooltipMsg, setTooltipMsg] = useState("msg");
  const [liveTime, setLiveTime] = useState("hh:mm:ss");
  const handleClose = () => {
    setTooltip(false);
  };

  const handleOpen = () => {
    // if (liveClock.isLive) {
    setTooltip(true);
    // }
  };

  useEffect(() => {
    let liveClock = setInterval(time, 1000);
    return () => clearInterval(liveClock);
  }, []);

  useEffect(() => {
    getLiveStatus();
    return () => window.liveStatusWebSocket?.close();
  }, []);

  function getLiveStatus() {
    window.liveStatusWebSocket?.close();
    const url = `${APP_BASE_URL_SOCKET}/${userId}/live`;
    window.liveStatusWebSocket = new WebSocket(url);
    window.liveStatusWebSocket.addEventListener("open", (event, a, b) => {
      isLive.current = true;
      setServerIsLive(true);
      // setLiveClock((prev) => {
      //     prev.isLive = true;
      // });
    });
    window.liveStatusWebSocket.onmessage = (res) => {
      let webPacket = JSON.parse(res.data);
      if (webPacket.lastReceviedPacketTs) {
        let offset = new Date().getTime() - webPacket.lastReceviedPacketTs;
        lastUpdatedTs.current = webPacket.lastReceviedPacketTs;
        if (offset < packetTimeOffset) {
          if (!isLive.current) {
            isLive.current = true;
            setServerIsLive(true);
          }
        } else {
          isLive.current = false;
          setServerIsLive(false);
        }
      } else {
        isLive.current = false;
        setServerIsLive(false);
      }
    };
  }
  function time() {
    let d = new Date();
    let s = d.getSeconds();
    let m = d.getMinutes();
    let h = d.getHours();
    if (isLive.current && hasInternetConnection()) {
      let hours = h < 10 ? "0" + h : h;
      let minutes = m < 10 ? "0" + m : m;
      let sec = s < 10 ? "0" + s : s;
      setLiveTime(hours + ":" + minutes + ":" + sec);
    }
    if (!hasInternetConnection()) {
      isLive.current = false;
      setServerIsLive(false);
    } else {
      if (!isLive.current) {
        isLive.current = true;
        setServerIsLive(true);
      }
    }
    setTooltipMsg(getLastUpdatedAt(lastUpdatedTs.current));
  }

  return (
    <Tooltip
      open={tooltip}
      onClose={handleClose}
      sx={{ backgroundColor: "red" }}
      onOpen={handleOpen}
      placement="bottom"
      title={<div className="liveClockTooltip">{tooltipMsg}</div>}
      disableInteractive
    >
      <div className={`liveStatusClock ${!isLive.current ? "isOffline" : ""}`}>
        <div className={`blinkIcon ${isLive.current ? "blinkAnimation" : ""} `}></div>
        <div className="clockText">{liveTime}</div>
      </div>
    </Tooltip>
  );
}

export default LiveStatusClock;
