import { Button, Tooltip } from "@mui/material";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../DataGrid/DataGrid";
import "./SFR.scss";
import { useImmer } from "use-immer";
import { APP_BASE_URL, SFR_API } from "../../Common/EndPoints";
import axios from "axios";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-hot-toast";
import DeleteModal from "../../Modal/DeleteModal";
import DeleteIcon from "@mui/icons-material/Delete";
import SfrInfoPopup from "./utils/SFR INFO POPUP/SfrInfoPopup";
import SfrFilters from "./utils/SFR FILTERS/SfrFilters";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { formatDataForFailureForm, getDurationInMinutes } from "./utils/Utils";
import { SFR_TABLE_COLUMN_STATIC, failurStateToLabelMap } from "./utils/Constants";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { formatDate } from "../../Utils/Utils";
import { useSelector } from "react-redux";
import { selectedCustomersIdsSelector } from "../../features/authentication/auth-slice";
import SfrAttachPopup from "./utils/SFR ATTACHMENTS/SfrAttachPopup";
import AttachmentIcon from "@mui/icons-material/Attachment";



export const SfrContext = createContext({
    updateSfrFilters: (data) => { },
});


const initDeletePopup = {
    open: false,
    sfrId: null,
};

const initialSfrInfo = {
    open: false,
    data: [],
};
const initConfirmPop = {
    open: false,
    dec: 'Do you want to edit the failure report ?',
    message: 'EDIT',
    rowData: null
};
const initialPaginantion = {
    pageIndex: 0,
    pageSize: 20,
    totalRowCount: 0,
};
const initialSfrFilter = {
    customerIds: [],
    filterCustomerId: [],
    repairedBy: null,
    status: null
};
const inittSfrtAttach = {
    open: false,
    entityId: null,
    entityName: 'SFR'
};
function SFR() {
    const navigate = useNavigate();
    const [sfrData, setSfrData] = useImmer([]);
    const [deletePopup, setDeletePopup] = useImmer(initDeletePopup);
    const [sfrInfo, setSfrInfo] = useImmer(initialSfrInfo);
    const [loader, setLoader] = useState(false)
    const [pagination, setPagination] = useImmer(initialPaginantion)
    const [confirmPop, setConfirmPop] = useImmer(initConfirmPop)
    const [sfrFilters, setSfrFilters] = useImmer(initialSfrFilter)
    const [sfrAttach, setSfrAttach] = useImmer(inittSfrtAttach)
    const customerIdList = useSelector(selectedCustomersIdsSelector)

    useEffect(() => {

        // console.log('useEffect',customerIdList)
        if (customerIdList.length > 0) {
            let paginationParms = {
                page: pagination.pageIndex + 1,
                pageSize: pagination.pageSize
            }
            let filterParms = {
                ...sfrFilters,
                customerIds: sfrFilters.filterCustomerId.length > 0 ? sfrFilters.filterCustomerId.toString() : customerIdList.toString(),

            }
            let params = { ...paginationParms, ...filterParms }
            params.filterCustomerId = null
            getSfrData(params);
        }

    }, [customerIdList, pagination.pageIndex, pagination.pageSize, sfrFilters]);

    useEffect(() => {
        let failureReportApiStatus = localStorage.getItem("failureReportApiStatus");
        if (failureReportApiStatus) {
            failureReportApiStatus = JSON.parse(failureReportApiStatus);
        }
        if (failureReportApiStatus) {
            if (failureReportApiStatus.severity === "success") {
                toast.success(failureReportApiStatus.msg);
            } else {
                toast.error(failureReportApiStatus.msg);
            }
            localStorage.removeItem("failureReportApiStatus");
        }
    }, []);
    const getSfrData = (params) => {
        setLoader(true)
        let url = `${APP_BASE_URL}${SFR_API}/search`;
        axios.get(url, { params }).then((res) => {
            setPagination((prev) => {
                prev.totalRowCount = res.data.data.totalElements
            })
            let RESPONSE = res.data.data.content;


            let newData = RESPONSE.map((_data) => {
                let innerBody = JSON.parse(_data.data);
                console.log("innerBody", innerBody);
                return {
                    ..._data,
                    causeOfFailure: innerBody.causeOfFailure,
                    userRepaired: innerBody?.rectificationBy?.value ?? innerBody.rectificationBy
                };
            });
            console.log(newData);
            setSfrData(newData);
            setLoader(false)
        });
    };
    let editSFR = (rowsData) => {
        let formattedDataForForm = formatDataForFailureForm(rowsData);

        navigate("/SFR-form", { state: formattedDataForForm });
    }
    const handleNavigate = () => {
        navigate("/SFR-form");
    };
    const SFR_TABLE_COLUMN = useMemo(() => {
        return [
            {
                field: "sfrAction",
                type: "actions",
                minWidth: 80,
                flex: 1,
                hideable: false,
                getActions: (params) => {

                    let lastUpdatedTime = params.row.dtCreated
                    let currentTS = new Date().getTime()
                    let timeGap = getDurationInMinutes(lastUpdatedTime, currentTS) / 60 //hours
                    return ([

                        <Tooltip placement="bottom" title="Edit" disableInteractive>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                onClick={(event) => {
                                    console.log(params)
                                    setConfirmPop((prev) => {
                                        prev.open = true
                                        prev.rowData = params.row
                                    })
                                }}
                                disabled={timeGap > 24 && params.row.status !== 'OPEN'}
                            />
                        </Tooltip>,
                        <Tooltip placement="bottom" title="View" disableInteractive>
                            <GridActionsCellItem
                                icon={<InfoIcon />}
                                label="Edit"
                                onClick={(event) => {
                                    let _rowData = formatDataForFailureForm(params.row);
                                    let rowData = {};
                                    Object.entries(_rowData).forEach(([key, value]) => {
                                        if (failurStateToLabelMap[key]?.type === "time") {
                                            rowData[key] = value ? formatDate(value) : "-";
                                        } else {
                                            rowData[key] = value?.id ? value.value : value;
                                        }
                                    });
                                    delete rowData['rectificationBy'];
                                    setSfrInfo((prev) => {
                                        prev.open = true;
                                        prev.info = rowData;
                                    });
                                }}
                            />
                        </Tooltip>,
                        <Tooltip placement="bottom" title="Attachments" disableInteractive>
                            <GridActionsCellItem
                                icon={<AttachmentIcon />}
                                label="Attachments"
                                onClick={(event) => {
                                    let id = params.row.id
                                    setSfrAttach((prev) => {
                                        prev.open = true
                                        prev.entityId = id
                                    })
                                    // console.log('editClicked', params)
                                }}
                            />
                        </Tooltip>,
                        // <Tooltip placement="bottom" title="Delete" disableInteractive>
                        //     <GridActionsCellItem
                        //         icon={<DeleteIcon />}
                        //         label="delete"
                        //         onClick={(event) => {
                        //             let id = params.row.id
                        //             setDeletePopup((prev) => {
                        //                 prev.open = true
                        //                 prev.sfrId = id
                        //             })
                        //             // console.log('editClicked', params)
                        //         }}
                        //     />
                        // </Tooltip>,
                    ])
                }

            },
            ...SFR_TABLE_COLUMN_STATIC,
        ];
    }, []);

    const deleteSFR = (sfrId) => {
        let url = `${APP_BASE_URL}${SFR_API}/${sfrId}`;
        axios
            .delete(url)
            .then((res) => {
                toast.success("SFR deleted successfully");
                getSfrData();
            })
            .catch((err) => {
                const errMsg = err?.response?.data?.error?.message;
                toast.error(errMsg);
            })
            .then(() => {
                setDeletePopup(initDeletePopup);
            });
    };
    const updateSfrFilters = (payload) => {
        setSfrFilters(payload);
    }
    return (
        <SfrContext.Provider
            value={{
                updateSfrFilters: updateSfrFilters
            }}
        >
            <div className="sfr-cpmponent">
                {deletePopup.open && (
                    <DeleteModal
                        open={deletePopup.open}
                        onClose={() => setDeletePopup(initDeletePopup)}
                        deleteMessageFor={"SFR"}
                        willDelete={() => deleteSFR(deletePopup.sfrId)}
                    />
                )}
                {sfrInfo.open && (
                    <SfrInfoPopup open={sfrInfo.open} onClose={() => setSfrInfo(initialSfrInfo)} info={sfrInfo.info} />
                )}
                {confirmPop.open && (
                    <ConfirmationModal
                        open={confirmPop.open}
                        onClose={() => {
                            setConfirmPop(initConfirmPop);
                        }}
                        onAction={(confirmation) => {
                            if (confirmation) {
                                editSFR(confirmPop.rowData)
                            }
                            setConfirmPop(initConfirmPop);
                        }}
                        message={confirmPop.message}
                        des={confirmPop.dec}
                    />
                )}
                {sfrAttach.open && (
                    <SfrAttachPopup
                        open={sfrAttach.open}
                        onClose={() => {
                            setSfrAttach(inittSfrtAttach);
                        }}
                        entityId={sfrAttach.entityId}
                        entityName={sfrAttach.entityName}

                    />
                )}
                <div className="sfr-header">
                    <Button variant="contained" onClick={() => handleNavigate()}>
                        add SFR
                    </Button>
                </div>
                <SfrFilters />
                {/* <div className='sfr-filters'></div> */}
                <div className="sfr-table">
                    <DataTable
                        key="signal-failure-report"
                        loading={loader}
                        tableInfo={{
                            id: "signal-failure-report",
                            label: "Signal Failure Report",
                            requireAuthSign: false,
                        }}
                        rows={sfrData}
                        columns={SFR_TABLE_COLUMN}
                        paginationMode={"server"}
                        // rowCount={50}
                        rowCount={pagination.totalRowCount}
                        pageIndex={pagination.pageIndex}
                        pageChange={(index) => {
                            setPagination((prev) => {
                                prev.pageIndex = index;
                            });
                        }}
                        pageSize={pagination.pageSize}
                        pageSizeChange={(size) => {
                            setPagination((prev) => {
                                prev.pageSize = size;
                            });
                        }}
                    />
                </div>
            </div>
        </SfrContext.Provider>

    );
}

export default SFR;
