import { Autocomplete, Button, Chip, FormControl, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import "./TcUtilization.scss";
import { useImmer } from "use-immer";
import { APP_BASE_URL_ALARM, TC_UTILISATION, trimAssetName } from "../../../Common/EndPoints";
import axios from "axios";
import { ActiveAlarmsData } from "../../Alarms/ActiveAlarms";
import TcCountUtilizationBarChart from "./utils/TcCountUtilizationBarChart";
import DataTable from "../../DataGrid/DataGrid";
import { UtilizationLabel, calculateDuration, convertSecondsToDuration, mostUtilizedTableColumn } from "../Constants";
import TcRuntimeChart from "./utils/TcRuntimeChart";
import { AutocompleteStationListPop } from "../../AutocompleteStationListPop/AutocompleteStationListPop";

export const TcUtilastionContext = createContext({
  tcUtilizationData: [],
});

const initialFilters = {
  from_ts: null,
  to_ts: null,
  customer_ids: [],
};
const initialUtilisedTc = {
  count: 5,
  data: [],
};
function TcUtilization({ assetType }) {
  const [filterState, setFilterState] = useImmer(initialFilters);
  const [tcUtilizationData, setTcUtilizationData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [mostUtilizedTc, setMostUtilizedTc] = useImmer(initialUtilisedTc);
  const { customerIdList, selectedCustomersIdName = [] } = useContext(ActiveAlarmsData);
  const permittedCustomerIds = useMemo(() => {
    const _list = selectedCustomersIdName;
    if (!_list) return [];
    return _list.map((customer) => ({ id: customer.customerId, value: customer.customerName }));
  }, [selectedCustomersIdName?.length]);

  useEffect(() => {
    if (customerIdList.length > 0) {
      setFilterState((state) => {
        state.customer_ids = state.customer_ids.filter((customer) => customerIdList.includes(customer.id));
      });
      getTcSummaryData(filterState);
    }
  }, [customerIdList]);
  useEffect(() => {
    if (customerIdList.length > 0) {
      getMostUtilisedTc(filterState);
    }
  }, [customerIdList, mostUtilizedTc.count]);
  const getTcSummaryData = (filterState) => {
    const customerIds = filterState.customer_ids
      ?.map((customer) => customer.id)
      ?.filter((id) => customerIdList.includes(id));
    const customer_ids = customerIds.length > 0 ? customerIds.toString() : customerIdList.toString();

    let filterParams = {
      from_ts: filterState.from_ts,
      to_ts: filterState.to_ts,
      customer_ids,
    };
    let url = `${APP_BASE_URL_ALARM}${TC_UTILISATION}`;
    axios.get(url, { params: filterParams }).then((res) => {
      if (res) {
        let Response = res.data;

        setTcUtilizationData(Response);
      } else {
        setTcUtilizationData([]);
      }
    });
  };
  const getMostUtilisedTc = (filterState) => {
    const customerIds = filterState.customer_ids
      ?.map((customer) => customer.id)
      ?.filter((id) => customerIdList.includes(id));
    const customer_ids = customerIds.length > 0 ? customerIds.toString() : customerIdList.toString();

    let filterParams = {
      from_ts: filterState.from_ts,
      to_ts: filterState.to_ts,
      customer_ids,
      order_cols: "total_occupied_duration desc NULLS last",
    };
    let url = `${APP_BASE_URL_ALARM}${TC_UTILISATION}`;
    axios.get(url, { params: filterParams }).then((res) => {
      if (res.data) {
        let Response = res.data?.slice(0, mostUtilizedTc.count) ?? [];

        Response = Response.map((row, i) => {
          let timeStamp = row.total_occupied_duration;
          timeStamp = Number(timeStamp);
          // let { minutes, hours } = ;
          let trimmedAssetName = trimAssetName(row.device_name);
          return {
            ...row,
            id: i,
            //   avg_ot: row.avg_ot.toFixed(2),
            assetName: trimmedAssetName,
            total_occupied_duration:
              convertSecondsToDuration(timeStamp),
          };
        });
        setMostUtilizedTc((prev) => {
          prev.data = Response;
        });
      } else {
        setMostUtilizedTc((prev) => {
          prev.data = [];
        });
      }
    });
  };
  const handleSearch = (filterState) => {
    getTcSummaryData(filterState);
    getMostUtilisedTc(filterState);
  };
  return (
    <TcUtilastionContext.Provider
      value={{
        tcUtilizationData: tcUtilizationData,
      }}
    >
      <div className="tc-utilization">
        <div className="pageHeader">{UtilizationLabel[assetType]}</div>
        <div className="utilisation-filter card">
          <Autocomplete
            size="small"
            className="filter-input auto-complete"
            multiple
            disableCloseOnSelect
            options={permittedCustomerIds}
            renderInput={(params) => (
              <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
            )}
            getOptionLabel={(gearType) => gearType.value}
            value={filterState?.customer_ids}
            onChange={(_, newValue) => {
              setFilterState((state) => {
                state.customer_ids = newValue;
              });
            }}
            renderTags={(value, getTagProps) => {
              return (
                <div>
                  <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
                  {value.length > 1 &&
                    <AutocompleteStationListPop title={value.map((stn) => stn?.value ?? '').toString()}>
                      <Chip size="small" label={`+${value.length - 1}`} />
                    </AutocompleteStationListPop>
                  }
                </div>
              );
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              size="small"
              disableFuture
              className="filter-input"
              // sx={{}}
              label="From Date"
              openTo="day"
              views={["year", "month", "day"]}
              value={filterState.from_ts}
              onChange={(newValue) => {
                if (Date.parse(newValue && newValue["$d"])) {
                  let ts = newValue["$d"].getTime();
                  setFilterState((prev) => {
                    prev.from_ts = ts;
                  });
                } else {
                  if (newValue === null) {
                    setFilterState((prev) => {
                      prev.from_ts = newValue;
                    });
                  }
                }
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
            <DatePicker
              disableFuture
              className="filter-input"
              label="To Date"
              openTo="day"
              views={["year", "month", "day"]}
              value={filterState.to_ts}
              onChange={(newValue) => {
                // let ts = newValue['$d'].getTime()
                // setFilterState((prev) => {
                //   prev.to_ts = ts;
                // });

                if (Date.parse(newValue && newValue["$d"])) {
                  let ts = newValue["$d"].getTime();
                  setFilterState((prev) => {
                    prev.to_ts = ts;
                  });
                } else {
                  if (newValue === null) {
                    setFilterState((prev) => {
                      prev.to_ts = newValue;
                    });
                  }
                }
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <Button variant="contained" onClick={() => handleSearch(filterState)}>
            Search
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setFilterState(initialFilters);
              handleSearch(initialFilters);
            }}
          >
            Clear Filters
          </Button>
        </div>
        <TcCountUtilizationBarChart />
        <div className="mostUtilisedTable card">
          <div className="header">
            <div className="card-heading">Most Utilized Track Circuits</div>
            <div className="countSelect">
              <div className="label">Number of Records:</div>
              <FormControl variant="standard" size="small">
                {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={mostUtilizedTc.count}
                  onChange={(event) => {
                    setMostUtilizedTc((prev) => {
                      prev.count = event.target.value;
                    });
                  }}
                // label="Age"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <DataTable
            key="most-utilised-track-circuits"
            loading={false}
            tableInfo={{
              id: "most-utilised-track-circuits",
              label: "Most Utilised Track Circuits",
              requireAuthSign: false,
            }}
            pageSize={pageSize}
            pageSizeChange={(size) => {
              setPageSize(size);
            }}
            rows={mostUtilizedTc.data}
            columns={mostUtilizedTableColumn[assetType]}
          />
        </div>
        <TcRuntimeChart />
        {/* <div className="somechart card"></div> */}
        {/* <div className='mostUtilisedTable card'></div> */}
      </div>
    </TcUtilastionContext.Provider>
  );
}

export default TcUtilization;
