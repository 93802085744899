import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store/store";
import App from "./App";
import React from "react";
import { Provider } from "react-redux";
import { Toaster, ToastBar } from "react-hot-toast";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Toaster>
          {(t) => (
            <ToastBar
              toast={t}
              style={{
                ...t.style,
                animation: t.visible ? "fadeIn 0.2s ease-out" : "fadeOut 0.15s ease-in forwards",
              }}
            />
          )}
        </Toaster>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
