import axios from "axios";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";

import "./AssetScheduleAssignment.scss";
import DataTable from "../DataGrid/DataGrid";
import DeleteModal from "../../Modal/DeleteModal";
import { selectedCustomersSelector } from "../../features/authentication/auth-slice";
import { APP_BASE_URL, ASSET_ASSIGNMENT_API, DELETE_ASSET_ASSIGNMENT_API, trimAssetName } from "../../Common/EndPoints";

const initialState = {
  tablerows: [],
};

const initialDeleteModal = { open: false, assetId: "", userId: "", loading: false };
function AssetScheduleAssignment() {
  const selected_customers = useSelector(selectedCustomersSelector);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useImmer(initialState);
  const [pageSize, setPageSize] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [deleteModal, setDeleteModal] = useImmer(initialDeleteModal);
  const access = window.frontendAccess?.ASSET_ASSIGNMENT;
  const allowCreateAssetAssignment = access?.create ?? true;
  const allowUpdateAssetAssignment = access?.update ?? true;
  // const allowReadSelfOnly = access?.readSelf??true;

  useEffect(() => {
    getAssetAssignments();
  }, [pageSize, pageIndex, selected_customers]);

  const columns = useMemo(
    () => [
      {
        field: "editActions",
        type: "actions",
        minWidth: 150,
        renderCell: (params) =>
          allowUpdateAssetAssignment ? (
            <Tooltip placement="bottom" title="Delete" disableInteractive>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() =>
                  setDeleteModal((prev) => {
                    prev.open = true;
                    prev.assetId = params.row.deviceId;
                    prev.userId = params.row.userId;
                  })
                }
              />
            </Tooltip>
          ) : (
            <></>
          ),
      },
      {
        field: "assetName",
        headerName: "Asset Name",
        sortable: true,
        minWidth: 180,
        flex: 1,
        type: "date",
      },
      {
        field: "assetType",
        headerName: "Asset Type",
        sortable: true,
        width: 130,
        flex: 1,
      },
      {
        field: "userName",
        headerName: "User Name",
        sortable: true,
        flex: 1,
        width: 150,
      },
      {
        field: "userRole",
        headerName: "User Role",
        sortable: true,
        flex: 1,
        width: 180,
      },
    ],
    [],
  );

  const getAssetAssignments = () => {
    setLoader(true);
    let url = `${APP_BASE_URL}${ASSET_ASSIGNMENT_API}`;
    const customerIdList = selected_customers.map((customer) => customer.customerId).toString();
    const params = {
      page: pageIndex + 1,
      pageSize,
      customerIdList,
    };
    // if (allowReadSelfOnly) params.userId = window.userId;

    //  let url = `${BASE_URL}${ASSET_ASSIGNMENT_API}?page=${pageIndex+1}&pageSize=${pageSize}&withAsset=true&withUser=true`
    axios
      .get(url, { params })
      .then((res) => {
        const RESPONSE = res.data.data;
        setRowCount(RESPONSE.totalElements);
        let tableRows = RESPONSE.content.map((row, index) => {
          return {
            id: index,
            assetName: trimAssetName(row.deviceName),
            deviceId: row.deviceId,
            userName: row.userName ?? "-",
            userId: row.userId,
            assetType: row.deviceTypeLabel ?? "-",
            userRole: row.userRoleName,
            userRoleId: row.userRoleId,
          };
        });
        setState((prev) => {
          prev.tablerows = tableRows;
        });
      })
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const onDelete = () => {
    setDeleteModal((prev) => {
      prev.loading = true;
    });
    let url = `${APP_BASE_URL}${DELETE_ASSET_ASSIGNMENT_API}/${deleteModal.assetId}/user/${deleteModal.userId}`;
    axios.delete(url).then((res) => {
      setDeleteModal(initialDeleteModal);
      getAssetAssignments();
    });
  };

  return (
    <div className="asset-assign-main-container">
      <div className="header">
        {allowCreateAssetAssignment && (
          <Button variant="contained" onClick={() => navigate("/asset-assignment-form")}>
            Assign assets
          </Button>
        )}
      </div>
      <div className="table-container">
        <DataTable
          loading={loader}
          tableInfo={{
            id: `asset-assignment-table`,
            label: "Asset Assignment Table",
            requireAuthSign: false,
          }}
          rows={state.tablerows}
          // columns={ASSET_ASSIGNMENT_TABLE_COLUMN()}
          columns={columns}
          paginationMode={"server"}
          pageSize={pageSize}
          pageIndex={pageIndex}
          rowCount={rowCount}
          pageChange={(index) => {
            setPageIndex(index);
          }}
          pageSizeChange={(size) => {
            setPageSize(size);
          }}
        />
      </div>
      {deleteModal.open && (
        <DeleteModal
          loading={deleteModal.loading}
          open={deleteModal.open}
          deleteMessageFor={"asset assignment"}
          onClose={() => {
            setDeleteModal((prev) => {
              prev.open = false;
            });
          }}
          willDelete={onDelete}
        />
      )}
    </div>
  );
}

export default AssetScheduleAssignment;
