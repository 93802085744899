import { trimAssetName } from "../../../Common/EndPoints";
import { convertToSentenceCase } from "./Utils";

export const rectificationStatusDropdowns = [
    { id: "OPEN", value: "OPEN" },
    { id: "CLOSE", value: "CLOSE" },
];
export const disconnectionMemoIssuedDropdowns = [
    { id: "YES", value: "YES" },
    { id: "NO", value: "NO" },
];
export const relayRoomOpenDropdowns = [
    { id: "YES", value: "YES" },
    { id: "NO", value: "NO" },
];

export const FIXED_DROPDOWNS = {
    department: [
        { id: "BAD WEATHER", value: "BAD WEATHER" },
        { id: "ELECTRIC", value: "ELECTRIC" },
        { id: "ENGG", value: "ENGG" },
        { id: "LNO", value: "LNO" },
        { id: "MECH", value: "MECH" },
        { id: "MISC", value: "MISC" },
        { id: "OPTG", value: "OPTG" },
        { id: "OTHERS", value: "OTHERS" },
        { id: "SNT", value: "SNT" },
    ],
};

export const SFR_TABLE_COLUMN_STATIC = [
    {
        field: "dtCreated",
        headerName: "Date",
        sortable: true,
        minWidth: 200,
        flex: 1,
        type: "date",
        valueFormatter: (param) => {
            if (param.value && param.value > 0) {
                const value = Number(param.value);
                const date = new Date(value);
                const yyyy = date.getFullYear();
                let mm = date.getMonth() + 1; // Months start at 0!
                let dd = date.getDate();

                if (dd < 10) dd = "0" + dd;
                if (mm < 10) mm = "0" + mm;

                const formattedToday = dd + "/" + mm + "/" + yyyy;
                const time = date.toLocaleTimeString();
                return formattedToday + " " + time;
            } else {
                return "-";
            }
        },
    },
    {
        field: "deviceName",
        headerName: "Asset Name",
        sortable: true,
        minWidth: 120,
        flex: 1,
        valueFormatter: (param) => {
            return trimAssetName(param.value);
        },
    },
    {
        field: "make",
        headerName: "Make",
        sortable: true,
        minWidth: 120,
        flex: 1,
    },
    {
        field: "model",
        headerName: "Model",
        sortable: true,
        minWidth: 120,
        flex: 1,
    },
    {
        field: "failureOn",
        headerName: "Failure On",
        sortable: true,
        minWidth: 120,
        flex: 1,
        valueFormatter: (param) => {
            if (param.value && param.value > 0) {
                const value = Number(param.value);
                const date = new Date(value);
                const yyyy = date.getFullYear();
                let mm = date.getMonth() + 1; // Months start at 0!
                let dd = date.getDate();

                if (dd < 10) dd = "0" + dd;
                if (mm < 10) mm = "0" + mm;

                const formattedToday = dd + "/" + mm + "/" + yyyy;
                const time = date.toLocaleTimeString();
                return formattedToday + " " + time;
            } else {
                return "-";
            }
        },
    },
    {
        field: "repairedOn",
        headerName: "Repaired On",
        sortable: true,
        minWidth: 120,
        flex: 1,
        valueFormatter: (param) => {
            if (param.value && param.value > 0) {
                const value = Number(param.value);
                const date = new Date(value);
                const yyyy = date.getFullYear();
                let mm = date.getMonth() + 1; // Months start at 0!
                let dd = date.getDate();

                if (dd < 10) dd = "0" + dd;
                if (mm < 10) mm = "0" + mm;

                const formattedToday = dd + "/" + mm + "/" + yyyy;
                const time = date.toLocaleTimeString();
                return formattedToday + " " + time;
            } else {
                return "-";
            }
        },
    },
    {
        field: "status",
        headerName: "Status",
        sortable: true,
        minWidth: 80,
        flex: 1,
        cellClassName: (p) => {
            if (p.value === 'CLOSE') {
                return "bold green";
            }
            if (p.value === 'OPEN') {
                return 'bold red'
            }

        }
    },
    {
        field: "userRepaired",
        headerName: "Repaired By",
        sortable: true,
        minWidth: 80,
        flex: 1,
    },
    {
        field: "causeOfFailure",
        headerName: "Cause Of Failure",
        sortable: true,
        minWidth: 300,
        flex: 1,
        valueFormatter: (param) => {
            if (param.value) {
                return convertToSentenceCase(param.value)
            } else {
                return '-'
            }
        }
    },
];

export const DATE_VALIDATION_GROUPS = {
    failure: ["failureOn", "infoReceivedTs", "failureAttendedTs", "repairedOn"],
    disconnectionMemo: ["infoReceivedTs", "gearDisconnectTime", "gearReconnectionTime"],
    relayRoom: ["infoReceivedTs", "relayRoomOpenTs", "relayRoomCloseTs"],
};

// export const failurStateToLabelMap = {
//     failureOn: 'Failure On',
//     infoReceivedTs: 'information Received At',
//     failureAttendedTs: "Failure Attended at Hrs",
//     repairedOn: "Rectification On",
//     gearDisconnectTime: 'Time of Gear Disconnect',
//     gearReconnectionTime: "Time of Gear Reconnect",
//     relayRoomOpenTs: 'Relay Room Open Timing',
//     relayRoomCloseTs: 'Relay Room Closed Timing',
//     zone: 'Zone',
//     division: 'Division',
//     customerId: 'Station',
//     failureEntryNo: 'Failure entry number',
//     department: 'Department',
//     assetType: 'Asset Type',
//     deviceId: 'Asset Name',
//     rfid: 'RFID',
//     make: 'Make',
//     model: 'Model',
//     serialNumber: 'Serial number',
//     infoReceivedBy: 'infoReceivedBy',
//     status: 'Rectification Status',
//     repairedBy: 'Rectification by',
//     failurDuration: 'Failure Duration (in Minutes)',
//     causeOfFailure: 'Cause of failure',
//     disconnectionMemoIssued: 'Disconnection Memo Issued to Rectify the Failure',
//     disconnectionMemoReqNumber: 'Disconnection memo number',
//     disconnectionMemoTimeOfferd: 'Time offered (In Minutes)',
//     disconnectionMemoTimeAccepted: 'Time Accepted (In Minutes)',
//     relayRoomOpened: 'Relay room opened',
//     relayRoomOpenDuration: `Relay room opn duration (in minutes)`,
//     actionPerformed: 'Action performed',
//     remarks: 'Remarks',
//     affectedAssets: 'Affected Assets',
//     detainedTrains: 'Detained trains',
//     rectificationBy: 'Rectification By'

// }
export const failurStateToLabelMap = {
    failureOn: { type: "time", label: "Failure On" },
    infoReceivedTs: { type: "time", label: "information Received At" },
    failureAttendedTs: { type: "time", label: "Failure Attended at Hrs" },
    repairedOn: { type: "time", label: "Rectification On" },
    gearDisconnectTime: { type: "time", label: "Time of Gear Disconnect" },
    gearReconnectionTime: { type: "time", label: "Time of Gear Reconnect" },
    relayRoomOpenTs: { type: "time", label: "Relay Room Open Timing" },
    relayRoomCloseTs: { type: "time", label: "Relay Room Closed Timing" },
    zone: { type: "text", label: "Zone" },
    division: { type: "text", label: "Division" },
    customerId: { type: "text", label: "Station" },
    failureEntryNo: { type: "text", label: "Failure entry number" },
    department: { type: "text", label: "Department", showInSentenceCase: false },
    assetType: { type: "text", label: "Asset Type" },
    deviceId: { type: "text", label: "Asset Name" },
    rfid: { type: "text", label: "RFID" },
    make: { type: "text", label: "Make" },
    model: { type: "text", label: "Model" },
    serialNumber: { type: "text", label: "Serial number" },
    infoReceivedBy: { type: "text", label: "infoReceivedBy" },
    status: { type: "text", label: "Rectification Status", showInSentenceCase: true },
    repairedBy: { type: "text", label: "Rectification by" },
    failurDuration: { type: "number", label: "Failure Duration (in Minutes)" },
    causeOfFailure: { type: "text", label: "Cause of failure", showInSentenceCase: true },
    disconnectionMemoIssued: { type: "text", label: "Disconnection Memo Issued to Rectify the Failure", showInSentenceCase: true },
    disconnectionMemoReqNumber: { type: "text", label: "Disconnection memo number" },
    disconnectionMemoTimeOfferd: { type: "number", label: "Time Requested (In Minutes)" },
    disconnectionMemoTimeAccepted: { type: "number", label: "Time Accepted (In Minutes)" },
    relayRoomOpened: { type: "text", label: "Relay room opened", showInSentenceCase: true },
    relayRoomOpenDuration: { type: "number", label: "Relay room opn duration (in minutes)" },
    actionPerformed: { type: "text", label: "Action performed" },
    remarks: { type: "text", label: "Remarks" },
    affectedAssets: { type: "object", label: "Affected Assets" },
    detainedTrains: { type: "object", label: "Detained trains" },
    rectificationBy: { type: "text", label: "Rectification By" },
};

export const UESR_ROLE_DROPDOWNS = [
    { id: "762db887-c919-44d9-8f0b-ed2498757e02", value: "ESM" },
    { id: "928fd45b-83df-4b79-b0a0-5e9df853a708", value: "SI" },
    { id: "c7537ee4-56a8-4989-983d-efcfb073d402", value: "TECHNICIAN" },
    { id: "9f322286-d50a-4912-b12c-194cdc01a30a", value: "SSE" },
    { id: "9842e378-7621-4e2c-81c3-26c967885942", value: "SSE/IC" },
    { id: "d98940ab-8fa6-4ddb-95d4-a8b1fa8bd958", value: "JE" },
];
export const allowedUserRoleIds = UESR_ROLE_DROPDOWNS.map((data) => data.id);
export const FIXED_REACTIVE_DROPDOWNS_COF = {
    "BAD WEATHER": [
        { id: "HEAVY LIGHTNING ", value: "HEAVY LIGHTNING " },
        { id: "HEAVY STORM ", value: "HEAVY STORM " },
        { id: "OTHERS", value: "OTHERS" },
        { id: "RAIN ", value: "RAIN " },
        { id: "TRANSMMISSION LINES DAMAGED DURING STORM", value: "TRANSMMISSION LINES DAMAGED DURING STORM" },
    ],
    ELECTRIC: [],
    ENGG: [
        { id: "BURR IN POINT", value: "BURR IN POINT" },
        { id: "CABLE CUT", value: "CABLE CUT" },
        { id: "CABLE CUT BY JCB / TTM", value: "CABLE CUT BY JCB / TTM" },
        { id: "CLIT SHORT", value: "CLIT SHORT" },
        { id: "CREEP EFFECT", value: "CREEP EFFECT" },
        { id: "END POST CRUSHED", value: "END POST CRUSHED" },
        { id: "ENGG STAFF WORKING", value: "ENGG STAFF WORKING" },
        { id: "FAILURE DUE TO P-WAY TROLLY MOVEMEN", value: "FAILURE DUE TO P-WAY TROLLY MOVEMEN" },
        { id: "GATE MAN NOT ATTENDED", value: "GATE MAN NOT ATTENDED" },
        { id: "GLUED Jt. DEF", value: "GLUED Jt. DEF" },
        { id: "I/ JOINT FAILURE DUE TO LOOSE PACKING", value: "I/ JOINT FAILURE DUE TO LOOSE PACKING" },
        { id: "INSULATION BUSH CRUSHED", value: "INSULATION BUSH CRUSHED" },
        { id: "IRON BURR", value: "IRON BURR" },
        { id: "LOOSE PACKING", value: "LOOSE PACKING" },
        { id: "METAL OVER FLOW", value: "METAL OVER FLOW" },
        { id: "NON INSULATED TROLLEY MOVEMENT", value: "NON INSULATED TROLLEY MOVEMENT" },
        { id: "NUT BOLT LOOSE/ BROKEN", value: "NUT BOLT LOOSE/ BROKEN" },
        { id: "OTHERS", value: "OTHERS" },
        { id: "PAD &amp; LINER MISSING", value: "PAD &amp; LINER MISSING" },
        { id: "PENDROL CLIP SHORT", value: "PENDROL CLIP SHORT" },
        { id: "POINT GAUGE DISTURBED", value: "POINT GAUGE DISTURBED" },
        { id: "POINT NOT SET AFTER  ENGG. BLOCK.", value: "POINT NOT SET AFTER  ENGG. BLOCK." },
        { id: "POOR BALLAST", value: "POOR BALLAST" },
        { id: "POOR DRAINAGE", value: "POOR DRAINAGE" },
        { id: "P/WAY FITTING LOOSE", value: "P/WAY FITTING LOOSE" },
        { id: "RAIL FRACTURE", value: "RAIL FRACTURE" },
        { id: "RAIL PANEL SHORT", value: "RAIL PANEL SHORT" },
        { id: "RDSO BUSH CRUSHED", value: "RDSO BUSH CRUSHED" },
        { id: "ROD/WIRE JAMMED WITH BALLAST/SLEEPER/RAILS", value: "ROD/WIRE JAMMED WITH BALLAST/SLEEPER/RAILS" },
        { id: "SLEEPER SHOWING SHORT", value: "SLEEPER SHOWING SHORT" },
        { id: "STRETCHER BAR BROKEN", value: "STRETCHER BAR BROKEN" },
        { id: "STUD BOLT LOOSE/BROKEN", value: "STUD BOLT LOOSE/BROKEN" },
        { id: "SWITCH RAIL OBSTRUCTING WITH STOCK RAIL", value: "SWITCH RAIL OBSTRUCTING WITH STOCK RAIL" },
        { id: "TESTED &amp; FOUND OK", value: "TESTED &amp; FOUND OK" },
        { id: "TRACK MACHINE WORKING", value: "TRACK MACHINE WORKING" },
        { id: "WIRE RUN JAMMED WITH BALLAST", value: "WIRE RUN JAMMED WITH BALLAST" },
        { id: "WRONG OPERATION  BY ENGG. GATE MAN", value: "WRONG OPERATION  BY ENGG. GATE MAN" },
    ],
    LNO: [
        { id: "BOOM BROKEN BY VEHICLE/OUTSIDER", value: "BOOM BROKEN BY VEHICLE/OUTSIDER" },
        { id: "BOOM DISTURBED BY VEHICLE/OUTSIDER", value: "BOOM DISTURBED BY VEHICLE/OUTSIDER" },
        { id: "BREAKAGE OF LENS", value: "BREAKAGE OF LENS" },
        { id: "CABLE BURNT", value: "CABLE BURNT" },
        { id: "CABLE CUT", value: "CABLE CUT" },
        { id: "CABLE THEFT BY MISCREANT", value: "CABLE THEFT BY MISCREANT" },
        { id: "CATTLE RUN OVER", value: "CATTLE RUN OVER" },
        { id: "JB / BOOT LEG  BROKEN", value: "JB / BOOT LEG  BROKEN" },
        { id: "JUMPER BROKEN BY OUTSIDER", value: "JUMPER BROKEN BY OUTSIDER" },
        { id: "OUTSIDERS INTERFERENCE", value: "OUTSIDERS INTERFERENCE" },
        { id: "RIGHT ITSELF/DURING TESTING/FKUW", value: "RIGHT ITSELF/DURING TESTING/FKUW" },
        { id: "SIGNAL WIRE  ENTANGALMENT", value: "SIGNAL WIRE  ENTANGALMENT" },
        { id: "TESTED &amp; FOUND OK", value: "TESTED &amp; FOUND OK" },
        { id: "THEFT OF RELAY/MOTOR/EQUIPMENT ETC.", value: "THEFT OF RELAY/MOTOR/EQUIPMENT ETC." },
        { id: "TRACK SHORT BY COIN/OTHER ", value: "TRACK SHORT BY COIN/OTHER " },
        { id: "WHEEL JAMMED WITH STONE PIECE", value: "WHEEL JAMMED WITH STONE PIECE" },
        { id: "WIRE REMOVED FROM WHEEL", value: "WIRE REMOVED FROM WHEEL" },
    ],
    MECH: [
        { id: "DAMAGE BY HANGING PART", value: "DAMAGE BY HANGING PART" },
        { id: "OTHERS", value: "OTHERS" },
        { id: "TOKEN LOST", value: "TOKEN LOST" },
        { id: "TOKEN OVER CARRIED", value: "TOKEN OVER CARRIED" },
    ],
    MISC: [
        { id: "ALUMINIUM FOIL/OTHER ON GJ/IJ", value: "ALUMINIUM FOIL/OTHER ON GJ/IJ" },
        { id: "BOOM BROKEN", value: "BOOM BROKEN" },
        { id: "DAMAGED DUE TO CATTLE RUN OVER", value: "DAMAGED DUE TO CATTLE RUN OVER" },
        { id: "FIRE", value: "FIRE" },
        { id: "IRON  DUST/ CHIPS", value: "IRON  DUST/ CHIPS" },
        { id: "OTHERS", value: "OTHERS" },
        { id: "RDSO SPL/MOTOR TROLLEY MOVEMENT", value: "RDSO SPL/MOTOR TROLLEY MOVEMENT" },
        { id: "RIGHT ITSELF/DURING TESTING/FKUW", value: "RIGHT ITSELF/DURING TESTING/FKUW" },
        { id: "SHORTED BY IRON WIRE", value: "SHORTED BY IRON WIRE" },
        { id: "TESTED &amp; FOUND OK", value: "TESTED &amp; FOUND OK" },
        { id: "WIRE EATEN/CUT BY RAT", value: "WIRE EATEN/CUT BY RAT" },
    ],
    OPTG: [
        { id: "NINA", value: "NINA" },
        { id: "NO DEMONSTRATION TO S & T", value: "NO DEMONSTRATION TO S & T" },
        { id: "OTHERS", value: "OTHERS" },
        { id: "SHUNTING MOVEMENT", value: "SHUNTING MOVEMENT" },
        { id: "SIGNAL NOT LIT", value: "SIGNAL NOT LIT" },
        { id: "SIGNAL RAISED &amp; RELOWERED", value: "SIGNAL RAISED &amp; RELOWERED" },
        { id: "SLACK OPERATION ", value: "SLACK OPERATION " },
        { id: "STONE/OBSTR. IN POINT", value: "STONE/OBSTR. IN POINT" },
        { id: "TESTED &amp; FOUND OK", value: "TESTED &amp; FOUND OK" },
        { id: "TOKEN /EXHAUSTED", value: "TOKEN /EXHAUSTED" },
        { id: "TOKEN PITTED FOR NO POUCH", value: "TOKEN PITTED FOR NO POUCH" },
        { id: "WRONG ENTRY", value: "WRONG ENTRY" },
        { id: "WRONG OPERATION", value: "WRONG OPERATION" },
    ],
    OTHERS: [
        { id: "BATTERY RUN DOWN", value: "BATTERY RUN DOWN" },
        { id: "BCR BUTTON ON MUX PRESSED", value: "BCR BUTTON ON MUX PRESSED" },
        { id: "B/INSTT. FAILED DURING SHUNTING", value: "B/INSTT. FAILED DURING SHUNTING" },
        { id: "BLOCK BATTERY RUN DOWN", value: "BLOCK BATTERY RUN DOWN" },
        { id: "BLOCK BELL DEFF.", value: "BLOCK BELL DEFF." },
        { id: "BLOCK / CABIN TELEPHONE FAULTY", value: "BLOCK / CABIN TELEPHONE FAULTY" },
        { id: "BLOCK PANEL DEFECTIVE", value: "BLOCK PANEL DEFECTIVE" },
        { id: "BOOM BROKEN ITSELF.", value: "BOOM BROKEN ITSELF." },
    ],
    SNT: [
        { id: "ADJUSTED", value: "ADJUSTED" },
        { id: "AT SUPPLY FAULTY", value: "AT SUPPLY FAULTY" },
        { id: "AT SUPPLY FLUCTUATION", value: "AT SUPPLY FLUCTUATION" },
        { id: "AXLE COUNTER BY P-WAY PUSH TROLLY", value: "AXLE COUNTER BY P-WAY PUSH TROLLY" },
        { id: "AXLE COUNTER RESET", value: "AXLE COUNTER RESET" },
        { id: "BATT CHARGER DEFECTIVE", value: "BATT CHARGER DEFECTIVE" },
        { id: "BATTERY CONNECTING  LEAD WIRE BROKEN", value: "BATTERY CONNECTING  LEAD WIRE BROKEN" },
        {
            id: "BATTERY CONNECTING  STRIP OFFERING HIGH RESISTANCE",
            value: "BATTERY CONNECTING  STRIP OFFERING HIGH RESISTANCE",
        },
        { id: "BATTERY DEFECTIVE", value: "BATTERY DEFECTIVE" },
        { id: "BATTERY GIVING REVERSE POLARITY", value: "BATTERY GIVING REVERSE POLARITY" },
        { id: "BATTERY REPLACED", value: "BATTERY REPLACED" },
        { id: "BATTERY RUN DOWN", value: "BATTERY RUN DOWN" },
        { id: "BCR BUTTON ON MUX PRESSED", value: "BCR BUTTON ON MUX PRESSED" },
        { id: "B/INSTT. FAILED DURING SHUNTING", value: "B/INSTT. FAILED DURING SHUNTING" },
        { id: "BLOCK BATTERY RUN DOWN", value: "BLOCK BATTERY RUN DOWN" },
        { id: "BLOCK BELL DEFF.", value: "BLOCK BELL DEFF." },
        { id: "BLOCK / CABIN TELEPHONE FAULTY", value: "BLOCK / CABIN TELEPHONE FAULTY" },
        { id: "BLOCK PANEL DEFECTIVE", value: "BLOCK PANEL DEFECTIVE" },
        { id: "BOOM BROKEN ITSELF.", value: "BOOM BROKEN ITSELF." },
        { id: "BOOM HIT BY ANY ROAD VEHICLE", value: "BOOM HIT BY ANY ROAD VEHICLE" },
        { id: "BOOM LOCK NOT OPERATED", value: "BOOM LOCK NOT OPERATED" },
        { id: "BOOM WELDING BROKEN", value: "BOOM WELDING BROKEN" },
        { id: "BPAC CHANNEL ADJUSTED", value: "BPAC CHANNEL ADJUSTED" },
        { id: "BULB FUSED", value: "BULB FUSED" },
        { id: "BULB HOLDER CONTACT MISSING", value: "BULB HOLDER CONTACT MISSING" },
        { id: "BUTTON CONTACT BROKEN", value: "BUTTON CONTACT BROKEN" },
        { id: "BuZZER/BUTTON DEFECTIVE", value: "BuZZER/BUTTON DEFECTIVE" },
        { id: "CABLE CUT", value: "CABLE CUT" },
        { id: "CABLE CUT BY S&amp;T CONTACTOR", value: "CABLE CUT BY S&amp;T CONTACTOR" },
        { id: "CABLE FAULTY", value: "CABLE FAULTY" },
        {
            id: "CABLE / WIRE CONNECTION BROKEN/LOOSE or TERMINAL LOOSE",
            value: "CABLE / WIRE CONNECTION BROKEN/LOOSE or TERMINAL LOOSE",
        },
        { id: "CAPACITOR DEFECTIVE", value: "CAPACITOR DEFECTIVE" },
        { id: "CARBON BRUSH OFFERING HIGH RESISTANCE", value: "CARBON BRUSH OFFERING HIGH RESISTANCE" },
        { id: "CARBON DEPOSITION ON RELAY CONTACT", value: "CARBON DEPOSITION ON RELAY CONTACT" },
        { id: "CARD DEFECTIVE", value: "CARD DEFECTIVE" },
        { id: "CARD  DEFECTIVE", value: "CARD  DEFECTIVE" },
        { id: "CARD REPLACED", value: "CARD REPLACED" },
        { id: "CARD REPLUGGED", value: "CARD REPLUGGED" },
        { id: "CB CONTACT MISSING", value: "CB CONTACT MISSING" },
        { id: "CELL DEFCTIVE", value: "CELL DEFCTIVE" },
        { id: "CELL SHOWING REVERSE POLARITY", value: "CELL SHOWING REVERSE POLARITY" },
        { id: "CHANGEOVER OF E1 CONVERTER SHUT DOWN", value: "CHANGEOVER OF E1 CONVERTER SHUT DOWN" },
        { id: "CHANGE OVER SWITCH DEFECTIVE", value: "CHANGE OVER SWITCH DEFECTIVE" },
        { id: "CHANNEL VOLTAGE LOW", value: "CHANNEL VOLTAGE LOW" },
        { id: "CHARGER DEFECTIVE", value: "CHARGER DEFECTIVE" },
        { id: "CHARGER  DEFECTIVE", value: "CHARGER  DEFECTIVE" },
        { id: "CHARGER SWITCH DEFECTIVE", value: "CHARGER SWITCH DEFECTIVE" },
        { id: "CHOKE DEF", value: "CHOKE DEF" },
        { id: "CLEARED BY DUMMY WHEEL", value: "CLEARED BY DUMMY WHEEL" },
        { id: "COMMUNICATION ERROR", value: "COMMUNICATION ERROR" },
        { id: "COMMUNICATION LINK FAILED", value: "COMMUNICATION LINK FAILED" },
        { id: "COMPLETE SSDAC UNIT REPLACED", value: "COMPLETE SSDAC UNIT REPLACED" },
        { id: "CONDENSOR IN POINT GROUP REPLACED", value: "CONDENSOR IN POINT GROUP REPLACED" },
        { id: "CONDENSOR REPLACRED", value: "CONDENSOR REPLACRED" },
        { id: "CONTACT BROKEN", value: "CONTACT BROKEN" },
        { id: "CONTACT CLEANED", value: "CONTACT CLEANED" },
        { id: "CONTACT MISSING", value: "CONTACT MISSING" },
        { id: "CONTACT WIRE FAULTY", value: "CONTACT WIRE FAULTY" },
        { id: "COUNTING MISMATCH.", value: "COUNTING MISMATCH." },
        {
            id: "CRANK HANDLE CONTACT ASSEMBLY OF THE POINT MACHINE REPLACED",
            value: "CRANK HANDLE CONTACT ASSEMBLY OF THE POINT MACHINE REPLACED",
        },
        { id: "CRANK HANDLE CONTACT  MISSING", value: "CRANK HANDLE CONTACT  MISSING" },
        { id: "CRANK HANDLE RELEASED", value: "CRANK HANDLE RELEASED" },
        { id: "CURRENT REGULATOR DEF", value: "CURRENT REGULATOR DEF" },
        { id: "CURRENT REGULATOR DEFECTIVE", value: "CURRENT REGULATOR DEFECTIVE" },
        { id: "CURRENT REGULATOR REPLACED", value: "CURRENT REGULATOR REPLACED" },
        { id: "DC-DC CONVERTER FAILED", value: "DC-DC CONVERTER FAILED" },
        { id: "DEFECTIVE MOTOR TROLLY OF ENGG. DEPTT.", value: "DEFECTIVE MOTOR TROLLY OF ENGG. DEPTT." },
        { id: "DETECTION TROLLEY DEFECTIVE", value: "DETECTION TROLLEY DEFECTIVE" },
        { id: "DETECTOR MISADJUSTED", value: "DETECTOR MISADJUSTED" },
        { id: "DG DEFECTIVE", value: "DG DEFECTIVE" },
        { id: "DG STARTING ARRANGEMENT DEF", value: "DG STARTING ARRANGEMENT DEF" },
        { id: "DIP OUT OF ADJUSTMENT", value: "DIP OUT OF ADJUSTMENT" },
        { id: "DRUM AXLE BROKEN", value: "DRUM AXLE BROKEN" },
        { id: "DRY SOLDER", value: "DRY SOLDER" },
        { id: "EI HARDWARE RESET", value: "EI HARDWARE RESET" },
        { id: "EI MODULE DEFECTIVE", value: "EI MODULE DEFECTIVE" },
        { id: "EI MODULE REPLUGGED", value: "EI MODULE REPLUGGED" },
        { id: "EI MODULE RESET", value: "EI MODULE RESET" },
        { id: "EI SYSTEM HANG", value: "EI SYSTEM HANG" },
        { id: "EI-SYSTEM RESTARTED", value: "EI-SYSTEM RESTARTED" },
        { id: "EJB DEF", value: "EJB DEF" },
        { id: "ELB-LIMIT SWITCH DEFECTIVE", value: "ELB-LIMIT SWITCH DEFECTIVE" },
        { id: "ELB-LOCKING DEFECTIVE", value: "ELB-LOCKING DEFECTIVE" },
        { id: "ELB-MOTOR DEFECTIVE", value: "ELB-MOTOR DEFECTIVE" },
        {
            id: "Electromagnetic Coupling of the Track Device (TX/RX Coils) was readjusted.",
            value: "Electromagnetic Coupling of the Track Device (TX/RX Coils) was readjusted.",
        },
        { id: "ENGG. CONTRACTOR STAFF WORKING", value: "ENGG. CONTRACTOR STAFF WORKING" },
        { id: "ENGG. GATEMAN BACKED SIGNAL SLOT", value: "ENGG. GATEMAN BACKED SIGNAL SLOT" },
        { id: "ENGG GATEMAN LATE ATTENDED", value: "ENGG GATEMAN LATE ATTENDED" },
        { id: "ENGG. GATEMAN LATE CLOSED LC- GATE.", value: "ENGG. GATEMAN LATE CLOSED LC- GATE." },
        { id: "EQUIPMENT-CARD DEFECTIVE", value: "EQUIPMENT-CARD DEFECTIVE" },
        { id: "EQUIPMENT DEF.", value: "EQUIPMENT DEF." },
        { id: "ERROR IN  MUX", value: "ERROR IN  MUX" },
        { id: "ERROR IN TRANSMITTER/ RECIEVER", value: "ERROR IN TRANSMITTER/ RECIEVER" },
        { id: "E TYPE LOCK DEF.", value: "E TYPE LOCK DEF." },
        { id: "E-TYPE LOCK SPRING BROKEN", value: "E-TYPE LOCK SPRING BROKEN" },
        { id: "EVALUATOR DEF", value: "EVALUATOR DEF" },
        { id: "f", value: "f" },
        { id: "FAULT IN MUX", value: "FAULT IN MUX" },
        { id: "FAULTY PART REPLACED", value: "FAULTY PART REPLACED" },
        { id: "FEED END CHOKE DEFECTIVE", value: "FEED END CHOKE DEFECTIVE" },
        { id: "FEED END REGULATING RESISTANCE WAS REPLACED", value: "FEED END REGULATING RESISTANCE WAS REPLACED" },
        { id: "FINGER CONTACT MISSING", value: "FINGER CONTACT MISSING" },
        { id: "FLASHER RELAY DEFECTIVE", value: "FLASHER RELAY DEFECTIVE" },
        { id: "FRICTION CLUTCH DEFECTIVE", value: "FRICTION CLUTCH DEFECTIVE" },
        { id: "fUSE BLOWN OFF", value: "fUSE BLOWN OFF" },
        { id: "FUSE DEFECTIVE", value: "FUSE DEFECTIVE" },
        { id: "FUSE GRIP LOOSE", value: "FUSE GRIP LOOSE" },
        { id: "FUSE LOOSE", value: "FUSE LOOSE" },
        { id: "FUSE REINSERTED", value: "FUSE REINSERTED" },
        { id: "FUSE REPLACED", value: "FUSE REPLACED" },
        { id: "GAIN ADJUSTED", value: "GAIN ADJUSTED" },
        { id: "GATE BOOM BROKEN BY ANY VEHICLE", value: "GATE BOOM BROKEN BY ANY VEHICLE" },
        { id: "GATE BOOM LOCK SWITCH DEFECTIVE", value: "GATE BOOM LOCK SWITCH DEFECTIVE" },
        { id: "GATE HOOTER DEFECTIVE", value: "GATE HOOTER DEFECTIVE" },
        { id: "GATE LEVER CB MISADJUSTMENT", value: "GATE LEVER CB MISADJUSTMENT" },
        { id: "GATE LOCK ROD OBSTRUCTED/JAMMED", value: "GATE LOCK ROD OBSTRUCTED/JAMMED" },
        { id: "GATE MAGNETO TELEPHONE DEFECTIVE", value: "GATE MAGNETO TELEPHONE DEFECTIVE" },
        { id: "GATE OUT OF ADJUSTMENT", value: "GATE OUT OF ADJUSTMENT" },
        { id: "GATE-PART BROKEN", value: "GATE-PART BROKEN" },
        { id: "GATE-PART/WELDING BROKEN", value: "GATE-PART/WELDING BROKEN" },
        { id: "GATE TELEPHONE DEFECTIVE", value: "GATE TELEPHONE DEFECTIVE" },
        { id: "GATE TELEPHONE PROPER NOTHING RECTIFIED.", value: "GATE TELEPHONE PROPER NOTHING RECTIFIED." },
        { id: "GATE WIRE BROKEN", value: "GATE WIRE BROKEN" },
        { id: "GAUGE TIE PLATE BROKEN", value: "GAUGE TIE PLATE BROKEN" },
        { id: "GEAR BOX DEFECTIVE", value: "GEAR BOX DEFECTIVE" },
        { id: "GLASS FUSE SHOWING BREAK", value: "GLASS FUSE SHOWING BREAK" },
        { id: "GLUED JOINT SHORTED WITH CHUCK RAIL", value: "GLUED JOINT SHORTED WITH CHUCK RAIL" },
        { id: "HANDLE RELEASED ON NEXT TRAIN", value: "HANDLE RELEASED ON NEXT TRAIN" },
        { id: "HARD RESETTING", value: "HARD RESETTING" },
        { id: "HARDWARE RESET", value: "HARDWARE RESET" },
        { id: "HKT / EKT /T-2 LOCK DEF.", value: "HKT / EKT /T-2 LOCK DEF." },
        { id: "HMU DEF", value: "HMU DEF" },
        { id: "HOOTER DEFECTIVE", value: "HOOTER DEFECTIVE" },
        {
            id: "I/J SHORTED WITH IRON CHIPS/BURR/PANDROLCLIPS ETC",
            value: "I/J SHORTED WITH IRON CHIPS/BURR/PANDROLCLIPS ETC",
        },
        { id: "INDICATION FAILURE", value: "INDICATION FAILURE" },
        { id: "INDICATION FAULT", value: "INDICATION FAULT" },
        { id: "INDICATION LAMP LOOSE", value: "INDICATION LAMP LOOSE" },
        { id: "INDOOR RECEIVER REPLACED", value: "INDOOR RECEIVER REPLACED" },
        { id: "INSULATION BUSH CRUSHED", value: "INSULATION BUSH CRUSHED" },
        { id: "INSULATION/JT. SHORT", value: "INSULATION/JT. SHORT" },
        { id: "INSULATION NOT PROVIDED", value: "INSULATION NOT PROVIDED" },
        { id: "INSULATION OF POINT GAUGE TIE PLATE CRUSHED.", value: "INSULATION OF POINT GAUGE TIE PLATE CRUSHED." },
        { id: "INSULATION OF SSD ARM CRUSHED", value: "INSULATION OF SSD ARM CRUSHED" },
        { id: "IPS MODULE DEFECTIVE", value: "IPS MODULE DEFECTIVE" },
        { id: "IPS TRANSFORMER DEFECTIVE", value: "IPS TRANSFORMER DEFECTIVE" },
        { id: "JACK SHOWING BREAK", value: "JACK SHOWING BREAK" },
        { id: "JUMPER/ BOND  WIRE  BROKEN", value: "JUMPER/ BOND  WIRE  BROKEN" },
        { id: "JUMPER BROKEN", value: "JUMPER BROKEN" },
        { id: "JUMPER CABLE DEF", value: "JUMPER CABLE DEF" },
        { id: "KEY OF THE LEVER ENTANGLED IN LOCK", value: "KEY OF THE LEVER ENTANGLED IN LOCK" },
        { id: "LAMP FUSE", value: "LAMP FUSE" },
        { id: "LC CONTACT OF SGE BLOCK INSTRUMENT BROKEN", value: "LC CONTACT OF SGE BLOCK INSTRUMENT BROKEN" },
        { id: "LD DEFECTIVE", value: "LD DEFECTIVE" },
        { id: "LED/BULB BROKEN BY OUTSIDER", value: "LED/BULB BROKEN BY OUTSIDER" },
        { id: "LED &amp; CR REPLACED", value: "LED &amp; CR REPLACED" },
        { id: "LED/CR REPLACED", value: "LED/CR REPLACED" },
        { id: "LED DEF", value: "LED DEF" },
        { id: "LED FOR PANEL INDICATION REPLACED", value: "LED FOR PANEL INDICATION REPLACED" },
        { id: "LED REPLACED", value: "LED REPLACED" },
        { id: "LEVER LOCK DEF.", value: "LEVER LOCK DEF." },
        { id: "LINE CLEAR CANCELLED &amp; RETAKEN", value: "LINE CLEAR CANCELLED &amp; RETAKEN" },
        { id: "LINER AND PAD MISSING", value: "LINER AND PAD MISSING" },
        { id: "LINK ERROR", value: "LINK ERROR" },
        { id: "LINK FAILED", value: "LINK FAILED" },
        { id: "LOCAL SUPPLY FAULTY", value: "LOCAL SUPPLY FAULTY" },
        { id: "LOCK DEFECTIVE", value: "LOCK DEFECTIVE" },
        { id: "LOCK PAWL BROKEN", value: "LOCK PAWL BROKEN" },
        { id: "LOCK SLIDE BROKEN", value: "LOCK SLIDE BROKEN" },
        { id: "LOOSE PACKING", value: "LOOSE PACKING" },
        { id: "LOW VOLTAGE", value: "LOW VOLTAGE" },
        { id: "MACLS", value: "MACLS" },
        { id: "MCB TRIPPED", value: "MCB TRIPPED" },
        { id: "MISTAKE OF ENGG GATEMAN", value: "MISTAKE OF ENGG GATEMAN" },
        { id: "MODEM CARD REPLACED", value: "MODEM CARD REPLACED" },
        { id: "MODULE DEFECTIVE", value: "MODULE DEFECTIVE" },
        { id: "MODULE REPLACED", value: "MODULE REPLACED" },
        { id: "MODULE RESET", value: "MODULE RESET" },
        { id: "MODULE SHOWING RED", value: "MODULE SHOWING RED" },
        { id: "MOTER BELT  BROKEN", value: "MOTER BELT  BROKEN" },
        { id: "MOTER BELT DEFECTIVE", value: "MOTER BELT DEFECTIVE" },
        { id: "MOUTH PIECE OF WINCH LOCK DISTURBED", value: "MOUTH PIECE OF WINCH LOCK DISTURBED" },
        { id: "MOVEMENT OF P-WAY PUSH TROLLY", value: "MOVEMENT OF P-WAY PUSH TROLLY" },
        { id: "MSDAC-CARD DEFECTIVE", value: "MSDAC-CARD DEFECTIVE" },
        { id: "MSDAC-CARD REPLUGGED", value: "MSDAC-CARD REPLUGGED" },
        { id: "MSDAC-EJB DEFECTIVE", value: "MSDAC-EJB DEFECTIVE" },
        { id: "MSDAC-EVALUATOR DEFECTIVE", value: "MSDAC-EVALUATOR DEFECTIVE" },
        { id: "MSDAC-MODEM COMMUNICATION FAILURE", value: "MSDAC-MODEM COMMUNICATION FAILURE" },
        { id: "MSDAC-RESET", value: "MSDAC-RESET" },
        { id: "MSDAC-TX/RX COIL DEFECTIVE", value: "MSDAC-TX/RX COIL DEFECTIVE" },
        { id: "MUX RESET.", value: "MUX RESET." },
        { id: "NC FINGER CONTACT LOOSE", value: "NC FINGER CONTACT LOOSE" },
        { id: "NEEDLE JAMMED", value: "NEEDLE JAMMED" },
        { id: "NON-VITAL INPUT MODULE DEFECTIVE", value: "NON-VITAL INPUT MODULE DEFECTIVE" },
        { id: "NON-VITAL OUTPUT MODULE DEFECTIVE", value: "NON-VITAL OUTPUT MODULE DEFECTIVE" },
        {
            id: "NR/RR LATCH RELAY NOT ACTUATED IN THE POINT GROUP",
            value: "NR/RR LATCH RELAY NOT ACTUATED IN THE POINT GROUP",
        },
        { id: "OBSTRUCTION BETWEEN SWITCH AND TONGUE RAIL", value: "OBSTRUCTION BETWEEN SWITCH AND TONGUE RAIL" },
        { id: "OFC CABLE OPERATED BY RCIL STAFF", value: "OFC CABLE OPERATED BY RCIL STAFF" },
        { id: "OFC CHARGER OFF", value: "OFC CHARGER OFF" },
        { id: "OFC MODEM LINK FAILED", value: "OFC MODEM LINK FAILED" },
        { id: "OFC MUX RESET", value: "OFC MUX RESET" },
        { id: "OFC PATCH CORD FAULTY", value: "OFC PATCH CORD FAULTY" },
        { id: "OFC STM MUX FAULTY", value: "OFC STM MUX FAULTY" },
        { id: "OFFERING HIGH RESISTANCE", value: "OFFERING HIGH RESISTANCE" },
        { id: "OHE COPPER WIRE SHORTING  TRACK", value: "OHE COPPER WIRE SHORTING  TRACK" },
        { id: "OHE STAFF WORKING.", value: "OHE STAFF WORKING." },
        { id: "OTHERS", value: "OTHERS" },
        { id: "PANEL INDICATION LAMP FUSED", value: "PANEL INDICATION LAMP FUSED" },
        { id: "PANEL INDICATION LED DEFECTIVE", value: "PANEL INDICATION LED DEFECTIVE" },
        { id: "P BRACKETT INSULATUION CRUSHED", value: "P BRACKETT INSULATUION CRUSHED" },
        { id: "PEDESTAL MOTOR DEFECTIVE", value: "PEDESTAL MOTOR DEFECTIVE" },
        { id: "POINT GROUP DEFECTIVE", value: "POINT GROUP DEFECTIVE" },
        { id: "POINT GROUP REPLUGGED", value: "POINT GROUP REPLUGGED" },
        { id: "POINT INSULATION PUNCHERED", value: "POINT INSULATION PUNCHERED" },
        { id: "POINT LOCKING NOT RELEASED", value: "POINT LOCKING NOT RELEASED" },
        { id: "POINT MACHINE PART BROKEN", value: "POINT MACHINE PART BROKEN" },
        { id: "POINT MOTOR DEFECTIVE", value: "POINT MOTOR DEFECTIVE" },
        { id: "POINT OBSTRUCTING IN LOCK", value: "POINT OBSTRUCTING IN LOCK" },
        { id: "POINT OPERATION SUPPLY VOLTAGE LOW", value: "POINT OPERATION SUPPLY VOLTAGE LOW" },
        { id: "POINT OUT OF ADJUSTMENT", value: "POINT OUT OF ADJUSTMENT" },
        { id: "POINT RODING SHORTED", value: "POINT RODING SHORTED" },
        { id: "POINT RODS SHORTED", value: "POINT RODS SHORTED" },
        { id: "POOR BALLAST/POOR DRAINAGE", value: "POOR BALLAST/POOR DRAINAGE" },
        { id: "POWER CABLE DEF.", value: "POWER CABLE DEF." },
        { id: "POWER SUPPLY UNIT DEFECTIVE", value: "POWER SUPPLY UNIT DEFECTIVE" },
        { id: "PREMATURE TOL", value: "PREMATURE TOL" },
        { id: "PUSH BUTTON CONTACT MISSING", value: "PUSH BUTTON CONTACT MISSING" },
        { id: "PUSH BUTTON DEFECTIVE", value: "PUSH BUTTON DEFECTIVE" },
        { id: "PUSH BUTTON/KNOB DEFECTIVE", value: "PUSH BUTTON/KNOB DEFECTIVE" },
        { id: "P-WAY FITTING LOOSE", value: "P-WAY FITTING LOOSE" },
        { id: "RAIL-TDI STAFF WORKING", value: "RAIL-TDI STAFF WORKING" },
        { id: "RAIN WATER LEAKAGE IN RELAY ROOM", value: "RAIN WATER LEAKAGE IN RELAY ROOM" },
        { id: "RDSO/GLUED JOINT  DEF.", value: "RDSO/GLUED JOINT  DEF." },
        { id: "RDSO JOINT DEFECTIVE", value: "RDSO JOINT DEFECTIVE" },
        { id: "RECEIVER DEFECTIVE", value: "RECEIVER DEFECTIVE" },
        { id: "REGULATING RES. DEF.", value: "REGULATING RES. DEF." },
        { id: "REGULATING RESISTANCE FAULTY", value: "REGULATING RESISTANCE FAULTY" },
        { id: "RELAY CONNECTOR FOUND UNLOCKED", value: "RELAY CONNECTOR FOUND UNLOCKED" },
        { id: "RELAY CONNECTOR OFFERING HIGH RESISTANCE", value: "RELAY CONNECTOR OFFERING HIGH RESISTANCE" },
        { id: "RELAY CONNECTOR UNLOCKED", value: "RELAY CONNECTOR UNLOCKED" },
        { id: "RELAY CONTACT OFFERING HIGH RESISTANCE", value: "RELAY CONTACT OFFERING HIGH RESISTANCE" },
        { id: "RELAY DEFECTIVE", value: "RELAY DEFECTIVE" },
        { id: "RELAY DEFECTIVE(PLUG IN TYPE-METAL TO CARBON)", value: "RELAY DEFECTIVE(PLUG IN TYPE-METAL TO CARBON)" },
        { id: "RELAY DEFECTIVE(PLUG IN TYPE-METAL TO METAL)", value: "RELAY DEFECTIVE(PLUG IN TYPE-METAL TO METAL)" },
        { id: "RELAY GROUP  DEFECTIVE", value: "RELAY GROUP  DEFECTIVE" },
        { id: "RELAY REPLACED", value: "RELAY REPLACED" },
        { id: "RELAY  REPLUGGED", value: "RELAY  REPLUGGED" },
        { id: "RELAY REPLUGGED", value: "RELAY REPLUGGED" },
        {
            id: "RELEASED RAIL PANEL SHORTING WITH THE POSITIVE RAIL",
            value: "RELEASED RAIL PANEL SHORTING WITH THE POSITIVE RAIL",
        },
        { id: "RESETTING DONE", value: "RESETTING DONE" },
        { id: "RESETTING FROM MUX", value: "RESETTING FROM MUX" },
        { id: "RESISTANCE DEFECTIVE", value: "RESISTANCE DEFECTIVE" },
        { id: "RIGHT DURING TESTING", value: "RIGHT DURING TESTING" },
        { id: "RIGHT ITSELF/DURING TESTING/FKUW", value: "RIGHT ITSELF/DURING TESTING/FKUW" },
        { id: "RLY. BLOCK LINE FAULTY", value: "RLY. BLOCK LINE FAULTY" },
        { id: "ROLLER CAME OUT FROM CAM PATH", value: "ROLLER CAME OUT FROM CAM PATH" },
        { id: "ROTARY SWITCH DEFECTIVE", value: "ROTARY SWITCH DEFECTIVE" },
        { id: "ROUTE CANCELLED &amp; SIGNAL RELOWERED", value: "ROUTE CANCELLED &amp; SIGNAL RELOWERED" },
        { id: "ROUTE GROUP DEFECTIVE", value: "ROUTE GROUP DEFECTIVE" },
        { id: "ROUTE GROUP REPLACED", value: "ROUTE GROUP REPLACED" },
        { id: "ROUTE GROUP REPLUGGED", value: "ROUTE GROUP REPLUGGED" },
        { id: "ROUTE LED REPLACED", value: "ROUTE LED REPLACED" },
        { id: "ROUTE LOCKED", value: "ROUTE LOCKED" },
        { id: "ROUTE LOCKED &amp; RELEASED BY ESM KEY", value: "ROUTE LOCKED &amp; RELEASED BY ESM KEY" },
        { id: "ROUTE NOT RELEASED", value: "ROUTE NOT RELEASED" },
        { id: "ROUTE RELEASED BY EMERGENCY", value: "ROUTE RELEASED BY EMERGENCY" },
        { id: "RUSTY RAIL", value: "RUSTY RAIL" },
        { id: "RX CARD REPLACED", value: "RX CARD REPLACED" },
        { id: "SGE B/INSTT. JAMMED IN TOL", value: "SGE B/INSTT. JAMMED IN TOL" },
        { id: "SGE BI-UNIT DEFECTIVE", value: "SGE BI-UNIT DEFECTIVE" },
        { id: "SHELF TYPE RELAY DEFECTIVE", value: "SHELF TYPE RELAY DEFECTIVE" },
        { id: "SHORT BY OHE BOND", value: "SHORT BY OHE BOND" },
        { id: "SHUNTING MOVEMENT", value: "SHUNTING MOVEMENT" },
        { id: "SIGNAL GROUP  DEFECTIVE", value: "SIGNAL GROUP  DEFECTIVE" },
        { id: "SIGNAL GROUP REPLACED", value: "SIGNAL GROUP REPLACED" },
        { id: "SIGNAL GROUP REPLUGGED", value: "SIGNAL GROUP REPLUGGED" },
        { id: "SIGNALTRANSFORMER DEF", value: "SIGNALTRANSFORMER DEF" },
        { id: "SLACK OPERATION BY ENGG GATEMAN", value: "SLACK OPERATION BY ENGG GATEMAN" },
        { id: "SLEEPER OBSTRUCTED", value: "SLEEPER OBSTRUCTED" },
        { id: "SLIDE BROKEN", value: "SLIDE BROKEN" },
        { id: "SLIDE OBSTRUCTED", value: "SLIDE OBSTRUCTED" },
        { id: "SLOT BUTTON DEFECTIVE", value: "SLOT BUTTON DEFECTIVE" },
        { id: "SLOT DISC JAMMED", value: "SLOT DISC JAMMED" },
        { id: "SLOT INDICATION BULB FUSED", value: "SLOT INDICATION BULB FUSED" },
        { id: "SM SLIDE CONTACT MISSING/BROKEN", value: "SM SLIDE CONTACT MISSING/BROKEN" },
        { id: "SM SLIDE LOCKING JAMMED", value: "SM SLIDE LOCKING JAMMED" },
        { id: "SOIL EROSION DUE TO RAIN WATER", value: "SOIL EROSION DUE TO RAIN WATER" },
        { id: "SOLAR PANEL DEF", value: "SOLAR PANEL DEF" },
        { id: "SPRING OF LEVER LOCK DEFECTIVE", value: "SPRING OF LEVER LOCK DEFECTIVE" },
        { id: "SSD", value: "SSD" },
        { id: "SSDAC EQUIPMENT DEF", value: "SSDAC EQUIPMENT DEF" },
        { id: "SSDAC FAILED DUE TO JAMMED WHEEL", value: "SSDAC FAILED DUE TO JAMMED WHEEL" },
        { id: "SSDAC RESET", value: "SSDAC RESET" },
        { id: "SSD ADJUSTED BY P-WAY STAFF", value: "SSD ADJUSTED BY P-WAY STAFF" },
        { id: "SSD ARM INSULATION DAMAGED", value: "SSD ARM INSULATION DAMAGED" },
        { id: "SSD ARM READJUSTED BY P-WAY STAFF", value: "SSD ARM READJUSTED BY P-WAY STAFF" },
        { id: "STABLISER DEFECTIVE", value: "STABLISER DEFECTIVE" },
        { id: "S&amp;T STAFF WORKING", value: "S&amp;T STAFF WORKING" },
        { id: "SUDDEN TENSION OCCURED IN DOUBLE SLIP POINT", value: "SUDDEN TENSION OCCURED IN DOUBLE SLIP POINT" },
        { id: "SUPPLY BREAK", value: "SUPPLY BREAK" },
        { id: "SUPPLY FAILED", value: "SUPPLY FAILED" },
        { id: "SWITCH BURNT", value: "SWITCH BURNT" },
        { id: "SWITCH DEFECTIVE", value: "SWITCH DEFECTIVE" },
        { id: "T2 LOCK DEFECTIVE.", value: "T2 LOCK DEFECTIVE." },
        { id: "T2 LOCK GIVING  TROUBLE", value: "T2 LOCK GIVING  TROUBLE" },
        { id: "TELEPHONE REPLACED", value: "TELEPHONE REPLACED" },
        { id: "TERMINAL LOOSE", value: "TERMINAL LOOSE" },
        { id: "TERMINAL SHOWING OPEN", value: "TERMINAL SHOWING OPEN" },
        { id: "TESTED &amp; FOUND OK", value: "TESTED &amp; FOUND OK" },
        { id: "TGT/TCF CONTACT  MISSING", value: "TGT/TCF CONTACT  MISSING" },
        { id: "TIMER RELAY CAPACITOR  DEFECTIVE", value: "TIMER RELAY CAPACITOR  DEFECTIVE" },
        { id: "TIMER RELAY DEFECTIVE", value: "TIMER RELAY DEFECTIVE" },
        { id: "TIMER RELAY REPLACED", value: "TIMER RELAY REPLACED" },
        { id: "TRACK FAILED MOMENTARILY", value: "TRACK FAILED MOMENTARILY" },
        { id: "TRACK FEED CABLE JUMPER DAMAGED BY RAIL PANEL", value: "TRACK FEED CABLE JUMPER DAMAGED BY RAIL PANEL" },
        { id: "TRACK FEED CHARGER DEF", value: "TRACK FEED CHARGER DEF" },
        { id: "Track feed Resistance showing break", value: "Track feed Resistance showing break" },
        { id: "TRACK JUMPER BROKEN BY P-WAY STAFF", value: "TRACK JUMPER BROKEN BY P-WAY STAFF" },
        { id: "TRACK JUMPER REPLACED", value: "TRACK JUMPER REPLACED" },
        { id: "TRACK RELAY DEFECTIVE", value: "TRACK RELAY DEFECTIVE" },
        { id: "TRACK SHORTED BY METAL WIRE", value: "TRACK SHORTED BY METAL WIRE" },
        { id: "TRANSFORMER COIL GIVING INTERMITTENT TROUBLE", value: "TRANSFORMER COIL GIVING INTERMITTENT TROUBLE" },
        { id: "TRANSFORMER DEFECTIVE", value: "TRANSFORMER DEFECTIVE" },
        { id: "TRANSMETER DEFECTIVE", value: "TRANSMETER DEFECTIVE" },
        { id: "TROLLEY MOVEMENT", value: "TROLLEY MOVEMENT" },
        { id: "TU DEFECTIVE", value: "TU DEFECTIVE" },
        { id: "TUMBLER SPRING BROKEN", value: "TUMBLER SPRING BROKEN" },
        { id: "TX/RX BOND DEFECTIVE/WORNOUT", value: "TX/RX BOND DEFECTIVE/WORNOUT" },
        { id: "TX/RX  COIL  DEF.", value: "TX/RX  COIL  DEF." },
        { id: "TX/RX UNIT DEFECTIVE", value: "TX/RX UNIT DEFECTIVE" },
        { id: "UFSBI MUX REPLACED.", value: "UFSBI MUX REPLACED." },
        { id: "VARIABLE RESISTANCE DEFECTIVE", value: "VARIABLE RESISTANCE DEFECTIVE" },
        { id: "VDU MONITOR/ INTERFACE TROUBLE", value: "VDU MONITOR/ INTERFACE TROUBLE" },
        { id: "VDU RESTARTED", value: "VDU RESTARTED" },
        { id: "VITAL INPUT MODULE DEFECTIVE", value: "VITAL INPUT MODULE DEFECTIVE" },
        { id: "VITAL OUTPUT MODULE DEFECTIVE", value: "VITAL OUTPUT MODULE DEFECTIVE" },
        { id: "WIRE CONNECTION BROKEN/LOOSE", value: "WIRE CONNECTION BROKEN/LOOSE" },
        { id: "WIRE CONNECTION BROKEN/ LOOSE", value: "WIRE CONNECTION BROKEN/ LOOSE" },
        { id: "WIRE DISLODGED FROM WINCH/WHEEL", value: "WIRE DISLODGED FROM WINCH/WHEEL" },
        { id: "WIRE ENTANGLMENT", value: "WIRE ENTANGLMENT" },
        { id: "WN BUTTON DEFECTIVE", value: "WN BUTTON DEFECTIVE" },
        { id: "WRONG OPERATION BY ENGG/GATEMAN", value: "WRONG OPERATION BY ENGG/GATEMAN" },
    ],
};
