// import { createEntityAdapter } from "@reduxjs/toolkit";
import { GENERIC_BASE_PATH, PACKET_PARAM, trimAssetName } from "../../Common/EndPoints";
import { apiAlarms } from "../api/apiAlarms";

// const devicesAdapter = createEntityAdapter({ selectId: (device) => device.id });

export const extendedGenericAPIs = apiAlarms.injectEndpoints({
  endpoints: (builder) => ({
    getDevicesName: builder.query({
      query: (request) => {
        let params = { table_name: "device", col_name: "name", id_name: "deviceid" };
        if (request) params = { ...params, ...request.params };
        return {
          url: `${GENERIC_BASE_PATH}/distinct_with_id`,
          params,
        };
      },
      transformResponse: (response) => {
        let listWithTrimmedAssetName = response.map((asset) => ({ id: asset.id, value: trimAssetName(asset.value) }));
        return listWithTrimmedAssetName;
      },
      // transformResponse: (response) => devicesAdapter.setAll({ totalRecords: response.totalRecords }, response.alarms),
    }),
    getCustomersList: builder.query({
      query: () => {
        return {
          url: `${GENERIC_BASE_PATH}/distinct_with_id`,
          params: { table_name: "customer", col_name: "stncode", id_name: "customerid" },
        };
      },
      //   transformResponse: (response) => devicesAdapter.setAll({ totalRecords: response.totalRecords }, response.alarms),
    }),
    getDeviceTypes: builder.query({
      query: (request) => {
        let params = { table_name: "device_type", col_name: "label", id_name: "typeid" };
        if (request) params = { ...params, ...request.params };
        return {
          url: `${GENERIC_BASE_PATH}/distinct_with_id`,
          params,
        };
      },
      //   transformResponse: (response) => devicesAdapter.setAll({ totalRecords: response.totalRecords }, response.alarms),
    }),
    getCategories: builder.query({
      query: () => {
        return {
          url: `${GENERIC_BASE_PATH}/distinct`,
          params: { table_name: "alarm", col_name: "category" },
        };
      },
      //   transformResponse: (response) => devicesAdapter.setAll({ totalRecords: response.totalRecords }, response.alarms),
    }),
    getAlarmTypes: builder.query({
      query: () => {
        return {
          url: `${GENERIC_BASE_PATH}/distinct`,
          params: { table_name: "alarm", col_name: "type" },
        };
      },
      //   transformResponse: (response) => devicesAdapter.setAll({ totalRecords: response.totalRecords }, response.alarms),
    }),
    getSubGear: builder.query({
      query: ({ params }) => {
        return {
          url: PACKET_PARAM,
          params: { ...params, type: "packet_subgear", else_default: true },
        };
      },
    }),
  }),
});

export const {
  useGetDevicesNameQuery,
  useGetCustomersListQuery,
  useGetDeviceTypesQuery,
  useGetCategoriesQuery,
  useGetAlarmTypesQuery,
  useGetSubGearQuery,
} = extendedGenericAPIs;
