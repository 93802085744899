import axios from "axios";
import { APP_BASE_URL, GET_ALL_ALERTS, GET_ALL_DEVICES_API, LOGIN_API } from "../Common/EndPoints";
import http from "./HttpService";

function getStationsFromPermissions_(permissions) {
  let stations = [];
  let div = [];

  permissions?.forEach((zone) => {
    if (Object.keys(zone).length > 0) {
      const zones = zone;
      let divisions = Object.values(zones);

      divisions.forEach((data) => {
        data.forEach((dt) => {
          Object.entries(dt).forEach(([key, value]) => {
            div.push(key);
            value.forEach((v) => {
              stations.push(v);
            });
          });
        });
      });
    }
  });

  return stations;
}

export const doLogin = async (user, type) => {
  let apiRequest = null;
  let queryString = "";

  if (type === "email") {
    apiRequest = axios.get;
    queryString = `email=${encodeURIComponent(user.email)}&password=${encodeURIComponent(user.password)}`;

    // let res = await http.get(`${LOGIN_API}?${queryString}`);
    // res = await axios.get(`${APP_BASE_URL_DEFAULT}${LOGIN_API}?${queryString}`);
  } else if (type === "mobile") {
    apiRequest = axios.post;
    queryString = `apkSignature=${user.secretkey}&mobileNo=${user.email}&otp=${user.password}`;
    // res = await axios.post(`${APP_BASE_URL_DEFAULT}${LOGIN_API}`)
  }

  let response_object = {};
  let user_details = {};

  let res = await apiRequest(`${APP_BASE_URL}${LOGIN_API}?${queryString}`);
  if (res?.data?.success) {
    res.data.data.customerId = localStorage.getItem("customerId");
    const data = res?.data?.data;

    const token = data?.jwtToken;
    localStorage.setItem("token", token);
    window.jwtToken = token;

    const userId = data?.userId;
    localStorage.setItem("userId", userId);
    window.userId = userId ?? "";

    const roleHierarchies = data?.roleHierarchies;
    localStorage.setItem("roleHierarchies", JSON.stringify(roleHierarchies));

    const name = data?.name;
    localStorage.setItem("user", name);

    const email = data?.email;
    localStorage.setItem("email", email);

    const authority = data?.authority;
    localStorage.setItem("authority", authority);

    localStorage.setItem("customizedUserData", JSON.stringify({ sidebarOpen: false }));

    const refreshToken = data?.jwtRefreshToken;
    localStorage.setItem("refresh_token", refreshToken);
    window.refreshToken = refreshToken;

    const permissions = data?.permissions;
    localStorage.setItem("permissions", JSON.stringify(permissions));

    const frontendAccess = {};
    data?.frontendAccess?.forEach((role) => {
      const { readAll, readSelf, byCustomer, update, create } = role;
      frontendAccess[role.entity] = { readAll, readSelf, byCustomer, update, create };
    });
    localStorage.setItem("frontendAccess", JSON.stringify(frontendAccess));
    window.frontendAccess = frontendAccess;

    const selectedCustomers = getStationsFromPermissions_(permissions);
    localStorage.setItem("selected-customers", JSON.stringify(selectedCustomers));
    localStorage.setItem("permitted-customers", JSON.stringify(selectedCustomers));

    const selectedCustomer = selectedCustomers[0];
    localStorage.setItem("selected-customer", JSON.stringify(selectedCustomer));

    user_details = {
      permissions,
      userId,
      roleHierarchies,
      email,
      authority,
      frontendAccess,
      selectedCustomers,
      selectedCustomer,
      permittedCustomers: selectedCustomers,
    };
    response_object = { user: user_details, refreshToken, token };

    // window.refreshToken = data?.jwtRefreshToken;

    // let url = `${APP_BASE_URL}/api/user/data/NOQ`;
    // axios.get(url).then((res) => {
    //   localStorage.setItem("userStationDetail", JSON.stringify(res.data.data));
    // });
  }
  return response_object;
};
