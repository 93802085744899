import React, { useEffect } from 'react'
import './StationStatusCountCharts.scss'
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useImmer } from 'use-immer';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateAlarmFilters, setTemporaryAlarmFilters } from '../../../../features/Alarms/alarmsSlice';

const healthTypeColor = {
    healthy: 'green',
    warning: 'orange',
    critical: 'red',
    maintenance: 'brown',
}

const ChartComponent = ({ type, totalCounts, count }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleClick = (severityType) => {
        if (severityType !== 'healthy') {
            let filterParam = {
                severity: severityType.toUpperCase(), limit: 20,
                offset: 0,
                pageIndex: 0,
                is_active: true,
                categories: null,
                device_ids: null,
                device_type_ids: null,
                alarm_types: null,
                // customer_ids: null,
                from_ts: null,
                to_ts: null,
            }

            dispatch(setTemporaryAlarmFilters(filterParam))
            navigate('/alarms')
        }
        if (severityType === 'healthy') {
            navigate('/telemetry')
        }
    }

    return (
        <div className='chart-conatainer'>

            <div onClick={() => handleClick(type)} className='chart'>

                {/* <CircularProgressbar value={value} maxValue={1} text={`${value * 100}%`} />; */}
                <CircularProgressbar

                    value={count}
                    maxValue={totalCounts}
                    text={count.toString()}
                    // background
                    // backgroundPadding={6}
                    strokeWidth={5}
                    className="hover-translate cursor-pointer"
                    circleRatio={0.75}
                    styles={buildStyles({
                        backgroundColor: "#f97316",
                        // rotation: 0.5 + (1 - count / totalCounts) / 2,
                        // strokeLinecap: "butt",
                        rotation: 1 / 2 + 1 / 8,
                        textColor: healthTypeColor[type],
                        // pathColor: "#f97316",
                        // strokeLinecap: "butt",
                        // textColor: "#fff",
                        pathColor: healthTypeColor[type],
                        pathTransitionDuration: 0.15,
                        trailColor: "#BBBBBB",

                    })}
                ></CircularProgressbar>{" "}
            </div>
            <div style={{ color: healthTypeColor[type] }} className='chart-heading'>{type.toUpperCase()}</div>
        </div>
    )
}
const initialCounts = {
    healthy: 0,
    warning: 0,
    critical: 0,
    maintenance: 0,
    total: 0
}
function StationStatusCountCharts({ stationStatusCounts }) {
    const [count, setCount] = useImmer(initialCounts)
    const value = 0.66;
    useEffect(() => {
        let healthy = 0
        let warning = 0
        let critical = 0
        let maintenance = 0
        // console.log(stationStatusCounts)
        for (var i in stationStatusCounts) {
            healthy += stationStatusCounts[i].healthy;
            warning += stationStatusCounts[i].warning;
            critical += stationStatusCounts[i].critical;
            maintenance += stationStatusCounts[i].maintenance;
        }
        setCount((prev => {
            prev.healthy = healthy
            prev.warning = warning
            prev.critical = critical
            prev.maintenance = maintenance
            prev.total = healthy + warning + critical
        }))
    }, [stationStatusCounts])
    return (
        // <div className='station-count-container card'>
        //     <div className='header'>
        //         <div className='heading'>Station Asset Health Status</div>
        //     </div>
        //     <div className='section'>
        <>
            <ChartComponent key={'healthy'} type={'healthy'} totalCounts={count.total} count={count.healthy} />
            <ChartComponent key={'warning'} type={'warning'} totalCounts={count.total} count={count.warning} />
            <ChartComponent key={'critical'} type={'critical'} totalCounts={count.total} count={count.critical} />
            <ChartComponent key={'maintenance'} type={'maintenance'} totalCounts={count.total} count={count.maintenance} />
        </>
        //     </div>
        // </div>
    )
}

export default StationStatusCountCharts