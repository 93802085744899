import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Pagination,
  TablePagination,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { APP_BASE_URL, ASSET_MAINTENANCE_TABLE_API, GET_MAINTENACE_ASSET } from "../../../../Common/EndPoints";
import Loader from "../../../Loader/Loader";
import { Transition } from "../../../../Modal/ModalConstants";
import { calculateDayDifference, getFormattedDateTime } from "../Constants";
import { ActiveAlarmsData } from "../../../Alarms/ActiveAlarms";

// const TYPE_COLOR = {
//     OVERDUE: '#FB0007',
//     TODAY: '#7AC142',
//     UPCOMING: '#F47A1F',
//     DUE: '#4daf00'

// }
const TYPE_COLOR = {
  OVERDUE: "red",
  TODAY: "#7AC142",
  UPCOMING: "blue",
  DUE: "orange",
};

const AssetInfoCard = ({ content, type }) => {
  const remainingDaysOffset = calculateDayDifference(new Date().getTime(), content.expectedDt);
  return (
    <div className="asset-info-card">
      <div className="head card-sec">
        <div className="asset-name">{` ${content.gearName}`}</div>
        {/* <div className='assignee'>{content.assignUserName ?? 'Not aval.'}</div> */}
        <div className="assignee">{""}</div>
      </div>
      <div className="middle card-sec">
        <div className="sch-code middle-content">
          <div className="key">Schedule Code</div>
          <div className="value">{content.scheduleCode}</div>
        </div>
        <div className="overdue-days middle-content">
          <div className="key">{remainingDaysOffset < 0 ? "Days Overdue" : "Days Remaining"}</div>
          <div className="value">{Math.abs(remainingDaysOffset)}</div>
        </div>
        <div className="exp-date middle-content">
          <div className="key">Expected completion date</div>
          <div className="value" style={{ color: TYPE_COLOR[type] }}>
            {getFormattedDateTime(content.expectedDt)}
          </div>
        </div>
      </div>
      <div className="footer card-sec">
        <div className="remarks">{content.remark}</div>
      </div>
    </div>
  );
};

function MaintenanceInfo({ open, onClose, type }) {
  const [searchValue, setSearchValue] = useState("");
  const [cardState, setCardState] = useState([]);
  const [rowState, setRawState] = useState([]);
  const [page, setPage] = React.useState(1);
  const [loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const { customerIdList } = useContext(ActiveAlarmsData);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (customerIdList.length > 0) {
      setLoader(true);
      let url = `${APP_BASE_URL}${ASSET_MAINTENANCE_TABLE_API}`;
      let params = {
        page: page,
        pageSize: 20,
        status: type,
        customerIds: customerIdList.toString(),
      };
      // let url = `${APP_BASE_URL}${GET_MAINTENACE_ASSET}?page=${page}&pageSize=${20}&status=${type}`
      axios.get(url, { params }).then((res) => {
        let RESPONSE = res.data.data.content;
        setTotalPage(res.data.data.totalPages);
        setCardState(RESPONSE);
        setRawState(RESPONSE);
        setLoader(false);
      });
    }
  }, [page, customerIdList]);

  useEffect(() => {
    if (searchValue) {
      let newCardState = cardState.filter((card) => {
        return card.gearName.includes(searchValue) || card.scheduleCode.includes(searchValue);
      });
      setCardState(newCardState);
    } else {
      setCardState(rowState);
    }
  }, [searchValue]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      // sx={{minHeight:'20em'}}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="responsive-dialog-title " className="flex spaceBetween">
        {type}
        <CloseIcon
          className="maintenance-info-popup-close-icon pointer"
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>

      <DialogContent className="maintenance-information-popup" dividers>
        {loader && <Loader />}
        <div className="header">
          <FormControl fullWidth className="filter-input" variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">Search</InputLabel>
            <Input
              placeholder="Search by Asset Name, Sch. code..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value.toUpperCase());
              }}
              endAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="cards">
          {cardState.map((cardContent, i) => {
            return <AssetInfoCard key={i} content={cardContent} type={type} />;
          })}
        </div>
        <div className="footer"></div>
      </DialogContent>
      <DialogActions className="pop-actions">
        <Pagination count={totalPage} page={page} onChange={handleChange} shape="rounded" />
      </DialogActions>
    </Dialog>
  );
}

export default MaintenanceInfo;
