import { createSlice } from "@reduxjs/toolkit";
import { COPY_RULES, DELETE_RULE, GET_RULES, PACKET_PARAM, POST_RULE, PUT_RULE } from "../../Common/EndPoints";
import { apiAlarms } from "../api/apiAlarms";

const INITIAL_RULE_CONDITION_OBJECT = { name: "", min: "", max: "", unit: "" };
const DEFAULT_FORM_ERRORS = {
  customer_id: false,
  device_type_id: false,
  create_keys: false,
  clear_keys: false,
  create_condition: false,
  clear_condition: false,
  event_type: false,
  alarm_name: false,
  create_meta: false,
  clear_meta: false,
  history_window: false,
  severity: false,
  mqtt_subgear: false,
};
const INITIAL_FORM_STATE = {
  customer_id: null,
  device_type_id: null,
  create_keys: [INITIAL_RULE_CONDITION_OBJECT],
  clear_keys: [],
  create_condition: "",
  clear_condition: "",
  event_type: "",
  alarm_name: "",
  create_meta: [{ key: "", value: "" }],
  clear_meta: [],
  history_window: "",
  enable: false,
  severity: "",
  mqtt_subgear: null,
  device_ids: [],
};

const initialState = {
  type: "view", // view | create | edit
  formValues: INITIAL_FORM_STATE,
  formErrors: DEFAULT_FORM_ERRORS,
  showCopyRulesPopup: false,
  deleteRule: { showPopup: false, id: "" },
};

export const rulesAPIs = apiAlarms.injectEndpoints({
  endpoints: (builder) => ({
    getRules: builder.query({
      query: (_params = {}) => {
        let params = { with_customer: true, with_device_type: true, with_devices: true, ..._params };
        return {
          url: `${GET_RULES}`,
          params,
        };
      },
      providesTags: ["get-rules"],
    }),
    createRule: builder.mutation({
      query: ({ device_ids, body }) => {
        let params = device_ids && device_ids.length > 0 ? device_ids.toString() : null;
        const bodyString = JSON.stringify(body);
        return {
          url: `${POST_RULE}` + (params ? `?device_ids=${params}` : ""),
          method: "POST",
          body: bodyString,
        };
      },
      invalidatesTags: ["get-rules"],
    }),
    editRule: builder.mutation({
      query: ({ id, body, device_ids }) => {
        let params = device_ids && device_ids.length > 0 ? device_ids.toString() : null;
        const bodyString = JSON.stringify(body);
        return {
          url: `${PUT_RULE}${id}` + (params ? `?device_ids=${params}` : ""),
          method: "PUT",
          body: bodyString,
        };
      },
      invalidatesTags: ["get-rules"],
    }),
    deleteRule: builder.mutation({
      query: (id) => {
        return {
          url: `${DELETE_RULE}${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["get-rules"],
    }),
    copyRule: builder.mutation({
      query: (params) => {
        return {
          url: `${COPY_RULES}`,
          method: "POST",
          params,
        };
      },
      invalidatesTags: ["get-rules"],
    }),
    getRulesKeys: builder.query({
      query: ({ params }) => {
        return {
          url: PACKET_PARAM,
          params: { ...params, type: "packet_key", else_default: true },
        };
      },
    }),
  }),
});
export const {
  useGetRulesQuery,
  useCreateRuleMutation,
  useEditRuleMutation,
  useDeleteRuleMutation,
  useCopyRuleMutation,
  useGetRulesKeysQuery,
} = rulesAPIs;

const alarmRulesSlice = createSlice({
  name: "alarm_rules",
  initialState,
  reducers: {
    updateRulesActionType: (state, { payload }) => {
      state.type = payload;
    },
    updateRulesFormValues: (state, { payload }) => {
      state.formValues = { ...state.formValues, ...payload };
    },
    updateRulesFormErrors: (state, { payload }) => {
      state.formErrors = payload;
    },
    resetFormValues: (state) => {
      state.formValues = INITIAL_FORM_STATE;
      state.formErrors = DEFAULT_FORM_ERRORS;
    },
    resetRulesFormErrors: (state) => {
      state.formErrors = DEFAULT_FORM_ERRORS;
    },
    triggerCopyRulesPopup: (state, { payload }) => {
      state.showCopyRulesPopup = payload ?? !state.showCopyRulesPopup;
    },
    rulesDelete: (state, { payload }) => {
      state.deleteRule = payload;
    },
  },
});
export const {
  updateRulesActionType,
  updateRulesFormValues,
  resetRulesFormErrors,
  updateRulesFormErrors,
  resetFormValues,
  triggerCopyRulesPopup,
  rulesDelete,
} = alarmRulesSlice.actions;
export default alarmRulesSlice.reducer;

export const ruleTypeSelector = (state) => state.alarm_rules.type;
export const ruleFormValuesSelector = (state) => state.alarm_rules.formValues;
export const ruleFormErrorsSelector = (state) => state.alarm_rules.formErrors;
export const showCopyRulesPopup = (state) => state.alarm_rules.showCopyRulesPopup;
export const ruleShowDeletePopupSelector = (state) => state.alarm_rules.deleteRule;
