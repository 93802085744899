import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import "./DeleteModal.scss";
import { FormControl, TextField } from "@mui/material";
import { useImmer } from "use-immer";
import { APP_BASE_URL, RESET_PASSWORD } from "../Common/EndPoints";
import axios from "axios";
import Loader from "../Components/Loader/Loader";

const ResetPasswordModal = ({ onClose, updated }) => {
  const initialState = {
    email: "",
    emailError: false,
    oldPassword: "",
    oldPasswordError: false,
    newPassword: "",
    newPasswordError: false,
  };

  const [resetState, setResetState] = useImmer(initialState);
  const [loader, setLoader] = useImmer(false);

  const validateForm = () => {
    let isValid = true;
    if (!resetState.email || !resetState.oldPassword || !resetState.newPassword) {
      isValid = false;
    }
    setResetState((draft) => {
      draft.emailError = !resetState.email;
      draft.oldPasswordError = !resetState.oldPassword;
      draft.newPasswordError = !resetState.newPassword;
    });
    return isValid;
  };
  const resetPassword = () => {
    if (validateForm()) {
      setLoader(true);
      let url = `${APP_BASE_URL}${RESET_PASSWORD}email=${resetState.email}&newPass=${resetState.newPassword}&oldPass=${resetState.oldPassword}`;

      axios
        .post(url)
        .then((res) => {
          updated(true);
        })
        .catch((err) => {
          updated(false);
        })
        .then(() => {
          setLoader(false);
          onClose(false);
        });
    }
  };
  return (
    <div>
      <Dialog open={true} onClose={onClose} className="mainDialog" aria-labelledby="responsive-dialog-title">
        <DialogTitle className="flex dialogTitle" style={{ borderBottom: "1px solid " }} id="responsive-dialog-title">
          Change Password
          <CloseIcon
            className="pointer"
            sx={{ ml: "auto" }}
            onClick={() => {
              onClose(false);
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth>
            <TextField
              // fullWidth
              sx={{ width: "100%" }}
              className="email-field"
              onChange={(e) => {
                setResetState((draft) => {
                  draft.email = e.target.value;
                });
                // validateForm();
              }}
              required
              error={resetState.emailError}
              value={resetState.email}
              label={"Email"}
              helperText={resetState.emailError ? "Empty field!" : " "}
            />

            <TextField
              // fullWidth
              sx={{ width: "100%" }}
              className="old-password-field"
              onChange={(e) => {
                setResetState((draft) => {
                  draft.oldPassword = e.target.value;
                });
                // validateForm();
              }}
              required
              type={"password"}
              error={resetState.emailError}
              value={resetState.oldPassword}
              label={"Old password"}
              helperText={resetState.oldPasswordError ? "Empty field!" : " "}
            />
            <TextField
              // fullWidth
              sx={{ width: "100%" }}
              className="new-password-field"
              onChange={(e) => {
                setResetState((draft) => {
                  draft.newPassword = e.target.value;
                });
                // validateForm();
              }}
              required
              type={"password"}
              error={resetState.newPasswordError}
              value={resetState.newPassword}
              label={"New password"}
              helperText={resetState.newPasswordError ? "Empty field!" : " "}
            />
          </FormControl>
        </DialogContent>

        <DialogActions className="dialogBtn">
          <Button variant="outlined" autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            autoFocus
            onClick={() => {
              resetPassword();
            }}
          >
            Reset
          </Button>
        </DialogActions>
        {loader && <Loader />}
      </Dialog>
    </div>
  );
};

export default ResetPasswordModal;
