import axios from "axios";
import {
  APP_BASE_URL,
  DELETE_CUSTOMER_GROUP,
  GET_CUSTOMER_GROUP,
  POST_CUSTOMER_GROUP,
  PUT_CUSTOMER_GROUP,
} from "../../Common/EndPoints";
import { getPermittedCustomersList } from "../../Utils/UserData";

export function getCustomerGroup() {
  return axios.get(APP_BASE_URL + GET_CUSTOMER_GROUP);
}
export function createCustomerGroup(params) {
  return axios.post(APP_BASE_URL + POST_CUSTOMER_GROUP, null, { params });
}
export function editCustomerGroup(params) {
  return axios.put(APP_BASE_URL + PUT_CUSTOMER_GROUP, null, { params });
}
export function deleteCustomerGroup(params) {
  return axios.delete(APP_BASE_URL + DELETE_CUSTOMER_GROUP, { params });
}

export function getCustomersFromGroup(customerGroupMap) {
  let permissions = localStorage.getItem("permissions");
  if (permissions) permissions = JSON.parse(permissions);
  const { customerMap = {} } = getPermittedCustomersList();
  const customer_ids = [];
  const customer_group = customerGroupMap
    .map((groupMap) => {
      customer_ids.push(groupMap.customerId);
      return customerMap[groupMap.customerId];
    })
    .toString();
  return { customer_ids, customer_group };
}

export function getTableData(customerGroupList) {
  if (!customerGroupList || customerGroupList.length === 0) return [];
  const customer_group_list = [];
  customerGroupList?.data?.data?.forEach((group) => {
    if (group.name === "ALL") return;
    customer_group_list.push({ name: group.name, customer_group: group.customerGroupMaps, id: group.id });
  });
  return customer_group_list;
}
