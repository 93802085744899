import React, { useEffect, useRef, useState } from "react";
import "./YardZoomComponent.scss";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import { Tooltip } from "@mui/material";

function YardZoomComponent({ plus, minus, reset, fullscreen, zoomPer }) {
  const [ fadeAction, setFadeAction] = useState(false)
//   console.log(fadeAction)
// const fadeAction = useRef()
useEffect(()=>{
    // console.log(zoomPer)
    if(zoomPer===0){
        setFadeAction(true)
    }else setFadeAction(false)
},[zoomPer])


  return (
    <>
      <div className="zoom-component-main-container">
        <div className={`percent-container box-theme ${fadeAction ? 'fade' :''}`}>
          <span id="text" className="zoom-per-container-child">
            Zoom Percent:{" "}
          </span>
          <span id="percent" className="zoom-per-container-child">
            {zoomPer}%
          </span>
        </div>
        <div className="tool-container box-theme">
          <Tooltip placement="left" title={"Zoom In"} disableInteractive>
            <div
              className="act-btn-icon"
              onClick={() => {
                plus(+10);
              }}
            >
              <ZoomInOutlinedIcon className="icon" />
            </div>
          </Tooltip>
          <Tooltip placement="left" title={"Zoom Out"} disableInteractive>
            <div
              className="act-btn-icon"
              onClick={() => {
                minus(-10);
              }}
            >
              <ZoomOutOutlinedIcon className="icon" />
            </div>
          </Tooltip>
          <Tooltip placement="left" title={"Reset"} disableInteractive>
            <div
              className="act-btn-icon"
              onClick={() => {
                reset(0);
              }}
            >
              <RestartAltOutlinedIcon className="icon" />
            </div>
          </Tooltip>
          <Tooltip placement="left" title={"Fulllscreen"} disableInteractive>
            <div
              className="act-btn-icon"
              onClick={() => {
                fullscreen();
              }}
            >
              <FullscreenOutlinedIcon className="icon" />
            </div>
          </Tooltip>
        </div>
      </div>

      {/* <div id="fade-id" className="zoom-per-container">
        <span id="text" className="zoom-per-container-child">
          Zoom Percent:{" "}
        </span>
        <span id="percent" className="zoom-per-container-child">
          {zoomPer}%
        </span>
      </div>

      <div className="comp-main-container">
        <Tooltip placement="left" title={"Zoom In"} disableInteractive>
          <div
            className="act-btn-icon"
            onClick={() => {
              plus(+10);
            }}
          >
            <ZoomInOutlinedIcon className="icon" />
          </div>
        </Tooltip>
        <Tooltip placement="left" title={"Zoom Out"} disableInteractive>
          <div
            className="act-btn-icon"
            onClick={() => {
              minus(-10);
            }}
          >
            <ZoomOutOutlinedIcon className="icon" />
          </div>
        </Tooltip>
        <Tooltip placement="left" title={"Reset"} disableInteractive>
          <div
            className="act-btn-icon"
            onClick={() => {
              reset(0);
            }}
          >
            <RestartAltOutlinedIcon className="icon" />
          </div>
        </Tooltip>
        <Tooltip placement="left" title={"Fulllscreen"} disableInteractive>
          <div
            className="act-btn-icon"
            onClick={() => {
              fullscreen();
            }}
          >
            <FullscreenOutlinedIcon className="icon" />
          </div>
        </Tooltip>
      </div> */}
    </>
  );
}

export default YardZoomComponent;
