import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  alarmModalSelector,
  closeModal,
  selectAlarmEntities,
  updateAlarmsSelectedRows,
  useAckAlarmMutation,
  useClearAlarmMutation,
  useUploadAttachmentsMutation,
} from "./alarmsSlice";
import "./Alarms.scss";
import AlarmInfoPopup from "./AlarmInfoPopup";
import { toast } from "react-hot-toast";
import { getAlarmCategoryList } from "../../Utils/Utils";
import { useGetFMEARelationQuery } from "../../Services/data-apis";
import FMEView from "./components/fme-view";

const modalTitleText = {
  ack: "Do you want to ack this alarm?",
  clear: "Do you want to clear this alarm",
  multi_ack: "Do you want to ack these alarms?",
  multi_clear: "Do you want to clear these alarms?",
};
const ALLOWED_FILE_EXTENSIONS = [".png", ".jpg", ".jpeg", ".webp", ".csv", ".xlsx", ".pdf"];
const EXTENSION_NOT_SUPPORTED_MESSAGE = `Supported file extensions are ${ALLOWED_FILE_EXTENSIONS.join(", ")}`;

const DEFAULT_FMEA_STATE = { mode: null, cause: null, effect: null };

function hasErrorAddField_(state = DEFAULT_FMEA_STATE, data = []) {
  let error_status = false;
  let error_message = "";
  const { mode, cause, effect } = state;

  if (!mode || !cause || !effect) {
    error_status = true;
    error_message = "Please fill all fields";
  }

  if (!error_status) {
    if (data?.find((fmea) => fmea.id === state?.effect?.id)) {
      error_status = true;
      error_message = "Cannot Add Duplicate Entries";
    }
  }

  return { error_status, error_message };
}

const FmeaList = ({ data, handleChange }) => {
  const [state, setState] = useState(DEFAULT_FMEA_STATE);
  const { data: mode_list = [], isFetching: isLoadingModeList } = useGetFMEARelationQuery({ params: {} });
  const { data: cause_list = [], isFetching: isLoadingCauseList } = useGetFMEARelationQuery(
    { params: { mode_ids: mode_list.map((mode) => mode.mode.id) } },
    { skip: state?.mode?.mode?.value ? false : true },
  );
  const { data: effect_list = [], isFetching: isLoadingEffectList } = useGetFMEARelationQuery(
    {
      params: { mode_ids: mode_list.map((mode) => mode.mode.id), cause_ids: cause_list.map((cause) => cause.cause.id) },
    },
    { skip: state?.mode?.mode?.value && state?.cause?.cause?.value ? false : true },
  );
  const [error, setError] = useState(false);

  const handleStateChange = useCallback((type, value) => {
    setState((state) => ({ ...state, [type]: value, error: false }));
  }, []);

  function handleAddFMEA_() {
    setError(false);
    const _validate = hasErrorAddField_(state, data);
    if (_validate.error_status) {
      setError(true);
      toast.error(_validate.error_message);
      return;
    }

    handleChange((fmea_state) => {
      fmea_state.push(state.effect);
      return [...fmea_state];
    });
    setState(DEFAULT_FMEA_STATE);
  }

  function handleRemove_(_index) {
    handleChange((fmea_state) => {
      fmea_state.splice(_index, 1);
      return [...fmea_state];
    });
  }

  return (
    <div className="alarm__fmea--container">
      <div>
        <DialogContentText className="w-full">Mode</DialogContentText>
        <Autocomplete
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={state.mode || null}
          options={mode_list}
          loading={isLoadingModeList}
          getOptionLabel={(option) => option?.mode?.value || ""}
          onChange={(_, value) => handleStateChange("mode", value)}
          renderInput={(params) => (
            <TextField {...params} error={error && !state.mode ? true : false} placeholder="Select Mode" />
          )}
          size="small"
        />
      </div>
      <div>
        <DialogContentText className="w-full">Cause</DialogContentText>
        <Autocomplete
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={state.cause || null}
          options={cause_list}
          loading={isLoadingCauseList}
          getOptionLabel={(option) => option?.cause?.value || ""}
          onChange={(_, value) => handleStateChange("cause", value)}
          renderInput={(params) => (
            <TextField {...params} error={error && !state.cause ? true : false} placeholder="Select Cause" />
          )}
          size="small"
        />
      </div>
      <div>
        <DialogContentText className="w-full">Effect</DialogContentText>
        <Autocomplete
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={state.effect || null}
          options={effect_list}
          loading={isLoadingEffectList}
          getOptionLabel={(option) => option?.effect?.value || ""}
          onChange={(_, value) => handleStateChange("effect", value)}
          renderInput={(params) => (
            <TextField {...params} error={error && !state.effect ? true : false} placeholder="Select Effect" />
          )}
          size="small"
        />
      </div>
      <div className="flex justify-end">
        <Button variant="outlined" onClick={handleAddFMEA_}>
          Add (FME)
        </Button>
      </div>
      {/* <div className="fmea-list">
        {data.map((fmea, index) => {
          return (
            <div key={"fmea-list-item" + fmea.id} className="fmea-list__row">
              <div className="data--container">
                <p className="mode">
                  <span>Mode:</span> {fmea?.mode?.value}
                </p>
                <p className="cause">
                  <span>Cause:</span> {fmea?.cause?.value}
                </p>
                <p className="effect">
                  <span>Effect:</span> {fmea?.effect?.value}
                </p>
              </div>
              <RemoveCircleOutlineIcon className="cursor-pointer" onClick={() => handleRemove_(index)}>
              clear
              </RemoveCircleOutlineIcon>
            </div>
          );
        })}
      </div> */}
      {/* <div className="alarm__fmea-view"> */}
      <FMEView data={data} handleRemove_={handleRemove_} />
      {/* </div> */}
    </div>
  );
};

export const AlarmActionsModal = () => {
  const dispatch = useDispatch();
  const { modalType, alarmId, isModalOpen: open } = useSelector(alarmModalSelector);
  const alarms = useSelector(selectAlarmEntities);
  const [ackAlarm, { isSuccess: alarmAckSuccess, reset: resetAlarmAck }] = useAckAlarmMutation();
  const [clearAlarm, { isSuccess: alarmClearSuccess, reset: resetAlarmClear }] = useClearAlarmMutation();
  const [uploadAttachments, { isSuccess: uploadAttachmentsSuccess, reset: resetUploadAttachments }] =
    useUploadAttachmentsMutation();

  // const alarmFilters = useSelector(alarmFiltersSelector);
  const categoryRef = useRef();
  // const { data: categoriesList = [] } = useGetCategoriesQuery(null);
  const categoriesList = getAlarmCategoryList();

  const [categoryValue, setCategoryValue] = useState("");
  const [remarkValue, setRemarkValue] = useState("");
  const [fmeaState, setFmeaState] = useState([]);

  const [attachmentsData, setAttachmentsData] = useState([]);

  const categoriesOptions = categoriesList.map((category) => (
    <MenuItem key={category} value={category}>
      {category}
    </MenuItem>
  ));

  const handleClose = useCallback(() => dispatch(closeModal()), []);

  // const { data: alarms = {} } = useGetPaginatedAlarmsQuery(alarmFilters);

  useEffect(() => {
    function handleModalClose() {
      setCategoryValue("");
      setRemarkValue("");
      handleClose();
      dispatch(updateAlarmsSelectedRows([]));
    }
    if (alarmAckSuccess) {
      toast.success("Alarm Ack Success");
      resetAlarmAck();
      handleModalClose();
    } else if (alarmClearSuccess) {
      toast.success("Alarm Clear Success");
      resetAlarmClear();
      handleModalClose();
    }
  }, [alarmAckSuccess, alarmClearSuccess]);
  useEffect(() => {
    if (uploadAttachmentsSuccess) {
      toast.success("Upload Attachments Success");
      resetUploadAttachments();
      return;
    }
  }, [uploadAttachmentsSuccess]);

  const handleConfirm = () => {
    const params = { user_id: localStorage.getItem("userId"), ids: alarmId };
    const body = {};
    if (categoryValue) params.category = categoryValue;
    if (remarkValue) body.remark = remarkValue;
    if (fmeaState && fmeaState.length !== 0) params.fmea_ids = fmeaState.map((fmea) => fmea.id).toString();
    if (modalType === "ack" || modalType === "multi_ack") {
      ackAlarm({ params, body });
      if (attachmentsData.length > 0) {
        const files = new FormData();
        attachmentsData.forEach((file) => {
          files.append("files", file);
        });
        const params = { entity_id: alarmId };
        const body = files;
        uploadAttachments({ params, body });
      }
    } else if (modalType === "clear" || modalType === "multi_clear") {
      clearAlarm({ params, body });
    }
  };

  if (!open) return <></>;
  if (modalType === "info") {
    return <AlarmInfoPopup open={open} alarmInfo={alarms[alarmId]} />;
  }

  const modalTitle = modalTitleText[modalType];

  const UploadAttachmentsWidget = () => {
    const attachmentUploadRef = useRef();
    // console.log("attachmentUploadRef", attachmentUploadRef);
    function addAttachment() {
      const attachmentFiles = [...attachmentUploadRef.current.files];
      for (const _attachment of attachmentFiles) {
        const _name = _attachment.name.split(".");
        const _extension = "." + _name.slice(-1);

        if (!_extension) {
          console.error("extension not found", _attachment);
        }

        if (!ALLOWED_FILE_EXTENSIONS.includes(_extension.toLowerCase())) {
          toast.error(EXTENSION_NOT_SUPPORTED_MESSAGE);
          attachmentUploadRef.current.value = null;
          return;
        }
      }
      setAttachmentsData((state) => [...state, ...attachmentFiles]);
      attachmentUploadRef.current.value = null;
    }
    function removeAttachment(index) {
      const attachmentFiles = [...attachmentsData];
      attachmentFiles.splice(index, 1);
      setAttachmentsData(attachmentFiles);
    }
    return (
      <div className="alarm-attachment-upload" style={{ marginTop: "1rem" }}>
        Attachments
        <div className="flex items-center gap-2 justify-between">
          <div className="file-input">
            <input
              type="file"
              id="file"
              accept={ALLOWED_FILE_EXTENSIONS.join(", ")}
              className="file"
              ref={attachmentUploadRef}
              onChange={addAttachment}
              multiple
            />
            <label htmlFor="file">SELECT FILE</label>
          </div>
        </div>
        <div className="mt-4">
          {attachmentsData.map(({ name }, index) => {
            return (
              <div className="alarm-attachment-row flex items-center gap-4" key={"alarm-attachment-row" + index}>
                <div
                  onClick={() => {
                    removeAttachment(index);
                  }}
                  className="cursor-pointer"
                >
                  X
                </div>
                <div>{name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      // aria-labelledby="alert-dialog-title"
      // aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">Do you want to {modalType} this Alarm?</DialogContentText> */}
        <DialogContentText style={{ width: "100%" }}>Category</DialogContentText>
        <Select
          style={{ width: "100%" }}
          placeholder="category"
          ref={categoryRef}
          value={categoryValue}
          onChange={({ target: { value } }) => setCategoryValue(value)}
          size="small"
        >
          {categoriesOptions}
        </Select>
        {(modalType === "ack" || modalType === "multi_ack") && (
          <>
            <TextField
              label="Remarks"
              style={{ width: "100%", marginTop: "1rem" }}
              placeholder="Remarks"
              value={remarkValue}
              onChange={({ target: { value } }) => setRemarkValue(value)}
              size="small"
            />
            <FmeaList data={fmeaState} handleChange={setFmeaState} />
          </>
        )}

        {modalType === "ack" && <UploadAttachmentsWidget />}
        {/* <select style={{ width: "100%" }} placeholder="category" ref={categoryRef}>
          {categoriesOptions}
        </select> */}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConfirm} type="submit">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AlarmActionsModal);
