import { Chip, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import DeleteIcon from "@mui/icons-material/Delete";

function ReactiveSecMarkup({ parentData, getParentPopUpData, editInfo }) {
    const [reactiveSecState, setReactiveSecState] = useImmer({});
    let LabelProps = {};
    if (parentData.type === "date") {
        LabelProps = {
            InputLabelProps: {
                shrink: parentData.type === "date",
            },
        };
    }
    useEffect(() => {
        if (reactiveSecState) {
            getParentPopUpData({ reactiveKey: parentData.key, reactiveValue: reactiveSecState.options });
        }
    }, [reactiveSecState, parentData]);
    useEffect(() => {
        if (editInfo) {
            setReactiveSecState((prev) => {
                prev.options = editInfo.reactiveValue;
            });
        }
    }, []);
    const removeOption = (i) => {
        let updatedOptions = [...reactiveSecState.options];
        if (i || i === 0) {
            updatedOptions.splice(i, 1);
        }
        setReactiveSecState((prev) => {
            prev.options = updatedOptions;
        });
    };

    const getParentDataOptions = () => {
        let options = []
        if (parentData.type === "dropdown") {
            options = [...parentData?.options]
        }
        if (parentData.type === "reactive-dropdown" || parentData.type === "reactive_dropdown") {
            Object.values(parentData.reactiveValue).forEach((val) => options.push(...val))
        }
        return options
    }

    return (
        <div className="dropdownMarkup">
            <div className="headingPart">
                <h2 className="dd-heading">Reactive Values</h2>
            </div>
            <div className="optionActPart">
                {(parentData.type === "dropdown" || parentData.type === "reactive-dropdown" || parentData.type === "reactive_dropdown") && (
                    <div className="option-input">
                        <FormControl fullWidth>
                            <InputLabel id="standard-label">{"Options"}</InputLabel>
                            <Select
                                defaultValue=""
                                id="select-standard"
                                className="field"
                                label="Options"
                                value={reactiveSecState?.options ?? []}
                                onChange={(e) => {
                                    setReactiveSecState((prev) => {
                                        prev.options = e.target.value;
                                        // prev.optionValue = ''
                                    });
                                }}
                                multiple
                            >
                                {getParentDataOptions().map((value, i) => {
                                    return (
                                        <MenuItem key={i} value={value}>
                                            {value}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {(parentData.type !== "dropdown" ||
                    parentData.type !== "reactive-dropdown" ||
                    parentData.type !== "reactive_dropdown") && (
                        <>
                            <div className="option-input">
                                <TextField
                                    label="Options"
                                    size="small"
                                    type={parentData.type}
                                    placeholder="Add Options"
                                    value={reactiveSecState.optionValue}
                                    onChange={(e) => {
                                        setReactiveSecState((prev) => {
                                            prev.optionValue = e.target.value;
                                        });
                                    }}
                                    {...LabelProps}
                                />
                                <div
                                    onClick={() => {
                                        if (reactiveSecState.optionValue.length > 0) {
                                            let newOptions = reactiveSecState.options ? [...reactiveSecState.options] : [];
                                            newOptions.push(reactiveSecState.optionValue);

                                            setReactiveSecState((prev) => {
                                                prev.options = newOptions;
                                                prev.optionValue = "";
                                            });
                                        }
                                    }}
                                    className="addBtn pointer"
                                >
                                    add
                                </div>
                            </div>
                            <div className="option-preview">
                                {reactiveSecState?.options &&
                                    reactiveSecState?.options.map((opt, i) => {
                                        return (
                                            <Chip
                                                label={opt}
                                                className="option-chip"
                                                onDelete={() => {
                                                    removeOption(i);
                                                }}
                                                deleteIcon={<DeleteIcon className="chip-icon" />}
                                                variant="outlined"
                                            />
                                        );
                                    })}
                            </div>
                        </>
                    )}
            </div>
        </div>
    );
}

export default ReactiveSecMarkup;
