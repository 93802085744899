import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { DetailsByUrl } from './ReportsConstants';
import './Reports.scss'
import DataTable from '../DataGrid/DataGrid';
import { ActiveAlarmsData } from '../Alarms/ActiveAlarms';
import { APP_BASE_URL_ALARM } from '../../Common/EndPoints';
import axios from 'axios';
import EachStnChartComponent from './COMPONENTS/LineChart';
import LineChart from './COMPONENTS/LineChart';
import StackedBarChart from './COMPONENTS/StackedBarChart';
import { useDispatch, useSelector } from 'react-redux';
import { claerAllReportingFilters, selectCurrentReportFilters, selectGlobelReportFilter, selectReportFilter, selectTempReportFilter, updateTempFilterOnNavBack, updateTempReportFilters } from './reportsSlice';
import { updateReportFilters } from './reportsSlice';
import Filters from './COMPONENTS/ReportFilters';
import ReportFilters from './COMPONENTS/ReportFilters';
import NewReportsFilter from './New Report filters/NewReportsFilter';
import { filter } from 'rxjs';
// import { StackedBarChart } from '@mui/icons-material';

function Reports() {
  const location = useLocation();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const url = location.pathname;

  useEffect(() => {
    if (!Object.keys(DetailsByUrl).includes(url)) {
      navigate('/reports/failures-by-devices')
    }
  }, [])





  const globelReportsFilters = useSelector(selectGlobelReportFilter)
  const tempReportsFilter = useSelector(selectTempReportFilter)
  const currentReportFilters = useSelector(selectCurrentReportFilters)


  const { customerIdList } = useContext(ActiveAlarmsData)
  const [rawData, setRawData] = useState([])


  // const [fullScreen, setFullScreen] = useState('')
  // const reportFilters = useSelector(selectReportFilter)
  useEffect(() => {
    // Define your callback function


    // Add the event listener when the component mounts
    window.addEventListener('popstate', handleBackNavigation);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, []);


  useEffect(() => {
    if (customerIdList.length > 0) {
      let filterParams = {
        ...currentReportFilters,
        filterCustomerIds: currentReportFilters.filterCustomerIds.map((customer) => {
          return customer.customerId
        }).toString(),
        device_type_ids: currentReportFilters.device_type_ids.map((geartype) => {
          return geartype.id
        }).toString(),
        device_ids: currentReportFilters.device_ids.map((device) => {
          return device.id
        }).toString(),
        categories: currentReportFilters.categories.toString(),
        alarm_types: currentReportFilters.alarm_types.toString(),

      }
      filterParams = { ...filterParams, customer_ids: filterParams.filterCustomerIds.length > 0 ? filterParams.filterCustomerIds : customerIdList.toString() }
      fetchData(DetailsByUrl[url]?.getUrl, filterParams)
    }
  }, [url, currentReportFilters])
  // useEffect(() => {
  //   if (customerIdList.length > 0) {
  //     let filterParams = { customer_ids: customerIdList.toString(), ...reportFilters }
  //     filterParams.device_type_ids = reportFilters.device_ids ? reportFilters.device_type_ids.map((value) => {
  //       return (value.id)
  //     }).toString() : null
  //     filterParams.categories = reportFilters.categories ? reportFilters.categories?.toString() : null
  //     filterParams.alarm_types = reportFilters.alarm_types ? reportFilters.alarm_types?.toString() : null
  //     filterParams.device_ids = reportFilters.device_ids ? reportFilters.device_ids.map((value) => {
  //       return (value.id)
  //     }).toString() : null
  //     fetchData(DetailsByUrl[url].getUrl, filterParams)
  //   }
  // }, [reportFilters])
  useEffect(() => {
    return () => {
      dispatch(claerAllReportingFilters())
    }
  }, [])
  function handleBackNavigation(event) {
    dispatch(updateTempFilterOnNavBack())
  }
  const fetchData = (fetchUrl, filterParams) => {
    setRawData([])
    let url = `${APP_BASE_URL_ALARM}${fetchUrl}`
    axios.get(url, { params: filterParams }).then((res) => {
      let RESPONSE = res.data
      RESPONSE.forEach((row, i) => {
        row.id = i
      })
      setRawData(RESPONSE)
    })
  }
  // let gidDesign = /reports/failures-by-devices
  // function clickFunction(){
  //     dispatch(updateReportFilters({b: 4214}))
  // }
  if (!Object.keys(DetailsByUrl).includes(url)) {
    // navigate('/users')
    return <></>
  }
  return (
    <div className={`reports-conatiner`}>

      {/* <div className={`reports-conatiner ${url === '/reports/failures-by-devices' ? 'gridDevice' : 'gridOthers'}`}> */}
      <div className='pageHeader'>{DetailsByUrl[url]?.name}</div>

      {/* <div className='filterBox reportsCard'>
        <ReportFilters getFilters={(filters) => { dispatch(updateReportFilters(filters)) }} />
      </div> */}
      <div className='filterBox reportsCard'>
        <NewReportsFilter />
      </div>
      <div className='tableBox reportsCard'>
        <DataTable
          loading={false}
          tableInfo={{
            id: `test-table`,
            label: "TEST TABLE",
            requireAuthSign: false,
          }}
          rows={rawData}
          columns={DetailsByUrl[url]?.tableColumns}
        />
      </div>
      {/* {url !== '/reports/failures-by-devices' && (<div className='eachStationChartBox card'>
            <LineChart dependsOn={'station'} data={rawData}/>
            </div>)} */}
      <div className='eachStationChartBox reportsCard'>
        <LineChart dependsOn={'station'} data={rawData} dependsOnSignleData={DetailsByUrl[url]?.dependentOn} />
      </div>
      <div className='eachTypeChartBox reportsCard'>
        <LineChart dependsOn={DetailsByUrl[url]?.dependentOn} data={rawData} />
      </div>
      <div className='eachStationByTypeChartBox reportsCard'>
        <StackedBarChart dependsOn={DetailsByUrl[url]?.dependentOn} response={rawData} />
      </div>
    </div>
  )
}

export default Reports