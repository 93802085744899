const YARD_THRESHOLD = {
  TC: {
    //occupied ranges
    VR: [{ min: null, max: 1.2 }],
    DLO: {
      VR: [{ min: null, max: 0.9 }],
    },
    MBG: {
      VR: [{ min: null, max: 0.9 }],
    },
    NHLG: {
      VR: [{ min: null, max: 0.9 }],
    },
    NHGJ: {
      VR: [{ min: null, max: 0.9 }],
    },
  },
  PM: {
    //failure
    OT: [{ min: 12, max: null }],
    BCA_station: {
      RWKR: [{ min: 18, max: null }],
      //normal chceck
      NWKR: [{ min: 18, max: null }],
    }
    //reverse check
    // RRWKR: [{ min: 18, max: null }],
    // RWKR: [{ min: 18, max: null }],
    // //normal chceck
    // RNWKR: [{ min: 18, max: null }],
    // NWKR: [{ min: 18, max: null }],
  },

  SG: {
    PL: [{ min: 90, max: null }],
    VON: [{ min: 90, max: null }],
    VOFF: [{ min: 90, max: null }],
    VS: [{ min: 90, max: null }],
    IHHG: [{ min: 90, max: null }],
    IHG: [{ min: 90, max: null }],
    IDG: [{ min: 90, max: null }],
    IRG: [{ min: 90, max: null }],
  },
  LCG: {
    AR: [{ min: 18, max: null }],
    BR: [{ min: 18, max: null }],
  },
  AC: {
    //chcek red
    VPWR: [{ min: null, max: 18 }],
    VR: [{ min: 5, max: 18 }],
    // check orange
    PR: [{ min: 18, max: null }],
    RST: [{ min: 44, max: null }],
  },
  AXL: {
    //chcek red
    VPWR: [{ min: null, max: 18 }],
    VR: [{ min: 5, max: 18 }],
    // check orange
    PR: [{ min: 18, max: null }],
    RST: [{ min: 44, max: null }],
  },
  BB: {
    //chcek green
    DC24: [{ min: 18, max: null }],
    AC110: [{ min: 90, max: null }],
    DC110: [{ min: 50, max: null }],

    orange: [],
    green: [],
  },
};

export const getThresholdRanges = (geartype, station, key) => {
  let thresholdValueRanges = null;
  const gearTypePacket = YARD_THRESHOLD?.[geartype];
  if (key === "IRG") {
    console.log("red ranges", gearTypePacket);
  }
  console.log();
  if (gearTypePacket) {
    const stationKey = `${station}_station`;
    if (gearTypePacket?.[stationKey]) {
      thresholdValueRanges =
        gearTypePacket?.[stationKey]?.[key] && gearTypePacket?.[stationKey]?.[key].length > 0
          ? gearTypePacket?.[stationKey]?.[key]
          : null;
    } else {
      thresholdValueRanges = gearTypePacket?.[key] && gearTypePacket?.[key].length > 0 ? gearTypePacket?.[key] : null;
    }
  }
  return thresholdValueRanges;
};

export const checkRangeValidation = ({ _value, devide, geartype, station, keyName }) => {
  let inRange = false;
  let value = Number(_value);
  if (devide) value = value / 1000;
  let ranges = getThresholdRanges(geartype, station, keyName);
  console.log('rangesd', keyName, ranges)
  if (keyName === "IRG") {
  }
  if (ranges) {
    ranges.forEach((range, i) => {
      let minValue = range.min;
      let maxValue = range.max;
      if (minValue && maxValue) {
        inRange = value > minValue && value < maxValue;
      } else {
        if (minValue) {
          inRange = value > minValue;
        }
        if (maxValue) {
          inRange = value < maxValue;
        }
      }
    });
  }
  if (geartype === "PM") {
    console.log("rangeCheck", keyName, value, ranges, inRange);
  }

  return inRange;
};

export const getAllValidValues = (valueArray) => {
  let keyList = [];
  valueArray.forEach((keyValue) => {
    if (keyValue && (Number(keyValue) || Number(keyValue) === 0)) {
      keyList.push(keyValue);
    }
  });
  return keyList.length > 0 ? keyList : null;
};

export const getValidbbValue = (bbKeyMap) => {
  const keyValObj = {};
  Object.entries(bbKeyMap).forEach(([key, keyValue]) => {
    if (keyValue && (Number(keyValue) || Number(keyValue) === 0)) {
      keyValObj["bbKey"] = key;
      keyValObj["bbValue"] = Number(keyValue) / 1000;
    }
  });
  return keyValObj;
};
