import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useLogin } from "./useLogin";
import { LoadingButton } from "../LoadingButton/LoadingButton";
import Copyright from "../Copyright/Copyright";
import "./Login.scss";

const logo = require("../../Images/RDPMSLOGO.png");

const SecurityInput = ({ state, inputType, sendOTP, handleChange }: any) => {
  if (!inputType) return <></>;
  if (inputType === "mobile") {
    if (state.secretkey === "")
      return (
        <LoadingButton
          className="w-full"
          variant="outlined"
          fullScreen
          onClick={sendOTP}
          loading={state.otpButtonLoading}
        >
          Send OTP
        </LoadingButton>
      );
    else
      return (
        <TextField
          required
          fullWidth
          style={{ marginTop: "1rem" }}
          label="OTP"
          type="OTP"
          value={state.password}
          error={state.passwordError}
          onChange={(event) => handleChange("password", event.target.value)}
        />
      );
  }
  return (
    <TextField
      required
      fullWidth
      style={{ marginTop: "1rem" }}
      label="Password"
      type="password"
      value={state.password}
      error={state.passwordError}
      onChange={(event) => handleChange("password", event.target.value)}
    />
  );
};

export default function Login() {
  const { state, handleSubmit, handleChange, inputType, sendOTP }: any = useLogin();
  const props = { state, inputType, sendOTP, handleChange };

  return (
    <>
      <CssBaseline />
      <Box className="login-page flex flexDirectionColumn alignCenter justifyCenter fullWidth">
        <div>
          <div className="login-content-box">
            <div className="flex justifyCenter">
              <img className="bitcommLogo" src={logo} alt="logo for bitcomm" />
            </div>

            <h3 className="afterlogotext">Remote Diagnostic And Predictive Maintenance</h3>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              className="login-form w-full"
              style={{ width: "30rem" }}
            >
              <TextField
                required
                fullWidth
                label="Email or Mobile Number"
                value={state.userName}
                error={state.userNameError}
                onChange={(event) => handleChange("userName", event?.target.value)}
              />

              <SecurityInput {...props} />

              <Typography className="error login-error-message" variant="caption" noWrap component="div">
                {state.errorMsg}
              </Typography>
              <LoadingButton loading={state.loading} type="submit" fullWidth variant="contained">
                Login
              </LoadingButton>
            </Box>
          </div>
          <div className="flex justifyCenter alignCenter">
            <Copyright className="login-copyright footer-copyright" />
          </div>
        </div>
      </Box>
    </>
  );
}
