import { ClickAwayListener } from "@mui/base";
import { GridActionsCellItem } from "@mui/x-data-grid";
import HistoryIcon from "@mui/icons-material/History";
import { Button, Tooltip } from "@mui/material";


const tsToDateFormatter = {
  valueFormatter: (param) => {
    if (param.value && param.value > 0) {
      const value = Number(param.value);
      const date = new Date(value);
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1; // Months start at 0!
      let dd = date.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;
      const time = date.toLocaleTimeString();
      // return formattedToday;
      return formattedToday + " " + time;
    } else {
      return "-";
    }
  }
}
export const deviceFixedColumns = [
  {
    field: "gearType",
    headerName: "Gear Type",
    sortable: true,
    minWidth: 110,
    hideable: true,
    flex: 1,
  },
  {
    field: "testMode",
    headerName: "Test Mode",
    sortable: true,
    minWidth: 90,
    hide: true,
    hideable: false,
    flex: 1,
  },
  {
    field: "subType",
    headerName: "SUB TYPE",
    sortable: true,
    minWidth: 90,
    hideable: true,
    flex: 1,
    hide: true,
  },
  {
    field: "gear_name_table",
    headerName: "Gear Name/No.",
    sortable: true,
    flex: 1,
    minWidth: 130,
    hideable: true,
  },
  {
    field: "zone",
    headerName: "Zone",
    sortable: true,
    minWidth: 80,
    flex: 1,
    hideable: true,
    hide: true,
  },
  {
    field: "division",
    headerName: "Division",
    sortable: true,
    minWidth: 80,
    flex: 1,
    hideable: true,
    hide: true,
  },
  {
    field: "code",
    headerName: "Asset Code",
    sortable: true,
    minWidth: 120,
    flex: 1,
    hideable: true,
  },

  {
    field: "rfid",
    headerName: "RFID",
    sortable: true,
    type: "string",
    flex: 1,
    minWidth: 120,
    hideable: true,
  },
  {
    field: "make",
    headerName: "Make",
    sortable: true,
    minWidth: 180,
    flex: 1,
    hideable: true,
  },
  {
    field: "model",
    headerName: "Model",
    sortable: true,
    minWidth: 180,
    flex: 1,

    hideable: true,
  },
  {
    field: "serial",
    headerName: "Sr. No.",
    sortable: true,
    minWidth: 120,
    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "lat",
    headerName: "Latitude",
    sortable: true,
    minWidth: 120,
    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "lon",
    headerName: "Longitude",
    sortable: true,
    minWidth: 120,
    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "connected",
    headerName: "Connected To",
    sortable: true,
    minWidth: 120,
    flex: 1,
    hide: true,
    hideable: true,
  },
  {
    field: "gear",
    headerName: "Gear Position",
    sortable: true,
    minWidth: 120,
    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "pole",
    headerName: "Pole Km",
    sortable: true,
    minWidth: 120,
    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "platform",
    headerName: "Platform No.",
    sortable: true,
    minWidth: 120,
    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "codal",
    headerName: "Codal Life(Yrs.)",
    sortable: true,
    minWidth: 120,

    flex: 1,

    hideable: true,
  },
  {
    field: "po",
    headerName: "Po No.",
    sortable: true,
    minWidth: 120,

    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "manufacture",
    headerName: "MAnufacture No.",
    sortable: true,
    minWidth: 120,

    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "purchase",
    headerName: "Purchase Date.",
    sortable: true,
    minWidth: 120,

    flex: 1,
    // hide: true,
    hideable: true,
  },
  {
    field: "install",
    headerName: "Install Date",
    sortable: true,
    minWidth: 135,

    flex: 1,
    hideable: true,
  },
  {
    field: "warrenty",
    headerName: "Warr. Expiry Date",
    sortable: true,
    minWidth: 135,

    flex: 1,
    hideable: true,
  },
  // {
  //   field: "maintenance",
  //   headerName: "Maint. Start Date",
  //   sortable: true,
  //   minWidth: 135,

  //   flex: 1,
  //   hideable: true,
  // },
  // {
  //   field: "station",
  //   headerName: "Station",
  //   sortable: true,
  //   minWidth: 100,

  //   flex: 1,
  //   hideable: true,
  // },
  {
    field: "remarks",
    headerName: "Remarks",
    sortable: true,
    minWidth: 150,

    flex: 1,
    hideable: true,
  },
]

// export const assetMaintenaceColumns = [
//   {
//     field: "editActions",
//     type: "actions",
//     minWidth: 80,
//     flex: 1,
//     hideable: false,
//     getActions: (params) => [
//       <Tooltip placement="bottom" title="Asset maintenance history" disableInteractive>
//         <GridActionsCellItem
//           icon={<HistoryIcon/>}
//           label="Aseet maintenance history"
//           onClick={(event) => {
//             console.log('params',params);
            
//           }}
//         />
//       </Tooltip>,
  
//     ],
//   },
//   {
//     field: "gearName",
//     headerName: "Asset Name",
//     sortable: true,
//     minWidth: 110,
//     hideable: true,
//     flex: 1,
//   },
//   {
//     field: "scheduleCode",
//     headerName: "Schedule Code",
//     sortable: true,
//     minWidth: 110,
//     hideable: true,
//     flex: 1,
//   },
//   {
//     field: "periodicity",
//     headerName: "Periodicity",
//     sortable: true,
//     minWidth: 110,
//     hideable: true,
//     flex: 1,
//   },
//   {
//     field: "status",
//     headerName: "Schedule Status",
//     sortable: true,
//     minWidth: 110,
//     hideable: true,
//     flex: 1,
//   },
//   {
//     field: "expectedDt",
//     headerName: "Due Date",
//     sortable: true,
//     minWidth: 110,
//     hideable: true,
//     flex: 1,
//     ...tsToDateFormatter
//   },
//   {
//     field: "completedDt",
//     headerName: "Done Date",
//     sortable: true,
//     minWidth: 110,
//     hideable: true,
//     flex: 1,
//     ...tsToDateFormatter
//   },
//   {
//     field: "completedByName",
//     headerName: "Done By",
//     sortable: true,
//     minWidth: 110,
//     hideable: true,
//     flex: 1,
//   },
//   {
//     field: "designation",
//     headerName: "Designation",
//     sortable: true,
//     minWidth: 110,
//     hideable: true,
//     flex: 1,
//   },

// ]