import dayjs from "dayjs";
import moment from "moment";
import { APP_BASE_URL_ALARM, GENRIC_ATTACHMENT_VIEW_URL } from "../../Common/EndPoints";

const SECONDS_TO_MILISECONDS = 1000;
const MILISECONDS_IN_A_DAY = 86400000;

const getFormattedDateTime = (ts) => {
  const today = new Date(ts);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  const time = new Date(ts).toLocaleTimeString();
  return formattedToday + " " + time;
};

function iterateObjectRecursively(object, array) {
  if (!object) return "-";
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === "object") {
      iterateObjectRecursively(value, array);
    } else {
      array.push(
        <div key={"alarm-info-sub-row" + array.length}>
          {key}: {value}
        </div>,
      );
    }
  });
}
export function getRowData(object) {
  return Object.entries(object).map(([key, value], index) => {
    const arr = [];
    var newValue;
    if (typeof value === "object") {
      iterateObjectRecursively(value, arr);
      newValue = arr;
    } else newValue = value;
    return (
      <div className="flex alarm-info-row" key={"alarm-info-row" + index}>
        <div className="p-2">
          {key
            .split("_")
            .map((word) => (word === "dt" ? "Date/Time" : word?.[0].toUpperCase() + word?.toLowerCase().substring(1)))
            .join(" ")}
        </div>
        <div className="flex flex-col flex-1 p-2">
          {key.split("_")?.[1] === "dt" ? getFormattedDateTime(newValue) : newValue}
        </div>
      </div>
    );
  });
}
export function colorAlarmsTableRow(params) {
  if (params.row.is_active) {
    return "bg-red-50";
  }
}

export function transformFilterRequestParams(params) {
  const updatedParams = { ...params };

  for (let key in updatedParams) {
    /** just to ensure that values such as '0' and false are not deleted */
    if (updatedParams[key] === "" || updatedParams[key] === null || updatedParams[key] === undefined) {
      delete updatedParams[key];
    } else {
      if (Array.isArray(updatedParams[key]) && updatedParams[key].length === 0) {
        delete updatedParams[key];
      }
    }
  }

  // during local station customer filter the customer_ids is an array of object containing id and value
  // but global customer ids are sent as a string
  if (params.customer_ids && Array.isArray(params.customer_ids)) {
    updatedParams.customer_ids = params.customer_ids.map((customer) => customer.id);
  }

  if (params.from_ts) {
    updatedParams.from_ts = dayjs(params.from_ts).unix() * SECONDS_TO_MILISECONDS;
  }
  if (params.to_ts) {
    updatedParams.to_ts = dayjs(params.to_ts).unix() * SECONDS_TO_MILISECONDS + MILISECONDS_IN_A_DAY;
  }
  if (params.device_type_ids?.length > 0) {
    updatedParams.device_type_ids = params.device_type_ids.map((device) => device.id);
  } else {
    delete updatedParams.device_type_ids;
  }

  if (params.categories?.length > 0) {
    updatedParams.categories = params.categories.join(",");
  } else {
    delete updatedParams.categories;
  }
  if (params.alarm_types?.length > 0) {
    updatedParams.alarm_types = params.alarm_types.join(",");
  } else {
    delete updatedParams.alarm_types;
  }

  if (params.device_ids?.length > 0) {
    updatedParams.device_ids = params.device_ids.map((device) => device.id);
  } else {
    delete updatedParams.device_ids;
  }
  // if (params.customer_ids?.length > 0) {
  //   updatedParams.customer_ids = params.customer_ids.map((customer) => customer.id);
  // }
  // else {
  //   delete updatedParams.customer_ids;
  // }

  if (params.remarks) {
    updatedParams.ack_info_contain = "remark:" + params.remarks;
  }
  delete updatedParams.remarks;

  return updatedParams;
}

export function stringToColor(str) {
  if (str === "Prediction") {
    return "rgb(16,145,30)";
  }
  if (str === "Testing") {
    return "rgb(255 141 0)";
  }
  var hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

export function calculateTimeDifference(fromTime, toTime) {
  let timeDiff;
  if (toTime && toTime !== "-") {
    timeDiff = moment(toTime).diff(fromTime, "minutes");
  } else {
    timeDiff = moment().diff(fromTime, "minutes");
  }
  if (timeDiff < 1) {
    timeDiff = "<1 min";
  } else if (timeDiff >= 1440) {
    timeDiff = Math.floor(timeDiff / 1440) + " D";
  } else if (timeDiff >= 60) {
    timeDiff = Math.floor(timeDiff / 60) + " H";
  } else if (timeDiff >= 1) {
    timeDiff = Math.floor(timeDiff) + " min";
  }
  return timeDiff;
}

export function getNavigateToTelemetryHistoryURL(device) {
  const deviceId = device?.id;
  const deviceType = device?.device_type?.type;
  let deviceName = device?.name?.split("_");
  deviceName.splice(0, 2);
  deviceName = deviceName.join("-");
  deviceName = encodeURIComponent(deviceName);
  return `/telemetry-history/${deviceId}_${deviceType}_${deviceName}`;
}

export function getAlarmsQueryParams({ temporaryFilters, customerIdList, alarmFilters }) {
  const selectedCustomerIdsArray = customerIdList;
  let customer_ids = selectedCustomerIdsArray;

  /**
   * temporary filters have the highest priority
   * they are set when redirecting from alarms page
   */
  if (temporaryFilters)
    return {
      ...temporaryFilters,
      customer_ids:
        temporaryFilters?.customer_ids?.map((customer) => customer.id).toString() ?? customerIdList.toString(),
    };
  /**
   * if customer ids are present in local customer filter on page(alarmsFilters)
   * then that array containing ids must be sent
   * else send the array containing all customer ids as the param
   */
  if (alarmFilters.customer_ids && alarmFilters.customer_ids.length > 0) {
    customer_ids = alarmFilters.customer_ids?.map((customer) => customer?.id ?? customer).toString();
  }
  return {
    ...alarmFilters,
    is_acked: alarmFilters.is_acked?.id ?? null,
    customer_ids: customer_ids.toString(),
  };
}

export function shouldInsertIntoTable({ alarm, filters }) {
  const alarm_time = dayjs(alarm.created_dt).unix();
  const from_ts = filters.from_ts ? dayjs(filters.from_ts).unix() : null;
  const to_ts = filters.to_ts ? dayjs(filters.to_ts).unix() : null;

  // if (!filters.is_active) {
  //   return false;
  // }

  // push alarm if user is on history page, regardless alarm is active or not
  if (filters.is_active !== null) {
    if (filters.is_active !== alarm.is_active) {
      return false;
    }
  }

  // only push if user is on page 1
  if (filters.pageIndex !== 0) {
    return false;
  }
  if (filters.severity && filters.severity !== alarm.severity) {
    return false;
  }
  if (filters.categories && filters.categories?.length !== 0 && !filters.categories?.includes(alarm.category)) {
    return false;
  }

  if (
    filters.customer_ids &&
    filters.customer_ids?.length !== 0 &&
    !filters.customer_ids?.find((customer) => alarm.customer.id === customer.id)
  ) {
    return false;
  }
  if (filters.alarm_types && filters.alarm_types?.length !== 0 && !filters.alarm_types?.includes(alarm.type)) {
    return false;
  }

  if (
    filters.device_ids &&
    filters.device_ids?.length !== 0 &&
    !filters.device_ids?.find((device) => alarm.device.id === device.id)
  ) {
    return false;
  }
  if (
    filters.device_type_ids &&
    filters.device_type_ids?.length !== 0 &&
    !filters.device_type_ids?.find((type) => alarm.device.device_type_id === type.id)
  ) {
    return false;
  }
  if (from_ts && from_ts > alarm_time) {
    return false;
  }

  if (to_ts && to_ts < alarm_time) {
    return false;
  }
  return true;
}

export function getAlarmAttachmentURL(_path) {
  return `${GENRIC_ATTACHMENT_VIEW_URL}/${_path}`;
}
