import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
// import footerLogo from "./bitcomm_logo-01-01.png";
// import logo from './bitcomm_logo-01-01.png'

const footerLogo = require('./bitcomm_logo-01-01.png');
const Copyright = (props: any) => {
  return (
    <>
      <div>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          {...props}
        >
          <img
            // style={{ width: "30px" }}
            src={footerLogo}
            alt="footer-logo-bitcomm"
          />
          {"Copyright © "}
          <Link color="inherit" href="">
            BitComm Technologies
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </div>
    </>
  );
};
export default Copyright;
