import { NotificationToast } from "../../features/notifications/NotifcationsToast";
import { useSelector } from "react-redux";
import { getAlarmNotificationSound } from "../../features/Alarms/alarmsSlice";

import NotificationBellWAV from "../../assets/audio/notification_bell.wav";
import { useEffect } from "react";
import { isBrowserSupported, onMessageListener } from "../../Services/firebase-init";
import { toast } from "react-hot-toast";
import { AlarmNotificationMessageProps } from "../../features/notifications/notification-toast-types";

export function useShowNotifications() {
  const alarmSound = useSelector(getAlarmNotificationSound);
  const audioWAV = new Audio(NotificationBellWAV);

  useEffect(() => {
    async function listenFCMMessages() {
      const supported = await isBrowserSupported;
      if (!supported) return;
      onMessageListener()
        .then((notification: AlarmNotificationMessageProps) => {
          console.log("notification", notification);

          if (alarmSound) {
            audioWAV.play();
          }
          toast((toastInfo) => <NotificationToast message={notification} toast_id={toastInfo.id} />, {
            position: "top-right",
            duration: 8000,
            style: { maxWidth: "max-content" },
          });
        })
        .catch((err) => console.log("failed: ", err));
    }
    listenFCMMessages();
  }, []);
}
