import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";

import { Transition } from "../../../Modal/ModalConstants";
import { ReactComponent as TrackCircuitDiagram } from "./track_circuit_diagram.svg";
import { ReactComponent as PointMachineCircuitDiagram } from "./pm_circuit_diagram.svg";
import {
  APP_BASE_URL,
  APP_BASE_URL_SOCKET,
  GET_ASSETS,
  GET_DEVICE_TYPE_ASSET,
  GET_LATEST_TELEMETRY,
} from "../../../Common/EndPoints";
import { selectedCustomersIdsSelector, userIdSelector } from "../../../features/authentication/auth-slice";

type GetDeviceTypeListResponse = {
  data: {
    typeid: string;
    label: string;
    type: string;
  }[];
};
function getDeviceTypeList() {
  return axios.get<GetDeviceTypeListResponse>(APP_BASE_URL + GET_DEVICE_TYPE_ASSET);
}

type GetDeviceListRequest = {
  params: {
    customerIds?: string;
    deviceTypeIds?: string;
  };
};
type Device = {
  deviceid: string;
  name: string;
  additionaldetail: {
    gear_name?: string;
    crossRefPoint?: string[];
  } & { [key: string]: string };
};
type GetDeviceListResponse = {
  data: Device[];
};
function getDeviceList({ params }: GetDeviceListRequest) {
  return axios.get<GetDeviceListResponse>(APP_BASE_URL + GET_ASSETS, { params });
}

type GetLatestTelemetryRequest = {
  body: { customerIds: string[]; status: string; deviceCategory: string | null };
  signal?: AbortSignal;
};
function getLatestTelemetry({ body, signal }: GetLatestTelemetryRequest) {
  return axios.post(APP_BASE_URL + GET_LATEST_TELEMETRY, body);
}

const DialogTransition: any = Transition;

type PacketData = Record<string, [{ ts: string; value: string }]>;
type ParsedData = Record<string, PacketData>;
type PopulateLogicType = { divide_by?: number; unit?: string; show_time_on_hover?: boolean };
type SvgDataMapType = Record<
  string,
  {
    key: string;
    device_id: string;
    populate_element?: (
      element: HTMLElement,
      data: PacketData | ParsedData,
      key: string,
      config: PopulateLogicType,
    ) => void;
  } & PopulateLogicType
>;

const TRACK_SVG_DATA_MAP_FIXED = {
  "VS-PTD&TPRN": {
    key: "24DC",
    device_id: "639abba7-4a07-496a-b2e0-fe94706d5411",
    populate_element: populateText,
    divide_by: 1000,
    unit: "V DC",
    show_time_on_hover: true,
  },
  "VS-PTD&TPRS": {
    key: "24DC",
    device_id: "bc6a5c35-5c33-4956-ae7e-352cfad10ecb",
    populate_element: populateText,
    divide_by: 1000,
    unit: "V DC",
    show_time_on_hover: true,
  },
  "VS-TRACKN": {
    key: "110AC",
    device_id: "3e20a3b4-517f-4e17-9b75-8d47d7074867",
    populate_element: populateText,
    divide_by: 1000,
    unit: "V AC",
    show_time_on_hover: true,
  },
  "VS-TRACKS": {
    key: "110AC",
    device_id: "4aaff4a1-8332-48ba-bd01-aad778750f0e",
    populate_element: populateText,
    divide_by: 1000,
    unit: "V AC",
    show_time_on_hover: true,
  },
  "ELD_VS-B24NTPR": {
    key: "DRYCH",
    device_id: "cbfe78ec-1127-4cd8-84e6-795113a7625b",
    populate_element: populateELDBox,
    show_time_on_hover: true,
  },
  "ELD_VS-B24STPR": {
    key: "DRYCH",
    device_id: "d48fdccf-5912-4245-81e3-027101c43916",
    populate_element: populateELDBox,
    show_time_on_hover: true,
  },
  "ELD_VS-2BX110": {
    key: "DRYCH",
    device_id: "32abd83e-68c1-49dc-99f0-b5147e816e87",
    populate_element: populateELDBox,
    show_time_on_hover: true,
  },
  "ELD_VS-4BX110": {
    key: "DRYCH",
    device_id: "fbc63fef-e331-4509-8ad3-035c27042434",
    populate_element: populateELDBox,
    show_time_on_hover: true,
  },
};

function populateELDBox(element: HTMLElement, data: PacketData, key: string, config: PopulateLogicType) {
  const value = data[key][0].value;
  const { show_time_on_hover } = config;
  if (value) {
    element.style.fill = Number(value) === 1 ? "green" : "red";
    if (show_time_on_hover) {
      showTimeOnHover(element, data, key, config);
    }
  }
}

function showTimeOnHover(element: HTMLElement, data: PacketData, key: string, config: PopulateLogicType) {
  const timestamp = data[key][0].ts;
  if (!timestamp) return;
  const formatted_date_time = moment(Number(timestamp)).format("DD-MM-YYYY HH:mm:ss");
  var titleElement = document.createElementNS("http://www.w3.org/2000/svg", "title");
  titleElement.textContent = `Last Time Captured: ${formatted_date_time}`;
  element.style.cursor = "pointer";
  element.appendChild(titleElement);
}

function populateText(element: HTMLElement, data: PacketData, key: string, config: PopulateLogicType) {
  const value = data?.[key]?.[0]?.value;

  const { divide_by = 1, unit = "", show_time_on_hover } = config;
  const numerical_value = Number(value);
  if (!value) return;
  let divided_value;
  if (numerical_value && !isNaN(numerical_value)) {
    divided_value = numerical_value / divide_by + " " + unit;
  } else {
    divided_value = value;
  }
  const textComp = element.querySelector("tspan");
  if (!textComp) return;
  textComp.textContent = divided_value;

  if (show_time_on_hover) {
    showTimeOnHover(element, data, key, config);
  }
}

function populateOperationLineWithOT(element: HTMLElement, data: PacketData, key: string, config: PopulateLogicType) {
  const ot_val = data?.[key]?.[0]?.value;
  if (!ot_val) return;
  const numerical_ot_val = Number(ot_val);

  if (!numerical_ot_val) return;
  if (numerical_ot_val < 10000) {
    element.style.stroke = "green";
  } else {
    element.style.stroke = "red";
  }
}

function getTrackCiruitDynamicDataMap(track_circuit_device_id: string) {
  return {
    TRACK_VF: {
      key: "VF",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
      show_time_on_hover: true,
    },
    TRACK_IF: {
      key: "IF",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      unit: "mA",

      show_time_on_hover: true,
    },
    TRACK_ICO: {
      key: "ICO",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      unit: "A",
      divide_by: 1000,
      show_time_on_hover: true,
    },
    TRACK_VCO: {
      key: "VCO",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
      show_time_on_hover: true,
    },
    TRACK_VCI: {
      key: "VCI",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
      show_time_on_hover: true,
    },
    TRACK_VR: {
      key: "VR",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
      show_time_on_hover: true,
    },
    TRACK_IR: {
      key: "IR",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      unit: "mA",
      show_time_on_hover: true,
    },
    TRACK_TPR: {
      key: "TPR",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
      show_time_on_hover: true,
    },
    TRACK_INDOOR_TPR: {
      key: "VRR",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
      show_time_on_hover: true,
    },
    Track_Gear_Name: { key: "NAME", device_id: track_circuit_device_id, populate_element: populateText },
    TrackName1_TR: { key: "NAME", device_id: track_circuit_device_id, populate_element: populateText },
    TrackName2_TR: { key: "NAME", device_id: track_circuit_device_id, populate_element: populateText },
    TrackName3_TR: { key: "NAME", device_id: track_circuit_device_id, populate_element: populateText },
    Leakage_Current: {
      key: "CUSTOM_LEAKAGE_CURRENT",
      device_id: track_circuit_device_id,
      populate_element: populateText,
      unit: "mA",
      show_time_on_hover: true,
    },
    UPSIDE_Track: {
      key: "VR",
      device_id: track_circuit_device_id,
      populate_element: colorTrackLine,
    },
    DOWNSIDE_Track: {
      key: "VR",
      device_id: track_circuit_device_id,
      populate_element: colorTrackLine,
    },
    Track_Feed_Charger_Status: {
      key: "ICO",
      device_id: track_circuit_device_id,
      populate_element: populateTrackFeedChargerStatus,
    },
  };
}

function colorTrackLine(element: HTMLElement, data: PacketData, key: string, config: PopulateLogicType) {
  const value = data[key][0].value;
  if (value) {
    element.style.stroke = Number(value) > 1200 ? "green" : "red";
  }
}

function populateTrackFeedChargerStatus(
  element: HTMLElement,
  data: PacketData,
  key: string,
  config: PopulateLogicType,
) {
  const value = data[key][0].value;
  if (value) {
    element.style.fill = Number(value) > 100 ? "green" : "red";
  }
}

function populateWireForPM(element: HTMLElement, data: ParsedData, key: string, config: PopulateLogicType) {
  function _isValuePresent(_key: string) {
    return Object.values(data).every((data) => {
      const value = data[_key][0].value;
      if (!value || value === "0" || value.includes("*") || isNaN(Number(value)) || Number(value) < 18000) return false;
      return true;
    });
  }

  function _colorLine(color: string) {
    element.style.stroke = color;
    element.style.strokeWidth = "3px";
  }

  const nwkr_present = _isValuePresent("NWKR");
  const rwkr_present = _isValuePresent("RWKR");
  const rnwkr_present = _isValuePresent("RNWKR");
  const rrwkr_present = _isValuePresent("RRWKR");

  if (!nwkr_present && !rwkr_present) {
    _colorLine("red");
    return;
  }

  switch (key) {
    case "NWKR": {
      if (nwkr_present) {
        _colorLine("green");
      } else {
        _colorLine("gray");
      }
      break;
    }
    case "RWKR": {
      if (rwkr_present) {
        _colorLine("green");
      } else {
        _colorLine("gray");
      }
      break;
    }
    case "RRWKR": {
      if (!rrwkr_present && !rnwkr_present) {
        _colorLine("red");
        break;
      }
      if (rrwkr_present) {
        _colorLine("green");
      } else {
        _colorLine("gray");
      }
      break;
    }
    case "RNWKR": {
      if (!rrwkr_present && !rnwkr_present) {
        _colorLine("red");
        break;
      }
      if (rnwkr_present) {
        _colorLine("green");
      } else {
        _colorLine("gray");
      }
      break;
    }
    default: {
    }
  }
}

function populateDateTimeForPM(element: HTMLElement, data: PacketData, key: string, config: PopulateLogicType) {
  const device_key_timestamp = data?.[key]?.[0]?.ts;
  if (!device_key_timestamp || isNaN(Number(device_key_timestamp))) return;
  const textComp = element.querySelector("tspan");
  if (!textComp) return;
  textComp.textContent = moment(Number(device_key_timestamp)).format("DD-MM-YYYY HH:mm:ss");
}

interface Props {
  open: boolean;
  gear_type: string;
  device_id: string;
  handleClose: () => void;
}

type CircuitViewProps = { data: Record<string, PacketData>; data_map: SvgDataMapType; gear_type: string };

const TelemetryCircuitView: React.FC<CircuitViewProps> = (props) => {
  const svg_ref = useRef<SVGSVGElement | null>(null);
  const { data = {}, data_map } = props;
  useEffect(() => {
    const svg_component = svg_ref.current;
    if (!svg_component) return;
    for (const svg_element_id in data_map) {
      const element = svg_component.getElementById(svg_element_id) as HTMLElement;
      const populate_logic = data_map[svg_element_id];
      const device_id = populate_logic.device_id;
      const device_data = data[device_id];

      if (!element) {
        console.error("SVG_ELEMENT_NOT_FOUND_FOR_ID", svg_element_id);
        continue;
      }

      if (!device_data) {
        if (!device_id && populate_logic.populate_element) {
          populate_logic.populate_element(element, data, populate_logic.key, populate_logic);
          continue;
        }

        console.error("DEVICE_DATA_NOT_FOUND_TO_POPULATE_SVG", device_id);
        continue;
      }

      if (populate_logic.populate_element) {
        populate_logic.populate_element(element, device_data, populate_logic.key, populate_logic);
      }
    }
  }, [data]);

  if (props.gear_type === "PM") return <PointMachineCircuitDiagram ref={svg_ref} />;
  return <TrackCircuitDiagram ref={svg_ref} />;
};

function useGetTelemetryCircuitViewData(
  required_device_id_list: string[],
  gear_type: string,
  device_id: keyof typeof data,
) {
  const customerIdList = useSelector(selectedCustomersIdsSelector) as string[];
  const userId = useSelector(userIdSelector);
  const telemetry_websocket = useRef<undefined | WebSocket>();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const dataRef = useRef<any>({});

  function filterAndParseTelemetryData(response: any[]) {
    const raw_data_map: Record<any, any> = {};
    for (const data of response) {
      const _device_id = data.deviceId;
      if (!required_device_id_list.includes(_device_id)) continue;
      raw_data_map[_device_id] = { point_machine_subgear: null };

      for (const packet of data.dataLists) {
        const data = packet.data;
        const subgear = packet.subgear;
        if (subgear?.toUpperCase() === "A" || subgear?.toUpperCase() === "B") {
          raw_data_map[_device_id].point_machine_subgear = subgear;
        }
        raw_data_map[_device_id] = { ...raw_data_map[_device_id], ...data };
      }
      if (!raw_data_map[_device_id].point_machine_subgear) delete raw_data_map[_device_id].point_machine_subgear;
    }
    return raw_data_map;
  }

  function getLiveData() {
    if (telemetry_websocket.current) {
      telemetry_websocket.current.close();
    }
    const url = `${APP_BASE_URL_SOCKET}/${userId}`;
    telemetry_websocket.current = new WebSocket(url);
    telemetry_websocket.current.onmessage = (res) => {
      if (!res?.data) return;
      let data = JSON.parse(res.data);
      const device_id_of_received_packet = data.deviceId;
      const packet_data = data?.dataLists?.[0].data;
      if (!dataRef.current || !dataRef.current[device_id_of_received_packet]) return;
      // console.log("dataRef", device_id_of_received_packet, dataRef);
      console.log("device_id_is_present");

      dataRef.current[device_id_of_received_packet] = {
        ...dataRef.current[device_id_of_received_packet],
        ...packet_data,
      };

      setData({ ...dataRef.current });
    };
  }

  async function populateTelemetryData(signal?: AbortSignal) {
    setLoading(true);
    let body = {
      customerIds: customerIdList,
      status: "ALL",
      deviceCategory: null,
    };

    try {
      const raw_response = await getLatestTelemetry({ body, signal });
      const response = raw_response.data.data;
      const parsed_data = filterAndParseTelemetryData(response);
      console.log("parsed_data_using_api", parsed_data);
      dataRef.current = parsed_data;
      getLiveData();
      setData(parsed_data);
      setLoading(false);
    } catch (error) {}
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (required_device_id_list.length === 0) return;
    populateTelemetryData(signal);
    return () => {
      controller.abort();
    };
  }, [required_device_id_list]);

  const final_parsed_data = useMemo(() => {
    switch (gear_type) {
      case "TRACK": {
        const track_feed_current = data[device_id]?.["IF"]?.[0];
        const track_relay_current = data[device_id]?.["IR"]?.[0];

        if (track_feed_current || track_relay_current) {
          const leakage_current = Number(track_feed_current.value) - Number(track_relay_current.value);
          if (!leakage_current || leakage_current < 0) {
            data[device_id] = {
              ...data[device_id],
              CUSTOM_LEAKAGE_CURRENT: [
                {
                  ts: null,
                  value: "NA",
                },
              ],
            };
            return data;
          }
          data[device_id] = {
            ...data[device_id],
            CUSTOM_LEAKAGE_CURRENT: [
              {
                ts: Math.max(Number(track_feed_current.ts), Number(track_relay_current.ts)),
                value: leakage_current,
              },
            ],
          };
        }

        return data;
      }
      default:
        return data;
    }
  }, [data, device_id, gear_type]);

  return useMemo(() => ({ data: final_parsed_data, loading }), [final_parsed_data, loading]);
}

async function getRequiredDeviceIdList(device_id: string, gear_type: string) {
  const device_id_list: string[] = [device_id];
  if (gear_type === "TRACK") {
    for (const svg_id in TRACK_SVG_DATA_MAP_FIXED) {
      const svg_id_val = TRACK_SVG_DATA_MAP_FIXED[svg_id as keyof typeof TRACK_SVG_DATA_MAP_FIXED];
      device_id_list.push(svg_id_val.device_id);
    }
  } else if (gear_type === "PM") {
    try {
      const device_type_list = await getDeviceTypeList();
      const pm_gear_type = device_type_list.data.data.find((device) => device.type === "PM");
      if (!pm_gear_type) {
        throw new Error("PM_GEAR_TYPE_NOT_FOUND");
      }
      const pm_type_id = pm_gear_type.typeid;
      const device_list = await getDeviceList({ params: { deviceTypeIds: pm_type_id } });
      let crossRefPoint: string[] = [];
      for (const device of device_list.data.data) {
        if (device.deviceid === device_id) {
          crossRefPoint = device.additionaldetail.crossRefPoint ?? [];
        }
      }

      for (const device of device_list.data.data) {
        if (!device.additionaldetail.gear_name) continue;
        if (crossRefPoint.includes(device.additionaldetail.gear_name)) {
          device_id_list.push(device.deviceid);
        }
      }
    } catch (error) {
      console.log("FETCHING_REQUIRED_DEVICE_IDS_FOR_PM", error);
      throw error;
    }
  }
  return device_id_list;
}

function getTrackCircuitSvgDataMap(track_circuit_device_id: string) {
  const track_circuit_map = getTrackCiruitDynamicDataMap(track_circuit_device_id);
  return { ...track_circuit_map, ...TRACK_SVG_DATA_MAP_FIXED };
}

function getPointMachineSvgDataMap(data: Record<any, any>) {
  let device_id_of_pm_on_end_a = null;
  let device_id_of_pm_on_end_b = null;

  for (const device_id in data) {
    const device_data = data[device_id];
    if (device_data?.point_machine_subgear?.toUpperCase() === "A") {
      device_id_of_pm_on_end_a = device_id;
    } else if (device_data?.point_machine_subgear?.toUpperCase() === "B") {
      device_id_of_pm_on_end_b = device_id;
    }
  }

  if (!device_id_of_pm_on_end_a && !device_id_of_pm_on_end_b) {
    console.error("POINT_MACHINE_CONFIGURATION_NOT_FOUND_FOR_A_AND_B", data);
    return {};
  }

  const COMMON_POINT_MACHINE_CONFIG = {
    Outdoor_Detection_NWKR_Wire1: {
      key: "NWKR",
      populate_element: populateWireForPM,
    },
    NWKR_DETECTION1: {
      key: "NWKR",
      populate_element: populateWireForPM,
    },
    NWKR_DETECTION2: {
      key: "NWKR",
      populate_element: populateWireForPM,
    },
    RWKR_DETECTION1: {
      key: "RWKR",
      populate_element: populateWireForPM,
    },
    RWKR_DETECTION2: {
      key: "RWKR",
      populate_element: populateWireForPM,
    },
    Outdoor_Detection_NWKR_Wire2: {
      key: "NWKR",
      populate_element: populateWireForPM,
    },
    Outdoor_Detection_NWKR_Wire3: {
      key: "NWKR",
      populate_element: populateWireForPM,
    },
    Outdoor_Detection_RWKR_Wire1: {
      key: "RWKR",
      populate_element: populateWireForPM,
    },
    Outdoor_Detection_RWKR_Wire2: {
      key: "RWKR",
      populate_element: populateWireForPM,
    },
    Outdoor_Detection_RWKR_Wire3: {
      key: "RWKR",
      populate_element: populateWireForPM,
    },
    Indoor_Detection_RNWKR_Wire1: {
      key: "RNWKR",
      populate_element: populateWireForPM,
    },
    Indoor_Detection_RNWKR_Wire2: {
      key: "RNWKR",
      populate_element: populateWireForPM,
    },
    Indoor_Detection_RRWKR_Wire1: {
      key: "RRWKR",
      populate_element: populateWireForPM,
    },
    Indoor_Detection_RRWKR_Wire2: {
      key: "RRWKR",
      populate_element: populateWireForPM,
    },
    Indoor_Detection_RRWKR_Wire3: {
      key: "RRWKR",
      populate_element: populateWireForPM,
    },
  };

  const POINT_MACHINE_A_CONFIG = {
    PointA_Operation_Date_Time: {
      key: "VNO",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateDateTimeForPM,
    },
    PointA_Outdoor_Detection_Date_Time: {
      key: "NWKR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateDateTimeForPM,
    },
    PointA_Indoor_Detection_Date_Time: {
      key: "RNWKR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateDateTimeForPM,
    },
    Point2_Operation_Name: {
      key: "NAME",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
    },
    PointA_Detection_Outdoor: {
      key: "NAME",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
    },
    Point2_Operation_Name_Box_Text: {
      key: "NAME",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
    },
    PointA_Operation: {
      key: "OT",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateOperationLineWithOT,
    },
    PM_A_DIR: {
      key: "DIR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
    },
    PointA_Detection_Indoor: {
      key: "NAME",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
    },
    PM_A_VNO_Voltage: {
      key: "VNO",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_A_VRO_Voltage: {
      key: "VRO",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_A_PC_Current: {
      key: "PC",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
      divide_by: 1000,
      unit: "A",
    },
    PM_A_OC_Current: {
      key: "OC",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
      divide_by: 1000,
      unit: "A",
    },
    LOCATION_BOX: {
      key: "LOC",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
    },
    PM_A_NWKR_Voltage_Outdoor: {
      key: "NWKR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_A_RWKR_Voltage_Outdoor: {
      key: "RWKR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_A_RNWKR_Voltage_Indoor: {
      key: "RNWKR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_A_RRWKR_Voltage_Indoor: {
      key: "RRWKR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
  };

  if (!device_id_of_pm_on_end_b) {
    console.error("POINT_MACHINE_CONFIGURATION_NOT_FOUND_FOR_B", data);
    return { ...COMMON_POINT_MACHINE_CONFIG, ...POINT_MACHINE_A_CONFIG };
  }

  const POINT_MACHINE_B_CONFIG = {
    PointB_Operation_Date_Time: {
      key: "VNO",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateDateTimeForPM,
    },
    PointB_Outdoor_Detection_Date_Time: {
      key: "NWKR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateDateTimeForPM,
    },
    PointB_Indoor_Detection_Date_Time: {
      key: "RNWKR",
      device_id: device_id_of_pm_on_end_a,
      populate_element: populateDateTimeForPM,
    },
    Point1_Operation_Name: {
      key: "NAME",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
    },
    PointB_Detection_Outdoor: {
      key: "NAME",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
    },
    Point2_Operation_Name_Box_Text_2: {
      key: "NAME",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
    },
    PointB_Operation: {
      key: "OT",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateOperationLineWithOT,
    },
    PM_B_DIR: {
      key: "DIR",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
    },
    PointB_Detection_Indoor: {
      key: "NAME",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
    },
    LOCATION_BOX: {
      key: "LOC",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
    },
    PM_B_VNO_Voltage: {
      key: "VNO",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_B_VRO_Voltage: {
      key: "VRO",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_B_PC_Current: {
      key: "PC",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
      divide_by: 1000,
      unit: "A",
    },
    PM_B_OC_Current: {
      key: "OC",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
      divide_by: 1000,
      unit: "A",
    },

    PM_B_NWKR_Voltage_Outdoor: {
      key: "NWKR",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_B_RWKR_Voltage_Outdoor: {
      key: "RWKR",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },

    PM_B_RNWKR_Voltage_Indoor: {
      key: "RNWKR",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
    PM_B_RRWKR_Voltage_Indoor: {
      key: "RRWKR",
      device_id: device_id_of_pm_on_end_b,
      populate_element: populateText,
      divide_by: 1000,
      unit: "V",
    },
  };

  if (!device_id_of_pm_on_end_a) {
    console.error("POINT_MACHINE_CONFIGURATION_NOT_FOUND_FOR_A", data);
    return { ...COMMON_POINT_MACHINE_CONFIG, ...POINT_MACHINE_B_CONFIG };
  }

  return { ...COMMON_POINT_MACHINE_CONFIG, ...POINT_MACHINE_A_CONFIG, ...POINT_MACHINE_B_CONFIG };
}

function getSvgDataMap(device_id: string, gear_type: string, data: Record<any, any>) {
  switch (gear_type) {
    case "TRACK":
      return getTrackCircuitSvgDataMap(device_id);
    case "PM":
      return getPointMachineSvgDataMap(data);
    default:
      return {};
  }
}

const TelemetryCircuitViewWrapper: React.FC<Props> = (props) => {
  const { device_id, handleClose, gear_type } = props;
  const [requiredDeviceIdList, setRequiredDeviceIdList] = useState<string[]>([]);
  async function handleRequiredDeviceIdList() {
    const required_device_id_list = await getRequiredDeviceIdList(device_id, gear_type);
    setRequiredDeviceIdList(required_device_id_list);
  }
  useEffect(() => {
    handleRequiredDeviceIdList();
  }, [device_id, gear_type]);
  const { data = {} } = useGetTelemetryCircuitViewData(requiredDeviceIdList, gear_type, device_id);
  console.log("parsed_data_changing", data);
  const svg_data_map = getSvgDataMap(device_id, gear_type, data);

  return (
    <>
      <DialogTitle id="responsive-dialog-title" className="flex">
        <CloseIcon className="pointer" sx={{ ml: "auto" }} onClick={handleClose} />
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} dividers>
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
          <TelemetryCircuitView data={data} data_map={svg_data_map} gear_type={gear_type} />
        </div>
      </DialogContent>
    </>
  );
};

const TelemetryCircuitViewModal: React.FC<Props> = (props) => {
  const { open } = props;

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={DialogTransition}
        fullWidth={true}
        maxWidth={"md"}
      >
        <TelemetryCircuitViewWrapper {...props} />
      </Dialog>
    </div>
  );
};

export default memo(TelemetryCircuitViewModal);
