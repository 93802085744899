import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { Transition } from "../../Modal/ModalConstants";
import CloseIcon from "@mui/icons-material/Close";
import { useCustomerGroupContext } from "./CustomerGroup";
import { useState } from "react";
import { getPermittedCustomersList } from "../../Utils/UserData";

export const HandleCustomerGroupModal = () => {
  const { customerIdArray, customerMap } = getPermittedCustomersList();
  const {
    name,
    customerIds,
    resetActionModal: closeActionModal,
    handleSubmitActionModal: handleSubmit,
    handleValueChange,
    type,
    modalTitle,
  } = useCustomerGroupContext();

  const [error, setError] = useState(false);
  function handleFormSubmit() {
    if (type === "delete") return handleSubmit();

    if (!name || !customerIds) {
      setError(true);
      return;
    }
    handleSubmit();
  }

  let renderContent =
    type === "delete" ? (
      <div>Do you want to delete this Station Group?</div>
    ) : (
      <>
        <FormControl fullWidth className="flex flex-col" style={{ gap: "2rem" }}>
          <InputLabel></InputLabel>
          <TextField
            required
            value={name}
            placeholder="Group Name"
            onChange={(event) => {
              handleValueChange("name", event.target.value);
            }}
            error={error ? !name : false}
            label="Group Name"
          />
          {error && !name && <FormHelperText error></FormHelperText>}
          <Autocomplete
            className="filter-input"
            disableCloseOnSelect
            id="combo-box-demo"
            multiple={true}
            value={customerIds}
            options={customerIdArray}
            getOptionLabel={(id) => customerMap[id]}
            onChange={(_, _value) => {
              handleValueChange("customerIds", _value);
            }}
            renderInput={(params) => (
              <TextField
                error={error ? customerIds?.length === 0 : false}
                placeholder="Station(s)"
                {...params}
                required
                label="Station(s)"
              />
            )}
          />
          {error && customerIds?.length === 0 && <FormHelperText error></FormHelperText>}
        </FormControl>
      </>
    );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={true}
      onClose={closeActionModal}
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" className="flex items-center">
        {modalTitle} Station Group
        <CloseIcon className="pointer" sx={{ ml: "auto" }} onClick={closeActionModal} />
      </DialogTitle>

      <DialogContent dividers>{renderContent}</DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={closeActionModal}>
          Cancel
        </Button>
        <Button variant="contained" autoFocus onClick={handleFormSubmit}>
          {modalTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandleCustomerGroupModal;
