import axios from "axios";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { APP_BASE_URL, GENERATE_OTP } from "../../Common/EndPoints";
import { doLogin } from "../../Services/DataService";

import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../features/authentication/auth-slice";
import useFirebaseCloudMessaging from "../../hooks/fcm/useFirebaseCloudMessaging";

const initialState = {
  userName: "",
  password: "",
  userNameError: false,
  passwordError: false,
  errorMsg: "",
  loading: false,
  otpButtonLoading: false,
  secretkey: "",
};
const PHONE_NUMBER_VALIDATION = /^\d{10}$/;
const EMAIL_VALIDATION = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export function useLogin() {
  const { refetchUserData } = useContext(ActiveAlarmsData);
  const [state, setState] = useImmer(initialState);
  const { registerUser } = useFirebaseCloudMessaging();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken !== null && refreshToken !== undefined && refreshToken !== "undefined") {
      navigate("/home");
    }
  }, []);

  const validateForm = () => {
    let _errorMsg = "";
    if (!state.userName || !state.password) _errorMsg = "Field must not be empty";
    if (inputType === null) _errorMsg = "Invalid Email or Mobile Number";
    if (!state.password && inputType !== null) _errorMsg = "Something went Wrong";
    setState((state) => {
      state.userNameError = !state.userName || inputType === null;
      state.passwordError = !state.password;
      state.errorMsg = _errorMsg;
    });
    if (!state.userName || !state.password) {
      return false;
    }
    return true;
  };

  const handleChange = useCallback((type, value) => {
    setState((state) => {
      state[type] = value;
      state[`${type}Error`] = !value.length;
      if (type === "userName") {
        state.otpButtonLoading = false;
        state.secretkey = "";
        state.errorMsg = "";
      }
    });
  }, []);

  function resetPassword() {
    setState((state) => {
      state.password = "";
    });
  }
  const inputType = useMemo(() => {
    // if (!state.userName || state.userName.length === 0) return null;
    if (state.userName.match(PHONE_NUMBER_VALIDATION)) {
      resetPassword();
      return "mobile";
    }
    if (state.userName.match(EMAIL_VALIDATION)) {
      resetPassword();
      return "email";
    }
    return null;
    // return "email";
  }, [state.userName]);

  const sendOTP = useCallback(() => {
    const params = { mobileNo: state.userName, otpTimeOut: 180 };
    setState((state) => {
      state.otpButtonLoading = true;
    });
    axios.post(`${APP_BASE_URL}${GENERATE_OTP}`, null, { params }).then(
      (res) => {
        setState((state) => {
          state.secretkey = res.data.data.secretkey;
        });
      },
      (err) => {
        setState((state) => {
          state.errorMsg = err?.response?.data?.error?.message ?? "Something went wrong! Please try again later.";
          state.otpButtonLoading = false;
        });
      },
    );
  }, [state.userName]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const user = {
        email: state.userName,
        password: state.password,
        secretkey: state.secretkey,
      };
      setState((state) => {
        state.loading = true;
        state.errorMsg = "";
      });
      doLogin(user, inputType).then(
        (user_data) => {
          console.log("user_data", user_data);

          const userId = user_data?.user?.userId;
          if (!userId) {
            return;
          }
          dispatch(loginSuccess(user_data));
          refetchUserData();
          registerUser(userId).then((res) => {
            console.log("res", res);
            navigate("/home");
          });

          //TODO: REMOVE THIS RELOAD!!!
          // window.location.reload();
        },
        (err) => {
          setState((state) => {
            state.errorMsg =
              "*" + err?.response?.data?.error?.message ?? "Something went wrong! Please try again later.";
            state.loading = false;
          });
        },
      );
    }
  };
  return useMemo(
    () => ({ state, setState, handleSubmit, handleChange, inputType, sendOTP }),
    [state, handleSubmit, handleChange, inputType],
  );
}
