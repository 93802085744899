import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import "./ReactivePop.scss";
import DeleteIcon from "@mui/icons-material/Delete";

function ReactivePopMarkup({ parentData, getParentPopUpData,editInfo }) {
  // let parentDependingKeysList = []
  const [parentDependingKeys, setParentDependingKeys] = useState("");
  const [dependingValues, setDependingValues] = useState("");
  const [reactiveOption, setReactiveOption] = useState([])
  const [parentDependingKeysList, setParentDependingKeysList] = useState([])

  const [options, setOptions] = useImmer({});

  let LabelProps;
  if (parentData.type === "date") {
    LabelProps = {
      InputLabelProps: {
        shrink: parentData.type === "date",
      },
    };
  }



  useEffect(() => {
    if (parentData.reactiveValue) {
      let a = []
      Object.values(parentData.reactiveValue).forEach((value) => {
        value.forEach((b) => {
          if (!a.includes(b)) {
            a.push(b)
          }
        })
      })
      setReactiveOption(a)
    } else {
      setReactiveOption(parentData.options)
    }
  }, [parentData])


  useEffect(() => {
    if (options) {
      getParentPopUpData({ reactiveKey: parentData.key, DEFAULT_VALUE: [], reactiveValue: options })

    }
  }, [options, parentData])

useEffect(()=>{
if(editInfo){
  let dependingKeys = Object.keys(editInfo.reactiveValue)
  setParentDependingKeysList(dependingKeys)
  setOptions(editInfo.reactiveValue)
}
},[])
  const handlePushOptions = (parentKey, dependingValue) => {
    if (dependingValue.length > 0) {
      if (options[parentKey]) {
        setOptions((prev) => {
          prev[parentKey] = [...prev[parentKey], dependingValue];
        });
      } else {
        setParentDependingKeysList([...parentDependingKeysList, parentKey])
        setOptions((prev) => {
          prev[parentKey] = [dependingValue];
        });
      }
    }
  };

  const removeOption = (parentKey, index) => {
    let updatedOptions = [...options[parentKey]];
    if (index || index === 0) {
      updatedOptions.splice(index, 1);
    }
    setOptions((prev) => {
      prev[parentKey] = [...updatedOptions];
    });
  };



  return (
    <>
      <h3 className="dd-heading ">{`Reactive values for ${parentData.label}(${parentData.type})`}</h3>
      <div className="reactive-pop-fields">
        {(parentData.type === "dropdown" || parentData.type === 'reactive-dropdown' ||parentData.type === 'reactive_dropdown' ) && (
          <FormControl fullWidth>
            <InputLabel id="standard-label">{"value"}</InputLabel>
            <Select
              defaultValue=""
              id="select-standard"
              className="field"
              label="value"
              value={parentDependingKeys}
              onChange={(e) => {
                setParentDependingKeys(e.target.value);
              }}
            >
              {reactiveOption?.map((item, i) => {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        {(parentData.type !== "dropdown" && (parentData.type !== 'reactive-dropdown'||parentData.type !== 'reactive_dropdown')) && (
          <TextField
            label="Reactive Value"
            placeholder="Enter a Reactive Value"
            type={parentData.type}
            value={parentDependingKeys}
            onChange={(e) => {
              setParentDependingKeys(e.target.value);
            }}
            {...LabelProps}
          />
        )}

        <TextField
          label="Options"
          placeholder="Add Options"
          value={dependingValues}
          onChange={(e) => {
            setDependingValues(e.target.value);
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            handlePushOptions(parentDependingKeys, dependingValues);
            setDependingValues("");

          }}
        >
          add
        </Button>
      </div>
      <div className="reactive-pop-option-preview">
        <Box className="preview-header-box" sx={{ maxWidth: { xs: 20, sm:480 }, bgcolor: 'background.paper' }}>
          <Tabs
            value={parentDependingKeys}
            onChange={(e, b) => {
              setParentDependingKeys(parentDependingKeysList[b])
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {parentDependingKeysList?.map((key, index) => {
              return (<Tab className={`tab ${parentDependingKeys === key ? 'active' : ''}`} key={index} label={key} />)
            })}

          </Tabs>
        </Box>
        <div className="option-Preview">
          {options?.[parentDependingKeys]?.length > 0 ? (
            options?.[parentDependingKeys].map((option, i) => {
              return (
                <Chip
                  label={option}
                  className="option-chip"
                  onDelete={() => {
                    removeOption(parentDependingKeys, i);
                  }}
                  deleteIcon={<DeleteIcon className="chip-icon" />}
                  variant="outlined"
                />
              );
            })
          ) : (
            <div>no options</div>
          )}
        </div>
      </div>
    </>
  );
}

export default ReactivePopMarkup;
