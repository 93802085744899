import { Alert, Button, Snackbar, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { APP_BASE_URL } from "../../../Common/EndPoints";
import DataTable from "../../DataGrid/DataGrid";
import { BASE_URL, ICONS, pageDetailsByUrl } from "./FormConstants";
import "./Forms.scss";

const snackInitialState = { open: false, message: "", severity: "" };

function Forms() {
  const access = window.frontendAccess?.FORM;
  const allowCreateForm = access?.create ?? true;
  const allowUpdateForm = access?.update ?? true;
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname;
  const [pageState, setPageState] = useImmer({
    name: "",
    tableColumns: [],
    tableRow: [],
  });
  const [loader, setLoader] = useState(false);
  const [snackBar, setSnackBar] = useImmer({ open: false, message: "", severity: "" });
  const [pageSize, setPageSize] = useState(20)
  useLayoutEffect(() => {
    let actionColumns = [
      {
        field: "editActions",
        type: "actions",
        minWidth: 150,
        flex: 1,
        hideable: false,
        getActions: (params) => [
          <Tooltip placement="bottom" title="View Form" disableInteractive>
            <GridActionsCellItem
              icon={ICONS.formViewIcon}
              label="view"
              onClick={(event) => {
                handleView(params);
              }}
            />
          </Tooltip>,
          <Tooltip placement="bottom" title="Active" disableInteractive>
            <GridActionsCellItem sx={{ display: (params.row.active ?? params.row.publish) ? "static" : "none" }} icon={ICONS.activeIcon} />
          </Tooltip>,
          <Tooltip placement="bottom" title="Not Active" disableInteractive>
            <GridActionsCellItem sx={{ display: (params.row.active ?? params.row.publish) ? "none" : "static" }} icon={ICONS.inActiveIcon} />
          </Tooltip>,
        ],
      },
    ];

    setPageState((prev) => {
      prev.name = pageDetailsByUrl[url].name;
      prev.tableColumns = [...actionColumns, ...pageDetailsByUrl[url].tableColumns];
    });
  }, [location.pathname]);

  useEffect(() => {
    getTableRows(url);

    if (localStorage.getItem("form-crud-status")) {
      let form_crud_status = localStorage.getItem("form-crud-status");
      if (form_crud_status) {
        if (form_crud_status === "updated successfully" || form_crud_status === "created successfully") {
          setSnackBar((prev) => {
            prev.open = true;
            prev.severity = "success";
            prev.message = "Form" + " " + form_crud_status;
          });
        } else {
          setSnackBar((prev) => {
            prev.open = true;
            prev.severity = "error";
            prev.message = form_crud_status;
          });
        }
        localStorage.removeItem("form-crud-status");
      }
    }
  }, [location.pathname]);

  const getTableRows = (pageUrl) => {
    setLoader(true);

    let url = `${APP_BASE_URL}${pageDetailsByUrl[pageUrl].getUrl}`;
    if (url && !url.includes("null")) {
      axios
        .get(url)
        .then((res) => {
          // pageState.tableRow = res.data.data
          let response = res.data.data;

          setPageState((prev) => {
            prev.tableRow = pageDetailsByUrl[pageUrl].getTableRows(response);
          });
        })
        .catch(() => {
          setLoader(false);
          setPageState((prev) => {
            prev.tableRow = [];
          });
        })
        .then(() => {
          setLoader(false);
        });
    } else {
      setPageState((prev) => {
        prev.tableRow = [];
      });
      setLoader(false);
    }
  };

  const handleOnCreate = () => {
    const createNavigateState = {
      mode: "create",
      name: '',
      formJson: [],
      formId: null,
      postUrl: pageDetailsByUrl[url].postUrl,
      pageUrl: url,
      published: false,
      isAssetForm: pageDetailsByUrl[url].isAssetForm,
      device_type_ids: null
    };
    navigate("/form-creator", {
      state: createNavigateState,
    });
  };

  const handleView = (params) => {
    let assetDeviceType = {}
    let formName
    if (pageDetailsByUrl[url].isAssetForm) {
      assetDeviceType = {
        device_type_ids: { id: params.row.deviceType.typeid, value: params.row.deviceType.label }
      }
      formName = params.row.name
    } else {
      formName = params.row.name.split("-")[1]?.trim() ?? params.row.name
      if (params.row.name.split("-")[0]?.trim()) {
        assetDeviceType = {
          device_type_ids: { id: params.row.name.split("-")[0].trim(), value: params.row.name.split("-")[0].trim() }
        }
      }

    }
    //  console.log('pageDetailsByUrl[url].putUrl',pageDetailsByUrl[url].putUrl)
    const viewNavigateState = {
      mode: "view",
      name: formName,
      formJson: params.row.data,
      formId: params.row.id,
      putUrl: pageDetailsByUrl[url].putUrl,
      pageUrl: url,
      published: params.row.active ?? params.row.publish,
      isAssetForm: pageDetailsByUrl[url].isAssetForm,
      allowUpdateForm,
      ...assetDeviceType
    };
    navigate("/form-creator", {
      state: viewNavigateState,
    });
  };

  return (
    <div className="forms-container">
      <div className="forms-header">
        <div className="heading">{pageState.name}</div>
        {allowCreateForm && (
          <Button
            onClick={(e) => {
              handleOnCreate();
            }}
            variant="contained"
            className="create-btn"
          >
            Create form
          </Button>
        )}
      </div>
      <div className="table-container">
        <DataTable
          loading={loader}
          tableInfo={{
            id: `${pageState.id}-table`,
            label: pageState.name,
            requireAuthSign: false,
          }}
          rows={pageState.tableRow}
          columns={pageState.tableColumns}
          pageSize={pageSize}
          pageSizeChange={((size) => {
            setPageSize(size)
          })}

        />
      </div>
      <Snackbar open={snackBar.open} autoHideDuration={2500} onClose={() => setSnackBar(snackInitialState)}>
        <Alert
          onClose={() => {
            setSnackBar(snackInitialState);
          }}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Forms;
