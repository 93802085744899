import { Autocomplete, Button, Chip, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { useEffect, useState, createContext, useContext, useMemo } from "react";
import { useImmer } from "use-immer";

import { APP_BASE_URL_ALARM, PM_UTILISATION, trimAssetName } from "../../../Common/EndPoints";
import { ActiveAlarmsData } from "../../Alarms/ActiveAlarms";
import "./PmUtilisation.scss";
import UtilisationPieChartTime from "./utils/RuntimePieChart";
import StackCountChart from "./utils/StackCountChart";
import DataTable from "../../DataGrid/DataGrid";
import { UtilizationLabel, convertSecondsToDuration, mostUtilizedTableColumn } from "../Constants";
import { AutocompleteStationListPop } from "../../AutocompleteStationListPop/AutocompleteStationListPop";

export const PmUtilastionContext = createContext({
  summaryData: [],
});

const initialFilters = {
  from_ts: null,
  to_ts: null,
  customer_ids: [],
};
const initialUtiliseddata = {
  count: 5,
  data: [],
};
function PmUtilisation({ assetType }) {
  const [summaryData, setSummmaryData] = useState([]);
  const [mostUtilisedAssetData, setMostUtilisedAssetData] = useImmer(initialUtiliseddata);
  const { customerIdList, selectedCustomersIdName = [] } = useContext(ActiveAlarmsData);
  const [filterState, setFilterState] = useImmer(initialFilters);
  const [pageSize, setPageSize] = useState(10);
  const permittedCustomerIds = useMemo(() => {
    const _list = selectedCustomersIdName;
    if (!_list) return [];
    return _list.map((customer) => ({ id: customer.customerId, value: customer.customerName }));
  }, [selectedCustomersIdName?.length]);

  useEffect(() => {
    if (customerIdList.length > 0) {
      setFilterState((state) => {
        state.customer_ids = state.customer_ids.filter((customer) => customerIdList.includes(customer.id));
      });
      getSummaryData(filterState);
    }
  }, [customerIdList]);
  useEffect(() => {
    getMostUtilisedAssets(filterState);
  }, [customerIdList, mostUtilisedAssetData.count]);

  function getSummaryData(filterState) {
    const customerIds = filterState.customer_ids
      ?.map((customer) => customer.id)
      ?.filter((id) => customerIdList.includes(id));
    const customer_ids = customerIds.length > 0 ? customerIds.toString() : customerIdList.toString();
    let filterParams = {
      from_ts: filterState.from_ts,
      to_ts: filterState.to_ts,
      customer_ids,
    };
    // let url = `${APP_BASE_URL_ALARM}${PM_UTILISATION}?customer_ids=${customerIdList.toString()}&from_ts=${filterState.from_ts}&to_ts=${filterState.to_ts}`
    let url = `${APP_BASE_URL_ALARM}${PM_UTILISATION}`;
    axios.get(url, { params: filterParams }).then((res) => {
      if (res) {
        let Response = res.data;
        setSummmaryData(Response);
      }
    });
  }
  function getMostUtilisedAssets(filterState) {
    const customerIds = filterState.customer_ids
      ?.map((customer) => customer.id)
      ?.filter((id) => customerIdList.includes(id));
    const customer_ids = customerIds.length > 0 ? customerIds.toString() : customerIdList.toString();

    let filterParams = {
      from_ts: filterState.from_ts,
      to_ts: filterState.to_ts,
      customer_ids,
      order_cols: "total_ot desc NULLS last",
    };
    let url = `${APP_BASE_URL_ALARM}${PM_UTILISATION}`;
    axios.get(url, { params: filterParams }).then((res) => {
      if (res) {
        let Response = res.data?.slice(0, mostUtilisedAssetData.count) ?? [];
        Response = Response.map((row, i) => {
          let trimmedAssetName = trimAssetName(row.device_name);
          let totalRuntime = convertSecondsToDuration(row.total_ot)
          return {
            ...row,
            id: i,
            avg_ot: row.avg_ot.toFixed(2),
            assetName: trimmedAssetName,
            total_ot: totalRuntime
          };
        });
        setMostUtilisedAssetData((prev) => {
          prev.data = Response;
        });
      }
    });
  }
  const handleSearch = (filterState) => {
    getSummaryData(filterState);
    getMostUtilisedAssets(filterState);
  };
  return (
    <PmUtilastionContext.Provider
      value={{
        summaryData: summaryData,
      }}
    >
      <div className="pm-utilisation-container">
        <div className="pageHeader">{UtilizationLabel[assetType]}</div>
        <div className="utilisation-filter card">
          <Autocomplete
            size="small"
            className="filter-input auto-complete"
            multiple
            disableCloseOnSelect
            options={permittedCustomerIds}
            renderInput={(params) => (
              <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(customer) => customer.value}
            value={filterState?.customer_ids}
            onChange={(_, newValue) => {
              setFilterState((state) => {
                state.customer_ids = newValue;
              });
            }}
            renderTags={(value, getTagProps) => {
              return (
                <div>
                  <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
                  {value.length > 1 &&
                    <AutocompleteStationListPop title={value.map((stn) => stn?.value ?? '').toString()}>
                      <Chip size="small" label={`+${value.length - 1}`} />
                    </AutocompleteStationListPop>
                  }
                </div>
              );
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              size="small"
              disableFuture
              className="filter-input"
              // sx={{}}
              label="From Date"
              openTo="day"
              views={["year", "month", "day"]}
              value={filterState.from_ts}
              onChange={(newValue) => {
                if (Date.parse(newValue && newValue["$d"])) {
                  let ts = newValue["$d"].getTime();
                  setFilterState((prev) => {
                    prev.from_ts = ts;
                  });
                } else {
                  if (newValue === null) {
                    setFilterState((prev) => {
                      prev.from_ts = newValue;
                    });
                  }
                }
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
            <DatePicker
              disableFuture
              className="filter-input"
              label="To Date"
              openTo="day"
              views={["year", "month", "day"]}
              value={filterState.to_ts}
              onChange={(newValue) => {
                // let ts = newValue['$d'].getTime()
                // setFilterState((prev) => {
                //   prev.to_ts = ts;
                // });

                if (Date.parse(newValue && newValue["$d"])) {
                  let ts = newValue["$d"].getTime();
                  setFilterState((prev) => {
                    prev.to_ts = ts;
                  });
                } else {
                  if (newValue === null) {
                    setFilterState((prev) => {
                      prev.to_ts = newValue;
                    });
                  }
                }
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <Button variant="contained" onClick={() => handleSearch(filterState)}>
            Search
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setFilterState(initialFilters);
              handleSearch(initialFilters);
            }}
          >
            Clear Filters
          </Button>
        </div>

        <StackCountChart />

        <div className="mostUtilisedTable card">
          <div className="header">
            <div className="card-heading">Most Utilized Point Machines</div>
            <div className="countSelect">
              <div className="label">Number of Records:</div>
              <FormControl variant="standard" size="small">
                {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={mostUtilisedAssetData.count}
                  onChange={(event) => {
                    setMostUtilisedAssetData((prev) => {
                      prev.count = event.target.value;
                    });
                  }}
                // label="Age"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <DataTable
            key="station-wise-status-count-table"
            loading={false}
            tableInfo={{
              id: "most-utilised-point-machines",
              label: "Most Utilised point machines",
              requireAuthSign: false,
            }}
            pageSize={pageSize}
            pageSizeChange={(size) => {
              setPageSize(size);
            }}
            rows={mostUtilisedAssetData.data}
            columns={mostUtilizedTableColumn[assetType]}
          />
        </div>

        <UtilisationPieChartTime runTimeType="Total_runtime" />

        <UtilisationPieChartTime runTimeType="Average_runtime" />
      </div>
    </PmUtilastionContext.Provider>
  );
}

export default PmUtilisation;
