import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ALARM_NOTIFICATION,
  APP_BASE_URL_NOTIFICATIONS,
  MARK_NOTIFICATION,
  NOTIFICATION_COUNT,
} from "../../Common/EndPoints";

export const apiNotifications = createApi({
  reducerPath: "notificationsAPI",
  baseQuery: fetchBaseQuery({ baseUrl: APP_BASE_URL_NOTIFICATIONS }),
  tagTypes: ["alarm-notifications-list", "notifications-count"],
  endpoints: (builder) => ({
    getAlarmNotification: builder.query({
      query: (params) => {
        return {
          url: ALARM_NOTIFICATION,
          params,
        };
      },
      providesTags: ["alarm-notifications-list"],
    }),
    getNotificationCount: builder.query({
      query: (params) => {
        return {
          url: NOTIFICATION_COUNT,
          params: { ...params, uninteracted: true },
        };
      },
      providesTags: ["notifications-count"],
    }),
    markNotificationsRead: builder.mutation({
      query: (params) => {
        return { url: MARK_NOTIFICATION, params: { ...params, mark_type: "interacted" }, method: "PATCH" };
      },
      invalidatesTags: ["notifications-count"],
    }),
  }),
});

export const { useGetNotificationCountQuery, useLazyGetAlarmNotificationQuery, useMarkNotificationsReadMutation } =
  apiNotifications;
