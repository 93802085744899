import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useImmer } from "use-immer";
import {
  APP_BASE_URL,
  CREATE_FORM_ASSIGNMENT,
  FORM_ASSIGNMENT_API,
  GET_CUSTOMER_GROUP_LIST,
  GET_FORMS_LIST,
  GET_SCHEDULE_CODE_LIST,
  GET_USER_ROLES,
  GET_DEVICE_TYPES_FOR_TABLE,
} from "../../../Common/EndPoints";
import { BASE_URL } from "../../FormCreator/Forms/FormConstants";
import { getFormattedDate, GET_DROPDOWN_BY_URL } from "../Constant/Constants";
import "./AssignmentForm.scss";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import { useGetDeviceTypesQuery } from "../../../features/SearchFilters/searchFiltersSlice";
import { toast } from "react-hot-toast";
import { extractAllowedUserRoleList } from "../../../Utils/Utils";

const initialDropDowns = {
  scheduleCodes_DD: [],
  customerGroups_DD: [],
  userRoles_DD: [],
  formList_DD: [],
  gearTypes_DD: [],
};

let stateUrlObj = {
  customerGroups_DD: GET_CUSTOMER_GROUP_LIST,
  // userRoles_DD: GET_USER_ROLES,
  formList_DD: GET_FORMS_LIST,
};

const periodicityOptions = [
  { value: "Weekly", key: 7 },
  { value: "Fortnightly", key: 14 },
  { value: "Monthly", key: 30 },
  { value: "Quarterly", key: 90 },
  { value: "Half-yearly", key: 180 },
  { value: "Yearly", key: 365 },
];

let initialFormValues = {
  scheduleCodes: null,
  forms: null,
  stationGroup: null,
  role: null,
  peridiocity: null,
  offset: "",
  startDate: "",
  gearType: null,
  active: false,
};

let initialConfirmationModal = {
  open: false,
  message: "",
  des: "",
};

function hasDropdownCuurentValue(ddList, ddValue) {
  let hasValue = false;
  ddList.forEach((dd) => {
    if (dd.key === ddValue?.key) {
      hasValue = true;
    }
  });

  return hasValue;
}

function AssignmentForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: gearTypesList = [] } = useGetDeviceTypesQuery(null);
  let USER_ID = localStorage.getItem("userId");
  const [dropDowns, setDropDowns] = useImmer(initialDropDowns);
  const [formValue, setFormValues] = useImmer(initialFormValues);
  const [filteredFormList, setFilterdFormLIst] = useState([]);
  const [loader, setLoader] = useState(false);
  const [confirmationModal, setConfirmationModal] = useImmer(initialConfirmationModal);
  let LabelProps = {
    InputLabelProps: {
      shrink: true,
    },
  };

  useEffect(() => {
    Object.entries(stateUrlObj).forEach(([state, url]) => {
      setDropDownValues(state, url);
    });
    getUserRoleList();
  }, []);

  // }, []);

  useEffect(() => {
    if (dropDowns.scheduleCodes_DD && formValue?.scheduleCodes) {
      if (!hasDropdownCuurentValue(dropDowns.scheduleCodes_DD, formValue.scheduleCodes)) {
        setFormValues((prev) => {
          prev.scheduleCodes = null
        })
      }
    }
  }, [dropDowns.scheduleCodes_DD]);
  useEffect(() => {
    console.log('filteredFormList', filteredFormList)
    //   console.log('dd config',filteredFormList, formValue.forms)
    if (filteredFormList.length > 0 && formValue?.forms) {
      if (!hasDropdownCuurentValue(filteredFormList, formValue.forms)) {
        setFormValues((prev) => {
          prev.forms = null
        })
      }
    }
  }, [filteredFormList]);

  useLayoutEffect(() => {
    if (location.state) {
      setFormValues(location.state);
      getScheduleCode(location.state.gearType.id);
    }
  }, [location]);

  useEffect(() => {
    if (formValue.gearType) {
      console.log('gearType while editing', formValue.gearType)
      let gearType = formValue.gearType.value.toUpperCase();
      let newFormLIst = dropDowns.formList_DD.filter((val) => {
        let formName = val.value.toUpperCase();
        if (formName.includes(gearType)) {
        }
        return formName.includes(gearType);
      });
      console.log('newFormLIst', newFormLIst)
      setFilterdFormLIst(newFormLIst);
      //   setFormValues((prev)=>{
      //     prev.forms = null
      //   })
    } else {
      setFilterdFormLIst([]);
      //   setFilterdFormLIst(dropDowns.formList_DD);
      //   setFormValues((prev)=>{
      //     prev.forms = null
      //   })
    }
  }, [formValue.gearType, dropDowns.formList_DD]);

  const getUserRoleList = () => {
    let roleHierarchies = JSON.parse(localStorage.getItem("roleHierarchies"));
    let allowedRoleIdList = extractAllowedUserRoleList(roleHierarchies);
    setDropDowns((prev) => {
      prev.userRoles_DD = allowedRoleIdList;
    });
  };

  const setDropDownValues = (state, url) => {
    let getUrl = `${APP_BASE_URL}${url}`;
    axios.get(getUrl).then((res) => {
      let RESPONSE = res.data.data;
      let ddList = Object.entries(RESPONSE).map(([key, value]) => {
        return { key: key, value: value };
      });
      setDropDowns((prev) => {
        prev[state] = ddList;
      });
    });
  };

  const handleFormSubmit = (formData) => {
    const params = {
      createdBy: USER_ID,
      customerGroupId: formData.stationGroup.key,
      dueOffset: Number(formData.dueAssignment),
      maintenanceFormId: formData.forms.key,
      periodicity: Number(formData.peridiocity.key),
      scheduleCodeId: formData.scheduleCodes.key,
      upcomingOffset: Number(formData.dueAssignment) + 1,
      userRoleId: formData.role.id,
      startedDt: new Date(formData.startDate).getTime(),
      active: formData.active,
    };

    let crudStatus = {
      success: "created successfully",
      error: "failed to create",
    };
    let url = `${APP_BASE_URL}${FORM_ASSIGNMENT_API}`;
    let apiRequest = axios.post;
    if (location.state) {
      crudStatus = {
        success: "updated successfully",
        error: "failed to update",
      };
      let assignmentId = location.state.assignmentId;
      url = `${APP_BASE_URL}${FORM_ASSIGNMENT_API}/${assignmentId}`;
      apiRequest = axios.put;
    }
    setLoader(true);
    apiRequest(url, null, { params: params })
      .then((res) => {
        setLoader(false);
        setFormValues(initialFormValues);
        localStorage.setItem("form-crud-status", crudStatus.success);
        navigate("/form-assignment");
      })
      .catch((err) => {
        setLoader(false);
        setFormValues(initialFormValues);
        const errMsg = err?.response?.data?.error?.message;
        localStorage.setItem("form-crud-status", errMsg);
        // const errMsg = err?.response?.data?.error?.message.slice(0, 15);
        // localStorage.setItem("form-crud-status", errMsg.length > 20 ? errMsg.slice(0, 19) + "..." : errMsg);
        navigate("/form-assignment");
      });
  };

  const handleOnChange = (value, stateName) => {
    setFormValues((prev) => {
      prev[stateName] = value;
    });
  };
  const getScheduleCode = (typeId) => {
    let url = `${APP_BASE_URL}${GET_SCHEDULE_CODE_LIST}`;
    let params = {
      deviceTypeId: typeId,
      scheduleCodeMapingOnly: true,
    };
    axios
      .get(url, { params })
      .then((res) => {
        let RESPONSE = res.data.data;
        let ddList = RESPONSE.map((val, i) => {
          return { key: val.id, value: val.code };
        });
        // let ddList = Object.entries(RESPONSE).map(([key, value]) => {
        //     return { key: key, value: value };
        // });

        setDropDowns((prev) => {
          prev.scheduleCodes_DD = ddList ?? [];
        });
        // setFormValues((prev)=>{
        //     prev.scheduleCodes = null
        // })
      })
      .catch((err) => {
        setDropDowns((prev) => {
          prev.scheduleCodes_DD = [];
        });
        // setFormValues((prev)=>{
        //     prev.scheduleCodes = null
        // })
      });
  };
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        let allowApiCall = true;
        let pridiocity = Number(formValue.peridiocity.key);
        let dueOffset = Number(formValue.dueAssignment);
        if (dueOffset > pridiocity) {
          allowApiCall = false;
          toast.error("Due Assignment should be less than Peridiocity");
        }
        if (allowApiCall) {
          setConfirmationModal((prev) => {
            prev.open = true;
            prev.message = "Form Assignment";
            prev.des = "Do you want to create form assignment?";
          });
        }
      }}
    >
      <div className="form-page">
        {loader && <Loader />}
        {confirmationModal && (
          <ConfirmationModal
            open={confirmationModal.open}
            onClose={() => {
              setConfirmationModal(initialConfirmationModal);
            }}
            onAction={(confirmation) => {
              if (confirmation) {
                handleFormSubmit(formValue);
              }
              setConfirmationModal(initialConfirmationModal);
            }}
            message={confirmationModal.message}
            des={confirmationModal.des}
          />
        )}
        <div className="form-section">
          <div className="part-header">Assignment Info</div>
          <div className="form-part">
            <Autocomplete
              disablePortal
              className="field"
              options={gearTypesList}
              value={formValue.gearType}
              //sx={{ width: 300 }}
              renderInput={(params) => <TextField required {...params} label="Gear Type" />}
              getOptionLabel={(params) => {
                return params.value;
              }}
              onChange={(event, newValue) => {
                handleOnChange(newValue, "gearType");
                getScheduleCode(newValue?.id ?? null);
              }}
            />
            <Autocomplete
              disablePortal
              className="field"
              options={dropDowns.scheduleCodes_DD}
              value={formValue.scheduleCodes}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField required {...params} label="Select Schedule Codes" />}
              getOptionLabel={(params) => {
                return params.value;
              }}
              onChange={(event, newValue) => {
                handleOnChange(newValue, "scheduleCodes");
              }}
            />
            <Autocomplete
              disablePortal
              className="field"
              // options={dropDowns.formList_DD}
              options={filteredFormList}
              value={formValue.forms}
              //sx={{ width: 300 }}
              renderInput={(params) => <TextField required {...params} label="Select Form" />}
              getOptionLabel={(params) => {
                return params.value;
              }}
              onChange={(event, newValue) => {
                handleOnChange(newValue, "forms");
              }}
            />
            <Autocomplete
              disablePortal
              className="field"
              options={dropDowns.customerGroups_DD}
              value={formValue.stationGroup}
              //sx={{ width: 300 }}
              renderInput={(params) => <TextField required {...params} label="Select Station Group" />}
              getOptionLabel={(params) => {
                return params.value;
              }}
              onChange={(event, newValue) => {
                handleOnChange(newValue, "stationGroup");
              }}
            />

            <Autocomplete
              disablePortal
              className="field"
              options={dropDowns.userRoles_DD}
              value={formValue.role}
              //sx={{ width: 300 }}
              renderInput={(params) => <TextField required {...params} label="Select role" />}
              getOptionLabel={(params) => {
                return params.value;
              }}
              onChange={(event, newValue) => {
                handleOnChange(newValue, "role");
              }}
            />
          </div>
        </div>
        <hr className="horizontal-rule"></hr>
        <div className="form-section">
          <div className="part-header">Timelines</div>
          <div className="form-part">
            <Autocomplete
              disablePortal
              className="field"
              options={periodicityOptions}
              value={formValue.peridiocity}
              renderInput={(params) => <TextField required {...params} label="Periodicity" />}
              getOptionLabel={(params) => {
                return params.value;
              }}
              onChange={(event, newValue) => {
                handleOnChange(newValue, "peridiocity");
              }}
              required
            />
            <TextField
              name="dueAssignment"
              className="field"
              onChange={(e) => {
                if (e.target.value >= 0) {
                  handleOnChange(e.target.value, "dueAssignment");
                }
              }}
              type={"number"}
              value={formValue.dueAssignment}
              label={"Due assignment"}
              placeholder="Enter Due assignment"
              required
            />
            <TextField
              name="startDate"
              className="field"
              onChange={(e) => {
                handleOnChange(e.target.value, "startDate");
              }}
              type={"date"}
              value={formValue.startDate}
              label={"Start From"}
              placeholder="Enter Start Date"
              {...LabelProps}
            />
          </div>
        </div>
        {/* <div className="footer">
                 

                    <Button variant="contained" type="submit">
                        {location.state ? 'Update Assignment' : 'Assign'}
                    </Button>
                </div> */}
        <div className="footer">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={formValue.active} onChange={(e) => handleOnChange(e.target.checked, "active")} />
              }
              label="Start Schedule"
            />
          </FormGroup>
          <Button variant="contained" type="submit">
            {location.state ? "Update Assignment" : "Assign"}
          </Button>
        </div>
      </div>

      {/* </div> */}
      {/* // </div > */}
      {/* </div > */}
    </form>
  );
}

export default AssignmentForm;
