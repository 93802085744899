import { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from "@mui/material";
import { deleteGenericAttachment, getGenericAttachment } from "./assetRegisterUtils";
import { APP_BASE_URL_ALARM } from "../../../Common/EndPoints";

function getArrayIntersection(_array, _secondArray) {
  return _secondArray.filter(Set.prototype.has.bind(new Set(_array)));
}

function useReactiveDropdown({ fieldData }) {
  const { control, getValues, setValue } = useFormContext();
  const { reactiveKey, reactiveValue, showInitially, multiple, key } = fieldData;

  let dropdownOptions = [];
  let showDropdown = showInitially;

  const parentValues = useWatch({ control, name: reactiveKey });

  /** when parent field options change then reactive-dropdown options will change
   * its value will change if value was selected but now options are not present in parent
   */
  useEffect(() => {
    let _value = getValues(key);

    if (!_value) return;

    if (!multiple) {
      // value is not present in dropdown options then remove value
      if (!dropdownOptions.includes(_value)) _value = "";
    }
    //set value as the common elements between dropdown options and value array
    else _value = getArrayIntersection(_value, dropdownOptions);
    setValue(key, _value);
  }, [parentValues]);

  if (parentValues?.length > 0) {
    Object.entries(reactiveValue).forEach(([option, value]) => {
      if (parentValues.includes(option)) {
        dropdownOptions = [...dropdownOptions, ...value];
        showDropdown = true;
      }
    });
  }

  const dropdownProps = { fieldData: { ...fieldData, type: "dropdown", options: dropdownOptions } };
  return { showDropdown, dropdownProps };
}

const ReactiveDropdown = (props) => {
  const { showDropdown, dropdownProps } = useReactiveDropdown(props);
  if (showDropdown) {
    return <Dropdown {...dropdownProps} />;
  }
  return <></>;
};

const Dropdown = ({ fieldData }) => {
  const { options = [], multiple = false, label, key, required, disabled } = fieldData;
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const _label = isRequiredField(required) ? label + "*" : label;
  const currentValue = useWatch({ control, name: key });

  return (
    <FormControl fullWidth>
      <InputLabel>{_label}</InputLabel>
      <Select
        name={key}
        className="field"
        label={_label}
        multiple={multiple ?? false}
        error={Boolean(errors[key])}
        disabled={disabled}
        {...register(key, { required: isRequiredField(required) })}
        value={currentValue || (multiple ? [] : "")}
      >
        {options?.map((item, index) => {
          return (
            <MenuItem key={key + "form-field-dropdown" + index} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
      <FieldErrorMessage error={errors[key]} />
    </FormControl>
  );
};
const isRequiredField = (required) => (required ? required : false);

const FieldErrorMessage = ({ error }) => {
  if (error?.message) return <FormHelperText error>{error.message}</FormHelperText>;
  switch (error?.type) {
    case "required":
      return <FormHelperText error>This field is required</FormHelperText>;
    default:
      return <>{}</>;
  }
};

function isLessThan(_first, _second, type) {
  if (type === "number") {
    return Number(_first) < Number(_second);
  }
  return _first < _second;
}

const ALPHANUMERIC_EXTENDED_MATCH = /^[a-zA-Z0-9\-/]+$/;
const ALPHANUMERIC_EXTENDED_SPACES_MATCH = /^[a-zA-Z0-9\-\/\s]+$/;
const INTEGER_MATCH = /^-?\d+$/;

function isFieldValidated(value, formValues, fieldData, form_keys_map) {
  const { conditions, type, required, max_length } = fieldData;
  if (!value && !required) return true;
  if (type === "number" && isNaN(Number(value))) return "Not a valid Number";
  if (type === "alphanumeric_extended") {
    const match = value.match(ALPHANUMERIC_EXTENDED_MATCH);
    if (!match) {
      return "Allowed only Text, Integers, hyphen(-) and backslash (/)";
    }
  }
  if (type === "alphanumeric_extended_spaces") {
    const match = value.match(ALPHANUMERIC_EXTENDED_SPACES_MATCH);
    if (!match) {
      return "Allowed only Text, Integers, hyphen(-), backslash (/) and spaces";
    }
  }
  if (type === "integer") {
    const match = value.match(INTEGER_MATCH);
    if (!match) {
      return "Allowed only Integer Values";
    }
  }
  if (max_length) {
    if (value.length > max_length) return `Length must be lesser than ${max_length} characters`;
  }
  if (!conditions) return true;
  if (type !== "date" && type !== "number") return true;

  const { min, max } = conditions;
  if (min) {
    const { values, parent_ids } = min;
    let _fixedValue = values[0];
    if (_fixedValue && isLessThan(value, _fixedValue, type)) return `Must be greater than ${_fixedValue}`;
    if (parent_ids) {
      for (const _id of parent_ids) {
        const parentValue = formValues?.[_id];
        if (!parentValue) continue;
        if (isLessThan(value, parentValue, type)) return `Must be greater than ${form_keys_map[_id].label}`;
      }
    }
  }
  if (max) {
    const { values, parent_ids } = max;
    const _fixedValue = values[0];
    if (_fixedValue && isLessThan(_fixedValue, value, type)) return `Must be lesser than ${_fixedValue}`;
    if (parent_ids) {
      for (const _id of parent_ids) {
        const parentValue = formValues[_id];
        if (!parentValue) continue;
        if (isLessThan(parentValue, value, type)) return `Must be lesser than ${form_keys_map[_id].label}`;
      }
    }
  }

  return true;
}
export function showSection({ parentValues, reactiveValue }) {
  if (Array.isArray(parentValues)) {
    const valueMatch = getArrayIntersection(parentValues, reactiveValue);
    if (valueMatch.length > 0) return true;
  } else {
    if (reactiveValue?.includes(parentValues)) {
      return true;
    }
  }
  return false;
}

const ReactiveSection = ({ fieldData }) => {
  const { reactiveKey, reactiveValue } = fieldData;
  const { control } = useFormContext();
  const parentValues = useWatch({ control, name: reactiveKey });
  const show = showSection({ parentValues, reactiveValue });
  if (show) return <FormField fieldData={{ ...fieldData, type: "section" }} />;
  return <></>;
};

const AttachmentNode = ({ fieldData }) => {
  const {
    register,
    formState: { errors },
    control,
    editMode,
    setValue,
  } = useFormContext();
  const { key, required, multiple, fileType, label } = fieldData;
  const _label = isRequiredField(required) ? label + "*" : label;
  const [files, setFiles] = useState([]);
  const [filesMetaData, setFilesMetaData] = useState(null);
  const currentValue = useWatch({ control, name: key });

  useEffect(() => {
    register(key, {
      required: isRequiredField(required),
      value: [],
    });
  }, []);
  // console.log("current value outside", currentValue);

  useEffect(() => {
    const _files = currentValue && Array.isArray(currentValue) ? [...currentValue] : [];
    setFiles(_files);
    if (editMode && filesMetaData === null && currentValue?.length > 0) {
      const ids = [];
      // console.log("current value", currentValue);
      currentValue.forEach((file) => {
        if (file instanceof File) return;
        ids.push(file);
      });
      // console.log("ids", ids);
      if (ids.length === 0) return;
      const params = { ids: ids.toString(), entity: "form" };
      getGenericAttachment(params).then((res) => {
        const _metaData = {};
        for (const _file of res?.data) {
          _metaData[_file.id] = _file;
        }
        setFilesMetaData(_metaData);
      });
    }
  }, [currentValue]);

  const acceptedTypes = fileType.join(",");
  // console.log("currentValue", currentValue);
  // console.log("files", files);
  // console.log("filesMetaData", filesMetaData);

  // const registerProps = register(key, {
  //   required: isRequiredField(required),
  //   value: [],
  // });

  // const { onChange, name, ref, onBlur } = registerProps;

  function handleAddFile(event) {
    if (currentValue && Array.isArray(currentValue)) {
      setValue(key, [...currentValue, ...event.target.files]);
    } else {
      setValue(key, [...event.target.files]);
    }
  }

  function handleFileRemove(file, index) {
    const _files = [...files];
    _files.splice(index, 1);
    setFiles(_files);
    setValue(key, _files);
    // setValue(key, [1, 2, 3, 4]);
    // if (file instanceof File) {
    //   _files.splice(index, 1);
    //   setFiles(_files);
    // } else {
    //   deleteGenericAttachment({ id: file, params: { entity: "form" } }).then((res) => {
    //     console.log("delete res", res);
    //     _files.splice(index, 1);
    //     setFiles(_files);
    //     setValue(key, _files);
    //   });
    // }
  }

  return (
    <FormControl fullWidth className="assets__asset-register__form__field--attachment">
      {_label}
      <input
        type="file"
        accept={acceptedTypes}
        multiple={multiple}
        // {...register(key, {
        //   required: isRequiredField(required),
        //   value: [],
        // })}
        // {...registerProps}
        // name={name}
        // ref={ref}
        onChange={handleAddFile}
        // onBlur={onBlur}
      />
      <div>
        {files?.map((file, index) => (
          <div className="flex gap-2" key={"attachment-node-" + file}>
            <span className="flex gap-2 items-center">
              {index + 1}.
              <a href={`${APP_BASE_URL_ALARM}/${filesMetaData?.[file]?.path}`} target="_blank">
                {filesMetaData?.[file]?.name ?? file?.name}
              </a>
            </span>
            <span
              className="cursor-pointer"
              onClick={() => {
                handleFileRemove(file, index);
              }}
            >
              X
            </span>
          </div>
        ))}
      </div>
      <FieldErrorMessage error={errors[key]} />
    </FormControl>
  );
};

export const FormField = ({ fieldData = {} }) => {
  const {
    register,
    unregister,
    formState: { errors },
    control,
    form_keys_map,
    setValue,
  } = useFormContext();
  const { type, label, key, placeholder, required, conditions, multiple, fileType, disabled } = fieldData;
  const _minValue = conditions?.min?.values?.[0];
  const _maxValue = conditions?.max?.values?.[0];
  const currentValue = useWatch({ control, name: key });
  const _label = isRequiredField(required) ? label + "*" : label;

  let fieldProps = {};
  switch (type) {
    case "heading":
      return <h2 className="heading">{label}</h2>;
    case "sub_heading":
    case "sub-heading":
      return <h4 className="sub-heading">{label}</h4>;
    case "date": {
      fieldProps = {
        InputLabelProps: { shrink: type === "date" },
        InputProps: { inputProps: { min: _minValue, max: _maxValue } },
      };

      break;
    }
    case "dropdown": {
      return <Dropdown fieldData={fieldData} />;
    }
    case "reactive-dropdown":
    case "reactive_dropdown": {
      return <ReactiveDropdown fieldData={fieldData} />;
    }
    case "section": {
      const { sectionFields = [], label } = fieldData;
      const renderSection = sectionFields.map((field) => <FormField key={field.key} fieldData={field} />);
      return (
        <div className="asset-register__asset-form--section">
          <h4 className="asset-register__asset-form--section--title">{label}</h4>
          {renderSection}
        </div>
      );
    }
    case "reactive-section":
    case "reactive_section": {
      return <ReactiveSection fieldData={fieldData} />;
    }
    case "attachment":
      return <AttachmentNode fieldData={fieldData} />;
    default: {
    }
  }
  const _type = type === "number" ? "text" : type;

  return (
    <FormControl fullWidth>
      <TextField
        className="field"
        type={_type}
        // type={type}
        name={key}
        error={Boolean(errors[key])}
        placeholder={placeholder}
        label={_label}
        disabled={disabled}
        {...fieldProps}
        {...register(key, {
          required: isRequiredField(required),
          min: { value: _minValue, message: `Must be greater than ${_minValue}` },
          max: { value: _maxValue, message: `Must be lesser than ${_maxValue}` },
          validate: (value, formValues) => {
            return isFieldValidated(value, formValues, fieldData, form_keys_map);
          },
          // valueAsNumber: type === "number",
        })}
        value={currentValue || ""}
      />
      <FieldErrorMessage error={errors[key]} />
    </FormControl>
  );
};

export const FormRender = () => {};

export default FormRender;
