/**
 * Define application level constants here
 */

// import file from './../../public/urlConfig.json'

// configure following two constants before creating build file

const isProduction = true; //true ? PRODUCTUION URL :  LOCAL URL
const usedByQA = false; // true ? ports used(7092, 7081) :  ports used(9092, 8081)
// console.log('run', window.runtimeUrlConfig)
// URL CONFIG
const LOCAL_RAW_URL = "192.168.1.214:";
const PRODUCTION_RAW_URL = "rdpms.in:";
const RAW_URL = isProduction ? PRODUCTION_RAW_URL : LOCAL_RAW_URL;
// PORTS CONFIG
const basePort = usedByQA ? "443/edge" : "9092/edge";
const baseSocketPort = usedByQA ? "7084" : "8084";
const alarmPort = usedByQA ? "7081/alarm_api" : "8081/alarm_api";
const alarmSocketPort = usedByQA ? "7081/alarm_api/api/v2/stream/alarm" : "8081/alarm_api/api/v2/stream/alarm";

//REST API BASE URL

// export const APP_BASE_URL = "https://" + RAW_URL + basePort;
// export const APP_BASE_URL_ALARM = "https://" + RAW_URL + alarmPort;
// const notificationPort = usedByQA ? "7081" : "8081";
// export const APP_BASE_URL_NOTIFICATIONS = "https://" + RAW_URL + notificationPort + "/notification_api";

//WEBSOCKET URLS
// export const TELEMETRY_SOCKET_URL = "wss://" + RAW_URL + baseSocketPort + "/edge/wss";
// export const ALARM_SOCKET = "wss://" + RAW_URL + alarmSocketPort;


const urlConfigFile = window.runtimeUrlConfig
const { url, base_api_configurations, alarm_api_configurations } = urlConfigFile
const edge_base_api_prefix = '/edge/api'
const edge_base_websocket_prefix = '/edge/wss'
const edge_alarm_api_prefix = '/alarm_api/api/v2'
const edge_alarm_notification_api_prefix = '/notification_api/api/v1'
const trend_api_prefix = '/trend_api/api';

// export const APP_BASE_URL1 = base_api_configurations.api_protocol + url + base_api_configurations.api_port;
export const APP_BASE_URL = base_api_configurations.api_protocol + url + base_api_configurations.api_port + edge_base_api_prefix;
export const APP_BASE_URL_SOCKET = base_api_configurations.websocket_protocol + url + base_api_configurations.websocket_port + edge_base_websocket_prefix;
export const APP_BASE_URL_ALARM = alarm_api_configurations.api_protocol + url + alarm_api_configurations.api_port + edge_alarm_api_prefix;
export const GENRIC_ATTACHMENT_VIEW_URL = alarm_api_configurations.api_protocol + url + alarm_api_configurations.api_port + '/alarm_api';
export const APP_BASE_URL_ALARM_SOCKET = alarm_api_configurations.websocket_protocol + url + alarm_api_configurations.api_port + edge_alarm_api_prefix;
export const APP_BASE_URL_NOTIFICATIONS = alarm_api_configurations.api_protocol + url + alarm_api_configurations.api_port + edge_alarm_notification_api_prefix;
export const APP_BASE_URL_TREND = alarm_api_configurations.api_protocol + url + alarm_api_configurations.api_port + trend_api_prefix;

// Sidebar width
export const drawerWidth = 240;


//socketUrl 
export const TELEMETRY_WEBSOCKET = "/edge/wss"
export const ALARM_WEBSOCKET_PATH = "/stream/alarm"
// export const NOTIFICATION_WEBSOCKET = "/notification_api"

// BASE APIS
export const LOGIN_API = "/user/login";
export const REFRESH_TOKEN_PATH = "/user/refreshToken"

export const GENERATE_OTP = "/user/login/generateOtp";
export const GET_DEVICE_BY_CUSTOMER_ID = "/devices/deviceTypes";
export const ACK_MAINTENANCE_PVT_ENTRY = "/devices/maintenanceRoaster/ackPredictiveEntry?";
export const GET_UNIVERSAL_DEVICE_KEYS = "/deviceKeys";
export const ADD_USER = `/user`;
export const DELETE_USER = "/user/";
export const UPDATE_USER = "/user";
export const RESET_PASSWORD = "/user/password/reset?";
export const PRELOAD_YARDVIEW = "/devices/telemetry/svg";
export const GET_ROSTER_DATA_BY_ID = "/devices/maintenanceRoaster";
/** History */
export const GET_TELEMETRY_HISTORY = "/history/timeseries";
export const GET_TELEMETRY_HISTORY_BY_TIMESTAMP_WITH_KEYS = "/history/timeseries/keys";
export const EXPORT_HISTORY = "/history/export";
export const GET_LAST_ENTRY_TS = "/history/lastDeviceEntry"
export const GET_CABLE_MONITORING_HISTORY = "/history/cablemonitor/timeseries";

/*user*/
export const GET_ALL_USER = "/user";
export const GET_ALERTS_HISTORY = "/alert/stats/";
export const ASSET_ACTIVE_DISABLE_API = "/devices";
/* assets*/
export const UPLOAD_MULTIPLE_ATTACHMENTS = "/v1/attachment/upload_multiple";
export const GET_ATTACHMENTS = "/v1/attachment";
export const GET_ATTACHMENT_BY_ID = "/v1/attachment/static";
export const DELETE_ATTACHMENT = "/v1/attachment";
export const GET_COLLECTION_MAP_ID = "/v1/collection_map";
export const GET_ASSET_FORM = "/v1/asset/form";
export const GET_LATEST_TELEMETRY = "/devices/telemetry";
export const GET_DEVICE_BY_ID = "/v1/device";

//SFR APIS
export const SFR_API = "/v1/failureReports";
//ASSET APIS
export const GET_DEVICE_TYPE_ASSET = "/v1/device_type";
export const ASSET_FORM = "/v1/device"; // post||put
export const GET_ASSETS = "/v1/device";
export const DELETE_ASSET = "/v1/device/";
export const ASSET_ADDITIONAL_INFO = "/v1/response"; // get || post || put
export const RTU_CREDS_API = "/v1/device/";
export const ASSET_MAINTENANCE_TABLE_API = "/v1/maintenance/history";
export const ASSET_MAINTENANCE_HISTORY_API = "/v1/maintenance/device/history";
export const JSON_TYPES = "/v1/device/jsonTypes"; // used to get sub types of asset
//----------DROPDOWNS------
export const GET_USER_ROLES = "/dashboard/userRole";
export const GET_FORMS_LIST = "/dashboard/maintenanceForm";
export const GET_CUSTOMER_GROUP_LIST = "/dashboard/customerGroup";
export const GET_SCHEDULE_CODE_LIST = "/v1/maintenance/form/schedulecode";
export const GET_ASSET_LIST = "/dashboard/asset";
export const GET_USER_LIST = "/dashboard/user";
export const TRAIN_LIST_API = "/v1/trains/info";

//asset transfer
export const GET_USER_ASSIGNED_ASSETS = `/v1/assetAssignments/asset/user/`;
export const DELETE_RECLAIM_ASSET_ASSIGNMENT = `/v1/assetAssignment/reclaimTransfer`;
export const POST_ASSET_ASSIGNMENT_TRANSFER = `/v1/assetAssignment/transfer`;
//-----form assignment
export const FORM_ASSIGNMENT_API = "/v1/maintenance/form/schedule";
export const ASSET_ASSIGNMENT_API = "/v1/assetAssignment/asset/user/search";
export const POST_ASSET_ASSIGNMENT_API = "/v1/assetAssignments/asset/user";
export const DELETE_ASSET_ASSIGNMENT_API = "/v1/assetAssignments/asset";
export const MAINTENACE_FORM_API = "/v1/maintenance/form";
export const GET_CUSTOMER_GROUP = "/v1/maintenance/customerGroup";
export const POST_CUSTOMER_GROUP = "/v1/maintenance/customerGroup/create";
export const DELETE_CUSTOMER_GROUP = "/v1/maintenance/customerGroup/delete";
export const PUT_CUSTOMER_GROUP = "/v1/maintenance/customerGroup/update";
//----maintenance dropdowns----
export const GET_MAINTENANCE_COUNT = "/v1/maintenanceTask/counts";
export const GET_MAINTENACE_ASSET = `/v1/maintenanceTask`;
export const DASHBOARD_MAINTENANCE_ROSTER = `/v1/devices/maintenanceRoaster/overview`;




// ALARM_APIS
export const GENERIC_BASE_PATH = "/generic";
export const PACKET_PARAM = GENERIC_BASE_PATH + "/packet_parma";
export const FMEA_RELATION = GENERIC_BASE_PATH + "/fmea_relation";
//-----dashboards---
export const GROUP_BY_COUNT_API = "/alarm/group_by_count";
/** ALARMS APIs */
export const GET_PAGINATED_ALARMS = `/alarm/paginated`;
export const GET_GROUP_BY_COUNT = `/alarm/group_by_count`;
export const PATCH_ACK_ALARM = `/alarm/acknowledge`;
export const PATCH_CLEAR_ALARM = `/alarm/clear`;
export const GENERIC_ATTACHMENTS = `/generic/attachment`;
export const GET_ALARM_BY_ID = "/alarm/";

/** Rules APIs */
export const GET_RULES = `/rule`;
export const POST_RULE = `/rule`;
export const PUT_RULE = `/rule/`;
export const DELETE_RULE = `/rule/`;
export const COPY_RULES = `/rule/batch_copy`;

/** Utilisation APIs */
export const PM_UTILISATION = "/utilisation/pm_summary";
export const TC_UTILISATION = "/utilisation/tc_summary";

/** Notifications - Firebase Cloud Messaging(FCM) */
export const ALARM_NOTIFICATION = "/frontend/notification";
export const NOTIFICATION_COUNT = "/frontend/notification/count";
export const MARK_NOTIFICATION = "/frontend/notification/mark";
export const REGISTER_USER = "/frontend/fcm/register";
export const UNREGISTER_USER = "/frontend/fcm/unregister";
/** Generic APIs */
export const GENERIC_ATTACHMENT = `/generic/attachment`; //get|post|delete
export const GENERIC_DEVICE_CONNECTIONS = "/generic/device_connection";
export const RTU_HEALTH = "/generic/rtu_health";
export const RTU_HEALTH_STATUS = '/generic/rtu_health_status';


export const GET_TREND_FEATURE = "/trend/features"; // get list of features (each row represents one trend feature)
export const GET_TREND_FEATURE_FOR_GRAPH = "/trend/paginated"; //get get each features data point (for each day and device and feature one row is there)
export const GET_TREND_HISTORY = "/trend/paginated/feat_agg"; //to get history of trends features (for each day and device one row is there)
export const GET_TREND_LATEST = "/trend/paginated/feat_agg/latest"; //to get latest trend of all devices (for each device one row is there)



// export const GET_ALL_DEVICES_API = "/api/v2/devices/getAllDevices";
// export const GET_DEVICE_BY_USER_ID = "/api/devices/user/";
// export const ADD_DEVICE_API = "/api/v2/devices/add";
// export const GET_ALL_ALERTS = "/api/v2/alert/allActiveAlerts";
// export const GET_ALL_CUSTOMERS = "/api/v2/customer/getAllCustomers";
// export const DELETE_DEVICE = "/api/v2/devices/delete";
// export const ACK_ALARM = "/api/v2/alert/ackAlarm";
// export const EDIT_DEVICE = "/api/v2/devices/updateDeviceById";
// export const GET_DEVICE_TYPES = "/api/devices/getDeviceTypes";
// export const GET_DEVICE_TYPES_FOR_TABLE = "/api/devices/deviceKeys/deviceTypes"; //universal
// export const GET_DEVICE_KEYS = "/api/devices/deviceKeys";
// export const CREATE_RULE = "/rule";
// export const RULES_API = "/rule";
// export const UPDATE_RULES = "/rule";
// export const GET_RULE_TEMPLATE = "/rule/template";
// export const GET_USER_BY_ID = "/api/user/";
// export const GET_ACTIVE_ALERTS_BY_ID = "/alert/active?customers=";
// export const ACK_CLEAR_ALARM = "/alert/";
// export const UPLOAD_SINGLE_ATTACHMENT = "/api/v1/attachment/upload";
// export const NOTIFY_ASSETS_UPDATE = "/api/v2/generic/refresh_store?store=DEVICE";
// export const GET_TELEMETRY_HISTORY_BY_DEVICEID = "/api/history?devieId=";
// export const GET_TELEMETRY_HISTORY_BY_TIMESTAMP = "/api/history/timeseries?deviceId=";
// export const GET_ZONE = "/api/dashboard/railwayData";
// export const GET_DIVISION_BY_ZONE = "/api/dashboard/railwayData/zone";
// export const GET_STATION_BY_DIV = "/api/dashboard/railwayData/division";
// export const SUBMIT_ADDITIONAL_INFO_ASSET = "/api/v1/response?";


export const tsToDateTimeFormatter = {
  valueFormatter: (param: any) => {
    if (param.value && param.value > 0) {
      const value = Number(param.value);
      const date = new Date(value);
      const yyyy = date.getFullYear();
      let mm: any = date.getMonth() + 1; // Months start at 0!
      let dd: any = date.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;
      const time = date.toLocaleTimeString();
      // return formattedToday;
      return formattedToday + " " + time;
    } else {
      return "-";
    }
  },
};

export const tsToDateFormatter = {
  valueFormatter: (param: any) => {
    if (param.value && param.value > 0) {
      const value = Number(param.value);
      const date = new Date(value);
      const yyyy = date.getFullYear();
      let mm: any = date.getMonth() + 1; // Months start at 0!
      let dd: any = date.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;
      return formattedToday;
    } else {
      return "-";
    }
  },
};
export const trimAssetName = (assetName: any) => {
  let trimmedAssetName = "-";
  if (assetName) {
    let stationName = assetName.split("_")[2];
    let splitNameFromIndex = assetName.indexOf(stationName) + stationName.length + 1;
    let deviceName = assetName.slice(splitNameFromIndex);
    trimmedAssetName = stationName + "-" + deviceName;
  }
  return trimmedAssetName;
};

