import { Autocomplete, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useImmer } from 'use-immer';
import { FormContext } from '../../../FormCreator';

let option = [
    { label: 'one', id: '1' },
    { label: 'two', id: '2' },
    { label: 'three', id: '3' },
    { label: 'four', id: '4' },
]
function getParentFieldOptionFromFields(jsonData, fieldType) {
    let labels = [];
    for (let i = 0; i < jsonData.length; i++) {
      const item = jsonData[i];
      if (item.type === fieldType) {
        labels.push({ label: item.label, value: item.key });
      } else if (item.sectionFields) {
        labels = labels.concat(getParentFieldOptionFromFields(item.sectionFields, fieldType));
      }
    }
    return labels;
  }
// const getParentFieldOptionFromFields = (fields, requiredType) => {
//     let options = [];
//     fields?.forEach((field, i) => {
//         if (field.type === requiredType || field.type === 'section' || field.type === 'reactive-section'  || field.type === 'reactive_section') {
//             if (field.type === 'section' || field.type === 'reactive-section'  || field.type === 'reactive_section') {
//                 options = [...options, ...getParentFieldOptionFromFields(field.sectionFields)];
//             } else {
//                 options.push({ label: field.label, value: field.key });
//             }
//         }
//     });
// // console.log()
//     return options;
// }
const initialState = {
    parent_ids_min: [],
    fixedValueMin: '',
    parent_ids_max: [],
    fixedValueMax: ''
}
function ValuesRangeTab({ getValuesRange, fieldNodeInfo }) {
    let LabelProps = {};
    if (fieldNodeInfo.type === "date") {
        LabelProps = {
            InputLabelProps: {
                shrink: fieldNodeInfo.type === "date",
            },
        };
    }
    const { formData } = useContext(FormContext)
    const [valueRangeState, setValueRangeState] = useImmer(initialState)
    const [paranetDD, setParentDD] = useState(option)
    const [parentFieldOptios, setParentFieldOption] = useState(() => getParentFieldOptionFromFields(formData, fieldNodeInfo.type))

useEffect(()=>{
    if(allowRangesToBeSet(valueRangeState)){
        let valueRangeObj = {
            min:{
                values:[valueRangeState.fixedValueMin],
                parent_ids:valueRangeState.parent_ids_min
            },
            max:{
                values:[valueRangeState.fixedValueMax],
                parent_ids:valueRangeState.parent_ids_max
            }
        }
        getValuesRange(valueRangeObj)
    }

},[valueRangeState])
const allowRangesToBeSet =(rangeState)=>{
let allow = false
Object.values(rangeState).forEach((value,i)=>{
    if(value.length>0){
        allow = true
        return;
    }
})
return allow
}
    const handleChange = (stateName, value) => {
        setValueRangeState((prev) => {
            prev[stateName] = value
        })
    }
    return (
        <>
            <div className='validation-row'>
                <div className='label'>Should be grater than:</div>
                <div className='fields'>
                    <FormControl size="small" className="fieldItem" fullWidth>
                        <InputLabel id="standard-label">{'Parent Fields'}</InputLabel>
                        <Select
                            defaultValue={valueRangeState?.parent_ids_min ?? []}
                            label={'Parent Fields'}
                            multiple
                            onChange={(e) => {
                                handleChange('parent_ids_min', e.target.value)

                            }}
                        >
                            {parentFieldOptios?.map((item, i) => {
                                return (
                                    <MenuItem key={i} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        size='small'
                        className='fieldItem'
                        type={fieldNodeInfo.type}
                        value={valueRangeState.fixedValueMin}
                        onChange={(e) => handleChange('fixedValueMin', e.target.value)}
                        label={'Fixed Value'}
                        {...LabelProps}
                    />
                </div>

            </div>
            <div className='validation-row'>
                <div className='label'>Should be Less than:</div>
                <div className='fields'>
                    <FormControl size="small" className="fieldItem" fullWidth>
                        <InputLabel id="standard-label">{'Parent Fields'}</InputLabel>
                        <Select
                            defaultValue={valueRangeState?.parent_ids_max ?? []}
                            label={'Parent Fields'}
                            multiple
                            onChange={(e) => {
                                handleChange('parent_ids_max', e.target.value)

                            }}
                        >
                            {parentFieldOptios?.map((item, i) => {
                                return (
                                    <MenuItem key={i} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        size='small'
                        className="fieldItem"
                        type={fieldNodeInfo.type}
                        value={valueRangeState.fixedValueMax}

                        onChange={(e) => { handleChange('fixedValueMax', e.target.value) }}
                        label={'Fixed Value'}
                        {...LabelProps}
                    />
                </div>

            </div>


        </>
    )
}

export default ValuesRangeTab
