import React, { useState } from 'react'
import DataTable from '../../../DataGrid/DataGrid';

function DashboardMaintenanceRoster({ rows, column, id, classId }) {
    const [pageSize, setPageSize] = useState(10);
    return (
        <DataTable
            key="dashboard-maintenance-dashboard"
            loading={false}
            tableInfo={{
                id: id,
                label: id,
                requireAuthSign: false,
            }}
            pageSize={pageSize}
            pageSizeChange={(size) => {
                setPageSize(size);
            }}
            rows={rows}
            columns={column}

        />
    )
}

export default DashboardMaintenanceRoster
