import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import "./AssetGis.scss";
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css"; //
// import customeUrl from './Utils/Icons/icon.svg'
import { APP_BASE_URL, GET_ASSETS } from "../Common/EndPoints";
import axios from "axios";
import { selectedCustomersIdsSelector } from "../features/authentication/auth-slice";
import { useSelector } from "react-redux";
import Loader from "../Components/Loader/Loader";
import { useImmer } from "use-immer";
import { v4 as uuid } from "uuid";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { ActiveAlarmsData } from "../Components/Alarms/ActiveAlarms";
import { useGetDeviceTypesQuery, useGetDevicesNameQuery } from "../features/SearchFilters/searchFiltersSlice";
import { getDeviceNameSearchParams } from "../features/SearchFilters/searchFiltersUtils";
import { getMarkerIcon } from "./Utils/Constants";
import GisPopup from "./Utils/GisPopup.js/GisPopup";
import { AutocompleteStationListPop } from "../Components/AutocompleteStationListPop/AutocompleteStationListPop";

const RenderAssetMarker = ({ deviceData, selectedAsset }) => {
    const { latitude, longitude, name, deviceType, deviceid } = deviceData;

    return (
        <div style={{ backgroundColor: "red" }}>
            <Marker position={[latitude, longitude]} icon={getMarkerIcon(deviceType.type, selectedAsset, deviceid)}>
                <Popup>
                    <GisPopup deviceData={deviceData} />
                </Popup>
            </Marker>
        </div>
    );
};

const intialAssets = [];
const initCords = {
    center: [20.5937, 78.9629],
    zoom: 5,
};

const iniFilters = {
    selectedCustomerIds: [],
    assetTypes: [],
};
function extractCommonFilter(filterValue, dropdown, keyName) {
    // return filterValue.filter((element) => dropdown.includes(element));
    return filterValue.filter((filter) => dropdown.some((dropdown) => filter[keyName] === dropdown[keyName]));
}
function AssetGis() {
    const [assets, setAssets] = useState(intialAssets);
    const [loader, setLoader] = useState(false);
    const [currCords, setCurrCords] = useImmer(initCords);
    const [filters, setFilters] = useImmer(iniFilters);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const customerIdList = useSelector(selectedCustomersIdsSelector);
    const { selectedCustomersIdName = [] } = useContext(ActiveAlarmsData);
    const { data: gearTypesList = [] } = useGetDeviceTypesQuery(null);
    const { data: deviceNamesList = [] } = useGetDevicesNameQuery(
        getDeviceNameSearchParams({
            customerIdList,
            filterState: {
                customer_ids: filters.selectedCustomerIds,
                device_type_ids: filters.assetTypes,
            },
        }),
    );

    useEffect(() => {
        if (customerIdList.length && !filters.selectedCustomerIds.length > 0) {
            getAssetsList(filters);
        }
    }, [customerIdList]);

    const getAssetsList = (filtersParams) => {
        setLoader(true);
        const params = {
            customerIds:
                filtersParams.selectedCustomerIds.length > 0
                    ? filtersParams.selectedCustomerIds.map((stn) => stn.id).toString()
                    : customerIdList.toString(),
            type_id: filtersParams.assetTypes.map((type) => type.id).toString(),
            is_active: true,
            with_type: true,
        };
        let url = `${APP_BASE_URL}${GET_ASSETS}`;
        axios
            .get(url, { params })
            .then((res) => {
                let RESPONSE = res.data.data;

                setAssets(RESPONSE);
            })
            .catch(() => { })
            .then(() => {
                setLoader(false);
            });
    };
    const permittedCustomerIds = useMemo(() => {
        const _list = selectedCustomersIdName;
        if (!_list) return [];
        const optionList = _list.map((customer) => ({ id: customer.customerId, value: customer.customerName }));
        const commanStations = extractCommonFilter(filters.selectedCustomerIds, optionList, "id");
        if (commanStations.length < filters.selectedCustomerIds.length) {
            setFilters((prev) => {
                prev.selectedCustomerIds = commanStations;
            });
            const filterParams = {
                selectedCustomerIds: commanStations,
                assetTypes: filters.assetTypes,
            };
            getAssetsList(filterParams);
        }
        return optionList;
    }, [selectedCustomersIdName?.length]);
    const renderMap = useMemo(() => {
        const key = uuid();
        return (
            <MapContainer
                key={key}
                center={currCords.center}
                zoom={currCords.zoom}
                scrollWheelZoom={true}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer
                    attribution=''
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    minZoom={5}
                // maxZoom={20}
                />
                {assets.map((asset, i) => {
                    return <RenderAssetMarker key={i} deviceData={asset} selectedAsset={selectedAsset} />;
                })}
            </MapContainer>
        );
    }, [currCords.center, currCords.zoom, assets]);
    // }, [currCords.center, currCords.zoom, assets])
    const findAsset = (asset) => {
        if (asset) {
            setLoader(true);
            const { id, value } = asset;
            const params = {
                deviceIds: id,
                with_type: true,
                with_attachments: true,
            };
            let url = `${APP_BASE_URL}${GET_ASSETS}`;
            axios
                .get(url, { params })
                .then((res) => {
                    const RESPONSE = res.data.data[0];
                    const { latitude, longitude } = RESPONSE;
                    setCurrCords((prev) => {
                        prev.center = [latitude, longitude];
                        prev.zoom = 20;
                    });
                })
                .catch((err) => { })
                .then(() => {
                    setLoader(false);
                });
        } else {
            setCurrCords(initCords);
        }
    };
    return (
        <div className="gis-conatiner">
            <div className="gis-filters">
                <div className="filter-inputs">
                    <Autocomplete
                        size="small"
                        className="filter-input auto-complete"
                        multiple
                        disableCloseOnSelect
                        options={permittedCustomerIds}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
                        )}
                        getOptionLabel={(gearType) => gearType.value}
                        value={filters?.selectedCustomerIds}
                        onChange={(_, newValue) => {
                            setFilters((state) => {
                                state.selectedCustomerIds = newValue;
                            });
                        }}
                        renderTags={(value, getTagProps) => {
                            return (
                                <div>
                                    <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
                                    {value.length > 1 && (
                                        <AutocompleteStationListPop title={value.map((stn) => stn?.value ?? "").toString()}>
                                            <Chip size="small" label={`+${value.length - 1}`} />
                                        </AutocompleteStationListPop>
                                    )}
                                </div>
                            );
                        }}
                    />
                    <Autocomplete
                        size="small"
                        className="filter-input auto-complete assetType"
                        multiple
                        disableCloseOnSelect
                        options={gearTypesList}
                        renderInput={(params) => (
                            <TextField
                                className="filter-input"
                                size="small"
                                {...params}
                                label="Asset types"
                                placeholder="Asset Types"
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(gearType) => gearType.value}
                        value={filters?.assetTypes}
                        onChange={(_, newValue) => {
                            setFilters((state) => {
                                state.assetTypes = newValue;
                            });
                        }}
                        renderTags={(value, getTagProps) => {
                            return (
                                <div>
                                    <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
                                    {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
                                </div>
                            );
                        }}
                    />
                    <Button
                        onClick={() => {
                            getAssetsList(filters);
                        }}
                        variant="contained"
                    >
                        Filter
                    </Button>
                    <Button
                        onClick={() => {
                            setFilters(iniFilters);
                            getAssetsList(iniFilters);
                        }}
                        variant="outlined"
                    >
                        clear
                    </Button>
                    <Button
                        onClick={() => {
                            setFilters(iniFilters);
                            getAssetsList(iniFilters);
                            setSelectedAsset(null);
                            findAsset(null);
                        }}
                        variant="outlined"
                    >
                        Reset Gis
                    </Button>
                </div>
                <div className="find-asset-inputs">
                    <Autocomplete
                        size="small"
                        className="filter-input auto-complete assetType"
                        disableCloseOnSelect
                        options={deviceNamesList}
                        renderInput={(params) => (
                            <TextField className="filter-input" size="small" {...params} label="Assets" placeholder="Assets" />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(gearType) => gearType.value}
                        value={selectedAsset}
                        onChange={(_, newValue) => {
                            setSelectedAsset(newValue);
                        }}
                    />
                    <Button
                        onClick={() => {
                            findAsset(selectedAsset);
                        }}
                        variant="contained"
                    >
                        {" "}
                        Find
                    </Button>
                </div>
            </div>
            <div className="map-container">
                {loader && <Loader />}
                {renderMap}
            </div>
        </div>
    );
}

export default AssetGis;
