import React, { useContext, useEffect, useRef, useState } from "react";
import { CUSTOMER_ID_DATA, SELECTED_CUSTOMER_SVG, SVG_COLORS, checkIsValidValue } from "./UTILS/Constant/Constant";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { APP_BASE_URL, APP_BASE_URL_SOCKET, PRELOAD_YARDVIEW, TELEMETRY_SOCKET_URL } from "../../Common/EndPoints";
import { useImmer } from "use-immer";
import axios from "axios";
import "./NewYardLayout.scss";
import YardZoomComponent from "./UTILS/Zoom Component/YardZoomComponent";
import Loader from "../Loader/Loader";
import YardTooltip from "./UTILS/Tooltip/YardTooltip";
import { useSelector } from "react-redux";
import { singleSelectedCustomerSelector } from "../../features/authentication/auth-slice";
import NoSVgComponent from "./UTILS/SVG/NoSVgComponent";
import { checkboxClasses, chipClasses } from "@mui/material";
import {
  checkRangeValidation,
  getAllValidValues,
  getThresholdRanges,
  getValidbbValue,
} from "./UTILS/Constant/Thresholds";
import { health_monitoring_api } from "../health-monitoring/HealthMonitoringAPI";

const getSvgColor = (yardData, { customerName }) => {
  console.log("selectorColorObj", yardData);
  const SVG_ELEMENT = document.getElementById("main-svg");
  if (SVG_ELEMENT) {
    Object.entries(yardData).forEach(([uniqueId, packet]) => {
      let { STN } = packet;
      let PARENT_GEARTYPE = uniqueId.split("_")[1];
      let commonValidationParams = {
        station: STN,
        geartype: PARENT_GEARTYPE,
        devide: true,
      };
      const selectorColorObj = {};
      switch (PARENT_GEARTYPE) {
        case "TRACK": {
          let { TPR } = packet;
          const [station, assetType, assetName] = uniqueId.split("_");
          const trakUniqueId = [station, "TRACK", assetName].join("_");
          let selector = trakUniqueId + "Line";
          if (checkIsValidValue(TPR)) {
            const _value = Number(TPR) / 1000;
            if (_value > 18) {
              console.log("tprvalue", uniqueId, _value);
              selectorColorObj[selector] = { att: "stroke", color: SVG_COLORS.unocuppied };
            } else {
              console.log("tprvalue_unocu", uniqueId, _value);
              selectorColorObj[selector] = { att: "stroke", color: SVG_COLORS.occupied };
            }
          }
          break;
        }
        case "TC": {
          let { TPR } = packet;
          const [station, assetType, assetName] = uniqueId.split("_");
          const trakUniqueId = [station, "TC", assetName].join("_");
          let selector = trakUniqueId + "Line";
          if (checkIsValidValue(TPR)) {
            const _value = Number(TPR) / 1000;
            if (_value > 18) {
              console.log("tprvalue", uniqueId, _value);
              selectorColorObj[selector] = { att: "stroke", color: SVG_COLORS.unocuppied };
            } else {
              console.log("tprvalue_unocu", uniqueId, _value);
              selectorColorObj[selector] = { att: "stroke", color: SVG_COLORS.occupied };
            }
          }
          // if (checkIsValidValue(VR)) {
          //   const validationParams = {
          //     ...commonValidationParams,
          //     keyName: "VR",
          //     _value: VR,
          //   };
          //   if (checkRangeValidation(validationParams)) {
          //     //occupied check
          //     selectorColorObj[selector] = { att: "stroke", color: SVG_COLORS.occupied };
          //   } else {
          //     selectorColorObj[selector] = { att: "stroke", color: SVG_COLORS.unocuppied };
          //   }
          // }
          break;
        }

        case "PM": {
          let iconSelector = uniqueId + "Icon";
          let heartSelector = uniqueId + "Hr";
          let { OT, STN, DIR, NWKR, RWKR } = packet;

          if (DIR && !DIR.includes("*")) {
            if (DIR === "NTOR") {
              selectorColorObj[iconSelector] = { att: "stroke", color: SVG_COLORS.reverse };
            }
            if (DIR === "RTON") {
              selectorColorObj[iconSelector] = { att: "stroke", color: SVG_COLORS.normal };
            }
          } else {
            if (checkIsValidValue(RWKR)) {
              let otVal = Number(RWKR);
              const validationParamsRwkr = {
                ...commonValidationParams,
                keyName: "RWKR",
                _value: RWKR,
              };
              console.log("RWKR", RWKR);
              //checkFailure
              if (checkRangeValidation(validationParamsRwkr)) {
                selectorColorObj[iconSelector] = { att: "stroke", color: SVG_COLORS.reverse };
              }
              if (checkIsValidValue(NWKR)) {
                let otVal = Number(NWKR);
                const validationParamsNwkr = {
                  ...commonValidationParams,
                  keyName: "NWKR",
                  _value: NWKR,
                };
                console.log("NWKR", NWKR);
                //checkFailure
                if (checkRangeValidation(validationParamsNwkr)) {
                  selectorColorObj[iconSelector] = { att: "stroke", color: SVG_COLORS.normal };
                }
              }
            }
          }


          if (checkIsValidValue(OT)) {
            let otVal = Number(OT);
            const validationParamsOT = {
              ...commonValidationParams,
              keyName: "OT",
              _value: OT,
            };
            console.log("otValue", OT);
            //checkFailure
            if (checkRangeValidation(validationParamsOT)) {
              selectorColorObj[heartSelector] = { att: "fill", color: SVG_COLORS.pmFailure };
            }
          }
          // //check normal
          // if (OperationType) {
          //   // let pmColor = OperationType === "Reverse" ? SVG_COLORS.reverse : SVG_COLORS.normal;
          //   let pmColor = null;
          //   if (OperationType === "Reverse") pmColor = SVG_COLORS.reverse;
          //   if (OperationType === "Normal") pmColor = SVG_COLORS.normal;
          //   selectorColorObj[iconSelector] = { att: "stroke", color: pmColor ?? "black" };
          // } else {
          //   if (checkIsValidValue(NWKR) || checkIsValidValue(RNWKR)) {
          //     let nwkrVal = Number(NWKR) / 1000;
          //     let rnwkrVal = Number(RNWKR) / 1000;
          //     const validationParamsNWKR = {
          //       ...commonValidationParams,
          //       keyName: "NWKR",
          //       _value: NWKR,
          //     };
          //     const validationParamsRNWKR = {
          //       ...commonValidationParams,
          //       keyName: "RNWKR",
          //       _value: RNWKR,
          //     };
          //     //check normal
          //     if (checkRangeValidation(validationParamsNWKR) || checkRangeValidation(validationParamsRNWKR)) {
          //       selectorColorObj[iconSelector] = { att: "stroke", color: SVG_COLORS.normal };
          //     }
          //   }
          //   //check reverse
          //   if (checkIsValidValue(RWKR) || checkIsValidValue(RRWKR)) {
          //     // let rwkrVal = Number(RWKR) / 1000;
          //     // let rrwkrVal = Number(RRWKR) / 1000;
          //     //check normal
          //     const validationParamsRWKR = {
          //       ...commonValidationParams,
          //       keyName: "RWKR",
          //       _value: RWKR,
          //     };
          //     const validationParamsRRWKR = {
          //       ...commonValidationParams,
          //       keyName: "RRWKR",
          //       _value: RRWKR,
          //     };
          //     if (checkRangeValidation(validationParamsRWKR) || checkRangeValidation(validationParamsRRWKR)) {
          //       selectorColorObj[iconSelector] = { att: "stroke", color: SVG_COLORS.reverse };
          //     }
          //   }
          //   //checkFailure
          //   if (checkIsValidValue(OT)) {
          //     let otVal = Number(OT);
          //     const validationParamsOT = {
          //       ...commonValidationParams,
          //       keyName: "OT",
          //       _value: OT,
          //     };
          //     //checkFailure
          //     if (checkRangeValidation(validationParamsOT)) {
          //       selectorColorObj[heartSelector] = { att: "fill", color: SVG_COLORS.pmFailure };
          //     }
          //   }
          // }

          break;
        }

        case "SG": {
          let { SUBGEAR } = packet;
          //SHUNT
          if (SUBGEAR === "SHUNT") {
            let { VON, VOFF, PL } = packet;
            let vonSelector = uniqueId + "Aspect3";
            let voffSelector = uniqueId + "Aspect1";
            let plSelector = uniqueId + "Aspect2";
            if (checkIsValidValue(VON)) {
              let vonVal = Number(VON) / 1000;
              const validationParams = {
                ...commonValidationParams,
                keyName: "VON",
                _value: VON,
              };
              if (checkRangeValidation(validationParams)) {
                selectorColorObj[vonSelector] = { att: "fill", color: SVG_COLORS.signalYellow };
              } else {
                selectorColorObj[vonSelector] = { att: "fill", color: "black" };
              }
            }
            if (checkIsValidValue(VOFF)) {
              let voffVal = Number(VOFF) / 1000;
              const validationParams = {
                ...commonValidationParams,
                keyName: "VOFF",
                _value: VOFF,
              };
              // if (voffVal > 90) {
              if (checkRangeValidation(validationParams)) {
                selectorColorObj[voffSelector] = { att: "fill", color: SVG_COLORS.signalYellow };
              } else {
                selectorColorObj[voffSelector] = { att: "fill", color: "black" };
              }
            }
            if (checkIsValidValue(PL)) {
              let plVal = Number(PL) / 1000;
              const validationParams = {
                ...commonValidationParams,
                keyName: "PL",
                _value: PL,
              };
              // if (plVal > 90) {
              if (checkRangeValidation(validationParams)) {
                selectorColorObj[plSelector] = { att: "fill", color: SVG_COLORS.signalYellow };
              } else {
                selectorColorObj[plSelector] = { att: "fill", color: "black" };
              }
            }
          } else if (SUBGEAR === "HG") {
            //calling
            let { VS } = packet;
            let vsSelector = uniqueId + "Aspect";
            if (checkIsValidValue(VS)) {
              let vsVal = Number(VS) / 1000;
              const validationParams = {
                ...commonValidationParams,
                keyName: "VS",
                _value: VS,
              };
              if (checkRangeValidation(validationParams)) {
                selectorColorObj[vsSelector] = { att: "fill", color: SVG_COLORS.signalYellow };
              } else {
                selectorColorObj[vsSelector] = { att: "fill", color: SVG_COLORS.blackColor };
              }
            }
          } else if (SUBGEAR === "DISTANT") {
            let { IHHG, IDG, IHG } = packet;
            if (checkIsValidValue(IHHG)) {
              let doubleYellowselector1 = uniqueId + "Aspect2";
              let doubleYellowselector2 = uniqueId + "Aspect3";
              let ihhgVal = Number(IHHG);
              const validationParams = {
                ...commonValidationParams,
                keyName: "IHHG",
                _value: IHHG,
                devide: false,
              };

              if (checkRangeValidation(validationParams)) {
                selectorColorObj[doubleYellowselector1] = { att: "fill", color: SVG_COLORS.signalYellow };
                selectorColorObj[doubleYellowselector2] = { att: "fill", color: SVG_COLORS.signalYellow };
              } else {
                selectorColorObj[doubleYellowselector1] = { att: "fill", color: SVG_COLORS.blackColor };
                selectorColorObj[doubleYellowselector2] = { att: "fill", color: SVG_COLORS.blackColor };
              }
            }
            if (checkIsValidValue(IDG)) {
              let greenSelector = uniqueId + "Aspect3";
              let idgVal = Number(IDG);
              const validationParams = {
                ...commonValidationParams,
                keyName: "IDG",
                _value: IDG,
                devide: false,
              };

              if (checkRangeValidation(validationParams)) {
                selectorColorObj[greenSelector] = { att: "fill", color: SVG_COLORS.signalGreen };
              } else {
                selectorColorObj[greenSelector] = { att: "fill", color: SVG_COLORS.blackColor };
              }
            }
            if (checkIsValidValue(IHG)) {
              let yellowSelector = uniqueId + "Aspect1";
              let ihgVal = Number(IHG);
              const validationParams = {
                ...commonValidationParams,
                keyName: "IHG",
                _value: IHG,
                devide: false,
              };

              if (checkRangeValidation(validationParams)) {
                selectorColorObj[yellowSelector] = { att: "fill", color: SVG_COLORS.signalYellow };
              } else {
                selectorColorObj[yellowSelector] = { att: "fill", color: SVG_COLORS.blackColor };
              }
            }
          } else {
            //main signal
            let SG_COMP = SVG_ELEMENT.querySelector(`[edge-id="${uniqueId}"]`);
            if (SG_COMP) {
              let SG_CHILD_ELEMENTS = SG_COMP.querySelectorAll("[edge-id-selector]");
              let aspectCount = 0;
              SG_CHILD_ELEMENTS.forEach((SG_CHILD) => {
                let SG_CHILD_ID = SG_CHILD.getAttribute("edge-id-selector");
                if (SG_CHILD_ID.includes(uniqueId + "Aspect")) {
                  aspectCount = aspectCount + 1;
                }
              });

              selectorColorObj[uniqueId + "Aspect1"] = { att: "fill", color: SVG_COLORS.blackColor };
              selectorColorObj[uniqueId + "Aspect2"] = { att: "fill", color: SVG_COLORS.blackColor };
              selectorColorObj[uniqueId + "Aspect3"] = { att: "fill", color: SVG_COLORS.blackColor };
              selectorColorObj[uniqueId + "Aspect4"] = { att: "fill", color: SVG_COLORS.blackColor };

              selectorColorObj[uniqueId + "Aspect1_class"] = { att: "class", color: "" };
              selectorColorObj[uniqueId + "Aspect2_class"] = { att: "class", color: "" };
              selectorColorObj[uniqueId + "Aspect3_class"] = { att: "class", color: "" };
              selectorColorObj[uniqueId + "Aspect4_class"] = { att: "class", color: "" };

              let updated = false;
              let { IDG, IHHG, IRG, IHG } = packet;
              if (checkIsValidValue(IHHG) && !updated) {
                // double yellow
                let ihhgVal = Number(IHHG);
                let doubleYellowselector1 = "";
                let doubleYellowselector2 = "";
                if (aspectCount === 2) {
                  doubleYellowselector1 = uniqueId + "Aspect1";
                  doubleYellowselector2 = uniqueId + "Aspect2";
                }
                if (aspectCount === 3) {
                  doubleYellowselector1 = uniqueId + "Aspect2";
                  doubleYellowselector2 = uniqueId + "Aspect3";
                }
                if (aspectCount === 4) {
                  doubleYellowselector1 = uniqueId + "Aspect2";
                  doubleYellowselector2 = uniqueId + "Aspect4";
                }
                const validationParams = {
                  ...commonValidationParams,
                  keyName: "IHHG",
                  _value: ihhgVal,
                  devide: false,
                };

                if (checkRangeValidation(validationParams)) {
                  updated = true;
                  selectorColorObj[doubleYellowselector1] = { att: "fill", color: SVG_COLORS.signalYellow };
                  selectorColorObj[doubleYellowselector2] = { att: "fill", color: SVG_COLORS.signalYellow };
                } else {
                  selectorColorObj[doubleYellowselector1] = { att: "fill", color: SVG_COLORS.blackColor };
                  selectorColorObj[doubleYellowselector2] = { att: "fill", color: SVG_COLORS.blackColor };
                }
              }
              if (checkIsValidValue(IDG) && !updated) {
                //green
                let idgVal = Number(IDG);
                let greenSelector = "";
                if (aspectCount === 2) {
                  greenSelector = uniqueId + "Aspect2";
                }
                if (aspectCount === 3) {
                  greenSelector = uniqueId + "Aspect3";
                }
                if (aspectCount === 4) {
                  greenSelector = uniqueId + "Aspect3";
                }
                const validationParams = {
                  ...commonValidationParams,
                  keyName: "IDG",
                  _value: IDG,
                  devide: false,
                };
                if (checkRangeValidation(validationParams)) {
                  updated = true;
                  selectorColorObj[greenSelector] = { att: "fill", color: SVG_COLORS.signalGreen };
                } else {
                  selectorColorObj[greenSelector] = { att: "fill", color: SVG_COLORS.blackColor };
                }
              }
              if (checkIsValidValue(IRG) && !updated) {
                //red

                let irgVal = Number(IRG);
                let redSelector = "";
                if (aspectCount === 1) {
                  redSelector = uniqueId + "Aspect1";
                }
                if (aspectCount === 2) {
                  redSelector = uniqueId + "Aspect1";
                }
                if (aspectCount === 3) {
                  redSelector = uniqueId + "Aspect1";
                }
                if (aspectCount === 4) {
                  redSelector = uniqueId + "Aspect1";
                }
                const validationParams = {
                  ...commonValidationParams,
                  keyName: "IRG",
                  _value: IRG,
                  devide: false,
                };

                if (checkRangeValidation(validationParams)) {
                  updated = true;
                  // console.log("signal selct", SG_COMP, { IDG, IHHG, IRG, IHG }, redSelector);
                  selectorColorObj[redSelector] = { att: "fill", color: SVG_COLORS.signalRed };
                  // selectorColorObj[redSelectorCopy] = { att: "fill", color: SVG_COLORS.signalRed };
                } else {
                  selectorColorObj[redSelector] = { att: "fill", color: SVG_COLORS.blackColor };
                  // selectorColorObj[redSelectorCopy] = { att: "fill", color: SVG_COLORS.blackColor };
                }
              }
              if (checkIsValidValue(IHG) && !updated) {
                //red
                let ihgVal = Number(IHG);
                let yellowSelector = "";
                if (aspectCount === 2) {
                  yellowSelector = uniqueId + "Aspect2";
                }
                if (aspectCount === 3) {
                  yellowSelector = uniqueId + "Aspect3";
                }
                if (aspectCount === 4) {
                  yellowSelector = uniqueId + "Aspect3";
                }
                const validationParams = {
                  ...commonValidationParams,
                  keyName: "IHG",
                  _value: IHG,
                  devide: false,
                };
                if (checkRangeValidation(validationParams)) {
                  updated = true;
                  selectorColorObj[yellowSelector] = { att: "fill", color: SVG_COLORS.signalYellow };
                } else {
                  selectorColorObj[yellowSelector] = { att: "fill", color: SVG_COLORS.blackColor };
                }
              }

              const validValues = getAllValidValues([IDG, IHG, IRG, IHHG]);
              if (validValues && !updated) {
                const isSignalBlank = validValues.every((value) => value < 60);

                selectorColorObj[uniqueId + "Aspect1_class"] = {
                  att: "class",
                  color: isSignalBlank ? "signalBlank" : "",
                };
                selectorColorObj[uniqueId + "Aspect2_class"] = {
                  att: "class",
                  color: isSignalBlank ? "signalBlank" : "",
                };
                selectorColorObj[uniqueId + "Aspect3_class"] = {
                  att: "class",
                  color: isSignalBlank ? "signalBlank" : "",
                };
                selectorColorObj[uniqueId + "Aspect4_class"] = {
                  att: "class",
                  color: isSignalBlank ? "signalBlank" : "",
                };
              }
            }

            // console.log('selectorColorObj', selectorColorObj)
          }
          break;
        }

        case "LCG": {
          let { AR, BR } = packet;
          if (checkIsValidValue(AR)) {
            let aOpenSelector = uniqueId + "BoomAOpen";
            let aCloseSelector = uniqueId + "BoomAClose";
            let arVal = Number(AR) / 1000;
            const validationParams = {
              ...commonValidationParams,
              keyName: "AR",
              _value: AR,
            };

            if (checkRangeValidation(validationParams)) {
              selectorColorObj[aCloseSelector] = { att: "fill", color: SVG_COLORS.boomCloseColor };
              selectorColorObj[aOpenSelector] = { att: "fill", color: SVG_COLORS.blackColor };
            } else {
              selectorColorObj[aOpenSelector] = { att: "fill", color: SVG_COLORS.boomOpenColor };
              selectorColorObj[aCloseSelector] = { att: "fill", color: SVG_COLORS.blackColor };
            }
          }

          if (checkIsValidValue(BR)) {
            let bOpenSelector = uniqueId + "BoomBOpen";
            let bCloseSelector = uniqueId + "BoomBClose";
            let brVal = Number(BR) / 1000;
            const validationParams = {
              ...commonValidationParams,
              keyName: "BR",
              _value: BR,
            };
            // chcek close
            if (checkRangeValidation(validationParams)) {
              selectorColorObj[bCloseSelector] = { att: "fill", color: SVG_COLORS.boomCloseColor };
              selectorColorObj[bOpenSelector] = { att: "fill", color: SVG_COLORS.blackColor };
            } else {
              selectorColorObj[bOpenSelector] = { att: "fill", color: SVG_COLORS.boomOpenColor };
              selectorColorObj[bCloseSelector] = { att: "fill", color: SVG_COLORS.blackColor };
            }
          }
          break;
        }
        case "AC" || "AXL": {
          const { VPWR, VR, PR, RST } = packet;
          let selector = uniqueId + "Icon";

          if (checkIsValidValue(VPWR) || checkIsValidValue(VR)) {
            let vrVal = Number(VR) / 1000;
            let vpwrVal = Number(VPWR) / 1000;
            const validationParamsVR = {
              ...commonValidationParams,
              keyName: "VR",
              _value: VR,
            };
            const validationParamsVPWR = {
              ...commonValidationParams,
              keyName: "VPWR",
              _value: VPWR,
            };
            //chcek red
            if (checkRangeValidation(validationParamsVPWR) || checkRangeValidation(validationParamsVR)) {
              selectorColorObj[selector] = { att: "fill", color: SVG_COLORS.acRed };
            } else if (checkIsValidValue(PR) || checkIsValidValue(RST)) {
              let prVal = Number(PR) / 1000;
              let rstVal = Number(RST) / 1000;
              const validationParamsPR = {
                ...commonValidationParams,
                keyName: "PR",
                _value: PR,
              };
              const validationParamsRST = {
                ...commonValidationParams,
                keyName: "RST",
                _value: RST,
              };
              //chcek orange
              if (checkRangeValidation(validationParamsPR) || checkRangeValidation(validationParamsRST)) {
                selectorColorObj[selector] = { att: "fill", color: SVG_COLORS.acOrange };
              } else {
                selectorColorObj[selector] = { att: "fill", color: SVG_COLORS.acGreen };
              }
            }
          }
          break;
        }
        case "BB": {
          // const { ["24DC"], VR, PR, RST } = packet;
          let DC24 = packet["24DC"];
          let AC110 = packet["110AC"];
          let DC110 = packet["110DC"];
          let DC60 = packet["60DC"];
          let DC6 = packet["6DC"];
          let DC12 = packet["12DC"];
          let bbKeyValObj = {
            "24DC": DC24,
            "110AC": AC110,
            "110DC": DC110,
            "6DC": DC6,
            "60DC": DC60,
            "12DC": DC12,
          };
          const { bbKey, bbValue } = getValidbbValue(bbKeyValObj);
          if (bbKey && bbValue) {
            let color = null;
            switch (bbKey) {
              case "24DC": {
                if (bbValue < 18) {
                  color = SVG_COLORS.bhmsRed;
                } else if (bbValue < 22) {
                  color = SVG_COLORS.bhmsOrange;
                } else {
                  color = SVG_COLORS.bhmsGreen;
                }
                break;
              }
              case "110AC": {
                if (bbValue < 90) {
                  color = SVG_COLORS.bhmsRed;
                } else if (bbValue < 100) {
                  color = SVG_COLORS.bhmsOrange;
                } else {
                  color = SVG_COLORS.bhmsGreen;
                }
                break;
              }
              case "110DC": {
                if (bbValue < 90) {
                  color = SVG_COLORS.bhmsRed;
                } else if (bbValue < 105) {
                  color = SVG_COLORS.bhmsOrange;
                } else {
                  color = SVG_COLORS.bhmsGreen;
                }
                break;
              }
              case "60DC": {
                if (bbValue < 50) {
                  color = SVG_COLORS.bhmsRed;
                } else if (bbValue < 55) {
                  color = SVG_COLORS.bhmsOrange;
                } else {
                  color = SVG_COLORS.bhmsGreen;
                }
                break;
              }
              case "6DC": {
                if (bbValue < 4) {
                  color = SVG_COLORS.bhmsRed;
                } else if (bbValue < 5) {
                  color = SVG_COLORS.bhmsOrange;
                } else {
                  color = SVG_COLORS.bhmsGreen;
                }
                break;
              }
              case "12DC": {
                if (bbValue < 10) {
                  color = SVG_COLORS.bhmsRed;
                } else if (bbValue < 11) {
                  color = SVG_COLORS.bhmsOrange;
                } else {
                  color = SVG_COLORS.bhmsGreen;
                }
                break;
              }
              default: {
              }
            }
            let selector = uniqueId + "Icon";
            selectorColorObj[selector] = { att: "fill", color };
          }
          // let DC24Val = Number(DC24) / 1000;
          // let AC110Val = Number(AC110) / 1000;
          // let DC110Val = Number(DC110) / 1000;
          // let DC60Val = Number(DC60) / 1000;
          // let DC110Val = Number(DC110) / 1000;
          // let DC110Val = Number(DC110) / 1000;

          // if (checkIsValidValue(DC24) || checkIsValidValue(AC110) || checkIsValidValue(DC110)) {
          //   let DC24Val = Number(DC24) / 1000;
          //   let AC110Val = Number(AC110) / 1000;
          //   const validationParamsDC24 = {
          //     ...commonValidationParams,
          //     keyName: "DC24",
          //     _value: DC24,
          //   };
          //   const validationParamsAC110 = {
          //     ...commonValidationParams,
          //     keyName: "AC110",
          //     _value: AC110,
          //   };
          //   const validationParamsDC110 = {
          //     ...commonValidationParams,
          //     keyName: "DC110",
          //     _value: DC110,
          //   };
          //   if (
          //     checkRangeValidation(validationParamsDC24) ||
          //     checkRangeValidation(validationParamsAC110) ||
          //     checkRangeValidation(validationParamsDC110)
          //   ) {
          //     selectorColorObj[selector] = { att: "fill", color: SVG_COLORS.bbGreen };
          //   } else {
          //     selectorColorObj[selector] = { att: "fill", color: SVG_COLORS.bbRed };
          //   }
          // }

          break;
        }
        case "BHMS" || "BAT": {
          const { PARENT_DEVICE } = packet;

          Object.entries(packet).forEach(([key, value]) => {
            if (checkIsValidValue(value)) {
              let keyValue = Number(value) / 1000;
              let selector = uniqueId + "_" + key;
              let thresholdValue = 1.9;
              if (key === "VBAT") {
                thresholdValue = 110;
              }
              if (customerName === "VS") {
                thresholdValue = 9.5;
              }
              if (key === "VBAT" || key === "IBAT") {
                if (key === "VBAT") {
                  let color = null;
                  if (keyValue > 106) {
                    color = SVG_COLORS.bhmsTextGreen;
                  } else {
                    color = SVG_COLORS.bhmsOrange;
                  }
                  console.log("bhmsPacket", packet);
                  selectorColorObj[selector] = { att: "text-with-color", value: keyValue + " " + "V", color: color };
                }
                if (key === "IBAT") {
                  let color = null;
                  if (keyValue < -1) {
                    color = SVG_COLORS.bhmsRed;
                  } else if (keyValue > 5) {
                    color = SVG_COLORS.bhmsOrange;
                  } else {
                    color = SVG_COLORS.bhmsTextGreen;
                  }
                  selectorColorObj[selector] = { att: "text-with-color", value: keyValue + " " + "A", color: color };
                }
              } else {
                if (keyValue > thresholdValue) {
                  selectorColorObj[selector] = { att: "fill", color: SVG_COLORS.bhmsGreen };
                } else {
                  // bhmsRed
                  selectorColorObj[selector] = { att: "fill", color: SVG_COLORS.bhmsOrange };
                }
              }
            }
          });
          break;
        }
        case "ELD": {
          const selector = uniqueId + "Icon";
          const value = packet.DRYCH;
          if (checkIsValidValue(value)) {
            selectorColorObj[selector] = {
              att: "fill",
              color: Number(value) === 1 ? SVG_COLORS.bhmsGreen : SVG_COLORS.bhmsRed,
            };
          }

          break;
        }
        default: {
        }
      }
      if (Object.keys(selectorColorObj).length > 0) {
        Object.entries(selectorColorObj).forEach(([selector, attriColor]) => {
          let sel = selector;
          if (selector.includes("class")) {
            sel = selector.split("_")[0];
          }
          if (attriColor.att === "text-with-color") {
            const { value, color } = attriColor;
            console.log(
              "bhmsText",
              value,
              `[edge-id-selector="${sel}"]`,
              SVG_ELEMENT.querySelector(`[edge-id-selector="${sel}"]`),
            );
            // SVG_ELEMENT.querySelector(`[edge-id-selector="${sel}"]`)?.setAttribute(attriColor.att, attriColor.color);
            SVG_ELEMENT.querySelector(`[edge-id-selector="${sel}"]`)?.setAttribute("fill", color);
            SVG_ELEMENT.querySelector(`[edge-id-selector="${sel}"]`).textContent = value;
            SVG_ELEMENT.querySelector(`[edge-id-selector="${sel}"]`).style.ffillWeight = "bold";
          } else {
            SVG_ELEMENT.querySelector(`[edge-id-selector="${sel}"]`)?.setAttribute(attriColor.att, attriColor.color);
          }
        });
      }
    });
  }
};

const toggleFullScreen = () => {
  const SVG_ELEMENT = document.getElementById("main-svg");
  if (SVG_ELEMENT.classList.value === "style") {
    SVG_ELEMENT.classList.remove("style");
  } else {
    SVG_ELEMENT.classList.add("style");
  }
  // let yard = document.getElementById("main-svg");
  if (SVG_ELEMENT.classList.value === "style") {
    // document.getElementById('zoom-comp-id').style.zIndex = 1200
    SVG_ELEMENT.style.position = "absolute";
    SVG_ELEMENT.style.top = "0";
    SVG_ELEMENT.style.left = "0";
    SVG_ELEMENT.style.zIndex = "1200";
    SVG_ELEMENT.style.background = "black";
    SVG_ELEMENT.style.overflowY = "scroll";
    SVG_ELEMENT.style.overflowX = "hidden";
  } else {
    // document.getElementById('zoom-comp-id').style.zIndex = 0
    SVG_ELEMENT.style.position = "static";
    SVG_ELEMENT.style.background = "white";
    // SVG_ELEMENT.style.zIndex = "0";
  }
};

const showToolTip = (event, uniqueId, yardData, setToolTipState, specialTooltipCase) => {
  const [station, assetType, assetName] = uniqueId.split("_");
  let _uniqueId = uniqueId;
  if (assetType === "TC") {
    _uniqueId = [station, "TRACK", assetName].join("_");
  }
  if (yardData[uniqueId]) {
    setToolTipState((draft) => {
      draft.open = true;
      draft.packet = yardData[_uniqueId];
      draft.uniqueId = _uniqueId;
      draft.specialTooltipCase = specialTooltipCase;
    });
  }
  const tooltip = document.getElementById("yard-tooltip");
  if (tooltip) {
    const tooltipStyles = getComputedStyle(tooltip);
    const TOOLTIP_HEIGHT = Number(tooltipStyles.height.slice(0, -2)); //in PX
    const TOOLTIP_WIDTH = Number(tooltipStyles.width.slice(0, -2)); //in PX
    tooltip.style.top = event.clientY - TOOLTIP_HEIGHT / 2.5 + "px";
    tooltip.style.left = event.clientX + 15 + "px";
    if (TOOLTIP_HEIGHT + event.screenY > window.screen.height) {
      // when tooltip height goes out viewport height
      tooltip.style.top = event.clientY - TOOLTIP_HEIGHT + "px";
    }
    if (TOOLTIP_WIDTH + event.screenX + 30 > window.screen.width) {
      // when tooltip height goes out viewport width
      tooltip.style.left = event.clientX - TOOLTIP_WIDTH - 15 + "px";
    }
  }
};

const initialTooltipState = { open: false, packet: null, uniqueId: null, specialTooltipCase: null };

function useMapOtherElementsForVS(element) {
  const { customerId, customerName } = useSelector(singleSelectedCustomerSelector);
  const { data: RtuHealthStatusRaw = [] } = health_monitoring_api.useGetRtuHealthStatusQuery(
    {
      status: "OFFLINE",
      customer_ids: customerId,
    },
    { pollingInterval: 30000 },
  );

  const { serverIsLive } = useContext(ActiveAlarmsData);

  function colorRtuHealth() {
    if (!element) return;
    let offline_rtu_count = 0;
    for (const status of RtuHealthStatusRaw) {
      if (status.col_name === "OFFLINE") offline_rtu_count = status.cnt;
    }
    const rtu_health = element?.getElementById("RTUHEALTHIcon");
    if (rtu_health) {
      if (offline_rtu_count > 0) rtu_health.setAttribute("fill", "red");
      else rtu_health.setAttribute("fill", "green");
    }

  }

  function colorServerStatus() {
    if (!element) return;
    const server_status_circle = element?.getElementById("EDGESERVERIcon");
    if (server_status_circle) {

      if (serverIsLive) server_status_circle.setAttribute("fill", "green");
      else server_status_circle.setAttribute("fill", "red");
    }
  }

  useEffect(() => {
    if (customerName !== "VS") return;
    if (!element) return;

    colorRtuHealth();
  }, [customerName, element, RtuHealthStatusRaw]);

  useEffect(() => {
    if (customerName !== "VS") return;
    if (!element) return;

    colorServerStatus();
  }, [customerName, element, serverIsLive]);
}

function NewYardLayout() {
  let socketData = useRef();
  const yardSocketRef = useRef();
  const selectedStation = useRef();
  const [yardData, setYardData] = useImmer({});
  const [zoomFactor, setZoomFactor] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toolTipState, setToolTipState] = useImmer(initialTooltipState);
  // const { customerId } = useContext(ActiveAlarmsData);
  const { customerId, customerName } = useSelector(singleSelectedCustomerSelector);
  useEffect(() => {
    selectedStation.current = customerName ?? "";
    if (SELECTED_CUSTOMER_SVG?.[customerName]) {
      setYardData({});
      socketData.current = {};
      getInitialData(customerId);
      setZoomFactor(0);
    }
  }, [customerId]);

  useEffect(() => {
    if (SELECTED_CUSTOMER_SVG?.[customerName]) {
      getLiveYardData();
    }
    return () => {
      // if (SELECTED_CUSTOMER_SVG?.[customerName]) {
      //     socketData.current = {}
      // }
    };
  }, []);

  useEffect(() => {
    if (SELECTED_CUSTOMER_SVG?.[customerName]) {
      toolTipEventListners();
    }
  }, [customerId, yardData]);

  useEffect(() => {
    if (JSON.stringify(yardData) !== "{}") {
      console.log("yardData", yardData);
      getSvgColor(yardData, { customerName });
    }
  }, [yardData]);

  const toolTipEventListners = () => {
    const SVG_ELEMENT = document.getElementById("main-svg");
    SVG_ELEMENT.querySelectorAll(".tooltip").forEach((element) => {
      element.addEventListener("mouseover", function (e) {
        let uniqueId = element.getAttribute("edge-id");
        let specialTooltipCase = null;
        if (uniqueId) {
          let gearType = uniqueId.split("_")[1];
          if (gearType === "BHMS") {
            let uniqueSelector = element.getAttribute("edge-id-selector");
            let uniqueIdKey = uniqueSelector.split("_").pop();
            specialTooltipCase = {
              keys: [uniqueIdKey],
              gearType: gearType,
            };
          }
          showToolTip(e, uniqueId, yardData, setToolTipState, specialTooltipCase);
        }
      });
      element.addEventListener("mouseleave", function (e) {
        setToolTipState(initialTooltipState);
      });
    });
  };

  const getInitialData = (customerId) => {
    setLoading(true);
    const url = `${APP_BASE_URL}${PRELOAD_YARDVIEW}?customerid=${customerId}`;
    axios
      .get(url)
      .then((res) => {
        let RESPONSE = res.data.data;
        let TYPE_DATA_OBJECT = {};
        RESPONSE.forEach((packet) => {
          const { PARENT_GEARTYPE, NAME, STN, PARENT_DEVICE } = packet.devicedatajson;
          if (PARENT_DEVICE) {
            let uniqueId = PARENT_DEVICE;
            if (TYPE_DATA_OBJECT[uniqueId]) {
              TYPE_DATA_OBJECT[uniqueId] = { ...TYPE_DATA_OBJECT[uniqueId], ...packet.devicedatajson };
            } else {
              TYPE_DATA_OBJECT[uniqueId] = packet.devicedatajson;
            }
          }
          // if (PARENT_GEARTYPE && NAME && STN) {
          //     let uniqueId = [STN, PARENT_GEARTYPE, NAME].join("_");
          //     if (TYPE_DATA_OBJECT[uniqueId]) {
          //         TYPE_DATA_OBJECT[uniqueId] = { ...TYPE_DATA_OBJECT[uniqueId], ...packet.devicedatajson };
          //     } else {
          //         TYPE_DATA_OBJECT[uniqueId] = packet.devicedatajson;
          //     }
          // }
        });
        socketData.current = TYPE_DATA_OBJECT;
        setYardData(TYPE_DATA_OBJECT);
        setLoading(false);
      })
      .then(() => { })
      .catch(() => {
        setLoading(false);
      });
  };
  const getLiveYardData = () => {
    const USER_ID = localStorage.getItem("userId");
    const socketUrl = `${APP_BASE_URL_SOCKET}/${USER_ID}`;
    yardSocketRef.current = new WebSocket(socketUrl);

    yardSocketRef.current.onopen = (a) => {
      // socketData.current = {};
    };
    yardSocketRef.current.omclose = (a) => { };
    yardSocketRef.current.onmessage = (res) => {
      if (JSON.parse(res.data).dataLists) {
        const RESPONSE = JSON.parse(res.data).dataLists[0].data;
        const STN_IN_PACKET = RESPONSE.STN[0].value;
        if (STN_IN_PACKET === selectedStation.current) {
          let { STN, NAME } = RESPONSE;
          // console.log('web', STN[0].value, RESPONSE)
          let parseWebResponse = {};

          Object.entries(RESPONSE).forEach(([key, value]) => {
            parseWebResponse[key] = value[0].value;
          });
          if (parseWebResponse.PARENT_DEVICE) {
            let { PARENT_DEVICE } = parseWebResponse;

            if (socketData.current[PARENT_DEVICE]) {
              // socketData.current[PARENT_DEVICE] = { ...socketData.current[PARENT_DEVICE], ...parseWebResponse }
              socketData.current = {
                ...socketData.current,
                [PARENT_DEVICE]: { ...socketData.current[PARENT_DEVICE], ...parseWebResponse },
              };
            } else {
              // socketData.current[PARENT_DEVICE] = parseWebResponse
              socketData.current = { ...socketData.current, [PARENT_DEVICE]: parseWebResponse };
            }
            setYardData((prev) => {
              return { ...socketData.current };
            });
          }
        }
      }
    };
  };

  const element = document.getElementById("main-svg");
  useMapOtherElementsForVS(element);
  if (element) {
    element.style.width = zoomFactor + 100 + "%";
    element.style.height = zoomFactor + 100 + "%";
  }
  return (
    <div className="yard-layout-main-container">
      {loading && (
        <div className="load">
          <Loader />
        </div>
      )}
      <div className="svg-container">{SELECTED_CUSTOMER_SVG?.[customerName] ?? <NoSVgComponent />}</div>
      {toolTipState.open && (
        <YardTooltip
          uniqueId={toolTipState.uniqueId}
          packet={toolTipState.packet}
          specialTooltipCase={toolTipState.specialTooltipCase}
        />
      )}
      {SELECTED_CUSTOMER_SVG?.[customerName] && (
        <YardZoomComponent
          plus={(plus) => {
            if (zoomFactor < 100) {
              setZoomFactor((prev) => prev + plus);
            }
          }}
          minus={(minus) => {
            if (zoomFactor > 0) {
              setZoomFactor((prev) => prev + minus);
            }
          }}
          reset={(reset) => {
            setZoomFactor(0);
          }}
          fullscreen={() => {
            toggleFullScreen();
          }}
          zoomPer={zoomFactor}
        />
      )}
    </div>
  );
}

export default NewYardLayout;
