import React, { useContext, useEffect, useState } from "react";
import "./AlarmCategoryFailureCount.scss";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import { useImmer } from "use-immer";
import ActiveAlarms, { ActiveAlarmsData } from "../../../Alarms/ActiveAlarms";
import { APP_BASE_URL, APP_BASE_URL_ALARM, GROUP_BY_COUNT_API } from "../../../../Common/EndPoints";
import axios from "axios";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Colors, CUSTOME_LEGENDS } from "../Constants";
import CustomeLegends from "../CUSTOME_LEGENDS/CustomeLegends";
import ChartDataLabels from "chartjs-plugin-datalabels";
import NoData from "../../../../Utils/NoDataComponent.js/NoData";
import { ALARM_CATEGORY_COLOR } from "../../../../Common/Constants";
ChartJS.register(ChartDataLabels);

const INITIAL_PIE_DATA = {
  labels: [],
  datasets: [
    {
      label: "Alarm Category Count",
      data: [0],
      backgroundColor: ["#cecece"],
      hoverOffset: 4,
    },
  ],
};
const CHART_OPTIONS = {
  responsive: true,
  aspectRatio: 1 / 1,
  cutout: "60%",

  plugins: {
    labels: {
      position: "outside",
    },
    legend: {
      display: false,
      // display:'bottom',
      // position: function (chart) {
      //     // setLegends(chart.chart.legend.legendItems)
      //     return ('bottom')
      // }
    },
    // pieceLabel: {
    //     render: 'label',
    //     arc: true,
    //     fontColor: '#000',
    //     position: 'outside'
    //   },
    datalabels: {
      // anchor:'end',
      color: "white",
      // offset:30,
      clip: true,
      // align :'end',
      formatter: function (count, chart) {
        const sum = chart.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
        let countPer = (count * 100) / sum;
        return countPer > 10 ? count : "";
      },
    },
    tooltip: {
      yAlign: "center",
      xAlign: "left",
      responsive: true,
    },
  },
};
function AlarmCategoryFailureChart() {
  const [pieData, setPieData] = useImmer(INITIAL_PIE_DATA);
  const [rawPieData, setRawPiedata] = useState([]);
  const [hideableLagends, setHidableLegends] = useState([]);
  const { customerIdList } = useContext(ActiveAlarmsData);
  const [legends, setLegends] = useState([]);
  useEffect(() => {
    if (customerIdList.length > 0) {
      getAlarmCountData();
    }
  }, [customerIdList]);

  useEffect(() => {
    if (rawPieData.length > 0) {
      let labels = [];
      let counts = [];
      let colors = [];
      let legends = [];
      rawPieData.forEach((row, i) => {
        if (!hideableLagends.includes(row.col_name)) {
          labels.push(row.col_name);
          counts.push(row.cnt);
          colors.push(ALARM_CATEGORY_COLOR[row.col_name] ?? Colors[i + 10]);
        }

        legends.push({ label: row.col_name, fill: ALARM_CATEGORY_COLOR[row.col_name] ?? Colors[i + 10] });
      });
      let newChartData = {
        labels: labels,
        datasets: [
          {
            label: "Alarm Category Count",
            data: counts,
            backgroundColor: colors,
            hoverOffset: 4,
          },
        ],
      };
      setPieData(newChartData);
      setLegends(legends);
    }
  }, [rawPieData, hideableLagends]);
  const getAlarmCountData = () => {
    let url = `${APP_BASE_URL_ALARM}${GROUP_BY_COUNT_API}?col_name=category&customer_ids=${customerIdList.toString()}&is_active=true`;
    axios.get(url).then((res) => {
      // let RESPONSE = [
      //     {
      //         "col_name": "Threshold",
      //         "cnt": 45
      //     },
      //     {
      //         "col_name": "Threshold34",
      //         "cnt": 40
      //     },
      //     {
      //         "col_name": "Threshold3ss4",
      //         "cnt": 30
      //     },
      //     {
      //         "col_name": "Threswwholdss4",
      //         "cnt": 50
      //     },
      // ]
      let RESPONSE = res.data;

      if (RESPONSE.length) {
        // RESPONSE.forEach((row, i) => {
        //     labels.push(row.col_name)
        //     counts.push(row.cnt)
        //     colors.push(ALARM_CATEGORY_COLOR[row.col_name] ?? Colors[i + 10])

        //     legends.push({ label: row.col_name, fill: ALARM_CATEGORY_COLOR[row.col_name] ?? Colors[i + 10] })
        // })
        // let newChartData = {
        //     labels: labels,
        //     datasets: [{
        //         label: 'Alarm Category Count',
        //         data: counts,
        //         backgroundColor: colors,
        //         hoverOffset: 4
        //     }]
        // }
        // setPieData(newChartData)
        setRawPiedata(RESPONSE);
      } else {
        setPieData(INITIAL_PIE_DATA);
        setLegends([]);
      }
    });
  };
  const hideSelectedLegends = (selectedLeg) => { };
  return (
    // <div className="alarm-category-failure-count-container card">
    //   <div className="header">
    //     <div className="heading">Alarm Category Failure Chart</div>
    //   </div>
    //   <div className="chart-container">
    //     {pieData.labels.length > 0 ? (
    //       <Chart className="pie-count-chart" type="doughnut" data={pieData} options={CHART_OPTIONS} />
    //     ) : (
    //       <NoData msg={`Not enough data`} />
    //     )}
    //   </div>

    //   <CustomeLegends
    //     legends={legends}
    //     getSelecetedLegends={(selectedLeg) => {
    //       setHidableLegends(selectedLeg);
    //     }}
    //   />
    // </div>
    <>
      {pieData.labels.length > 0 ? (
        <Chart className="pie-count-chart" type="doughnut" data={pieData} options={CHART_OPTIONS} />
      ) : (
        <NoData msg={`Not enough data`} />
      )}
      <CustomeLegends
        legends={legends}
        getSelecetedLegends={(selectedLeg) => {
          setHidableLegends(selectedLeg);
        }}
      />
    </>
  );
}

export default AlarmCategoryFailureChart;
