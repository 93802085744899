import React from 'react'
import SFRFORM_Component from '../utils/SFRFORM_Component'
import './SfrForm.scss'
import { useLocation } from 'react-router-dom'

function SfrForm() {
    let location = useLocation()
    console.log(' location state', location.state)
    return (
        <div className='sfr-form-component'>
            {/* <div className='sfr-form-header'>
                SFR FORM
            </div> */}
            <SFRFORM_Component initFaliureForm={location?.state ?? {}} isEdit={location.state ? true : false} />

        </div>

    )
}

export default SfrForm
