import {
  FIXED_VIRTUAL_BUFFER_BETWEEN_KEYS,
  FIXED_VIRTUAL_SPACE_FOR_EACH_KEY,
  KEYS_TO_BE_FORMATTED,
  STATIC_KEYS_INFO,
  TOTAL_POSSIBLE_PM_KEYS,
} from "./BhmsGraphConfig";

const getTransformedValue = (key, packet, keysVirtualThreshold) => {
  let transformedValue = null;
  let packetValue = packet?.[0]?.values?.[key] ?? null;
  if (packetValue) {
    packetValue = Number(packetValue);
    // backend & hardware chutiya h iski wajah se RTU NaN value ko 14510848 ya phir kuch 16710848 karke bhej deta h apni marzi se kuch bhi aa skta h
    // jo ki dikhani nahi h
    if ((packetValue || packetValue === 0) && packetValue < 1000000) {
      const keyForUnit = STATIC_KEYS_INFO[key].unit;
      let formmatedPacketValue = KEYS_TO_BE_FORMATTED.includes(keyForUnit) ? packetValue / 1000 : packetValue;
      if (key === "IBAT") {
        formmatedPacketValue += Math.abs(STATIC_KEYS_INFO[key]?.custom_range?.min);
      }
      const keyStartVal = keysVirtualThreshold[key].virtualMin;
      const keysUnitSize = STATIC_KEYS_INFO[key].actualUnitsForYaxis;
      const transFormaRatio = FIXED_VIRTUAL_SPACE_FOR_EACH_KEY / keysUnitSize;
      transformedValue = keyStartVal + transFormaRatio * formmatedPacketValue;
    }
  }
  return transformedValue;
};

export const retrieveTransformedValue = (key, value, keysVirtualThreshold) => {
  const TransformationConstantToBeMinus = keysVirtualThreshold[key].virtualMin;
  const keysUnitSize = STATIC_KEYS_INFO[key].actualUnitsForYaxis;
  const retrivalRatio = keysUnitSize / FIXED_VIRTUAL_SPACE_FOR_EACH_KEY;
  let retrivedValue = (value - TransformationConstantToBeMinus) * retrivalRatio;
  if (key === "IBAT") {
    retrivedValue -= Math.abs(STATIC_KEYS_INFO[key]?.custom_range?.min);
  }
  return retrivedValue.toFixed(1);
};

export const getTransformedSgHistorydata = (data) => {
  let transformedData = {};
  let keysVirtualThreshold = {};

  const historyData = [...data];
  if (historyData.length > 0) {
    TOTAL_POSSIBLE_PM_KEYS.map((key, index) => {
      const minValue = index * (FIXED_VIRTUAL_SPACE_FOR_EACH_KEY + FIXED_VIRTUAL_BUFFER_BETWEEN_KEYS);
      const maxValue = minValue + FIXED_VIRTUAL_SPACE_FOR_EACH_KEY;
      if (Array.isArray(key)) {
        for (const _key of key) {
          keysVirtualThreshold[_key] = { virtualMin: minValue, virtualMax: maxValue };
        }
      } else {
        keysVirtualThreshold[key] = { virtualMin: minValue, virtualMax: maxValue };
      }
    });
    // const sortedHistoryData = historyData.sort((a, b) => a.createdTs - b.createdTs);
    const sortedHistoryData = historyData.sort((a, b) => a.data[0].dateTime - b.data[0].dateTime);
    sortedHistoryData.forEach((historyPacket, index) => {
      const ts = historyPacket.data[0].dateTime;
      let packetForTs = {};
      const dataPacket = historyPacket.data;

      function getDataPacketForKey(key) {
        const formattedPacketValue = getTransformedValue(key, dataPacket, keysVirtualThreshold);
        if (formattedPacketValue) {
          packetForTs[key] = formattedPacketValue;
        }
      }
      TOTAL_POSSIBLE_PM_KEYS.forEach((key, index) => {
        if (Array.isArray(key)) {
          for (const _key of key) {
            getDataPacketForKey(_key);
          }
        } else {
          getDataPacketForKey(key);
        }
      });
      transformedData[ts] = packetForTs;
    });
  }
  return {
    transformHistoryData: transformedData,
    keysVirtualThreshold: keysVirtualThreshold,
  };
};

export const getAnnotaionsForPointGraph = (keysVirtualThreshold) => {
  let annotationObj = {};
  if (Object.keys(keysVirtualThreshold).length > 0) {
    TOTAL_POSSIBLE_PM_KEYS.forEach((key, i) => {
      let _key = key;
      if (Array.isArray(key)) _key = key[0];
      const startValue = keysVirtualThreshold[_key].virtualMin;
      const endValue = startValue + FIXED_VIRTUAL_SPACE_FOR_EACH_KEY;
      let box = {
        type: "box",
        yMin: startValue,
        yMax: endValue,
        borderWidth: 0.5,
        backgroundColor: "rgba(144, 238, 144, 0.25)",
        // backgroundColor: graphAnnotationBackgroundColorSg[key] ?? "rgba(255, 99, 132, 0.25)",
      };

      annotationObj[`box${i + 1}`] = box;

      if (STATIC_KEYS_INFO[key]?.custom_range?.min) {
        const size_factor = FIXED_VIRTUAL_SPACE_FOR_EACH_KEY / STATIC_KEYS_INFO[key].actualUnitsForYaxis;
        annotationObj[`box${i + 1}_center-line`] = {
          type: "box",
          yMin: startValue + Math.abs(STATIC_KEYS_INFO[key]?.custom_range?.min) * size_factor,
          yMax: startValue + Math.abs(STATIC_KEYS_INFO[key]?.custom_range?.min) * size_factor,
          borderWidth: 0.2,
          backgroundColor: "rgba(144, 238, 144, 0.25)",
        };
      }
    });
  }

  return annotationObj;
};

export function getMaxValueOfSgYaxis() {
  const total_virtual_space = TOTAL_POSSIBLE_PM_KEYS.length * FIXED_VIRTUAL_SPACE_FOR_EACH_KEY;
  const extra_space_on_y_axis = 100;
  const total_virtual_buffer = (TOTAL_POSSIBLE_PM_KEYS.length - 1) * FIXED_VIRTUAL_BUFFER_BETWEEN_KEYS;
  const maxYAxisValue = total_virtual_space + total_virtual_buffer + extra_space_on_y_axis;
  return maxYAxisValue;
}
