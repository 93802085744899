import React, { useState } from "react";
import './DashboardCard.scss'
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';

function DashboardCard({ gridChild, chartChild, cardId, cardName, initialViewMode, singleChild, singleChildType }) {
  const [viewMode, setViewMode] = useState(initialViewMode)
  return <div className={`dashboardCard ${cardId}`}>
    <div className="header">
      <div className='heading'>{cardName}</div>
      {!singleChild && (<ButtonGroup size="small" aria-label="outlined primary button group">
        <Tooltip placement="bottom" title="Chart" disableInteractive>
          <Button onClick={(e) => { setViewMode('chart') }} variant={`${viewMode === 'chart' ? 'contained' : 'outlined'}`} size="small"><InsertChartOutlinedRoundedIcon /></Button>
        </Tooltip>
        <Tooltip placement="bottom" title="Table" disableInteractive>
          <Button onClick={(e) => { setViewMode('grid') }} variant={`${viewMode === 'grid' ? 'contained' : 'outlined'}`} size="small"><TableRowsOutlinedIcon /></Button>
        </Tooltip>
      </ButtonGroup>)}

    </div>

    {viewMode === 'chart' && (<div className={`chart-table-container dashboardChart`}>{chartChild}</div>)}
    {viewMode === 'grid' && (<div className={`chart-table-container dashboardTable`}>{gridChild}</div>)}
    {viewMode === 'singleChild' && (<div className={`chart-table-container ${singleChildType}`}>{singleChild}</div>)}

  </div>
}

export default DashboardCard;
