import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { APP_BASE_URL_ALARM, GENRIC_ATTACHMENT_VIEW_URL, trimAssetName } from "../../../../../Common/EndPoints";
import './MaintenanceAttachPopup.scss'
import moment from "moment";

function MaintenanceAttachmentModel({ open, onClose, label, attachments, assetName, completeDt }) {
    // console.log("in pop", attachments);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            // TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle id="responsive-dialog-title" className="flex">
                {label}
                <CloseIcon
                    className="pointer"
                    sx={{ ml: "auto" }}
                    onClick={() => {
                        onClose(false);
                    }}
                />
            </DialogTitle>
            <DialogContent className="MaintAttachContent" dividers>
                <div className="attach-header">
                    <div className="assetName "><b>Asset Name:</b> <span className="valueLabel">{trimAssetName(assetName)}</span></div>
                    <div className="completeDtae "><b>Completed At:</b> <span className="valueLabel">{moment(completeDt).format("DD/MM/YYYY HH:MM:SS")}</span></div>
                </div>
                <div className="instruction">Please click the following links to open attachment</div>
                <div className="attachments">
                    {attachments.length > 0 && attachments.map((attachment, index) => {
                        return (
                            <>
                                <a href={`${GENRIC_ATTACHMENT_VIEW_URL}/${attachment}`} target="_blank" className="">
                                    {` Attachment ${index + 1}`}
                                </a> <br></br></>
                        );
                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <div className="maint-attach-popup-actions flex alignCenter">
                    <Button variant="outlined" autoFocus onClick={onClose}>
                        close
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default MaintenanceAttachmentModel;
