import { memo } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";

import AssetTransferModal from "./AssetTransferModal";
import { useAssetTransferSelector } from "./useAssetTransferSelector";

const SelectUser = memo(({ value, onChange, usersIdList, usersList, title, error }) => {
  return (
    <div>
      <div className="mb-3">{title}</div>
      <Autocomplete
        value={value}
        options={usersIdList}
        renderInput={(params) => (
          <TextField {...params} label="Select User" error={error} helperText={error ? "This field is required" : ""} />
        )}
        getOptionLabel={(userId) => usersList[userId]}
        onChange={onChange}
      />
    </div>
  );
});

const AssetTransfer = () => {
  const { actionState, usersList, usersIdList, setUserValue, handleModalClose, handleModalToggle } =
    useAssetTransferSelector();

  return (
    <div className="flex flex-col justify-between gap-8">
      <SelectUser
        value={actionState.user1}
        usersIdList={usersIdList}
        usersList={usersList}
        onChange={(_, value) => setUserValue("user1", value)}
        title={"Transfer Assets From/Reclaim Assets to"}
        error={actionState.user1error}
      />
      <SelectUser
        value={actionState.user2}
        usersIdList={usersIdList}
        usersList={usersList}
        onChange={(_, value) => setUserValue("user2", value)}
        title={"Transfer Assets to/Reclaim Assets from"}
        error={actionState.user2error}
      />
      <div className="flex justify-end gap-4">
        <Button variant="contained" onClick={() => handleModalToggle("Reclaim")}>
          Reclaim
        </Button>
        <Button variant="contained" onClick={() => handleModalToggle("Transfer")}>
          Transfer
        </Button>
      </div>
      {actionState.modalOpen && (
        <AssetTransferModal {...actionState} handleClose={handleModalClose} usersList={usersList} />
      )}
    </div>
  );
};

export default memo(AssetTransfer);
