import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ActiveAlarmsData } from "../../Alarms/ActiveAlarms";
import { useImmer } from "use-immer";
import { APP_BASE_URL, GET_DEVICE_TYPE_ASSET } from "../../../Common/EndPoints";
import axios from "axios";
import './AssetsFilters.scss'
import { AutocompleteStationListPop } from "../../AutocompleteStationListPop/AutocompleteStationListPop";
import { useSelector } from "react-redux";
import { selectedCustomersSelector } from "../../../features/authentication/auth-slice";

const initialFilters = {
  deviceTypeIds: [],
  customerIds: [],
  makeContains: "",
  nameContains: "",
};

function AssetsFilters({ getAssetFilter }) {
  const [filters, setFilters] = useImmer(initialFilters);
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const { customerIdList } = useContext(ActiveAlarmsData);
  const selectedCustomers = useSelector(selectedCustomersSelector);

  const permittedCustomerIds = useMemo(() => {
    const _list = selectedCustomers;
    if (!_list) return [];
    return _list.map((customer) => ({ id: customer.customerId, value: customer.customerName }));
  }, [selectedCustomers]);
  useEffect(() => {
    getDeviceTypes();
  }, []);
  useEffect(() => {
    setFilters((state) => {
      state.customerIds = state.customerIds.filter((customer) => customerIdList.includes(customer.id));
    });
  }, [customerIdList]);
  const handleChange = (value, stateName) => {
    setFilters((prev) => {
      prev[stateName] = value;
    });
  };
  const getDeviceTypes = () => {
    let url = `${APP_BASE_URL}${GET_DEVICE_TYPE_ASSET}`;
    axios.get(url).then((res) => {
      let deviceTypes = res.data.data.map((type) => {
        return {
          value: type.typeid,
          label: type.type,
          alias: type.label,
        };
      });
      setDeviceTypeList(deviceTypes);
      //   setDropDowns((prev) => {
      //     prev.deviceTypeDD = deviceTypes;
      //   });
    });
  };

  const onFilter = (filterParams) => {
    let newFilterParams = {
      ...filterParams,
      customerIds: filterParams.customerIds.map((val) => val.id),
      deviceTypeIds: filterParams.deviceTypeIds.map((val) => val.value),
    };
    getAssetFilter(newFilterParams);
  };
  return (
    <div className="assets-tableFilter">
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        multiple
        disableCloseOnSelect
        options={permittedCustomerIds}
        renderInput={(params) => (
          <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
        )}
        getOptionLabel={(customer) => customer.value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={filters?.customerIds}
        onChange={(_, newValue) => {
          handleChange(newValue, "customerIds");
        }}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && (
                <AutocompleteStationListPop title={value.map((stn) => stn?.value ?? "").toString()}>
                  <Chip size="small" label={`+${value.length - 1}`} />
                </AutocompleteStationListPop>
              )}
            </div>
          );
        }}
      />
      <Autocomplete
        className="filter-input"
        size="small"
        disablePortal
        id="combo-box-demo"
        value={filters.deviceTypeIds ?? []}
        options={deviceTypeList}
        getOptionLabel={(params) => {
          return params.alias;
        }}
        onChange={(_, newValue) => {
          handleChange(newValue, "deviceTypeIds");
        }}
        // sx={{ width: 300 }}
        renderInput={(params) => <TextField size="small" {...params} label="Asset Type" />}
        multiple
      />
      <TextField
        className="filter-input"
        type="text"
        size="small"
        label="Make Contains"
        value={filters.makeContains}
        onChange={(e) => {
          handleChange(e.target.value, "makeContains");
        }}
      />
      <TextField
        className="filter-input"
        type="text"
        size="small"
        label="Name Contains"
        value={filters.nameContains}
        onChange={(e) => {
          handleChange(e.target.value?.toUpperCase(), "nameContains");
        }}
      />
      <Button variant="contained" onClick={() => onFilter(filters)}>
        Filter
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          onFilter(initialFilters);
          setFilters(initialFilters);
        }}
      >
        Clear
      </Button>
    </div>
  );
}

export default AssetsFilters;
