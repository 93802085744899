import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
  registerables as registerablesJS,
} from "chart.js";
// import "chartjs-adapter-luxon";
// import ZoomPlugin from "chartjs-plugin-zoom";
import ChartStreaming, { StreamingPlugin, RealTimeScale } from "chartjs-plugin-streaming";

import { APP_BASE_URL, GET_TELEMETRY_HISTORY, JSON_TYPES } from "../../Common/EndPoints";
import { Colors } from "./Constant";

// APIs
export function getAssetSubTypes(params) {
  return axios.get(APP_BASE_URL + JSON_TYPES, { params });
}

export function getTelemetryHistory(params, signal) {

  return axios.get(APP_BASE_URL + GET_TELEMETRY_HISTORY, { params, signal });
}

// Utility Functions
export function registerChartJS() {
  ChartJS.register(...registerablesJS);
  ChartJS.register(
    CategoryScale,
    BarElement,
    LineController,
    LinearScale,
    PointElement,
    TimeSeriesScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    StreamingPlugin,
    RealTimeScale,
    ChartStreaming,
    // ZoomPlugin,
  );
}

export function getHistoryGraphData({ params, columns, columnUnit, live_chart }) {
  return getTelemetryHistory(params).then(
    (res) => {
      const _data = res?.data?.data;
      const _content = _data.content;
      const columnKeys = {};
      const time_duration = new Date().getTime() - _content.at(-1)?.data?.[0]?.dateTime;
      let _graphOptions = null;
      if (live_chart) {
        _graphOptions = getHistoryGraphOptions(time_duration);
      } else {
        _graphOptions = getHistoryTimeSeriesGraphOptions();
      }
      const labels = [];
      const displayFields = {};
      for (const _column of columns) {
        if (_column.field === "lastActivityTime" || _column.field === "NAME") continue;
        columnUnit[_column.field] = _column.unit;
        displayFields[_column.field] = [];
      }
      // loop through the data and push values for each key if they contain number
      for (const _row of _content) {
        const _rowData = _row?.data?.[0];
        const time = _rowData.dateTime;
        const values = _rowData.values;
        labels.unshift(new Date(time).toISOString());
        if (live_chart) {
          for (const _key in values) {
            const _value = values[_key];

            if (isNaN(_value)) continue;
            columnKeys[_key] ??= [];
            columnKeys[_key].unshift({ x: time, y: columnUnit[_key] === "v" ? _value / 1000 : _value });
            continue;
          }
        } else {
          for (const _key in displayFields) {
            let _value = values[_key];

            if (!_value || isNaN(_value)) {
              _value = null;
            }
            displayFields[_key].unshift(_value);
          }
        }
      }

      const keysToShow = {};
      if (live_chart) {
        // remove keys that are not to be shown
        for (const _column of columns) {
          if (!columnKeys[_column.field]) continue;
          keysToShow[_column.field] = columnKeys[_column.field];
        }
      }

      const _graphData = [];
      let _index = 0;
      const keys = live_chart ? keysToShow : displayFields;
      for (const _key in keys) {
        const _value = keys[_key];
        if (_value.length === 0) continue;
        let containsValue = false;
        for (const value of _value) {
          if (value !== null) containsValue = true;
        }
        if (!containsValue) continue;
        _graphData.push({
          label: _key,
          data: _value,
          borderColor: Colors[_index],
          yAxisID: columnUnit[_key] === "mA" ? "A" : "V",
          tension: 0.1,
          spanGaps: true,
        });
        _index++;
      }
      return { time_duration, _graphData, labels, _graphOptions };
    },
    (err) => err?.response?.data,
  );
}

export function getHistoryGraphOptions(timeRange) {
  const _options = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      datalabels: {
        formatter: function () {
          return "";
        },
      },
      tooltip: {
        mode: "index",
        intersect: true,
        position: "nearest",
      },
    },
    animation: false,
    stacked: false,
    scales: {
      x: {
        type: "realtime",
        distribution: "linear",
        realtime: {
          duration: timeRange,
          delay: 1000,
          time: {
            displayFormat: "h:mm",
          },
        },
      },
      V: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          // Include a unit sign in the ticks
          callback: function (value, index, ticks) {
            return value + " V";
          },
        },
      },
      A: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          // Include a unit sign in the ticks
          callback: function (value, index, ticks) {
            return value + " mA";
          },
        },
      },
    },
  };
  return _options;
}
export function getHistoryTimeSeriesGraphOptions() {
  const _options = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      datalabels: {
        formatter: function () {
          return "";
        },
      },
      tooltip: {
        mode: "index",
        intersect: true,
        position: "nearest",
      },
    },
    animation: false,
    stacked: false,
    scales: {
      V: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          // Include a unit sign in the ticks
          callback: function (value, index, ticks) {
            return value + " V";
          },
        },
      },
      A: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          // Include a unit sign in the ticks
          callback: function (value, index, ticks) {
            return value + " mA";
          },
        },
      },
    },
  };
  return _options;
}

export const convertToHigherUnit = (value, unit) => {
  if (isNaN(value) || value < 0) return "-";
  if (["v"].includes(unit)) return Number(value) / 1000;
  /** if unit is not 'MV' then return value as it is */
  if (!["MV"].includes(unit?.toUpperCase())) return value;
  // return value / 1000;
  return value;
  // if (!value) return "-";
  // if (!["MV"].includes(unit.toUpperCase())) return value + " " + unit;
  // return (value / 1000).toFixed(2) + " V";
};

export const subtractTime = (range, date = new Date()) => {
  if (range < 60) {
    date.setMinutes(date.getMinutes() - range);
    return date;
  } else {
    date.setHours(date.getHours() - range / 60);
    return date;
  }
};
