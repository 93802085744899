import React, { useEffect, useState } from 'react'
import { Chart } from 'react-chartjs-2';
import { useImmer } from 'use-immer';
import { Colors } from '../../Telemetry/Constant';
import './StackedBarChart.scss'
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import NoData from '../../../Utils/NoDataComponent.js/NoData';
import { useLocation, useNavigate } from 'react-router-dom';
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { RedirectionByUrl } from '../ReportsConstants';
import { trimAssetName } from '../../../Common/EndPoints';
import { useDispatch, useSelector } from 'react-redux';
import { implementTempReportFilters, selectCurrentReportFilters, selectGlobelReportFilter, selectTempReportFilter } from '../reportsSlice';
ChartJS.register(ChartDataLabels);


const initialChartData = {
    labels: ['DLO', 'NOQ', 'DTX', 'AUB', 'RQJ', 'NHGJ', 'NHLG', 'MBG'],
    datasets: [{
        label: 'TC',
        data: [],
        stack: 0,
        backgroundColor: 'blue',
    },
    { label: 'SG', data: [], stack: 1, backgroundColor: 'red', }]
}

const dataTypeKey = {
    station: 'stn_code',
    alarmType: 'type',
    category: 'category',
    devices: 'device_name',
    gearType: 'device_type_label'

}
const filterKeyObj = {
    category: 'categories',
    alarmType: 'alarm_types',
    gearType: 'device_type_ids'
}
let initialCardLabel = 'Total Event Count Of Selected Station by Type'
function StackedBarChart({ dependsOn, response }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [chartData, setChartData] = useImmer(initialChartData)
    const [cardLabel, setCardLabel] = useState(initialCardLabel)
    const [fullScreen, setFullScreen] = useState(false)
    const globelFilters = useSelector(selectGlobelReportFilter)
    const currentReportFilters = useSelector(selectCurrentReportFilters)
    const tempReportFilters = useSelector(selectTempReportFilter)
    const dispatch = useDispatch()




    // const InitialBarOption = {
    // type: 'bar',
    // data: data,
    const InitialBarOption = {
        responsive: true,
        // aspectRatio: 4.8 / 1,
        // aspectRatio: 2.8 / 1,
        maintainAspectRatio: false,
        onClick: function (chartData, b, c) {
            // console.log(RedirectionByUrl[location.pathname])
            const dataFromChart = chartData.chart.tooltip.dataPoints
            // console.log(dataFromChart)
            let navFilter
            if (dataFromChart[0].dataset.filterWhenMultipleData) {
                if (dataFromChart[0].dataset.redirectionFilter === 'customer_ids') {
                    navFilter = {
                        filterCustomerIds: [dataFromChart[0].dataset.filterIdObject[dataFromChart[0].label]]
                    }
                }
                if (dataFromChart[0].dataset.redirectionFilter === 'device_ids') {
                    let navObj = dataFromChart[0].dataset.filterIdObject[dataFromChart[0].label]
                    navObj.value = trimAssetName(navObj?.value)
                    navFilter = {
                        device_ids: [dataFromChart[0].dataset.filterIdObject[dataFromChart[0].label]]
                    }
                }


                //    console.log(navFilter)

            } else {
                if (dataFromChart[0].dataset.filterKey === 'alarm_types') {
                    navFilter = {
                        alarm_types: [dataFromChart[0].label]
                    }
                }
                if (dataFromChart[0].dataset.filterKey === 'categories') {
                    navFilter = {
                        categories: [dataFromChart[0].label]
                    }
                }
                if (dataFromChart[0].dataset.filterKey === 'device_type_ids') {
                    navFilter = {
                        device_type_ids: [dataFromChart[0].dataset.filterObj[dataFromChart[0].label]]
                    }
                }
            }
            // const TempFilterFromLocal = localStorage.getItem('tempFilters')
            // if (TempFilterFromLocal) {
            //     let newLocalObj = JSON.parse(TempFilterFromLocal)
            //     if (newLocalObj?.[RedirectionByUrl[location.pathname]]) {
            //         newLocalObj[RedirectionByUrl[location.pathname]] = { ...newLocalObj[RedirectionByUrl[location.pathname]], ...navFilter }
            //     } else {
            //         newLocalObj[RedirectionByUrl[location.pathname]] = { ...navFilter }
            //         // newLocalObj[RedirectionByUrl[location.pathname]] = {navFilter}
            //     }
            //     //  console.log('local',newLocalObj)
            //     localStorage.setItem('tempFilters', JSON.stringify(newLocalObj))

            // } else {
            //     localStorage.setItem('tempFilters', JSON.stringify({ [RedirectionByUrl[location.pathname]]: navFilter }))
            // }
            if (tempReportFilters.length > 0) {
                let lastObjOfTempFilter = tempReportFilters.slice(-1)[0];
                dispatch(implementTempReportFilters({ ...lastObjOfTempFilter, ...navFilter }))
            } else {
                dispatch(implementTempReportFilters({ ...currentReportFilters, ...navFilter }))
                // console.log('initit tmp filter', )
            }

            navigate(RedirectionByUrl[location.pathname])
            // console.log(chartData.chart.tooltip.title)
            // console.log(chartData.chart.tooltip.body[0].lines[0], b ,c)

        },
        interaction: {
            mode: 'index',
            intersect: false,
            callbacks: {
                intersect: function (a) {
                },
            },
        },
        plugins: {
            // title: {
            //   display: true,
            //   text: 'Chart.js Bar Chart - Stacked'
            // },
            legend: {
                display: false,
                // display: true,
                position: 'bottom',
                maxHeight: 25,
                maxWidth: 5,
            },
            datalabels: {
                anchor: 'end',
                color: "black",
                align: 'end',
                // offset:30,
                clip: true,
                // align :'end',
                formatter: function (count, chart) {
                    // const sum = chart.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
                    // let countPer = (count*100)/sum
                    // console.log(countPer)
                    // return count > 0 ? count : '0'
                    return ''
                }
            },
            tooltip: {
                yAlign: "center",
                xAlign: "left",
                responsive: true,
            }
        },

        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        }
    }
    //   };


    useEffect(() => {
        setChartData(initialChartData)
        if (response.length > 0) {



            if (dependsOn === 'devices') {
                let label = []
                let countArray = []
                let assetDeviceIdObj = {}
                response.forEach((row, i) => {
                    let trimmedAssetName = trimAssetName(row.device_name)
                    label.push(trimmedAssetName)
                    countArray.push(row.cnt)
                    if (!assetDeviceIdObj[trimmedAssetName]) {
                        assetDeviceIdObj[trimmedAssetName] = { id: row.device_id, value: row.device_name }
                    }
                })

                let newChartData = {
                    labels: label,
                    datasets: [{
                        label: 'Count',
                        data: countArray,
                        stack: 0,
                        backgroundColor: '#36A2EB',
                        filterIdObject: assetDeviceIdObj,
                        filterWhenMultipleData: true,
                        redirectionFilter: 'device_ids'
                    }]
                }
                setChartData(newChartData)
            } else {
                let stationTypewiseCount = {}
                let labels = []
                let dataSets = []
                let availTypes = []
                let typeCountObj = {}
                let StationCustomerIdObj = {}
                response.forEach((row, i) => {
                    if (!StationCustomerIdObj[row.stn_code]) {
                        StationCustomerIdObj[row.stn_code] = { customerId: row.customer_id, customerName: row.stn_code }
                    }

                })
                // console.log('StationCustomerIdObj',StationCustomerIdObj)
                response.forEach((row, i) => {
                    if (!availTypes.includes(row[dataTypeKey[dependsOn]])) {
                        availTypes.push(row[dataTypeKey[dependsOn]])
                    }
                    if (!typeCountObj[row[dataTypeKey[dependsOn]]]) {
                        typeCountObj[row[dataTypeKey[dependsOn]]] = []
                    }
                    if (stationTypewiseCount[row.stn_code]) {

                        if (stationTypewiseCount[row.stn_code][row[dataTypeKey[dependsOn]]]) {
                            // StackedBarChart.js:245 Uncaught TypeError: stationTypewiseCount[row.stn_code][row[dataTypeKey[dependsOn]]].push is not a function
                            stationTypewiseCount[row.stn_code][row[dataTypeKey[dependsOn]]].push(row.cnt)
                        } else {
                            stationTypewiseCount[row.stn_code][row[dataTypeKey[dependsOn]]] = row.cnt
                        }
                    } else {
                        stationTypewiseCount[row.stn_code] = { [row[dataTypeKey[dependsOn]]]: row.cnt }
                    }
                })
                // console.log('stationTypewiseCount',stationTypewiseCount)
                Object.entries(stationTypewiseCount).forEach(([key, values]) => {
                    labels.push(key)
                    // console.log(values)
                    availTypes.forEach((type) => {
                        typeCountObj[type].push(values[type] ? values[type] : null)
                    })
                })
                // console.log('labels', labels)
                // console.log('typeCountObj', typeCountObj)

                Object.entries(typeCountObj).forEach(([type, countsArray], i) => {
                    dataSets.push({
                        label: type,
                        data: countsArray,
                        stack: i,
                        backgroundColor: Colors[i],
                        filterWhenMultipleData: true,
                        filterIdObject: StationCustomerIdObj,
                        redirectionFilter: 'customer_ids'


                    })
                })
                let newChartData = {
                    labels: labels,
                    datasets: dataSets
                }
                // console.log('newChartData', newChartData)
                if (newChartData.labels.length > 1) {
                    setCardLabel(initialCardLabel)
                    setChartData(newChartData)
                } else {
                    // console.log('aa',dependsOn, response)
                    let newCardLabel = `Total Event Count Of Each Type Of ${response[0].stn_code} Station`
                    setCardLabel(newCardLabel)
                    // console.log(response[0].stn_code)
                    let labelsSignleStation = []
                    let colorsSignleStation = []
                    let dataSignleStation = []
                    let filterKey = filterKeyObj[dependsOn]
                    let typeIdObj = []
                    if (dependsOn === 'gearType') {
                        response.forEach((row) => {
                            if (!typeIdObj[row.device_type_label]) {
                                typeIdObj[row.device_type_label] = { id: row.device_type_id, value: row.device_type_label }
                            }
                        })
                    }
                    // let dataSetSignleStation = []
                    //for single station data
                    response.forEach((row, i) => {
                        labelsSignleStation.push(row[dataTypeKey[dependsOn]])
                        colorsSignleStation.push(Colors[i])
                        dataSignleStation.push(row.cnt)

                    })
                    const newChartDataSignleStation = {
                        labels: labelsSignleStation,
                        datasets: [{
                            label: '',
                            data: dataSignleStation,
                            backgroundColor: colorsSignleStation,
                            filterKey: filterKey,
                            filterObj: typeIdObj
                        }],

                    };
                    setChartData(newChartDataSignleStation)
                    //   console.log(labelsSignleStation)
                }
            }

        }
    }, [response])
    const handleFullSCreen = () => {
        setFullScreen(!fullScreen)
    }
    return (
        <div className={`Chart-main-container ${fullScreen ? 'fullScreen' : ''}`}>
            {(response && response?.length > 0) ? (<>     <div className='header'>
                <div className='heading'>{cardLabel} </div>
                <FullscreenIcon sx={{ color: '#5398DD', cursor: 'pointer' }} onClick={() => handleFullSCreen()} />
            </div>
                <div className='chartContainer'>
                    <Chart
                        className="type-status-count-chart cursor-pointer"
                        type="bar"
                        data={chartData}
                        options={InitialBarOption}
                    />
                </div></>) : (<NoData msg={`Total Failure Count of Each Station by Type`} />)}

        </div>
    )
}

export default StackedBarChart