import React from "react";
import { useDrag } from "react-dnd";
import { FORM_COMPONENTS_OPTIONS, itemTypes, REACTIVE_FORM_COMPONENTS_OPTIONS } from "../Constats";
import "./FieldTypeComponents.scss";

const CreateFormComponent = ({ label, id, type, handleAdd, icon, bgColor }) => {
  const [{ isDragging }, drag] = useDrag({
    type: itemTypes.ADD_COMPONENT,
    item: {
      id: id,
      label: label,
      type: type,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  return (
    <div ref={drag} className={`row ${isDragging ? "dragging" : ""}`}>
      <div style={{ backgroundColor: bgColor }} className="field icon">
        {icon}
      </div>
      <div className="field text">{label}</div>
    </div>
  );
};

function FieldTypeComponents() {
  return (
    <div className="component-container">
      <div  className="form-component-container">
        <div className="typeHeading">Basic Form Elements</div>
        {FORM_COMPONENTS_OPTIONS.map((props, index) => {
          return (
            <CreateFormComponent key={"form-component" + index} {...props} />
          );
        })}
      </div>
      <div className="form-component-container">
        <div className="typeHeading">Advanced Form Elements</div>
        {REACTIVE_FORM_COMPONENTS_OPTIONS.map((props, index) => {
          return (
            <CreateFormComponent key={"form-component" + index} {...props} />
          );
        })}
      </div>
    </div>
  );
}

export default FieldTypeComponents;
