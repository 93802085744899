import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./Components/Login/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./Components/Header/Header";
import Sidebar from "./Components/Sidebar/Sidebar";
import ActiveAlarms from "./Components/Alarms/ActiveAlarms";
import { Box, CssBaseline } from "@mui/material";
import Assets from "./Components/Devices/Devices";
import Telemetry from "./Components/Telemetry/Telemetry";
import AlarmsMigrated from "./features/Alarms/Alarms";
// import YardView from "./Components/YardView/YardView";
import HistoryTable from "./Components/Telemetry/HistoryTable";
import User from "./Components/User/User";
import AssetsProvider from "./Components/Devices/AssetsProvider";
import YardLayout from "./Components/YardView/YardLayout/YardLayout";
import FormCreator from "./Components/FormCreator/FormCreator";
import Forms from "./Components/FormCreator/Forms/Forms";
import AssetScheduleAssignment from "./Components/Schedule Aseet assignment/AssetScheduleAssignment";
import AssetAssignmentForm from "./Components/Schedule Aseet assignment/ASSET ASSIGNMENT FORM/AssetAssignmentForm";
import FormAssignment from "./Components/Form Assignment/FormAssignment";
import AssignmentForm from "./Components/Form Assignment/AssignmentForm/AssignmentForm";
import AssetTransfer from "./features/AssetTransfer/AssetTransfer";
import { useGetAlarmTypesQuery, useGetDeviceTypesQuery } from "./features/SearchFilters/searchFiltersSlice";
import AlarmRulesView from "./features/AlarmRules/Components/ViewAllRules/ViewAllRules";
import AlarmRulesForm from "./features/AlarmRules/Components/CreateOrEditRule/CreateOrEditRule";
import MigratedDashBoard from "./Components/MIGRATED DASHBOARD/MigratedDashBoard";
import Reports from "./Components/REPORTS/Reports";
import MaintenanceResponseTable from "./Components/Devices/Maintenance/Maintenance Records/MaintenanceResponseTable";
import AssetRegister from "./Components/Devices/AssetRegister/NewRegister";
import AssetMaintenanceHistory from "./Components/Devices/Maintenance/Maintenance Records/Asset Maintenance History/AssetMaintenanceHistory";
import AssetMaintenanceResponses from "./Components/Devices/Maintenance/Maintenance Records/Maintenance Assets Response/AssetMaintenanceResponses";
import AssetUtilization from "./Components/Asset Utilisation/AssetUtilization";
import CustomerGroup from "./features/CustomerGroup/CustomerGroup";
import SFR from "./Components/SFR/SFR";
import SfrForm from "./Components/SFR/SFR FORM/SfrForm";
import SetAttribute from "./Components/YardView/YardLayout/Constant/SetAttribute";
import { useSelector } from "react-redux";
import NewYardLayout from "./Components/NEW YARD LAYOUT/NewYardLayout";
import { isLoggedInSelector } from "./features/authentication/auth-slice";
import AlarmNotificationInfo from "./Components/alarm-notification-info/AlarmNotificationInfo";
import { useShowNotifications } from "./hooks/alarms/show-notifications";
import AssetGis from "./Assets Gis/AssetGis";
import CableMonitoring from "./Components/cable-monitoring/CableMonitoring";

import "antd/dist/antd.css";
import CableMonitoringHistory from "./Components/cable-monitoring/CableMonitoringHistory";
import HealthMonitoring from "./Components/health-monitoring/HealthMonitoring";
import Trend from "./Components/Trend/Trend";
import TrendHistoryTable from "./Components/Trend/TrendHistoryTable";


const theme = createTheme();

declare global {
  interface Window {
    refreshToken: string | null;
    frontendAccess: any;
    userId: string;
    runtimeUrlConfig: any;
  }
}

var refreshToken = localStorage.getItem("refresh_token");
window.refreshToken = refreshToken;
let frontendAccess = localStorage.getItem("frontendAccess");
if (frontendAccess) frontendAccess = JSON.parse(frontendAccess);
window.frontendAccess = frontendAccess ?? {};

window.userId = localStorage.getItem("userId") ?? "";

type AccessRouteProps = {
  access: string;
  children: JSX.Element;
};

const AccessRoute = ({ access, children }: AccessRouteProps) => {
  const _frontendAccess = window.frontendAccess;
  const _read = _frontendAccess[access];
  const allowAccess = _read?.readSelf !== false || _read?.readAll !== false;
  if (!allowAccess) return <Navigate to="/" />;
  return children;
};

const App = () => {
  const loggedIn = useSelector(isLoggedInSelector);

  useGetDeviceTypesQuery(null);
  useGetAlarmTypesQuery(null);

  useShowNotifications();

  return (
    <ActiveAlarms isUserLoggedIn={loggedIn}>
      <ThemeProvider theme={theme}>
        <Box className="flex fullViewHeight fullWidth">
          <CssBaseline />
          {/* {isLoading ? <Loader /> : null} */}
          {loggedIn ? (
            <>
              <div className="flex fullWidth fullHeight">
                <Sidebar />
                <div className="fullWidth fullHeight">
                  <Header />
                  <div className="component-page fullWidth">
                    <Routes>
                      <Route path="/" element={<MigratedDashBoard />} />
                      <Route path="/home" element={<MigratedDashBoard />} />
                      <Route path="/dashboard" element={<MigratedDashBoard />} />
                      <Route
                        path="/alarms"
                        element={
                          <AccessRoute access="ALARM">
                            <AlarmsMigrated />
                          </AccessRoute>
                        }
                      />
                      <Route path="/" element={<AssetsProvider />}>
                        <Route
                          path="assets"
                          element={
                            <AccessRoute access="ASSET">
                              <Assets />
                            </AccessRoute>
                          }
                        />
                        <Route
                          path="/asset-register"
                          element={
                            <AccessRoute access="ASSET">
                              <AssetRegister />
                            </AccessRoute>
                          }
                        />
                      </Route>
                      <Route
                        path="/maintenance-records"
                        element={
                          <AccessRoute access="ASSET">
                            <MaintenanceResponseTable />
                          </AccessRoute>
                        }
                      />
                      <Route
                        path="/maintenanace-history"
                        element={
                          <AccessRoute access="ASSET">
                            <AssetMaintenanceHistory />
                          </AccessRoute>
                        }
                      />
                      <Route
                        path="/maintenanace-responses"
                        element={
                          <AccessRoute access="ASSET">
                            <AssetMaintenanceResponses />
                          </AccessRoute>
                        }
                      />
                      <Route path="/telemetry" element={<Telemetry />} />
                      <Route path="/trend" element={<Trend />} />
                      {/* <Route path="/yard-view" element={<YardLayout />} /> */}
                      <Route path="telemetry/cable-history/:id" element={<CableMonitoringHistory />} />
                      <Route path="/telemetry-history/:id" element={<HistoryTable />} />
                      <Route path="/trend-history/:id" element={<TrendHistoryTable />} />

                      <Route path="/yard-view" element={<NewYardLayout />} />
                      <Route
                        path="/user"
                        element={
                          <AccessRoute access="USER">
                            <User />
                          </AccessRoute>
                        }
                      />

                      <Route path="/form-creator" element={<FormCreator />} />
                      <Route
                        path="/forms/:id"
                        element={
                          <AccessRoute access="FORM">
                            <Forms />
                          </AccessRoute>
                        }
                      />
                      <Route
                        path="/form-assignment"
                        element={
                          <AccessRoute access="FORM_ASSIGNMENT">
                            <FormAssignment />
                          </AccessRoute>
                        }
                      />
                      <Route
                        path="/station-group"
                        element={
                          <AccessRoute access="FORM_ASSIGNMENT">
                            <CustomerGroup />
                          </AccessRoute>
                        }
                      />

                      <Route path="/assignment-form" element={<AssignmentForm />} />
                      <Route
                        path="/asset-assignment"
                        element={
                          <AccessRoute access="ASSET_ASSIGNMENT">
                            <AssetScheduleAssignment />
                          </AccessRoute>
                        }
                      />
                      <Route
                        path="/asset-assignment-form"
                        element={
                          <AccessRoute access="ASSET_ASSIGNMENT">
                            <AssetAssignmentForm />
                          </AccessRoute>
                        }
                      />
                      <Route path="/asset-transfer" element={<AssetTransfer />}></Route>
                      <Route path="/reports" element={<Reports />} />
                      <Route path="/reports/:id" element={<Reports />} />
                      <Route path="/utilization/:id" element={<AssetUtilization />} />
                      <Route path="/alarm-rules" element={<AlarmRulesView />} />
                      <Route path="/alarm-rules/create" element={<AlarmRulesForm />} />
                      <Route path="/alarm-rules/edit" element={<AlarmRulesForm />} />
                      <Route path="/station-group" element={<CustomerGroup />} />
                      <Route path="/failure-report" element={<SFR />} />
                      <Route path="/SFR-form" element={<SfrForm />} />
                      <Route path="/setAtt" element={<SetAttribute />} />
                      <Route path="/gis" element={<AssetGis />} />
                      <Route path="/health-monitoring" element={<HealthMonitoring />} />
                      {/* <Route path="cable-monitoring" element={<CableMonitoring />}></Route> */}
                    </Routes>
                  </div>
                  <AlarmNotificationInfo />
                </div>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          )}
        </Box>
      </ThemeProvider>
    </ActiveAlarms>
  );
};

export default App;
