export function getPermittedCustomersList() {
  const customerCache = localStorage.getItem("customer_cache");
  if (customerCache) return JSON.parse(customerCache);

  const result = [];
  const resultMap = {};
  const customerIdArray = [];

  let permissions = localStorage.getItem("permissions");
  if (permissions) permissions = JSON.parse(permissions);
  const jsonData = permissions;

  const stack = [jsonData];
  while (stack.length > 0) {
    const obj = stack.pop();

    if (obj instanceof Array) {
      obj.forEach((item) => {
        stack.push(item);
      });
    } else if (typeof obj === "object") {
      Object.keys(obj).forEach((key) => {
        if (key === "customerId" && obj[key]) {
          const customer = {
            customerId: obj[key],
            customerName: obj["customerName"],
          };
          result.push(customer);
          resultMap[obj[key]] = obj["customerName"];
          customerIdArray.push(obj[key]);
        } else {
          stack.push(obj[key]);
        }
      });
    }
  }

  const customerInfo = {
    customerArray: result,
    customerMap: resultMap,
    customerIdArray: customerIdArray,
  };
  localStorage.setItem("customer_cache", JSON.stringify(customerInfo));
  return customerInfo;
}
