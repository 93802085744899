import moment from "moment";
import { Table, Tooltip } from "antd";
import { Button, Input } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import React, { useContext, useEffect, useMemo, useState } from "react";

import "./cable-monitoring.scss";
import { ColumnsType } from "antd/lib/table";
import { generatePDF } from "./cable-monitoring-export";
import { GetConnectedDeviceResponse, cable_monitoring_apis } from "./cableMonitoringSlice";
import { trimAssetName } from "../../Common/EndPoints";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";

export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";
export type SingleDeviceLiveData = Record<string, [{ ts: string; value: string }]>;

export type ParsedLiveData = Record<string, SingleDeviceLiveData>;

export function parseCableMonitoringData(
  connectedDevices: GetConnectedDeviceResponse,
  telemetryData: ParsedLiveData,
  previousData?: CableMonitoringData[],
) {
  const gear_wise_data = telemetryData;
  const response_map: Record<string, CableMonitoringData> = {};

  for (const device of connectedDevices) {
    const parent_id = device.parent.id;
    const parent_name = device.parent.name;
    const trimmed_parent_name = trimAssetName(device.parent.name);
    response_map[parent_id] ??= {
      cable: trimmed_parent_name,
      gear: [],
      outdoor_reference: [],
      indoor_key: "",
      indoor_receive: [],
      outdoor_key: "",
      outdoor_realtime: [],
      location: [],
      eld_status: "",
      indoor_source: "",
      health: [],
      impacted_gear: [],
      time: "",
      last_packets_received: [],
      last_indoor_received_ts: [],
      last_outdoor_realtime_ts: [],
      last_indoor_source_ts: "",
      ev_minus: [],
      ev_plus: [],
      difference: [],
      parent_id,
    };
    const gear_name = device.child.name;

    const gear_data = gear_wise_data[gear_name];
    const parent_data = gear_wise_data[parent_name];

    const parent_ts = parent_data?.["WETCH"]?.[0].ts;

    let previous_cable_data: CableMonitoringData | null = null;
    let previous_gear_index: number | null = null;
    if (previousData && previousData.length > 0) {
      const previous = previousData.find((parent) => parent.cable === parent_name);
      if (previous) previous_cable_data = previous;
      const gear_index = previous?.gear.findIndex((_name) => gear_name === _name);
      if (gear_index && gear_index !== -1) previous_gear_index = gear_index;
    }

    // if (gear_name === "MIU-299AT" || gear_name === "MIU-UMT3T") {
    //   console.log("parent data", gear_data);
    // }

    const value_24DC = parent_data?.["24DC"]?.[0].value;
    const value_110DC = parent_data?.["110DC"]?.[0].value;
    const value_110AC = parent_data?.["110AC"]?.[0].value;

    const formatted_24DC =
      value_24DC && !value_24DC.includes("*") && Number(value_24DC) < 10000
        ? Number(value_24DC).toFixed(2) + " DC"
        : null;
    const formatted_110DC =
      value_110DC && !value_110DC.includes("*") && Number(value_110DC) < 10000
        ? Number(value_110DC).toFixed(2) + " DC"
        : null;
    const formatted_110AC =
      value_110AC && !value_110AC.includes("*") && Number(value_110AC) < 10000
        ? Number(value_110AC).toFixed(2) + " AC"
        : null;

    // const indoor_receive_key = device.Meta?.indoor_key;
    // const outdoor_realtime_key = device.Meta?.outdoor_key;
    // const indoor_receive = indoor_receive_key ? gear_data?.[indoor_receive_key]?.[0]?.value || "-" : "-";
    // const outdoor_realtime = outdoor_realtime_key ? gear_data?.[outdoor_realtime_key]?.[0]?.value || "-" : "-";

    let indoor_receive = "-";
    let outdoor_realtime = "-";
    let default_indoor_source = "-";
    let default_wetch = "-";
    let ev_plus = "-";
    let ev_minus = "-";
    let difference = "-";

    let outdoor_realtime_ts = "-";
    let indoor_received_ts = "-";
    let default_indoor_source_ts = "-";

    if (previous_cable_data && previous_gear_index) {
      indoor_receive = previous_cable_data.indoor_receive[previous_gear_index] ?? "-";
      outdoor_realtime = previous_cable_data.outdoor_realtime[previous_gear_index] ?? "-";

      ev_plus = previous_cable_data.ev_plus[previous_gear_index] ?? "-";
      ev_minus = previous_cable_data.ev_minus[previous_gear_index] ?? "-";
      difference = previous_cable_data.difference[previous_gear_index] ?? "-";

      outdoor_realtime_ts = previous_cable_data.last_outdoor_realtime_ts[previous_gear_index] ?? "-";
      indoor_received_ts = previous_cable_data.last_indoor_received_ts[previous_gear_index] ?? "-";

      default_indoor_source = previous_cable_data.indoor_source ?? "-";
      default_indoor_source_ts = previous_cable_data.last_indoor_source_ts ?? "-";

      default_wetch = previous_cable_data.eld_status ?? "-";
    }

    const indoor_receive_keys = device.meta?.indoor_receive;
    const outdoor_realtime_keys = device.meta?.outdoor_realtime;

    if (indoor_receive_keys) {
      for (const key_map of indoor_receive_keys) {
        const { key, max, min } = key_map;

        const gear_value = gear_data?.[key]?.[0]?.value;
        if (!gear_value) continue;

        const numerical_value = Number(gear_value) / 1000;

        if (numerical_value >= Number(min) && numerical_value <= Number(max)) {
          indoor_receive = Number(numerical_value).toFixed(2);
          indoor_received_ts = gear_data?.[key]?.[0].ts;
          break;
        }
      }
    }

    if (outdoor_realtime_keys) {
      for (const key_map of outdoor_realtime_keys) {
        const { key, max, min } = key_map;

        const gear_value = gear_data?.[key]?.[0]?.value;
        if (!gear_value) continue;

        const numerical_value = Number(gear_value) / 1000;
        if (numerical_value >= Number(min) && numerical_value <= Number(max)) {
          outdoor_realtime = Number(numerical_value).toFixed(2);
          outdoor_realtime_ts = gear_data?.[key]?.[0].ts;
          break;
        }
      }
    }

    const outdoor_reference = device.meta?.outdoor_ref?.toString() || "-";
    const indoor_source = formatted_24DC || formatted_110DC || formatted_110AC || default_indoor_source;
    let _indoor_source_ts = "";
    if (indoor_source === formatted_24DC) {
      _indoor_source_ts = parent_data?.["24DC"]?.[0]?.ts;
    } else if (indoor_source === formatted_110DC) {
      _indoor_source_ts = parent_data?.["110DC"]?.[0]?.ts;
    } else if (indoor_source === formatted_110AC) {
      _indoor_source_ts = parent_data?.["110AC"]?.[0]?.ts;
    } else {
      _indoor_source_ts = default_indoor_source_ts;
    }

    const indoor_source_ts = _indoor_source_ts;

    const wetch = parent_data?.DRYCH?.[0]?.value;
    const wetch_status = wetch ? (wetch == "1" ? "HEALTHY" : "FAULT") : default_wetch;
    // const wetch_status = wetch ? (Number(wetch) < 18000 ? "FAULT" : "HEALTHY") : default_wetch;
    // const location = gear_data?.LOC?.[0]?.value || "-"
    const location = device.meta?.loc || "-";

    const trimmed_gear_name = trimAssetName(gear_name);

    const impacted_gear =
      outdoor_realtime && outdoor_reference && outdoor_reference !== "-" && outdoor_realtime !== "-"
        ? Number(outdoor_realtime) < Number(outdoor_reference)
          ? trimmed_gear_name
          : "-"
        : "-";

    const gear_event_time = gear_data?.EVENTTYPE?.[0]?.ts ?? 0;
    let impacted_gear_for_110VDC = null;

    if (wetch_status === "FAULT" && parent_name === "POINT(110VDC)") {
      const numerical_gear_time = Number(gear_event_time);
      const numerical_parent_time = Number(parent_ts);
      const parent_gear_time_difference = Math.abs(numerical_parent_time - numerical_gear_time);
      if (parent_gear_time_difference < 15000) {
        impacted_gear_for_110VDC = trimmed_gear_name;
      }
    }

    const currentEVPlusValue = gear_data?.EVN?.[0]?.value;
    const currentEVMinusValue = gear_data?.EVB?.[0]?.value;
    const currentEVDifference = gear_data?.DIFF?.[0]?.value;

    if (
      currentEVPlusValue !== null &&
      currentEVPlusValue !== undefined &&
      Number(currentEVPlusValue) !== 16777215 &&
      !currentEVPlusValue.includes("*")
    ) {
      ev_plus = currentEVPlusValue;
    }

    if (
      currentEVMinusValue !== null &&
      currentEVMinusValue !== undefined &&
      Number(currentEVMinusValue) !== 16777215 &&
      !currentEVMinusValue.includes("*")
    ) {
      ev_minus = currentEVMinusValue;
    }

    if (currentEVDifference !== null && currentEVDifference !== undefined && !currentEVDifference.includes("*")) {
      difference = currentEVDifference;
    }

    const row = response_map[parent_id];
    row.gear.push(trimmed_gear_name);
    row.outdoor_reference.push(outdoor_reference);
    row.location.push(location);
    row.eld_status = wetch_status;
    row.indoor_receive.push(indoor_receive);
    row.outdoor_realtime.push(outdoor_realtime);
    row.indoor_source = indoor_source;
    row.health.push(gear_data?.HEALTH?.[0]?.value || "-");
    row.impacted_gear.push(impacted_gear_for_110VDC ?? impacted_gear);
    row.time = parent_ts ?? "-";
    row.last_packets_received.push(gear_data?.TIME?.[0]?.ts ?? "-");
    row.last_outdoor_realtime_ts.push(outdoor_realtime_ts);
    row.last_indoor_received_ts.push(indoor_received_ts);
    row.ev_plus.push(ev_plus ?? "-");
    row.ev_minus.push(ev_minus ?? "-");
    row.difference.push(difference ?? "-");
    row.last_indoor_source_ts = indoor_source_ts;
    row.parent_id = parent_id;
  }

  const final_response: CableMonitoringData[] = [];
  for (const device_id in response_map) {
    const device = response_map[device_id];
    final_response.push(device);
  }

  return final_response;
}

function getOutdoorRealtimeStyle(value: string, index: number, row_data?: CableMonitoringData): React.CSSProperties {
  const outdoor_reference = row_data?.outdoor_reference?.[index];
  if (outdoor_reference && outdoor_reference !== "-" && value && value !== "-") {
    if (Number(value) < Number(outdoor_reference)) {
      return { color: "red" };
    }
  }
  return {};
}

function getImpactedGearStyle(value: string): React.CSSProperties {
  if (value !== "-") {
    return { color: "red" };
  }
  return {};
}

function getEldStatus(value: string): React.CSSProperties {
  if (value === "HEALTHY") {
    return { color: "green", fontWeight: "bold" };
  }
  if (value === "FAULT") {
    return { color: "red", fontWeight: "bold" };
  }
  return {};
}

interface SpanMultipleRowsProps {
  data: string[];
  style?: (value: string, index: number, row_data?: CableMonitoringData) => React.CSSProperties;
  row_data?: CableMonitoringData;
  last_ts_key?: keyof CableMonitoringData;
}
const SpanMultipleRows: React.FC<SpanMultipleRowsProps> = ({ data, style, row_data, last_ts_key }) => {
  return (
    <div className="flex flex-col">
      {data.map((value, index) => {
        const className =
          index !== data.length - 1
            ? "cable-monitoring__table__sub-row__value"
            : "cable-monitoring__table__sub-row__value--borderless";
        const _time = last_ts_key ? row_data?.[last_ts_key]?.[index] : row_data?.last_packets_received?.[index];
        const time = _time && _time !== "-" ? Number(_time) : null;

        if (!value || value === "-" || !time) {
          return (
            <div className={className} key={value + index} style={style?.(value, index, row_data)}>
              {value}
            </div>
          );
        }

        return (
          <Tooltip title={moment(time, "x").format(DATE_TIME_FORMAT)}>
            <div className={className} key={value + index} style={style?.(value, index, row_data)}>
              {value}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

const ActionColumn = ({ value, row_data }: { value: string; row_data: CableMonitoringData }) => {
  const navigate = useNavigate();
  function handleCableClick() {
    navigate("cable-history/" + row_data.parent_id);
  }
  return (
    <div className="cursor-pointer" onClick={handleCableClick}>
      <Tooltip title="History" placement="right">
        <HistoryIcon />
      </Tooltip>
    </div>
  );
};

export const CABLE_MONITORING_COLUMS: ColumnsType<any> = [
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
    defaultSortOrder: "ascend",
    width: 30,
    render: (value: string, row_data) => <ActionColumn value={value} row_data={row_data} />,
  },
  {
    title: "Cable Function",
    dataIndex: "cable",
    key: "cable",
    align: "center",
    defaultSortOrder: "ascend",
    width: 100,
  },
  {
    title: "ELD Status",
    dataIndex: "eld_status",
    align: "center",
    width: 100,
    render: (value: string, row_data) => {
      const time = Number(row_data.time);
      if (!time) return <div style={getEldStatus(value)}>{value}</div>;

      return (
        <Tooltip title={moment(time, "x").format(DATE_TIME_FORMAT)}>
          <div style={getEldStatus(value)}>{value}</div>
        </Tooltip>
      );
    },
  },
  {
    title: "Location",
    dataIndex: "location",
    width: 100,
    // align: "center",
    render: (data: string[]) => <SpanMultipleRows data={data} />,
  },
  {
    title: "Gear",
    // align: "center",
    dataIndex: "gear",
    width: "8rem",
    render: (data: string[]) => <SpanMultipleRows data={data} />,
  },
  {
    title: "Health",
    dataIndex: "health",
    align: "center",
    width: 100,
    render: (data: string[]) => <SpanMultipleRows data={data} />,
  },
  {
    title: "Impacted Gear",
    dataIndex: "impacted_gear",
    width: 100,
    align: "center",
    render: (data: string[], row_data) => (
      <SpanMultipleRows
        data={data}
        style={getImpactedGearStyle}
        row_data={row_data}
        last_ts_key="last_outdoor_realtime_ts"
      />
    ),
  },
  {
    title: "Indoor Source",
    align: "center",
    dataIndex: "indoor_source",
    width: 100,
    render: (value, row_data) => {
      const time = Number(row_data.last_indoor_source_ts);

      if (!time) return <div>{value}</div>;
      const formatted_time = moment(time, "x").format(DATE_TIME_FORMAT);

      return (
        <Tooltip title={formatted_time}>
          <div>{value}</div>
        </Tooltip>
      );
    },
  },
  {
    title: "Outdoor Realtime",
    dataIndex: "outdoor_realtime",
    align: "center",
    width: 100,
    render: (data: string[], row_data) => (
      <SpanMultipleRows
        data={data}
        style={getOutdoorRealtimeStyle}
        row_data={row_data}
        last_ts_key="last_outdoor_realtime_ts"
      />
    ),
  },
  {
    title: "Outdoor Reference",
    dataIndex: "outdoor_reference",
    align: "center",
    width: 100,
    render: (data: string[], row_data) => <SpanMultipleRows data={data} row_data={row_data} />,
  },
  {
    title: "Indoor Received",
    dataIndex: "indoor_receive",
    align: "center",
    width: 100,
    render: (data: string[], row_data) => (
      <SpanMultipleRows data={data} row_data={row_data} last_ts_key="last_indoor_received_ts" />
    ),
  },
];

export function parseLiveData(data: LiveData[]) {
  const gear_wise_data: ParsedLiveData = {};
  for (const device of data) {
    const dataList = device.dataLists;
    if (!dataList) {
      continue;
    }
    let device_data: SingleDeviceLiveData = { TIME: [{ ts: "0", value: "0" }] };
    for (const _data of dataList) {
      const data_packet = _data.data;
      let _activity_time = data_packet?.["TIME"]?.[0]?.ts;
      const activity_time = Number(_activity_time) || 0;
      if (activity_time < Number(device_data.TIME[0].ts)) {
        device_data = { ...data_packet, ...device_data };
      } else {
        device_data = { ...device_data, ...data_packet };
      }
    }

    gear_wise_data[device.deviceName] = device_data;
  }

  return gear_wise_data;
}

export type CableMonitoringData = {
  cable: string;
  gear: string[];
  outdoor_reference: string[];
  indoor_key: string;
  outdoor_key: string;
  indoor_receive: string[];
  outdoor_realtime: string[];
  location: string[];
  eld_status: string;
  indoor_source: string;
  health: string[];
  impacted_gear: string[];
  time: string;
  last_packets_received: string[];
  last_outdoor_realtime_ts: string[];
  last_indoor_received_ts: string[];
  last_indoor_source_ts: string;
  ev_plus: string[];
  ev_minus: string[];
  difference: string[];
  parent_id: string;
};

function useGetCableMonitoringData(telemetryData: LiveData[]) {
  const [data, setData] = useState<CableMonitoringData[]>([]);
  const { customerIdList }: any = useContext(ActiveAlarmsData);

  const { data: connectedDevices = [], isFetching: loading } = cable_monitoring_apis.useGetConnectedDevicesQuery(
    {
      connection_types: "ELD",
      with_customer: true,
      with_device_type: true,
      customer_ids: customerIdList.toString(),
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (loading) return;
    if (connectedDevices.length === 0) return;
    const parsed_data = parseLiveData(telemetryData);
    const _data = parseCableMonitoringData(connectedDevices, parsed_data, []);
    setData(_data);
  }, [loading, connectedDevices, telemetryData]);

  const response = useMemo(() => ({ data, loading }), [data, loading]);
  return response;
}

type DataPacket = {
  data: Record<string, [{ ts: string; value: string }]>;
  dataId: string;
  source: string;
  subgear: string;
};

export type LiveData = {
  dataLists?: DataPacket[];
  deviceName: string;
  isTestMode: boolean;
  testModeTime?: null;
};

function useGetFilteredCableMonitoringData(data: CableMonitoringData[], searchValue: string) {
  if (!searchValue) return data;

  const filter_data = data.filter((parent_device) => {
    for (const key in parent_device) {
      const packet = parent_device[key as keyof CableMonitoringData];
      if (typeof packet === "string") {
        if (packet.toLowerCase().includes(searchValue.toLowerCase())) return true;
      } else {
        for (const gear_value of packet) {
          if (gear_value.toLowerCase().includes(searchValue.toLowerCase())) return true;
        }
      }
    }

    return false;
  });

  return filter_data;
}

const CableMonitoring: React.FC<any> = ({ state }) => {
  const telemetry_data: LiveData[] = state.data;

  const { data, loading: fetching_connections } = useGetCableMonitoringData(telemetry_data);

  const [searchValue, setSearchValue] = useState("");

  const fetching_data = state.loader;

  const filtered_data = useGetFilteredCableMonitoringData(data, searchValue);

  function handleExport() {
    generatePDF(data);
  }

  function handleSearch(text: string) {
    setSearchValue(text);
  }

  return (
    <div
      className="absolute w-full"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #E0E0E0",
        borderRadius: "5px",
      }}
    >
      <div className="flex justify-between items-center" style={{ padding: "0 0.5rem" }}>
        <Button onClick={handleExport}>Export</Button>
        <Input
          value={searchValue}
          onChange={(event) => handleSearch(event.target.value)}
          placeholder="Search..."
          type="search"
        />
      </div>
      <Table
        sticky
        className="cable-monitoring__table w-full"
        style={{ flex: 1 }}
        dataSource={filtered_data}
        columns={CABLE_MONITORING_COLUMS}
        loading={fetching_connections || fetching_data}
        size="small"
        // scroll={{ x: 100, y: 600 }}
        scroll={{ x: 100, y: "calc(80vh - 11rem)" }}
      />
    </div>
  );
};

export default CableMonitoring;
