import { ALARM_CATEGORY_COLOR } from "../Common/Constants";

export const getUniqueBy = (arr, key) => {
  const keys = new Set();
  return arr.filter((el) => !keys.has(el[key]) && keys.add(el[key]));
};

export const sortData = (a, b) => {
  if (a.last_nom > b.last_nom) return 1;
  if (a.last_nom < b.last_nom) return -1;
  return 0;
};

export function getAlarmCategoryColor(type) {
  return ALARM_CATEGORY_COLOR[type] ?? "red";
}

export function getAlarmCategoryList() {
  return Object.keys(ALARM_CATEGORY_COLOR).map((key) => key);
}

export function downloadFile({ data, name }) {
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function extractMasterRoleIds(roleHierarchiesObj) {
  let masterRoleIds = [];

  if (roleHierarchiesObj.masterRoleId) {
    masterRoleIds.push(roleHierarchiesObj.masterRoleId);
  }

  if (roleHierarchiesObj.roleGroup) {
    roleHierarchiesObj.roleGroup.forEach((role) => {
      masterRoleIds = [...masterRoleIds, ...extractMasterRoleIds(role)];
    });
  }

  return masterRoleIds;
}



export function extractAllowedUserRoleList(roleHierarchiesObj) {
  let roleList = [];

  if (roleHierarchiesObj.masterRoleId) {
    roleList.push({
      id: roleHierarchiesObj.masterRoleId,
      value: roleHierarchiesObj.roleName,
    });
  }

  if (roleHierarchiesObj?.roleGroup?.length > 0) {
    roleHierarchiesObj.roleGroup.forEach((role) => {
      roleList = [...roleList, ...extractAllowedUserRoleList(role)];
    });
  }

  return roleList;
}


export function extractUserDataFromPermission(jsonObj) {
  const outermostKeys = [];
  const outerToInnerKeys = {};
  const innerToCustomerNames = {};
  const customerNameToId = {};

  for (const obj of jsonObj) {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        outermostKeys.push(key);
        const innerObjs = obj[key];
        const innerKeys = innerObjs.reduce((acc, innerObj) => {
          const keys = Object.keys(innerObj);
          for (const innerKey of keys) {
            if (innerKey !== "customerId" && innerKey !== "customerName") {
              if (!innerToCustomerNames[innerKey]) {
                innerToCustomerNames[innerKey] = [];
              }
              const childObjs = innerObj[innerKey];
              for (const childObj of childObjs) {
                if (childObj && childObj.customerName) {
                  innerToCustomerNames[innerKey].push(childObj.customerName);
                  if (!customerNameToId[childObj.customerName]) {
                    customerNameToId[childObj.customerName] = [];
                  }
                  customerNameToId[childObj.customerName].push(childObj.customerId);
                }
              }
            }
          }
          return acc.concat(keys);
        }, []);
        outerToInnerKeys[key] = innerKeys;
      }
    }
  }

  return {
    outermostKeys,
    outerToInnerKeys,
    innerToCustomerNames,
    customerNameToId,
  };
}


export function formatDate(ts) {
  const value = Number(ts);
  const date = new Date(value);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  const time = date.toLocaleTimeString();
  return formattedToday + " " + time;
}