import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "./DataCard.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getFormattedDateTime } from "../Telemetry/Constant";

const initialDialogState = {
  allDdata: [],
  addDialogLabel: "DETAILS",
  showDialog: false,
  selectedRow: null,
};

const DataCard = ({ data }) => {

  return (
    <>
      <Card className="data-card" elevation={0.5}>
        <CardContent className="data-card-content">
          <div className="data-card-content-text-secondary">
            {data.label.toUpperCase()}
          </div>
          <div className="data-card-content-text-secondary">
            {getFormattedDateTime(Number(data.lastActivityTime)) ?? '-'}
          </div>
          <div className="data-card-content-text-primary">
            {data.value === undefined ? "-" : (Number(data.value) / 1000).toFixed(2) + '(v)'}
          </div>

          <div className="data-card-content-text-secondary">
            <Link
              style={{ color: "blue" }}
              to={`/telemetry-history/${data.deviceId}_${data.deviceType}`}
            >
              History
            </Link>
          </div>
        </CardContent>
      </Card>

    </>
  );
};

export default DataCard;
