import moment from "moment";
import { RTU_HEALTH, RTU_HEALTH_STATUS } from "../../Common/EndPoints";
import { apiAlarms } from "../../features/api/apiAlarms";
import { DATE_TIME_FORMAT } from "../cable-monitoring/CableMonitoring";

export type GetPaginatedRTUHealthParams = {
  limit: number;
  offset: number;
  is_verified?: boolean;
  status?: "ONLINE" | "OFFLINE";
  token_like?: string;
  order_by?: string; // default is receive_ts
  customer_ids?: string;
};

type RTUHealthPacketData = {
  OC: string;
  OT: string;
  PC: string;
  RH: string;
  DIR: string;
  LOC: string;
  STN: string;
  VER: string;
  VNO: string;
  VNS: string;
  VRO: string;
  VRS: string;
  DATE: string;
  NAME: string;
  RSSI: string;
  TEMP: string;
  TIME: string;
  SUBGEAR: string;
  GEARTYPE: string;
  EVENTTIME: number[];
  EVENTTYPE: string;
  EVENTDATA1: number[];
};

type RTUHealthRawPacket = {
  token: string;
  receive_ts: number;
  data: RTUHealthPacketData;
  verified: null | string;
  status: string;
};

export type RTUHealthPacket = RTUHealthRawPacket & {
  id: string;
  formatted_date: string;
};

type GetPaginatedRTUHealthRawResponse = {
  totalRecords: number;
  offset: number;
  limit: number;
  items: RTUHealthRawPacket[];
};

type GetPaginatedRTUHealthResponse = GetPaginatedRTUHealthRawResponse & {
  items: RTUHealthPacket[];
};


export type RTUHealth = { col_name: string; cnt: number };
export type GetRtuHealthStatusResponse = RTUHealth[];

export const health_monitoring_api = apiAlarms.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedRTUHealth: builder.query<GetPaginatedRTUHealthResponse, GetPaginatedRTUHealthParams>({
      query: (params) => {
        return {
          url: RTU_HEALTH,
          params,
        };
      },
      transformResponse: (response: GetPaginatedRTUHealthRawResponse) => {
        const items = response.items;
        const _items = [];
        for (const item of items) {
          const new_item = {
            ...item,
            id: item.token,
            formatted_date: moment(item.receive_ts).format(DATE_TIME_FORMAT),
          };
          _items.push(new_item);
        }

        return { ...response, items: _items };
      },
      keepUnusedDataFor: 0,
    }),
    getRtuHealthStatus: builder.query<GetRtuHealthStatusResponse, GetPaginatedRTUHealthParams>({
      query: (params) => {
        return {
          url: RTU_HEALTH_STATUS,
          method: "GET",
          params,
        };
      },
      providesTags: [],
    }),
  }),
});
