import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APP_BASE_URL_ALARM } from "../../Common/EndPoints";
export const apiAlarms = createApi({
  // The cache reducer expects to be added at `state.alarmAPI` (already default - this is optional)
  reducerPath: "alarmAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `${APP_BASE_URL_ALARM}` }),
  tagTypes: ["paginatedAlarms"],
  // The "endpoints" represent operations and requests for this server
  endpoints: () => ({}),
});
