// import $ from "jquery";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import DataTable from "../DataGrid/DataGrid";
import HistoryGraph from "./HistoryGraph";
import {APP_BASE_URL, EXPORT_HISTORY, APP_BASE_URL_SOCKET, APP_BASE_URL_TREND, GET_TREND_FEATURE,  } from "../../Common/EndPoints";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import "./TrendHistoryTable.scss";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import moment from "moment";
import { toast } from "react-hot-toast";
import { getAssetSubTypes, getTelemetryHistory, trimDeviceName } from "./HistoryUtils";
import { GridActionsCellItem } from "@mui/x-data-grid";
import TimelineIcon from "@mui/icons-material/Timeline";
import PointChartPopup from "./PointOperationChart/PointChartPopup";
import { FIXED_COLUMNS, convertToHigherUnit, initialPointChartPopData } from "./Constant";
import { downloadFile } from "../../Utils/Utils";

// import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
const TABS_LIST = ["table", "graph"];
const DEFAULT_HISTORY_SUB_TABS_LIST = ["ALL"];
const DEFAULT_NUMBER_OF_EVENTS = 500;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_INDEX = 0; 

const isValidToPush = (subtype, SELECTED_SUBTAB_REF) => {
  let isValid = false;
  const selectedSubtab = SELECTED_SUBTAB_REF.current.selecetdTab;
  if (selectedSubtab === "ALL") {
    isValid = true;
  } else {
    if (selectedSubtab === SELECTED_SUBTAB_REF.current.mapping[subtype]) {
      isValid = true;
    }
  }
  return isValid
};

export default function TrendHistoryTable() {
  const SELECTED_SUBTAB_REF = useRef()
  const params = new URLSearchParams(document.location.search);
  const _startDateInURL = params.get("start_date");
  const _endDateInURL = params.get("end_date");
  const startDate = _startDateInURL ? moment(_startDateInURL) : null;
  const endDate = _endDateInURL ? moment(_endDateInURL) : null;
  const initialState = {
    rowsData: [],
    dataForFilter: [],
    gridColumns: [],
    startTs: startDate,
    endTs: endDate,
    startTsError: false,
    endTsError: false,
  };
  const [state, setState] = useImmer(initialState);
  //for date range picker
  let id = useLocation();
  const userId = localStorage.getItem("userId");
  const deviceId = id.pathname.split("/")[2].split("_")[0];
  const deviceType = id.pathname.split("/")[2].split("_")?.[1] ?? '';
  const _deviceName = id.pathname.split("/")[2].split("_")?.[2] ?? '';
  const _cellIndex = id.pathname.split("/")[2].split("_")?.[3] ?? undefined;
  const deviceName = decodeURIComponent(_deviceName);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [rowCount, setRowCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("table");
  const [subTabsList, setSubTabsList] = useState(DEFAULT_HISTORY_SUB_TABS_LIST);
  const [selectedSubTab, setSelectedSubTab] = useState(subTabsList[0]);
  const [loading, setLoading] = useState(false);
  const [liveData, setLiveData] = useState(null);
  const { customerIdList } = useContext(ActiveAlarmsData);
  const rowsData = useRef([]);
  const [graphState, setGraphState] = useImmer({ data: [], chart: "live" });
  const numberOfEvents = useRef(DEFAULT_NUMBER_OF_EVENTS);
  const [pointChartPop, setPointChartPop] = useImmer(initialPointChartPopData);

  //console.log("prams from url --> deviceId: ", deviceId , " startDate: ", startDate.valueOf() , " endDate: " , endDate.valueOf());

  useEffect(() => {
    const _typeIdInURL = params.get("device_type_id");
    const typeId = _typeIdInURL;    
    const subtypeKeymap = {}
    if (!typeId) return;
    getAssetSubTypes({ typeId }).then(
      (res) => {
        const _tabs = res.data?.data?.map((_sub_type) => {
          subtypeKeymap[_sub_type.mapedJsonKey] = _sub_type.subtype
          return _sub_type.subtype
        });
        SELECTED_SUBTAB_REF.current = {
          selecetdTab: DEFAULT_HISTORY_SUB_TABS_LIST[0],
          mapping: subtypeKeymap
        }
        setSubTabsList([...DEFAULT_HISTORY_SUB_TABS_LIST, ..._tabs]);
      },
      (err) => {
        //toast.error("Error fetching Sub Types");
      },
    );
  }, []);
  useEffect(() => {
    const controller = new AbortController();
    //telemetryWebSocketConnection();
    dynamicColumns();
    getHistoryByTs(selectedSubTab, null, controller.signal);
    return () => {
      controller.abort();
      window.wsTelemetry?.close();
    };
  }, [pageIndex, pageSize, selectedSubTab, selectedTab]);

  useEffect(() => {
    rowsData.current = state.rowsData ?? [];
  }, [state.rowsData]);

  const dynamicColumns = () => {
    const _typeIdInURL = params.get("device_type_id");
    //const url = `${APP_BASE_URL}${GET_UNIVERSAL_DEVICE_KEYS}`; // customerId of LKR
    const url = `${APP_BASE_URL_TREND}${GET_TREND_FEATURE}?device_type_ids=${_typeIdInURL}`;
    let apiParams = {
      customerIds: customerIdList.toString(),
      type: deviceType,
    };    
    axios.get(url).then((res) => {
      let obj = {};
      let columns = [];
      if ((res?.data ?? []).length > 0) {
        res.data.forEach((row) => {
          let key = row.key;
          let feature = row.feature; 
          console.log("deviceType --> ", deviceType);
          if(deviceType === 'Track%20Circuit') {
            key = key + "_"+row.state;
            feature = feature + " " + row.state;
          }
          obj[key] = { key: key, alias: feature, unit: row.unit, state: row.state, order: row.order };
        });
        Object.values(obj).forEach((column, i) => {
          let isColumnVisible = true;
          if(isColumnVisible) {
            columns.push({
              key: i,
              field: column.key,
              headerName: `${column.alias} (${column.unit})`,
              sortable: true,
              flex: 1,
              minWidth: 150,
              unit: column.unit,
              verticalAlign: "center",
              resizeable: true,
              order: column.order,
              cellClassName: (params) => {
                return '';
              },
              valueGetter: (param) => {
                let value = param.value;
                if (column.unit === '' || column.unit === null || column.unit === '-' || column.unit === '%') {
                  return value
                } else {
                  return convertToHigherUnit(value, column.unit);
                }
              },          
            });
          }
        });
      }
      console.log("res.data --> ", res.data, " columns-->", columns);
      if (res.data[0].order) {
        columns = columns.sort((a, b) => {
          return a.order - b.order;
        });
      }
      columns = [
        {
          field: "lastActivityTime",
          headerName: "Last Activity Time",
          sortable: true,
          align: "center",
          headerAlign: "center",
          flex: 1,
          minWidth: 200,
          valueFormatter: (params) => {
            let TS = params.value;
            if (TS && TS > 0) {
              const value = Number(TS);
              const date = new Date(value);
              const yyyy = date.getFullYear();
              let mm = date.getMonth() + 1; // Months start at 0!
              let dd = date.getDate();
              if (dd < 10) dd = "0" + dd;
              if (mm < 10) mm = "0" + mm;
              const formattedToday = dd + "/" + mm + "/" + yyyy;
              const time = date.toLocaleTimeString();
              return formattedToday + " " + time;
            } else {
              return "-";
            }
          },
        },
        {
          field: "NAME",
          headerName: "NAME",
          sortable: true,
          width: 140,
          align: "center",
          headerAlign: "center",
          minWidth: 100,
          flex: 0.5,
          valueGetter: (param) => {
            return param.row.NAME;
          },
        },
        {
          field: "LOC",
          headerName: "Location",
          sortable: true,
          hide: false,
          hideable: true,
          minWidth: 100,
          flex: 0.5,
        },
        ...columns,
      ];
      
      setState((draft) => {
        draft.data = [];
        draft.gridColumns = columns ?? [];
      });
    });
  };
  
  const getHistoryByTs = (subType, reset, signal) => {
    setLoading(true);

    let from_ts = null;
    let to_ts = null;
    let page = DEFAULT_PAGE_INDEX + 1;

    if (!reset) {
      from_ts = state.startTs ? new Date(state.startTs).getTime() : null;
      to_ts = state.endTs ? new Date(state.endTs).getTime() : null;
      page = pageIndex + 1;
    }
    const subtype = subType !== "ALL" ? subType : null;
    const params = {
      device_ids: deviceId,
      to_ts,
      from_ts,
      limit: 100,
      offset: 0,
    };

    console.log("getTelemetryHistory --> params : ", params);
    getTelemetryHistory(params, signal)
      .then(
        (res) => {
 
          console.log("getTelemetryHistory --> response : ", res?.data);
          const _data = res?.data?.items ?? [];
          const _totalCount = _data.length;
          
          const _rowData = [];
          _data.forEach((row, index) => {
            row.values = {};
            row.features.forEach(column => {
              let key = column.key;
              if(row.device_type_label === "Track Circuit") {
                key = key + "_" +column.state
              }
              row[key] = column.value;
              row.values[key] = column.value;  //for graph
            });
            row.lastActivityTime = new Date(row.last_updated_on).getTime();
            row.ts = row.lastActivityTime;
            row.id = index;
            let nameCode = trimDeviceName(row.device_name).name;
            let zoneDivCode = trimDeviceName(row.device_name).zoneDiv; 
            row["NAME"] = nameCode;
            row["ZONE_DIV"] = zoneDivCode;
            row["LOC"] = zoneDivCode; 
            _rowData.push(row);
          });
          console.log("getTelemetryHistory --> _rowData : ", _rowData);
          if (selectedTab === "graph") return {data: {items: _rowData}};
          return { _rowData, _totalCount };
        },
        (err) => {
          if (err.response) {
            toast.error(err?.response?.data?.error?.message ?? "Something Went Wrong! Try again Later");
          }
        },
      )
      .then((res) => {
        setLoading(false);
        if (selectedTab === "table") {
          setState((state) => {
            state.rowsData = res?._rowData ?? [];
          });
        } else {
          const chartType = from_ts !== null && to_ts !== null ? "fixed" : "live";
          setGraphState((state) => {
            state.data = res ?? [];
            state.chart = chartType;
          });
        }
        setRowCount(res?._totalCount ?? 0);
      });
  };

  
  function handleDateTimeChange(type, date) {
    setState((state) => {
      state[type] = date;
      state[`${type}Error`] = false;
    });
  }

  function exportFromAPI(type) {
    const subtype = selectedSubTab !== "ALL" ? selectedSubTab : null;
    const _payload = {
      deviceId: deviceId,
      exportType: type.toUpperCase(),
      startTs: new Date(state.startTs).getTime(),
      endTs: state.endTs ? new Date(state.endTs).getTime() : null,
      customerIds: customerIdList.toString(),
      subtype,
    };
    if (_payload.startTs) {
      const toastId = toast.loading("Exporting...");

      axios
        .get(`${APP_BASE_URL}${EXPORT_HISTORY}`, { params: _payload, responseType: "blob" })
        .then(
          (response) => {
            const name = `${deviceType}_${deviceName}_History.${type}`;
            const data = response.data;
            downloadFile({ name, data });
          },
          async (err) => {
            const text = JSON.parse(await err.response.data.text());

            toast.error(text.error);
          },
        )
        .then(() => {
          toast.dismiss(toastId);
        });
    } else {
      toast.error("Please Select a Start Date to Export History");
    }
  }

  return (
    <div className="telemetry-history">
      <Stack
        className="telemetry-history-header flex flexWrap alignCenter spaceBetween flexDirectionRow"
        component="form"
        noValidate
        spacing={""}
      >
        <div className="flex alignCenter flexWrap">
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {TABS_LIST.map((_tab) => (
              <Button
                key={_tab}
                variant={selectedTab === _tab ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTab(_tab);
                }}
              >
                {_tab}
              </Button>
            ))}
          </ButtonGroup>
        </div>

        <div className="flex gap-2 alignCenter flexWrap">
          {selectedTab === "graph" && (
            <div className="flex-1">
              <FormControl fullWidth style={{ width: "10rem" }}>
                <InputLabel id="number-of-events-label">Number of Events</InputLabel>
                <Select
                  labelId="number-of-events-label"
                  onChange={(event) => {
                    numberOfEvents.current = event.target.value;
                    getHistoryByTs(null, false);
                  }}
                  defaultValue={DEFAULT_NUMBER_OF_EVENTS}
                  size="small"
                  label="Number of Events"
                >
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                  <MenuItem value={2000}>2000</MenuItem>
                  <MenuItem value={3000}>3000</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DateTimePicker
              label="From Date-Time*"
              value={state.startTs}
              onChange={(date) => handleDateTimeChange("startTs", date)}
              renderInput={(params) => (
                <TextField size="small" {...params} error={state.startTsError} type="datetime-local" />
              )}
            />
            <DateTimePicker
              label="To Date-Time*"
              value={state.endTs}
              onChange={(date) => handleDateTimeChange("endTs", date)}
              renderInput={(params) => (
                <TextField size="small" {...params} error={state.endTsError} type="datetime-local" />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="outlined"
            onClick={() => {
              setState((state) => {
                state.startTs = null;
                state.endTs = null;
                state.startTsError = false;
                state.endTsError = false;
              });
              getHistoryByTs(selectedSubTab, true);
            }}
          >
            Clear
          </Button>
          <Button variant="contained" onClick={() => getHistoryByTs(selectedSubTab)}>
            Search
          </Button>
        </div>
      </Stack>
      <div className="telemetry-history-main fullWidth">
        {selectedTab === "table" ? (
          <>
            <div className="flex items-center flexWrap">
              {subTabsList.map((_subTab) => (
                <button
                  className={
                    "telemetry-history__tabs--button " +
                    (selectedSubTab === _subTab ? "telemetry-history__tabs--selected" : "")
                  }
                  variant="text"
                  onClick={() => {
                    setPageIndex(DEFAULT_PAGE_INDEX);
                    setPageSize(DEFAULT_PAGE_SIZE);
                    setSelectedSubTab(_subTab);
                    SELECTED_SUBTAB_REF.current.selecetdTab = _subTab

                  }}
                  key={_subTab}
                >
                  {_subTab}
                </button>
              ))}
            </div>
            <DataTable
              tableInfo={{
                id: `telemetry-history-table`,
                label: `Telemetry ${deviceName}`,
                requireAuthSign: false,
                timestamp: { startTs: state.startTs, endTs: state.endTs },
              }}
              loading={loading}
              rows={state.rowsData}
              columns={state.gridColumns}
              pageChange={(index) => {
                setPageIndex(index);
              }}
              pageSizeChange={(size) => {
                setPageSize(size);
                setPageIndex(DEFAULT_PAGE_INDEX);
              }}
              pageIndex={pageIndex}
              pageSize={pageSize}
              paginationMode={"server"}
              rowCount={rowCount}
              rowColor={(params) => {
                if (deviceType === "PM") {
                  return params.row.OT > 6000 ? "otColor" : "";
                }
              }}
              serverSideExport={exportFromAPI}
            />
          </>
        ) : (
          <HistoryGraph
            loading={loading}
            graphState={graphState}
            columns={state.gridColumns}
            deviceId={deviceId}
            liveData={liveData}
          ></HistoryGraph>
        )}
      </div>
      {pointChartPop.open && (
        <PointChartPopup
          open={pointChartPop.open}
          machineData={pointChartPop.machineData}
          deviceId={pointChartPop.deviceId}
          currentTs={pointChartPop.currentTs}
          deviceName={pointChartPop.deviceName}
          onClose={() => {
            setPointChartPop((prev) => {
              prev.open = false;
            });
          }}
          disableFilter
        />
      )}
    </div>
  );
}
