import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import DataTable from "../../../../DataGrid/DataGrid";
import { ActiveAlarmsData } from "../../../../Alarms/ActiveAlarms";
import axios from "axios";
import {
  APP_BASE_URL,
  ASSET_MAINTENANCE_HISTORY_API,
  ASSET_MAINTENANCE_TABLE_API,
  GET_SCHEDULE_CODE_LIST,
} from "../../../../../Common/EndPoints";
import HistoryIcon from "@mui/icons-material/History";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Autocomplete, Button, TextField, Tooltip } from "@mui/material";
import {
  calculateDayDifference,
  getFormattedDate,
  getFormattedDateTime,
} from "../../../../MIGRATED DASHBOARD/DASHBOARD COMPONENTS/Constants";
import { FORM_VIEW_ICON, MAINTENACE_FORM_RESPONSE_ICON } from "../../../../../ICONS/Icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useImmer } from "use-immer";
import "./AssetMaintenanceHistory.scss";
import { PeridioCityTerminologies } from "../../MaintenanceConstants";
import { extractAllowedUserRoleList, extractMasterRoleIds } from "../../../../../Utils/Utils";

const initialFilters = {
  startTs: null,
  endTs: null,
  scheduleCode: null,
  pageIndex: 0,
  pageSize: 20,
  totalRowCount: 0,
};

function AssetMaintenanceHistory() {
  let roleHr = JSON.parse(localStorage.getItem('roleHierarchies'))
  let MASTER_ROLE_IDS = extractMasterRoleIds(roleHr)
  const location = useLocation();
  const navigate = useNavigate();
  const { customerIdList } = useContext(ActiveAlarmsData);
  const [assetHistory, setAssetHistory] = useState([]);
  const [assetHistoryFilters, setAssetHistoryFilters] = useImmer(initialFilters);
  const [scheduleCodes, setScheduleCodes] = useState([]);
  const [navState, setNavState] = useImmer(location.state)
  console.log('navState', location.state)
  useEffect(() => {
    getScheduleCodeList(navState.asset.assetId);
  }, []);
  const getScheduleCodeList = (assetId) => {
    let url = `${APP_BASE_URL}${GET_SCHEDULE_CODE_LIST}?assetId=${assetId}&scheduleCodeMapingOnly=${true}`;
    axios.get(url).then((res) => {
      let codes = res.data.data.map((val) => {
        return {
          id: val.id,
          label: val.code,
        };
      });
      // console.log('codes',codes )
      setScheduleCodes(codes);
    });
  };
  useEffect(() => {
    if (customerIdList) {
    let roleIdList = assetHistoryFilters?.roleId?.length>0?assetHistoryFilters.roleId.map((role)=>role.id).toString():MASTER_ROLE_IDS.toString()

      // let roleIdList = assetHistoryFilters?.roleId?.length>0?'':MASTER_ROLE_IDS.toSt
      let filterParams = {
        // customerIds: customerIdList.toString(),
        status: "DONE",
        withResponse: false,
        page: assetHistoryFilters.pageIndex + 1,
        pageSize: assetHistoryFilters.pageSize,
        startTs: assetHistoryFilters.startTs ?? null,
        endTs: assetHistoryFilters.endTs ?? null,
        scheduleCode: assetHistoryFilters?.scheduleCode?.label ?? null,
        deviceIds: navState.asset.assetId,
        roleIdList
      };
      getAssetMaintenanceHistory(filterParams);
    }
  }, [
    customerIdList,
    assetHistoryFilters.pageIndex,
    assetHistory.pageSize,
    assetHistoryFilters.startTs,
    assetHistoryFilters.endTs,
    assetHistoryFilters.scheduleCode,
    assetHistoryFilters.roleId,
  ]);

  const assetMaintenanceHistoryColumn = useMemo(() => {
    return [
      {
        field: "responseAction",
        type: "actions",
        minWidth: 80,
        flex: 1,
        hideable: false,
        getActions: (params) => [
          <Tooltip placement="bottom" title="Asset maintenance Response" disableInteractive>
            <GridActionsCellItem
              icon={FORM_VIEW_ICON}
              label="Aseet maintenance history"
              onClick={(event) => {
                let navigationState = {
                  asset: {
                    assetName: params.row.gearName,
                    assetId: params.row.assetId,
                  },
                  scheduleCode: {
                    code: { id: params.row.scheduleCodeId, label: params.row.scheduleCode },
                    list: scheduleCodes,
                  },
                };
                navigate("/maintenanace-responses", { state: navigationState });
              }}
            />
          </Tooltip>,
        ],
      },
      {
        field: "scheduleCode",
        headerName: "Schedule Code",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "maintenanceCategory",
        headerName: "Maintenance Category",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "completedDt",
        headerName: "Done Date",
        sortable: true,
        minWidth: 150,
        hideable: true,
        flex: 1,
      },

      {
        field: "expectedDt",
        headerName: "Expected Done Date",
        sortable: true,
        minWidth: 180,
        hideable: true,
        flex: 1,
      },
      {
        field: "periodicity",
        headerName: "Peridiocity",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "completedByName",
        headerName: "Done By",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "offset",
        headerName: "Offset",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
        cellClassName: (param) => {
          if (param.value.includes("Late")) {
            return "red";
          } else if (param.value.includes("Time")) {
            return "green";
          }
        },
      },

      {
        field: "designation",
        headerName: "Designation",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
    ];
  });

  const getAssetMaintenanceHistory = (params) => {
    let url = `${APP_BASE_URL}${ASSET_MAINTENANCE_HISTORY_API}`;
    axios.get(url, { params }).then((res) => {
      setAssetHistoryFilters((prev) => {
        prev.totalRowCount = res.data.data.totalElements;
      });
      let response = res.data.data.content;
      let assetHistoryRows = response.map((row, i) => {
        let remainingDaysOffset = calculateDayDifference(row.completedDt, row.expectedDt);
        remainingDaysOffset =
          remainingDaysOffset > 0 || remainingDaysOffset === 0
            ? "On Time"
            : Math.abs(remainingDaysOffset) + " " + "Days Late";
        return {
          ...row,
          id: i,
          completedDt: getFormattedDateTime(row.completedDt),
          expectedDt: getFormattedDate(row.expectedDt),
          offset: remainingDaysOffset,
          periodicity: PeridioCityTerminologies[row.periodicity] ?? "",
        };
      });
      setAssetHistory(assetHistoryRows);
    });
  };
  const handleChange = (value, stateName) => {
    setAssetHistoryFilters((prev) => {
      prev[stateName] = value;
    });
  };
  const getRoleIdList = useMemo(()=>{
    let roleHierarchies = JSON.parse(localStorage.getItem("roleHierarchies"));
  let  list= extractAllowedUserRoleList(roleHierarchies)
  console.log('list',list)
  return list
  
  },[])
  return (
    <div className="asset-maintenance-history">
      <div className="heading">Maintenance history of {navState?.asset.assetName ?? ""}</div>
      <div className="filters">
        <Autocomplete
          disablePortal
          className="filter-input"
          size="small"
          options={scheduleCodes}
          value={assetHistoryFilters.scheduleCode}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField size="small" {...params} label="Select Schedule Code" />}
          getOptionLabel={(params) => {
            return params.label;
          }}
          onChange={(event, newValue) => {
            handleChange(newValue, "scheduleCode");
          }}
        />
                <Autocomplete
          className="filter-input"
          size="small"
          disablePortal
          id="combo-box-demo"
          value={assetHistoryFilters.roleId ?? []}
          options={getRoleIdList}
          onChange={(_, newValue) => {
            handleChange(newValue, "roleId");
          }}
          getOptionLabel={(role) => role.value}
          multiple
          // sx={{ width: 300 }}
          renderInput={(params) => <TextField size="small" {...params} label="User Role" />}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            size="small"
            // disableFuture
            className="filter-input"
            // sx={{}}
            label="From Date"
            openTo="day"
            views={["year", "month", "day"]}
            value={assetHistoryFilters?.startTs}
            onChange={(newValue) => {
              if (Date.parse(newValue && newValue["$d"])) {
                let ts = newValue["$d"].getTime();
                handleChange(ts, "startTs");
              } else {
                if (newValue === null) {
                  handleChange(newValue, "startTs");
                }
              }

              // let ts = newValue["$d"].getTime();
              // handleChange(ts, "startTs");
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <DatePicker
            // disableFuture
            className="filter-input"
            label="To Date"
            openTo="day"
            views={["year", "month", "day"]}
            value={assetHistoryFilters?.endTs}
            onChange={(newValue) => {
              if (Date.parse(newValue && newValue["$d"])) {
                let ts = newValue["$d"].getTime();
                handleChange(ts, "endTs");
              } else {
                if (newValue === null) {
                  handleChange(newValue, "endTs");
                }
              }
              // let ts = newValue["$d"].getTime();
              // handleChange(ts, "endTs");
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        <Button variant="outlined" onClick={() => setAssetHistoryFilters(initialFilters)}>
          Clear Filters
        </Button>
      </div>
      <div className="asset-maintenance-history-table">
        <DataTable
          tableInfo={{
            id: `asset-maintenance -history`,
            label: `Asset Maintenance History`,
            requireAuthSign: false,
            timestamp: { startTs: null, endTs: null },
          }}
          rows={assetHistory}
          columns={assetMaintenanceHistoryColumn}
          paginationMode={"server"}
          // rowCount={50}
          rowCount={assetHistoryFilters.totalRowCount}
          pageIndex={assetHistoryFilters.pageIndex}
          pageChange={(index) => {
            setAssetHistoryFilters((prev) => {
              prev.pageIndex = index;
            });
          }}
          pageSize={assetHistoryFilters.pageSize}
          pageSizeChange={(size) => {
            setAssetHistoryFilters((prev) => {
              prev.pageSize = size;
            });
          }}
        />
      </div>
    </div>
  );
}

export default AssetMaintenanceHistory;
