import { Button } from "@mui/material";
import { toast } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { createContext, memo, useContext, useEffect, useMemo, useReducer } from "react";

import { useAsync } from "../../hooks/useAsync";
import DataTable from "../../Components/DataGrid/DataGrid";
import HandleCustomerGroupModal from "./HandleCustomerGroupModal";
import {
  createCustomerGroup,
  deleteCustomerGroup,
  editCustomerGroup,
  getCustomerGroup,
  getCustomersFromGroup,
  getTableData,
} from "./CustomerGroupUtils";

const CustomerGroupEditActions = (params) => {
  const { toggleModal } = useCustomerGroupContext();

  return (
    <div>
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        onClick={() => {
          const { name, id, customer_group } = params.params.row;
          const customerIds = customer_group.map((customer) => customer.customerId);
          toggleModal({ type: "edit", name, customerGroupId: id, customerIds });
        }}
      />
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => {
          const { id } = params.params.row;
          toggleModal({ type: "delete", customerGroupId: id });
        }}
      />
    </div>
  );
};
const CustomersList = (params) => {
  const customersList = params.params.row.customer_group;
  const { customer_group } = getCustomersFromGroup(customersList);
  return <div>{customer_group}</div>;
};
const CUSTOMER_GROUP_TABLE_COLUMNS = [
  {
    field: "editActions",
    type: "actions",
    width: 80,
    hideable: false,
    renderCell: (params) => <CustomerGroupEditActions params={params} />,
  },
  {
    field: "name",
    headerName: "Group Name",
    headerAlign: "center",
    align: "center",
    sortable: true,
    width: 120,
  },
  {
    field: "customer_group",
    headerName: "Stations",
    headerAlign: "center",
    align: "center",
    sortable: true,
    width: 120,
    flex: 1,
    renderCell: (params) => <CustomersList params={params} />,
  },
];

const INITIAL_ACTION_MODAL_STATE = {
  open: false,
  name: "",
  customerIds: [],
  type: null,
  customerGroupId: null,
  tableData: [],
  pageSize: 20,
};

const MODAL_ACTIONS = {
  create: { dispatch: "OPEN_CREATE_MODAL", request: createCustomerGroup, title: "Create" },
  edit: { dispatch: "OPEN_EDIT_MODAL", request: editCustomerGroup, title: "Edit" },
  delete: { dispatch: "OPEN_DELETE_MODAL", request: deleteCustomerGroup, title: "Delete" },
};

const customerGroupReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "TOGGLE_MODAL":
      return { ...state, open: !state.open };
    case "SET_TABLE_DATA":
      return { ...state, tableData: payload.tableData };
    case "OPEN_CREATE_MODAL":
    case "OPEN_EDIT_MODAL":
    case "OPEN_DELETE_MODAL":
      return {
        ...state,
        ...payload,
        open: true,
      };
    case "SET_TABLE_PAGE_SIZE":
      return { ...state, pageSize: payload.pageSize };
    case "RESET":
      return { ...INITIAL_ACTION_MODAL_STATE, tableData: state.tableData, pageSize: state.pageSize };
    case "HANDLE_VALUE_CHANGE":
      return { ...state, [payload.type]: payload.value };
    default:
      return state;
  }
};

const CustomerGroupContext = createContext();
export const useCustomerGroupContext = () => useContext(CustomerGroupContext);

export const CustomerGroupProvider = () => {
  const [state, dispatch] = useReducer(customerGroupReducer, INITIAL_ACTION_MODAL_STATE);
  const {
    execute: fetchCustomerGroup,
    status: loading,
    value: customerGroupList = [],
    error,
  } = useAsync(getCustomerGroup, true);

  useEffect(() => {
    const tableData = getTableData(customerGroupList);
    if (tableData.length === 0 && state.tableData.length === 0) return;
    dispatch({ type: "SET_TABLE_DATA", payload: { tableData } });
  }, [customerGroupList]);

  function toggleModal(payload) {
    const type = payload?.type ? MODAL_ACTIONS[payload.type].dispatch : "TOGGLE_MODAL";
    dispatch({ type, payload });
  }
  function handlePageSizeChange(pageSize) {
    dispatch({ type: "SET_TABLE_PAGE_SIZE", payload: { pageSize } });
  }
  function resetActionModal() {
    dispatch({ type: "RESET" });
  }
  function handleValueChange(type, value) {
    dispatch({ type: "HANDLE_VALUE_CHANGE", payload: { type, value } });
  }
  function handleSubmitActionModal() {
    const customerIds = state.customerIds?.toString();
    const name = state.name;
    const apiRequest = MODAL_ACTIONS[state.type].request;
    const title = MODAL_ACTIONS[state.type].title;
    const customerGroupId = state.customerGroupId;
    apiRequest({ customerIds, name, customerGroupId }).then(
      () => {
        toast.success(`${title} Successful`);
        fetchCustomerGroup();
        resetActionModal();
      },
      (err) => {
        const message = err.response.data.error.message ?? "Something Went Wrong";
        console.error("Customer Group CRUD request error", message);
        toast.error(`${message}`);
      },
    );
  }

  const modalTitle = MODAL_ACTIONS[state.type]?.title;

  const store = useMemo(
    () => ({
      loading,
      toggleModal,
      resetActionModal,
      handlePageSizeChange,
      handleSubmitActionModal,
      handleValueChange,
      modalTitle,
      ...state,
    }),
    [state.tableData, state.open, state.tableSize, state.name, state.customerIds, loading],
  );
  return (
    <CustomerGroupContext.Provider value={store}>
      <CustomerGroup />
    </CustomerGroupContext.Provider>
  );
};

const CustomerGroup = memo(() => {
  const { loading, open, toggleModal, tableSize, handlePageSizeChange, tableData } = useCustomerGroupContext();

  return (
    <div className="page-container--card">
      <div className="page-container__sub-header">
        <Button variant="outlined" onClick={() => toggleModal({ type: "create" })}>
          Create Group
        </Button>
      </div>
      <DataTable
        tableInfo={{
          id: `Customer-Group-Table`,
          label: `Customer Group`,
          requireAuthSign: false,
          timestamp: { startTs: null, endTs: null },
        }}
        pageSize={tableSize}
        pageSizeChange={handlePageSizeChange}
        loading={loading === "pending" ? true : false}
        rows={tableData}
        columns={CUSTOMER_GROUP_TABLE_COLUMNS}
      />
      {open && <HandleCustomerGroupModal />}
    </div>
  );
});
export default CustomerGroupProvider;
