import { Button, ButtonBase, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useImmer } from 'use-immer'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const inititialRangeState = {}
let inititialRangeFields = [1]

function RangePreviewNumeric({ rangeType,getRangeObj }) {
    const [numberOfRow, setNumberOfRow] = useImmer([1])
    const [rangeState, setRangeState] = useImmer({})
    const handleOChange = (number, fieldType, value) => {
        let newRangeObj = rangeState[number] ? { ...rangeState[number] } : {}
        newRangeObj[fieldType] = value
        setRangeState((prev) => {
            prev[number] = newRangeObj
        })

    }

    useEffect(() => {
        let rangeArray = Object.values(rangeState)
        let rangeObj = {}
        if(rangeArray.length >0){
       rangeObj[rangeType] = rangeArray
       getRangeObj(rangeObj)
   }
    }, [rangeState])

    return (
        <>
            {numberOfRow.map((number, i) => {
                return (
                    <div className='rangeRow'>
                        <TextField size='small' value={rangeState[number]?.min ?? ''} type='number' label='min' onChange={(e) => { handleOChange(number, 'min', e.target.value) }} />
                        <TextField size='small' value={rangeState[number]?.max ?? ''} type='number' label='max' onChange={(e) => { handleOChange(number, 'max', e.target.value) }} />
                        {numberOfRow.length - 1 == i && (
                            <div onClick={() => {
                                setNumberOfRow((prev) => {
                                    prev.push(number + 1)
                                })
                            }}><AddIcon size='small' /></div>
                        )}
                        {numberOfRow.length > 1 && (
                            <div onClick={() => {
                                let newNumberOfRows = [...numberOfRow]
                                let newRangeState = { ...rangeState }
                                const index = newNumberOfRows.indexOf(number);
                                if (index > -1) {
                                    newNumberOfRows.splice(index, 1);
                                }
                                delete newRangeState[number]
                                setNumberOfRow(newNumberOfRows)
                                setRangeState(newRangeState)
                            }}><RemoveIcon size='small' /></div>
                        )}

                    </div>
                )

            })}        </>
    )
}

export default RangePreviewNumeric
