import { Autocomplete, Button, Chip, InputLabel, TextField } from "@mui/material";
import React, { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import { extractUserDataFromPermission } from "../../../Utils/Utils";
import {
    FIXED_DROPDOWNS,
    FIXED_REACTIVE_DROPDOWNS_COF,
    UESR_ROLE_DROPDOWNS,
    allowedUserRoleIds,
    disconnectionMemoIssuedDropdowns,
    failurStateToLabelMap,
    rectificationStatusDropdowns,
    relayRoomOpenDropdowns,
} from "./Constants";
import { useGetDeviceTypesQuery, useGetDevicesNameQuery } from "../../../features/SearchFilters/searchFiltersSlice";
import { ActiveAlarmsData } from "../../Alarms/ActiveAlarms";
import {
    getDeviceNameSearchParams,
    getDeviceTypeSearchParams,
} from "../../../features/SearchFilters/searchFiltersUtils";
import { APP_BASE_URL, GET_ALL_USER, GET_ASSETS, GET_USER_LIST, GET_USER_ROLES, SFR_API } from "../../../Common/EndPoints";
import axios from "axios";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import "./SFRFORM_Component.scss";
import AffectedGearTypes from "./Add AFFECTED GEAR TYPE  OR TRAIN/AffectedGearTypes";
import DetainedTrains from "./Add AFFECTED GEAR TYPE  OR TRAIN/DetainedTrains";
import dayjs from "dayjs";
import { Navigate, useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import moment from "moment";
import { toast } from "react-hot-toast";
import { min } from "rxjs";
import { getDesiredFormat, getDurationInMinutes, getMinMaxRangeOfDate } from "./Utils";

// "customerId": "string", --
// "data": "string",
// "dbCreate": 0,
// "deviceId": "string", --
// "division": "string", --
// "failureEntryNo": "string", --
// "failureOn": 0, --
// "genericAttachmentId": "string",
// "id": "string",
// "make": "string", --
// "model": "string", --
// "repairedBy": "string", --
// "repairedOn": 0, --
// "rfid": "string", --
// "serialNumber": "string", --
// "status": "string", --
// "zone": "string" --

function hasDropdownCuurentValue(ddList, ddValue) {
    let hasValue = false;
    ddList.forEach((dd) => {
        if (dd.id === ddValue?.id) {
            hasValue = true;
        }
    });

    return hasValue;
}

const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
};

const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
};
const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[500] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const INITIAL_AFFECTED_ASSETS = [{ assetType: null, asset: null }];
const INITIAL_DETAINED_TRAINS = [{ trainNumber: null, detention: "", hqPunctality: "", rlyBdPunctuality: "" }];
function removeDependentStates(array, valuesToRemove) {
    return array.filter((value) => !valuesToRemove.includes(value));
}

function addDependentState(array, valuesToAdd) {
    valuesToAdd.forEach((value) => {
        if (!array.includes(value)) {
            array.push(value);
        }
    });

    return array;
}

const INITIAL_REQUIRED_FIELD = [
    "zone",
    "division",
    "customerId",
    'assetType',
    "failureEntryNo",
    "deviceId",
    "department",
    "deviceId",
    "infoReceivedBy",
    "infoReceivedTs",
    "failureOn",
    "status",
    "disconnectionMemoIssued",
    "relayRoomOpened",
];
const dependentRequiredFields = {
    status: ["failureAttendedTs", "repairedOn", "repairedBy", "causeOfFailure", "actionPerformed"],
    disconnectionMemoIssued: [
        "disconnectionMemoReqNumber",
        "disconnectionMemoTimeOfferd",
        "disconnectionMemoTimeAccepted",
        "gearDisconnectTime",
        "gearReconnectionTime",
    ],
    relayRoomOpened: ["relayRoomOpenTs", "relayRoomCloseTs"],
};
let initialConfirmationModal = {
    open: false,
    message: "",
    des: "",
};
function SFRFORM_Component({ initFaliureForm, isEdit }) {
    const [failureForm, setFailureForm] = useImmer(initFaliureForm);
    const [requiredFields, setRequiredFields] = useImmer(INITIAL_REQUIRED_FIELD);
    const [confirmationModal, setConfirmationModal] = useImmer(initialConfirmationModal);
    console.log("requiredFields", requiredFields);
    const [validationState, setValidationState] = useImmer({ autoCheck: false });
    const navigate = useNavigate()
    let permissions = JSON.parse(localStorage.getItem("permissions"));
    const {
        outermostKeys: zone,
        outerToInnerKeys: division,
        innerToCustomerNames: station,
        customerNameToId: customer_name_to_id,
    } = extractUserDataFromPermission(permissions);
    const { customerIdList = [], selectedCustomersIdName = [] } = useContext(ActiveAlarmsData);
    const { data: gearTypesList = [] } = useGetDeviceTypesQuery(
        getDeviceTypeSearchParams({
            customerIdList,
            filterState: { customer_ids: failureForm?.customerId ? [failureForm?.customerId] : [] },
        }),
    );
    const { data: deviceNamesList = [] } = useGetDevicesNameQuery(
        getDeviceNameSearchParams({
            customerIdList,
            filterState: {
                customer_ids: failureForm?.customerId ? [failureForm?.customerId] : [],
                device_type_ids: failureForm?.assetType ? [failureForm?.assetType] : [],
            },
        }),
    );

    const handleChange = (stateName, value) => {
        setFailureForm((prev) => {
            prev[stateName] = value;
        });
    };
    useEffect(() => {
        if (validationState.autoCheck) {
            validateFailureForm(failureForm);
        }
    }, [failureForm]);
    useEffect(() => {
        let dependentFields = dependentRequiredFields.status;
        let existingRequiredFields = [...requiredFields];

        if (failureForm.status && (failureForm.status.value === "REPAIRED" || failureForm.status.value === "CLOSE")) {
            let newRequiredFields = addDependentState(existingRequiredFields, dependentFields);
            console.log("newRequiredFields", newRequiredFields);
            setRequiredFields(newRequiredFields);
        } else {
            let newRequiredFields = removeDependentStates(existingRequiredFields, dependentFields);
            setRequiredFields(newRequiredFields);
        }
    }, [failureForm.status]);

    useEffect(() => {
        let dependentFields = dependentRequiredFields.disconnectionMemoIssued;
        let existingRequiredFields = [...requiredFields];

        if (failureForm.disconnectionMemoIssued && failureForm.disconnectionMemoIssued.value === "YES") {
            let newRequiredFields = addDependentState(existingRequiredFields, dependentFields);
            console.log("newRequiredFields", newRequiredFields);
            setRequiredFields(newRequiredFields);
        } else {
            let newRequiredFields = removeDependentStates(existingRequiredFields, dependentFields);
            setRequiredFields(newRequiredFields);
        }
    }, [failureForm.disconnectionMemoIssued]);
    useEffect(() => {
        let dependentFields = dependentRequiredFields.relayRoomOpened;
        let existingRequiredFields = [...requiredFields];

        if (failureForm.disconnectionMemoIssued && failureForm.relayRoomOpened.value === "YES") {
            let newRequiredFields = addDependentState(existingRequiredFields, dependentFields);
            console.log("newRequiredFields", newRequiredFields);
            setRequiredFields(newRequiredFields);
        } else {
            let newRequiredFields = removeDependentStates(existingRequiredFields, dependentFields);
            setRequiredFields(newRequiredFields);
        }
    }, [failureForm.relayRoomOpened]);
    // }, [failureForm.status, failureForm.disconnectionMemoIssued, failureForm.relayRoomOpened])

    const getZoneList = useMemo(() => {
        let list = [];
        zone.forEach((zone) => {
            list.push({ id: zone, value: zone });
        });
        return list;
    }, []);
    // const getUserRoles = useMemo(() => {
    //     let list = [];
    //     let url = `${APP_BASE_URL}${GET_USER_ROLES}`;
    //     let params = {}
    //     // if (failureForm?.customerId) {
    //     //     params = {
    //     //         customerIds: failureForm.customerId.id
    //     //     }
    //     // }
    //     axios.get(url, { params }).then((res) => {
    //         // const RESPONSE = res.data.data
    //         // console.log('user RESPONSE', RESPONSE)
    //         Object.entries(res.data.data).forEach(([key, value]) => {
    //             list.push({ id: key, value: value });
    //         });
    //     });
    //     return list;
    // }, []);
    const getUsersList = useMemo(() => {


        if (failureForm.customerId) {
            let list = [];
            // let url = `${APP_BASE_URL}${GET_USER_LIST}`;
            let url = `${APP_BASE_URL}${GET_ALL_USER}`;
            let params = {
                customerIds: failureForm.customerId.id,
                roleIdList: allowedUserRoleIds.toString()
            }
            axios.get(url, { params }).then((res) => {
                console.log('users', res.data.data)
                let USERS = res.data.data
                USERS.forEach((user) => {
                    list.push({ id: user.id, value: user.firstName + ' ' + user.lastName })
                })

                console.log('list', list)
            });
            return list;
        } else {
            return []
        }

    }, [failureForm.customerId]);

    const getDivisionList = useMemo(() => {
        let list = [];
        if (failureForm.zone) {
            let divList = division[failureForm.zone.value];
            divList.forEach((division) => {
                list.push({ id: division, value: division });
            });
        }

        if (failureForm.division && !hasDropdownCuurentValue(list, failureForm.division)) {
            setFailureForm((prev) => {
                prev.division = null;
            });
        }
        return list;
    }, [failureForm.zone]);

    const getStationList = useMemo(() => {
        let list = [];
        if (failureForm.division) {
            let stnList = station[failureForm.division.value];
            stnList.forEach((stn) => {
                list.push({ id: customer_name_to_id[stn].toString(), value: stn });
            });
        }
        if (failureForm.customerId && !hasDropdownCuurentValue(list, failureForm.customerId)) {
            setFailureForm((prev) => {
                prev.customerId = null;
            });
        }

        return list;
    }, [failureForm.division]);

    const getCOFDropDowns = useMemo(() => {
        let COF_LIST = [];
        if (failureForm.department) {
            COF_LIST = FIXED_REACTIVE_DROPDOWNS_COF[failureForm.department.value];
        }
        if (failureForm.causeOfFailure && !hasDropdownCuurentValue(COF_LIST, failureForm.causeOfFailure)) {
            setFailureForm((prev) => {
                prev.causeOfFailure = null;
            });
        }

        return COF_LIST;
    }, [failureForm.department]);

    useEffect(() => {
        if (failureForm.deviceId) {
            let url = `${APP_BASE_URL}${GET_ASSETS}/${failureForm.deviceId.id}`;
            axios.get(url).then((res) => {
                let RESPONSE = res.data.data;
                let ADDITIONAL_INFO = RESPONSE.additionaldetail;
                // console.log('ADDITIONAL_INFO', ADDITIONAL_INFO)
                setFailureForm((prev) => {
                    prev.rfid = ADDITIONAL_INFO.rfid ?? "";
                    prev.model = ADDITIONAL_INFO.model ?? "";
                    prev.make = ADDITIONAL_INFO.make ?? "";
                    prev.serialNumber = ADDITIONAL_INFO.serial ?? "";
                });
            });
        } else {
            setFailureForm((prev) => {
                prev.rfid = "";
                prev.model = "";
                prev.model = "";
                prev.serialNumber = "";
            });
        }
    }, [failureForm.deviceId]);

    useEffect(() => {
        if (failureForm.failureOn && failureForm.repairedOn) {
            let duration = getDurationInMinutes(failureForm.failureOn, failureForm.repairedOn);
            setFailureForm((prev) => {
                prev.failurDuration = duration;
            });
        } else {
            setFailureForm((prev) => {
                prev.failurDuration = "";
            });
        }
    }, [failureForm.failureOn, failureForm.repairedOn]);

    useEffect(() => {
        if (failureForm.relayRoomOpenTs && failureForm.relayRoomCloseTs) {
            let duration = getDurationInMinutes(failureForm.relayRoomOpenTs, failureForm.relayRoomCloseTs);
            setFailureForm((prev) => {
                prev.relayRoomOpenDuration = duration;
            });
        } else {
            setFailureForm((prev) => {
                prev.relayRoomOpenDuration = "";
            });
        }
    }, [failureForm.relayRoomOpenTs, failureForm.relayRoomCloseTs]);

    const getAffectedAssets = useCallback((affectedAssets) => {
        setFailureForm((prev) => {
            prev.affectedAssets = affectedAssets;
        });
    }, []);
    const getDetainedTrains = useCallback((detainedTrains) => {
        setFailureForm((prev) => {
            prev.detainedTrains = detainedTrains;
        });
    }, []);

    const validateFailureForm = (form) => {
        let isValid = true;
        let dateStates = Object.keys(failurStateToLabelMap)
        // console.log("aaaa", form);
        requiredFields.forEach((reqField) => {
            if (dateStates.includes(reqField)) {
                if (!validationState[reqField]) {
                    isValid = !failureForm[reqField] ? false : true;
                    setValidationState((prev) => {
                        prev[reqField] = !failureForm[reqField] ? "Field is required" : ''
                    });
                }
            } else {
                if (!form[reqField]) {
                    isValid = false;
                    setValidationState((prev) => {
                        prev[reqField] = "Field is required";
                    });
                }
                else {
                    setValidationState((prev) => {
                        prev[reqField] = "";
                    });
                }
            }

        });
        let _validationState = { ...validationState, autoCheck: '' }
        Object.values(_validationState).forEach((errorText) => {
            if (errorText) {
                console.log('stuck', errorText)
                isValid = false
            }
        })
        console.log(isValid)
        return isValid;
    };

    const handleSubmit = () => {


        let url = `${APP_BASE_URL}${SFR_API}`
        let apiRequest = axios.post
        let failureReportApiStatus = {
            severity: 'success',
            msg: 'Submitted Succesfully'
        }
        if (isEdit) {
            // url.log('failureForm in edit', failureForm)
            url = `${APP_BASE_URL}${SFR_API}/${failureForm.id}`
            apiRequest = axios.put
            failureReportApiStatus = {
                severity: 'success',
                msg: 'Updated Succesfully'
            }
        }

        let data = getDesiredFormat(failureForm)
        // console.log(data)
        // let url = `${APP_BASE_URL}${SFR_API}`
        apiRequest(url, data).then((res) => {
            console.log('post succes', res)
            localStorage.setItem('failureReportApiStatus', JSON.stringify(failureReportApiStatus))
        }).catch((err) => {
            const errMsg = err?.response?.data?.error?.message;
            failureReportApiStatus = {
                severity: 'error',
                msg: errMsg
            }
            localStorage.setItem('failureReportApiStatus', JSON.stringify(failureReportApiStatus))
        }).then(() => {
            navigate('/failure-report')
        })


    };
    const getMaxRange = (type, state) => {
        const [_, maxRangeArray] = getMinMaxRangeOfDate(type, state);
        // console.log("maxRange", maxRange);
        let maxRange = null;
        let _relatedState = null
        for (const relatedState of maxRangeArray) {
            if (failureForm[relatedState]) {
                maxRange = dayjs(new Date(failureForm[relatedState]));
                _relatedState = relatedState
                break;
            }
        }
        return { maxRange: maxRange, relatedState: _relatedState }
    };

    const getRangeAndError = (type, state) => {
        const [minRangeArray, maxRangeArray] = getMinMaxRangeOfDate(type, state);
        let maxRange = null
        let minRange = null
        let minRangeState = null
        let maxRangeState = null
        for (const relatedState of maxRangeArray) {
            if (failureForm[relatedState]) {
                maxRange = failureForm[relatedState]
                maxRangeState = relatedState
            }
        }
        for (const relatedState of minRangeArray) {
            if (failureForm[relatedState]) {
                minRange = failureForm[relatedState]
                minRangeState = relatedState
            }
        }
        return {
            min: minRange ? { ts: minRange, errState: minRangeState } : null,
            max: maxRange ? { ts: maxRange, errState: maxRangeState } : null,
        }
    }
    const getMinRange = (type, state) => {
        const [minRangeArray, _] = getMinMaxRangeOfDate(type, state);
        // console.log("maxRange", maxRange);
        let minRange = null;
        for (const relatedState of minRangeArray) {
            if (failureForm[relatedState]) {
                minRange = dayjs(new Date(failureForm[relatedState]));
                break;
            }
        }
        return minRange
    };
    const handleConfirmPopup = () => {
        setValidationState((prev) => {
            prev.autoCheck = true
        })
        if (validateFailureForm(failureForm)) {
            setConfirmationModal((prev) => {
                prev.open = true;
                prev.message = "Submit Form";
                prev.des = "Do you want to submit the form ?";
            });
        } else {
            console.log('not validated')
        }

    }

    const checkDateValidation = (rangeError, currentTs, currentState) => {
        const { min, max } = rangeError
        // console.log('checkDateValidation', min, max, currentTs)
        let errorText = ''
        if (min) {
            let { ts, errState } = min
            if (currentTs < ts) {
                errorText = 'Value can not be less than ' + failurStateToLabelMap[errState]?.label
            }
        }
        if (max) {
            let { ts, errState } = max
            if (currentTs > ts) {
                errorText = 'Value can not be greater than ' + failurStateToLabelMap[errState]?.label
            }
        }
        setValidationState((prev) => {
            prev[currentState] = errorText
        })
    }
    return (
        <form className="sfr-form-container">
            {confirmationModal.open && (
                <ConfirmationModal
                    open={confirmationModal.open}
                    onClose={() => {
                        setConfirmationModal(initialConfirmationModal);
                    }}
                    onAction={(confirmation) => {
                        if (confirmation) {

                            handleSubmit()

                        }
                        setConfirmationModal(initialConfirmationModal);
                    }}
                    message={confirmationModal.message}
                    des={confirmationModal.des}
                />
            )}
            <div className="sfr-form">
                <Autocomplete
                    className="form-input autocomplete"
                    size="small"
                    value={failureForm.zone ?? null}
                    onChange={(event, newValue) => {
                        handleChange("zone", newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={getZoneList}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    // required={requiredFields.includes('zone')}

                    renderInput={(params) => (
                        <TextField
                            size="small"
                            error={validationState?.zone}
                            helperText={validationState?.zone}
                            {...params}
                            label={"Zone" + (requiredFields.includes("zone") ? "*" : "")}
                            placeholder="Select zone"
                        />
                    )}
                />

                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.division ?? null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                        handleChange("division", newValue);
                    }}
                    options={getDivisionList}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            error={validationState?.division}
                            helperText={validationState?.division}
                            size="small"
                            {...params}
                            label={"Division" + (requiredFields.includes("division") ? "*" : "")}
                            placeholder="Select division"
                        />
                    )}
                />

                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.customerId ?? null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                        handleChange("customerId", newValue);
                    }}
                    options={getStationList}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                            label={"Station" + (requiredFields.includes("division") ? "*" : "")}
                            error={validationState?.customerId}
                            helperText={validationState?.customerId}
                            placeholder="Select station"
                        />
                    )}
                />

                <TextField
                    className="form-input textfield"
                    value={failureForm.failureEntryNo ?? ""}
                    onChange={(e) => {
                        handleChange("failureEntryNo", e.target.value);
                    }}
                    size="small"
                    label={"Failure Entry No" + (requiredFields.includes("failureEntryNo") ? "*" : "")}
                    error={validationState?.failureEntryNo}
                    helperText={validationState?.failureEntryNo}
                    placeholder="Failure Entry No"
                />

                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.department ?? null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                        handleChange("department", newValue);
                    }}
                    options={FIXED_DROPDOWNS.department}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                            label={"Department" + (requiredFields.includes("department") ? "*" : "")}
                            error={validationState?.department}
                            helperText={validationState?.department}
                            placeholder="Select Department"
                        />
                    )}
                />

                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.assetType ?? null}
                    onChange={(event, newValue) => {
                        handleChange("assetType", newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={gearTypesList}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                            label={"Asset Type" + (requiredFields.includes("assetType") ? "*" : "")}
                            error={validationState?.assetType}
                            helperText={validationState?.assetType}
                            placeholder="Select Asset Type"
                        />
                    )}
                />
                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.deviceId ?? null}
                    onChange={(event, newValue) => {
                        handleChange("deviceId", newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={deviceNamesList}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                            label={"Asset Name" + (requiredFields.includes("deviceId") ? "*" : "")}
                            error={validationState?.deviceId}
                            helperText={validationState?.deviceId}
                            placeholder="Select Asset Name"
                        />
                    )}
                />

                <TextField
                    className="form-input textfield disabledField"
                    value={failureForm.rfid ?? ""}
                    size="small"
                    label="RFID"
                    disabled
                // placeholder="Failure Entry No"
                />
                <TextField
                    className="form-input textfield disabledField"
                    value={failureForm.make ?? ""}
                    size="small"
                    label="Make"
                    disabled
                // placeholder="Failure Entry No"
                />
                <TextField
                    className="form-input textfield disabledField"
                    value={failureForm.model ?? ""}
                    size="small"
                    label="Model"
                    disabled
                // placeholder="Failure Entry No"
                />
                <TextField
                    className="form-input textfield disabledField"
                    value={failureForm.serialNumber ?? ""}
                    size="small"
                    label="Serial Number"
                    disabled
                // placeholder="Failure Entry No"
                />
                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.infoReceivedBy ?? null}
                    onChange={(event, newValue) => {
                        handleChange("infoReceivedBy", newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={UESR_ROLE_DROPDOWNS}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                            label={"Information Received By" + (requiredFields.includes("infoReceivedBy") ? "*" : "")}
                            error={validationState?.infoReceivedBy}
                            helperText={validationState?.infoReceivedBy}
                            placeholder="Select role"
                        />
                    )}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DateTimePicker
                        className="form-input dateTimeField"
                        label={"Failure On" + (requiredFields.includes("failureOn") ? "*" : "")}
                        value={failureForm.failureOn ?? null}
                        onChange={(newValue) => {
                            if (Date.parse(newValue && newValue["$d"])) {
                                let ts = newValue["$d"].getTime();
                                let rangeErrors = getRangeAndError("failure", "failureOn")
                                checkDateValidation(rangeErrors, ts, 'failureOn')
                                handleChange("failureOn", ts);

                            } else {
                                if (newValue === null) {
                                    handleChange("failureOn", newValue);
                                }
                            }
                        }}
                        // onError={(err, value, c, d) => {
                        //     console.log('dateError', err, new Date(value), c, d)

                        // }}
                        // maxDateTime={getMaxRange("failure", "failureOn").maxRange}
                        renderInput={(params) => (
                            <TextField
                                size="small"
                                {...params}
                                error={validationState?.failureOn}
                                helperText={validationState?.failureOn}
                                type="datetime-local"
                            />
                        )}
                    />
                    <DateTimePicker
                        className="form-input dateTimeField"
                        label={"information Received At" + (requiredFields.includes("infoReceivedTs") ? "*" : "")}
                        value={failureForm.infoReceivedTs ?? null}
                        onChange={(newValue) => {
                            if (Date.parse(newValue && newValue["$d"])) {
                                let ts = newValue["$d"].getTime();
                                let rangeErrors = getRangeAndError("failure", "infoReceivedTs")
                                checkDateValidation(rangeErrors, ts, 'infoReceivedTs')
                                handleChange("infoReceivedTs", ts);
                            } else {
                                if (newValue === null) {
                                    handleChange("infoReceivedTs", newValue);
                                }
                            }
                        }}
                        // onError={(err, value, c, d) => {
                        //     console.log('dateError', err, new Date(value), c, d)
                        //     setValidationState((prev) => {
                        //         prev.infoReceivedTs = genrateDateError(err, value)
                        //     })
                        // }}
                        // maxDateTime={getMaxRange("failure", "infoReceivedTs")}
                        // minDateTime={getMinRange("failure", "infoReceivedTs")}
                        renderInput={(params) => (
                            <TextField
                                size="small"
                                {...params}
                                error={validationState?.infoReceivedTs}
                                helperText={validationState?.infoReceivedTs}
                                type="datetime-local"
                            />
                        )}
                    />
                    <DateTimePicker
                        className="form-input dateTimeField"
                        label={"Failure Attended at Hrs" + (requiredFields.includes("failureAttendedTs") ? "*" : "")}
                        value={failureForm.failureAttendedTs ?? null}
                        onChange={(newValue) => {
                            if (Date.parse(newValue && newValue["$d"])) {
                                let ts = newValue["$d"].getTime();
                                let rangeErrors = getRangeAndError("failure", "failureAttendedTs")
                                checkDateValidation(rangeErrors, ts, 'failureAttendedTs')
                                handleChange("failureAttendedTs", ts);
                            } else {
                                if (newValue === null) {
                                    handleChange("failureAttendedTs", newValue);
                                }
                            }
                        }}
                        // maxDateTime={getMaxRange("failure", "failureAttendedTs")}
                        // minDateTime={getMinRange("failure", "failureAttendedTs")}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={validationState?.failureAttendedTs}
                                helperText={validationState?.failureAttendedTs}
                                size="small"
                            />
                        )}
                    />
                </LocalizationProvider>

                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.status ?? null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                        handleChange("status", newValue);
                    }}
                    options={rectificationStatusDropdowns}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                            label={"Rectification Status" + (requiredFields.includes("status") ? "*" : "")}
                            error={validationState?.status}
                            helperText={validationState?.status}
                            placeholder="Rectification Status"
                        />
                    )}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    {/* <DateTimePicker
                        className="form-input dateTimeField"
                        label={"Failure Attended at Hrs" + (requiredFields.includes('failureAttendedTs') ? '*' : "")}
                        value={failureForm.failureAttendedTs ?? null}
                        onChange={(newValue) => {
                            if (Date.parse(newValue && newValue["$d"])) {
                                let ts = newValue["$d"].getTime();
                                handleChange("failureAttendedTs", ts);
                            } else {
                                if (newValue === null) {
                                    handleChange("failureAttendedTs", newValue);
                                }
                            }
                        }}
                        renderInput={(params) => <TextField {...params} error={validationState?.failureAttendedTs} helperText={validationState?.failureAttendedTs} size="small" />}
                    /> */}
                    {failureForm.status && (failureForm.status.value === "REPAIRED" || failureForm.status.value === "CLOSE") && (
                        <DateTimePicker
                            className="form-input dateTimeField"
                            label={"Rectification On" + (requiredFields.includes("repairedOn") ? "*" : "")}
                            value={failureForm.repairedOn ?? null}
                            onChange={(newValue) => {
                                if (Date.parse(newValue && newValue["$d"])) {
                                    let ts = newValue["$d"].getTime();
                                    let rangeErrors = getRangeAndError("failure", "repairedOn")
                                    checkDateValidation(rangeErrors, ts, 'repairedOn')
                                    handleChange("repairedOn", ts);
                                } else {
                                    if (newValue === null) {
                                        handleChange("repairedOn", newValue);
                                    }
                                }
                            }}

                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    error={validationState?.repairedOn}
                                    helperText={validationState?.repairedOn}
                                    type="datetime-local"
                                />
                            )}
                        />
                    )}
                </LocalizationProvider>

                {failureForm.status && (failureForm.status.value === "REPAIRED" || failureForm.status.value === "CLOSE") && (
                    <>
                        {/* <TextField
                            value={failureForm.repairedBy}
                            className="form-input textfield"
                            size="small"
                            onChange={(e) => {
                                handleChange("repairedBy", e.target.value);
                            }}
                            placeholder="Rectification By"
                            label="Rectification By"
                        /> */}
                        <Autocomplete
                            size="small"
                            className="form-input autocomplete"
                            value={failureForm.repairedBy ?? null}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                handleChange("repairedBy", newValue);
                            }}
                            options={getUsersList}
                            getOptionLabel={(params) => {
                                return params.value;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    label={"Rectification By" + (requiredFields.includes("repairedBy") ? "*" : "")}
                                    error={validationState?.repairedBy}
                                    helperText={validationState?.repairedBy}
                                    placeholder="Rectification By"
                                />
                            )}
                        />
                        <TextField
                            className="form-input textfield disabledField"
                            value={failureForm.failurDuration ?? ""}
                            size="small"
                            label="Failure Duration (in Minutes)"
                            disabled
                        // placeholder="Failure Entry No"
                        />
                    </>
                )}

                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.causeOfFailure ?? null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                        handleChange("causeOfFailure", newValue);
                    }}
                    options={getCOFDropDowns}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                            label={"Cause Of Failure" + (requiredFields.includes("causeOfFailure") ? "*" : "")}
                            error={validationState?.causeOfFailure}
                            helperText={validationState?.causeOfFailure}
                            placeholder="Select Cause Of Failure"
                        />
                    )}
                />

                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.disconnectionMemoIssued ?? null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                        handleChange("disconnectionMemoIssued", newValue);
                    }}
                    options={disconnectionMemoIssuedDropdowns}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                            label={
                                "Disconnection Memo Issued to Rectify the Failure" +
                                (requiredFields.includes("disconnectionMemoIssued") ? "*" : "")
                            }
                            error={validationState?.disconnectionMemoIssued}
                            helperText={validationState?.disconnectionMemoIssued}
                            placeholder="Disconnection Memo Issued to Rectify the Failure"
                        />
                    )}
                />
                {failureForm.disconnectionMemoIssued && failureForm.disconnectionMemoIssued.value === "YES" && (
                    <>
                        <TextField
                            className="form-input textfield"
                            value={failureForm.disconnectionMemoReqNumber ?? ""}
                            size="small"
                            onChange={(e) => {
                                handleChange("disconnectionMemoReqNumber", e.target.value);
                            }}
                            placeholder="Disconnection Memo Request Number"
                            label={
                                "Disconnection Memo Request Number" + (requiredFields.includes("disconnectionMemoReqNumber") ? "*" : "")
                            }
                            error={validationState?.disconnectionMemoReqNumber}
                            helperText={validationState?.disconnectionMemoReqNumber}
                        />
                        <TextField
                            className="form-input textfield"
                            value={failureForm.disconnectionMemoTimeOfferd ?? ""}
                            size="small"
                            // type="number"
                            onChange={(e) => {
                                const regex = /^\d*\.?\d{0,}$/;
                                let inputValue = e.target.value
                                if (regex.test(inputValue)) {
                                    handleChange("disconnectionMemoTimeOfferd", inputValue);
                                }

                            }}
                            placeholder="Time Requested (In Minutes)"
                            label={"Time Requested (In Minutes)" + (requiredFields.includes("disconnectionMemoTimeOfferd") ? "*" : "")}
                            error={validationState?.disconnectionMemoTimeOfferd}
                            helperText={validationState?.disconnectionMemoTimeOfferd}
                        />
                        <TextField
                            className="form-input textfield"
                            value={failureForm.disconnectionMemoTimeAccepted ?? ""}
                            size="small"
                            onChange={(e) => {
                                const regex = /^\d*\.?\d{0,}$/;
                                let inputValue = e.target.value
                                if (regex.test(inputValue)) {
                                    handleChange("disconnectionMemoTimeAccepted", inputValue);
                                }

                            }}

                            placeholder="Time Accepted  (In Minutes)"
                            label={
                                "Time Accepted (In Minutes)" + (requiredFields.includes("disconnectionMemoTimeAccepted") ? "*" : "")
                            }
                            error={validationState?.disconnectionMemoTimeAccepted}
                            helperText={validationState?.disconnectionMemoTimeAccepted}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DateTimePicker
                                className="form-input dateTimeField"
                                label={"Time of Gear Disconnect" + (requiredFields.includes("gearDisconnectTime") ? "*" : "")}
                                value={failureForm.gearDisconnectTime ?? null}
                                onChange={(newValue) => {
                                    if (Date.parse(newValue && newValue["$d"])) {
                                        let ts = newValue["$d"].getTime();
                                        let rangeErrors = getRangeAndError("disconnectionMemo", "gearDisconnectTime")
                                        checkDateValidation(rangeErrors, ts, 'gearDisconnectTime')
                                        handleChange("gearDisconnectTime", ts);
                                    } else {
                                        if (newValue === null) {
                                            handleChange("gearDisconnectTime", newValue);
                                        }
                                    }
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        error={validationState?.gearDisconnectTime}
                                        helperText={validationState?.gearDisconnectTime}
                                        type="datetime-local"
                                    />
                                )}
                            />
                            <DateTimePicker
                                className="form-input dateTimeField"
                                label={"Time of Gear Reconnect" + (requiredFields.includes("gearReconnectionTime") ? "*" : "")}
                                value={failureForm.gearReconnectionTime ?? null}
                                onChange={(newValue) => {
                                    if (Date.parse(newValue && newValue["$d"])) {
                                        let ts = newValue["$d"].getTime();
                                        let rangeErrors = getRangeAndError("disconnectionMemo", "gearReconnectionTime")
                                        checkDateValidation(rangeErrors, ts, 'gearReconnectionTime')
                                        handleChange("gearReconnectionTime", ts);
                                    } else {
                                        if (newValue === null) {
                                            handleChange("gearReconnectionTime", newValue);
                                        }
                                    }
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        error={validationState?.gearReconnectionTime}
                                        helperText={validationState?.gearReconnectionTime}
                                        type="datetime-local"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </>
                )}

                <Autocomplete
                    size="small"
                    className="form-input autocomplete"
                    value={failureForm.relayRoomOpened ?? null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                        handleChange("relayRoomOpened", newValue);
                    }}
                    options={relayRoomOpenDropdowns}
                    getOptionLabel={(params) => {
                        return params.value;
                    }}
                    renderInput={(params) => (
                        <TextField
                            className="form-input textfield"
                            size="small"
                            {...params}
                            label={"Relay Room Open" + (requiredFields.includes("relayRoomOpened") ? "*" : "")}
                            error={validationState?.relayRoomOpened}
                            helperText={validationState?.relayRoomOpened}
                            placeholder="Relay Room Open"
                        />
                    )}
                />
                {failureForm.relayRoomOpened && failureForm.relayRoomOpened.value === "YES" && (
                    <>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DateTimePicker
                                className="form-input dateTimeField"
                                label={"Relay Room Open Timing" + (requiredFields.includes("relayRoomOpenTs") ? "*" : "")}
                                value={failureForm.relayRoomOpenTs ?? null}
                                onChange={(newValue) => {
                                    if (Date.parse(newValue && newValue["$d"])) {
                                        let ts = newValue["$d"].getTime();
                                        let rangeErrors = getRangeAndError("relayRoom", "relayRoomOpenTs")
                                        checkDateValidation(rangeErrors, ts, 'relayRoomOpenTs')
                                        handleChange("relayRoomOpenTs", ts);
                                    } else {
                                        if (newValue === null) {
                                            handleChange("relayRoomOpenTs", newValue);
                                        }
                                    }
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        error={validationState?.relayRoomOpenTs}
                                        helperText={validationState?.relayRoomOpenTs}
                                        type="datetime-local"
                                    />
                                )}
                            />
                            <DateTimePicker
                                className="form-input dateTimeField"
                                label={"Relay Room Closed Timing" + (requiredFields.includes("relayRoomCloseTs") ? "*" : "")}
                                value={failureForm.relayRoomCloseTs ?? null}
                                onChange={(newValue) => {
                                    if (Date.parse(newValue && newValue["$d"])) {
                                        let ts = newValue["$d"].getTime();
                                        let rangeErrors = getRangeAndError("relayRoom", "relayRoomCloseTs")
                                        checkDateValidation(rangeErrors, ts, 'relayRoomCloseTs')
                                        handleChange("relayRoomCloseTs", ts);
                                    } else {
                                        if (newValue === null) {
                                            handleChange("relayRoomCloseTs", newValue);
                                        }
                                    }
                                }}
                                maxDateTime={getMaxRange("relayRoom", "relayRoomCloseTs")}
                                minDateTime={getMinRange("relayRoom", "relayRoomCloseTs")}
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        error={validationState?.relayRoomCloseTs}
                                        helperText={validationState?.relayRoomCloseTs}
                                        type="datetime-local"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextField
                            className="form-input textfield disabledField"
                            value={failureForm.relayRoomOpenDuration ?? ""}
                            size="small"
                            label="Realy Room Opened Duration(In Minutes)"
                            disabled
                        // placeholder="Failure Entry No"
                        />
                    </>
                )}
                {/* <InputLabel>Action Performed</InputLabel>
                <StyledTextarea
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Action Performed"
                /> */}
                <TextField
                    className="form-input textfield"
                    value={failureForm.actionPerformed ?? ""}
                    onChange={(e) => {
                        handleChange("actionPerformed", e.target.value);
                    }}
                    size="small"
                    label={"Action Performed" + (requiredFields.includes("actionPerformed") ? "*" : "")}
                    error={validationState?.actionPerformed}
                    helperText={validationState?.actionPerformed}
                    placeholder="Action Performed"
                />
                <TextField
                    className="form-input textfield"
                    value={failureForm.remarks ?? ""}
                    onChange={(e) => {
                        handleChange("remarks", e.target.value);
                    }}
                    size="small"
                    label="Remarks"
                    placeholder="Remarks"
                />
            </div>
            <AffectedGearTypes
                initialAffectedAssets={failureForm?.affectedAssets ?? INITIAL_AFFECTED_ASSETS}
                getAffectedAssets={getAffectedAssets} selectedStation={failureForm.customerId ? failureForm.customerId : null}
            />
            <DetainedTrains
                initialDetainedTrains={failureForm?.detainedTrains ?? INITIAL_DETAINED_TRAINS}
                getDetainedTrains={getDetainedTrains}
            />
            <div className="sfr-form-actions">
                <Button variant="contained" onClick={() => handleConfirmPopup()}>
                    {isEdit ? 'Update' : 'Submit'}
                </Button>
                {/* <Button variant="outlined" onClick={handleClearForm()}>Clear</Button> */}
            </div>
        </form>
    );
}

export default SFRFORM_Component;
