import React, { useContext, useEffect, useState } from 'react'
import { TcUtilastionContext } from '../TcUtilization'
import { NoData } from '../../../DataGrid/DataGrid'
import { barChartHightModulus } from '../../Constants'
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useImmer } from 'use-immer';
import { Chart } from 'react-chartjs-2';
import { trimAssetName } from '../../../../Common/EndPoints';

const InitialBarOption = {
    responsive: true,
    maintainAspectRatio: false,
    // responsive: true,
    // aspectRatio: 100 / 105,
    indexAxis: 'y',
    // aspectRatio: 5.3 / 1,
    interaction: {
      mode: "nearest",
      axis: "y",
      intersect: false,
      callbacks: {
        intersect: function (a) {
        },
      },
    },
    plugins: {
      // title: {
      //   display: true,
      //   text: 'Chart.js Bar Chart - Stacked'
      // },
      legend: {
        display: false,
        // display: true,
        position: 'top',
        maxHeight: 25,
        maxWidth: 2,
      },
      datalabels: {
        anchor: 'end',
        color: "black",
        align: 'end',
        // offset:30,
        clip: true,
        // align :'end',
        formatter: function (count, chart) {
          // const sum = chart.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
          // let countPer = (count*100)/sum
          // console.log(countPer)
          // return count > 0 ? count : '0'
          return ''
        }
      },
      tooltip: {
        yAlign: "center",
        xAlign: "left",
        responsive: true,
      }
    },
  
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true
      }
    }
  }
  
  const initialChartData = {
    labels: [],
    datasets: [{
      label: 'Normal Count',
      data: [],
      // stack: 0,
      backgroundColor: '#4588f6',
    }]
  }
export default function TcCountUtilizationBarChart({}) {
    const {tcUtilizationData} = useContext(TcUtilastionContext)
    const [chartData, setChartData] = useImmer(initialChartData)
  const [fullScreen , setFullScreen] = useState(false)
  useEffect(() => {
    if (tcUtilizationData) {
      let newlabels = []
      let occupiedCounts = []

   
      tcUtilizationData.forEach((row) => {
        let trimmedAssetName = trimAssetName(row?.device_name)
        newlabels.push(trimmedAssetName)
        occupiedCounts.push(row.cnt_occupied)
        let newChartData = {
          labels: newlabels,
          datasets: [{
            label: 'Occupied Count',
            data: occupiedCounts,
            backgroundColor: '#4588f6',
          }]

        }
        setChartData(newChartData)
      })
    }


  }, [tcUtilizationData])

    const handleFullScreen =()=>{
        setFullScreen(!fullScreen)
          }
  return (
    <div  className={`count-chart card ${fullScreen?'fullScreenCountChart':''}`}>
    {(tcUtilizationData || tcUtilizationData?.length > 0) ? (<> 
    <div className='card-header'>
    <div className='card-heading'>
 Occupied Counts
    </div>
    <div className='fullScreenIcon pointer' onClick={()=>handleFullScreen()}><FullscreenIcon/></div>
    </div>
   
      <div
        style={{ height: tcUtilizationData.length > 40 ? tcUtilizationData.length * barChartHightModulus + "%" : '95%' }}
        className='chart-container'>

        <Chart
          className="type-status-count-chart"
          type="bar"
          data={chartData}
          options={InitialBarOption}
        />
      </div> </>) : (<NoData msg={'Data for Normal/Obstruction count summary is not avalible'} />)}

  </div>
  )
}
