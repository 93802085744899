import { Autocomplete, Button, Chip, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import "./AssetAssignmentForm.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  APP_BASE_URL,
  GET_ASSETS,
  GET_USER_LIST,
  POST_ASSET_ASSIGNMENT_API,
  trimAssetName,
} from "../../../Common/EndPoints";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router";
import { useGetDeviceTypesQuery } from "../../../features/SearchFilters/searchFiltersSlice";
import { toast } from "react-hot-toast";
import { extractAllowedUserRoleList } from "../../../Utils/Utils";
import { useSelector } from "react-redux";
import { selectedCustomersSelector } from "../../../features/authentication/auth-slice";

const initialInputFields = {
  assetsInput: [],
  usersInput: null,
};

const initialDropDowns = {
  ddAssets: [],
  ddUsers: [],
};

const initialFilters = {
  asset: "",
  user: "",
};
const initialddFilters = {
  roleList: [],
  assetTypes: [],
  userRoles: [],
};
function extractCommonFilter(filterValue, dropdown, keyName) {
  // return filterValue.filter((element) => dropdown.includes(element));
  return filterValue.filter((filter) => dropdown.some((dropdown) => filter[keyName] === dropdown[keyName]));
}
function hasDropdownCuurentValue(ddList, ddValue) {
  let hasValue = false;
  ddList.forEach((dd) => {
    if (dd.id === ddValue?.id) {
      hasValue = true;
    }
  });

  return hasValue;
}

function getAllowedUserRoles() {
  let roleHierarchies = JSON.parse(localStorage.getItem("roleHierarchies"));
  let allowedRoleIdList = extractAllowedUserRoleList(roleHierarchies);

  return allowedRoleIdList;
}

function AssetAssignmentForm() {
  const { data: gearTypesList = [] } = useGetDeviceTypesQuery(null);
  const navigate = useNavigate();
  const [inputFields, setInputFields] = useImmer(initialInputFields);
  const [ddList, setDdList] = useImmer(initialDropDowns);
  const [ddFilters, setddFilters] = useImmer(initialddFilters);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useImmer(initialFilters);
  const [filteredData, setFiltredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const selected_customers = useSelector(selectedCustomersSelector);

  useEffect(() => {
    const allowed_user_roles_list = getAllowedUserRoles();
    setddFilters((state) => {
      state.roleList = allowed_user_roles_list;
    });
  }, []);

  useEffect(() => {
    console.log("ddFilters --> ", ddFilters, filter, inputFields ,ddList);    
  }, [ddFilters, filter]);

  useEffect(() => {
    let data = [...tableData];
    let filteredData = data.filter((row, i) => {
      return (
        row.asset.includes(filter.asset.length > 0 ? filter.asset : "") &&
        row.user.includes(filter.user.length > 0 ? filter.user : "")
      );
    });
    setFiltredData(filteredData);
  }, [filter]);

  useEffect(() => {
    if (inputFields.usersInput) {
      if (!hasDropdownCuurentValue(ddList.ddUsers, inputFields.usersInput)) {
        setInputFields((prev) => {
          prev.usersInput = null;
        });
      }
    }
  }, [ddList.ddUsers]);

  useEffect(() => {
    if (inputFields.assetsInput) {
      let commonAssetsInput = extractCommonFilter(inputFields.assetsInput, ddList.ddAssets, "id");
      setInputFields((prev) => {
        prev.assetsInput = commonAssetsInput;
      });
    }
  }, [ddList.ddAssets]);

  const useFiltredData = () => {
    let showFilteredData = false;
    if (filter.asset.length > 0 || filter.user.length > 0) {
      showFilteredData = true;
    }
    return showFilteredData;
  };

  useEffect(() => {
    const params = {
      deviceTypeIds: ddFilters.assetTypes.map((type) => type.id).toString() || null,
      customerIds: selected_customers.map((customer) => customer.customerId).toString(),
    };
    if (inputFields.usersInput) {
      params.user_id = inputFields.usersInput.id;
    }
    getDropDowns("ddAssets", null, GET_ASSETS, params);
  }, [ddFilters.assetTypes, inputFields.usersInput, selected_customers]);

  useEffect(() => {
    const params = { roleIds: null };
    let role_ids = [];
    if (ddFilters.userRoles.length === 0) {
      role_ids = getAllowedUserRoles();
    } else {
      role_ids = ddFilters.userRoles;
    }

    params.roleIds = role_ids.map((role) => role.id).toString();
    params.customerIds = selected_customers.map((customer) => customer.customerId).toString();
    //params.stationNames = selected_customers;

    console.log("ddFilters.userRoles --> ddUsers --> params, api_url --> ", params, GET_USER_LIST);
    //console.log("selected_customers --> ", selected_customers);

    getDropDowns("ddUsers", null, GET_USER_LIST, params);
  }, [ddFilters.userRoles, selected_customers]);

  const getDropDowns = (ddState, listState, url, params) => {
    let URL = `${APP_BASE_URL}${url}`;
    //console.log("getDropDowns --> ", URL, params, ddState);
    axios.get(URL, { params }).then((res) => {
      const RESPONSE = res.data.data;
      let dd = [];
      if (ddState === "ddAssets") {
        dd = RESPONSE.map((device) => ({ id: device.deviceid, label: trimAssetName(device.name) }));
      } else {
        dd = Object.entries(RESPONSE).map(([key, value], i) => {
          return { id: key, label: value };
        });
        dd = [{id: "", label: "[--Select--]"},...dd];
      }
      setDdList((state) => {
        state[ddState] = dd;
      });
    }).catch(error => {
      console.log("Error: ", error);
      setDdList((state) => {
        state[ddState] = [];
      });
    });
  };

  const handleClickAdd = (fieldData) => {
    let table = [];
    // fieldData.usersInput.forEach((userData, i) => {
    fieldData.assetsInput.forEach((assetData, j) => {
      table.push({
        user: fieldData.usersInput.label,
        asset: assetData.label,
        assetId: assetData.id,
        userId: fieldData.usersInput.id,
        loading: false,
      });
    });
    // });
    setTableData([...tableData, ...table]);
    setInputFields(initialInputFields);
  };

  const handleRemoveRow = (removeIndex) => {
    let newTableData = [...tableData];
    if (removeIndex > -1) {
      // only splice array when item is found
      newTableData.splice(removeIndex, 1); // 2nd parameter means remove one item only
    }
    setTableData(newTableData);
  };
  const handleSubmit = (dataCollected) => {
    let postData = {};
    dataCollected.forEach((row) => {
      if (Object.keys(postData).includes(row.userId)) {
        postData[row.userId].push(row.assetId);
      } else {
        postData[row.userId] = [row.assetId];
      }
    });

    let lastIndex = Object.keys(postData).length - 1;
    setLoader(true);
    Object.entries(postData).forEach(([userId, assetIds], i) => {
      createAssignment(userId, assetIds, i, lastIndex);
    });
  };

  const createAssignment = (userId, assetIds, index, lastIndex) => {
    let url = `${APP_BASE_URL}${POST_ASSET_ASSIGNMENT_API}?userIds=${userId.toString()}&assetIds=${assetIds.toString()}`;
    axios
      .post(url)
      .then((res) => {
        if (index === lastIndex) {
          setLoader(false);
          navigate("/asset-assignment");
        }
      })
      .catch((err) => {
        const errMsg = err?.response?.data?.error?.message.slice(0, 15);
        toast.error(errMsg);
        setLoader(false);
      });
  };

  function getAssetsDropdownOptions() {
    if (ddList.ddAssets.length === 0) return [];
    if (inputFields.assetsInput.length === ddList.ddAssets.length) return ["Deselect All", ...ddList.ddAssets];
    return ["Select All", ...ddList.ddAssets];
  }

  function getGearTypeDropdownOptions() {
    if (gearTypesList.length === 0) return [];
    if (ddFilters?.assetTypes.length === gearTypesList.length) {
      return [{ id: "deselect-all", value: "Deselect All" }, ...gearTypesList];
    }
    return [{ id: "select-all", value: "Select All" }, ...gearTypesList];
  }

  const assets_dropdown_options = getAssetsDropdownOptions();
  const gear_type_dropdown_options = getGearTypeDropdownOptions();

  return (
    <div className="asset-assignment-form-conatiner">
      {loader && <Loader />}
      <div className="hedaer-inputs">
        <div className="fields">
          <div className="userRow row">
            <Autocomplete
              size="small"
              className="autocomplete-input"
              multiple
              disableCloseOnSelect
              options={ddFilters.roleList}
              renderInput={(params) => (
                <TextField size="small" {...params} label="User Roles" placeholder="User Roles" />
              )}
              getOptionLabel={(gearType) => gearType.value}
              value={ddFilters?.userRoles}
              onChange={(_, newValue) => {
                setddFilters((state) => {
                  state.userRoles = newValue;
                });
              }}
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />{" "}
                    {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
                  </div>
                );
              }}
            />
            <Autocomplete
              size="small"
              className="autocomplete-input"
              value={inputFields.usersInput}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                setInputFields((prev) => {
                  prev.usersInput = newValue;
                });
              }}
              // multiple
              id="tags-filled"
              options={ddList.ddUsers}
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip variant="outlined" label={value[0].label} {...getTagProps(0)} />
                    {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
                  </div>
                );
              }}
              renderInput={(params) => <TextField size="small" {...params} label="Users" placeholder="Search" />}
            />
          </div>
          <div className="assetRow row">
            <Autocomplete
              size="small"
              className="autocomplete-input"
              multiple
              disableCloseOnSelect
              options={gear_type_dropdown_options}
              renderInput={(params) => (
                <TextField size="small" {...params} label="Gear Types" placeholder="Gear Types" />
              )}
              getOptionLabel={(gearType) => gearType.value}
              value={ddFilters?.assetTypes}
              onChange={(_, newValue) => {
                for (const selected_value of newValue) {
                  if (selected_value.id === "select-all") {
                    setddFilters((state) => {
                      state.assetTypes = gearTypesList;
                    });
                    return;
                  }
                  if (selected_value.id === "deselect-all") {
                    setddFilters((state) => {
                      state.assetTypes = [];
                    });
                    return;
                  }
                }
                setddFilters((state) => {
                  state.assetTypes = newValue;
                });
              }}
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />{" "}
                    {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
                  </div>
                );
              }}
            />
            <Autocomplete
              size="small"
              className="autocomplete-input"
              value={inputFields.assetsInput}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                if (newValue.includes("Select All")) {
                  setInputFields((state) => {
                    state.assetsInput = ddList.ddAssets;
                  });
                  return;
                }

                if (newValue.includes("Deselect All")) {
                  setInputFields((state) => {
                    state.assetsInput = [];
                  });
                  return;
                }
                setInputFields((prev) => {
                  prev.assetsInput = newValue;
                });
              }}
              multiple
              id="tags-filled"
              options={assets_dropdown_options}
              freeSolo
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip variant="outlined" label={value[0].label} {...getTagProps(0)} />
                    {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
                  </div>
                );
              }}
              renderInput={(params) => <TextField size="small" {...params} label="Assets" placeholder="Search" />}
            />
          </div>
        </div>

        {/* <Autocomplete
          size="small"
          className="autocomplete-input"
          value={inputFields.usersInput}
          disableCloseOnSelect
          onChange={(event, newValue) => {
            setInputFields((prev) => {
              prev.usersInput = newValue;
            });
          }}
          multiple
          id="tags-filled"
          options={ddList.ddUsers}
          renderTags={(value, getTagProps) => {
            return (
              <div>
                <Chip variant="outlined" label={value[0].label} {...getTagProps(0)} />
                {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
              </div>
            );
          }}
          renderInput={(params) => <TextField size="small" {...params} label="Users" placeholder="Search" />}
        />
        <Autocomplete
          size="small"
          className="autocomplete-input"
          value={inputFields.assetsInput}
          disableCloseOnSelect
          onChange={(event, newValue) => {
            setInputFields((prev) => {
              prev.assetsInput = newValue;
            });
          }}
          multiple
          id="tags-filled"
          options={ddList.ddAssets}
          freeSolo
          renderTags={(value, getTagProps) => {
            return (
              <div>
                {" "}
                <Chip variant="outlined" label={value[0].label} {...getTagProps(0)} />{" "}
                {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
              </div>
            );
          }}
          renderInput={(params) => <TextField size="small" {...params} label="Assets" placeholder="Search" />}
        /> */}
        <div className="addBtn">
          <Button
            variant="contained"
            onClick={() => {
              handleClickAdd(inputFields);
            }}
          >
            ADD
          </Button>
        </div>
      </div>
      <div className="preview-container">
        <table>
          <thead>
            <tr>
              <th>
                <span>User</span>
                <span>
                  <input
                    type="text"
                    placeholder="search user"
                    value={filter.user}
                    onChange={(e) => {
                      setFilter((prev) => {
                        prev.user = e.target.value;
                      });
                    }}
                  />
                </span>
              </th>
              <th>
                <span>Asset</span>
                <span>
                  <input
                    type="text"
                    placeholder="search asset"
                    value={filter.asset}
                    onChange={(e) => {
                      setFilter((prev) => {
                        prev.asset = e.target.value.toUpperCase();
                      });
                    }}
                  />
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(useFiltredData() ? filteredData : tableData).map((row, i) => {
              return (
                <tr key={i}>
                  <td>{row.user}</td>
                  <td>{row.asset}</td>
                  <td className="pointer">
                    {row.loading ? (
                      <div className="lds-ring">
                        <div></div>
                      </div>
                    ) : (
                      <IconButton
                        onClick={() => {
                          handleRemoveRow(i);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="footer">
        <Button
          onClick={() => {
            setFilter(initialFilters);
          }}
          sx={{ display: useFiltredData() ? "static" : "none" }}
          variant="outlined"
        >
          Clear Filters
        </Button>
        <Button
          sx={{ display: tableData.length > 0 ? "static" : "none" }}
          onClick={() => {
            setTableData([]);
          }}
          variant="outlined"
        >
          Clear all entries
        </Button>
        <Button
          variant="contained"
          sx={{ display: tableData.length > 0 ? "static" : "none" }}
          onClick={() => {
            handleSubmit(tableData);
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default AssetAssignmentForm;