
const keyUnit = {
  'Feed Current': 'mA',
  'Relay Current': 'mA',
  'Charger Output': 'v',

}
export const RADAR_OPTION = {

  interaction: {
    mode: "nearest",
    intersect: false,

    callbacks: {
      intersect: function (a) { },
    },
  },
  scales: {
    r: {
      maxRotation: 90,
      minRotation: 90,
      grid: {
      },
      pointLabels: {
        font: {
          size: 12,
        },
        color: 'black',
        maxRotation: 90,
        minRotation: 90
      },
      ticks: {
        color: 'blue'
      }
    },
  },
  plugins: {
    tooltip: {
      yAlign: "bottom",
      xAlign: "center",
      responsive: true,

      callbacks: {
        label: function (tooltipItem, data) {
          if (tooltipItem.dataset.id === 'enrgization') {
            return tooltipItem.label + ' : ' + tooltipItem.formattedValue + '%';
          } else {
            return tooltipItem.label + ' : ' + tooltipItem.formattedValue + ` (${keyUnit[tooltipItem.dataset.id]})`
          }

        },
        title: function (tooltipItem, data) {
          return tooltipItem[0].dataset.id
        }
      },
    },
    legend: {
      display: false,
    },
  }
};