import { ReactComponent as NOQ } from "./../SVG/NOQ_Station.svg";
import { ReactComponent as DLO } from "./../SVG/DLO_Station.svg";
import { ReactComponent as NHLG } from "./../SVG/NHLG_Station.svg";
import { ReactComponent as LKR } from "./../SVG/LKR_Station.svg";
import { ReactComponent as AUB } from "./../SVG/AUB_Station.svg";
import { ReactComponent as NHGJ } from "./../SVG/NHGJ_Station.svg";
import { ReactComponent as MBG } from "./../SVG/MBG_Station.svg";
import { ReactComponent as MSV } from "./../SVG/MSV_Station.svg";
import { ReactComponent as MIU } from "./../SVG/MIU_Station.svg";
import { ReactComponent as KTCE } from "./../SVG/KTCE_Station.svg";
import { ReactComponent as FTP } from "./../SVG/FTP_Station.svg";
import { ReactComponent as VS } from "./../SVG/VS_Station.svg";
import { ReactComponent as BCA } from "./../SVG/BCA_Station.svg";
import NoSVgComponent from "../SVG/NoSVgComponent";

// export const CUSTOMER_ID_DATA = {
//     "63082280-fddd-11ec-a13d-73d65bc9a6b0": {
//         name: "NOQ",
//         SVG: <NOQ />,
//         NAME_CHANGE_REQUIRED: true,
//         NAME_CHANGE_REQUIRED_DEVICE_TYPES: ["AXL", 'AC'],
//     }, //NOQ
//     "c6dd9c30-37f9-11ed-9c2e-1d1be10ca79c": { name: "DLO", SVG: <DLO /> },
//     "9bab46a0-6965-11ed-adec-d93a931de5d5": { name: "NHLG", SVG: <NHLG /> },
//     "52d82a50-f153-11eb-b50e-0dffb4290152": { name: "LKR", SVG: <LKR /> },
//     NOT_AVALIBILE: { name: null, SVG: <NoSVgComponent /> },
// };

export const SELECTED_CUSTOMER_SVG = {
  NOQ: <NOQ />,
  DLO: <DLO />,
  NHLG: <NHLG />,
  LKR: <LKR />,
  AUB: <AUB />,
  NHGJ: <NHGJ />,
  MBG: <MBG />,
  MSV: <MSV />,
  MIU: <MIU />,
  KTCE: <KTCE />,
  VS: <VS />,
  BCA: <BCA />,
  // FTP: <FTP />,
};

// export const CHANGED_NAME = {
//     NOQ: {
//         AXL: {
//             UPBPAC4: "NBS_UP2",
//             UPBPAC3: "NBS_UP1",
//             DNBPAC3: "NBS_DN1",
//             DNBPAC4: "NBS_DN2",
//             DNBPAC1: "SMTA_DN1",
//             DNBPAC2: "SMTA_DN2",
//             UPBPAC1: "SMTA_UP1",
//             UPBPAC2: "SMTA_UP2",
//         },
//         AC: {
//             UPBPAC4: "NBS_UP2",
//             UPBPAC3: "NBS_UP1",
//             DNBPAC3: "NBS_DN1",
//             DNBPAC4: "NBS_DN2",
//             DNBPAC1: "SMTA_DN1",
//             DNBPAC2: "SMTA_DN2",
//             UPBPAC1: "SMTA_UP1",
//             UPBPAC2: "SMTA_UP2",
//         },
//     },
// };

// export const Colors = {
//     baseColor: "#6c7091",
//     redColor: "#FF0000", //red
//     orangeColor: "#E77E00", //orange
//     normal: "#21E074", //green
//     reverse: "#0088D1", // bulue
//     whiteColor: "#ffffff",
//     yellowColor: "yellow",
//     blackColor: "black",

// };

// export const Constant = {
//     IPS_checkThreshold: 35,
//     IPS_12Min: 9,
//     IPS_12Max: 15,
//     IPS_24Min: 24,
//     IPS_24Max: 33,
//     IPS_60Min: 54,
//     IPS_60Max: 66,
//     IPS_110Min: 110,
//     IPS_110Max: 140,
//     IPS_220Min: 220,
//     IPS_220Max: 240,
// };

// export const ConstantIPS = [
//     [Constant.IPS_12Min, Constant.IPS_12Max],
//     [Constant.IPS_24Min, Constant.IPS_24Max],
//     [Constant.IPS_60Min, Constant.IPS_60Max],
//     [Constant.IPS_110Min, Constant.IPS_110Max],
//     [Constant.IPS_220Min, Constant.IPS_220Max],
// ];

export const SVG_COLORS = {
  // occupied: 'orange',
  occupied: "#E77E00",
  unocuppied: "white",
  normal: "#21E074",
  reverse: "#0088D1",
  pmFailure: "red",
  signalYellow: "yellow",
  signalGreen: "#00FF00",
  signalRed: "red",
  acRed: "red",
  acOrange: "orange",
  acGreen: "#21E074",
  boomCloseColor: "red",
  boomOpenColor: "green",
  blackColor: "black",
  bhmsGreen: "green",
  bhmsRed: "red",
  bhmsOrange: "orange",
  bbGreen: "green",
  bbRed: "red",
  bhmsTextGreen: "#21E074",
};

export const SINGLE_SELECTOR_GEARTYPE = ["TC", "AC", "AXL", "BB", "PM", "IPS"];

export const BAT_CODES = {
  range: ["VBAT1", "VBAT2", "VBAT3", "VBAT4", "VBAT5", "VBAT6", "VBANK"],
  individual: [
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "B6",
    "B7",
    "B8",
    "B9",
    "B10",
    "B11",
    "B12",
    "B13",
    "B14",
    "B15",
    "B16",
    "B17",
    "B18",
    "B19",
    "B20",
    "B21",
    "B22",
    "B23",
    "B24",
    "B25",
    "B26",
    "B27",
    "B28",
    "B29",
    "B30",
    "B31",
    "B32",
    "B33",
    "B34",
    "B35",
    "B36",
    "B37",
    "B38",
    "B39",
    "B40",
    "B41",
    "B42",
    "B43",
    "B44",
    "B45",
    "B46",
    "B47",
    "B48",
    "B49",
    "B50",
    "B51",
    "B52",
    "B53",
    "B54",
    "B55",
    "VBAT",
    "IBAT",
  ],
  type: "divide",
};

const COLUMN_TYPES = {
  NAME: "simple",
  LOC: "simple",
  ICO: "simple",
  IF: "simple",
  IR: "simple",
  TR: "simple",
  VER: "simple",
  VCO: "divide",
  VCI: "divide",
  VF: "divide",
  VR: "divide",
  TIME: "time",
  DATE: "simple",
  IS: "simple",
  VS: "divide",
  IRG: "simple",
  IDG: "simple",
  IHG: "simple",
  IHHG: "simple",
  VRG: "divide",
  VDG: "divide",
  VHG: "divide",
  PL: "divide",
  "24DC": "divide",
  "110DC": "divide",
  "110AC": "divide",
  "6DC": "divide",
  "60DC": "divide",
  "12DC": "divide",
  OC: "divide",
  OT: "divide",
  NWKR: "divide",
  RWKR: "divide",
  RNWKR: "divide",
  RRNWKR: "divide",
  V1: "divide",
  MOD: "simple",
  RX1: "simple",
  RX2: "simple",
  TX1: "divide",
  TX2: "divide",
  VBAT1: "divide",
  VBAT2: "divide",
  VBAT3: "divide",
  VBAT4: "divide",
  VBAT5: "divide",
  VBAT6: "divide",
  VBANK: "divide",
  VPWR: "divide",
  RST: "divide",
  PR: "divide",
  IPWR: "simple",
  AO: "divide",
  BO: "divide",
  ACL: "divide",
  BCL: "divide",
  EMRSR: "divide",
  CHR: "divide",
  VON: "divide",
  VOFF: "divide",
  AR: "divide",
  BR: "divide",
  OperationType: "simple",
  DRYCH: "simple",
  TPR: "divide",
  DIR: "simple",
  IBAT: "divide",
  VNO: "divide",
  VRO: "divide",
};

export const ColumnsType = () => {
  BAT_CODES.individual.forEach((key) => {
    COLUMN_TYPES[key] = "divide";
  });
  return COLUMN_TYPES;
};

const COLUMN_KEYS = {
  TC: ["ICO", "IF", "IR", "TPR", "VCO", "VCI", "VF", "VR"],
  TRACK: ["ICO", "IF", "IR", "TPR", "VCO", "VCI", "VF", "VR"],
  SG: {
    SHUNT: ["PL", "VON", "VOFF"],
    HG: ["IS", "VS", "PL"],
    NORMAL: ["IRG", "IDG", "IHG", "IHHG", "VRG", "VHG", "VDG"],
  },
  BB: ["24DC", "110DC", "110AC", "6DC", "60DC", "12DC"],
  // PM: ["OT", "NWKR", "RWKR", "RNWKR", "RRWKR"],
  PM: ["OT", "DIR"],
  AXL: ["VPWR", "VR", "PR", "RST"],
  AC: ["VPWR", "VR", "PR", "RST"],
  IPS: ["V1"],
  BAT: ["VBAT1", "VBAT2", "VBAT3", "VBAT4", "VBAT5", "VBAT6", "VBANK"],
  LCG: ["AR", "BR"],
  AXLLKR: ["MOD", "RX1", "RX2", "TX1", "TX2"],
  PMVS: ["OT", "DIR", "OC", "VNO", "VRO", "NWKR", "RWKR"],
  PMBCA: ["OT", "DIR", "OC", "VNO", "VRO", "NWKR", "RWKR"],
  ELD: ["DRYCH"],
};

export const ColumnKeys = () => {
  BAT_CODES.individual.forEach((key, i) => {
    let keyLIst = [key]
    COLUMN_KEYS['BHMSBHMSICON' + (i + 1)] = keyLIst
  })
  return COLUMN_KEYS
}

export const checkIsValidValue = (keyValue) => {
  let isValid = false
  if (keyValue && (Number(keyValue) || Number(keyValue) === 0)) {
    isValid = true
  }
  return isValid
}

