import React from 'react'
import { useLocation } from 'react-router-dom'
import PmUtilisation from './Pm Utilsiation/PmUtilisation'
import TcUtilization from './TC/TcUtilization'

function AssetUtilization() {
    const location = useLocation()
  const assetType = location.pathname.split('/')[2]
  return(
    <>
    {assetType==='point-machine' && (
        <PmUtilisation assetType={assetType}/>
       )}
    {assetType==='track-circuit' && (
        <TcUtilization assetType={assetType}/>
       )}
       </>
  )
  
}

export default AssetUtilization
