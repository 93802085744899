import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { Transition } from './ModalConstants'
import CircularProgress from '@mui/material/CircularProgress';



function ConfirmationModal({ open, onClose, onAction, message, des, loading }) {

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle>{message}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {des}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => { onAction(false) }}>Cancel</Button>
        <Button variant='contained' onClick={() => { onAction(true) }}>{loading ? <CircularProgress sx={{ width: '70% !important', height: '70% !important', color: 'white' }} size={'small'} className="btnLoader" /> : 'Confirm'}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
