import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router";
import { Alert, Button, Snackbar } from "@mui/material";

import DataTable from "../DataGrid/DataGrid";
import { ASSIGNMENT_TABLE_COLUMN, getFormattedDate } from "./Constant/Constants";
import { APP_BASE_URL, FORM_ASSIGNMENT_API } from "../../Common/EndPoints";
import "./FormAssignment.scss";
import DeleteModal from "../../Modal/DeleteModal";
import { toast } from "react-hot-toast";

const initialformAssignmentState = {
  tableRows: [],
};

const peridiocityObj = {
  7: "Weekly",
  14: "Fortnightly",
  30: "Monthly",
  90: "Quarterly",
  180: "Half-yearly",
  365: "Yearly",
};
const snackInitialState = { open: false, message: "", severity: "" };
const initDeleteState = {
  open: false,
  assignmentId: null
}
function FormAssignment() {
  const access = window.frontendAccess?.FORM_ASSIGNMENT;
  const allowCreateFormAssignment = access?.create ?? true;
  const allowUpdateFormAssignment = access?.update ?? true;
  const navigate = useNavigate();
  const [FormAssignmentState, setFormAssignmentState] = useImmer(initialformAssignmentState);
  const [deleteModel, setDeleteModel] = useImmer(initDeleteState)
  const [snackBar, setSnackBar] = useImmer({ open: false, message: "", severity: "" });
  const handleClickAssign = () => {
    navigate("/assignment-form");
  };
  function navigateToStationGroup() {
    navigate("/station-group");
  }
  useEffect(() => {

    getScheduleList()

  }, []);

  useEffect(() => {
    if (localStorage.getItem("form-crud-status")) {
      let form_crud_status = localStorage.getItem("form-crud-status");
      if (form_crud_status) {
        if (form_crud_status === "updated successfully" || form_crud_status === "created successfully") {
          toast.success("Assignment" + " " + form_crud_status)
          // setSnackBar((prev) => {
          //   prev.open = true;
          //   prev.severity = "success";
          //   prev.message = "Assignment" + " " + form_crud_status;
          // });
        } else {
          toast.error("Assignment" + " " + form_crud_status)
          // setSnackBar((prev) => {
          //   prev.open = true;
          //   prev.severity = "error";
          //   prev.message = form_crud_status;
          // });
        }
        localStorage.removeItem("form-crud-status");
      }
    }
  }, []);
  const [pageSize, setPageSize] = useState(20);
  const handlePageSizeChange = useCallback((size) => setPageSize(size));
  const tableSize = useMemo(() => pageSize, [pageSize]);

  const handleDelete = (assignmentId) => {
    setDeleteModel((prev) => {
      prev.open = true
      prev.assignmentId = assignmentId
    })

  }
  const getScheduleList = () => {
    const url = `${APP_BASE_URL}${FORM_ASSIGNMENT_API}?withCreatedBy=true&withCustomerGroup=true&withMaintenanceForm=true&withScheduleCode=true&withUserRole=true`;
    axios.get(url).then((res) => {
      // let tableRows = []
      let RESPONSE = res.data.data;
      let tableRows = RESPONSE.map((data, i) => {
        let editState = {
          scheduleCodes: { key: data.scheduleCode.id, value: data.scheduleCode.code },
          forms: { key: data.maintenanceForm.id, value: data.maintenanceForm.name },
          stationGroup: { key: data.customerGroup.id, value: data.customerGroup.name },
          role: { id: data.userRole.roleId, value: data.userRole.rolename },
          peridiocity: { key: data.periodicity, value: peridiocityObj[data.periodicity] },
          // offset: '',
          dueAssignment: data.dueOffset,
          startDate: getFormattedDate(data.startedDt),
          gearType: { id: data.scheduleCode.deviceType.typeId, value: data.scheduleCode.deviceType.label },
          active: data.active,
          assignmentId: data.id,
        };
        return {
          id: i,
          createdDate: data.createdDt,
          scheduleCode: data.scheduleCode.code,
          gearType: data.scheduleCode.deviceType.label,
          formAssigned: data.maintenanceForm.name,
          startedDate: data.startedDt,
          assignedUseRole: data.userRole.rolename,
          AssignedGroup: data.customerGroup.name,
          createdById: data.createdById,
          createdDt: data.createdDt,
          customerGroupId: data.customerGroupId,
          dueOffset: data.dueOffset,
          assignmentId: data.id,
          maintenanceFormId: data.maintenanceFormId,
          periodicity: data.periodicity,
          scheduleCodeId: data.scheduleCodeId,
          startedDt: data.startedDt,
          upcomingOffset: data.upcomingOffset,
          userRoleId: data.userRoleId,
          active: data.active,
          editState: editState,
        };

        // setFormAssignmentState((prev)=>{
        //     prev.tableRows = RESPONSE
        // })
      });
      setFormAssignmentState((prev) => {
        prev.tableRows = tableRows;
      });
    });
  }
  const deleteAssignment = () => {
    let url = `${APP_BASE_URL}${FORM_ASSIGNMENT_API}/${deleteModel.assignmentId}/delete`
    axios.delete(url).then(() => {
      toast.success('assignment deleted successfully')
    }).catch((err) => {
      console.log(err)
      const errMsg = err?.response?.data?.error?.message;
      toast.error(errMsg)
    }).then(() => {
      setDeleteModel(initDeleteState)
      getScheduleList()
    })
    // console.log(url)
  }
  const handleCloseDeleteModel = () => {
    setDeleteModel(initDeleteState)
  }
  return (
    <div className="form-assignment-container">
      <div className="header">
        {allowCreateFormAssignment && (
          <div className="flex items-center gap-2">
            <Button variant="outlined" onClick={navigateToStationGroup}>
              Station Groups
            </Button>
            <Button
              onClick={() => {
                handleClickAssign();
              }}
              variant="contained"
              className="assignment-form-btn"
            >
              New Assignment
            </Button>
          </div>
        )}
      </div>
      <div className="form-assignment-table-container">
        <DataTable
          loading={false}
          tableInfo={{
            id: `form-assignment-table`,
            label: "form assignment",
            requireAuthSign: false,
          }}
          pageSize={tableSize}
          pageSizeChange={handlePageSizeChange}
          rows={FormAssignmentState.tableRows}
          columns={ASSIGNMENT_TABLE_COLUMN(allowUpdateFormAssignment, handleDelete)}
        // rowColor={(params) => {
        //   if (!params.row.active) {
        //     return 'disabledSchedule'
        //   }
        // }}
        />
      </div>
      <Snackbar open={snackBar.open} autoHideDuration={2500} onClose={() => setSnackBar(snackInitialState)}>
        <Alert
          onClose={() => {
            setSnackBar(snackInitialState);
          }}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <DeleteModal
        open={deleteModel.open}
        onClose={handleCloseDeleteModel}
        willDelete={() => deleteAssignment()}
        deleteMessageFor={'Schedule'}
      />
    </div>
  );
}

export default FormAssignment;
