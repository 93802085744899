import React, { useContext, useEffect, useState } from 'react'
import { Chart as ChartJS } from "chart.js";
import { Chart } from 'react-chartjs-2';
import { PmUtilastionContext } from '../PmUtilisation'
import { useImmer } from 'use-immer';
import NoData from '../../../../Utils/NoDataComponent.js/NoData';
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { barChartHightModulus } from '../../Constants';
import { trimAssetName } from '../../../../Common/EndPoints';


const InitialBarOption = {
  responsive: true,
  maintainAspectRatio: false,
  // responsive: true,
  // aspectRatio: 100 / 105,
  indexAxis: 'y',
  // aspectRatio: 5.3 / 1,
  interaction: {
    mode: "nearest",
    axis: "y",
    intersect: false,
    callbacks: {
      intersect: function (a) {
      },
    },
  },
  plugins: {
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart - Stacked'
    // },
    legend: {
      // display: false,
      display: true,
      position: 'top',
      maxHeight: 25,
      maxWidth: 2,
    },
    datalabels: {
      anchor: 'end',
      color: "black",
      align: 'end',
      // offset:30,
      clip: true,
      // align :'end',
      formatter: function (count, chart) {
        // const sum = chart.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
        // let countPer = (count*100)/sum
        // console.log(countPer)
        // return count > 0 ? count : '0'
        return ''
      }
    },
    tooltip: {
      yAlign: "center",
      xAlign: "left",
      responsive: true,
    }
  },

  scales: {
    x: {
      stacked: true,
      grid: {
        display: false
      }
    },
    y: {
      stacked: true
    }
  }
}

const initialChartData = {
  labels: [],
  datasets: [{
    label: 'Normal Count',
    data: [],
    // stack: 0,
    backgroundColor: '#4588f6',
  }]
}
// const chartHieghtModulus = 95 / 40
function StackCountChart({ countData }) {
  const { summaryData } = useContext(PmUtilastionContext)
  const [chartData, setChartData] = useImmer(initialChartData)
  const [chartHeight, setChartHeight] = useState('95%')
  const [fullScreen , setFullScreen] = useState(false)
  // console.log('summarydata in chart',summaryData)
  useEffect(() => {
    if (summaryData) {
      let newlabels = []
      let normalCounts = []
      let obstructionCounts = []

      if (summaryData.length > 40) {
        let newChatHeight
        setChartHeight('110%')
      }
      summaryData.forEach((row) => {
        let trimmedAssetName = trimAssetName(row.device_name)
        newlabels.push(trimmedAssetName)
        normalCounts.push(row.cnt_normal_op)
        obstructionCounts.push(row.cnt_obstruction_op)
        let newChartData = {
          labels: newlabels,
          datasets: [{
            label: 'Normal Count',
            data: normalCounts,
            stack: 0,
            backgroundColor: '#4588f6',
          },
          { label: 'Obstruction Count', data: obstructionCounts, stack: 0, backgroundColor: 'red', }]

        }
        setChartData(newChartData)
      })
    }


  }, [summaryData])
  const handleFullScreen =()=>{
    setFullScreen(!fullScreen)
      }
  return (
    <div  className={`count-chart card ${fullScreen?'fullScreenCountChart':''}`}>
      {(summaryData || summaryData?.length > 0) ? (<> 
      <div className='card-header'>
      <div className='card-heading'>
        Normal/Obstruction count summary
      </div>
      <div className='fullScreenIcon pointer' onClick={()=>handleFullScreen()}><FullscreenIcon/></div>
      </div>
     
        <div
          style={{ height: summaryData.length > 40 ? summaryData.length * barChartHightModulus + "%" : '95%' }}
          className='chart-container'>

          <Chart
            className="type-status-count-chart"
            type="bar"
            data={chartData}
            options={InitialBarOption}
          />
        </div> </>) : (<NoData msg={'Data for Normal/Obstruction count summary is not avalible'} />)}

    </div>
  )
}

export default StackCountChart
