import React, { useContext, useEffect, useState } from 'react'
import { PmUtilastionContext } from '../PmUtilisation'
import { Chart as ChartJS } from "chart.js";
import { Chart } from 'react-chartjs-2';
import { Colors } from '../../../Telemetry/Constant';
// import { PmUtilastionContext } from '../PmUtilisation'
import { useImmer } from 'use-immer';
import NoData from '../../../../Utils/NoDataComponent.js/NoData';
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { trimAssetName } from '../../../../Common/EndPoints';
import { convertSecondsToDuration } from '../../Constants';

const chartTypeKey = {
  Average_runtime: 'avg_ot',
  Total_runtime: 'total_ot',
}
const INITIAL_PIE_DATA = {
  "labels": [
    "DLO-14",
  ],
  "datasets": [
    {
      "label": "Maintenance Count",
      "data": [
        738,
      ],
      "backgroundColor": [
        "#c3d244",
      ],
      "hoverOffset": 4
    }
  ]
}


// const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

// const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

function stringToColor(str) {
  str = str.split('').reverse().join("")
  var hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var colour = "#";
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}
const pieChartOption = (showDataLabels) => {
  return ({

    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 30,
        bottom: 30
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    //     aspectRatio: function(a,b,c){
    //     },
    aspectRatio: 3 / 2,
    // onClick: function (chartDAta) {
    //     let maintenanceType = chartDAta.chart.tooltip.body[0].lines[0].split(':')[0]
    //     setMaintenaceInfoModel((prev) => {
    //         prev.open = true
    //         // prev.info = RESPONSE
    //         prev.type = maintenanceType
    //     })
    //   },
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart - Stacked',
        align: 'right'
      },
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        color: "black",
        align: 'end',
        // offset:30,
        // clip: true,
        display: 'auto',
        // align :'end',
        formatter: function (value, context) {

          // return context.dataset.data.length<35 ? context.chart.data.labels[
          //   context.dataIndex
          // ]:''
          return context.chart.data.labels[
            context.dataIndex
          ]

        },
      },
      tooltip: {
        yAlign: "center",
        xAlign: "left",
        responsive: true,
        callbacks: {
          label: function (tooltipItem, data) {
            let timeStamp = tooltipItem.formattedValue.replace(/,/g, '')
            timeStamp = Number(timeStamp)
            let newLabel = convertSecondsToDuration(timeStamp)
            return tooltipItem.label + ': ' + newLabel
          },

        },
      }

    }
  })
}

function UtilisationPieChartTime({ runTimeType }) {
  const { summaryData } = useContext(PmUtilastionContext)
  const [pieData, setPieData] = useImmer(INITIAL_PIE_DATA)
  const [fullScreen, setFullScreen] = useState(false)
  const [showDataLabels, setShowPieDataLabels] = useState(false)
  useEffect(() => {
    if (summaryData) {
      let newLabels = []
      let newData = []
      let colors = []
      summaryData.forEach((row, i) => {
        let trimmedAssetName = trimAssetName(row.device_name)

        newLabels.push(trimmedAssetName)
        newData.push(row?.[chartTypeKey[runTimeType]])
        // colors.push(Colors[i])
        colors.push(stringToColor(row?.device_id))

      })
      let newChartData = {
        labels: newLabels,
        datasets: [{
          label: 'Maintenance Count',
          data: newData,
          backgroundColor: colors,
          hoverOffset: 4
        }]
      }
      setPieData(newChartData)
    }

  }, [summaryData])
  useEffect(() => {
    if (!pieData.dummy) {
      setShowPieDataLabels(true)

    }
  }, [pieData])

  const handleFullScreen = () => {
    setFullScreen(!fullScreen)
  }
  return (
    <div className={`runtime-chart-card card ${runTimeType}  ${fullScreen ? 'fullScreen' : ''}`}>
      {(summaryData || summaryData?.length > 0) ? (<>
        <div className='card-header'>
          <div className='card-heading'>
            {runTimeType.split('_')[0] + ' ' + runTimeType.split('_')[1].charAt(0).toUpperCase() + runTimeType.split('_')[1].slice(1)}
          </div>
          <div className='fullScreenIcon pointer' onClick={() => handleFullScreen()}><FullscreenIcon /></div>
        </div>

        <div className='chart-container'>
          <Chart
            className="pie-count-chart"
            type="doughnut"
            data={pieData}
            options={pieChartOption(showDataLabels)}
          // options={CHART_OPTIONS(handleActionBtn)}
          />
        </div></>) : (<NoData msg={`Data for  ${runTimeType.split('_').join("-")} is not avalible`} />)}

    </div>
  )
}

export default UtilisationPieChartTime
