import { useState, useContext, useEffect, useLayoutEffect, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  ListItemButton,
} from "@mui/material";
import axios from "axios";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

import {
  APP_BASE_URL,
  GET_ATTACHMENT_BY_ID,
  GET_COLLECTION_MAP_ID,
  GET_ASSET_FORM,
  APP_BASE_URL_ALARM,
} from "./../../../Common/EndPoints";
import { AssetsData } from "../AssetsProvider";
import { Transition } from "../../../Modal/ModalConstants";
import CloseIcon from "@mui/icons-material/Close";
import noImage from "./noImage.jpg";
import "./InfoPopup.scss";
import { showSection } from "./FormRender";
import { getGenericAttachment } from "./assetRegisterUtils";
import { toast } from "react-hot-toast";
import { downloadFile } from "../../../Utils/Utils";

const keys = {
  codal: "Codal Life (In Years)",
  code: "Asset Code",
  connected: "Connected to",
  division: "Division",
  gear: "Gear Position",
  gearType: "Gear type",
  gear_name: "Gear Name",
  install: "Installation Date",
  lat: "Latitude",
  lon: "Longitude",
  maintenance: "Maintenance Start Date",
  make: "Make",
  manufacture: "Manufactured Date",
  model: "model",
  platform: "Platform Number",
  po: "PO Number",
  pole: "Pole KM",
  purchase: "Purchased Date",
  remarks: "Remarks",
  rfid: "RFID",
  serial: "Serial Number",
  station: "Station",
  subType: "Sub Type",
  warrenty: "Warrenty Expiry Date",
  zone: "Zone",
};

const NavigationTabs = ({ activeTab, setActiveTab, additionalFormData, setSnackbarMessage }) => {
  const [additionalInfo, setAdditionalInfo] = useState(false);
  useEffect(() => {
    if (additionalFormData.length > 0) {
      let responsesCount = 0;
      additionalFormData.forEach((data) => {
        if (data.responses) {
          responsesCount += 1;
        }
      });
      if (responsesCount > 0) {
        setAdditionalInfo(true);
      }
    }
  }, [additionalFormData]);
  const _tabs = ["General Info"];
  if (additionalInfo) _tabs.push("Additional Info");
  return (
    <div className="assets-info-popup-navigation-tabs flex">
      {_tabs.map((key, index) => {
        return (
          <div
            key={key + index}
            className={`pointer info-popup-navigation-tab ${activeTab === index && "info-popup-navigation-tab-active"}`}
            onClick={() => setActiveTab(index)}
          >
            {key}
          </div>
        );
      })}
    </div>
  );
};
const Attachments = ({ attachmentsData }) => {
  return (
    <div className="object-data-viewer-list-item flex spaceBetween">
      <span className="object-data-viewer-list-item-key">Attachments</span>
      <div className="object-data-viewer-list-item-separator">:</div>
      <span className="object-data-viewer-list-item-value flex flexDirectionColumn">
        {attachmentsData.map((file, index) => {
          const { name, path, type } = file;
          if (type !== "Gear Type Image") {
            return (
              <a
                className="assets__info__attachment--link cursor-pointer"
                href={`${APP_BASE_URL}${GET_ATTACHMENT_BY_ID}/${file.id}?isDownload=true`}
              >
                {file.name}
              </a>
            );
          }
        })}
      </span>
    </div>
  );
};

function handleAttachmentDownload({ url, name }) {
  const toastId = toast.loading("Exporting...");
  axios
    .get(url, { responseType: "blob" })
    .then(
      (response) => {
        const data = response.data;
        downloadFile({ data, name });
      },
      async (err) => {
        const text = JSON.parse(await err.response.data.text());
        toast.error(text.error);
      },
    )
    .then(() => {
      toast.dismiss(toastId);
    });
}

const AttachmentNode = ({ fieldData, form_values }) => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    const ids = form_values?.[fieldData.key] ?? [];
    if (ids.length === 0) return;
    const params = { ids: ids.toString(), entity: "form" };
    getGenericAttachment(params).then((res) => {
      setFiles(res.data);
    });
  }, []);
  // const fieldValue = form_values[fieldData.key]?.toString();

  return (
    <li key={fieldData.key} className="object-data-viewer-list-item">
      <span className="object-data-viewer-list-item-key">{fieldData.label}</span>
      <span className="object-data-viewer-list-item-value flex flex-col">
        {files.map((file, index) => (
          <span className="flex gap-2 items-center">
            {index + 1}.
            <div
              className="assets__info__attachment--link cursor-pointer"
              onClick={() => {
                const url = `${APP_BASE_URL_ALARM}/${file.path}`;
                handleAttachmentDownload({ url, name: file.name });
              }}
            >
              {file.name}
            </div>
          </span>
        ))}
      </span>
    </li>
  );
};

const RenderField = ({ fieldData, form_values }) => {
  switch (fieldData.type) {
    case "section": {
      return (
        <div className="assets__info-popup__section" key={fieldData.key}>
          <h4 className="assets__info-popup__section--title">{fieldData.label}</h4>
          {fieldData.sectionFields.map((field) => (
            <RenderField fieldData={field} form_values={form_values} key={field.key} />
          ))}
        </div>
      );
    }
    case "reactive-section":
    case "reactive_section": {
      const reactiveValue = fieldData.reactiveValue;
      const parentKey = fieldData.reactiveKey;
      const parentValues = form_values[parentKey];
      if (showSection({ parentValues, reactiveValue })) {
        return (
          <div className="assets__info-popup__section" key={fieldData.key}>
            <h4 className="assets__info-popup__section--title">{fieldData.label}</h4>
            {fieldData.sectionFields.map((field) => (
              <RenderField fieldData={field} form_values={form_values} key={field.key} />
            ))}
          </div>
        );
      }
      return <></>;
    }
    case "reactive_dropdown":
    case "reactive-dropdown": {
      let showDropdown = fieldData.showInitially;
      const reactiveValue = fieldData.reactiveValue;
      const parentKey = fieldData.reactiveKey;
      const parentValues = form_values[parentKey];

      Object.entries(reactiveValue).forEach(([option, value]) => {
        if (Array.isArray(parentValues) && parentValues.includes(option)) {
          showDropdown = true;
        } else if (parentValues === option) {
          showDropdown = true;
        }
      });

      if (!showDropdown) return <></>;
      return (
        <RenderField form_values={form_values} fieldData={{ ...fieldData, type: "dropdown" }} key={fieldData.key} />
      );
    }
    case "attachment":
      return <AttachmentNode fieldData={fieldData} form_values={form_values} key={fieldData.key} />;
    default: {
      const fieldValue = form_values[fieldData.key]?.toString();
      return (
        <li key={fieldData.key} className="object-data-viewer-list-item flex spaceBetween">
          <span className="object-data-viewer-list-item-key">{fieldData.label}</span>
          <div className="object-data-viewer-list-item-separator">:</div>
          <span className="object-data-viewer-list-item-value">{fieldValue}</span>
        </li>
      );
    }
  }
};

const AdditionalInfoFormData = ({ data = {} }) => {
  const { data: form = [], responses = {} } = data;

  const fieldProps = { form_values: responses?.[0]?.data };
  return (
    <ul>
      {form.map((field) => (
        <RenderField fieldData={field} {...fieldProps} key={field.key} />
      ))}
    </ul>
  );
};

const AdditionalInfoDataContainer = ({ additionalFormData = [] }) => {
  const { attachmentsData, setFormId, formId } = useContext(AssetsData);
  const tabsData = useMemo(() => {
    const ids = [];
    const entities = {};
    additionalFormData.forEach((form, index) => {
      ids.push(form.id);
      entities[form.id] = form;
      if (index === 0) setFormId(form.id);
    });
    return { ids, entities };
  }, [additionalFormData]);
  function handleFormChange(_formId) {
    setFormId(_formId);
  }
  return (
    <div className="assets-info-popup-tab-view-container flex spaceBetween">
      <div className="assets-info-popup-tab-sections maxContent">
        {tabsData.ids.map((_formId, index) => (
          <List
            key={"assets-tab-section" + index}
            className={formId === _formId ? "info-popup-tab-active" : "info-popup-tab"}
          >
            <ListItem disablePadding onClick={() => handleFormChange(_formId)}>
              <ListItemButton>{tabsData.entities[_formId].name}</ListItemButton>
            </ListItem>
          </List>
        ))}
      </div>
      <div className="assets-info-popup-tab-data fullFlexBasis">
        <AdditionalInfoFormData data={tabsData.entities[formId]} />
        <Attachments attachmentsData={attachmentsData} />
      </div>
    </div>
  );
};

const PopupData = ({ infoData, attachmentsData, additionalFormData, activeTab, setFormId }) => {
  return (
    <div key={activeTab} className="assets-info-popup-data-info maxContent fullFlexBasis">
      {activeTab === 0 ? (
        <>
          <ObjectDataViewer object={infoData} labelObject={keys} />
          <Attachments attachmentsData={attachmentsData} />
        </>
      ) : (
        <>
          <AdditionalInfoDataContainer infoData={infoData} additionalFormData={additionalFormData} />
        </>
      )}
    </div>
  );
};
const ObjectDataViewer = ({ object, labelObject }) => {
  return (
    <ul>
      {Object.entries(object).map(([key, value], index) => {
        if (!labelObject || labelObject?.[key]) {
          return (
            <li key={key + index} className="object-data-viewer-list-item flex spaceBetween">
              <span className="object-data-viewer-list-item-key">{labelObject ? labelObject[key] : key}</span>
              <div className="object-data-viewer-list-item-separator">:</div>
              <span className="object-data-viewer-list-item-value">{value}</span>
            </li>
          );
        }
      })}
    </ul>
  );
};

const ImageViewerContainer = ({ attachmentsData }) => {
  const [gearTypeImages, setGearTypeImages] = useState([]);
  // const [imageLoading, setImageLoading] = useState(true);
  const [currentPreviewImage, setCurrentPreviewImage] = useState(0);

  function handlePreviousPreviewImage() {
    if (currentPreviewImage !== 0) setCurrentPreviewImage((previousValue) => previousValue - 1);
  }
  function handleNextPreviewImage() {
    if (currentPreviewImage !== gearTypeImages.length - 1) setCurrentPreviewImage((previousValue) => previousValue + 1);
  }
  useEffect(() => {
    const displayImages = attachmentsData.reduce(function (accumulator, { type, path, id }) {
      if (type === "Gear Type Image") {
        // accumulator.push(`${APP_BASE_URL}/${path}`);
        accumulator.push(`${APP_BASE_URL}${GET_ATTACHMENT_BY_ID}/${id}`);
      }
      return accumulator;
    }, []);
    setGearTypeImages(displayImages);
  }, [attachmentsData]);
  // useEffect(() => {
  //   if (gearTypeImages[currentPreviewImage]) {
  //     setImageLoading(true);
  //   } else setImageLoading(false);
  // }, [gearTypeImages, currentPreviewImage]);
  return (
    <div className="assets-info-popup-attachment-info-container">
      <div className="assets-info-popup-image">
        {/* {imageLoading && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#e6e6e6",
              height: "15em",
            }}
          ></div>
        )} */}
        <a href={gearTypeImages[currentPreviewImage] ?? null} target="_blank" rel="noreferrer">
          <img
            loading="lazy"
            // onLoad={() => {
            //   setImageLoading(false);
            // }}
            key={currentPreviewImage}
            // style={{ width: "100%", height: "100%", objectFit: "contain" }}
            style={{ width: "18em", height: "18em", objectFit: "contain" }}
            src={gearTypeImages[currentPreviewImage] ?? noImage}
            alt="Not Available"
          />
        </a>
      </div>
      {gearTypeImages.length > 0 && (
        <div className="assets-images-preview-container flex alignCenter spaceBetween">
          <div
            className={`assets-images-preview-previous-navigation ${
              currentPreviewImage === 0 ? "notAllowed" : "pointer"
            }`}
            onClick={handlePreviousPreviewImage}
          ></div>
          <div>
            {currentPreviewImage + 1} of {gearTypeImages.length}
          </div>
          <div
            className={`assets-images-preview-forward-navigation ${
              currentPreviewImage === gearTypeImages.length - 1 ? "notAllowed" : "pointer"
            }`}
            onClick={handleNextPreviewImage}
          ></div>
        </div>
      )}
    </div>
  );
};

function InfoPopup({ open, onClose, label, infoData }) {
  const { attachmentsData, setFormId, setAttachmentsData } = useContext(AssetsData);
  const [additionalFormData, setAdditionalFormData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  useEffect(() => {
    const url = `${APP_BASE_URL}${GET_COLLECTION_MAP_ID}?type_id=${infoData.typeid}`;
    axios.get(url).then(({ data }) => {
      const url2 = `${APP_BASE_URL}${GET_ASSET_FORM}?collection_map_id=${data[0].collectionmapid}&with_responses=true&device_id=${infoData.deviceId}`;
      axios.get(url2).then(({ data }) => {
        setAdditionalFormData(data.data);
      });
    });
  }, []);
  const [activeTab, setActiveTab] = useState(0);
  useLayoutEffect(() => {
    if (activeTab === 0) {
      setFormId("");
      // setAttachmentsEdit(false);
    }
    setAttachmentsData([]);
    // else {
    // }
  }, [activeTab]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        className="assets-information-popup"
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="responsive-dialog-title" className="flex">
          {label}
          <CloseIcon
            className="assets-info-popup-close-icon pointer"
            onClick={() => {
              onClose(false);
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <div className="assets-info-popup-content-container fullWidth flex flexDirectionColumn">
            <NavigationTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              additionalFormData={additionalFormData}
              setSnackbarMessage={setSnackbarMessage}
            />
            <div className="assets-info-popup-data-container flex spaceEvenly">
              <PopupData
                infoData={infoData}
                attachmentsData={attachmentsData}
                activeTab={activeTab}
                additionalFormData={additionalFormData}
                setFormId={setFormId}
              />
              <ImageViewerContainer attachmentsData={attachmentsData} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={() => {
              onClose(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarMessage ? true : false} autoHideDuration={6000} onClose={() => setSnackbarMessage(null)}>
        <Alert severity="error" className="fullWidth">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default InfoPopup;
