import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  getFormControlLabelUtilityClasses,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import { useImmer } from "use-immer";
import {
  APP_BASE_URL,
  APP_BASE_URL_ALARM,
  ASSET_MAINTENANCE_HISTORY_API,
  ASSET_MAINTENANCE_TABLE_API,
  GENERIC_BASE_PATH,
  GET_DEVICE_TYPE_ASSET,
  GET_SCHEDULE_CODE_LIST,
  trimAssetName,
} from "../../../../Common/EndPoints";
import DataTable from "../../../DataGrid/DataGrid";
import "./MaintenanceResponseTable.scss";
import ActiveAlarms, { ActiveAlarmsData } from "../../../Alarms/ActiveAlarms";
import { extractMasterRoleIds } from "../../../../Utils/Utils";
import MaintenanceAttachmentModel from "./MaintenanceAttachments/MaintenanceAttachmentModel";

const tsToDateFormatter = {
  valueFormatter: (param) => {
    if (param.value && param.value > 0) {
      const value = Number(param.value);
      const date = new Date(value);
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1; // Months start at 0!
      let dd = date.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;
      const time = date.toLocaleTimeString();
      return formattedToday;
      // return formattedToday + " " + time;
    } else {
      return "-";
    }
  },
};

const initialColumns = [
  {
    field: "completedDt",
    headerName: "Done Date",
    sortable: true,
    minWidth: 110,
    hideable: true,
    flex: 1,
    valueFormatter: (params) => {
      let TS = params.value;
      if (TS && TS > 0) {
        const value = Number(TS);
        const date = new Date(value);
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        const formattedToday = dd + "/" + mm + "/" + yyyy;
        const time = date.toLocaleTimeString();
        return formattedToday + " " + time;
      } else {
        return "-";
      }
    },
  },
  {
    field: "gearName",
    headerName: "Asset Name",
    sortable: true,
    minWidth: 110,
    hideable: true,
    flex: 1,
    valueFormatter: (param) => {
      return trimAssetName(param.value)
    }
  },
  {
    field: "scheduleCode",
    headerName: "Schedule Code",
    sortable: true,
    minWidth: 110,
    hideable: true,
    flex: 1,
  },
];

const initialPaginantion = {
  pageIndex: 0,
  pageSize: 20,
  totalRowCount: 0,
};
const initialFilters = {
  deviceType: null, //tc typeId
  scheduleCode: null,
  scheduleStatus: null,
  device_ids: []
};
const initialPageState = {
  tableColumns: initialColumns,
  tableRows: [],
  loader: false,
};
const initialDropdowns = {
  deviceTypeDD: [],
  scheduleCodeDD: [],
  assetNameDD: [],
  scheduleStatusDD: [
    { value: "DUE", label: "DUE" },
    { value: "DONE", label: "DONE" },
  ],
};
const initAttachState = {
  open: false,
  label: "Attachments",
  attachments: [],
  assetName: '',
  completeDt: ''
}
export default function MaintenanceResponseTable() {
  let roleHr = JSON.parse(localStorage.getItem('roleHierarchies'))
  let MASTER_ROLE_IDS = extractMasterRoleIds(roleHr)
  const [paginantion, setPaginantion] = useImmer(initialPaginantion);
  const [responseFilter, setResponseFilter] = useImmer(initialFilters);
  const [responsePageState, setResponsePageState] = useImmer(initialPageState);
  const [dropdowns, setDropdowns] = useImmer(initialDropdowns);
  const [attachPop, setAttachPop] = useImmer(initAttachState)
  const { customerIdList } = useContext(ActiveAlarmsData)
  const handleFilterChange = (value, stateName) => {
    setResponseFilter((prev) => {
      prev[stateName] = value;
    });
  };
  useLayoutEffect(() => {
    getDeviceTypes();
    // getScheduleCodes()
  }, []);
  useEffect(() => {
    if (responseFilter.deviceType) {
      getScheduleCodes(responseFilter.deviceType.value);
      getAssetList(responseFilter.deviceType.value)
    }
  }, [responseFilter.deviceType]);

  useEffect(() => {
    if (responseFilter.scheduleCode) {
      getFormResponse({ ...responseFilter, customerIds: customerIdList });
    }
  }, [responseFilter.scheduleCode, responseFilter.scheduleStatus, responseFilter.device_ids, customerIdList]);

  const getScheduleCodes = (deviceType) => {
    let url = `${APP_BASE_URL}${GET_SCHEDULE_CODE_LIST}?deviceTypeId=${deviceType}&scheduleCodeMapingOnly=${true}`;
    axios.get(url).then((res) => {
      // console.log('res, ',res.data.data)
      const response = res.data.data
      let schCodes = response.map((val, i) => {
        return val.code
      })
      // let schCodes = Object.values(res.data.data);
      setDropdowns((prev) => {
        prev.scheduleCodeDD = schCodes;
      });
      setResponseFilter((prev) => {
        prev.scheduleCode = schCodes[0];
      });
    });
  };

  const getAssetList = (deviceType) => {
    let url = `${APP_BASE_URL_ALARM}${GENERIC_BASE_PATH}/distinct_with_id`
    let params = {
      table_name: 'device',
      col_name: 'name',
      id_name: 'deviceid',
      device_type_ids: deviceType,
      relations: 'DEVICE_TYPE'
    }
    axios.get(url, { params }).then((res) => {
      let assetList = res.data.map((val) => {
        return {
          id: val.id,
          value: trimAssetName(val.value)
        }
      })
      setDropdowns((prev) => {
        prev.assetNameDD = assetList
      })
    })

  }

  const getDeviceTypes = () => {
    let url = `${APP_BASE_URL}${GET_DEVICE_TYPE_ASSET}`;
    axios.get(url).then((res) => {
      let deviceTypes = res.data.data.map((type) => {
        return {
          value: type.typeid,
          label: type.type,
          alias: type.label,
        };
      });
      setDropdowns((prev) => {
        prev.deviceTypeDD = deviceTypes;
      });
      setResponseFilter((prev) => {
        prev.deviceType = deviceTypes[0];
      });
    });
  };
  const getFormResponse = (filterParms) => {
    let params = {
      deviceTypeId: filterParms.deviceType.value,
      scheduleCode: filterParms.scheduleCode,
      status: filterParms.scheduleStatus,
      customerIds: filterParms.customerIds.toString(),
      deviceIds: filterParms?.device_ids.map((val) => val.id)?.toString(),
      roleIdList: MASTER_ROLE_IDS.toString()
    };
    let url = `${APP_BASE_URL}${ASSET_MAINTENANCE_HISTORY_API}?page=${1}&pageSize=${20}&withResponse=true&status=DONE`;
    axios.get(url, { params }).then((res) => {
      const apiResponse = res.data.data.content;
      if (apiResponse.length > 0) {
        let form = apiResponse[0]?.maintenanceFormResponse?.form?.data;
        // getColumnsByForm(form);
        let newTableRows = apiResponse.map((row, i) => {
          let basicInfo = {
            id: i,
            completedDt: row.completedDt,
            gearName: row.gearName,
            scheduleCode: row.scheduleCode,
          };
          let formResponse = row.maintenanceFormResponse.data;
          let newRow = { ...basicInfo, ...formResponse };
          return newRow;
        });
        setResponsePageState((prev) => {
          prev.tableRows = newTableRows;
          prev.tableColumns = [...initialColumns, ...getColumnsByFormA(form)];
        });
      } else {
        setResponsePageState((prev) => {
          prev.tableRows = [];
          prev.tableColumns = initialColumns;
        });
      }
    });
  };

  const getColumnsByFormA = (fieldList) => {
    if (!fieldList || fieldList.length === 0) {
      return [];
    }
    let columns = [];

    fieldList?.forEach((field, i) => {
      if (field.type !== "heading" && field.type !== "sub_heading") {
        if (field.type === "section" || field.type === "reactive_section") {
          columns = [...columns, ...getColumnsByFormA(field.sectionFields)];
        } else {
          if (field.key !== "completedDt" && field.showInTable) {
            columns.push({
              field: field.key,
              headerName: field.label,
              sortable: true,
              minWidth: 210,
              hideable: true,
              isAttachment: field.type === 'attachment',
              flex: 1,
              valueFormatter: (param) => {
                if (field.type === 'attachment') {
                  return 'Attachments'
                } else if (!param.value || param.value === "") {
                  return "-";
                }
              },
              cellClassName: (param) => {
                if (field.type === 'attachment') {
                  if (param.row[param.field] && param.row[param.field]?.length > 0) {
                    return 'blue pointer';
                  } else {
                    return 'noAttachment'
                  }
                }
              }
            });
          }
        }
      }
    });

    return columns;
  };

  const getColumnsByForm = (formJson) => {
    let newColumns = [...initialColumns];
    formJson.forEach((field) => {
      if (field.showInTable) {
        newColumns.push({
          field: field.key,
          headerName: field.label,
          sortable: true,
          minWidth: 180,
          hideable: true,
          flex: 1,
        });
      }
    });
    setResponsePageState((prev) => {
      prev.tableColumns = newColumns;
    });
  };
  const handleCloseAttachpop = () => {
    setAttachPop(initAttachState)
  }

  const handleOpenAttachPopup = (param) => {
    if (param.colDef.isAttachment && param.row[param.field].length > 0) {
      setAttachPop((prev) => {
        prev.open = true
        prev.attachments = param.row[param.field]
        prev.assetName = param.row.gearName
        prev.completeDt = param.row.completedDt

      })
    }
  }
  return (
    <div className="responseTablePage">
      <div className="responseTableFilters card">
        <FormControl className="filterInput" size="small">
          <InputLabel shrink>Asset Type</InputLabel>
          <Select
            // defaultValue={responsePageState.deviceTypeDD?.[0]?.typeid}
            label="Asset Type"
            value={responseFilter.deviceType ? responseFilter.deviceType : {}}
            onChange={(e) => {
              handleFilterChange(e.target.value, "deviceType");
            }}
            notched
          >
            {dropdowns.deviceTypeDD.map((dd, i) => {
              return (
                <MenuItem key={i} value={dd}>
                  {dd.alias}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl className="filterInput" size="small">
          <InputLabel shrink>Schedule Code</InputLabel>
          <Select
            // defaultValue={responsePageState.deviceTypeDD?.[0]?.typeid}
            label="Schedule Code"
            value={responseFilter.scheduleCode}
            onChange={(e) => {
              handleFilterChange(e.target.value, "scheduleCode");
            }}
            notched
          >
            {dropdowns.scheduleCodeDD.map((dd, i) => {
              return (
                <MenuItem key={i} value={dd}>
                  {dd}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Autocomplete
          size="small"
          className="filterInput"
          multiple
          disableCloseOnSelect
          options={dropdowns?.assetNameDD}
          renderInput={(params) => <TextField size="small" {...params} label="Assets" placeholder="Assets" />}
          getOptionLabel={(asset) => asset.value}
          value={responseFilter?.device_ids ?? []}
          onChange={(a, b, c) => {
            handleFilterChange(b, "device_ids");
          }}
          renderTags={(value, getTagProps) => {
            return (
              <div>
                <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />{" "}
                {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
              </div>
            );
          }}
        />
        {/* <FormControl className="filterInput" size="small">
          <InputLabel>Schedule Status</InputLabel>
          <Select
            // defaultValue={responsePageState.deviceTypeDD?.[0]?.typeid}
            label="Schedule Status"
            value={responseFilter.scheduleStatus}
            onChange={(e) => {
              handleFilterChange(e.target.value, "scheduleStatus");
            }}
          >
            {dropdowns.scheduleStatusDD.map((dd, i) => {
              return (
                <MenuItem key={i} value={dd.value}>
                  {dd.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl> */}
        {/* <Autocomplete
          className="filterInput"
          size="small"
          disablePortal
          id="combo-box-demo"
          options={dropdowns.scheduleStatusDD}
          onChange={(_, newValue) => {
            setResponseFilter((prev) => {
              prev.scheduleStatus = newValue ? newValue.value : null;
            });
          }}
          // sx={{ width: 300 }}
          renderInput={(params) => <TextField size="small" {...params} label="Schedule Status" />}
        /> */}
        {/* <Button variant='contained'>Search</Button> */}
        {/* <Button variant='outlined' onClick={handleClearFilter}>Clear Filter</Button> */}
      </div>
      <div className="responseTable card">
        <DataTable
          tableInfo={{
            id: `devices-table`,
            label: `Devices`,
            requireAuthSign: false,
            timestamp: { startTs: null, endTs: null },
          }}
          loading={responsePageState.loader}
          rows={responsePageState.tableRows}
          columns={responsePageState.tableColumns}
          paginationMode={"server"}
          // rowCount={50}
          rowCount={paginantion.totalRowCount}
          pageIndex={paginantion.pageIndex}
          pageChange={(index) => {
            setPaginantion((prev) => {
              prev.pageIndex = index;
            });
          }}
          pageSize={paginantion.pageSize}
          pageSizeChange={(size) => {
            setPaginantion((prev) => {
              prev.pageSize = size;
            });
          }}
          onCellClick={(param) => {
            handleOpenAttachPopup(param)

          }}
        />
      </div>
      <MaintenanceAttachmentModel open={attachPop.open} label={attachPop.label} attachments={attachPop.attachments} assetName={attachPop.assetName} completeDt={attachPop.completeDt} onClose={handleCloseAttachpop} />
    </div>
  );
}
