import React, { useEffect, useRef } from "react";

function TableCellTooltip({ textContent }) {
    const tooltipDivRef = useRef();
    const element = tooltipDivRef.current;
    if (element) {
        if (element?.scrollWidth > element?.clientWidth) {
            element.setAttribute("title", textContent);
            element.style.cursor = 'pointer'
        } else {
            element.removeAttribute("title");
            element.style.cursor = 'text'
        }
    }
    return (
        <div
            id="tooltipCell"
            ref={tooltipDivRef}
            className="MuiDataGrid-cellContent"
        >
            {textContent}
        </div>
    );
}

export default TableCellTooltip;
