import * as firebase from "firebase/app";
import * as firebase_messaging from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCT4fTEcb6B4NxvFmroNm2v9YM6r-1cbHs",
  authDomain: "rdpms-edge.firebaseapp.com",
  projectId: "rdpms-edge",
  storageBucket: "rdpms-edge.appspot.com",
  messagingSenderId: "599946883383",
  appId: "1:599946883383:web:3c5d7fcf5e1541c505aab2",
  measurementId: "G-141NQ8FF2R",
};

firebase.initializeApp(firebaseConfig);

const messaging = (async () => {
  try {
    const isSupportedBrowser = await firebase_messaging.isSupported();
    if (isSupportedBrowser) {
      return firebase_messaging.getMessaging();
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async () => {
  let currentToken = "";
  const supported = await firebase_messaging.isSupported();
  if (!supported) {
    console.log("browser not supported");
    return null;
  }
  try {
    const messagingResolve = await messaging;
    currentToken = await firebase_messaging.getToken(messagingResolve, { vapidKey: publicKey });
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise(async (resolve) => {
    const messagingResolve = await messaging;
    firebase_messaging?.onMessage(messagingResolve, (payload) => {
      resolve(payload);
    });
  });

export const isBrowserSupported = firebase_messaging.isSupported();
