import { useEffect, useState, useContext, useMemo } from "react";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { AutocompleteStationListPop } from "../AutocompleteStationListPop/AutocompleteStationListPop";
import { selectedCustomersIdsSelector } from "../../features/authentication/auth-slice";
import { useSelector } from "react-redux";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { filter } from "rxjs";
import { NoEncryption } from "@mui/icons-material";
import Typography from '@mui/material/Typography';

const TrendFilters = ({filters, setFilters, trendState}) => {
  //const { customerIdList, newUserData } = useContext(ActiveAlarmsData);
  const { selectedCustomersIdName = [] } = useContext(ActiveAlarmsData);
  const customerIdList = useSelector(selectedCustomersIdsSelector)
  const permittedCustomerIds = useMemo(() => {
    const _list = selectedCustomersIdName;
    if (!_list) return [];
    return _list.map((customer) => ({ id: customer.customerId, value: customer.customerName }));
  }, [selectedCustomersIdName]);

  useEffect(() => {
    console.log("TrendFilters --> filters  --> ", filters, trendState);    
  }, [filters, trendState]);
  useEffect(() => {
    console.log("TrendFilters --> filters  --> ", filters, trendState);    
  }, [trendState]);

  const handleCustomerCheckboxChange = (event) => {
    console.log("handleCustomerCheckboxChange --> ", event.target.name, event.target.checked, permittedCustomerIds);
    if(event.target.checked) {
      setFilters((state) => {
        state.customerIds = [...(filters.customerIds ?? []), permittedCustomerIds.filter(item => item.id === event.target.name)[0]];
      });
      // add
    } else {
      setFilters((state) => {
        state.customerIds = [...(filters.customerIds ?? []).filter(item => item.id !== event.target.name)];
      });
    }
  };

  const handleDeviceTypeCheckboxChange = (event) => {
    console.log("handleDeviceTypeCheckboxChange --> ", event.target.name, event.target.checked,trendState.deviceTypeList);
    if(event.target.checked) {
      setFilters((state) => {
        //state.deviceIds = [...(filters.deviceIds ?? []), trendState.deviceTypeList.filter(item => item.typeId === event.target.name)[0]];
        state.deviceIds = [trendState.deviceTypeList.filter(item => item.typeId === event.target.name)[0]];
      });
      // add
    } else {
      setFilters((state) => {
        state.deviceIds = [...(filters.deviceIds ?? []).filter(item => item.typeId !== event.target.name)];
      });
    }
  };

  const handleStateCheckboxChange = (event) => {
    let stateList =  [...new Set((trendState.apiKeys ?? []).map(ele => ele.state))];
    console.log("handleStateCheckboxChange --> ", event.target.name, event.target.checked, stateList);
    if(event.target.checked) {
      setFilters((state) => {
        state.keyState = [...(filters.keyState ?? []), stateList.filter(item => item === event.target.name)[0]];
      });
      // add
    } else {
      setFilters((state) => {
        state.keyState = [...(filters.keyState ?? []).filter(item => item !== event.target.name)];
      });
    }
  };
 
  const handleKeyCheckboxChange = (event) => {
    let keyList = [...new Set((trendState.apiKeys ?? []).map(ele => `${ele.key} (${ele.alias})`))];
    console.log("handleKeyCheckboxChange --> ", event.target.name, event.target.checked, keyList);
    if(event.target.checked) {
      setFilters((state) => {
        state.key = [...(filters.key ?? []), keyList.filter(item => item === event.target.name)[0]];
      });
      // add
    } else {
      setFilters((state) => {
        state.key = [...(filters.key ?? []).filter(item => item !== event.target.name)];
      });
    }
  };

  const handleDeviceCheckboxChange = (event) => {
    console.log("handleDeviceTypeCheckboxChange --> ", event.target.name, event.target.checked,trendState.data);
    if(event.target.checked) {
      setFilters((state) => {
        //state.data = [...(filters.data ?? []), trendState.data.filter(item => item.deviceId === event.target.name)[0]];
        state.data = [trendState.data.filter(item => item.deviceId === event.target.name)[0]];
      });
      // add
    } else {
      setFilters((state) => {
        state.data = [...(filters.data ?? []).filter(item => item.deviceId !== event.target.name)];
      });
    }
  };

  return (
    <>
     {false && 
        <div style={{ position: "static" , display: "none" }} className="trend-filters">        
            <Autocomplete size="small" options={permittedCustomerIds} 
              className="filter-input auto-complete" multiple disableCloseOnSelect
              renderInput={(params) => (
                <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(customer) => customer.value}
              value={filters?.customerIds ?? []}
              onChange={(_, newValue) => {
                setFilters((state) => {
                  state.customerIds = newValue;
                });
              }}
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
                    {value.length > 1 && (
                      <AutocompleteStationListPop title={value.map((stn) => stn?.value ?? "").toString()}>
                        <Chip size="small" label={`+${value.length - 1}`} />
                      </AutocompleteStationListPop>
                    )}
                  </div>
                );
              }}
            />
            <Autocomplete options={trendState.deviceTypeList} size="small"
              className="filter-input auto-complete"
              multiple
              disableCloseOnSelect        
              renderInput={(params) => (
                <TextField className="filter-input" size="small" {...params} label="Device Type" placeholder="Device Type" />
              )}
              isOptionEqualToValue={(option, value) => option.typeId === value.typeId}
              getOptionLabel={(customer) => customer.label}
              value={filters?.deviceIds ?? []}
              onChange={(_, newValue) => {
                setFilters((state) => {
                  state.deviceIds = newValue;
                });
              }}
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip size="small" variant="outlined" label={value[0].label} {...getTagProps(0)} />
                    {value.length > 1 && (
                      <AutocompleteStationListPop title={value.map((ele) => ele?.label ?? "").toString()}>
                        <Chip size="small" label={`+${value.length - 1}`} />
                      </AutocompleteStationListPop>
                    )}
                  </div>
                );
              }}
            />
          <Autocomplete options={[...new Set((trendState.apiKeys ?? []).map(ele => ele.state))]} size="small"
              className="filter-input auto-complete"
              multiple
              disableCloseOnSelect        
              renderInput={(params) => (
                <TextField className="filter-input" size="small" {...params} label="State" placeholder="State" />
              )}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(ele) => ele}
              value={filters?.keyState ?? []}
              onChange={(_, newValue) => {
                setFilters((state) => {
                  state.keyState = newValue;
                });
              }}
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip size="small" variant="outlined" label={value[0]} {...getTagProps(0)} />
                    {value.length > 1 && (
                      <AutocompleteStationListPop title={value.map((ele) => ele ?? "").toString()}>
                        <Chip size="small" label={`+${value.length - 1}`} />
                      </AutocompleteStationListPop>
                    )}
                  </div>
                );
              }}
            />
          <Autocomplete options={[...new Set((trendState.apiKeys ?? []).map(ele => `${ele.key} (${ele.alias})`))]} size="small"
              className="filter-input auto-complete"
              multiple
              disableCloseOnSelect        
              renderInput={(params) => (
                <TextField className="filter-input" size="small" {...params} label="Key (Feature)" placeholder="Key (Feature)" />
              )}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(ele) => ele}
              value={filters?.key ?? []}
              onChange={(_, newValue) => {
                setFilters((state) => {
                  state.key = newValue;
                });
              }}
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip size="small" variant="outlined" label={value[0]} {...getTagProps(0)} />
                    {value.length > 1 && (
                      <AutocompleteStationListPop title={value.map((ele) => ele ?? "").toString()}>
                        <Chip size="small" label={`+${value.length - 1}`} />
                      </AutocompleteStationListPop>
                    )}
                  </div>
                );
              }}
            />
            <Autocomplete options={trendState.data} size="small"
              className="filter-input auto-complete"
              multiple
              disableCloseOnSelect        
              renderInput={(params) => (
                <TextField className="filter-input" size="small" {...params} label="Device" placeholder="Device" />
              )}
              isOptionEqualToValue={(option, value) => option.deviceId === value.deviceId}
              getOptionLabel={(ele) => ele.NAME}
              value={filters?.data ?? []}
              onChange={(_, newValue) => {
                setFilters((state) => {
                  state.data = newValue;
                });
              }}
              renderTags={(value, getTagProps) => {
                return (
                  <div>
                    <Chip size="small" variant="outlined" label={value[0].NAME} {...getTagProps(0)} />
                    {value.length > 1 && (
                      <AutocompleteStationListPop title={value.map((ele) => ele?.NAME ?? "").toString()}>
                        <Chip size="small" label={`+${value.length - 1}`} />
                      </AutocompleteStationListPop>
                    )}
                  </div>
                );
              }}
            />
        </div>
     }
     <div style={{ position: "static" }} className="trend-filters part-2"> 
        <div className="vertical-filter">
          <Typography variant="h6" gutterBottom>Stations</Typography>
          {permittedCustomerIds.map(item => {
              return (<>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={(filters.customerIds??[]).filter(ele => ele.id === item.id).length > 0} 
                      onChange={handleCustomerCheckboxChange} 
                      name={item.id} />
                  }
                  label={item.value}
                />
              </>);
          })}
        </div>
        <div className="vertical-filter">
          <Typography variant="h6" gutterBottom>Device Type</Typography>
          {(trendState.deviceTypeList??[]).map(item => {
              return (<>                
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={(filters.deviceIds??[]).filter(ele => ele.typeId === item.typeId).length > 0} 
                      onChange={handleDeviceTypeCheckboxChange} 
                      name={item.typeId} />
                  }
                  label={item.label}
                />
              </>); 
          })}
        </div>
        <div className="vertical-filter">
          <Typography variant="h6" gutterBottom>State</Typography>
          {[...new Set((trendState.apiKeys ?? []).map(ele => ele.state))].map(item => {
              return (<>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={(filters.keyState??[]).filter(ele => ele === item).length > 0} 
                      onChange={handleStateCheckboxChange} 
                      name={item} />
                  }
                  label={item}
                />
              </>); 
          })}
        </div>
        <div className="vertical-filter">
          <Typography variant="h6" gutterBottom>Key (Feature)</Typography>
          {[...new Set((trendState.apiKeys ?? []).map(ele => `${ele.key} (${ele.alias})`))].map(item => {
              return (<>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={(filters.key??[]).filter(ele => ele === item).length > 0} 
                      onChange={handleKeyCheckboxChange} 
                      name={item} />
                  }
                  label={item}
                />
              </>); 
          })}
        </div>
        <div className="vertical-filter">
          <Typography variant="h6" gutterBottom>Devices</Typography>
          {(trendState.data??[]).map(item => {
              return (<>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={(filters.data??[]).filter(ele => ele.deviceId === item.deviceId).length > 0} 
                      onChange={handleDeviceCheckboxChange} 
                      name={item.deviceId} />
                  }
                  label={item.NAME}
                />
              </>); 
          })}
        </div>
     </div> 
    </>
  );
};
export default TrendFilters;
