import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { drawerWidth } from "../../Common/EndPoints";

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   // width: `calc(100% - ${drawerWidth}px)`,
  //   // marginLeft: `${drawerWidth}px`,
  //   width: `calc(100% - max(10vw, 11em))`,
  //   // marginLeft: `max(10vw, 11em)`,
  //   // transition: theme.transitions.create(["margin", "width"], {
  //   //   easing: theme.transitions.easing.easeOut,
  //   //   duration: theme.transitions.duration.enteringScreen,
  //   // }),
  // }),
}));
