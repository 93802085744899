export const FIXED_VIRTUAL_SPACE_FOR_EACH_GROUP = 200;
export const FIXED_VIRTUAL_BUFFER_BETWEEN_GROUP = 40;

const POINT_CONFIG = {
    0: {
        keys: ["OC", "PC"],
        actualUnitsForYaxis: 20,
        unit: "A",
    },
    1: {
        keys: ["OT"],
        actualUnitsForYaxis: 30,
        unit: "S",
    },
    2: {
        keys: ["NWKR", "RWKR", "RNWKR", "RRWKR"],
        actualUnitsForYaxis: 50,
        unit: "V",
    },
    3: {
        keys: ["VNO", "VRO"],
        actualUnitsForYaxis: 150,
        unit: "V",
    },
};
const SIGNAL_CONFIG = {
    0: {
        keys: ["ICO"],
        actualUnitsForYaxis: 5,
        unit: "A",
    },
    1: {
        keys: ["IF", "IR"],
        actualUnitsForYaxis: 2000,
        unit: "mA",
    },
    2: {
        keys: ["VR", "VF", "VCO", "VCH"],
        actualUnitsForYaxis: 10,
        unit: "V",
    },
    3: {
        keys: ["TPR", "VRR"],
        actualUnitsForYaxis: 150,
        unit: "V",
    },
    3: {
        keys: ["VCI"],
        actualUnitsForYaxis: 150,
        unit: "V",
    },
};

export const KEYS_CONFIG = {
    PM: {
        OC: {
            lineColor: "red",
            divide: true,
            Label: 'Operating Current'
        },
        PC: {
            lineColor: "red",
            divide: true,
            Label: 'Peak Current'
        },
        OT: {
            lineColor: "red",
            divide: true,
            Label: 'Operating Time'
        },
        VNO: {
            lineColor: "red",
            divide: true,
            Label: 'Normal Operating Voltage(NW)'

        },
        VRO: {
            lineColor: "red",
            divide: true,
            Label: 'Reverse Operating Voltage(RW)'
        },
        NWKR: {
            lineColor: "red",
            divide: true,
            Label: 'NWKR'
        },
        RWKR: {
            lineColor: "red",
            divide: true,
            Label: 'RWKR'
        },
        RNWKR: {
            lineColor: "red",
            divide: true,
            Label: 'NWKR-Indoor'
        },
        RRWKR: {
            lineColor: "red",
            divide: true,
            Label: 'RWKR-Indoor'
        },
    },
    TRACK: {
        ICO: {
            lineColor: "red",
            divide: true,
            Label: 'Charger Output Current'
        },
        IF: {
            lineColor: "red",
            divide: false,
            Label: 'Feed Current'
        },
        IR: {
            lineColor: "red",
            divide: false,
            Label: 'Relay Current'
        },
        VR: {
            lineColor: "red",
            divide: true,
            Label: 'Relay Voltage'

        },
        VF: {
            lineColor: "red",
            divide: true,
            Label: 'Feed Voltage'
        },
        VCO: {
            lineColor: "red",
            divide: true,
            Label: 'Charger O/P Voltage'
        },
        VCH: {
            lineColor: "red",
            divide: true,
            Label: 'Choke Voltage'
        },
        TPR: {
            lineColor: "red",
            divide: true,
            Label: 'TPR-Indoor'
        },
        VRR: {
            lineColor: "red",
            divide: true,
            Label: 'TPR-Outdoor'
        },
        VCI: {
            lineColor: "red",
            divide: true,
            Label: 'Charger I/P Voltage'
        },
    },
};

export const GROUP_CONFIG = {
    PM: POINT_CONFIG,
    TRACK: SIGNAL_CONFIG
};


export const LINE_COLORS = [
    '#1f77b4', // blue
    '#1f77b4', // blue
    '#ff7f0e', // orange
    '#2ca02c', // green
    '#d62728', // red
    '#9467bd', // purple
    '#8c564b', // brown
    '#e377c2', // pink
    '#7f7f7f', // gray
    '#bcbd22', // yellow
    '#17becf'  // cyan
];
