import { trimAssetName } from "../../Common/EndPoints";
import { CableDataRaw, SingleDevice } from "./CableMonitoringHistory";

export type CableMonitoringHistoryData = {
  cable: string;
  gear: string[];
  outdoor_reference: string[];
  //   indoor_key: string;
  //   outdoor_key: string;
  indoor_receive: string[];
  outdoor_realtime: string[];
  location: string[];
  eld_status: string;
  indoor_source: string;
  health: string[];
  impacted_gear: string[];
  time: string;
  last_packets_received: string[];
  last_outdoor_realtime_ts: string[];
  last_indoor_received_ts: string[];
  last_indoor_source_ts: string;
  //   ev_plus: string[];
  //   ev_minus: string[];
  //   difference: string[];
  //   parent_id: string;
};

export function getParsedCableHistoryData(
  raw_data: CableDataRaw[],
  cable_device_info: SingleDevice,
): CableMonitoringHistoryData[] {
  const final_response: CableMonitoringHistoryData[] = [];
  const trimmed_cable_name = trimAssetName(cable_device_info.name);

  for (const cable of raw_data) {
    const row: CableMonitoringHistoryData = {
      cable: trimmed_cable_name,
      time: cable.ts,
      gear: [],
      outdoor_reference: [],
      //   indoor_key: "",
      indoor_receive: [],
      //   outdoor_key: "",
      outdoor_realtime: [],
      location: [],
      eld_status: "",
      indoor_source: "",
      health: [],
      impacted_gear: [],
      last_packets_received: [],
      last_indoor_received_ts: [],
      last_outdoor_realtime_ts: [],
      last_indoor_source_ts: "",
    };

    const value_24DC = cable.data?.["24DC"];
    const value_110DC = cable.data?.["110DC"];
    const value_110AC = cable.data?.["110AC"];

    const formatted_24DC =
      value_24DC && !value_24DC.includes("*") && Number(value_24DC) < 10000
        ? Number(value_24DC).toFixed(2) + " DC"
        : null;
    const formatted_110DC =
      value_110DC && !value_110DC.includes("*") && Number(value_110DC) < 10000
        ? Number(value_110DC).toFixed(2) + " DC"
        : null;
    const formatted_110AC =
      value_110AC && !value_110AC.includes("*") && Number(value_110AC) < 10000
        ? Number(value_110AC).toFixed(2) + " AC"
        : null;

    let default_indoor_source = "-";
    let default_indoor_source_ts = "-";

    const indoor_source = formatted_24DC || formatted_110DC || formatted_110AC || default_indoor_source;
    let indoor_source_ts = cable.ts ?? default_indoor_source_ts;

    const default_wetch = "-";
    
    const wetch = cable.data?.DRYCH;
    const wetch_status = wetch ? (wetch == "1" ? "HEALTHY" : "FAULT") : default_wetch;
    // const wetch = cable.data?.WETCH;
    // const wetch_status = wetch ? (Number(wetch) < 18000 ? "FAULT" : "HEALTHY") : default_wetch;

    for (const sub_gear of cable.cableHistoryData) {
      const gear_name = sub_gear.device.name;
      let indoor_receive = "-";
      let outdoor_realtime = "-";

      let outdoor_realtime_ts = "-";
      let indoor_received_ts = "-";

      let packet_time = 0;

      let sorted_sub_gear_data: Record<string, string> = {};
      sub_gear.data.sort((a, b) => a.ts - b.ts);
      for (const _data of sub_gear.data) {
        sorted_sub_gear_data = { ...sorted_sub_gear_data, ..._data.telemetry };
        packet_time = _data.ts;
      }

      const indoor_receive_keys = sub_gear.meta?.indoor_receive;
      const outdoor_realtime_keys = sub_gear.meta?.outdoor_realtime;

      if (indoor_receive_keys) {
        for (const key_map of indoor_receive_keys) {
          const { key, max, min } = key_map;

          const gear_value = sorted_sub_gear_data[key as keyof typeof sorted_sub_gear_data];
          if (!gear_value) continue;

          const numerical_value = Number(gear_value);

          if (numerical_value >= Number(min) && numerical_value <= Number(max)) {
            indoor_receive = Number(numerical_value).toFixed(2);
            indoor_received_ts = packet_time.toString();
            break;
          }
        }
      }

      if (outdoor_realtime_keys) {
        for (const key_map of outdoor_realtime_keys) {
          const { key, max, min } = key_map;

          const gear_value = sorted_sub_gear_data[key as keyof typeof sorted_sub_gear_data];
          if (!gear_value) continue;

          const numerical_value = Number(gear_value) / 1000;

          if (numerical_value >= Number(min) && numerical_value <= Number(max)) {
            outdoor_realtime = Number(numerical_value).toFixed(2);
            outdoor_realtime_ts = packet_time.toString();
            break;
          }
        }
      }

      const outdoor_reference = sub_gear.meta?.outdoor_ref?.toString() || "-";

      const trimmed_gear_name = trimAssetName(gear_name);
      const impacted_gear =
        outdoor_realtime && outdoor_reference && outdoor_reference !== "-" && outdoor_realtime !== "-"
          ? Number(outdoor_realtime) < Number(outdoor_reference)
            ? trimmed_gear_name
            : "-"
          : "-";

      const location = sub_gear.meta?.loc || "-";
      const health = sorted_sub_gear_data.HEALTH;

      row.gear.push(trimmed_gear_name);
      row.outdoor_reference.push(outdoor_reference);
      row.location.push(location);
      row.indoor_receive.push(indoor_receive);
      row.outdoor_realtime.push(outdoor_realtime);
      row.health.push(health || "-");
      row.impacted_gear.push(impacted_gear);
      row.last_packets_received.push(packet_time?.toString() ?? "-");
      row.last_outdoor_realtime_ts.push(outdoor_realtime_ts);
      row.last_indoor_received_ts.push(indoor_received_ts);
    }

    row.indoor_source = indoor_source;
    row.time = cable.ts ? new Date(cable.ts).getTime().toString() : "-";
    row.last_indoor_source_ts = indoor_source_ts;
    row.eld_status = wetch_status;

    // row.parent_id = cable_device_info.id;
    final_response.push(row);
  }
  return final_response;
}
