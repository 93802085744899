import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useLayoutEffect, useState } from "react";
import { useImmer } from "use-immer";
import { FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { ADD_USER, APP_BASE_URL, GET_USER_ROLES, UPDATE_USER } from "../../Common/EndPoints";
import axios from "axios";
import { Transition } from "../../Modal/ModalConstants";
import { toast } from "react-hot-toast";
import { extractAllowedUserRoleList } from "../../Utils/Utils";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  authority: "",
  zoneCode: [],
  mobileNumber: "",
  zoneList: [],
  divisionCode: [],
  divisionList: [],
  stationCode: [],
  stationList: [],
  userRoleList: [],
  divisionStationList: {},
  firstNameError: false,
  confirmPasswordError: false,
  confirmPassword: "",
  lastNameError: false,
  firstNameErrorText: "",
  lastNameErrorText: "",
  emailError: false,
  passwordError: false,
  zoneCodeError: false,
  divisionCodeError: false,
  mobileNumberError: false,
  stationCodeError: false,
  authorityError: false,
};
const intitialSnackbarState = {
  open: true,
  severityType: "",
  snackbarMessage: "",
};
const AddUser = ({ open, onClose, label, editRow, update, userStationsData }) => {
  const [state, setState] = useImmer(initialState);
  const [value, setValue] = useState("");
  const [snackBarState, setSnackBarState] = useImmer(intitialSnackbarState);
  function handleInputChange(event) {
    const newValue = event.target.value.replace(/[^\d]/g, "");

    // const newValue = event.target.value.replace(/[^0-9.]/g, "");
    // const parts = newValue.split(".");
    // if (parts.length > 2) {
    //   parts.pop();
    //   newValue = parts.join(".");
    // }
    if (newValue.length <= 10) {
      setState((prev) => {
        prev.mobileNumber = newValue;
      });
    }
  }

  useEffect(() => {
    getZone();
    getUserRoles();
  }, []);
  useLayoutEffect(() => {
    if (editRow) {
      setState((previousState) => {
        previousState.firstName = editRow.firstName;
        previousState.lastName = editRow.lastName;
        previousState.email = editRow.email;
        previousState.zoneCode = editRow.zones;
        previousState.divisionCode = editRow.divisions;
        previousState.stationCode = editRow.stations;
        previousState.authority = editRow?.authority;
        previousState.mobileNumber = editRow?.mobileNumber;
        previousState.password = "";
      });
    }
  }, []);

  const getZone = () => {
    let zones = [];
    userStationsData?.forEach((zone) => {
      zones = [...zones, ...Object.keys(zone)];
    });

    setState((previousState) => {
      previousState.zoneList = zones;
    });
  };

  useEffect(() => {
    if (state.zoneCode.length > 0) {
      let divisionList = [];
      userStationsData?.forEach((zone_map) => {
        const zone = Object.keys(zone_map)[0];
        if (state.zoneCode.includes(zone)) {
          zone_map[zone].forEach((divisions) => {
            divisionList = [...divisionList, ...Object.keys(divisions)];
          });
        }
      });
      setState((previousState) => {
        previousState.divisionList = divisionList;
        previousState.stationList = [];
      });
      if (editRow) {
        setState((previousState) => {
          previousState.divisionCode = editRow.divisions;
        });
      }
    } else {
      setState((previousState) => {
        previousState.divisionList = [];
        previousState.divisionCode = [];
        previousState.stationList = [];
      });
    }
  }, [state.zoneCode]);
  useEffect(() => {
    if (state.divisionCode.length > 0) {
      let stationList = [];
      state.divisionCode.forEach((div) => {
        userStationsData?.forEach((zones) => {
          const zone = Object.keys(zones)[0];
          if (state.zoneCode.includes(zone)) {
            zones[zone].forEach((divisions) => {
              if (divisions[div]) {
                divisions[div].forEach((stations) => {
                  stationList = [...stationList, stations.customerName];
                });
              }
            });
          }
        });
      });

      setState((previousState) => {
        previousState.stationList = stationList;
      });
      if (editRow) {
        setState((previousState) => {
          previousState.stationCode = editRow.stations;
          // previousState.stationCode = editRow.stations.split(",");
        });
      }
    } else {
      setState((previousState) => {
        previousState.stationList = [];
      });
    }
  }, [state.divisionCode]);
  const validateForm = () => {
    let isValid = true;
    if (
      !state.firstName ||
      state.firstName.length > 30 ||
      !state.lastName ||
      state.lastName.length > 30 ||
      !state.email ||
      (!editRow && !state.password && !state.confirmPassword) ||
      !state.zoneCode ||
      !state.divisionCode ||
      !state.mobileNumber ||
      state.mobileNumber.length < 10 ||
      !state.stationCode
    ) {
      isValid = false;
    }

    let confirmPasswordErrorText = "";
    if (!editRow) {
      if (!state.confirmPassword) {
        isValid = false;
        confirmPasswordErrorText = "Please enter a password for confirmation";
      }
      if (state.confirmPassword && state.confirmPassword !== state.password) {
        isValid = false;
        confirmPasswordErrorText = "Password did not match";
      }
    }

    function get_error_message(word, length_check = false) {
      if (!word) return "Field is required";

      function is_length_under_56() {
        if (word.length > 56) return "Must be under 56 characters";
        return null;
      }

      if (length_check) return is_length_under_56();
      return null;
    }

    setState((previousState) => {
      previousState.firstNameError = !state.firstName || state.firstName.length > 56;
      previousState.firstNameErrorText = get_error_message(state.firstName, true);
      previousState.lastNameError = !state.lastName || state.lastName.length > 56;
      previousState.lastNameErrorText = get_error_message(state.lastName, true);
      previousState.emailError = !state.email;
      if (!editRow) previousState.passwordError = !state.password;
      previousState.zoneCodeError = !state.zoneCode;
      previousState.confirmPasswordError = confirmPasswordErrorText;
      previousState.divisionCodeError = !state.divisionCode[0];
      previousState.stationCodeError = !state.stationCode[0];
      previousState.customerIdError = !state.customerId;
      previousState.mobileNumberError = state.mobileNumber.length < 10;
    });

    if (!isValid) {
      toast.error("Please fill all the mandatory fields");
    }
    return isValid;
  };

  // const getUserRoles = () => {
  //   let url = `${APP_BASE_URL}${GET_USER_ROLES}`;
  //   axios.get(url).then((res) => {
  //     let RESPONSE = res.data.data;
  //     let roleList = Object.values(RESPONSE);
  //     console.log(roleList)
  //     setState((prev) => {
  //       prev.userRoleList = roleList;
  //     });
  //   });
  // };

  const getUserRoles = () => {
    let roleHierarchies = JSON.parse(localStorage.getItem("roleHierarchies"));
    let allowedRoleIdList = extractAllowedUserRoleList(roleHierarchies);
    setState((prev) => {
      prev.userRoleList = allowedRoleIdList.map((role) => role.value);
    });
  };
  const onSubmit = () => {
    if (validateForm()) {
      const payload = {
        authority: state.authority,
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        password: state.password,
        mobileNumber: state.mobileNumber,
        zoneCode: state.zoneCode.toString(),
        divisionCode: state.divisionCode.toString(),
        stationCode: state.stationCode.toString(),
      };
      let successType = " User has been created";
      let apiMethod = axios.post;
      let url = `${APP_BASE_URL}${ADD_USER}?email=${payload.email}&First Name=${payload.firstName}&mobileNo=${payload.mobileNumber}&last Name=${payload.lastName}&password=${payload.password}&zone Code=${payload.zoneCode}&division Code=${payload.divisionCode}&stn Code=${payload.stationCode}&authority=${payload.authority}`;
      if (editRow) {
        apiMethod = axios.put;
        successType = "User has been updated";
        url = `${APP_BASE_URL}${UPDATE_USER}/${editRow.id}?email=${payload.email}&mobileNo=${payload.mobileNumber}&authority=${payload.authority}&first name=${payload.firstName}&last name=${payload.lastName}&stn code=${payload.stationCode}&division code=${payload.divisionCode}&zone code=${payload.zoneCode}`;
      }
      apiMethod(url)
        .then((res) => {
          if (res) {
            setState(initialState);
            onClose(true);
            update(successType, "success");
          }
        })
        .catch((err) => {
          const errMsg = err?.response?.data?.error?.message.slice(0, 15);
          update(errMsg, "error");
        });
    }
  };

  const onClear = () => {
    setState(initialState);
    getZone();
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="responsive-dialog-title" className="flex">
          {label}
          <CloseIcon
            className="pointer"
            sx={{ ml: "auto" }}
            onClick={() => {
              onClose(false);
            }}
          />
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size="small"
                sx={{ minWidth: 250, maxWidth: 250 }}
                onChange={(e) => {
                  setState((draft) => {
                    draft.firstName = e.target.value;
                  });
                }}
                required
                error={state.firstNameError}
                value={state.firstName}
                label={"First Name"}
                helperText={state.firstNameErrorText}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                sx={{ minWidth: 250, maxWidth: 250 }}
                onChange={(e) => {
                  setState((draft) => {
                    draft.lastName = e.target.value;
                  });
                }}
                required
                error={state.lastNameError}
                value={state.lastName}
                label={"Last Name"}
                helperText={state.lastNameErrorText}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                sx={{ minWidth: 250, maxWidth: 250 }}
                onChange={(e) => {
                  setState((draft) => {
                    draft.email = e.target.value;
                  });
                }}
                inputProps={{
                  type: "email",
                }}
                required
                error={state.emailError}
                type={"mail"}
                value={state.email}
                label={"Email"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                sx={{ minWidth: 250, maxWidth: 250 }}
                value={state.mobileNumber}
                error={state.mobileNumberError}
                label="Mobile Number"
                onChange={handleInputChange}
                required
                // placeholder=" number"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" sx={{ minWidth: 250, maxWidth: 250 }}>
                <InputLabel id="standard-label">User Role</InputLabel>
                <Select
                  labelId="select-standard-label"
                  id="select-standard"
                  value={state.authority}
                  required
                  error={state.zoneCodeError}
                  onChange={(e) => {
                    setState((previousState) => {
                      previousState.authority = e?.target?.value;
                    });
                  }}
                  label="User Role"
                >
                  {state.userRoleList?.map((value, index) => (
                    <MenuItem key={value + index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" sx={{ minWidth: 250, maxWidth: 250 }}>
                <InputLabel id="standard-label">Zone Code</InputLabel>
                <Select
                  multiple
                  labelId="select-standard-label"
                  id="select-standard"
                  value={state.zoneCode}
                  required
                  error={state.zoneCodeError}
                  onChange={(e) => {
                    setState((previousState) => {
                      previousState.zoneCode = e?.target?.value;
                    });
                  }}
                  label="zone Code"
                >
                  {state.zoneList?.map((zone, index) => (
                    <MenuItem key={zone + index} value={zone}>
                      {zone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" sx={{ minWidth: 250, maxWidth: 250 }}>
                <InputLabel id="standard-label">Division Code</InputLabel>
                <Select
                  multiple
                  labelId="select-standard-label"
                  id="select-standard"
                  value={state.divisionCode}
                  required
                  error={state.divisionCodeError}
                  onChange={(event) => {
                    setState((draft) => {
                      draft.divisionCode = event?.target?.value;
                    });
                  }}
                  label="division Code"
                >
                  {state.divisionList?.map((division, index) => (
                    <MenuItem key={division + index} value={division}>
                      {division}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" sx={{ minWidth: 250, maxWidth: 250 }}>
                <InputLabel id="standard-label">Station Code</InputLabel>
                <Select
                  multiple
                  labelId="select-standard-label"
                  id="select-standard"
                  value={state.stationCode}
                  required
                  error={state.stationCodeError}
                  onChange={(event) => {
                    setState((state) => {
                      state.stationCode = event?.target?.value;
                    });
                  }}
                  label="Station Code"
                >
                  {state.stationList.length > 0 && (
                    <MenuItem>
                      <ListItemText
                        primary={state.stationCode.length === state.stationList.length ? "Deselect All" : "Select All"}
                        onClick={(event) => {
                          event.stopPropagation();
                          const _stationList =
                            state.stationCode.length === state.stationList.length ? [] : state.stationList;
                          setState((state) => {
                            state.stationCode = _stationList;
                          });
                        }}
                      ></ListItemText>
                    </MenuItem>
                  )}
                  {state.stationList.map((station, index) => (
                    <MenuItem key={station + index} value={station}>
                      {station}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!editRow && (
              <>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    sx={{ minWidth: 250, maxWidth: 250 }}
                    onChange={(event) => {
                      setState((previousState) => {
                        previousState.password = event.target.value;
                      });
                    }}
                    inputProps={{
                      type: "password",
                    }}
                    required
                    error={state.passwordError}
                    value={state.password}
                    label={"password"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    sx={{ minWidth: 250, maxWidth: 250 }}
                    onChange={(event) => {
                      setState((previousState) => {
                        previousState.confirmPassword = event.target.value;
                      });
                    }}
                    inputProps={{
                      type: "password",
                    }}
                    required
                    error={state.confirmPasswordError}
                    helperText={state.confirmPasswordError}
                    value={state.confirmPassword}
                    label={"Confirm Password"}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={onClear}>
            Clear
          </Button>
          <Button variant="contained" autoFocus onClick={onSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddUser;
