import { FIXED_VIRTUAL_BUFFER_BETWEEN_GROUP, FIXED_VIRTUAL_SPACE_FOR_EACH_GROUP, LINE_COLORS } from "./keyGroupingConfig";

const isValidNum = (num) => {
    let isNum = false;
    if (num || num === 0) {
        const _number = Number(num);
        return !isNaN(_number);
    }
    return isNum;
};

export const getGroupWiseMinMax = (groups) => {
    const _groupWiseMinMax = {};
    Object.entries(groups).forEach(([groupIndex, groupInfo]) => {
        const minValue = groupIndex * (FIXED_VIRTUAL_SPACE_FOR_EACH_GROUP + FIXED_VIRTUAL_BUFFER_BETWEEN_GROUP);
        const maxValue = minValue + FIXED_VIRTUAL_SPACE_FOR_EACH_GROUP;
        _groupWiseMinMax[groupIndex] = { virtualMin: minValue, virtualMax: maxValue };
    });
    return _groupWiseMinMax;
};

const getTransformedPacket = (packet, keys, groupVirtualThreshold, actualUnitsForYaxis, TOTAL_KEYS) => {
    const { GEARTYPE } = packet;

    if (GEARTYPE !== "VIB") {
        const _packet = {};
        keys.forEach((keyName) => {
            let packetValue = packet?.[keyName];
            const _isValidNum = isValidNum(packetValue);
            if (_isValidNum) {
                const divide = TOTAL_KEYS[keyName].divide;
                packetValue = divide === true ? Number(packetValue) / 1000 : Number(packetValue);
                const keyStartVal = groupVirtualThreshold.virtualMin;
                const transFormaRatio = FIXED_VIRTUAL_SPACE_FOR_EACH_GROUP / actualUnitsForYaxis;
                const transformedValue = keyStartVal + transFormaRatio * packetValue;
                _packet[keyName] = transformedValue;
            } else {
                _packet[keyName] = null;
            }
        });
        return _packet;
    }
};

export const getTransformedHistoryData = (rawData, groupWiseVirtualMinMax, keyGroups, TOTAL_KEYS) => {
    const parsedData = {};
    Object.entries(keyGroups).forEach(([groupIndex, groupInfo]) => {
        const { keys, actualUnitsForYaxis, unit } = groupInfo;
        const groupVirtualThreshold = groupWiseVirtualMinMax[groupIndex];

        const parsedDataMap = {};
        rawData.forEach((rawPacket) => {
            const ts = rawPacket?.data?.[0]?.["dateTime"];
            const packet = rawPacket?.data?.[0]?.["values"];
            const transFormedPacket = getTransformedPacket(
                packet,
                keys,
                groupVirtualThreshold,
                actualUnitsForYaxis,
                TOTAL_KEYS,
            );
            if (transFormedPacket) {
                // parsedDataMap[ts] = transFormedPacket;
                if (parsedData[ts]) {
                    parsedData[ts] = { ...parsedData[ts], ...transFormedPacket };
                } else {
                    parsedData[ts] = transFormedPacket;
                }
            }
        });
    });
    const persedSortedTs = Object.keys(parsedData).sort((a, b) => a - b);
    const parsedSortedMap = {};
    persedSortedTs.forEach((sortedKey) => {
        parsedSortedMap[sortedKey] = parsedData[sortedKey];
    });

    return parsedSortedMap;
};

export const getChartLabelAndDatasets = (parsedTsDataMap, totalKeys) => {
    const keysDataMap = {};
    const timestampChartLabels = [];
    const dataSets = []
    Object.entries(parsedTsDataMap).forEach(([timestamp, dataPacket]) => {
        timestampChartLabels.push(new Date(Number(timestamp)).toLocaleString("en-GB"));
        Object.entries(totalKeys).forEach(([keyName, keyObj]) => {
            if (keysDataMap[keyName]) {
                keysDataMap[keyName].push(dataPacket[keyName]);
            } else {
                keysDataMap[keyName] = [dataPacket[keyName]];
            }
        });
    });
    Object.entries(totalKeys).forEach(([keyName, keyObj], i) => {

        const dataSet = {
            label: keyObj?.label ?? keyName,
            data: keysDataMap[keyName],
            dataKey: keyName,
            borderColor: LINE_COLORS[i] ?? "grey",
            backgroundColor: LINE_COLORS[i] ?? "grey",
            borderWidth: 3,
            fill: false,
            pointRadius: 1.5,
            pointHoverRadius: 1.5,
            spanGaps: true,
        };
        dataSets.push(dataSet)
    });
    return { dataSets: dataSets, timestampChartLabels: timestampChartLabels }
};

export function getMaxValueOfYaxis(totalKeys) {
    const total_virtual_space = Object.keys(totalKeys).length * FIXED_VIRTUAL_SPACE_FOR_EACH_GROUP;
    const extra_space_on_y_axis = 100;
    const total_virtual_buffer = (Object.keys(totalKeys).length - 1) * FIXED_VIRTUAL_BUFFER_BETWEEN_GROUP;
    const maxYAxisValue = total_virtual_space + total_virtual_buffer + extra_space_on_y_axis;
    return maxYAxisValue;
}

export const retrieveTransformedValue = (tooltipKey, unformattedValue, groupWiseVirtualMinMax, totalGroupes) => {
    let formattedValue = null
    Object.entries(totalGroupes).forEach(([groupIndex, groupInfo]) => {
        const { keys, actualUnitsForYaxis, unit } = groupInfo
        if (keys.includes(tooltipKey)) {
            const virtualMinMax = groupWiseVirtualMinMax[groupIndex]
            const TransformationConstantToBeMinus = virtualMinMax.virtualMin;
            const keysUnitSize = actualUnitsForYaxis;
            const retrivalRatio = keysUnitSize / FIXED_VIRTUAL_SPACE_FOR_EACH_GROUP;
            let retrivedValue = (unformattedValue - TransformationConstantToBeMinus) * retrivalRatio;
            formattedValue = Number(retrivedValue).toFixed(1) + ' ' + unit
        }
    })
    return formattedValue
}

export const getGroupedChartAnnotation = (groupWiseVirtualMinMax, maxOnYaxis, totalGroupes) => {
    const annotations = {}
    Object.entries(groupWiseVirtualMinMax).forEach(([groupIndex, virtualMinMax], i) => {
        const keys = totalGroupes[groupIndex].keys.join(', ')
        const { virtualMin, virtualMax } = virtualMinMax
        let box = {
            type: "box",
            yMin: virtualMin,
            yMax: virtualMax,
            borderWidth: 0.5,
            backgroundColor: "#8080801f",
            label: {
                display: true,
                content: [keys],
                backgroundColor: "transparent",
                color: "black",
                position: "center",
                yAdjust: -73,
                font: { size: 12 },
            },

        };
        // let lable = {
        //     type: "text",
        //     yMin: virtualMin,
        //     yMax: virtualMax,
        //     borderWidth: 0.5,
        //     backgroundColor: "#8080801f",

        // };
        annotations[`box${i + 1}`] = box
    })
    return annotations
}