import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "./PopUp.scss";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { FormContext } from "../../FormCreator";
import { useImmer } from "use-immer";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactivePopMarkup from "./ReactivePopMarkup";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FieldAnalytics from "./Analytics/FieldAnalytics";
import DisplayField from "./DisplayTab/DisplayField";
import ValuesRangeTab from "./ValuesRangeTab/ValuesRangeTab";

// function replaceObjectByKey(arr, keyToMatch, newObject) {
//   for (let i = 0; i < arr.length; i++) {
//     if (arr[i].key === keyToMatch) {
//       arr[i] = newObject;
//       return arr;
//     }
//     if (arr[i].sectionFields) {
//       replaceObjectByKey(arr[i].sectionFields, keyToMatch, newObject);
//     }
//   }
//   return arr;
// }
function replaceObjectByKey(json, keyToReplace, newObject) {
  return json.map(obj => {
    if (obj.key === keyToReplace) {
      return { ...newObject };
    }

    if (obj.sectionFields) {
      return { ...obj, sectionFields: replaceObjectByKey(obj.sectionFields, keyToReplace, newObject) };
    }

    return obj;
  });

}
const fileTypeOptions = [
  { label: 'ALL', value: 'allFiles' },
  { label: 'Image file', value: ['.IMG', '.PNG', '.JPG', '.JPEG'] },
  { label: 'Document file', value: ['.PDF', '.XLR', '.XLS', 'XLSX'] },
]

const tabsVisibity = {
  rangeTab: ['date', 'number'], analyticsTab: ['number', 'textfield', 'dropdown', 'reactive_dropdown', 'iot_field']

}

const ANALYTICSTYPE = {
  textfield: 'categorical',
  number: 'numeric',
  dropdown: 'categorical',
  'reactive_dropdown': 'categorical',
  'iot_field': 'numeric',

}
const labelField = {
  id: "label",
  type: "textfield",
  label: "Label",
  placeholder: "Label for the field",
};
const placeHolderField = {
  id: "placeholder",
  type: "textfield",
  label: "placeHolder",
  placeholder: "Placeholder for the field",
};
const requirdField = { id: "required", type: "checkbox", label: "Required While Filling" };
const multiSelecetField = { id: "multiple", type: "checkbox", label: "multi select", option: [] };
const showInitiallyField = { id: "showInitially", type: "checkbox", label: "Show initially", option: [] };
const imageQualityField = { id: 'image_quality', type: 'number', label: 'Image Quality' }
const iotFields = [
  {
    id: "iot-key",
    type: "textfield",
    label: "IOT KEY",
    placeholder: "Key for IOT data",
  },
  {
    id: "Unit",
    type: "dropdown",
    label: "Select Unit",
    options: [
      { label: "S", value: "S" },
      { label: "mS", value: "mS" },
      { label: "A", value: "A" },
      { label: "mA", value: "mA" },
      { label: "V", value: "V" },
      { label: "mV", value: "mV" },
    ],
  },
];
let showOnTableField = [{ id: "showInTable", type: "checkbox", label: "Show in table", option: [] }];
let fileTypeField = { id: "fileType", type: "dropdown", label: "File Types", options: fileTypeOptions };
const inititialPopupFormState = {}
function PopUp({ open, onClose, data, targetId, editInfo }) {
  let renderFields = [];
  const {
    register,
    // handleSubmit,
    reset,
    formState: { errors },
    // setValue,
  } = useForm();
  const { manipulateFormData, formData, isAssetForm, replaceFormData } = useContext(FormContext);
  const [popupFormState, setPopupFormState] = useImmer(null)
  console.log('popupFormState', popupFormState, formData)
  const [fetchInfoRequired, setFetchInfoRequired] = useState(false)
  const [optionValue, setOptionValue] = useState("");
  const [options, setOptions] = useImmer([]);
  const [parentFieldData, setParentFieldData] = useImmer(null);
  // const {isAssetForm} = useContext(FormContext);
  // const [reactiveData, setReactiveData] = useState(null);
  // const [iotKeyUnit, setIotKeyUnit] = useState("");
  const [tab, setTab] = React.useState('display');

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };


  const getListFromFieldGroup = (fieldList) => {

    let options = [];
    fieldList?.forEach((field, i) => {

      if (field.type !== "heading" && field.type !== "sub_heading") {
        if (field.type === "section" || field.type === "reactive_section") {
          options = [...options, ...getListFromFieldGroup(field.sectionFields)];
        } else {
          if (editInfo) {
            if (editInfo.key !== field.key) {
              options.push({ label: field.label, value: field.key });
            }
          } else {

            options.push({ label: field.label, value: field.key });
          }

        }
      }


    });

    return options;
  };

  const getParentFieldInfo = (key, allFields) => {
    let parentField;
    allFields?.forEach((field, i) => {
      if (field.type === "section" || field.type === 'reactive_section') {
        parentField = getParentFieldInfo(key, field.sectionFields);
      } else {
        if (field.key === key) {
          setParentFieldData(field);
        } else {
        }
      }
    });
  };
  if (isAssetForm) {
    showOnTableField = []
  }

  if (data.type === "heading" || data.type === "sub_heading" || data.type === "section" || data.type === "date") {
    renderFields = [labelField];
  }
  if (data.type === "date") {
    renderFields = [labelField, requirdField, ...showOnTableField];
  }
  if (data.type === "textfield" || data.type === "number") {
    renderFields = [labelField, placeHolderField, requirdField, ...showOnTableField];
  }
  if (data.type === "dropdown") {
    renderFields = [labelField, { type: 'dropdownMarkup' }, multiSelecetField, requirdField, ...showOnTableField];
  }
  if (data.type === "image_capture") {
    renderFields = [labelField, imageQualityField, requirdField, ...showOnTableField];
  }
  if (data.type === "reactive-dropdown" || data.type === "reactive_dropdown") {
    renderFields = [
      labelField,
      { id: "parent-field", type: "dropdown", label: "parent-field", options: getListFromFieldGroup(formData) },
      { type: 'reactiveDropdownMarkup' },
      showInitiallyField,
      requirdField,
      ...showOnTableField,
    ];
  }
  if (data.type === "reactive_section" || data.type === "reactive_textfield" || data.type === "reactive_number" || data.type === "reactive_date") {
    renderFields = [
      labelField,
      { id: "parent-field", type: "dropdown", label: "parent-field", options: getListFromFieldGroup(formData) },
      { type: 'reactiveSectionMarkup' },
      showInitiallyField,
    ];
  }
  if (data.type === "iot-field" || data.type === "iot_field") {
    renderFields = [labelField, placeHolderField, ...iotFields, requirdField, ...showOnTableField];
  }
  if (data.type === "attachment") {
    renderFields = [labelField, fileTypeField, multiSelecetField, requirdField, ...showOnTableField];
  }
  const getAnalyticsData = (data) => {
    if (Object.values(data.ranges).length > 0) {
      setPopupFormState((prev) => {
        prev.analyticsData = data
      })
    }

  }
  const getValueRangesData = (data) => {
    setPopupFormState((prev) => {
      prev.conditions = data
    })
  }
  const handleSubmit = (popupformState) => {
    if (editInfo) {
      let newFormData = [...formData]
      newFormData = replaceObjectByKey(newFormData, editInfo.key, popupformState)
      replaceFormData(newFormData)
    } else {
      manipulateFormData(popupFormState, targetId);

    }
    onClose();
    setPopupFormState({})
  }
  const fetchDataFromComponents = (copmponentStateData) => {
    let key = uuid()
    if (editInfo) {
      key = editInfo.key
    }
    setPopupFormState({ type: data.type, key: uuid(), ...popupFormState, ...copmponentStateData })
  }
  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth={"md"}
    >
      <DialogTitle>{data.label}</DialogTitle>
      <DialogContent
        dividers
        className="popup-container"
      >
        <Tabs
          size='small'
          value={tab}
          onChange={handleChange}
        >
          <Tab value="display" label="Display" />
          {(tabsVisibity.rangeTab.includes(data.type) && !editInfo) && (<Tab value="valueRanges" label="Validation" />)}
          {(tabsVisibity.analyticsTab.includes(data.type) && !editInfo) && (<Tab value="analytics" label="Analytics" />)}


        </Tabs>
        <div className={`displayTab ${tab === 'display' ? 'show' : 'hide'}`}>
          <DisplayField editInfo={editInfo} displayRenderFields={renderFields} fieldNodeInfo={data} fetchInfoRequired={fetchInfoRequired} fetchData={(copmponentStateData) => fetchDataFromComponents(copmponentStateData)} />
        </div>
        <div className={`valuesRangeTab ${tab === 'valueRanges' ? 'show' : 'hide'}`}>
          <ValuesRangeTab getValuesRange={getValueRangesData} fieldNodeInfo={data} />
        </div>
        <div className={`analyticsTab ${tab === 'analytics' ? 'show' : 'hide'}`}>
          <FieldAnalytics getAnalyticsData={getAnalyticsData} analyticsType={ANALYTICSTYPE[data.type]} />
        </div>

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          close
        </Button>
        <Button variant="contained" onClick={() => { handleSubmit(popupFormState) }}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PopUp;
