import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import CloseIcon from "@mui/icons-material/Close";
import {
  APP_BASE_URL,
  RTU_CREDS_API,
} from "../../Common/EndPoints";
import axios from "axios";
import "./IotCredPopUp.scss";
import { Transition } from "../../Modal/ModalConstants";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { notifyAssetUpdate } from "./AssetRegister/assetRegisterUtils";

const initialCredState = {
  cred: "",
  credError: false,
  credData: [],
  credId: "",
};
const intitialSnackbarState = {
  open: true,
  severityType: "",
  snackbarMessage: "",
};
let initialConfirmationModal = {
  open: false,
  message: "",
  des: "",
  actionType: "",

};
function IotCredPopUp({ open, onClose, label, deviceType, deviceId }) {
  const [credState, setCredState] = useImmer(initialCredState);
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [snackBarState, setSnackBarState] = useImmer(intitialSnackbarState);
  const [confirmationModal, setConfirmationModal] = useImmer(initialConfirmationModal);

  useEffect(() => {
    getCredentials();
  }, []);
  const getFormattedDateTime = (ts) => {
    const today = new Date(ts);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    const time = new Date(ts).toLocaleTimeString()
    return formattedToday + ' ' + time
  }
  const validateForm = () => {
    let isValid = true;
    if (!credState.cred || credState.cred.split('_').length !== 3) {
      isValid = false;
    }
    setCredState((draft) => {
      draft.credError = !credState.cred || credState.cred.split('_').length !== 3
    });
    return isValid;
  };
  const getCredentials = () => {
    const url = `${APP_BASE_URL}${RTU_CREDS_API}${deviceId}/rtu_cred`;
    axios.get(url).then((res) => {
      let data = res.data.data;
      data.forEach((row) => {
        row["loading"] = false;
      });
      setCredState((draft) => {
        draft.credData = data;
      });
    });
  };
  const closeModal = () => {
    onClose(false);
  };
  const uploadRtuCred = (id) => {
    const url = `${APP_BASE_URL}${RTU_CREDS_API}${id}/rtu_cred?token=${credState.cred}&device_type=${deviceType}`;
    setUploadingLoading(true);
    axios
      .post(url)
      .then((res) => {
        getCredentials();
        // notifyAssetUpdate()
        setCredState((draft) => {
          draft.cred = "";
        });
        setUploadingLoading(false);
        setSnackBarState((draft) => {
          draft.open = true;
          draft.severityType = "success";
          draft.snackbarMessage = "Credential Uploded Successfully..";
        });
      })
      .catch((err) => {
        setSnackBarState((draft) => {
          draft.open = true;
          draft.severityType = "error";
          draft.snackbarMessage = "Failed to upload credential..";
        });
      })
      .then(() => {
        setCredState((draft) => {
          draft.cred = "";
        });
        setUploadingLoading(false);
      });
  };
  const deleteRtueCred = (credId, index) => {
    let rowToBeDelete = [...credState.credData];
    rowToBeDelete[index] = { ...rowToBeDelete[index], loading: true };
    setCredState((draft) => {
      draft.credData = rowToBeDelete;
    });
    const url = `${APP_BASE_URL}${RTU_CREDS_API}${deviceId}/rtu_cred/${credId}`;
    axios
      .delete(url)
      .then((res) => {
        getCredentials();
        // notifyAssetUpdate()
        setSnackBarState((draft) => {
          draft.open = true;
          draft.severityType = "success";
          draft.snackbarMessage = "Credential deleted Successfully..";
        });
      })
      .catch(() => {
        setSnackBarState((draft) => {
          draft.open = true;
          draft.severityType = "error";
          draft.snackbarMessage = "Failed to delted Credential..";
        });
      })
      .then(() => {
        rowToBeDelete[index] = { ...rowToBeDelete[index], loading: false };
        setCredState((draft) => {
          draft.credData = rowToBeDelete;
        });
      });
  };

  const handleConfirm = () => {
    if (confirmationModal.actionType === 'add') {
      uploadRtuCred(confirmationModal.deviceId);
    }
    if (confirmationModal.actionType === 'delete') {
      deleteRtueCred(confirmationModal.credId, confirmationModal.credIndex);
    }
  }
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="responsive-dialog-title" className="flex">
          {label}
          <CloseIcon
            className="pointer"
            sx={{ ml: "auto" }}
            onClick={() => {
              onClose(false);
            }}
          />
        </DialogTitle>
        <DialogContent className="mainContentContainer" dividers>
          <div className="cred-text-btn-container flex">
            <TextField
              // fullWidth
              sx={{ width: "100%" }}
              className="credTextField"
              onChange={(e) => {
                setCredState((draft) => {
                  draft.cred = e.target.value.toUpperCase()
                });
              }}
              required
              error={credState.credError}
              value={credState.cred}
              label={"RTU Cred"}
              placeholder="Format {STN}_{GEARTYPE}_{NAME}"
              helperText={credState.credError ? "Please enter text in STN_GEARTYPE_NAME format" : " "}
            />
            <Button
              className='add-btn'
              onClick={() => {
                if (validateForm()) {
                  // uploadRtuCred(deviceId);
                  setConfirmationModal((prev) => {
                    prev.open = true;
                    prev.message = "Asset";
                    prev.des = `Do you want to ADD RTU Cred.?`;
                    prev.actionType = "add";
                    prev.deviceId = deviceId;

                  });
                }
              }}
              variant="contained"
            >
              ADD
            </Button>
          </div>

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Created Time</th>
                  <th>RTU Cred.</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {credState.credData.map((data, i) => {
                  let credId = data.id;
                  return (
                    <tr key={i}>
                      <td>{getFormattedDateTime(data.created_time)}</td>
                      <td>{data.credentials_value}</td>
                      <td className="pointer">
                        {data.loading ? (
                          <div className="lds-ring">
                            <div></div>
                          </div>
                        ) : (
                          <IconButton
                            onClick={() => {
                              // deleteRtueCred(credId, i);
                              setConfirmationModal((prev) => {
                                prev.open = true;
                                prev.message = "Asset";
                                prev.des = "Do you want to delete RTU Cred.?";
                                prev.actionType = "delete";
                                prev.credId = credId;
                                prev.credIndex = i;
                              });
                            }}
                          >
                            <DeleteIcon sx={{ color: '#d80000' }} />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* </div> */}
        </DialogContent>
        <DialogActions className="dialogActionBtn" style={{ padding: "1em" }}>
          {uploadingLoading && (
            <div className="flex alignCenter">
              <div>Uploading</div>
              <div className="lds-ring">
                <div></div>
              </div>
            </div>
          )}
          <Button
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </Button>
        </DialogActions>
        <Snackbar
          open={snackBarState.open}
          autoHideDuration={4000}
          onClose={() => {
            setSnackBarState((draft) => {
              draft.open = false;
            });
          }}
        >
          <Alert severity={snackBarState.severityType} sx={{ width: "100%" }}>
            {snackBarState.snackbarMessage}
          </Alert>
        </Snackbar>
      </Dialog>
      {confirmationModal && (
        <ConfirmationModal
          open={confirmationModal.open}
          onClose={() => {
            setConfirmationModal(initialConfirmationModal);
          }}
          onAction={(confirmation) => {
            if (confirmation) {
              handleConfirm()
            }
            setConfirmationModal(initialConfirmationModal);
          }}
          message={confirmationModal.message}
          des={confirmationModal.des}
        />
      )}
    </>
  );
}

export default IotCredPopUp;
