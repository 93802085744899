import axios from "axios";
import DataTable from "../DataGrid/DataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";

import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import Tooltip from "@mui/material/Tooltip";

import "./Roster.scss";
import { useImmer } from "use-immer";
import { APP_BASE_URL, GET_ROSTER_DATA_BY_ID, trimAssetName } from "../../Common/EndPoints";
import AckPopup from "./AckPopup";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { useContext, useEffect, useState } from "react";

const initialState = {
  rosterData: [],
  showAckModal: false,
  selectedDeviceId: null,
  customerIdList: [],
  stationList: [],
};

function MaintenanceRoster() {
  const [rosterState, setRosterState] = useImmer(initialState);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const { customerIdList } = useContext(ActiveAlarmsData);

  useEffect(() => {
    const controller = new AbortController();
    if (customerIdList.length > 0) {
      GetRosterData(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [customerIdList, pageSize, pageIndex]);

  const rosterColumn = [
    {
      field: "timestamp",
      headerName: "Generation Time",
      sortable: true,
      minWidth: 200,
      flex: 1,
      type: "date",
      valueFormatter: (param) => {
        if (param.value && param.value > 0) {
          const value = Number(param.value);
          const date = new Date(value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = "0" + dd;
          if (mm < 10) mm = "0" + mm;

          const formattedToday = dd + "/" + mm + "/" + yyyy;
          const time = date.toLocaleTimeString();
          return formattedToday + " " + time;
        } else {
          return "-";
        }
      },
    },
    {
      field: "station",
      headerName: "Station",
      sortable: true,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "gearType",
      headerName: "GearType",
      sortable: true,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "gearName",
      headerName: "GearName",
      sortable: true,
      minWidth: 230,
      flex: 1,
    },

    {
      field: "periodicity",
      headerName: "Periodicity",
      sortable: true,
      minWidth: 117,
      flex: 1,
    },
    {
      field: "scheduled_code",
      headerName: "Scheduled Code",
      sortable: true,
      minWidth: 135,
      flex: 1,
    },
    {
      field: "authorised_person",
      headerName: "Authorised Person",
      sortable: true,
      minWidth: 140,
      flex: 1,
    },
    {
      field: "last_maintenance_date",
      headerName: "Last Maintenance Date",
      sortable: true,
      minWidth: 170,
      flex: 1,
    },
    {
      field: "next_maintenance_date",
      headerName: "Next Maintenance Date",
      sortable: true,
      minWidth: 170,
      flex: 1,
    },
    {
      field: "current_status",
      headerName: "Current Status",
      sortable: true,
      minWidth: 120,
      flex: 1,
    },

    {
      field: "health",
      headerName: "Health",
      sortable: true,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "proposed_maintenance",
      headerName: "Proposed Maintenance",
      sortable: true,
      minWidth: 170,
      flex: 1,
    },
    {
      field: "probability_of_failure",
      headerName: "Probability of Failure",
      sortable: true,
      minWidth: 170,
      flex: 1,
    },
    {
      field: "last_failure_date",
      headerName: "Last Failure Date",
      sortable: true,
      minWidth: 160,
      flex: 1,
    },
    {
      field: "suggestion",
      headerName: "Suggestion",
      sortable: true,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Tooltip placement="top-start" title={params.row.suggestion} disableInteractive>
          <span className="table-cell-trucate">{params.row.suggestion}</span>
        </Tooltip>
      ),
    },
    {
      field: "alarm",
      headerName: "Alarm Since Last Maintenance",
      sortable: true,
      minWidth: 170,
      flex: 1,
      renderCell: (params) => (
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "0px auto",
          }}
        >
          {params?.value?.map((d) => {
            return (
              <li
                style={{
                  color: d.includes("WARNING") ? "orange" : d.includes("CRITICAL") ? "red" : "green",
                  fontSize: "13px",
                }}
              >
                {d}
              </li>
            );
          })}
        </ul>
      ),
    },

    {
      field: "remarks",
      headerName: "Remarks",
      sortable: true,
      minWidth: 120,
      flex: 1,
    },
  ];
  const access = window.frontendAccess?.ASSET;
  const allowUpdateAssets = access?.update ?? true;
  if (allowUpdateAssets) {
    rosterColumn.push({
      field: "rosterActions",
      type: "actions",
      minWidth: 110,
      hideable: false,
      renderCell: (params) => {
        <>
          <Tooltip placement="left" title={"Acknowledge"} disableInteractive>
            <GridActionsCellItem
              icon={<BuildCircleIcon />}
              label="ACK"
              onClick={(e) => {
                setRosterState((draft) => {
                  draft.showAckModal = true;
                  draft.selectedDeviceId = params.row.deviceid;
                });
              }}
              disabled={params.row.remarks !== "-"}
            />
          </Tooltip>
        </>;
      },
    });
  }
  const GetRosterData = (signal) => {
    setLoading(true);
    let url = `${APP_BASE_URL}${GET_ROSTER_DATA_BY_ID}?customerIds=${customerIdList.toString()}&page=${pageIndex + 1
      }&pageSize=${pageSize}`;
    axios
      .get(url, { signal })
      .then((res) => {
        setRowCount(res.data.data.countRecords);
        const data = res.data.data.data.map((d, i) => {
          const alarm = d.alarmSinceLastMaint
            ? Object.entries(d.alarmSinceLastMaint).map(([key, value]) => {
              return `${key}`.split("C")[0].toLocaleUpperCase() + ":" + `${value}`;
            })
            : null;
          return {
            id: i,
            deviceid: d.deviceId,
            timestamp: d.timestamp,
            station: d.station ? d.station : "-",
            gearType: d.deviceType?.label ?? "-",
            gearName: trimAssetName(d.gearName),
            periodicity: d.periodicity ? d.periodicity + " " + "days" : "-",
            scheduled_code: d.scheduledCode ? d.scheduledCode : "-",
            authorised_person: d.authorizedPerson ? d.authorizedPerson : "-",
            last_maintenance_date: d.periodicLastMaint ? d.periodicLastMaint.split('.')[0] : "-",
            next_maintenance_date: d.periodicNextMaint ? d.periodicNextMaint.split(' ')[0] : "-",
            current_status: d.periodicCurrentStatus ? d.periodicCurrentStatus : "-",
            health: d.health ? d.health.toFixed(2) : "-",
            proposed_maintenance: d.proposedMaintenanceDt ? d.proposedMaintenanceDt : "-",
            probability_of_failure: d.failureProbability ? d.failureProbability : "-",
            last_failure_date: d.lastFailureDt ? d.lastFailureDt : "-",
            suggestion: d.suggestion ? d.suggestion : "-",
            alarm: alarm,
            remarks: d.remarks ? d.remarks : "-",
          };
        });
        setRosterState((draft) => {
          draft.rosterData = data;
        });
      })
      .catch(() => { })
      .then(() => {
        setLoading(false);
      });
  };
  const columnGroupingModel = [
    {
      groupId: "GENERAL INFORMATION",
      headerAlign: "center",
      align: "center",
      disableExport: false,
      headerClassName: "groupHeader",
      children: [
        { field: "timestamp", headerAlign: "center", align: "center" },
        { field: "station" },
        { field: "gearType" },
        { field: "gearName" },
      ],
    },
    {
      groupId: "PERIODIC MAINTENANCE",
      headerAlign: "center",
      disableExport: false,
      headerClassName: "groupHeader",
      children: [
        { field: "periodicity" },
        { field: "scheduled_code" },
        { field: "authorised_person" },
        { field: "last_maintenance_date" },
        { field: "next_maintenance_date" },
        { field: "current_status" },
      ],
    },
    {
      groupId: "PREDICTIVE MAINTENANCE",
      headerAlign: "center",
      disableExport: false,
      headerClassName: "groupHeader",
      children: [
        { field: "health" },
        { field: "proposed_maintenance" },
        { field: "probability_of_failure" },
        { field: "last_failure_date" },
        { field: "suggestion" },
        { field: "alarm" },
        { field: "remarks" },
      ],
    },
    {
      groupId: "  ",
      headerAlign: "center",
      headerClassName: "groupHeader",
      children: [{ field: "rosterActions" }],
    },
  ];

  const handleCloseAckModal = () => {
    setRosterState((draft) => {
      draft.showAckModal = false;
    });
  };

  return (
    <>
      {rosterState.showAckModal && (
        <AckPopup
          open={true}
          label={"Acknowledge"}
          onClose={handleCloseAckModal}
          selectedDeviceId={rosterState.selectedDeviceId}
          ackStatus={(status) => {
            GetRosterData();
          }}
        />
      )}

      <DataTable
        tableInfo={{
          id: `maintenance-roaster-table`,
          label: `Maintenance Roaster`,
          requireAuthSign: true,
          timestamp: null,
        }}
        loading={loading}
        rows={rosterState.rosterData}
        columns={rosterColumn}
        columnGroupingModel={columnGroupingModel}
        paginationMode={"server"}
        pageSize={pageSize}
        pageIndex={pageIndex}
        rowCount={rowCount}
        pageChange={(index) => {
          setPageIndex(index);
        }}
        pageSizeChange={(size) => {
          setPageSize(size);
        }}
      />
    </>
  );
}

export default MaintenanceRoster;
