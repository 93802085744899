export let NOQ_MAPPING = {
    PM101: 'NOQ_PM_101',
    PM131: 'NOQ_PM_131',
    PM134: 'NOQ_PM_134',
    PM136: 'NOQ_PM_136',
    PM135: 'NOQ_PM_135',
    PM114: 'NOQ_PM_114',
    PM137: 'NOQ_PM_137',
    PM102: 'NOQ_PM_102',
    PM106: 'NOQ_PM_106',
    PM132: 'NOQ_PM_132',
    PM115: 'NOQ_PM_115',
    PM108: 'NOQ_PM_108',
    PM109: 'NOQ_PM_109',
    PM107: 'NOQ_PM_107',
    PM111: 'NOQ_PM_111',
    PM104: 'NOQ_PM_104',
    PM116: 'NOQ_PM_116',
    PM112: 'NOQ_PM_112',
    PM105: 'NOQ_PM_105',
    PM138: 'NOQ_PM_138',
    PM113: 'NOQ_PM_113',
    TC101T: 'NOQ_TC_101T',
    TC104T: 'NOQ_TC_104T',
    TC105T: 'NOQ_TC_105T',
    TC108T: 'NOQ_TC_108T',
    TC135T: 'NOQ_TC_135T',
    TC102T: 'NOQ_TC_102T',
    TC107T: 'NOQ_TC_107T',
    TC103T: 'NOQ_TC_103T',
    TC109T: 'NOQ_TC_109T',
    TC112T: 'NOQ_TC_112T',
    TC115T: 'NOQ_TC_115T',
    TC131T: 'NOQ_TC_131T',
    TC133T: 'NOQ_TC_133T',
    TC134T: 'NOQ_TC_134T',
    TC132T: 'NOQ_TC_132T',
    TC137T: 'NOQ_TC_137T',
    TC138T: 'NOQ_TC_138T',
    TC2AT: 'NOQ_TC_2AT',
    TC2T: 'NOQ_TC_2T',
    TC3T1: 'NOQ_TC_3T1',
    PM18X: 'NOQ_PM_18X',
    PM133: 'NOQ_PM_133',
    TC3T2: 'NOQ_TC_3T2',
    TC46T1T: 'NOQ_TC_46T1T',
    TC46T2T: 'NOQ_TC_46T2T',
    TC47T: 'NOQ_TC_47T',
    SGC3: 'NOQ_SG_C3',
    SGC46: 'NOQ_SG_C46',
    SGS12: 'NOQ_SG_S12',
    SGS14: 'NOQ_SG_S14',
    SGS16: 'NOQ_SG_S16',
    SGS2: 'NOQ_SG_S2',
    SGS3: 'NOQ_SG_S3',
    SGS35: 'NOQ_SG_S35',
    SGS37: 'NOQ_SG_S37',
    SGS39: 'NOQ_SG_S39',
    SGS41: 'NOQ_SG_S41',
    SGS46: 'NOQ_SG_S46',
    TCOE1T: 'NOQ_TC_OE1T',
    TCOD2T: 'NOQ_TC_OD2T',
    TCOD1T: 'NOQ_TC_OD1T',
    TCOC2T: 'NOQ_TC_OC2T',
    TCOC1T: 'NOQ_TC_OC1T',
    TCOB2T: 'NOQ_TC_OB2T',
    TCOB1T: 'NOQ_TC_OB1T',
    TCOA2T: 'NOQ_TC_OA2T',
    TCOA1T: 'NOQ_TC_OA1T',
    TCC46T: 'NOQ_TC_C46T',
    TCC3T: 'NOQ_TC_C3T',
    TC47AT: 'NOQ_TC_47AT',
    SGSDD: 'NOQ_SG_SDD',
    SGSUD: 'NOQ_SG_SUD',
    SH41: 'NOQ_SG_SH41',
    SH16: 'NOQ_SG_SH16',
    SH14: 'NOQ_SG_SH14',
    SH39: 'NOQ_SG_SH39',
    SH35: 'NOQ_SG_SH35',
    SH37: 'NOQ_SG_SH37',
    SH9: 'NOQ_SG_SH9',
    SH18: 'NOQ_SG_SH18',
    SH43: 'NOQ_SG_SH43',
    SH7: 'NOQ_SG_SH7',
    SH24: 'NOQ_SG_SH24',
    SH20: 'NOQ_SG_SH20',
    SH22: 'NOQ_SG_SH22',
    SH44: 'NOQ_SG_SH44',
    AXLNBS_DN1: 'NOQ_AC_DNBPAC3',
    AXLNBS_DN2: 'NOQ_AC_DNBPAC4',
    AXLNBS_UP1: 'NOQ_AC_UPBPAC3',
    AXLNBS_UP2: 'NOQ_AC_UPBPAC4',
    AXLSMTA_DN1: 'NOQ_AC_DNBPAC1',
    AXLSMTA_DN2: 'NOQ_AC_DNBPAC2',
    AXLSMTA_UP1: 'NOQ_AC_UPBPAC1',
    AXLSMTA_UP2: 'NOQ_AC_UPBPAC2',
    TCOE2T: 'NOQ_TC_OE2T',
    PM103: 'NOQ_PM_103',
    SGS47: 'NOQ_SG_S47',
    LCGNN90A: 'NOQ_LCG_NN90A',
    LCGNN89A: 'NOQ_LCG_NN89A'
}
