// -----------HEADRES----------
export const FIXED_COLUMNS = [
  {
    field: "lastActivityTime",
    headerName: "Last Activity Time",
    sortable: false,
    verticalAlign: "center",
    minWidth: 200,
    flex: 1,
    // valueFormatter: (params) => {
    //   let TS = params.value;
    //   if (TS && TS > 0) {
    //     const value = Number(TS);
    //     const date = new Date(value);
    //     const yyyy = date.getFullYear();
    //     let mm = date.getMonth() + 1; // Months start at 0!
    //     let dd = date.getDate();
    //     if (dd < 10) dd = "0" + dd;
    //     if (mm < 10) mm = "0" + mm;
    //     const formattedToday = dd + "/" + mm + "/" + yyyy;
    //     const time = date.toLocaleTimeString();
    //     return formattedToday + " " + time;
    //   } else {
    //     return "-";
    //   }
    // },
  },
  {
    field: "NAME",
    headerName: "Name",
    sortable: true,
    // align: "center",
    // headerAlign: "center",
    verticalAlign: "center",
    minWidth: 150,
    flex: 0.5,
  },
  // {
  //   field: "ZONE_DIV",
  //   headerName: "Group",
  //   sortable: true,
  //   // align: "center",
  //   // headerAlign: "center",
  //   verticalAlign: "center",
  //   minWidth: 150,
  //   hide: true,
  //   hideable: true,
  //   flex: 0.5,
  // },
  {
    field: "LOC",
    headerName: "Location",
    sortable: true,
    hide: false,
    hideable: true,
    minWidth: 100,
    flex: 0.5,
  },
  {
    field: "testMode",
    headerName: "Test Mode",
    sortable: true,
    minWidth: 90,
    hide: false,
    hideable: false,
    flex: 1,
    valueGetter: (param) => {
      const value = param.row.testMode;
      if (value) {
        return "ON";
      } else {
        return "OFF";
      }
    },
  },
];

export const TC_COLUMNS = [
  {
    field: "trackStatus",
    headerName: "Track Status",
    sortable: true,
    minWidth: 100,
    valueGetter: (param) => {
      const value = Number(param.row.VR);
      const stnName = param.row.NAME.split("-")[0];
      if (stnName === "MBG" || stnName === "NHGJ" || stnName === "NHLG" || stnName === "DLO") {
        if (!isNaN(value)) {
          if (param.row.VR < 900) return "Occupied";
          else return "Free";
        } else {
          return "-";
        }
      } else {
        if (!isNaN(value)) {
          if (param.row.VR < 1200) return "Occupied";
          else return "Free";
        } else {
          return "-";
        }
      }
    },
    cellClassName: (param) => {
      if (param.value === "Free") {
        return "blue";
      } else if (param.value === "Occupied") {
        return "orange";
      }
    },
  },
  {
    field: "energization",
    headerName: "Energization %",
    sortable: true,
    type: "string",
    minWdth: 160,
    valueGetter: (param) => {
      const value = param.row.VR;
      const stnName = param.row.NAME.split("-")[0];
      if (value > 0) {
        let eng = (value / 1400) * 100;
        if (stnName === "MBG" || stnName === "NHGJ" || stnName === "NHLG" || stnName === "DLO") {
          eng = (value / 500) * 100;
        }
        return eng.toFixed(0) + "%";
      }
      return "-";
    },
    cellClassName: (param) => {
      let eng = 0;
      const value = param.row.VR;
      const stnName = param.row.NAME.split("-")[0];
      if (value > 0 && (stnName === "MBG" || stnName === "NHGJ" || stnName === "NHLG" || stnName === "DLO")) {
        eng = (value / 500) * 100;
      } else if (value > 0) {
        eng = (value / 1400) * 100;
      }
      // if ((eng > 300 || eng < 100) && eng > 10) {
      //   return "red";
      // } else if (eng > 280 || eng < 180) {
      //   return "orange";
      // } else return "blue";
      if (eng > 300 || eng < 100) {
        return "red";
      } else if (eng > 280 || eng < 180) {
        return "orange";
      } else return "blue";
    },
  },
  {
    field: "chargerStatus",
    headerName: "Charger Status",
    sortable: true,
    minWidth: 140,
    valueGetter: (param) => {
      const value = Number(param.row.ICO);
      if (!isNaN(value) && value) {
        if (value > 1800) return "Boost Mode";
        else return "Trickle Mode";
      } else {
        return "-";
      }
    },
  },
];

export const PM_COLUMNS = [
  // {
  //   field: "direction",
  //   headerName: "Direction",
  //   sortable: true,
  //   minWidth: 120,
  //   hide: false,
  //   hideable: false,
  //   flex: 1,
  //   valueGetter: (param) => {
  //     const valueType = param.row.OperationType;
  //     return valueType ?? "-";
  //   },
  // },
];

export const initialPointChartPopData = {
  open: false,
  machineData: null,
  deviceId: null,
  deviceName: "",
  currentTs: null,
};

export const INITIAL_TELEMETRY_CIRCUIT_VIEW_MODAL_STATE = {
  open: false,
  gear_type: null,
  device_id: null,
};


export const DEVICETYPE_WITH_GROUPED_KEY_HISTORY_GRAPH = ['PM', 'TRACK']

// -----------FUNCTIONS_---------

/** To Convert a Unit to Higher Unit ---> like mV to V */
export const convertToHigherUnit = (value, unit) => {
  let _value = Number(value)
  if (isNaN(_value) || value < 0) return "-";
  if (!unit.startsWith('m')) return (_value / 1000) === 0 ? 0 : (_value / 1000).toFixed(2)


  return _value;
};

export const getCellColor = (value, criticalRange, warningRange) => {
  for (let i in criticalRange) {
    let range = criticalRange[i];
    if (range[0] < value && range[1] > value) {
      return "red";
    }
  }

  for (let i in warningRange) {
    let range = warningRange[i];
    if (range[0] < value && range[1] > value) {
      return "orange";
    }
  }
  return "blue";
};

export const pmOperationDataFilter = (evntData, evntTime) => {
  const processedEventTime = [];
  const processedEventData = [];
  if (evntTime.length > 0 && evntData.length > 0) {
    const eventTime = evntTime.split(" ");
    const eventData = evntData.split(" ");

    eventTime.forEach((value, index) => {
      if (Number(value) !== 0 || index === 0) {
        processedEventTime.push(Number(value));
      }
    });
    eventData.forEach((value, index) => {
      if (Number(value) !== 0 || index === 0) {
        processedEventData.push(Number(value));
      }
    });
  }
  return {
    data: processedEventData,
    time: processedEventTime,
  };
};

export const getFormattedDateTime = (ts) => {
  const today = new Date(ts);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  const time = new Date(ts).toLocaleTimeString();
  return formattedToday + " " + time;
};

export const Colors = [
  "rgba(54, 162, 235)",
  "#e51c23",
  "#00876c",
  "#65b17a",
  "#ef8251",
  "#d43d51",
  "rgba(255, 99, 132)",
  "rgba(255, 159, 64)",
  "rgba(75, 192, 192)",
  "rgba(153, 102, 255)",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#5677fc",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#259b24",
  "#8bc34a",
  "#afb42b",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

export const compare = {
  "<": function (a, b) {
    return a < b;
  },
  ">": function (a, b) {
    return a > b;
  },
  "=": function (a, b) {
    return a === b;
  },
  ">=": function (a, b) {
    return a >= b;
  },
  "<=": function (a, b) {
    return a <= b;
  },
};

export const isValidNum = (num) => {
  let isNum = false;
  if (num || num === 0) {
    const _number = Number(num);
    return !isNaN(_number);
  }
  return isNum;
};
