import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAlarmCategoryColor } from "../../Utils/Utils";
import { openModal } from "./alarmsSlice";
import { calculateTimeDifference, stringToColor } from "./alarmUtils";
import TableCellTooltip from "../../Components/Global Components/Table Cell Tooltip/TableCellTooltip";

export const ALARM_TABLE_INFO = {
  id: `alarms-table`,
  label: `Alarms`,
  requireAuthSign: false,
  timestamp: { startTs: null, endTs: null },
};

const AlarmsActionIcons = ({ params, allowUpdate }) => {
  const dispatch = useDispatch();
  const handleAction = (type, alarmId) => dispatch(openModal({ modalType: type, alarmId: alarmId }));

  // show clear icon only if clear_dt is not present
  const showClearIcon = params.row.clear_dt ? false : true;
  return (
    <div className="flex">
      {allowUpdate && (
        <>
          <div
            className={`cursor-pointer icon ${params.row.ack_dt ? "notAllowed" : ""}`}
            onClick={() => {
              if (!params.row.ack_dt) handleAction("ack", params.row.id);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414l2.829 2.829l5.656-5.657l1.415 1.414L11.003 16z"
              />
            </svg>
          </div>
          {showClearIcon && (
            <div className="cursor-pointer icon" onClick={() => handleAction("clear", params.row.id)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="32"
                  d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192Z"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M320 320L192 192m0 128l128-128"
                />
              </svg>
            </div>
          )}
        </>
      )}
      <div className="cursor-pointer icon" onClick={() => handleAction("info", params.row.id)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0Z"
          />
        </svg>
      </div>
    </div>
  );
};

const AlarmsCategoryCell = ({ params }) => {
  const categoryValue = params.row.category;
  return <div style={{ color: getAlarmCategoryColor(categoryValue) }}>{categoryValue}</div>;
};
const getDeviceNameTrimmed = (params) => {
  var deviceName = params?.row?.device?.name;
  if (deviceName) {
    deviceName = deviceName.split("_").slice(3).join("-");
  }
  return deviceName;
};
const DeviceNameCell = ({ params }) => {
  var deviceName = getDeviceNameTrimmed(params);
  return <div >{deviceName}</div>;
};

const AlarmTotalActiveDuration = ({ params }) => {
  const fromTime = params.row.created_dt;
  const toTime = params.row.clear_dt ?? null;
  const [difference, setDifference] = useState(calculateTimeDifference(fromTime, toTime));
  useEffect(() => {
    const interval = setInterval(() => {
      const newDifference = calculateTimeDifference(fromTime, toTime);
      if (difference !== newDifference) {
        setDifference(newDifference);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [fromTime, toTime]);
  return <div>{difference}</div>;
};

export const ALARMS_COLUMNS = [
  {
    field: "editActions",
    type: "actions",
    headerName: "",
    renderCell: (params) => <AlarmsActionIcons params={params} allowUpdate={true} />,
  },
  {
    field: "created_dt",
    headerName: "Alarm Time",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 155,
    hideable: true,
    // flex: 1,
    valueFormatter: (date) => moment(date.value).format("DD-MM-YYYY HH:mm:ss") ?? "-",

  },
  {
    field: "alarm_duration",
    headerName: "Duration",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 80,
    maxWidth: 80,
    hideable: true,
    flex: 1,
    renderCell: (params) => <AlarmTotalActiveDuration params={params} />,
  },
  {
    field: "clear_dt",
    headerName: "Corrected Time",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 155,
    hideable: true,
    flex: 1,
    valueFormatter: (date) => (date?.value ? moment(date.value).format("DD-MM-YYYY HH:mm:ss") ?? "-" : "-"),
  },
  {
    field: "ack_dt",
    headerName: "Ack Time",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 155,
    hideable: true,
    flex: 1,
    valueFormatter: (date) => (date?.value ? moment(date.value).format("DD-MM-YYYY HH:mm:ss") ?? "-" : "-"),
  },
  {
    field: "customer.station",
    headerName: "Station",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 70,
    maxWidth: 80,
    hideable: true,
    flex: 1,
    valueGetter: (params) => params?.row?.customer?.station ?? "-",
  },
  {
    field: "device.name",
    headerName: "Gear Name",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 90,
    hideable: true,
    flex: 1,
    renderCell: (params) => <DeviceNameCell params={params} />,
    valueFormatter: (params) => getDeviceNameTrimmed(params),
  },
  {
    field: "is_active",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 90,
    maxWidth: 90,
    hideable: true,
    flex: 1,
    valueFormatter: (status) => (status.value ? "ACTIVE" : "CLEARED" ?? "-"),
    cellClassName: (params) => {
      if (params.formattedValue === "ACTIVE") return "font-bold text-red-500";
      else if (params.formattedValue === "CLEARED") return "font-bold text-green-600";
      return "";
    },
  },
  {
    field: "severity",
    headerName: "Severity",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 140,
    maxWidth: 140,
    hideable: true,
    flex: 1,
    cellClassName: (params) => {
      if (params.formattedValue === "CRITICAL") return "font-bold text-red-500";
      else if (params.formattedValue === "WARNING") return "font-bold text-orange-500";
      else if (params.formattedValue === "MAINTENANCE") return "font-bold text-brown-500";
      return "";
    },

  },
  {
    field: "type",
    headerName: "Alarm Type",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 150,
    hideable: true,
    flex: 1,
    renderCell: (params) => {
      return <TableCellTooltip textContent={params.value} />
    }
  },
  {
    field: "event_count",
    headerName: "Event Count",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 90,
    maxWidth: 100,
    hideable: true,
    flex: 1,
  },
  {
    field: "category",
    headerName: "Category",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 100,
    hideable: true,
    flex: 1,
    renderCell: (params) => <AlarmsCategoryCell params={params} />,
  },
];
var ALARMS_COLUMNS_UPDATE = [...ALARMS_COLUMNS];
ALARMS_COLUMNS_UPDATE[0] = {
  field: "editActions",
  type: "actions",
  headerName: "",
  renderCell: (params) => <AlarmsActionIcons params={params} allowUpdate={false} />,
};
export const ALARMS_COLUMNS_NO_UPDATE = ALARMS_COLUMNS_UPDATE;

const _exportKeys = [];
const _exportAliases = [];
const _exportFormatter = [];
ALARMS_COLUMNS.forEach((column) => {
  //console.log("ALARMS_COLUMNS --> ", column.field);
  if (column.field !== "editActions" && column.field !== "alarm_duration") {
    _exportKeys.push(column.field);
    _exportAliases.push(column.headerName);
    if (column.field === "created_dt" || column.field === "clear_dt" || column.field === "ack_dt") {
      _exportFormatter.push("UTC");
      return;
    }
    if (column.field === "is_active") {
      _exportFormatter.push("alarm_status");
      return;
    }
    if (column.field === "device.name") {
      _exportFormatter.push("asset_name");
      return;
    }
    _exportFormatter.push("");
  }
});

export const ALARMS_EXPORT_PARAMS = {
  with_customer: true,
  with_device: true,
  with_device_type: true,
  export_keys: _exportKeys.join(","),
  export_aliases: _exportAliases.join(","),
  export_formatter: _exportFormatter.join(","),
  export: true,
};


const export_keys_alias_formatter = (columnVisibility) => {
  const _exportKeys = [];
  const _exportAliases = [];
  const _exportFormatter = [];
  ALARMS_COLUMNS.forEach((column) => {
    if(columnVisibility[column.field] ?? true) {
      if (column.field !== "editActions" && column.field !== "alarm_duration") {
        _exportKeys.push(column.field);
        _exportAliases.push(column.headerName);
        if (column.field === "created_dt" || column.field === "clear_dt" || column.field === "ack_dt") {
          _exportFormatter.push("UTC");
          return;
        }
        if (column.field === "is_active") {
          _exportFormatter.push("alarm_status");
          return;
        }
        _exportFormatter.push("");
      }
    }
  });
  return { _exportKeys, _exportAliases, _exportFormatter }
}

export const ALARMS_EXPORT_PARAMS_DYN = (columnVisibility) => {
  return {
    with_customer: true,
    with_device: true,
    with_device_type: true,
    export_keys: export_keys_alias_formatter(columnVisibility)._exportKeys.join(","),
    export_aliases: export_keys_alias_formatter(columnVisibility)._exportAliases.join(","),
    export_formatter: export_keys_alias_formatter(columnVisibility)._exportFormatter.join(","),
    export: true,
  };
};


