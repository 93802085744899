import { memo, useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import {
  alarmFiltersSelector,
  clearTemporaryFilters,
  DEFAULT_ALARM_FILTERS,
  setAlarmFilters,
  setTemporaryAlarmFilters,
  temporaryAlarmFiltersSelector,
  updateAlarmFilters,
} from "./alarmsSlice";
import {
  useGetAlarmTypesQuery,
  useGetDevicesNameQuery,
  useGetDeviceTypesQuery,
} from "../SearchFilters/searchFiltersSlice";
import { getAlarmCategoryList } from "../../Utils/Utils";
import { ActiveAlarmsData } from "../../Components/Alarms/ActiveAlarms";
import { getDeviceNameSearchParams, getDeviceTypeSearchParams } from "../SearchFilters/searchFiltersUtils";
import { AutocompleteStationListPop } from "../../Components/AutocompleteStationListPop/AutocompleteStationListPop";
import { selectedCustomersSelector } from "../authentication/auth-slice";

const operation = (list1, list2, isUnion = false) =>
  list1.filter(
    (
      (set) => (a) =>
        isUnion === set.has(a.id)
    )(new Set(list2.map((b) => b.id))),
  );

// Following functions are to be used:
const inBoth = (list1, list2) => {
  if (!list1 || !list2 || list1?.length === 0 || list2?.length === 0) return [];
  return operation(list1, list2, true);
};

const AlarmsFilters = () => {
  const temporaryFilters = useSelector(temporaryAlarmFiltersSelector);
  const alarmFilters = useSelector(alarmFiltersSelector);

  const INITIAL_FILTERS_STATE = temporaryFilters ?? alarmFilters;
  const [filterState, setFilterState] = useState(INITIAL_FILTERS_STATE);

  const { selectedCustomersIdName = [], customerIdList = [] } = useContext(ActiveAlarmsData);
  const selectedCustomersList = useSelector(selectedCustomersSelector);
  const categoriesList = getAlarmCategoryList();
  const { data: gearTypesList = [] } = useGetDeviceTypesQuery(
    getDeviceTypeSearchParams({ filterState, customerIdList }),
  );
  const { data: deviceNamesList = [] } = useGetDevicesNameQuery(
    getDeviceNameSearchParams({ filterState, customerIdList }),
  );
  const { data: alarmTypesList = [] } = useGetAlarmTypesQuery(null);

  useEffect(() => {
    const customer_ids = filterState.customer_ids?.filter((customer) => customerIdList.includes(customer.id));
    dispatch(updateAlarmFilters({ ...filterState, customer_ids }));
  }, [customerIdList.length]);

  useEffect(() => {
    const device_type_ids = inBoth(gearTypesList, filterState.device_type_ids);
    setFilterState((state) => ({ ...state, device_type_ids }));
  }, [gearTypesList]);

  useEffect(() => {
    const device_ids = inBoth(deviceNamesList, filterState.deviceNamesList);
    setFilterState((state) => ({ ...state, device_ids }));
  }, [deviceNamesList]);

  const permittedCustomerIds = useMemo(() => {
    const _list = selectedCustomersList;
    if (!_list) return [];
    return _list.map((customer) => ({ id: customer.customerId, value: customer.customerName }));
  }, [selectedCustomersList]);

  const dispatch = useDispatch();

  const handleSearch = () => {
    if (temporaryFilters) {
      dispatch(setTemporaryAlarmFilters(filterState));
      return;
    }
    dispatch(updateAlarmFilters(filterState));
  };

  const handleClear = () => {
    if (temporaryFilters) {
      dispatch(clearTemporaryFilters());
      return;
    }
    const _filters = { ...DEFAULT_ALARM_FILTERS, is_active: alarmFilters.is_active };
    dispatch(setAlarmFilters(_filters));
  };

  useEffect(() => {
    setFilterState(INITIAL_FILTERS_STATE);
  }, [temporaryFilters, alarmFilters]);

  return (
    <div className="alarm-filters-container">
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        multiple
        disableCloseOnSelect
        options={permittedCustomerIds}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
        )}
        getOptionLabel={(customer) => customer.value}
        value={filterState?.customer_ids ?? []}
        onChange={(_, newValue) => {
          setFilterState((state) => ({
            ...state,
            customer_ids: newValue,
          }));
        }}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && (
                <AutocompleteStationListPop title={value.map((stn) => stn?.value ?? "").toString()}>
                  <Chip size="small" label={`+${value.length - 1}`} />
                </AutocompleteStationListPop>
              )}
            </div>
          );
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disableFuture
          label="From Date"
          openTo="day"
          views={["year", "month", "day"]}
          value={filterState.from_ts}
          onChange={(newValue) => setFilterState((prev) => ({ ...prev, from_ts: newValue }))}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        <DatePicker
          disableFuture
          label="To Date"
          openTo="day"
          views={["year", "month", "day"]}
          value={filterState.to_ts}
          onChange={(newValue) => setFilterState((prev) => ({ ...prev, to_ts: newValue }))}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
      <Autocomplete
        size="small"
        multiple
        limitTags={2}
        disableCloseOnSelect
        options={categoriesList}
        value={filterState.categories ?? []}
        onChange={(_, value) => setFilterState((prev) => ({ ...prev, categories: value }))}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0]} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
        renderInput={(params) => <TextField size="small" {...params} label="Categories" placeholder="Categories" />}
      />
      <Autocomplete
        size="small"
        multiple
        disableCloseOnSelect
        limitTags={2}
        options={gearTypesList}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={filterState.device_type_ids ?? []}
        getOptionLabel={(gearType) => gearType.value}
        onChange={(_, value) =>
          setFilterState((prev) => ({
            ...prev,
            device_type_ids: value,
          }))
        }
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
        renderInput={(params) => <TextField size="small" {...params} label="Gear Types" placeholder="Gear Types" />}
      />

      <Autocomplete
        size="small"
        limitTags={2}
        value={filterState.severity}
        options={["WARNING", "CRITICAL", "MAINTENANCE"]}
        onChange={(_, value) => setFilterState((prev) => ({ ...prev, severity: value }))}
        renderInput={(params) => <TextField size="small" {...params} label="Severity" placeholder="Severity" />}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
      />
      <Autocomplete
        size="small"
        multiple
        disableCloseOnSelect
        limitTags={2}
        value={filterState.device_ids ?? []}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={deviceNamesList}
        getOptionLabel={(deviceNames) => deviceNames.value}
        onChange={(_, value) =>
          setFilterState((prev) => ({
            ...prev,
            device_ids: value,
          }))
        }
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
        renderInput={(params) => <TextField size="small" {...params} label="Gear Names" placeholder="Gear Names" />}
      />

      <Autocomplete
        size="small"
        multiple
        disableCloseOnSelect
        limitTags={2}
        value={filterState.alarm_types ?? []}
        options={alarmTypesList}
        onChange={(_, value) =>
          setFilterState((prev) => ({
            ...prev,
            alarm_types: value,
          }))
        }
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0]} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
        renderInput={(params) => <TextField size="small" {...params} label="Alarm Types" placeholder="Alarm Types" />}
      />

      <TextField
        label="Remarks"
        size="small"
        onChange={(event) =>
          setFilterState((prev) => ({
            ...prev,
            remarks: event.target.value,
          }))
        }
        value={filterState.remarks ?? ""}
        placeholder="Remarks"
      />

      <Autocomplete
        size="small"
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={[
          { value: "Acked", id: true },
          { value: "Not Acked", id: false },
        ]}
        getOptionLabel={(status) => status.value}
        value={filterState.is_acked ?? null}
        onChange={(_, value) => setFilterState((prev) => ({ ...prev, is_acked: value }))}
        renderInput={(params) => <TextField size="small" {...params} label="Ack Status" placeholder="Ack Status" />}
      />

      <Button variant="outlined" onClick={handleClear}>
        Clear
      </Button>
      <Button variant="contained" onClick={handleSearch}>
        Search
      </Button>
    </div>
  );
};

export default memo(AlarmsFilters);
