import { useState, useCallback, memo } from "react";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, ListItemIcon, Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useImmer } from "use-immer";

import { SidebarHeader } from "./Style";
import { PopOverContent, sidebarTiles } from "./NavLink";
import logo from "../../Images/bitcommLogo.png";
import SideNavPopOver from "./SideNavPopOver";
import "./Sidebar.scss";

// const USER_AUTHORITY = localStorage.getItem("authority");

const initialPopOverData = { anchorEl: null, data: null };
const sidebarItemsList = sidebarTiles.ids;
const sidebarItemsEntities = sidebarTiles.entities;

/**
 * @returns Each sidebar item containing icon and text
 */
const SidebarItem = ({ id, sidebarType }) => {
  const nav = sidebarItemsEntities[id];
  const location = useLocation();
  const [popOverData, setPopOverData] = useImmer(initialPopOverData);
  const handleShowPopOver = (event) => {
    if (nav.showPopover) {
      setPopOverData((prev) => {
        prev.open = Boolean(event.currentTarget);
        prev.anchorEl = event.currentTarget;
        prev.data = PopOverContent[nav.label];
      });
    }
  };
  const checkSelected = () => {
    if (nav.label === "Form Creator" || nav.label === "Reports" || nav.label === "Asset Utilization") {
      let selected = false;
      PopOverContent[nav.label].forEach((row) => {
        if ("/" + row.url === location.pathname) {
          selected = true;
        }
      });
      return selected;
    } else {
      return location.pathname === "/" + nav.url;
    }
  };
  var tooltipTitle = "";
  var itemButtonClass = "";
  var marginRight = 2;
  if (sidebarType === "permanent") {
    tooltipTitle = nav.label;
    itemButtonClass = "justify-center items-center";
    marginRight = 0;
  }

  return (
    <>
      {nav.url !== "#" && (
        <ListItem
          disablePadding
          // selected={location.pathname === `/${nav.url}`}
          selected={checkSelected()}
          component={Link}
          // to={'/'}
          to={nav.url}
          key={nav.label}
        >
          <Tooltip title={tooltipTitle} placement="bottom" disableInteractive>
            <ListItemButton className={`${itemButtonClass} sidebar-button`}>
              <ListItemIcon
                className="justify-center"
                sx={{
                  minWidth: 0,
                  mr: marginRight,
                }}
              >
                {nav.icon}
              </ListItemIcon>
              {sidebarType === "temporary" && (
                <Box className="flex">
                  <ListItemText style={{ color: "black" }} primary={nav.label} />
                </Box>
              )}
            </ListItemButton>
          </Tooltip>
        </ListItem>
      )}
      {nav.url === "#" && (
        // <div className="popOverBtn">
        <Button
          sx={{
            minWidth: 0,
            mr: marginRight,
            // color: '#6a6e71'
          }}
          onClick={(e) => handleShowPopOver(e)}
          className={`popOverBtn ${sidebarType === "temporary" ? "tempPopOver" : ""
            } ${checkSelected() ? 'Mui-selected' : ''} MuiListItemButton-root MuiListItemButton-gutters MuiButtonBase-root justify-center items-center sidebar-button css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root`}
          variant=""
          startIcon={nav.icon}
        >
          {sidebarType === "temporary" ? nav.label : ""}
        </Button >

        // </div>
      )}
      {
        popOverData.open && (
          <SideNavPopOver
            open={popOverData.open}
            onClose={() => {
              setPopOverData(initialPopOverData);
            }}
            anchorEl={popOverData.anchorEl}
            data={popOverData.data}
          />
        )
      }
      {/* */}
    </>
  );
};

/**
 * @returns The total content in both 'permanent' and 'temporary' sidebars
 */
const SidebarContent = (props) => {
  const { toggleSidebar, sidebarType } = props;

  const modulesAccessList = window.frontendAccess;
  const renderSidebarItems = [];
  for (let itemId of sidebarItemsList) {
    const sidebarItemData = modulesAccessList[itemId];
    if (sidebarItemData?.readAll === false && sidebarItemData?.readSelf === false) continue;
    renderSidebarItems.push(itemId);
  }
  return (
    <div className="side-container">
      <div className="nav-container">
        <SidebarHeader className="justifyCenter">
          {sidebarType === "temporary" && <img src={logo} alt="bitcomm logo" className="sidebar-bitcomm-logo" />}
          <IconButton
            onClick={toggleSidebar}
            className={sidebarType === "temporary" ? "absolute left sidebar-menu-icon" : ""}
          >
            <Tooltip
              placement="bottom"
              title={sidebarType === "temporary" ? "Minimize sidebar" : "Expand sidebar"}
              disableInteractive
            >
              <MenuIcon />
            </Tooltip>
          </IconButton>
        </SidebarHeader>
        <Divider />
        {renderSidebarItems.map((id, index) => (
          <SidebarItem key={"sidebar-item" + index} id={id} sidebarType={sidebarType} />
        ))}
      </div>
      <div className="version-txt">{`25/08/2023 1:20`}</div>
    </div>
  );
};

const Sidebar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = useCallback(() => {
    setOpenSidebar((previousValue) => !previousValue);
  }, []);
  return (
    <>
      <Drawer className="sidebar-drawer" variant="permanent" anchor="left">
        <SidebarContent toggleSidebar={toggleSidebar} sidebarType="permanent" />
      </Drawer>
      <Drawer
        variant="temporary"
        open={openSidebar}
        onClose={toggleSidebar}
        PaperProps={{
          sx: { minWidth: "14em" },
        }}
      >
        <SidebarContent toggleSidebar={toggleSidebar} sidebarType="temporary" />
      </Drawer>
    </>
  );
};
export default memo(Sidebar);
