import React from "react";
// import { ActiveAlarmsData } from "../../../Alarms/ActiveAlarms";
// import { CUSTOMER_ID_DATA } from "../Constant/Constant";

function NoSVgComponent() {
  // const { customerId } = useContext(ActiveAlarmsData);

  //----------------------------------------------------------------------------typewriter effect
  // useEffect(() => {
  //   document.getElementById("error-text").innerHTML = "";
  //   typeWriter();
  //   return () => {
  //     document.getElementById("error-text").innerHTML = "";
  //     txt = "";
  //   };
  // }, [customerId]);
  // var i = 0;
  // var txt = `SVG of selected station is not available currently.`;
  // var speed = 0.5;

  // function typeWriter() {
  //   if (i < txt.length) {
  //     document.getElementById("error-text").innerHTML += txt.charAt(i);
  //     i++;
  //     setTimeout(typeWriter, speed);
  //   }
  // }

  return (
    <div className="no-svg-container">
      <div className="errorIcon-container">

        <svg
          className="errorIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="white"
            d="M12.884 2.532c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21h18a.998.998 0 0 0 .883-1.467L12.884 2.532zM13 18h-2v-2h2v2zm-2-4V9h2l.001 5H11z"
          />
        </svg>

      </div>

      <p id="error-text" className="text-shadow">SVG of selected station is not available currently.</p>
    </div>
  );
}

export default NoSVgComponent;
