import { Autocomplete, Button, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import DataTable from "../../../../DataGrid/DataGrid";
import { useLocation, useNavigate } from "react-router-dom";
import "./AssetMaintenanceResponses.scss";
import { useImmer } from "use-immer";
import { filter } from "rxjs";
import { ActiveAlarmsData } from "../../../../Alarms/ActiveAlarms";
import axios from "axios";
import {
  APP_BASE_URL,
  ASSET_MAINTENANCE_HISTORY_API,
  ASSET_MAINTENANCE_TABLE_API,
  trimAssetName,
  tsToDateTimeFormatter,
} from "../../../../../Common/EndPoints";
import MaintenanceAttachmentModel from "../MaintenanceAttachments/MaintenanceAttachmentModel";

const getInitialFilters = (navState) => {
  if (navState) {
    return {
      startTs: null,
      endTs: null,
      scheduleCode: navState.scheduleCode.code,
      assetName: navState.asset.assetName,
      assetId: navState.asset.assetId,
      pageIndex: 0,
      pageSize: 20,
      totalRowCount: 0,
    };
  }

};
const initialState = {
  tableColumns: [],
  tableRows: [],
};
const initAttachState = {
  open: false,
  label: "Attachments",
  attachments: [],
  assetName: '',
  completeDt: ''
}
function AssetMaintenanceResponses() {
  const location = useLocation();
  const navigate = useNavigate();
  // const navState = location.state;
  const { customerIdList } = useContext(ActiveAlarmsData);
  const [assetResponseState, setAssetResponseState] = useImmer(initialState);
  const [assetResponseFilters, setAssetResponseFilters] = useImmer(() => {
    return getInitialFilters(location.state);
  });
  const [attachPop, setAttachPop] = useImmer(initAttachState)
  const [navState, setNavState] = useImmer(location.state)
  const handleChange = (value, stateName) => {
    setAssetResponseFilters((prev) => {
      prev[stateName] = value;
    });
  };

  useEffect(() => {
    let filterParams = {
      // assetId:assetResponseFilters.assetId,
      startTs: assetResponseFilters.startTs,
      endTs: assetResponseFilters.endTs,
      scheduleCode: assetResponseFilters.scheduleCode.label,
      status: "DONE",
      withResponse: true,
      page: assetResponseFilters.pageIndex + 1,
      pageSize: assetResponseFilters.pageSize,
      deviceIds: assetResponseFilters.assetId,
    };
    getAssetsResponse(filterParams, assetResponseFilters.assetId);
  }, [
    assetResponseFilters.pageIndex,
    assetResponseFilters.pageSize,
    assetResponseFilters.startTs,
    assetResponseFilters.endTs,
    assetResponseFilters.scheduleCode,
  ]);

  const getAssetsResponse = (params, assetId) => {
    let url = `${APP_BASE_URL}${ASSET_MAINTENANCE_HISTORY_API}`;
    axios.get(url, { params }).then((res) => {
      setAssetResponseFilters((prev) => {
        prev.totalRowCount = res.data.data.totalElements;
      });

      let response = res.data.data.content;
      let form = response[0]?.maintenanceFormResponse?.form?.data;
      let newTableRows = response.map((row, i) => {
        let formResponseOuterPacket = row.maintenanceFormResponse

        let basicInfo = {
          ...row,
          id: row.id + i,
          latitude: formResponseOuterPacket.latitude ?? '-',
          longitude: formResponseOuterPacket.longitude ?? '-'
        };

        let formResponse = row.maintenanceFormResponse.data;

        let newRow = { ...basicInfo, ...formResponse };
        return newRow;
      });


      const DefaultColumns = [
        {
          field: "completedDt",
          headerName: "Done Date",
          sortable: true,
          minWidth: 180,
          hideable: true,
          flex: 1,
          ...tsToDateTimeFormatter,
        },
        {
          field: 'latitude',
          headerName: 'Latitude',
          sortable: true,
          minWidth: 180,
          hideable: true,
          flex: 1,
        },
        {
          field: 'longitude',
          headerName: 'Longitude',
          sortable: true,
          minWidth: 180,
          hideable: true,
          flex: 1,
        }
      ]
      let tableColumns = [...DefaultColumns, ...getColumnsByForm(form)];
      setAssetResponseState((prev) => {
        prev.tableColumns = tableColumns;
        prev.tableRows = newTableRows;
      });
    });
  };
  const getColumnsByForm = (fieldList) => {
    if (!fieldList || fieldList.length === 0) {
      return [];
    }
    let columns = [];

    fieldList?.forEach((field, i) => {
      if (field.type !== "heading" && field.type !== "sub_heading") {
        if (field.type === "section" || field.type === "reactive_section") {
          columns = [...columns, ...getColumnsByForm(field.sectionFields)];
        } else {
          if (field.key !== "completedDt") {
            columns.push({
              field: field.key,
              headerName: field.label,
              sortable: true,
              minWidth: 210,
              isAttachment: (field.type === 'attachment' || field.type === 'image_capture'),
              hideable: true,
              flex: 1,
              valueFormatter: (param) => {
                if (field.type === 'attachment' || field.type === 'image_capture') {
                  return 'Attachments'
                } else if (!param.value || param.value === "") {
                  return "-";
                }
              },
              cellClassName: (param) => {
                if (field.type === 'attachment' || field.type === 'image_capture') {
                  if (param.row[param.field] && param.row[param.field]?.length > 0) {
                    return 'blue pointer';
                  } else {
                    return 'noAttachment'
                  }
                }
              }
            });
          }
        }
      }
    });

    return columns;
  };
  // const getColumnsByForm = (formJson) => {
  //   let newColumns = [];
  //   formJson.forEach((field) => {
  //     // if (field.showInTable) {
  //     newColumns.push({
  //       field: field.key,
  //       headerName: field.label,
  //       sortable: true,
  //       minWidth: 110,
  //       hideable: true,
  //       flex: 1,
  //     });
  //     // }
  //   });
  //   setAssetResponseState((prev) => {
  //     prev.tableColumns = newColumns;
  //   });
  // };
  const handleCloseAttachpop = () => {
    setAttachPop(initAttachState)
  }
  const handleOpenAttachPopup = (param) => {
    if (param.colDef.isAttachment && param.row[param.field].length > 0) {
      setAttachPop((prev) => {
        prev.open = true
        prev.attachments = param.row[param.field]
        prev.assetName = assetResponseFilters?.assetName
        prev.completeDt = param.row.completedDt

      })
    }
  }
  return (
    <div className="asset-maintenance-responses">
      <div className="heading">
        Maintenance Response of {trimAssetName(assetResponseFilters?.assetName)} on{" "}
        {assetResponseFilters?.scheduleCode?.label}
      </div>
      <div className="filters">
        <Autocomplete
          disableClearable
          disablePortal
          className="filter-input"
          size="small"
          options={navState.scheduleCode.list}
          value={assetResponseFilters?.scheduleCode}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField size="small" {...params} label="Select Schedule Code" />}
          getOptionLabel={(params) => {
            return params.label;
          }}
          onChange={(event, newValue) => {
            handleChange(newValue, "scheduleCode");
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            size="small"
            // disableFuture
            className="filter-input"
            // sx={{}}
            label="From Date"
            openTo="day"
            views={["year", "month", "day"]}
            value={assetResponseFilters?.startTs}
            onChange={(newValue) => {
              if (Date.parse(newValue && newValue["$d"])) {
                let ts = newValue["$d"].getTime();
                handleChange(ts, "startTs");
              } else {
                if (newValue === null) {
                  handleChange(newValue, "startTs");
                }
              }
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <DatePicker
            // disableFuture
            className="filter-input"
            label="To Date"
            openTo="day"
            views={["year", "month", "day"]}
            value={assetResponseFilters?.endTs}
            onChange={(newValue) => {
              if (Date.parse(newValue && newValue["$d"])) {
                let ts = newValue["$d"].getTime();
                handleChange(ts, "endTs");
              } else {
                if (newValue === null) {
                  handleChange(newValue, "endTs");
                }
              }
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        <Button variant="outlined" onClick={() => setAssetResponseFilters(getInitialFilters(navState))}>
          Clear Filters
        </Button>
      </div>
      <div className="asset-maintenance-responses-table">
        <DataTable
          tableInfo={{
            id: `asset-maintenance-responses`,
            label: `Asset Maintenance Responses`,
            requireAuthSign: false,
            timestamp: { startTs: null, endTs: null },
          }}
          rows={assetResponseState.tableRows}
          columns={assetResponseState.tableColumns}
          paginationMode={"server"}
          // rowCount={50}
          rowCount={assetResponseFilters.totalRowCount}
          pageIndex={assetResponseFilters.pageIndex}
          pageChange={(index) => {
            setAssetResponseFilters((prev) => {
              prev.pageIndex = index;
            });
          }}
          pageSize={assetResponseFilters.pageSize}
          pageSizeChange={(size) => {
            setAssetResponseFilters((prev) => {
              prev.pageSize = size;
            });
          }}
          onCellClick={(param) => {
            handleOpenAttachPopup(param)

          }}
        />
      </div>
      <MaintenanceAttachmentModel open={attachPop.open} label={attachPop.label} attachments={attachPop.attachments} assetName={attachPop.assetName} completeDt={attachPop.completeDt} onClose={handleCloseAttachpop} />

    </div>
  );
}

export default AssetMaintenanceResponses;
