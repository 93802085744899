import React, { useEffect, useState } from 'react'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import './CustomeLegends.scss'
function CustomeLegends({ legends, getSelecetedLegends}) {
    const [slecetedLegend, setSelectedLegend] = useState([])
  useEffect(()=>{
    getSelecetedLegends(slecetedLegend)
  },[slecetedLegend])
    const handleLegendClick = (leg) => {
        if (!slecetedLegend.includes(leg.label)) {
            setSelectedLegend((prev) => [...prev, leg.label])
        } else {
            let newLegends = [...slecetedLegend]
            newLegends = newLegends.filter(function (item) {
                return item !== leg.label
            })
            setSelectedLegend(newLegends)
        }
    }
    return (
        <div className='legends-container'>
            <ArrowLeftIcon onClick={() => {
                let ele = document.getElementById('scroll')
                ele.scrollBy({
                    top: 0,
                    left: -40,
                    behavior: "smooth",
                })
            }} />
            <div id='scroll' className='legends' >
                {legends.length > 0 && legends.map((leg, i) => {
                    return (<div key={i} onClick={() => {
                        handleLegendClick(leg)
                    }} className={`legend pointer ${slecetedLegend.includes(leg.label) ? 'selected' : ''}`}>
                        <span className='colour-box'
                            style={{ backgroundColor: leg.fill }}></span><span>{leg.label}</span>
                    </div>)
                })}
            </div>
            <ArrowRightIcon onClick={() => {
                let ele = document.getElementById('scroll')
                ele.scrollBy({
                    top: 0,
                    left: 40,
                    behavior: "smooth",
                })
            }} />
        </div>
    )
}

export default CustomeLegends