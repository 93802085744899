import React, { useMemo } from "react";
import { getBHMSGrid } from "./getBHMSGrid";
import "./bhms-style.scss";

// const BHMS_JSON = {
//   grid_config: {
//     rows: 1,
//     columns: 5,
//   },
//   data_config: {
//     data_mapping: "grid", // "grid" | "cell"
//     key_prefix: "VBAT",
//     value_prefix: "Bank ",
//     value_unit: "V",
//     value_thresold: 9.5,
//     disabled_cell_bg_color: "lightgray",
//     threshold_exceeded_bg_color: "lightgreen",
//     threshold_not_exceeded_bg_color: "red",
//     start_index: 26,
//   },
//   number_of_horizontal_grids: 1,
//   number_of_vertical_grids: 11,
//   type: "horizontal", // "horizontal" | "vertical" | "random"
//   is_spiral: false,
//   starting_point: "topLeft", // "topLeft" | "topRight" | "bottomLeft" | "bottomRight"
//   // cell_values: {
//   //   "1_1": { text: "Cell 1", id: "cell_1" },
//   // },
// };

function getCellColor({
  value,
  cell_data_mapping,
  backgroundColor,
  value_thresold,
  disabled_cell_bg_color,
  threshold_exceeded_bg_color,
  threshold_not_exceeded_bg_color,
}) {
  if (!cell_data_mapping) return backgroundColor;

  if (!value || !value_thresold || isNaN(Number(value)) || isNaN(Number(value_thresold))) return disabled_cell_bg_color;
  if (Number(value) < Number(value_thresold)) return threshold_not_exceeded_bg_color;
  return threshold_exceeded_bg_color;
}

const Cell = ({ cell, data, config, backgroundColor }) => {
  const { data_config } = config;
  const {
    data_mapping,
    value_thresold,
    disabled_cell_bg_color = "lightgray",
    threshold_exceeded_bg_color = "lightgreen",
    threshold_not_exceeded_bg_color = "red",
    value_unit,
  } = data_config;
  const cell_data_mapping = data_mapping === "cell";
  const value = data?.[cell.id];
  const bg_color = getCellColor({
    value,
    cell_data_mapping,
    backgroundColor,
    value_thresold,
    disabled_cell_bg_color,
    threshold_exceeded_bg_color,
    threshold_not_exceeded_bg_color,
  });

  const display_value = value && Number(value) ? value + value_unit : "";

  return (
    <div className="cell" style={{ backgroundColor: bg_color }}>
      {/* <div className="cell-top-row">
        <div className="top-connector"></div>
      </div> */}
      <div className={"cell-status " + (cell_data_mapping ? "" : "cell-status--grid-mapping")}>
        {cell_data_mapping && <div className="status-circle"></div>}
        <div>{cell.cell_text}</div>
      </div>
      {cell_data_mapping && <div className="cell-value">{display_value}</div>}
      {/* <div className="cell-bottom-row">
        <div className="bottom-connector"></div>
      </div> */}
    </div>
  );
};

const Grid = ({ cell_list, config, grid_index, data = {} }) => {
  const { data_config } = config;
  const {
    data_mapping,
    key_prefix,
    value_prefix,
    value_unit,
    value_thresold,
    disabled_cell_bg_color = "lightgray",
    threshold_exceeded_bg_color = "lightgreen",
    threshold_not_exceeded_bg_color = "red",
  } = data_config;

  const grid_cells = (cellBackgroundColor) => (
    <div
      className="smallest-grid"
      style={{
        gridTemplateRows: `repeat(${config.GRID_ROWS}, 1fr)`,
        gridTemplateColumns: `repeat(${config.GRID_COLUMNS}, 1fr)`,
      }}
    >
      {cell_list.map((cell) => (
        <Cell key={cell.id} cell={cell} data={data} config={config} backgroundColor={cellBackgroundColor} />
      ))}
    </div>
  );

  if (data_mapping !== "grid") {
    return grid_cells(null);
  }

  let cellBackgroundColor = disabled_cell_bg_color;

  const grid_key = key_prefix + grid_index;
  const numerical_value = data?.[grid_key] && Number(data?.[grid_key]) ? Number(data?.[grid_key]) : null;
  if (numerical_value && value_thresold && Number(value_thresold)) {
    if (numerical_value < Number(value_thresold)) {
      cellBackgroundColor = threshold_not_exceeded_bg_color;
    } else {
      cellBackgroundColor = threshold_exceeded_bg_color;
    }
  }

  const value = numerical_value ? numerical_value + value_unit : "-";
  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div>
          {value_prefix} {grid_index}: {value}
        </div>
      </div>
      {grid_cells(cellBackgroundColor)}
    </div>
  );
};

const BHMS_GENERATOR = ({ data = {}, config = {} }) => {
  const {
    grid_config = {},
    data_config = {},
    number_of_horizontal_grids,
    number_of_vertical_grids,
    type,
    is_spiral,
    starting_point,
    cell_values,
  } = config;

  const { rows, columns } = grid_config;

  const { grid_start_index = 1 } = data_config;

  const BHMS_CONFIG = {
    IS_SPIRAL: is_spiral,
    GRID_ROWS: rows,
    GRID_COLUMNS: columns,
    NUMBER_OF_HORIZONTAL_GRIDS: number_of_horizontal_grids,
    NUMBER_OF_VERTICAL_GRIDS: number_of_vertical_grids,
    CELL_VALUES: cell_values,
    data_config,
  };

  const grids = useMemo(() => getBHMSGrid(type, starting_point, BHMS_CONFIG), [type, starting_point]);

  console.log(grids, "grids");
  return (
    <div className="bhms-grid--wrapper">
      <div
        className="main-grid"
        style={{
          gridTemplateRows: `repeat(${number_of_vertical_grids}, 1fr)`,
          gridTemplateColumns: `repeat(${number_of_horizontal_grids}, 1fr)`,
        }}
      >
        {grids.map((cell_list, index) => (
          <Grid
            key={"Grid-" + index}
            cell_list={cell_list}
            config={BHMS_CONFIG}
            grid_index={index + grid_start_index}
            data={data}
          />
        ))}
      </div>
    </div>
  );
};

export default BHMS_GENERATOR;
