import { Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './SidePopOver.scss'

function SideNavPopOver({ open, onClose, anchorEl, data }) {
  // console.log(data)
  const navigate = useNavigate();
  const location = useLocation()




  const handleContentClick = (url, state) => {
    navigate(url);
    localStorage.removeItem('tempFilters');
    // navigate(url,{state:state});
    onClose();
    // console.log(url)
  };

  return (
    <>
      <Popover
        id={"data.id"}
        className="popOverContainer"
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          onClose();
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
      
        {data &&
          data.map((row) => {
            return (
              <div
             
              className={`${location.pathname === `/${row.url}`? 'activeSubTab':''} label`}
                onClick={() => {
                  handleContentClick(row.url, row.state);
                }}
               
              >
                {row.label}
              </div>
            );
          })}

       
      </Popover>
    </>
  );
}

export default SideNavPopOver;
