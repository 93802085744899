import { configureStore } from "@reduxjs/toolkit";
import { apiBase } from "../features/api/apiBase";
import { apiAlarms } from "../features/api/apiAlarms";
import { apiNotifications } from "../features/api/api-notifications";

import alarmsReducer from "../features/Alarms/alarmsSlice";
import reportReducer from "../Components/REPORTS/reportsSlice";
import alarmRulesReducer from "../features/AlarmRules/alarmRulesSlice";
import authenticationReducer from "../features/authentication/auth-slice";

export default configureStore({
  reducer: {
    [apiBase.reducerPath]: apiBase.reducer,
    [apiAlarms.reducerPath]: apiAlarms.reducer,
    [apiNotifications.reducerPath]: apiNotifications.reducer,
    alarm: alarmsReducer,
    reporting: reportReducer,
    alarm_rules: alarmRulesReducer,
    authentication: authenticationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([apiBase.middleware, apiAlarms.middleware, apiNotifications.middleware]),
});
