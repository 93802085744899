import axios from "axios";
import { useCallback, useMemo } from "react";
import { toast } from "react-hot-toast";
import { APP_BASE_URL, DELETE_RECLAIM_ASSET_ASSIGNMENT, POST_ASSET_ASSIGNMENT_TRANSFER } from "../../Common/EndPoints";

export function useHandleAssetTransferQuery({ type, user2, user1, handleClose }, assignedDevicesList) {
  const handleConfirmClick = useCallback(() => {
    if (type === "Reclaim") {
      axios
        .delete(`${APP_BASE_URL}${DELETE_RECLAIM_ASSET_ASSIGNMENT}?fromUserId=${user2}&toUserId=${user1}`)
        .then((res) => {
          toast.success("Reclaimed Assets Successfully");
        })
        .catch(() => {
          toast.error("Failed to reclaim assets");
        })
        .then(() => {
          handleClose();
        });
    } else if (type === "Transfer") {
      let deviceIds = [];
      for (let device in assignedDevicesList) {
        if (assignedDevicesList[device].checked === true) {
          deviceIds.push(device);
        }
      }
      const params = {
        deviceIds: deviceIds.join(","),
        fromUserId: user1,
        toUserId: user2,
        transferBy: localStorage.getItem("userId"),
      };
      axios
        .post(`${APP_BASE_URL}${POST_ASSET_ASSIGNMENT_TRANSFER}`, null, { params })
        .then((res) => {
          toast.success(res.data.data);
        })
        .catch(() => {
          toast.error("Failed to transfer assets");
        })
        .then(() => {
          handleClose();
        });
    }
  }, [assignedDevicesList, handleClose, type, user1, user2]);

  return useMemo(() => ({ handleConfirmClick }), [handleConfirmClick]);
}
