import { Button } from "@mui/material";

export const LoadingButton = ({ loading, children, variant, ...rest }) => {
  const buttonClass = () => {
    if (!variant) return "lds-ring--white";
    return variant === "contained" ? "lds-ring--white" : "lds-ring--black";
  };

  return (
    <Button variant={variant ?? "contained"} {...rest}>
      {loading ? (
        <div className={`lds-ring ${buttonClass()}`}>
          <div></div>
        </div>
      ) : (
        children
      )}
    </Button>
  );
};
