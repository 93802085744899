import React, { useCallback, useEffect, useContext, useState, useLayoutEffect } from "react";
import { Alert, Button, ButtonGroup, Tooltip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import DataTable from "../DataGrid/DataGrid";
import { useImmer } from "use-immer";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { AttachPopUp } from "./AssetRegister/AttachPopUp";
import { GridActionsCellItem } from "@mui/x-data-grid";
import KeyIcon from "@mui/icons-material/Key";
import {
  APP_BASE_URL,
  // APP_BASE_URL,
  ASSET_ACTIVE_DISABLE_API,
  DELETE_ASSET,
  // DELETE_DEVICE,
  GET_ASSETS,
  trimAssetName,
  // GET_DEVICE_BY_ID,
} from "../../Common/EndPoints";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../../Modal/DeleteModal";
import "./Devices.scss";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import InfoPopup from "./AssetRegister/InfoPopup";
import { AssetsData } from "./AssetsProvider";
import IotCredPopUp from "./IotCredPopUp";
import { MAINTENACE_FORM_RESPONSE_ICON } from "../../ICONS/Icons";
import { deviceFixedColumns } from "./DeviceConstants";
import { ICONS } from "../FormCreator/Forms/FormConstants";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import MaintenaceTable from "./Maintenance/MaintenaceTable/MaintenaceTable";
import MaintenanceRoster from "../MaintenanceRoster/MaintenanceRoster";
import AssetsFilters from "./Assets Filter/AssetsFilters";

const initialState = {
  data: [],
  rowAssetsData: [],
  addDevice: false,
  showDeleteModal: false,
  snackbar: false,
  pageSize: 10,
  total: 10,
  deviceActionLabel: "Add Asset",
  selectedRow: null,
  id: null,
  severityType: null,
  snackbarMessage: null,
  loader: false,
  apiError: null,
  infoData: null,
  customerIdList: "",
};

const AUTHORITY = localStorage.getItem("authority");
let initialConfirmationModal = {
  open: false,
  message: "",
  des: "",
  actionType: "",
  deviceId: "",
};

const getDivecesFilteredData = (filter: any, data: any, setDeviceTableLoader: any) => {
  let filteredData: any = [];
  data.forEach((row: any) => {
    let gearName = row?.gear_name_table?.toUpperCase();
    let gearMake = row?.make?.toUpperCase();
    if (
      gearName?.includes(filter.nameContains) &&
      (filter.customerIds.length > 0 ? filter.customerIds.includes(row.customerId) : true) &&
      (filter.deviceTypeIds.length > 0 ? filter.deviceTypeIds.includes(row.typeid) : true) &&
      (filter.makeContains.length > 0 ? gearMake?.includes(filter.makeContains.toUpperCase()) : true)
    ) {
      filteredData.push(row);
    }
  });
  return filteredData;
};
const Devices: React.FC = (): JSX.Element => {
  const location = useLocation();
  const navState = location.state;
  const { setDeviceId, isEditMode, setAttachmentsEdit }: any = useContext(AssetsData);
  const [diviceTableLoader, setDeviceTableLoader] = useState(true);
  const [state, setState] = useImmer<any>(initialState);
  const [showInfo, setShowInfo] = useState(false);
  const [attachPopup, setAttachPopup] = useState(false);
  const [showActive, setShowActive] = useState(null);
  const [credPopUp, setCredPopUp] = useImmer({
    open: "",
    deviceType: "",
    deviceId: "",
  });
  const [pageSize, setPageSize] = useState(20);
  const { customerId, customerIdList }: any = useContext(ActiveAlarmsData);
  const [initialHiddenColumns, setIntialHiddenColumns] = useState({});
  const [confirmationModal, setConfirmationModal] = useImmer(initialConfirmationModal);
  const [tab, setTab] = React.useState(sessionStorage.getItem("selecetdAsstTab") ?? "asset");
  const [deviceDataFilter, setDeviceDataFilter] = useImmer({
    deviceTypeIds: [],
    customerIds: [],
    makeContains: "",
    nameContains: "",
  });
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (showInfo) setAttachmentsEdit(false);
    else if (attachPopup) setAttachmentsEdit(true);
    else setAttachmentsEdit(false);
  }, [showInfo, attachPopup]);
  const access = window.frontendAccess?.ASSET;
  const allowCreateAssets = access?.create ?? true;
  const allowUpdateAssets = access?.update ?? true;

  const deleteDevice = useCallback((id) => {
    axios
      .delete(`${APP_BASE_URL}${DELETE_ASSET}${id}`)
      .then((res) => {
        getDevices(null);

        setState(
          (draft: { snackbarMessage: string; snackbar: any; showDeleteModal: boolean; severityType: string }) => {
            draft.showDeleteModal = false;
            draft.snackbar = true;
            draft.severityType = "success";
            draft.snackbarMessage = "Asset hase been Deleted";
          },
        );
      })
      .catch(() => {
        setState(
          (draft: { snackbarMessage: string; snackbar: any; showDeleteModal: boolean; severityType: string }) => {
            draft.showDeleteModal = false;
            draft.snackbar = true;
            draft.severityType = "error";
            draft.snackbarMessage = "Failed to delete Asset";
          },
        );
      });
  }, []);

  const modalAction = (id: any) => {
    setState((draft: any) => {
      draft.showDeleteModal = true;
      draft.id = id;
    });
  };
  const handleCloseUserModal = () => {
    setState((draft: any) => {
      draft.showDeleteModal = false;
    });
  };

  const authority = localStorage.getItem("authority");

  const columns = React.useMemo(
    () => [
      {
        field: "editActions",
        type: "actions",
        minWidth: 185,
        renderCell: (params: any) => (
          <>
            {allowUpdateAssets && (
              <Tooltip placement="bottom" title={<div className="tooltip--title">Edit</div>} disableInteractive>
                <GridActionsCellItem
                  icon={<EditIcon />}
                  // style={{ display: AUTHORITY !== "admin" ? "none" : "" }}
                  style={{ color: "#3B71CA" }}
                  label="edit"
                  onClick={() => {
                    setState((draft: { selectedRow: any; deviceActionLabel: string }) => {
                      draft.deviceActionLabel = "Edit Device";
                      draft.selectedRow = params.row;
                    });
                    const updatedParams = { ...params.row, gear_name: params.row.gear_name };
                    delete updatedParams.gear_name_table;
                    addDevice(updatedParams);
                  }}
                />
              </Tooltip>
            )}
            <Tooltip placement="bottom" title={<div className="tooltip--title">Info</div>} disableInteractive>
              <GridActionsCellItem
                icon={<InfoIcon />}
                style={{ color: "#54B4D3" }}
                label="Info"
                onClick={() => {
                  setState((draft: any) => {
                    draft.infoData = params.row;
                  });
                  setDeviceId(params.row.deviceId);
                  setShowInfo(true);
                }}
              />
            </Tooltip>
            {allowUpdateAssets && (
              <>
                <Tooltip
                  placement="bottom"
                  title={<div className="tooltip--title">Attachments</div>}
                  disableInteractive
                >
                  <GridActionsCellItem
                    icon={<AttachmentIcon />}
                    label="Attachments"
                    style={{ color: "#9FA6B2" }}
                    onClick={() => {
                      isEditMode(true);
                      setDeviceId(params?.row?.deviceId);
                      setAttachPopup(true);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={<div className="tooltip--title">RTU Credentials</div>}
                  disableInteractive
                >
                  <GridActionsCellItem
                    icon={<KeyIcon />}
                    label="rtu-cred"
                    style={{ color: "#002ead" }}
                    onClick={(e: any) => {
                      setCredPopUp((draft: any) => {
                        draft.open = true;
                        draft.deviceType = params.row.gearType;
                        draft.deviceId = params.row.deviceId;
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip placement="bottom" title={<div className="tooltip--title">Active</div>} disableInteractive>
                  <GridActionsCellItem
                    label="active"
                    sx={{ display: params.row.isActive ? "static" : "none" }}
                    icon={ICONS.activeIcon}
                    onClick={() => {
                      setConfirmationModal((prev) => {
                        prev.open = true;
                        prev.message = "Asset";
                        prev.des = "Do you want to disable asset?";
                        prev.actionType = "disable";
                        prev.deviceId = params.row.deviceId;
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip placement="bottom" title="Not Active" disableInteractive>
                  <GridActionsCellItem
                    label="disable"
                    sx={{ display: params.row.isActive ? "none" : "static" }}
                    icon={ICONS.inActiveIcon}
                    onClick={() => {
                      setConfirmationModal((prev) => {
                        prev.open = true;
                        prev.message = "Asset";
                        prev.des = "Do you want to active asset?";
                        prev.actionType = "active";
                        prev.deviceId = params.row.deviceId;
                      });
                    }}
                  />
                </Tooltip>
              </>
            )}
          </>
        ),
      },
      ...deviceFixedColumns,
    ],
    [deleteDevice],
  );
  useEffect(() => {
    const coloumnsToHide: any = {};
    columns.forEach((column: any) => {
      if (column.hide) {
        coloumnsToHide[column.field] = false;
      }
    });

    setIntialHiddenColumns(coloumnsToHide);
  }, [columns]);
  useEffect(() => {
    const controller = new AbortController();
    if (customerIdList?.length > 0 && tab === "asset") {
      getDevices(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [customerId, customerIdList, showActive, tab]);

  useEffect(() => {
    // setDeviceTableLoader(true)
    let newAssetsData = [...state.rowAssetsData];
    // getDivecesFilteredData(deviceDataFilter, newAssetsData)
    setState((prev: any) => {
      prev.data = getDivecesFilteredData(deviceDataFilter, newAssetsData, setDeviceTableLoader);
    });
    // setDeviceTableLoader(false)
    return () => { };
  }, [state.rowAssetsData, deviceDataFilter]);


  function getDeviceNameWithoutStation(name: string) {
    const split_name_list = name.split("_");
    return split_name_list?.[split_name_list.length - 1] ?? "";
  }

  const getDevices = (signal: any) => {
    setDeviceTableLoader(true);
    const params = { is_active: showActive, customerIds: customerIdList?.toString(), with_type: true };
    axios
      .get(`${APP_BASE_URL}${GET_ASSETS}`, {
        signal,
        params,
      })
      // .get(`${APP_BASE_URL}${GET_ASSETS}customers=${customerIdList.toString()}&is_active=true&with_type=true`, {
      //   signal,
      // })
      .then((res) => {
        const {
          data: { data: oneRowData },
        } = res;
        let data: any = [];
        let row: any;
        oneRowData.forEach(
          (
            { additionaldetail, deviceid, typeid, name, deviceType, customer_id, istestmode, isactive }: any,
            index: number,
          ) => {
            row = {
              ...additionaldetail,
              id: index,
              deviceId: deviceid,
              typeid: typeid,
              gear_name_table: trimAssetName(name),
              gearType: deviceType.label,
              customerId: customer_id,
              testMode: istestmode,
              isActive: isactive,
              gear_name: additionaldetail.gear_name ? additionaldetail.gear_name : getDeviceNameWithoutStation(name),
            };
            data.push(row);
          },
          // }
        );
        setState((draft: any) => {
          draft.rowAssetsData = data;
        });
      })
      .then(() => {
        setDeviceTableLoader(false);
      })
      .catch((res) => {
        // setDeviceTableLoader(false)
      })
      .then(() => { });
  };

  const addDevice = (d: any) => {
    navigate("/asset-register", { state: { d } });
  };

  const handleCloseInfoPopUp = () => {
    setShowInfo(false);
    setDeviceId(null);
  };

  const handleActiveDisable = (actionType: any, deviceId: any) => {
    let url = `${APP_BASE_URL}${ASSET_ACTIVE_DISABLE_API}/${actionType}?deviceId=${deviceId}`;
    axios.post(url).then((res) => {
      let snakMsg: any;
      if (actionType === "active") {
        snakMsg = "asset activated";
      }
      if (actionType === "disable") {
        snakMsg = "asset disabled";
      }
      setState((draft: { snackbarMessage: string; snackbar: any; showDeleteModal: boolean; severityType: string }) => {
        // draft.showDeleteModal = false;
        draft.snackbar = true;
        draft.severityType = "success";
        draft.snackbarMessage = snakMsg;
      });
      setConfirmationModal(initialConfirmationModal);
      getDevices(null);
      // notifyAssetUpdate();
    });
    // if(actionType==='active'){

    // }
    // if(actionType==='disable'){

    // }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setTab(newValue);
    sessionStorage.setItem("selecetdAsstTab", newValue);
  };
  function handleShowActiveChange(_status: any) {
    setShowActive(_status);
  }
  const getAssetFilter = useCallback((filters) => {
    setDeviceDataFilter(filters);
  }, []);
  return (
    <div className="devices">
      <div className="devices-heading flex textBottom justify-between">
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          aria-label="disabled tabs example"
        >
          <Tab value={"asset"} label="Assets" />
          <Tab value={"maintenance"} label="Maintenance" />
          <Tab value={"roaster"} label="Roster" />
        </Tabs>
        <div className="flex gap-4 items-center">
          {/* {tab === "asset" && (
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                variant={showActive === null ? "contained" : "outlined"}
                onClick={() => handleShowActiveChange(null)}
              >
                Show All
              </Button>
              <Button variant={showActive ? "contained" : "outlined"} onClick={() => handleShowActiveChange(true)}>
                Active
              </Button>
              <Button
                variant={showActive === false ? "contained" : "outlined"}
                onClick={() => handleShowActiveChange(false)}
              >
                Inactive
              </Button>
            </ButtonGroup>
          )} */}
          {allowCreateAssets && tab === "asset" && (
            <Button variant="contained" onClick={() => addDevice(null)}>
              {state.deviceActionLabel}
            </Button>
          )}
          {tab === "maintenance" && (
            <Button
              variant="contained"
              onClick={() => {
                navigate("/maintenance-records");
              }}
            >
              {"Maintenance Records"}
            </Button>
          )}
        </div>

        {/* {authority === "admin" && (
          <Button variant="contained" onClick={() => addDevice(null)}>
            {state.deviceActionLabel}
          </Button>
        )} */}
      </div>
      {/* {state.addDevice && (
        <AddDevicesForm
          open={state.addDevice}
          editRow={state.selectedRow}
          onClose={handleCloseAddDevice}
          label={state.deviceActionLabel}
          update={() => {
            getDevices(null);
            setState((draft: any) => {
              draft.snackbar = true;
              draft.severityType = "success";
              draft.snackbarMessage = "Success";
            });
          }}
        />
      )} */}

      {state.showDeleteModal && (
        <DeleteModal
          loading={false}
          open={state.showDeleteModal}
          onClose={handleCloseUserModal}
          deleteMessageFor={"Asset"}
          willDelete={() => deleteDevice(state.id)}
        />
      )}
      {showInfo && (
        <InfoPopup open={true} label={"Asset Info"} onClose={handleCloseInfoPopUp} infoData={state.infoData} />
      )}
      {attachPopup && (
        <AttachPopUp
          open={true}
          inAdditionalForm={false}
          label={"Add Attachments"}
          onClose={() => {
            isEditMode(false);
            setAttachPopup(false);
            setDeviceId(null);
          }}
          attachmentsEdit={true}
          attachmentsUploadedMessage={(severity: String, snackbarMessage: String) => {
            setState((previousState: typeof state) => {
              previousState.snackbar = true;
              previousState.severityType = severity;
              previousState.snackbarMessage = snackbarMessage;
            });
          }}
        />
      )}
      {credPopUp.open && (
        <IotCredPopUp
          open={credPopUp.open}
          label="RTU Credentials"
          deviceType={credPopUp.deviceType}
          deviceId={credPopUp.deviceId}
          onClose={(e: any) => {
            setCredPopUp((draft: any) => {
              draft.open = false;
            });
          }}
        />
      )}

      {state.snackbar && (
        <Snackbar
          open={state.snackbar}
          autoHideDuration={3000}
          onClose={() => {
            setState((draft: any) => {
              draft.snackbar = false;
            });
          }}
        >
          <Alert
            onClose={() => {
              setState((draft: any) => {
                draft.snackbar = false;
              });
            }}
            severity={state.severityType}
            className="fullWidth"
          >
            {state.snackbarMessage}
          </Alert>
        </Snackbar>
      )}
      {confirmationModal && (
        <ConfirmationModal
          open={confirmationModal.open}
          onClose={() => {
            setConfirmationModal(initialConfirmationModal);
          }}
          onAction={(confirmation: any) => {
            if (confirmation) {
              handleActiveDisable(confirmationModal.actionType, confirmationModal.deviceId);
            }
            setConfirmationModal(initialConfirmationModal);
          }}
          message={confirmationModal.message}
          des={confirmationModal.des}
          loading={false}
        />
      )}
      <Box className="devices-table-container">
        {tab === "asset" && (
          <>
            <AssetsFilters getAssetFilter={getAssetFilter} />
            <ButtonGroup
              className="btnGroupAssetStatus"
              size="small"
              variant="outlined"
              aria-label="outlined button group"
            >
              <Button
                variant={showActive === null ? "contained" : "outlined"}
                onClick={() => handleShowActiveChange(null)}
              >
                Show All
              </Button>
              <Button variant={showActive ? "contained" : "outlined"} onClick={() => handleShowActiveChange(true)}>
                Active
              </Button>
              <Button
                variant={showActive === false ? "contained" : "outlined"}
                onClick={() => handleShowActiveChange(false)}
              >
                Inactive
              </Button>
            </ButtonGroup>
            <div className="deviceTabTable">
              <DataTable
                tableInfo={{
                  id: `devices-table`,
                  label: `Devices`,
                  requireAuthSign: false,
                  timestamp: { startTs: null, endTs: null },
                }}
                loading={diviceTableLoader}
                rows={state.data}
                columns={columns}
                pageSize={pageSize}
                pageSizeChange={(size: any) => {
                  setPageSize(size);
                }}
                onRowClick={() => { }}
                columnVisibilityModel={initialHiddenColumns}
                rowColor={(params: any) => {
                  return params.row.testMode ? "changeRowColor" : "";
                }}
                hideExportPDF={true}
              />
            </div>
          </>
        )}
      </Box>
      {tab === "maintenance" && (
        <Box className="asset-table-container">
          <MaintenaceTable navState={navState} />
        </Box>
      )}
      {tab === "roaster" && (
        <Box className="roaster-table-container">
          <MaintenanceRoster />
        </Box>
      )}
    </div>
  );
};
export default Devices;
