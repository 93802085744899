
import L from 'leaflet'; // Import leaflet directly for the custom icon

import tcIcon from './Icons/track_circuit_icon.png'
import pmIcon from './Icons/pm_icon.png'
import sgIcon from './Icons/signal_icon.png'
import acIcon from './Icons/ac_icon.png'
import batIcon from './Icons/battery_icon.png'
import bbIcon from './Icons/busbar_icon.png'
import ipsIcon from './Icons/ips_icon.png'
import lcgIcon from './Icons/lc_gate_icon.png'
import defaultIcon from './Icons/track_circuit_icon.png'

const typeIconMap = {
    TC: tcIcon,
    TRACK: tcIcon,
    PM: pmIcon,
    SG: sgIcon,
    AC: acIcon,
    AXL: acIcon,
    BAT: batIcon,
    BHMS: batIcon,
    BB: bbIcon,
    IPS: ipsIcon,
    LCG: lcgIcon,
    default: defaultIcon
}

// const customIcon = L.divIcon({
//     className: 'custom-marker-icon',
//     html: `<div style="background-color: red;" class="marker-circle">${dt}</div>`,
// });


export const getMarkerIcon = (type, selectedAsset, deviceid) => {
    console.log('gis', type, selectedAsset, deviceid)
    let selectedIconClass = ''
    if (selectedAsset) {
        selectedIconClass = selectedAsset.id === deviceid ? 'selcetdIcon' : ''
    } else {
        selectedIconClass = ''
    }
    const icon = L.divIcon({
        className: 'custom-marker-icon',
        iconAnchor: [16, 20],
        html: `<div class="icon-background  ${selectedIconClass}">
                  <img  src="${typeIconMap[type]}" alt="Custom Icon" class="icon-image">
                </div>`,
    });
    return icon
}