import axios from "axios";
import { APP_BASE_URL_ALARM, GENERIC_ATTACHMENT, NOTIFY_ASSETS_UPDATE } from "../../../Common/EndPoints";
import { extractUserDataFromPermission } from "../../../Utils/Utils";



export const getGeneralInfoForm = (gearTypeOptions, permissions, editMode) => {
  const {
    outermostKeys: zone,
    outerToInnerKeys: division,
    innerToCustomerNames: station,
    customerNameToId: customer_name_to_id,
  } = extractUserDataFromPermission(permissions);
  const form = [
    {
      type: "dropdown",
      key: "gearType",
      label: "Gear Type",
      options: gearTypeOptions,
      multiple: false,
      required: true,
      disabled: editMode,
    },
    {
      type: "alphanumeric_extended",
      key: "subType",
      label: "Sub Type",
      placeholder: "Sub Type",
      required: false,
      max_length: 30,
    },
    {
      type: "dropdown",
      key: "zone",
      label: "Zone",
      options: zone,
      multiple: false,
      required: true,
      disabled: editMode,
    },
    {
      type: "reactive-dropdown",
      key: "division",
      label: "Division",
      required: true,
      reactiveKey: "zone",
      reactiveValue: division,
      showInitially: true,
      disabled: editMode,
    },
    {
      type: "reactive-dropdown",
      key: "station",
      label: "Station",
      required: true,
      reactiveKey: "division",
      reactiveValue: station,
      showInitially: true,
      disabled: editMode,
    },
    {
      label: "Asset Code",
      key: "code",
      placeholder: "Asset Code",
      required: false,
      type: "alphanumeric_extended",
      max_length: 30,
    },
    {
      label: "Gear Name",
      placeholder: "Gear Name",
      key: "gear_name",
      required: true,
      type: "alphanumeric_extended",
      disabled: editMode,
      max_length: 30,
    },
    {
      label: "RFID",
      key: "rfid",
      required: false,
      type: "alphanumeric_extended",
      max_length: 30,
    },
    {
      label: "Latitude",
      key: "lat",
      required: true,
      type: "number",
    },
    {
      label: "Longitude",
      key: "lon",
      required: true,
      type: "number",
    },
    {
      label: "Make",
      key: "make",
      required: false,
      type: "alphanumeric_extended_spaces",
      max_length: 255,
    },
    {
      label: "Model",
      key: "model",
      required: false,
      type: "alphanumeric_extended_spaces",
      max_length: 255,
    },
    {
      label: "Serial Number",
      key: "serial",
      required: false,
      type: "alphanumeric_extended",
      max_length: 30,
    },
    {
      label: "Connected To",
      key: "connected",
      required: false,
      type: "textfield",
      max_length: 30,
    },
    {
      label: "Gear Position",
      key: "gear",
      required: false,
      type: "textfield",
      max_length: 30,
    },
    {
      label: "Pole KM",
      key: "pole",
      required: false,
      type: "textfield",
      max_length: 30,
    },
    {
      label: "Platform Number",
      key: "platform",
      required: false,
      type: "integer",
      max_length: 30,
    },
    {
      label: "Codal Life (In Years)",
      key: "codal",
      required: false,
      type: "number",
      max_length: 30,
    },
    {
      label: "PO Number",
      key: "po",
      required: false,
      type: "textfield",
      max_length: 30,
    },
    {
      label: "Manufactured Date",
      key: "manufacture",
      options: null,
      required: false,
      required_allow: false,
      type: "date",
      conditions: {
        max: { values: [], parent_ids: ["purchase"] },
      },
    },
    {
      data_type: null,
      input: true,
      label: "Purchased Date",
      key: "purchase",
      options: null,
      required: false,
      required_allow: false,
      type: "date",
      conditions: {
        max: { values: [], parent_ids: ["install"] },
      },
    },
    {
      data_type: null,
      input: true,
      label: "Installation Date",
      key: "install",
      options: null,
      required: false,
      required_allow: false,
      type: "date",
      conditions: {
        max: { values: [], parent_ids: ["warrenty", "maintenance"] },
      },
    },
    {
      data_type: null,
      input: true,
      label: "Warranty Expiry Date",
      key: "warrenty",
      options: null,
      required: false,
      required_allow: false,
      type: "date",
    },
    {
      data_type: null,
      input: true,
      label: "Maintenance Start Date",
      key: "maintenance",
      options: null,
      required: false,
      required_allow: false,
      type: "date",
    },
    {
      data_type: "str",
      input: true,
      label: "Remarks",
      key: "remarks",
      options: null,
      required: false,
      required_allow: false,
      type: "textfield",
    },
  ];
  return { form, customer_name_to_id };
};

const BASE_URL_GENERIC_ATTACHMENT = APP_BASE_URL_ALARM + GENERIC_ATTACHMENT;
export function getGenericAttachment(params) {
  return axios.get(BASE_URL_GENERIC_ATTACHMENT, { params });
}

export function deleteGenericAttachment({ id, params }) {
  return axios.delete(`${BASE_URL_GENERIC_ATTACHMENT}/${id}`, { params });
}

//not for ui, just calling to notify changes to backend at 8081
// export function notifyAssetUpdate() {
//   let url = `${APP_BASE_URL_ALARM}${NOTIFY_ASSETS_UPDATE}`;
//   axios.post(url);
// }
