import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./Roster.scss";
import { useImmer } from "use-immer";
import {
  ACK_MAINTENANCE_PVT_ENTRY,
  APP_BASE_URL,
} from "../../Common/EndPoints";
import axios from "axios";
const initialState = {
  remarks: "",

  remarksError: false,
};
function AckPopup({ open, label, onClose, selectedDeviceId,ackStatus}) {
  const [state, setState] = useImmer(initialState);
  const validateForm = () => {
    let isValid = true;
    if (!state.remarks) {
      isValid = false;
    }
    setState((draft) => {
      draft.remarksError = !state.remarks;
    });
    return isValid;
  };

  const onSubmit = () => {
    let userId = localStorage.getItem("userId");
    if (validateForm()) {
      let url = `${APP_BASE_URL}${ACK_MAINTENANCE_PVT_ENTRY}deviceId=${selectedDeviceId}&remarks=${state.remarks}&userId=${userId}`;
      axios.post(url).then((res)=>{
  
        ackStatus(true)
      }).catch(()=>{
       
        ackStatus(false)
      }).then(()=>{
        onClose(false)
      })
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="responsive-dialog-title" className="flex">
          {label}
          <CloseIcon
            className="pointer"
            sx={{ ml: "auto" }}
            onClick={() => {
              onClose(false);
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth>
            <TextField
              sx={{ width: "100%" }}
              className="ackTextField"
              onChange={(e) => {
                setState((draft) => {
                  draft.remarks = e.target.value;
                });
                // validateForm();
              }}
              required
              error={state.remarksError}
              value={state.remarks}
              label={"Remarks"}
              helperText={state.remarksError ? "Empty field!" : " "}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={onSubmit}>
            Acknowlwdge
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AckPopup;
