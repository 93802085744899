import "chartjs-adapter-moment";
import { Chart } from "react-chartjs-2";
import { useMemo, useRef } from "react";
import { Chart as ChartJS } from "chart.js";
import { useEffect, useState } from "react";
import "./HistoryTable.scss";
import { Button } from "@mui/material";

import Loader from "../Loader/Loader";
import { registerChartJS } from "./HistoryUtils";
import moment from "moment";
import { Colors, convertToHigherUnit } from "./Constant";

registerChartJS();
const convertKeys: any = {
  mV: "V",
  mA: "A",
  v: "V",
};



function convertUnit(unit: any, value: any) {
  let _value = Number(value);
  if (isNaN(_value) || value < 0) return "-";
  if (unit.toUpperCase() === "V" || unit.toUpperCase() === "S") {
    if (_value === 0) return 0;
    return (_value / 1000).toFixed(2);
  }

  return _value;
}
const options: any = {
  maintainAspectRatio: true,
  responsive: true,
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  plugins: {
    datalabels: {
      formatter: function () {
        return "";
      },
    },
    tooltip: {
      mode: "index",
      intersect: true,
      position: "nearest",
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          let keyName = tooltipItem?.dataset?.label;
          let value = tooltipItem?.formattedValue;
          let unit = tooltipItem?.dataset?.yAxisID;
          unit = `(${unit === "A" ? "mA" : unit})`;
          return keyName + " " + ":" + " " + value + unit;
        },
        title: function (tooltipItem: any, data: any) {
          return tooltipItem?.[0]?.label;
        },
      },
    },
    // zoom: {
    //   pan: {
    //     enabled: true,
    //     mode: "x",
    //   },
    //   zoom: {
    //     pinch: {
    //       enabled: true,
    //     },
    //     wheel: {
    //       enabled: true,
    //     },
    //     mode: "x",
    //   },
    //   // limits: {
    //   //   x: {
    //   //     minDelay: 0,
    //   //     maxDelay: 4000,
    //   //     minDuration: 1000,
    //   //     // maxDuration: 20000,
    //   //   },
    //   // },
    // },
  },
  animation: false,
  stacked: false,
  scales: {
    x: {
      type: "realtime",
      distribution: "linear",
      realtime: {
        duration: 600000,
        delay: 3000,
        // ttl: 1000,
        time: {
          displayFormat: "h:mm",
        },
      },
    },
    V: {
      type: "linear",
      display: true,
      position: "left",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        // Include a unit sign in the ticks
        callback: function (value: any) {
          return value.toFixed(2) + " V";
        },
      },
    },
    A: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        // Include a unit sign in the ticks
        callback: function (value: any) {
          return value.toFixed(2) + " mA";
        },
      },
    },
    S: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        // Include a unit sign in the ticks
        callback: function (value: any) {
          return value.toFixed(2) + " s";
        },
      },
    },
  },
};
const optionsStatic: any = {
  maintainAspectRatio: true,
  responsive: true,
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  plugins: {
    datalabels: {
      formatter: function () {
        return "";
      },
    },
    tooltip: {
      mode: "index",
      intersect: true,
      position: "nearest",
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          let keyName = tooltipItem?.dataset?.label;
          let value = tooltipItem?.formattedValue;
          let unit = tooltipItem?.dataset?.yAxisID;
          unit = `(${unit === "A" ? "mA" : unit})`;
          return keyName + " " + ":" + " " + value + unit;
        },
        title: function (tooltipItem: any, data: any) {
          return tooltipItem?.[0]?.label;
        },
      },
    },
  },

  animation: false,
  stacked: false,
  scales: {
    V: {
      type: "linear",
      display: true,
      position: "left",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        // Include a unit sign in the ticks
        callback: function (value: any) {
          return value.toFixed(2) + " V";
        },
      },
    },
    A: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        // Include a unit sign in the ticks
        callback: function (value: any) {
          return value.toFixed(2) + " mA";
        },
      },
    },
    S: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        // Include a unit sign in the ticks
        callback: function (value: any) {
          return value.toFixed(2) + " s";
        },
      },
    },
  },
};

const data = { datasets: [], labels: [] };
export const HistoryGraph = ({ deviceId, columns, liveData, graphState, loading }: any) => {
  console.log("graphState", graphState);
  const [showStreamingChart, setShowStreamingChart]: any = useState(true);
  const [unitsOfColumns, setUnitsOfColumns]: any = useState({});
  const chartReference = useRef<ChartJS>(null);
  const chartReferenceStatic = useRef<ChartJS>(null);
  const [garphOptions, setGraphOptions] = useState(showStreamingChart ? options : optionsStatic)
  const [showHideColumns, setShowHideColumns] = useState("Hide");

  const hideShowColumns = () => {
    let show: boolean;
    if (showHideColumns === "Hide") {
      show = true;
    } else {
      show = false;
    }
    const chart: any = showStreamingChart ? chartReference?.current : chartReferenceStatic?.current;
    if (chart) {
      chart?.data?.datasets?.map(function (ds: any) {
        ds.hidden = show;
        return ds;
      });

      /**
       * if we are only triggering the above dataset true or false then after clicking on any legend icon its metadata
       * gets set and the above hidden property stops working for that particular dataset thus its metadata needs to be
       * set to hidden again.
       */
      chart?._metasets?.map(function (ds: any) {
        ds.hidden = null;
        return ds;
      });
      chart?.update();
      if (showHideColumns === "Hide") setShowHideColumns("Show");
      else setShowHideColumns("Hide");
    }
  };

  useEffect(() => {
    /* MINOR BUG: how is data updating without using setData ? */
    const chart: any = chartReference?.current;
    const _dataSets = chart?.data?.datasets ?? [];
    if (_dataSets.length === 0) return;
    _dataSets.map((row: any) => {
      if (liveData?.[row.label]) {
        // console.log('hhh', parseInt(liveData[row.label]?.[0]?.value), unitsOfColumns[row.label])
        row.data.push({
          x: Date.now(),
          y: convertUnit(unitsOfColumns[row.label], parseInt(liveData[row.label]?.[0]?.value)),
          // unitsOfColumns[row.label] === "mv"
          //   ? parseInt(liveData[row.label]?.[0]?.value) / 1000
          //   : parseInt(liveData[row.label]?.[0]?.value),
        });
      } else {
        row.data.push({ x: Date.now(), y: null });
      }
      return row;
    });
    chart?.update();
    // Live Data is needed or not?
  }, [liveData]);
  // }, []);

  useEffect(() => {
    let columnUnit: any = {};
    let live_chart = graphState.chart === "live" ? true : false;
    setShowHideColumns("Hide");
    setShowStreamingChart(live_chart);
    const chart: any = chartReference?.current;
    const chartNew: any = chartReferenceStatic?.current;
    if (graphState?.data?.length === 0) {
      if (live_chart) {
        chart.config.data.datasets = [];
        chart.config.options.scales.x.realtime.duration = 600000;
        chart.update();
      } else {
        chartNew.config.data.datasets = [];
        chartNew.update();
      }
      return;
    }
    const _data = graphState.data?.data?.data;
    const _content = _data?.content;
    const columnKeys: any = {};
    const time_duration = new Date().getTime() - _content?.at(-1)?.data?.[0]?.dateTime;

    const labels: any = [];
    const displayFields: any = {};
    for (const _column of columns) {
      if (_column.field === "lastActivityTime" || _column.field === "NAME") continue;
      columnUnit[_column.field] = _column.unit;
      displayFields[_column.field] = [];
    }

    setUnitsOfColumns(columnUnit);

    // loop through the data and push values for each key if they contain number
    for (const _row of _content) {
      const _rowData = _row?.data?.[0];
      const time = _rowData.dateTime;
      const values = _rowData.values;
      const _time = moment(time).format("DD-MM-YYYY HH:mm:ss");
      labels.unshift(_time);
      if (live_chart) {
        for (const _key in displayFields) {
          const _value = values[_key];
          if (_key === "LOC") {
            continue;
          }
          columnKeys[_key] ??= [];
          if (!_value || isNaN(_value)) {
            columnKeys[_key].unshift({ x: time, y: null });

            continue;
          }
          columnKeys[_key].unshift({ x: time, y: convertUnit(columnUnit[_key] ?? "", _value) });
          // columnKeys[_key].unshift({ x: time, y: columnUnit[_key] === "v" ? _value / 1000 : _value });
          continue;
        }
      } else {
        for (const _key in displayFields) {
          let _value: any = values[_key];
          if (_key === "LOC") {
            continue;
          }
          if (!_value || isNaN(_value)) {
            _value = null;
          }
          if (_value) {
            displayFields[_key].unshift(convertUnit(columnUnit[_key] ?? "", _value));
          } else {
            displayFields[_key].unshift(null);
          }
        }
      }
    }
    chartNew.config.data.labels = labels;
    const keysToShow: any = {};
    if (live_chart) {
      // remove keys that are not to be shown
      for (const _column of columns) {
        if (!columnKeys[_column.field]) continue;
        if (_column.field === "lastActivityTime" || _column.field === "NAME") continue;
        keysToShow[_column.field] = columnKeys[_column.field];
      }
    }

    const _graphData = [];
    let _index = 0;
    const keys = live_chart ? keysToShow : displayFields;

    for (const _key in keys) {
      const _value = keys[_key];
      if (_value.length === 0) continue;
      let containsValue = false;
      for (const value of _value) {
        if (value !== null) containsValue = true;
      }
      if (!containsValue) continue;
      const yAxisID = convertKeys[columnUnit[_key]] ?? columnUnit[_key];
      if (!yAxisID || yAxisID === " " || yAxisID === "-" || yAxisID.includes("*")) continue;
      _graphData.push({
        label: _key,
        data: _value,
        borderColor: Colors[_index],
        yAxisID: yAxisID,
        spanGaps: true,
      });
      _index++;
    }
    const axis: any = { x: true }
    const totalAxis = ["x", "V", "S", "A"]
    const uniqueUnits: any = ['x']
    Object.entries(columnUnit).forEach(([key, unit]: any) => {
      const yAxisUnit = convertKeys[unit] ?? null;
      if (!uniqueUnits.includes(yAxisUnit) && yAxisUnit) {
        uniqueUnits.push(yAxisUnit)
      }
    })
    totalAxis.forEach((un: any) => {
      axis[un] = uniqueUnits.includes(un)
    })
    Object.entries(axis).forEach(([axis, show]: any) => {
      if (chart.config.options.scales.x[axis]) {
        chart.config.options.scales.x[axis].display = false
      }
    })

    if (live_chart) {
      chart.config.data.datasets = [..._graphData];
      chart.config.options.scales.x.realtime.duration = time_duration;
      chart.config.options.scales.V.display = axis["V"];
      chart.config.options.scales.A.display = axis["A"];
      chart.config.options.scales.S.display = axis["S"];
      chart.update()
    } else {
      chartNew.config.data.datasets = [..._graphData];
      chart.config.options.scales.V.display = axis["V"];
      chart.config.options.scales.A.display = axis["A"];
      chart.config.options.scales.S.display = axis["S"];
      chartNew.update();
    }
  }, [graphState]);


  return (
    <div className="history-graph">
      {loading && <Loader />}
      <div className="flex justify-center fullWidth items-center">
        <div>
          <Button variant="text" className="history-graph-toggle-data" onClick={hideShowColumns}>
            {showHideColumns} Data
          </Button>
        </div>
        <div></div>
      </div>

      <div>
        <Chart
          style={{ maxHeight: "60vh", display: showStreamingChart ? "block" : "none" }}
          redraw={true}
          ref={chartReference}
          type="line"
          data={data}
          options={garphOptions}
        ></Chart>
        <Chart
          style={{ maxHeight: "60vh", display: !showStreamingChart ? "block" : "none" }}
          redraw={true}
          ref={chartReferenceStatic}
          type="line"
          data={data}
          options={garphOptions}
        ></Chart>
      </div>
    </div>
  );
};
export default HistoryGraph;
