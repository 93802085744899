import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { implementTempReportFilters, replaceTempFilters, selectCurrentReportFilters, selectGlobelReportFilter, selectTempReportFilter, updateCurrentReportFilters, updateGlobelReportFilters } from '../reportsSlice'
import { useGetAlarmTypesQuery, useGetDeviceTypesQuery, useGetDevicesNameQuery } from '../../../features/SearchFilters/searchFiltersSlice'
import { getDeviceNameSearchParams } from '../../../features/SearchFilters/searchFiltersUtils'
import { getAlarmCategoryList } from '../../../Utils/Utils'
import { ActiveAlarmsData } from '../../Alarms/ActiveAlarms'
import { Autocomplete, Button, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useImmer } from 'use-immer'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AutocompleteStationListPop } from '../../AutocompleteStationListPop/AutocompleteStationListPop'


const initialFilters = {
    filterCustomerIds: [],
    device_type_ids: [],
    alarm_types: [],
    categories: [],
    device_ids: [],
    severity: null,
    is_active: null,
    from_ts: null,
    to_ts: null,
}

function extractCommonFilter(filterValue, dropdown, keyName) {
    // return filterValue.filter((element) => dropdown.includes(element));
    return filterValue.filter((filter) => dropdown.some((dropdown) => filter[keyName] === dropdown[keyName]));
}
function NewReportsFilter() {
    let globelFilters = useSelector(selectGlobelReportFilter)
    let tempReportFilter = useSelector(selectTempReportFilter)
    let currentReportFilters = useSelector(selectCurrentReportFilters)
    const dispatch = useDispatch()
    const { customerIdList = [], selectedCustomersIdName = [] } = useContext(ActiveAlarmsData)
    const categoriesList = getAlarmCategoryList();
    const [reportFilter, setReportFilter] = useImmer(initialFilters)
    const filterState = { customer_ids: reportFilter.filterCustomerIds.map((customer) => { return { id: customer.customerId } }), device_type_ids: reportFilter.device_type_ids }
    const { data: gearTypesList = [] } = useGetDeviceTypesQuery({ customerIdList, filterState });
    const { data: deviceNamesList = [] } = useGetDevicesNameQuery(getDeviceNameSearchParams({ customerIdList, filterState }));
    const { data: alarmTypesList = [] } = useGetAlarmTypesQuery(null);


    useEffect(() => {
        if (selectedCustomersIdName.length > 0) {
            let commonSelectedStations = extractCommonFilter(currentReportFilters.filterCustomerIds, selectedCustomersIdName, 'customerId')
            let newReportsFilter = { ...currentReportFilters, filterCustomerIds: commonSelectedStations }
            if (tempReportFilter.length > 0) {
                let newLastObjOfTempFilter = { ...tempReportFilter.slice(-1)[0], ...newReportsFilter }
                let _newTempFilter = [...tempReportFilter]
                _newTempFilter[_newTempFilter.length - 1] = newLastObjOfTempFilter
                // console.log('check', _newTempFilter)
                dispatch(replaceTempFilters(_newTempFilter))
            } else {
                dispatch(updateGlobelReportFilters(newReportsFilter))
            }
        }
    }, [selectedCustomersIdName])

    useEffect(() => {
        let commonSelectedAssets = extractCommonFilter(reportFilter.device_ids, deviceNamesList, 'id')
        setReportFilter((prev) => {
            prev.device_ids = commonSelectedAssets
        })
        // console.log('commonSelectedAssets', reportFilter.device_ids, deviceNamesList)
    }, [deviceNamesList])
    useEffect(() => {
        let commonSelectedDeviceTypes = extractCommonFilter(reportFilter.device_type_ids, gearTypesList, 'id')
        setReportFilter((prev) => {
            prev.device_type_ids = commonSelectedDeviceTypes
        })
        // console.log('commonSelectedAssets', reportFilter.device_ids, deviceNamesList)
    }, [gearTypesList])

    const handleFilterChange = (stateName, stateValue) => {
        setReportFilter((prev) => {
            prev[stateName] = stateValue
        })
    }

    useEffect(() => {
        if (tempReportFilter.length > 0) {
            let newLastObjOfTempFilter = { ...tempReportFilter.slice(-1)[0] }
            dispatch(updateCurrentReportFilters(newLastObjOfTempFilter))
            setReportFilter(newLastObjOfTempFilter)
        } else {
            dispatch(updateCurrentReportFilters(globelFilters))
            setReportFilter(globelFilters)
        }
    }, [globelFilters, tempReportFilter])


    const filterReports = () => {
        if (tempReportFilter.length > 0) {
            let newLastObjOfTempFilter = { ...tempReportFilter.slice(-1)[0], ...reportFilter }
            let _newTempFilter = [...tempReportFilter]
            _newTempFilter[_newTempFilter.length - 1] = newLastObjOfTempFilter
            // console.log('check', _newTempFilter)
            dispatch(replaceTempFilters(_newTempFilter))
        } else {
            dispatch(updateGlobelReportFilters(reportFilter))
        }
    }
    const clearReportsFilter = () => {
        if (tempReportFilter.length > 0) {
            let newLastObjOfTempFilter = { ...tempReportFilter.slice(-1)[0], ...initialFilters }
            let _newTempFilter = [...tempReportFilter]
            _newTempFilter[_newTempFilter.length - 1] = newLastObjOfTempFilter
            dispatch(replaceTempFilters(_newTempFilter))
        } else {
            dispatch(updateGlobelReportFilters(initialFilters))
        }
    }
    return (
        <>
            <Autocomplete
                size="small"
                className="filter-input auto-complete"
                multiple
                disableCloseOnSelect
                options={selectedCustomersIdName}
                isOptionEqualToValue={(option, value) => option.customerId === value.customerId}
                renderInput={(params) => (
                    <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
                )}
                getOptionLabel={(customer) => customer.customerName}
                value={reportFilter?.filterCustomerIds ?? []}
                onChange={(_, newValue) => {
                    handleFilterChange('filterCustomerIds', newValue)
                }}
                renderTags={(value, getTagProps) => {
                    return (
                        <div>
                            <Chip size="small" variant="outlined" label={value[0].customerName} {...getTagProps(0)} />
                            {value.length > 1 &&
                                <AutocompleteStationListPop title={value.map((stn) => stn?.customerName ?? '').toString()}>
                                    <Chip size="small" label={`+${value.length - 1}`} />
                                </AutocompleteStationListPop>
                            }
                        </div>
                    );
                }}
            />
            <Autocomplete
                size="small"
                className="filter-input auto-complete"
                multiple
                disableCloseOnSelect
                options={gearTypesList}
                renderInput={(params) => <TextField size="small" {...params} label="Gear Types" placeholder="Gear Types" />}
                getOptionLabel={(gearType) => gearType.value}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={reportFilter?.device_type_ids}
                onChange={(_, newValue) => {
                    handleFilterChange('device_type_ids', newValue)
                }}
                renderTags={(value, getTagProps) => {
                    return (
                        <div>
                            <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
                            {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
                        </div>
                    );
                }}
            />
            <Autocomplete
                size="small"
                className="filter-input auto-complete"
                multiple
                disableCloseOnSelect
                options={deviceNamesList}
                renderInput={(params) => <TextField size="small" {...params} label="Assets" placeholder="Assets" />}
                getOptionLabel={(asset) => asset.value}
                value={reportFilter?.device_ids}
                onChange={(event, newValue) => {
                    handleFilterChange('device_ids', newValue)
                }}
                renderTags={(value, getTagProps) => {
                    return (
                        <div>
                            <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />{" "}
                            {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
                        </div>
                    );
                }}
            />

            <Autocomplete
                size="small"
                className="filter-input auto-complete"
                multiple
                disableCloseOnSelect
                limitTags={1}
                options={categoriesList}
                value={reportFilter?.categories}
                onChange={(event, newValue) => {
                    handleFilterChange('categories', newValue)
                }}
                renderInput={(params) => <TextField size="small" {...params} label="Categories" placeholder="Categories" />}
                renderTags={(value, getTagProps) => {
                    return (
                        <div>
                            <Chip size="small" variant="outlined" label={value[0]} {...getTagProps(0)} />{" "}
                            {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
                        </div>
                    );
                }}
            />
            <Autocomplete
                size="small"
                className="filter-input auto-complete"
                multiple
                disableCloseOnSelect
                limitTags={1}
                options={alarmTypesList}
                value={reportFilter?.alarm_types}
                onChange={(event, newValue) => {
                    handleFilterChange('alarm_types', newValue)
                }}
                renderInput={(params) => <TextField size="small" {...params} label="Alarm Types" placeholder="Alarm Types" />}
                renderTags={(value, getTagProps) => {
                    return (
                        <div>
                            <Chip size="small" variant="outlined" label={value[0]} {...getTagProps(0)} />{" "}
                            {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}{" "}
                        </div>
                    );
                }}
            />
            <FormControl size="small" className="filter-input">
                <InputLabel id="standard-label">{"Severity"}</InputLabel>
                <Select
                    defaultValue=""
                    id="select-standard"
                    label="Severity"
                    value={reportFilter?.severity}
                    onChange={(event) => {
                        handleFilterChange('severity', event.target.value)
                    }}
                >
                    <MenuItem key={1} value={"WARNING"}>
                        WARNING
                    </MenuItem>
                    <MenuItem key={2} value={"CRITICAL"}>
                        CRITICAL
                    </MenuItem>
                    <MenuItem key={2} value={"MAINTENANCE"}>
                        MAINTENANCE
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl size="small" className="filter-input">
                <InputLabel id="standard-label">Active/InActive</InputLabel>
                <Select
                    defaultValue=""
                    id="select-standard"
                    label="Active/InActive"
                    value={reportFilter?.is_active}
                    onChange={(event) => {
                        handleFilterChange('is_active', event.target.value)
                    }}
                >
                    <MenuItem key={1} value={true}>
                        Active
                    </MenuItem>
                    <MenuItem key={2} value={false}>
                        InActive
                    </MenuItem>
                </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    size="small"
                    disableFuture
                    className="filter-input"
                    label="From Date"
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={reportFilter?.from_ts}
                    onChange={(newValue) => {
                        if (Date.parse(newValue && newValue["$d"])) {
                            let ts = newValue["$d"].getTime();
                            handleFilterChange('from_ts', ts)
                        } else {
                            if (newValue === null) {
                                handleFilterChange('from_ts', newValue)
                            }
                        }
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
                <DatePicker
                    disableFuture
                    className="filter-input"
                    label="To Date"
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={reportFilter?.to_ts}
                    onChange={(newValue) => {
                        if (Date.parse(newValue && newValue["$d"])) {
                            let ts = newValue["$d"].getTime();
                            handleFilterChange('to_ts', ts)
                        } else {
                            if (newValue === null) {
                                handleFilterChange('to_ts', newValue)
                            }
                        }
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
            </LocalizationProvider>
            <Button variant='contained' onClick={filterReports}>Search</Button>
            <Button variant='outlined' onClick={clearReportsFilter}>Clear</Button>
        </>
    )
}

export default NewReportsFilter
