import { useRef } from "react";
import { getToken } from "../../Services/firebase-init";
import { userIdSelector } from "../../features/authentication/auth-slice";
import { useSelector } from "react-redux";
import axios from "axios";
import { APP_BASE_URL_NOTIFICATIONS, REGISTER_USER, UNREGISTER_USER } from "../../Common/EndPoints";

export default function useFirebaseCloudMessaging() {
  const tokenRef = useRef<Promise<string | null>>(getToken());
  const userId = useSelector(userIdSelector);

  async function registerUser(user_id: string) {
    const token = await tokenRef.current;
    if (!token) return;
    const params = { user_id: user_id, token };
    const register = await axios.post(APP_BASE_URL_NOTIFICATIONS + REGISTER_USER, null, { params });
    return register;
  }

  async function unregisterUser() {
    const token = await tokenRef.current;
    if (!token) return;
    const params = { user_id: userId, token };
    const unregister = await axios.delete(APP_BASE_URL_NOTIFICATIONS + UNREGISTER_USER, { params });
    return unregister;
  }

  return { registerUser, unregisterUser };
}
