import { Table } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import { useParams } from "react-router-dom";

import { CABLE_MONITORING_COLUMS, DATE_TIME_FORMAT } from "./CableMonitoring";
import { CableMonitoringHistoryData, getParsedCableHistoryData } from "./parse-cable-history-data";
import { APP_BASE_URL, GET_CABLE_MONITORING_HISTORY, GET_DEVICE_BY_ID } from "../../Common/EndPoints";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

export type SingleDevice = {
  name: string;
};

type GetDeviceByIdResponse = {
  data: SingleDevice;
};

function getDeviceById(device_id: string) {
  return axios.get<GetDeviceByIdResponse>(APP_BASE_URL + GET_DEVICE_BY_ID + `/${device_id}`, {
    params: { with_customer: true, with_type: true },
  });
}

type GetCableMonitoringHistoryParams = {
  cableId: string;
  page?: number;
  pageSize?: number;
  startTs?: any;
  endTs?: any;
};

type SubGearPacket = {
  data: { telemetry: Record<string, string>; ts: number }[];
  device: SingleDevice;
  meta?: null | {
    outdoor_ref?: number;
    indoor_receive?: { key: string; min: string; max: string }[];
    outdoor_realtime?: { key: string; min: string; max: string }[];
    loc?: string;
  };
};

export type CableDataRaw = {
  cableHistoryData: SubGearPacket[];
  data: Record<string, string>;
  id: string;
  ts: string;
};

type GetCableMonitoringHistoryResponse = {
  data: { content: CableDataRaw[]; totalElements: number };
};

function getCableMonitoringHistory(params: GetCableMonitoringHistoryParams) {
  return axios.get<GetCableMonitoringHistoryResponse>(APP_BASE_URL + GET_CABLE_MONITORING_HISTORY, { params });
}

function getCableHistoryColumns() {
  const _columns: ColumnType<any>[] = CABLE_MONITORING_COLUMS.map((column: ColumnType<any>) => {
    if (column.dataIndex !== "cable") return column;
    const cable_column = { ...column };
    cable_column.render = (value: string) => value;
    return cable_column;
  }).filter((column) => column.dataIndex !== "actions");

  const time_column: ColumnType<any> = {
    title: "Last Activity Time",
    dataIndex: "time",
    align: "center",
    width: 100,
    render: (value: string, row_data) => {
      const time = Number(row_data.time);
      if (!time) return "-";
      return <div>{moment(time, "x").format(DATE_TIME_FORMAT)}</div>;
    },
  };
  _columns.unshift(time_column);
  return _columns;
}

type FiterType = {
  current: number;
  pageSize: number;
  total: number;
  pageSizeOptions: number[];
  from_ts: Dayjs | null;
  to_ts: Dayjs | null;
};
const DEFAULT_PAGINATION_CONFIG: FiterType = {
  current: 1,
  pageSize: 10,
  total: 0,
  pageSizeOptions: [10, 20, 50],
  from_ts: null,
  to_ts: null,
};

const CableMonitoringHistory = () => {
  const [data, setData] = useState<CableMonitoringHistoryData[]>([]);
  const [deviceInfo, setDeviceInfo] = useState<null | SingleDevice>(null);
  const [isFetchingDeviceInfo, setIsFetchingDeviceInfo] = useState(true);
  const [isFetchingCableHistory, setIsFetchingCableHistory] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_CONFIG);
  const { id: device_id } = useParams();

  async function fetchDeviceInfo() {
    setIsFetchingDeviceInfo(true);
    if (typeof device_id !== "string") return;
    try {
      const response = await getDeviceById(device_id);
      const device_info = response.data.data;
      setDeviceInfo(device_info);
    } catch (error) {
      setDeviceInfo(null);
    } finally {
      setIsFetchingDeviceInfo(false);
    }
  }

  async function fetchCableHistory() {
    if (typeof device_id !== "string") return;
    if (deviceInfo === null) return;
    setIsFetchingCableHistory(true);
    try {
      const response = await getCableMonitoringHistory({
        cableId: device_id,
        page: pagination.current ?? 0,
        pageSize: pagination.pageSize,
        startTs: pagination.from_ts ? dayjs(pagination.from_ts).format("YYYY-MM-DD hh:mm:ss") : null,
        endTs: pagination.to_ts ? dayjs(pagination.to_ts).endOf("day").format("YYYY-MM-DD hh:mm:ss") : null,
      });
      const cable_history = response.data.data.content;
      setPagination((state) => ({ ...state, total: response.data.data.totalElements }));
      const cable_history_parsed = getParsedCableHistoryData(cable_history, deviceInfo);
      console.log("cable_history_parsed", cable_history_parsed);
      setData(cable_history_parsed);
    } catch (error) {
      // setPagination(DEFAULT_PAGINATION_CONFIG);
      setData([]);
    } finally {
      setIsFetchingCableHistory(false);
    }
  }

  useEffect(() => {
    fetchDeviceInfo();
  }, [device_id]);

  useEffect(() => {
    if (!deviceInfo) return;
    fetchCableHistory();
  }, [deviceInfo, pagination.current, pagination.pageSize, pagination.from_ts, pagination.to_ts]);

  const history_columns = getCableHistoryColumns();

  function handleChange(pagination: TablePaginationConfig) {
    setPagination((state) => ({
      ...state,
      current: pagination.current ?? 1,
      total: pagination.total ?? 0,
      pageSize: pagination.pageSize ?? 10,
    }));
  }

  return (
    <>
      <div style={{ height: "100%", position: "relative" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="flex items-center gap-2 mb-2 bg-white p-2">
            <DatePicker
              disableFuture
              className="filter-input"
              label="From Date"
              openTo="day"
              views={["year", "month", "day"]}
              value={pagination.from_ts}
              onChange={(value: Dayjs | null) => {
                setPagination((state) => ({ ...state, from_ts: value }));
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />

            <DatePicker
              disableFuture
              className="filter-input"
              label="To Date"
              openTo="day"
              views={["year", "month", "day"]}
              value={pagination.to_ts}
              onChange={(value: Dayjs | null) => {
                setPagination((state) => ({ ...state, to_ts: value }));
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </div>
        </LocalizationProvider>
        <div
          className="absolute w-full"
          style={{
            height: "90%",
            display: "flex",
            flexDirection: "column",
            border: "1px solid #E0E0E0",
            borderRadius: "5px",
          }}
        >
          <Table
            sticky
            className="cable-monitoring__table w-full"
            // style={{ height: "95%", padding: "0.5rem" }}
            dataSource={data}
            onChange={handleChange}
            columns={history_columns}
            loading={isFetchingCableHistory || isFetchingDeviceInfo}
            pagination={pagination}
            size="small"
            // scroll={{ x: 100, y: 620 }}
            scroll={{ x: 100, y: "calc(80vh - 7rem)" }}
          />
        </div>
      </div>
    </>
  );
};

export default CableMonitoringHistory;
