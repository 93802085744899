import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const ASSIGNMENT_TABLE_COLUMN = (allowUpdateFormAssignment, handleDelete) => {
  const navigate = useNavigate();
  return [
    {
      field: "editActions",
      type: "actions",
      minWidth: 150,
      flex: 1,
      hideable: false,
      getActions: (params) =>
        allowUpdateFormAssignment
          ? [
            <Tooltip placement="bottom" title="Edit" disableInteractive>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={(event) => {
                  navigate("/assignment-form", {
                    state: params.row.editState,
                  });
                }}
              />
            </Tooltip>,
            <Tooltip placement="bottom" title="Delete" disableInteractive>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={(event) => {
                  handleDelete(params.row.assignmentId)
                }}
              />
            </Tooltip>,
          ]
          : [],
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      sortable: true,
      minWidth: 180,
      flex: 1,
      type: "date",
      valueFormatter: (param) => {
        if (param.value && param.value > 0) {
          const value = Number(param.value);
          const date = new Date(value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = "0" + dd;
          if (mm < 10) mm = "0" + mm;

          const formattedToday = dd + "/" + mm + "/" + yyyy;

          return formattedToday;
        } else {
          return "-";
        }
      },
    },
    {
      field: "scheduleCode",
      headerName: "Schcedule Code",
      sortable: true,
      width: 130,
      flex: 1,
    },
    {
      field: "gearType",
      headerName: "Asset Type",
      sortable: true,
      width: 130,
      flex: 1,
    },
    {
      field: "formAssigned",
      headerName: "Form Assigned",
      sortable: true,
      flex: 1,
      width: 150,
    },
    {
      field: "startedDate",
      headerName: "Start From",
      sortable: true,
      flex: 1,
      width: 180,
      valueFormatter: (param) => {
        if (param.value && param.value > 0) {
          const value = Number(param.value);
          const date = new Date(value);
          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = "0" + dd;
          if (mm < 10) mm = "0" + mm;

          const formattedToday = dd + "/" + mm + "/" + yyyy;
          const time = date.toLocaleTimeString();
          return formattedToday;
        } else {
          return "-";
        }
      },
    },
    {
      field: "assignedUseRole",
      headerName: "Assigned user role",
      sortable: true,
      flex: 1,
      width: 120,
    },
    {
      field: "AssignedGroup",
      headerName: "Assigned Group",
      sortable: true,
      flex: 1,
      width: 120,
    },
  ];
};

export const getFormattedDate = (ts) => {
  const today = new Date(ts);
  const yyyy = today.getFullYear();
  let mm = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1; // Months start at 0!
  // let mm = today.getMonth() + 1 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

  const formattedToday = yyyy + "-" + mm + "-" + dd;

  return formattedToday;
};
