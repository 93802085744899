import React, { useMemo } from "react";

import BHMS_GENERATOR from "./BHMS_GENERATOR";

const BHMS_JSON = {
  grid_config: {
    rows: 1,
    columns: 5,
  },
  data_config: {
    data_mapping: "grid", // "grid" | "cell"
    key_prefix: "B",
    value_prefix: "Bank ",
    value_unit: "V",
    value_thresold: 9.5,
    disabled_cell_bg_color: "lightgray",
    threshold_exceeded_bg_color: "lightgreen",
    threshold_not_exceeded_bg_color: "red",
  },
  number_of_horizontal_grids: 1,
  number_of_vertical_grids: 6,
  type: "horizontal", // "horizontal" | "vertical" | "random"
  is_spiral: false,
  starting_point: "topLeft", // "topLeft" | "topRight" | "bottomLeft" | "bottomRight"
};

const BHMS2_JSON = {
  grid_config: {
    rows: 1,
    columns: 5,
  },
  data_config: {
    data_mapping: "grid", // "grid" | "cell"
    key_prefix: "B",
    value_prefix: "Bank ",
    value_unit: "V",
    value_thresold: 9.5,
    disabled_cell_bg_color: "lightgray",
    threshold_exceeded_bg_color: "lightgreen",
    threshold_not_exceeded_bg_color: "red",
    cell_start_index: 31,
    grid_start_index: 7,
  },
  number_of_horizontal_grids: 1,
  number_of_vertical_grids: 5,
  type: "horizontal", // "horizontal" | "vertical" | "random"
  is_spiral: false,
  starting_point: "topLeft", // "topLeft" | "topRight" | "bottomLeft" | "bottomRight"
};

const BhmsWrapper: React.FC<any> = ({ state }) => {
  console.log("state", state);

  const data = useMemo(() => {
    let merged_data: Record<string, string> = {};
    for (const packet of state.data) {
      merged_data = { ...merged_data, ...packet };
    }
    for (const key in merged_data) {
      if (!key.startsWith("B")) continue;
      const merged_data_key = key as keyof typeof merged_data;
      if (merged_data[merged_data_key] && Number(merged_data[merged_data_key])) {
        merged_data[merged_data_key] = (Number(merged_data[merged_data_key]) / 1000).toFixed(3).toString();
      }
    }
    return merged_data;
  }, [state.data]);

  const bhms_info = useMemo(() => {
    const IBAT = data.IBAT && Number(data.IBAT) ? Number(data.IBAT) / 1000 : 0;
    const VBAT = data.VBAT && Number(data.VBAT) ? Number(data.VBAT) / 1000 : 0;

    const charging_current = IBAT > 0 ? IBAT : 0;
    const discharge_current = IBAT < 0 ? Math.abs(IBAT) : 0;
    const bank_voltage = VBAT;

    return {
      bank_voltage,
      charging_current,
      discharge_current,
    };
  }, [data]);

  return (
    <div className="bhms-page">
      <div className="bhms-info" style={{ width: "40rem" }}>
        <div>
          <div>Bank Voltage</div>
          <div>{bhms_info.bank_voltage} V</div>
        </div>
        <div>
          <div>Charging Current</div>
          <div>{bhms_info.charging_current} A</div>
        </div>
        <div>
          <div>Discharge Current</div>
          <div>{bhms_info.discharge_current} A</div>
        </div>
      </div>
      <BHMS_GENERATOR data={data} config={BHMS_JSON} />
      <BHMS_GENERATOR data={data} config={BHMS2_JSON} />
    </div>
  );
};

export default BhmsWrapper;
