import { memo, useContext } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  alarmFiltersSelector,
  openModal,
  selectedAlarmRows,
  temporaryAlarmFiltersSelector,
  updateAlarmFilters,
  updateTemporaryAlarmFilters,
  useGetGroupByCountQuery,
} from "./alarmsSlice";
import { ActiveAlarmsData } from "../../Components/Alarms/ActiveAlarms";
import { getAlarmsQueryParams } from "./alarmUtils";

const AlarmStatusTabs = memo(() => {
  const dispatch = useDispatch();
  const temporaryFilters = useSelector(temporaryAlarmFiltersSelector);
  const alarmFilters = useSelector(alarmFiltersSelector);
  const alarmStatus = temporaryFilters ? temporaryFilters.is_active : alarmFilters.is_active;
  const handleAlarmFilterChange = (param) => {
    if (temporaryFilters) {
      dispatch(updateTemporaryAlarmFilters(param));
      return;
    }
    dispatch(updateAlarmFilters(param));
  };

  return (
    <div className="">
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          variant={alarmStatus === true ? "contained" : "outlined"}
          onClick={() => handleAlarmFilterChange({ is_active: true })}
        >
          Active
        </Button>
        <Button
          variant={alarmStatus === false ? "contained" : "outlined"}
          onClick={() => handleAlarmFilterChange({ is_active: false })}
        >
          Closed
        </Button>
        <Button
          variant={alarmStatus === null || alarmStatus === undefined ? "contained" : "outlined"}
          onClick={() => handleAlarmFilterChange({ is_active: null })}
        >
          History
        </Button>
      </ButtonGroup>
    </div>
  );
});

const AlarmGroupByCount = memo(() => {
  const alarmFilters = useSelector(alarmFiltersSelector);
  const temporaryFilters = useSelector(temporaryAlarmFiltersSelector);
  const { customerIdList } = useContext(ActiveAlarmsData);
  const { data: groupByData } = useGetGroupByCountQuery(
    getAlarmsQueryParams({ temporaryFilters, customerIdList, alarmFilters }),
  );
  const renderCount = groupByData?.map((_type, index) => (
    <div
      className={`font-bold ${_type.col_name === "WARNING" ? "text-orange-500" : "text-red-500"}`}
      key={`${_type.col_name}-${index}`}
    >
      {_type.col_name} : {_type.cnt}
    </div>
  ));

  return <div className="flex gap-2">{renderCount}</div>;
});

const AlarmActionsTab = () => {
  const dispatch = useDispatch();
  const selectedRows = useSelector(selectedAlarmRows);
  const handleAction = (type) => dispatch(openModal({ modalType: type, alarmId: selectedRows.join(",") }));
  if (selectedRows.length === 0) return <div className="flex-1"></div>;
  return (
    <div className="">
      <Button onClick={() => handleAction("multi_ack")}>Acknowledge Selected</Button>
      <Button onClick={() => handleAction("multi_clear")}>Clear Selected</Button>
    </div>
  );
};

export const AlarmTableHeader = memo(({ allowUpdateAlarms }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-4 items-center">
        <AlarmStatusTabs />
        <AlarmGroupByCount />
      </div>
      {allowUpdateAlarms && <AlarmActionsTab />}
    </div>
  );
});
