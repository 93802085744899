export const TOTAL_POSSIBLE_PM_KEYS = [
  ["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "B9", "B10", "B11"],
  "IBAT",
  "VBAT",
];
export const FIXED_VIRTUAL_SPACE_FOR_EACH_KEY = 200;
export const FIXED_VIRTUAL_BUFFER_BETWEEN_KEYS = 40;
export const KEYS_TO_BE_FORMATTED = ["S", "A", "V"];

export const STATIC_KEYS_INFO: any = {
  B1: {
    key: "B1",
    label: "B1",
    lineColor: "#FCD05E",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B2: {
    key: "B2",
    label: "B2",
    lineColor: "#3f51b5",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B3: {
    key: "B3",
    label: "B3",
    lineColor: "blue",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B4: {
    key: "B4",
    label: "B4",
    lineColor: "#e51c23",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B5: {
    key: "B5",
    label: "B5",
    lineColor: "#00876c",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B6: {
    key: "B6",
    label: "B6",
    lineColor: "#65b17a",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B7: {
    key: "B7",
    label: "B7",
    lineColor: "#ef8251",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B8: {
    key: "B8",
    label: "B8",
    lineColor: "#d43d51",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B9: {
    key: "B9",
    label: "B9",
    lineColor: "#e91e63",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B10: {
    key: "B10",
    label: "B10",
    lineColor: "#9c27b0",
    actualUnitsForYaxis: 20,
    unit: "A",
  },
  B11: {
    key: "B11",
    label: "B11",
    lineColor: "#673ab7",
    actualUnitsForYaxis: 20,
    unit: "V",
    box_label: "B1 - B11",
  },
  IBAT: {
    key: "IBAT",
    label: "IBank",
    lineColor: "#7BB367",
    actualUnitsForYaxis: 80,
    custom_range: { min: -40, max: 40 },
    unit: "A",
  },
  VBAT: {
    key: "VBAT",
    label: "VBank",
    lineColor: "#117BA8",
    actualUnitsForYaxis: 150,
    unit: "V",
  },
  RWV: {
    key: "RWV",
    label: "Revarse Operation Voltage",
    lineColor: "#404040",
    actualUnitsForYaxis: 160,
    unit: "V",
  },
};

export const DUMMY_POINT_RESPONSE = [
  //   {
  //     createdTs: 1703577428156,
  //     data: {
  //       STN: "AELN",
  //       VER: "1.0",
  //       LOC: "SER",
  //       DATE: "04-01-00",
  //       TIME: "22-23-25",
  //       TEMP: "0",
  //       RH: "0",
  //       RSSI: "0",
  //       NAME: "100A",
  //       GEARTYPE: "PM",
  //       SUBGEAR: "PMO",
  //       EVENTTYPE: "4",
  //       OC: "2200",
  //       PC: "4000",
  //       OT: "2552",
  //       EVENTTIME: [
  //         0, 24, 47, 70, 94, 117, 141, 164, 187, 211, 234, 258, 281, 305, 328, 351, 375, 398, 422, 445, 468, 492, 515,
  //         539, 562, 585, 609, 632, 656, 679, 703, 726, 749, 773, 796, 820, 843, 866, 890, 913, 937, 960, 984, 1007, 1030,
  //         1054, 1077, 1101, 1124, 1147, 1171, 1194, 1218, 1241, 1265, 1288, 1311, 1335, 1358, 1382, 1405, 1428, 1452,
  //         1475, 1499, 1522, 1545, 1569, 1592, 1616, 1639, 1663, 1686, 1709, 1733, 1756, 1780, 1803, 1826, 1850, 1873,
  //         1897, 1920, 1944, 1967, 1990, 2014, 2037, 2061, 2084, 2107, 2131, 2154, 2178, 2201, 2224, 2248, 2271, 2295,
  //         2318, 2342, 2552, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //       ],
  //       EVENTDATA1: [
  //         0, 262, 262, 275, 275, 275, 287, 287, 300, 300, 300, 312, 312, 312, 325, 325, 337, 337, 337, 350, 350, 350, 362,
  //         362, 362, 375, 375, 375, 387, 387, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400,
  //         400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400,
  //         400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 387, 387, 387, 375,
  //         375, 375, 362, 362, 362, 350, 350, 337, 337, 337, 325, 325, 287, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0,
  //       ],
  //       PARENT_DEVICE: "AELN_PM_100A",
  //       NWV: "112000",
  //       RWV: "108000",
  //     },
  //     tsCategoryId: 45,
  //   },
  //   {
  //     createdTs: 1703577438156,
  //     data: {
  //       STN: "AELN",
  //       VER: "1.0",
  //       LOC: "SER",
  //       DATE: "04-01-00",
  //       TIME: "22-23-25",
  //       TEMP: "0",
  //       RH: "0",
  //       RSSI: "0",
  //       NAME: "100A",
  //       GEARTYPE: "PM",
  //       SUBGEAR: "PMO",
  //       EVENTTYPE: "4",
  //       OC: "2300",
  //       PC: "4100",
  //       OT: "3052",
  //       EVENTTIME: [
  //         0, 24, 47, 70, 94, 117, 141, 164, 187, 211, 234, 258, 281, 305, 328, 351, 375, 398, 422, 445, 468, 492, 515,
  //         539, 562, 585, 609, 632, 656, 679, 703, 726, 749, 773, 796, 820, 843, 866, 890, 913, 937, 960, 984, 1007, 1030,
  //         1054, 1077, 1101, 1124, 1147, 1171, 1194, 1218, 1241, 1265, 1288, 1311, 1335, 1358, 1382, 1405, 1428, 1452,
  //         1475, 1499, 1522, 1545, 1569, 1592, 1616, 1639, 1663, 1686, 1709, 1733, 1756, 1780, 1803, 1826, 1850, 1873,
  //         1897, 1920, 1944, 1967, 1990, 2014, 2037, 2061, 2084, 2107, 2131, 2154, 2178, 2201, 2224, 2248, 2271, 2295,
  //         2318, 2342, 2552, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //       ],
  //       EVENTDATA1: [
  //         0, 262, 262, 275, 275, 275, 287, 287, 300, 300, 300, 312, 312, 312, 325, 325, 337, 337, 337, 350, 350, 350, 362,
  //         362, 362, 375, 375, 375, 387, 387, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400,
  //         400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400,
  //         400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 387, 387, 387, 375,
  //         375, 375, 362, 362, 362, 350, 350, 337, 337, 337, 325, 325, 287, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  //         0, 0, 0, 0, 0, 0, 0, 0,
  //       ],
  //       PARENT_DEVICE: "AELN_PM_100A",
  //       NWV: "110000",
  //       RWV: "109000",
  //     },
  //     tsCategoryId: 45,
  //   },
];

export const DUMMY_RES = [
  {
    data: [
      {
        subGear: "SB2",
        dateTime: 1707919733589,
        classification: null,
        values: {
          LOC: "IPSROOM",
          B2: 4000,
          B3: 11000,
          B4: 3000,
          B10: 6051,
          B11: 11021,
          B12: 121903,
          B13: "***",
          B14: "***",
          B15: "***",
          B16: "***",
          STN: "VS",
          NAME: "BATT2",
          B9: 10899,
          SUBGEAR: "SB2",
          GEARTYPE: "BAT",
          IBAT: -11000,
          VBAT: 70000,
        },
      },
    ],
  },
  {
    data: [
      {
        subGear: "SB1",
        dateTime: 1707919718170,
        classification: null,
        values: {
          LOC: "IPSROOM",
          STN: "VS",
          NAME: "BATT1",
          B1: 11021,
          B2: 11051,
          B3: 1000,
          B4: 9000,
          B5: 11021,
          B6: 11021,
          B7: 11021,
          B8: 11021,
          SUBGEAR: "SB1",
          GEARTYPE: "BAT",
          IBAT: 1000,
          VBAT: 43000,
        },
      },
    ],
  },
  {
    data: [
      {
        subGear: "SB2",
        dateTime: 1707919644886,
        classification: null,
        values: {
          LOC: "IPSROOM",
          B10: 11051,
          B11: 11021,
          B12: 121903,
          B13: "***",
          B14: "***",
          B15: "***",
          B16: "***",
          STN: "VS",
          NAME: "BATT2",
          B9: 10899,
          SUBGEAR: "SB2",
          GEARTYPE: "BAT",
          IBAT: -1000,
          VBAT: 15000,
        },
      },
    ],
  },
  {
    data: [
      {
        subGear: "SB1",
        dateTime: 1707919644537,
        classification: null,
        values: {
          LOC: "IPSROOM",
          STN: "VS",
          NAME: "BATT1",
          B1: 11021,
          B2: 11051,
          B3: 11051,
          B4: 11021,
          B5: 11021,
          B6: 11051,
          B7: 11021,
          B8: 11021,
          SUBGEAR: "SB1",
          GEARTYPE: "BAT",
          IBAT: 11000,
          VBAT: 23000,
        },
      },
    ],
  },
  {
    data: [
      {
        subGear: "SB2",
        dateTime: 1707919484722,
        classification: null,
        values: {
          LOC: "IPSROOM",
          B10: 5000,
          B11: 7000,
          B12: 121764,
          B13: "***",
          B14: "***",
          B15: "***",
          B16: "***",
          STN: "VS",
          NAME: "BATT2",
          B9: 1000,
          SUBGEAR: "SB2",
          GEARTYPE: "BAT",
          IBAT: 11000,
          VBAT: 16000,
        },
      },
    ],
  },
  {
    data: [
      {
        subGear: "SB1",
        dateTime: 1707919469203,
        classification: null,
        values: {
          LOC: "IPSROOM",
          STN: "VS",
          NAME: "BATT1",
          B1: 2021,
          B2: 6051,
          B3: 10021,
          B4: 9991,
          B5: 7021,
          B6: 6021,
          B7: 8021,
          B8: 4021,
          SUBGEAR: "SB1",
          GEARTYPE: "BAT",
          IBAT: 11000,
          VBAT: 1000,
        },
      },
    ],
  },
  {
    data: [
      {
        subGear: "SB2",
        dateTime: 1707919360263,
        classification: null,
        values: {
          LOC: "IPSROOM",
          B10: 1051,
          B11: 6021,
          B12: 121903,
          B13: "***",
          B14: "***",
          B15: "***",
          B16: "***",
          STN: "VS",
          NAME: "BATT2",
          B9: 10899,
          SUBGEAR: "SB2",
          GEARTYPE: "BAT",
          IBAT: 11000,
          VBAT: 67000,
        },
      },
    ],
  },
  {
    data: [
      {
        subGear: "SB1",
        dateTime: 1707919344824,
        classification: null,
        values: {
          LOC: "IPSROOM",
          STN: "VS",
          NAME: "BATT1",
          B1: 11021,
          B2: 11051,
          B3: 1021,
          B4: 1991,
          B5: 1021,
          B6: 5021,
          B7: 7021,
          B8: 8021,
          SUBGEAR: "SB1",
          GEARTYPE: "BAT",
          IBAT: 11000,
          VBAT: 66000,
        },
      },
    ],
  },
];
