import axios from "axios";
import { GET_ASSET_FORM, MAINTENACE_FORM_API } from "../../../Common/EndPoints";
import { ACTIVE_ICON, FORM_VIEW_ICON, INACTIVE_ICON } from "../../../ICONS/Icons";

const getRowFromMaintenanceData = (response)=>{
    let tableRows = [...response]
    response.forEach((row, i) => {
        let shchAssignArray = row.schedulesAssignments;
        let schCodes = [];
        if (shchAssignArray.length > 0) {
          shchAssignArray.forEach((scode, i) => {
            if (scode.scheduleCode) {
              schCodes.push(scode.scheduleCode);
            }
          });
        }
        row.createdBy = row.createdBy.firstName + " " + row.createdBy.lastName;
        row.schdeuleCodes = schCodes.length > 0 ? schCodes.toString() : "-";
      });
      return tableRows
}

const getRowFromAssetsFormData =(response)=>{
    let tableRows = response?.map((row,i)=>{
        return {...row,gearType:row.deviceType.label}
    })

    return tableRows
}

const maintenanceFormColumn =
    [

        {

            field: "createdDt",
            headerName: "Created Date",
            sortable: true,
            minWidth: 180,
            flex: 1,
            type: "date",
            valueFormatter: (param) => {
                if (param.value && param.value > 0) {
                    const value = Number(param.value);
                    const date = new Date(value);
                    const yyyy = date.getFullYear();
                    let mm = date.getMonth() + 1; // Months start at 0!
                    let dd = date.getDate();

                    if (dd < 10) dd = "0" + dd;
                    if (mm < 10) mm = "0" + mm;

                    const formattedToday = dd + "/" + mm + "/" + yyyy;
                    const time = date.toLocaleTimeString();
                    return formattedToday + " " + time;
                } else {
                    return "-";
                }
            },
        },
        {
            field: "createdBy",
            headerName: "Created By",
            sortable: true,
            width: 130,
            flex: 1,
        },
        {
            field: "name",
            headerName: "Form Name",
            sortable: true,
            flex: 1,
            width: 150,
        },
        // {
        //     field: "active",
        //     headerName: "is Active",
        //     sortable: true,
        //     flex: 1,
        //     width: 120,
        // },
        {
            field: "schdeuleCodes",
            headerName: "Active Schedules",
            sortable: true,
            flex: 1,
            width: 120,
        },
    ]

export const assetFormsColumns =[
    {
        field:'created_dt',
        headerName:'Created Data',
        sortable: true,
        minWidth: 180,
        flex: 1,
        type: "date",
        valueFormatter: (param) => {
            if (param.value && param.value > 0) {
                const value = Number(param.value);
                const date = new Date(value);
                const yyyy = date.getFullYear();
                let mm = date.getMonth() + 1; // Months start at 0!
                let dd = date.getDate();

                if (dd < 10) dd = "0" + dd;
                if (mm < 10) mm = "0" + mm;

                const formattedToday = dd + "/" + mm + "/" + yyyy;
                const time = date.toLocaleTimeString();
                return formattedToday + " " + time;
            } else {
                return "-";
            }
        },
    },
    {
        field:'gearType',
        headerName:'Asset Type',
        sortable: true,
        flex: 1,
        width: 120,
    },
    {
        field:'name',
        headerName:'Form Name',
        sortable: true,
        flex: 1,
        width: 150,
    }
]



export const BASE_URL = "http://192.168.1.213:9092";

export const pageDetailsByUrl = {
    "/forms/maintenance": {
        id: 'maintenance-forms',
        name: 'Maintenance Forms',
        tableColumns: maintenanceFormColumn,
        getTableRows:getRowFromMaintenanceData,
        getUrl: MAINTENACE_FORM_API,
        postUrl: MAINTENACE_FORM_API,
        putUrl: MAINTENACE_FORM_API,
        isAssetForm: false
    },
    "/forms/asset": {
        id: 'asset-forms',
        name: "Asset Forms",
        tableColumns: assetFormsColumns,
        getTableRows:getRowFromAssetsFormData,
        getUrl: GET_ASSET_FORM+'?with_device_type=true',
        postUrl: GET_ASSET_FORM,
        putUrl: GET_ASSET_FORM,
        isAssetForm: true
    }
}

export const ICONS = {
    formViewIcon:FORM_VIEW_ICON,
    activeIcon:ACTIVE_ICON,
    inActiveIcon: INACTIVE_ICON
}



