import { useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem, Toolbar, Typography, Tooltip } from "@mui/material";
import { useImmer } from "use-immer";
import { AppBar } from "./Style";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../Images/bitcommLogo.png";
import ProfileModal from "../../Modal/ProfileModal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import LiveStatusClock from "./LiveStatusClock/LiveStatusClock";
import { logoutSuccess, permittedCustomersSelector } from "../../features/authentication/auth-slice";
import Box from "@mui/material/Box";
import { StationsSelect } from "./components/StationsSelect";
import AlarmNotificationSound from "./components/AlarmNotificationSound";
import AlarmsNotifactions from "./components/AlarmsNotifications";
import useFirebaseCloudMessaging from "../../hooks/fcm/useFirebaseCloudMessaging";

const initialState = {
  auth: true,
  openUserMenu: false,
  label: "",
  menuOpen: false,
  showProfileModal: false,
  stations: [],
  updatedUserData: null,
};

const Header = () => {
  const permittedCustomers = useSelector(permittedCustomersSelector) ?? [];
  const [state, setState] = useImmer({ ...initialState, stations: permittedCustomers });
  const [anchorProfileMenu, setAnchorProfileMenu] = useState(null);
  const dispatch = useDispatch();

  const { unregisterUser } = useFirebaseCloudMessaging();

  const navigate = useNavigate();
  const location = useLocation();
  let USER_NAME = localStorage.getItem("user") ?? "";
  USER_NAME = USER_NAME.slice(0, 2).toUpperCase();

  useEffect(() => {
    const head = (endHeader, name) => {
      if (endHeader !== undefined && name !== undefined) {
        return " " + name;
      } else {
        return " ";
      }
    };
    const startingHeader = location?.pathname?.substring(1)?.replace("-", " ")?.split("/")[0]?.toUpperCase();
    const endHeader = location?.pathname?.split("_")[1];
    const name = location?.pathname?.split("_")[2];
    const _newHeaderLabel = startingHeader + head(endHeader, name);

    const newHeaderLabel = decodeURIComponent(_newHeaderLabel);
    setState((draft) => {
      draft.headerLabel = newHeaderLabel;
    });
  }, [location]);

  /**
   * open the dropdown menu next to stations dropdown to allow for user profile options
   */
  const handleMenu = (e) => {
    setAnchorProfileMenu(e.currentTarget);
    setState((draft) => {
      draft.openUserMenu = !state.openUserMenu;
    });
  };

  /**
   * close the user profile options dropdown
   */
  const handleClose = () => {
    setState((draft) => {
      draft.openUserMenu = !state.openUserMenu;
    });
  };

  async function handleLogout() {
    const unregister = await unregisterUser();
    console.log("unregister", unregister);
    dispatch(logoutSuccess());
  }

  /**
   * for opening the profile popup with user details
   */
  const handleOpenProfile = () => {
    setState((draft) => {
      draft.showProfileModal = true;
      draft.openUserMenu = false;
    });
  };

  /**
   * to close the profile popup inside user details dropdown
   */
  const handleCloseProfile = () => {
    setState((draft) => {
      draft.showProfileModal = false;
    });
  };

  const backBtn = () => {
    window.history.back();
  };

  return (
    <AppBar className="site-header" position="fixed" open>
      <Toolbar className="header-toolbar">
        <img
          className="pointer"
          onClick={() => {
            navigate("/");
          }}
          src={logo}
          alt="Bitcomm Logo"
          height="50px"
        />

        {!["", "home", "dashboard"].includes(location.pathname.split("/")[1]) && (
          <>
            <div className="header-back-button">
              <IconButton color="inherit" onClick={backBtn}>
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
            </div>
            <Typography variant="h6" noWrap component="div">
              {state.headerLabel}
            </Typography>
          </>
        )}

        {state.auth && (
          <Box className="flex alignCenter absolute right">
            <LiveStatusClock />
            <AlarmNotificationSound />
            <AlarmsNotifactions />
            <StationsSelect />

            <Tooltip placement="bottom" title={"Profile"} disableInteractive>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => handleMenu(e)}
                color="inherit"
              >
                <div className="avatar cursor-pointer">{USER_NAME}</div>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorProfileMenu}
              open={Boolean(state.openUserMenu)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleOpenProfile}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            {state.showProfileModal && <ProfileModal open={state.showProfileModal} onClose={handleCloseProfile} />}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default Header;
