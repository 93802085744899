class Horizontal {
  constructor(startPoint) {
    this.startPoint = startPoint;
    this.dirJ = 0;
    this.dirI = 0;

    if (this.startPoint.includes("Left")) {
      this.dirJ = 1;
    } else {
      this.dirJ = -1;
    }

    if (this.startPoint.includes("top")) {
      this.dirI = 1;
    } else {
      this.dirI = -1;
    }
  }

  populateGrid({ grid, TOTAL_ROWS, TOTAL_COLUMNS, IS_SPIRAL, data_config }) {
    const { key_prefix = "", value_prefix = "", cell_start_index = 1 } = data_config;
    let cnt = 1;
    let i, j;

    if (this.startPoint.includes("top")) {
      i = 0;
    } else {
      i = TOTAL_ROWS - 1;
    }

    if (this.startPoint.includes("Left")) {
      j = 0;
    } else {
      j = TOTAL_COLUMNS - 1;
    }

    while (cnt <= TOTAL_ROWS * TOTAL_COLUMNS) {
      const cell_count = cell_start_index + cnt - 1;

      grid[i][j] = { cell_text: cell_count, id: key_prefix + cell_count };
      const newJ = this.dirJ + j;

      if (newJ >= 0 && newJ < TOTAL_COLUMNS) {
        j = newJ;
      } else {
        if (IS_SPIRAL) {
          this.dirJ = this.dirJ === 1 ? -1 : 1;
        }

        if (this.dirJ === 1) {
          j = 0;
        } else {
          j = TOTAL_COLUMNS - 1;
        }
      }

      if (cnt % TOTAL_COLUMNS === 0) {
        i += this.dirI;
      }
      cnt += 1;
    }
  }
}

class Vertical {
  constructor(startPoint) {
    this.startPoint = startPoint;
    this.dirJ = 0;
    this.dirI = 0;

    if (this.startPoint.includes("Left")) {
      this.dirJ = 1;
    } else {
      this.dirJ = -1;
    }

    if (this.startPoint.includes("top")) {
      this.dirI = 1;
    } else {
      this.dirI = -1;
    }
  }

  populateGrid({ grid, TOTAL_ROWS, TOTAL_COLUMNS, IS_SPIRAL, data_config }) {
    const { key_prefix = "", value_prefix = "", cell_start_index = 1 } = data_config;
    let cnt = 1;
    let i, j;

    if (this.startPoint.includes("top")) {
      i = 0;
    } else {
      i = TOTAL_ROWS - 1;
    }

    if (this.startPoint.includes("Left")) {
      j = 0;
    } else {
      j = TOTAL_COLUMNS - 1;
    }

    while (cnt <= TOTAL_ROWS * TOTAL_COLUMNS) {
      const cell_count = cell_start_index + cnt - 1;
      grid[i][j] = { cell_text: cell_count, id: key_prefix + cell_count };

      const newI = this.dirI + i;

      if (newI >= 0 && newI < TOTAL_ROWS) {
        i = newI;
      } else {
        if (IS_SPIRAL) {
          this.dirI = this.dirI === 1 ? -1 : 1;
        }

        if (this.dirI === 1) {
          i = 0;
        } else {
          i = TOTAL_ROWS - 1;
        }
      }

      if (cnt % TOTAL_ROWS === 0) {
        j += this.dirJ;
      }
      cnt += 1;
    }
  }
}

class Random {
  constructor(startPoint) {
    this.startPoint = startPoint;
    this.dirJ = 1;
    this.dirI = 1;
  }

  populateGrid({ grid, TOTAL_ROWS, TOTAL_COLUMNS, CELL_VALUES, data_config }) {
    let cnt = 1;
    let i = 0,
      j = 0;

    while (cnt <= TOTAL_ROWS * TOTAL_COLUMNS) {
      grid[i][j] = {
        cell_text: CELL_VALUES[`${i + 1}_${j + 1}`]?.text ?? cnt,
        id: CELL_VALUES[`${i + 1}_${j + 1}`]?.id ?? `${i}_${j}`,
      };

      const newI = this.dirI + i;

      if (newI >= 0 && newI < TOTAL_ROWS) {
        i = newI;
      } else {
        if (this.dirI === 1) {
          i = 0;
        } else {
          i = TOTAL_ROWS - 1;
        }
      }

      if (cnt % TOTAL_ROWS === 0) {
        j += this.dirJ;
      }
      cnt += 1;
    }
  }
}

function getGroupedGrids(matrix, numRows, numColumns) {
  const outputMatrix = [];

  for (let i = 0; i < matrix.length; i += numRows) {
    for (let j = 0; j < matrix[i].length; j += numColumns) {
      const group = [];
      for (let x = 0; x < numRows && i + x < matrix.length; x++) {
        for (let y = 0; y < numColumns && j + y < matrix[i].length; y++) {
          group.push(matrix[i + x][j + y]);
        }
      }
      outputMatrix.push(group);
    }
  }

  return outputMatrix;
}

export function getBHMSGrid(type = null, startPoint = "topLeft", json_config = {}) {
  const {
    IS_SPIRAL = false,
    GRID_ROWS = 0,
    GRID_COLUMNS = 0,
    NUMBER_OF_HORIZONTAL_GRIDS = 0,
    NUMBER_OF_VERTICAL_GRIDS = 0,
    CELL_VALUES,
    data_config,
  } = json_config;

  let class_instance;
  const TOTAL_ROWS = GRID_ROWS * NUMBER_OF_VERTICAL_GRIDS;
  const TOTAL_COLUMNS = NUMBER_OF_HORIZONTAL_GRIDS * GRID_COLUMNS;

  const grid = Array.from({ length: TOTAL_ROWS }, () => Array(TOTAL_COLUMNS).fill(null));
  switch (type) {
    case "horizontal": {
      class_instance = new Horizontal(startPoint);
      break;
    }
    case "vertical": {
      class_instance = new Vertical(startPoint);
      break;
    }
    case "random": {
      class_instance = new Random();
      break;
    }
    default:
      return [];
  }

  class_instance.populateGrid({ grid, TOTAL_ROWS, TOTAL_COLUMNS, IS_SPIRAL, CELL_VALUES, data_config });
  //   console.log(grid);
  const main_grid = getGroupedGrids(grid, GRID_ROWS, GRID_COLUMNS);
  return main_grid;
}
