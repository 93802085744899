import { Autocomplete, Checkbox, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";

import { triggerCopyRulesPopup, useCopyRuleMutation, useGetRulesQuery } from "../../alarmRulesSlice";
import { Transition } from "../../../../Modal/ModalConstants";
import { useGetCustomersAccessList } from "../../../SearchFilters/useGetCustomersAccessList";

function getRuleByCustomerParams(state) {
  let params = null;
  if (!state.from_customer_id) return params;
  return { customer_ids: state.from_customer_id.customerId };
}

export const CopyAlarmRulesPopup = () => {
  const dispatch = useDispatch();
  const [copyRules] = useCopyRuleMutation();
  const [customers, setCustomers] = useState({
    from_customer_id: null,
    to_customer_id: null,
    enable: false,
    copy_all: true,
    error: false,
  });
  const { data: rulesList = [], isFetching } = useGetRulesQuery(getRuleByCustomerParams(customers));

  const [selectedRules, setSelectedRules] = useState({});

  const { data: customer } = useGetCustomersAccessList();
  const closeCopyRulesPopup = () => dispatch(triggerCopyRulesPopup(false));
  function handleFilterChange(type, value) {
    setCustomers((state) => ({ ...state, [type]: value }));
  }
  function handleSelectedRulesChange(rule_id, value) {
    setSelectedRules((state) => ({ ...state, [rule_id]: value }));
  }
  function showError(field) {
    if (!customers.error) return false;
    if (!customers[field]) return true;
    return false;
  }

  useEffect(() => {
    const _selectedRules = {};
    for (const _rule of rulesList) {
      _selectedRules[_rule.id] = true;
    }
    setSelectedRules(_selectedRules);
  }, [rulesList]);

  function handleSubmit() {
    const { from_customer_id, to_customer_id, enable, copy_all } = customers;
    if (!from_customer_id || !to_customer_id) {
      setCustomers((state) => ({ ...state, error: true }));
      return;
    }

    const _params = {
      from_customer_id: from_customer_id?.customerId,
      to_customer_id: to_customer_id?.customerId,
      enable,
    };
    if (!copy_all) {
      const rule_ids = [];
      for (const _ruleId in selectedRules) {
        if (selectedRules[_ruleId]) {
          rule_ids.push(_ruleId);
        }
      }
      if (rule_ids.length === 0) {
        toast.error("Select at least One Rule to Copy");
        return;
      }
      _params.rule_ids = rule_ids;
    }

    if (from_customer_id?.customerId === to_customer_id?.customerId) {
      toast.error("Selected Customers are Same");
      return;
    }

    copyRules(_params).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data ?? "Something went wrong! Try again later");
        return;
      }
      let message = `Copied ${res.data.length} Rules Successfully`;
      if (res.data.length === 0) {
        message = "No Rules to Copy";
        toast.error(message);
      } else {
        toast.success(message);
      }
      closeCopyRulesPopup();
    });
  }

  function handleSelectAllSelectedRules() {
    const currently_all_selected = Object.values(selectedRules).every((rule) => rule);
    const _selectedRules = { ...selectedRules };
    for (const rule_id in _selectedRules) {
      if (currently_all_selected) {
        _selectedRules[rule_id] = false;
      } else {
        _selectedRules[rule_id] = true;
      }
    }
    setSelectedRules(_selectedRules);
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={true}
        onClose={closeCopyRulesPopup}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="responsive-dialog-title" className="flex items-center">
          Copy Alarm Rules
          <CloseIcon className="pointer" sx={{ ml: "auto" }} onClick={closeCopyRulesPopup} />
        </DialogTitle>

        <DialogContent dividers>
          <div className="flex items-center gap-3">
            <p style={{ minWidth: "9rem" }}>From Station*</p>
            <Autocomplete
              fullWidth
              isOptionEqualToValue={(option, value) => option.customerId === value.customerId}
              value={customers.from_customer_id}
              options={customer}
              getOptionLabel={(customer) => customer.customerName}
              onChange={(_, value) => handleFilterChange("from_customer_id", value)}
              renderInput={(params) => <TextField {...params} error={showError("from_customer_id")} />}
              size="small"
            />
          </div>
          <div className="flex items-center gap-3">
            <p style={{ minWidth: "9rem" }}>To Station*</p>
            <Autocomplete
              fullWidth
              isOptionEqualToValue={(option, value) => option.customerId === value.customerId}
              value={customers.to_customer_id}
              options={customer}
              getOptionLabel={(customer) => customer.customerName}
              onChange={(_, value) => handleFilterChange("to_customer_id", value)}
              renderInput={(params) => <TextField {...params} error={showError("to_customer_id")} />}
              size="small"
            />
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={customers.enable}
                onChange={(event) => handleFilterChange("enable", event.target.checked)}
              />
            }
            label="Enable Rules"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={customers.copy_all}
                onChange={(event) => handleFilterChange("copy_all", event.target.checked)}
              />
            }
            label="Copy All Rules"
          />
          {!customers.copy_all && customers.from_customer_id && !isFetching && (
            <div className="alarm-rules__copy__select-rules--container">
              <div className="flex justify-between items-center">
                <div>Select Rules to Copy</div>
                <div>
                  <Button onClick={handleSelectAllSelectedRules}>
                    {Object.values(selectedRules).every((rule) => rule) ? "Deselect All" : "Select All"}
                  </Button>
                </div>
              </div>
              <div className="alarm-rules__copy__select-rules--list">
                {rulesList.map((rule) => (
                  <FormControlLabel
                    key={rule.id}
                    control={
                      <Checkbox
                        checked={selectedRules[rule.id] ?? false}
                        onChange={(event) => handleSelectedRulesChange(rule.id, event.target.checked)}
                      />
                    }
                    label={rule.alarm_name}
                  />
                ))}
              </div>
            </div>
          )}
          <FormHelperText>
            Only new rules that are not present in the "To Station" compared to "From Station" will be updated. If same
            rule is present in both station then the rule definition won't be overwritten!
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={closeCopyRulesPopup}>
            Cancel
          </Button>
          <Button variant="contained" autoFocus onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
