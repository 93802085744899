import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Skeleton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useGetAlarmByIdQuery } from "../../features/Alarms/alarmsSlice";
import {
  calculateTimeDifference,
  getNavigateToTelemetryHistoryURL,
  getRowData,
} from "../../features/Alarms/alarmUtils";
import { AlarmInfoSection, DisplayImage, ViewOtherAttachments } from "../../features/Alarms/AlarmInfoPopup";
import FMEView from "../../features/Alarms/components/fme-view";

export default function AlarmNotificationInfo() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const alarm_id = searchParams.get("show_info");
  const [openModal, setOpenModal] = useState<boolean>(alarm_id ? true : false);
  const { data: alarmInfo = {}, isFetching } = useGetAlarmByIdQuery(alarm_id, { skip: alarm_id ? false : true });

  function handleModalClose() {
    searchParams.delete("show_info");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    let _openModal = false;
    if (alarm_id) _openModal = true;
    setOpenModal(_openModal);
  }, [alarm_id]);

  const {
    ack_dt = null,
    created_dt = null,
    updated_dt = null,
    clear_dt = null,
    acked_info = null,
    clear_info = null,
    create_info = null,
    clear_value = null,
    create_value = null,
    update_value = null,
    id = null,
    device = null,
    fmeas = [],
  } = alarmInfo;

  const createInfo = { created_dt, create_info, create_value };
  const ackInfo = { ack_dt, acked_info };
  const clearInfo = { clear_dt, clear_info, clear_value };
  const updateInfo = { updated_dt, update_value };

  const createInfoData = getRowData(createInfo);
  const updateInfoData = getRowData(updateInfo);
  const clearInfoData = getRowData(clearInfo);
  const ackInfoData = getRowData(ackInfo);

  const alarmDuration = calculateTimeDifference(created_dt, clear_dt);

  const handleRelatedData = useCallback(() => {
    const deviceTypeId = device?.device_type?.typeid;
    const url = getNavigateToTelemetryHistoryURL(device);
    let start_date: string | Date = new Date(created_dt);
    start_date.setMinutes(start_date.getMinutes() - 5);
    start_date = start_date.toISOString();
    const searchParams = {
      start_date,
      end_date: clear_dt ?? new Date().toISOString(),
      device_type_id: deviceTypeId,
    };

    navigate({
      pathname: url,
      search: createSearchParams(searchParams).toString(),
    });
  }, [device]);

  const ModalContent = () => {
    if (isFetching)
      return (
        <>
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "20%" }} />
          <Skeleton variant="text" sx={{ fontSize: "4rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "20%" }} />
          <Skeleton variant="text" sx={{ fontSize: "4rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "20%" }} />
          <Skeleton variant="text" sx={{ fontSize: "4rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "20%" }} />
          <Skeleton variant="text" sx={{ fontSize: "4rem", width: "100%" }} />
          <div className="flex items-center justify-between mt-4">
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "40%" }} />
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "20%" }} />
          </div>
        </>
      );
    return (
      <section className="flex justify-between gap-4 p-1" style={{ height: "60vh" }}>
        <div className="flex-1">
          <AlarmInfoSection data={createInfoData} title="Create Info" />
          {updated_dt && <AlarmInfoSection data={updateInfoData} title="Updated Info" />}
          {clear_dt && <AlarmInfoSection data={clearInfoData} title="Clear Info" />}
          {ack_dt && <AlarmInfoSection data={ackInfoData} title="Ack Info" />}
          {fmeas?.length > 0 && (
            <div className="alarm__fmea-view--wrapper">
              <DialogContentText>FME (Failure Mode Effect)</DialogContentText>
              <FMEView data={fmeas} />
            </div>
          )}
          <ViewOtherAttachments id={id} />
          <div className="flex justify-between items-center">
            <p>Alarm Duration: {alarmDuration}</p>
            <Button onClick={handleRelatedData} variant="contained">
              Related Data
            </Button>
          </div>
        </div>
        <DisplayImage id={id} />
      </section>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="responsive-dialog-title"
        className="AlarmInfoPopup"
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title" className="flex">
          Alarm Info
          <CloseIcon className="pointer" sx={{ ml: "auto" }} onClick={handleModalClose} />
        </DialogTitle>
        <DialogContent dividers>
          <ModalContent />
        </DialogContent>
      </Dialog>
    </div>
  );
}
