import React, { useContext, useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
import { TcUtilastionContext } from '../TcUtilization'
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import NoData from '../../../../Utils/NoDataComponent.js/NoData';
import { Chart } from 'react-chartjs-2';
import { calculateDuration, convertHoursToDuration, convertSecondsToDuration, convertSecondsToHours } from '../../Constants';
import { trimAssetName } from '../../../../Common/EndPoints';


const InitialBarOption = {
    responsive: true,
    maintainAspectRatio: true,
    // responsive: true,
    aspectRatio: 10 / 3,
    // indexAxis: 'y',
    // aspectRatio: 5.3 / 1,
    interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
        callbacks: {
            intersect: function (a) {
            },
        },
    },
    // scales: {
    //     y: {
    //         ticks: {
    //             // Include a dollar sign in the ticks
    //             callback: function (value, index, ticks) {
    //                 return '$' + 'shdjkhfjk';
    //             }
    //         }
    //     }
    // },
    plugins: {
        // title: {
        //   display: true,
        //   text: 'Chart.js Bar Chart - Stacked'
        // },
        legend: {
            display: false,
            // display: true,
            position: 'top',
            maxHeight: 25,
            maxWidth: 2,
        },
        datalabels: {
            anchor: 'end',
            color: "black",
            align: 'end',
            // offset:30,
            clip: true,
            // align :'end',
            formatter: function (count, chart) {
                // const sum = chart.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
                // let countPer = (count*100)/sum
                // console.log(countPer)
                // return count > 0 ? count : '0'
                return ''
            }
        },
        tooltip: {
            yAlign: "center",
            xAlign: "left",
            responsive: true,

            callbacks: {
                label: function (tooltipItem, data) {
                    let label = tooltipItem.dataset.label
                    let timeStamp = tooltipItem.formattedValue.replace(/,/g, '')
                    timeStamp = Number(timeStamp)
                    let newLabel = convertHoursToDuration(timeStamp)

                    return newLabel
                },
                title: function (tooltipItem, data) {
                    return tooltipItem[0].label
                }
            },
        },

    },

    scales: {
        x: {
            stacked: true,
            grid: {
                display: false
            }
        },
        y: {
            stacked: true
        }
    }
}

const initialChartData = {
    labels: [],
    datasets: [{
        label: 'Normal Count',
        data: [],
        // stack: 0,
        backgroundColor: '#4588f6',
    }]
}
function TcRuntimeChart() {
    const { tcUtilizationData } = useContext(TcUtilastionContext)
    const [chartData, setChartData] = useImmer(initialChartData)
    const [fullScreen, setFullScreen] = useState(false)
    useEffect(() => {
        if (tcUtilizationData) {
            let newlabels = []
            let occupiedDuration = []


            tcUtilizationData.forEach((row) => {
                let trimmedAssetName = trimAssetName(row?.device_name)
                newlabels.push(trimmedAssetName)
                occupiedDuration.push(convertSecondsToHours(row.total_occupied_duration))
                let newChartData = {
                    labels: newlabels,
                    datasets: [{
                        label: 'Total Time',
                        data: occupiedDuration,
                        backgroundColor: '#4588f6',
                    }]

                }
                setChartData(newChartData)
            })
        }


    }, [tcUtilizationData])
    const handleFullScreen = () => {
        setFullScreen(!fullScreen)
    }
    return (
        <div className={`tcRuntimeChart card ${fullScreen ? 'fullScreenCountChart' : ''}`}>
            {(tcUtilizationData || tcUtilizationData?.length > 0) ? (<>
                <div className='card-header'>
                    <div className='card-heading'>
                        Total Occupied Duration of Track Circuits
                    </div>
                    <div className='fullScreenIcon pointer' onClick={() => handleFullScreen()}><FullscreenIcon /></div>
                </div>

                <div className='chart-container'>
                    <Chart
                        className="pie-count-chart"
                        type="bar"
                        data={chartData}
                        options={InitialBarOption}
                    />
                </div></>) : (<NoData msg={`Data for  is not avalible`} />)}

        </div>
    )
}

export default TcRuntimeChart
