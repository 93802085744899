import axios from "axios";
import { useEffect, useState, createContext, useMemo } from "react";
import { APP_BASE_URL, REFRESH_TOKEN_PATH } from "../../Common/EndPoints";
import { useAlarmsWebsocket } from "../../features/Alarms/useAlarmsWebsocket";
export const ActiveAlarmsData = createContext({});

window.jwtToken = localStorage.getItem("token");
let _authorizing = null;
const HEADER_NAME = "Authorization";

const refresh = async () => {
  return axios.post(`${APP_BASE_URL}${REFRESH_TOKEN_PATH}?refreshTokenId=${window.refreshToken}`).then(({ data }) => {
    const { token } = data?.data;
    window.jwtToken = token;
    localStorage.setItem("token", token);
    return Promise.resolve(token);
  });
};

axios.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${window.jwtToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
axios.interceptors.response.use(undefined, async (error) => {
  if (error.response?.status !== 401) {
    return Promise.reject(error);
  }

  // create pending authorization
  _authorizing ??= refresh()
    .finally(() => (_authorizing = null))
    .catch((error) => Promise.reject(error));

  const originalRequestConfig = error.config;
  delete originalRequestConfig.headers[HEADER_NAME]; // use from defaults

  // delay original requests until authorization has been completed
  return _authorizing.then(() => axios.request(originalRequestConfig));
});

let initialCustomerIdList = [];
if (localStorage.getItem("customerIdList")) {
  initialCustomerIdList = localStorage.getItem("customerIdList");
  initialCustomerIdList = JSON.parse(initialCustomerIdList);
}

export const ActiveAlarms = ({ isUserLoggedIn, children }) => {
  const [newUserData, setNewUserDAta] = useState({ customerDetails: [] });
  const [customerId, setCustomerId] = useState(localStorage.getItem("customerId"));
  const [customerIdList, setCustomerIdList] = useState(initialCustomerIdList);
  const [serverIsLive, setServerIsLive] = useState(false);

  const selectedCustomersIdName = useMemo(
    () => newUserData.customerDetails.filter((customer) => customerIdList.includes(customer.customerId)),
    [newUserData.customerDetails, customerIdList],
  );

  useAlarmsWebsocket(isUserLoggedIn, customerIdList);

  /**
   * Here we get the userData from localStorage that is set upon user login in the doLogin function in DataService.js
   */
  const fetchNewUserData = () => {
    const permissions = localStorage.getItem("permissions");
    if (!permissions) return;
    const res = JSON.parse(permissions);
    let stations = [];
    let div = [];

    res?.forEach((zone) => {
      if (Object.keys(zone).length > 0) {
        const zones = zone;
        let divisions = Object.values(zones);

        divisions.forEach((data) => {
          data.forEach((dt) => {
            Object.entries(dt).forEach(([key, value]) => {
              div.push(key);
              value.forEach((v) => {
                stations.push(v);
              });
            });
          });
        });
      }
    });
    setNewUserDAta({ customerDetails: stations });

    let _customerIdsList = localStorage.getItem("customerIdList");
    // single customer for Yard View
    let _customerIdSelected = localStorage.getItem("customerId");

    if (!_customerIdsList) {
      _customerIdsList = stations.map((st) => {
        return st.customerId;
      });
      _customerIdsList ??= [];
      localStorage.setItem("customerIdList", JSON.stringify(_customerIdsList));
    } else {
      _customerIdsList = JSON.parse(_customerIdsList);
    }

    setCustomerIdList(_customerIdsList);

    if (!localStorage.getItem("STN")) {
      const STN = stations.map((row) => {
        return row.customerName;
      });
      localStorage.setItem("STN", STN);
    }

    if (!_customerIdSelected) {
      _customerIdSelected = stations?.[0]?.customerId;
      localStorage.setItem("customerId", _customerIdSelected);
    }
    setCustomerId(_customerIdSelected);
  };

  useEffect(() => {
    fetchNewUserData();
  }, []);

  const fetchCustomerId = () => {
    const newCustomerId = localStorage.getItem("customerId");
    setCustomerId(newCustomerId);
  };
  const fetchCustomerIdList = () => {
    let newCustomerIdList = localStorage.getItem("customerIdList");
    if (newCustomerIdList) newCustomerIdList = JSON.parse(newCustomerIdList);
    newCustomerIdList ??= [];
    setCustomerIdList(newCustomerIdList);
  };

  return (
    <ActiveAlarmsData.Provider
      value={{
        newUserData: newUserData,
        refetchUserData: fetchNewUserData,
        customerId: customerId,
        updateCustomerId: fetchCustomerId,
        customerIdList: customerIdList,
        updateCustomerIdList: fetchCustomerIdList,
        selectedCustomersIdName,
        serverIsLive,
        setServerIsLive,
      }}
    >
      {children}
    </ActiveAlarmsData.Provider>
  );
};
export default ActiveAlarms;
