import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from 'react'
import { useImmer } from "use-immer";
import ReactivePopMarkup from "../ReactivePopMarkup";
import { FormContext } from "../../../FormCreator";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactiveSecMarkup from "./ReactiveSecMarkup.js/ReactiveSecMarkup";

function DisplayField({ displayRenderFields, fieldNodeInfo, fetchData, editInfo }) {
  const { formData } = useContext(FormContext)
  const [displayFieldState, setDisplayFields] = useImmer({})
  const [parentFieldData, setParentFieldData] = useImmer(null);
  useEffect(() => {
    let newDisplayFieldState = { ...displayFieldState }
    delete newDisplayFieldState?.optionValue;
    delete newDisplayFieldState["parent-field"];
    if (newDisplayFieldState.reactiveData) {
      newDisplayFieldState = { ...newDisplayFieldState, ...newDisplayFieldState.reactiveData }
      delete newDisplayFieldState?.reactiveData;
    }
    fetchData(newDisplayFieldState)
  }, [displayFieldState])
  // useEffect(()=>{
  //   displayRenderFields.forEach((field)=>{
  //     // if(field.type === 'checkbox' && field.key !== editInfo.key){
  //       // if(editInfo.key!==field.key){
  //         setDisplayFields((prev)=>{
  //           prev[field.id] = false
  //         })
  //       // }
  //     // }
  //   })
  // },[])
  useEffect(() => {
    if (editInfo) {
      setDisplayFields(editInfo)
      if (fieldNodeInfo.type.includes('reactive')) {
        getParentFieldInfo(editInfo.reactiveKey, formData);
        setDisplayFields((prev) => {
          prev['parent-field'] = editInfo.reactiveKey
        })
      }
    }
  }, [])
  const getParentFieldInfo = (key, allFields) => {
    let parentField;
    if (allFields) {
      allFields.forEach((field, i) => {
        if (field.type === "section" || field.type === "reactive_section" || field.type === "reactive-section") {
          parentField = getParentFieldInfo(key, field.sectionFields);
        } else {
          if (field.key === key) {
            setParentFieldData(field);
          } else {
          }
        }
      });
    }

  };
  const getParentPopUpData = (data) => {
    setDisplayFields((prev) => {
      prev.reactiveData = data
    })
  };
  const removeOption = (i) => {
    let updatedOptions = [...displayFieldState.options];
    if (i || i === 0) {
      updatedOptions.splice(i, 1);
    }
    setDisplayFields((prev) => {
      prev.options = updatedOptions
    })
  };
  const getDropdownDefaultValue = (editInfo) => {
    let defaultValue = ''
    if (editInfo) {
      if (editInfo.reactiveKey) {
        defaultValue = editInfo.reactiveKey
      }
      if (editInfo.Unit) {
        defaultValue = editInfo.Unit
      }
      return defaultValue
    }
  }
  return (
    <>
      {displayRenderFields.map((field, i) => {
        if (field.type === "checkbox") {
          return (
            <FormControlLabel
              key={i}
              control={<Checkbox size="small" checked={displayFieldState[field.id] ?? false} onChange={(e) => {
                setDisplayFields((prev) => {
                  prev[field.id] = e.target.checked
                })

              }} />}
              label={field.label}
            />
          );
        } else if (field.type === "dropdown") {
          return (
            <FormControl size="small" fullWidth>
              <InputLabel id="standard-label">{field.label}</InputLabel>
              <Select
                key={fieldNodeInfo.id}
                defaultValue={getDropdownDefaultValue(editInfo)}
                // defaultValue={editInfo?editInfo.reactiveKey:''}

                id="select-standard"
                className="field"
                label={field.label}
                value={displayFieldState[field.id]}
                onChange={(e) => {
                  setDisplayFields((prev) => {
                    prev[field.id] = e.target.value === 'allFiles' ? [] : e.target.value
                  })
                  if (fieldNodeInfo.type === "reactive-dropdown" || fieldNodeInfo.type === "reactive_date" || fieldNodeInfo.type === "reactive_number" || fieldNodeInfo.type === "reactive_dropdown" || fieldNodeInfo.type === "reactive_section" || fieldNodeInfo.type === "reactive_textfield") {
                    getParentFieldInfo(e.target.value, formData);
                    // console.log(e.target.value, formData)
                  }

                }}
                multiple={field.multiple}
              >
                {field?.options?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>

            </FormControl>

          );
        } else if (field.type === 'dropdownMarkup') {
          return (
            <div className="dropdownMarkup">
              <div className="headingPart">

                <h2 className="dd-heading">Add Options</h2>
              </div>
              <div className="optionActPart">
                <div className="option-input">
                  <TextField
                    label="Options"
                    size="small"
                    placeholder="Add Options"
                    value={displayFieldState.optionValue}
                    onChange={(e) => {
                      setDisplayFields((prev) => {
                        prev.optionValue = e.target.value
                      })
                    }}
                  />
                  <div
                    onClick={() => {
                      if (displayFieldState.optionValue.length > 0) {
                        let newOptions = displayFieldState.options ? [...displayFieldState.options] : []
                        newOptions.push(displayFieldState.optionValue)

                        setDisplayFields((prev) => {
                          prev.options = newOptions
                          prev.optionValue = ''
                        })
                      }
                    }}
                    className="addBtn pointer"
                  >
                    add
                  </div>
                </div>

                <div className="option-preview">
                  {displayFieldState?.options &&
                    displayFieldState?.options.map((opt, i) => {
                      return (
                        <Chip
                          label={opt}
                          className="option-chip"
                          onDelete={() => {
                            removeOption(i);
                          }}
                          deleteIcon={<DeleteIcon className="chip-icon" />}
                          variant="outlined"
                        />
                      );
                    })}
                </div>
              </div>

            </div>
          )

        } else if (field.type === 'reactiveDropdownMarkup') {
          return (<div>
            {!parentFieldData && (<div>Please select Parent field</div>)}

            {parentFieldData && (<ReactivePopMarkup editInfo={editInfo} parentData={parentFieldData} getParentPopUpData={getParentPopUpData} />)}
          </div>)
        } else if (field.type === 'reactiveSectionMarkup') {
          return (
            <div>
              {!parentFieldData && (<div>Please select Parent field</div>)}

              {parentFieldData && (<ReactiveSecMarkup editInfo={editInfo} parentData={parentFieldData} getParentPopUpData={getParentPopUpData} />)}
            </div>
          )

        }
        else {
          return (
            <>
              <TextField
                className="field"
                size="small"
                type={field.type}
                key={i}
                value={displayFieldState[field.id] ?? ''}
                label={field.label}
                placeholder={field.placeholder}
                onChange={(e) => {
                  let value = e.target.value
                  if (field.id === 'image_quality') {
                    if (/^[1-9][0-9]?$|^100$/.test(value) || value === '') {
                      setDisplayFields((prev) => {
                        prev[field.id] = value
                      })
                    }

                  } else {
                    setDisplayFields((prev) => {
                      prev[field.id] = value
                    })
                  }
                }}
              />
            </>
          );
        }
      })}
    </>
  )
}

export default DisplayField
