import { Button } from "@mui/material";
import { useImmer } from "use-immer";
import "./FilterComponent.scss";

const initialParamData = {
  startTs: "",
  endTs: "",
  key: "",
  operator: "",
  value: "",
};

const initialFormError = {
  startTsError: false,
  endTsError: false,
  keyError: false,
  operatorError: false,
  valueError: false,
};
function FilterComponent({ getFilterParams, resetFilters }) {
  const [paramData, setParamData] = useImmer(initialParamData);
  const [formError, setFormError] = useImmer(initialFormError);

  function showError(data) {
    setFormError((prev) => {
      prev.startTsError = !data.startTs;
      prev.endTsError = !data.endTs;
    });
    if ((data.key || data.operator || data.value) && (!data.key || !data.operator || !data.value)) {
      setFormError((prev) => {
        prev.keyError = !data.key;
        prev.operatorError = !data.operator;
        prev.valueError = !data.value;
      });
    } else if (data.key && data.operator && data.value) {
      setFormError((prev) => {
        prev.keyError = !data.key;
        prev.operatorError = !data.operator;
        prev.valueError = !data.value;
      });
    } else if (!data.key && !data.operator && !data.value) {
      setFormError((prev) => {
        prev.keyError = data.key;
        prev.operatorError = data.operator;
        prev.valueError = data.value;
      });
    }
  }

  function handleClear() {
    setParamData(initialParamData);
    setFormError(initialFormError);
    resetFilters();
  }

  function validate(data) {
    let valid = false;
    if (data.startTs && data.endTs && !data.key && !data.operator && !data.value) {
      valid = true;
    }
    if (
      (data.key || data.operator || data.value) &&
      data.key &&
      data.operator &&
      data.value &&
      data.startTs &&
      data.endTs
    ) {
      valid = true;
    }
    showError(data);
    return valid;
  }

  function handleFilterClick() {
    if (validate(paramData)) {
      const startTs = new Date(paramData.startTs).getTime();
      const endTs = new Date(paramData.endTs).getTime();
      const _paramData = { ...paramData, startTs, endTs };
      getFilterParams(_paramData);
    }
  }

  function handleFilterChange(event) {
    setParamData((state) => {
      state[event.target.name] = event.target.value;
    });
  }

  return (
    <div className="filter-container">
      <div className="filters">
        <div className="date-filters">
          <div className="input-div">
            <span>From: </span>
            <input
              className={`filter-input ${formError.startTsError ? "error" : ""}`}
              placeholder="From"
              type="datetime-local"
              name="startTs"
              onChange={handleFilterChange}
              value={paramData.startTs}
            />
          </div>
          <div className="input-div">
            <span>To: </span>
            <input
              className={`filter-input ${formError.endTsError ? "error" : ""}`}
              type="datetime-local"
              name="endTs"
              onChange={handleFilterChange}
              value={paramData.endTs}
            />
          </div>
        </div>
        <div className="compare-filters">
          <select
            className={`filter-input compare ${formError.keyError ? "error" : ""}`}
            name="key"
            onChange={handleFilterChange}
            value={paramData.key}
          >
            <option value="">Select Key</option>
            <option value="OT">OT</option>
            <option value="OC">OC</option>
          </select>

          <select
            className={`filter-input compare ${formError.operatorError ? "error" : ""}`}
            name="operator"
            onChange={handleFilterChange}
            value={paramData.operator}
          >
            <option value="" disabled>
              Select Operator
            </option>
            <option value=">">Greater than</option>
            <option value="<">less than</option>
            <option value="=">Equal to</option>
            <option value=">=">Greater or equals to</option>
            <option value="<=">Lesser or equals to</option>
          </select>
          <input
            className={`filter-input compare ${formError.valueError ? "error" : ""}`}
            placeholder="Value"
            type="number"
            name="value"
            onChange={handleFilterChange}
            value={paramData.value}
          />
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Button onClick={handleClear} variant="outlined">
          Clear
        </Button>
        <Button onClick={handleFilterClick} variant="contained">
          Filter
        </Button>
      </div>
    </div>
  );
}

export default FilterComponent;
