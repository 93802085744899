import { useCallback, useEffect, useMemo, useState } from "react";
import { getUserAssignedAssets } from "./AssetTransferUtils";

export function useGetAssignedAssets({ type, user1 }) {
  const [assignedDevicesList, setAssignedDevicesList] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (type === "Transfer") {
      setIsFetching(true);
      getUserAssignedAssets(user1)
        .then(
          (res) => {

            const devicesObject = {};
            for (const device of res) {
              devicesObject[device.deviceId] = { checked: true, deviceName: device.deviceName };
            }
            setAssignedDevicesList(devicesObject);
          },
          () => {},
        )
        .then(() => {
          setIsFetching(false);
        });
    }
  }, [type, user1]);

  const handleSelectedAssetsChange = useCallback(({ key, value, checked }) => {
    setAssignedDevicesList((prev) => ({
      ...prev,
      [key]: { checked: checked, deviceName: value.deviceName },
    }));
  }, []);

  return useMemo(
    () => ({
      assignedDevicesList,
      handleSelectedAssetsChange,
      isFetching,
    }),
    [assignedDevicesList, isFetching, handleSelectedAssetsChange],
  );
}
