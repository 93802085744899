import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import "./AfterSubPopUp.scss";

function AfterSubmitPopup({ open, onClose, currentForm, nextForm, handleConfirMation }) {
  const formSubTitle = nextForm
    ? `Do you want to fill ${nextForm} form?`
    : "This was the last form, You can go back now";
  return (
    <Dialog open={open} aria-labelledby="responsive-dialog-title" className="mainDialog" maxWidth={"md"}>
      <DialogContent className="mainContentContainer" dividers>
        <div className="currentFormText">{currentForm} Form has been submitted Successfully</div>
        {<div className="nextFormText">{formSubTitle}</div>}
      </DialogContent>
      <DialogActions className="dialogActionBtn" style={{ padding: "1em" }}>
        <Button variant="outlined" onClick={() => handleConfirMation(false)}>
          Back
        </Button>
        {nextForm && (
          <Button variant="contained" onClick={() => handleConfirMation(true)}>
            Proceed to {nextForm}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AfterSubmitPopup;
