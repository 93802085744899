import React, { useState } from "react";
import { Chart as ChartJS } from "chart.js";
import { Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { useImmer } from "use-immer";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
import "./PointOprationChart.scss";
import { APP_BASE_URL, GET_TELEMETRY_HISTORY_BY_TIMESTAMP_WITH_KEYS } from "../../../Common/EndPoints";

import { Colors, compare, getFormattedDateTime } from "../Constant";
import FilterComponent from "./FilterComponent";
import moment from "moment";

ChartJS.register(annotationPlugin);

function PointOperationChart({ axisData, lineData, lineData2, lineData3, deviceId, currentTs, disableFilter }) {
  console.log("currentTS ", currentTs);
  const initialPmLines = [
    {
      label: moment(currentTs).format("DD-MM-YYYY HH:mm:ss") + " Red",
      data: lineData,
      fill: false,
      borderColor: "#e51c23",
      tension: 0.1,
      pointRadius: 0,
      pointHoverRadius: 4,
    },
    {
      label: moment(currentTs).format("DD-MM-YYYY HH:mm:ss") + " Yellow",
      data: lineData2,
      fill: false,
      borderColor: "orange",
      tension: 0.1,
      pointRadius: 0,
      pointHoverRadius: 4,
    },
    {
      label: moment(currentTs).format("DD-MM-YYYY HH:mm:ss") + " Blue",
      data: lineData3,
      fill: false,
      borderColor: "blue",
      tension: 0.1,
      pointRadius: 0,
      pointHoverRadius: 4,
    },
  ];
  const [crossLinePosition, setCrossLinePosition] = useImmer({
    xMin: 0,
    xMax: 0,
    yMin: 0,
    yMax: 0,
  });

  const [pmLines, setPmLines] = useImmer(initialPmLines);
  const [snackBar, setSnackBar] = useState(false);
  const [axisDataSet, setAxisDataSet] = useState(axisData);

  const labels = axisDataSet;
  const LINE_OPTIONS = {
    maintainAspectRatio: true,
    responsive: true,
    aspectRatio: 2.4,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
      callbacks: {
        intersect: function (a) {},
      },
    },
    scales: {
      xAxis: {
        type: "linear",
        // suggestedMax: maxRangeEventTime,
        ticks: {
          // forces step size to be 500 units
          stepSize: 500,
        },
      },
      yAxis: {
        // suggestedMax: maxRangeEventData,
      },
    },
    plugins: {
      datalabels: {
        color: "white",
        formatter: function (count) {
          return "";
        },
      },
      tooltip: {
        titleAlign: "center",
        yAlign: "bottom",
        xAlign: "left",
        responsive: true,
        displayColors: true,
        boxHeight: 5,
        caretSize: 0,
        rtl: true,
        bodyFontSize: 10,

        callbacks: {
          label: function (tooltipItem, data) {
            console.log(tooltipItem);
            return `${tooltipItem?.dataset?.label} : ${tooltipItem?.formattedValue} (mA)`;
          },
          title: function (tooltipItem, data) {
            return `At ${tooltipItem?.[0]?.label} (mS)`;
          },
        },
      },

      // annotation: {
      //   annotations: {
      //     line1: {
      //       type: "line",
      //       yMin: 3200,
      //       yMax: 3200,
      //       xMin: 0,
      //       xMax: 1000,
      //       borderColor: "rgb(255, 99, 132)",
      //       borderWidth: 1,
      //       borderDash: [5, 15],
      //     },
      //     line2: {
      //       type: "line",
      //       yMin: 3200,
      //       yMax: 3200,
      //       xMin: 1000,
      //       borderColor: "rgb(255, 99, 132)",
      //       borderWidth: 1,
      //       label: {
      //         display: true,
      //         content: ["Critical Operating Current"],
      //         backgroundColor: "transparent",
      //         color: "rgb(255, 99, 132)",
      //         position: "center",
      //         yAdjust: -10,
      //         font: { size: 13 },
      //       },
      //     },
      //     line3: {
      //       type: "line",
      //       xMin: 1000,
      //       xMax: 1000,
      //       borderColor: "rgb(102,218,151)",
      //       borderWidth: 1,
      //       label: {
      //         display: true,
      //         content: ["Operation Start"],
      //         backgroundColor: "transparent",
      //         color: "rgb(102,218,151)",
      //         position: "center",
      //         rotation: -90,
      //         xAdjust: -10,
      //         yAdjust: -100,
      //         font: { size: 13 },
      //       },
      //     },

      //     line5: {
      //       type: "line",
      //       xMin: crossLinePosition.xMin,
      //       xMax: crossLinePosition.xMax,
      //       borderWidth: 1,
      //     },
      //   },
      // },
    },
  };

  const data = {
    labels: labels,
    datasets: pmLines,
  };
  function handleClearFilters() {
    setPmLines(initialPmLines);
  }

  const getOperationLines = (filterParams) => {
    let keys = ["EVENTTIME", "EVENTDATA1", "EVENTDATA2", "EVENTDATA3"];
    if (filterParams.key) {
      keys.push(filterParams.key);
    }
    let params = {
      deviceId: deviceId,
      startTs: filterParams.startTs,
      endTs: filterParams.endTs,
      keys: keys.toString(),
    };
    let url = `${APP_BASE_URL}${GET_TELEMETRY_HISTORY_BY_TIMESTAMP_WITH_KEYS}`;
    axios
      .get(url, { params })
      .then((res) => {
        if (res.data.data) {
          let newAxisDataSet = new Set();
          res.data.data.EVENTTIME.forEach((row, i) => {
            JSON.parse(row.value).forEach((dt) => {
              newAxisDataSet.add(dt);
            });
          });
          let rawEventData = res.data.data.EVENTDATA1;
          let yellowEventData = res.data.data.EVENTDATA2;
          let blueEventData = res.data.data.EVENTDATA3;

          if (filterParams.key) {
            let rawFilterKeyData = res.data.data[filterParams.key];
            rawFilterKeyData.forEach((dt, i) => {
              rawEventData[i][filterParams.key] = Number(dt.value);
            });
          }

          let newPmLines = [];
          rawEventData.forEach((dt, i) => {
            if (filterParams.operator && filterParams.value && filterParams.key) {
              if (compare[filterParams.operator](dt[filterParams.key], filterParams.value)) {
                newPmLines.push({
                  label: getFormattedDateTime(dt.ts) + " Red",
                  data: JSON.parse(dt.value),
                  fill: false,
                  borderColor: Colors[i],
                  tension: 0.1,
                  pointRadius: 0,
                  pointHoverRadius: 4,
                });
              }
            } else {
              newPmLines.push({
                label: getFormattedDateTime(dt.ts) + " Red",
                data: JSON.parse(dt.value),
                fill: false,
                borderColor: Colors[i],
                tension: 0.1,
                pointRadius: 0,
                pointHoverRadius: 4,
              });
            }
          });

          yellowEventData.forEach((dt, i) => {
            if (filterParams.operator && filterParams.value && filterParams.key) {
              if (compare[filterParams.operator](dt[filterParams.key], filterParams.value)) {
                newPmLines.push({
                  label: getFormattedDateTime(dt.ts) + " Yellow",
                  data: JSON.parse(dt.value),
                  fill: false,
                  borderColor: Colors[i],
                  tension: 0.1,
                  pointRadius: 0,
                  pointHoverRadius: 4,
                });
              }
            } else {
              newPmLines.push({
                label: getFormattedDateTime(dt.ts) + " Yellow",
                data: JSON.parse(dt.value),
                fill: false,
                borderColor: Colors[i],
                tension: 0.1,
                pointRadius: 0,
                pointHoverRadius: 4,
              });
            }
          });

          blueEventData.forEach((dt, i) => {
            if (filterParams.operator && filterParams.value && filterParams.key) {
              if (compare[filterParams.operator](dt[filterParams.key], filterParams.value)) {
                newPmLines.push({
                  label: getFormattedDateTime(dt.ts) + " Blue",
                  data: JSON.parse(dt.value),
                  fill: false,
                  borderColor: Colors[i],
                  tension: 0.1,
                  pointRadius: 0,
                  pointHoverRadius: 4,
                });
              }
            } else {
              newPmLines.push({
                label: getFormattedDateTime(dt.ts) + " Blue",
                data: JSON.parse(dt.value),
                fill: false,
                borderColor: Colors[i],
                tension: 0.1,
                pointRadius: 0,
                pointHoverRadius: 4,
              });
            }
          });
          setPmLines(newPmLines);
        }
      })
      .catch(() => {
        setSnackBar(true);
        setPmLines([]);
      });
  };
  return (
    <div className="point-chart-container">
      <div className="header">
        <div className="annotation">
          <div>X-Axis: Time(mS)</div>
          <div>Y-Axis: Current(mA)</div>
        </div>
        {!disableFilter && (
          <div className="filter">
            <FilterComponent
              getFilterParams={(params) => {
                getOperationLines(params);
              }}
              resetFilters={handleClearFilters}
            />
          </div>
        )}
      </div>
      {lineData.length > 0 || axisDataSet.length > 0 ? (
        <div className="point-chart">
          <Chart className="point-line-chart" type="line" data={data} options={LINE_OPTIONS} />
        </div>
      ) : (
        <div>Data Unavailable</div>
      )}
      <Snackbar
        open={snackBar}
        autoHideDuration={6000}
        onClose={() => {
          setSnackBar(false);
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar(false);
          }}
          severity={"error"}
          className="fullWidth"
        >
          {"NO data to Show"}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PointOperationChart;
