import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { getLocalStorage, getParsedLocalStorage } from "../../Utils/storage-utils";

function isLoggedIn_() {
  const loggedIn = getLocalStorage("refresh_token");
  return loggedIn ? true : false;
}

const selectedCustomersAdapter = createEntityAdapter({ selectId: (customer) => customer.customerId });

function getInitialSelectedCustomers_() {
  const _selectedCustomers = getParsedLocalStorage("selected-customers");
  if (_selectedCustomers)
    return selectedCustomersAdapter.setAll(selectedCustomersAdapter.getInitialState(), _selectedCustomers);

  return selectedCustomersAdapter.getInitialState();
}

const emptyState = {
  isAuthenticated: false,
  refreshToken: null,
  token: null,
  user: {
    name: null,
    selectedCustomer: null,
    selectedCustomers: selectedCustomersAdapter.getInitialState(),
    permissions: null,
    userId: null,
    roleHierarchies: null,
    email: null,
    authority: null,
    frontendAccess: null,
    permittedCustomers: null,
  },
};

const initialState = {
  isAuthenticated: isLoggedIn_(),
  refreshToken: getLocalStorage("refresh_token"),
  token: getLocalStorage("token"),
  user: {
    name: getLocalStorage("user"),
    selectedCustomer: getParsedLocalStorage("selected-customer"),
    selectedCustomers: getInitialSelectedCustomers_(),
    permissions: getParsedLocalStorage("permissions", []),
    userId: getLocalStorage("userId"),
    roleHierarchies: getParsedLocalStorage("roleHierarchies"),
    email: getLocalStorage("email"),
    authority: getLocalStorage("authority"),
    frontendAccess: getParsedLocalStorage("frontendAccess"),
    permittedCustomers: getParsedLocalStorage("permitted-customers"),
  },
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      state.user = {
        ...action.payload.user,
        selectedCustomers: selectedCustomersAdapter.setAll(
          selectedCustomersAdapter.getInitialState(),
          action.payload.user.selectedCustomers,
        ),
      };
    },
    logoutSuccess: () => {
      localStorage.clear();
      return emptyState;
    },
    setSelectedCustomer: (state, action) => {
      localStorage.setItem("selected-customer", JSON.stringify(action.payload));
      state.user.selectedCustomer = action.payload;
    },
    setSelectedCustomers: (state, action) => {
      localStorage.setItem("selected-customers", JSON.stringify(action.payload));
      selectedCustomersAdapter.setAll(state.user.selectedCustomers, action.payload);
    },
    addSelectedCustomer: (state, action) => {
      selectedCustomersAdapter.addOne(state.user.selectedCustomers, action.payload);
    },
    removeSelectedCustomer: (state, action) => {
      selectedCustomersAdapter.removeOne(state.user.selectedCustomers, action.payload);
    },
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  setSelectedCustomer,
  setSelectedCustomers,
  addSelectedCustomer,
  removeSelectedCustomer,
} = authenticationSlice.actions;

export const {
  selectIds: selectedCustomersIdsSelector,
  selectEntities: selectedCustomersMapSelector,
  selectAll: selectedCustomersSelector,
} = selectedCustomersAdapter.getSelectors((state) => state.authentication.user.selectedCustomers);

export const isLoggedInSelector = (state) => state.authentication.isAuthenticated;
export const permittedCustomersSelector = (state) => state.authentication.user.permittedCustomers;
export const singleSelectedCustomerSelector = (state) => state.authentication.user.selectedCustomer;
export const userIdSelector = (state) => state.authentication.user.userId;

export default authenticationSlice.reducer;
