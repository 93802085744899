import React, { useState } from 'react'
import { RADAR_OPTION } from "./Constants";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Chart } from "react-chartjs-2";
function Card({ heading, CHART_DATA }) {
  const [fullScreen, setFullScreen] = useState(false)

  return (
    <div style={{ cursor: 'pointer' }} className={`chart-box ${fullScreen ? 'fullScreen' : ''}`} >
      <div className="card-header">
        <div className='card-heading'>{heading}</div>
        <div claaaName="fullscreen-icon" onClick={() => {
          setFullScreen((prev) => prev = !prev)
        }}><FullscreenIcon /></div>
      </div>
      <div className="chart">
        <Chart className="chart-canvas" type="radar" data={CHART_DATA} options={RADAR_OPTION} />
      </div>
    </div>
  )
}

export default Card