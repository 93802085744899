import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import './AffectedGearTrain.scss'
import { useGetDeviceTypesQuery, useGetDevicesNameQuery } from "../../../../features/SearchFilters/searchFiltersSlice";
import { getDeviceNameSearchParams, getDeviceTypeSearchParams } from "../../../../features/SearchFilters/searchFiltersUtils";
import { ActiveAlarmsData } from "../../../Alarms/ActiveAlarms";




const FieldRow = ({ field, index, handleChange, handleRemoveField, selectedStation }) => {
    const { customerIdList = [] } = useContext(ActiveAlarmsData);
    const { data: gearTypesList = [] } = useGetDeviceTypesQuery(
        getDeviceTypeSearchParams({
            customerIdList,
            filterState: {
                customer_ids: selectedStation ? [selectedStation] : []
            },
        }),
    );

    const { data: deviceNamesList = [] } = useGetDevicesNameQuery(
        getDeviceNameSearchParams({
            customerIdList,
            filterState: {
                device_type_ids: field?.assetType ? [field.assetType] : [],
                customer_ids: selectedStation ? [selectedStation] : []
            },
        }),
    );
    return (
        <div key={index} className="fieldRow assetAffected">
            <Autocomplete
                size="small"
                className="form-input autocomplete"
                value={field.assetType ?? null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                    handleChange(index, 'assetType', newValue);
                }}
                options={gearTypesList}
                getOptionLabel={(params) => {
                    return params.value;
                }}
                renderInput={(params) => (
                    <TextField size="small" {...params} label="Affected Asset Type" placeholder=" Select Affected Asset Type" />
                )}
            />
            <Autocomplete
                size="small"
                className="form-input autocomplete"
                value={field.asset ?? null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                    handleChange(index, 'asset', newValue);
                }}
                options={deviceNamesList}
                getOptionLabel={(params) => {
                    return params.value;
                }}
                renderInput={(params) => (
                    <TextField size="small" {...params} label="Affected Asset" placeholder="Affected Asset" />
                )}
            />
            <IconButton onClick={() => handleRemoveField(index)} aria-label="delete" className="deleteRow">
                <CancelRoundedIcon />
            </IconButton>

        </div>
    )
}
function AffectedGearTypes({ initialAffectedAssets, getAffectedAssets, selectedStation }) {
    const [affectedGear, setAffectdGears] = useImmer(initialAffectedAssets);

    useEffect(() => {
        let affectAssetList = []
        affectedGear.forEach((data) => {
            if (data.assetType && data.asset) {
                affectAssetList.push(data)
            }
        })
        getAffectedAssets(affectAssetList)
    }, [affectedGear])
    // console.log('affectedGear', affectedGear)
    const handleChange = (index, key, value) => {
        setAffectdGears((prev) => {
            prev[index][key] = value
        })
    }
    const handleClickAdd = () => {
        let pushObj = { assetType: null, asset: null }
        setAffectdGears((prev) => {
            prev = prev.push(pushObj)
        })
    }
    const handleRemoveField = (index) => {

        setAffectdGears((prev) => {
            prev = prev.splice(index, 1)
        })
    }
    return (
        <div className="dynamicFieldRender">
            <Button onClick={() => handleClickAdd()} className="addFieldActionBtn" variant="contained">
                ADD Assets Affected +
            </Button>
            <div className="fields">
                {affectedGear.map((field, index) => {
                    return <FieldRow index={index} selectedStation={selectedStation} field={field} handleChange={handleChange} handleRemoveField={handleRemoveField} />
                })}
            </div>
        </div>
    );
}

export default AffectedGearTypes;
