import { styled } from '@mui/material/styles';
import { themeBgColor } from '../../Theme/BaseTheme';

export const SidebarHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 1),
    // backgroundColor: themeBgColor,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));
