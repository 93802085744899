import { Button, Chip, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useImmer } from 'use-immer'
import DeleteIcon from "@mui/icons-material/Delete";



const initialStringValueState = {
    stringValue: '',
    stringValueArray: []
}
function RangePreviewString({getRangeObj,rangeType}) {
    const [stringValuesState, setStringValueState] = useImmer(initialStringValueState)

    const handleAddClick = (value) => {
        let newStringValueArray = [...stringValuesState.stringValueArray]
        newStringValueArray.push(value)
        setStringValueState((prev) => {
            prev.stringValue = ''
            prev.stringValueArray = newStringValueArray
        })
    }

    const onDeleteAction = (index) => {
        let newStringValueArray = [...stringValuesState.stringValueArray]
        newStringValueArray.splice(index, 1)
        setStringValueState((prev) => {
            prev.stringValueArray = newStringValueArray
        })
    }
    useEffect(()=>{
        let rangeArray = Object.values(stringValuesState.stringValueArray)
        let rangeObj = {}
        rangeObj[rangeType] = rangeArray
        if(rangeArray.length>0){

            getRangeObj(rangeObj)
        }
    },[stringValuesState.stringValueArray])
    return (
        <>
            <div className='stringInputs'>
                <TextField value={stringValuesState.stringValue} size='small' label='value' onChange={(e) => {
                    if(e.target.value.length > 0){
                        setStringValueState((prev) => {
                            prev.stringValue = e.target.value
                        })
                    }
                }} />
                <Button size='small' variant='contained' onClick={() => {
                    if(stringValuesState.stringValue.length>0){

                        handleAddClick(stringValuesState.stringValue)
                    }
                }} >Add</Button>
            </div>
            <div className='stringRangePreview'>
                {stringValuesState.stringValueArray.map((value, i) => {
                    return (<Chip
                        label={value}
                        className="option-chip"
                        // onClick={handleClick}
                        onDelete={() => {
                            onDeleteAction(i);
                        }}
                        deleteIcon={<DeleteIcon className="chip-icon" />}
                        variant="outlined"
                    // color="success"
                    />)
                })}
            </div>
        </>
    )
}

export default RangePreviewString
