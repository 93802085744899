import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  alarmFiltersSelector,
  getAlarmNotificationSound,
  refetchAlarmsData,
  selectAlarmEntities,
  updateSingleAlarmRow,
} from "./alarmsSlice";
import NotificationBellWAV from "../../assets/audio/notification_bell.wav";
import { ALARM_SOCKET, ALARM_WEBSOCKET_PATH, APP_BASE_URL_ALARM_SOCKET } from "../../Common/EndPoints";
import { shouldInsertIntoTable } from "./alarmUtils";

const audioWAV = new Audio(NotificationBellWAV);
var filters = null;
var entities = null;
var playAlarmSound = null;

function playNotificationSound() {
  if (playAlarmSound) {
    audioWAV.play();
  }
}

export function useAlarmsWebsocket(connect, customerIdList) {
  const alarmEntities = useSelector(selectAlarmEntities);
  const alarmFilters = useSelector(alarmFiltersSelector);
  const alarmSound = useSelector(getAlarmNotificationSound);
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * - this is done because in websocket latest value of state cannot be accessed
     * - as the value of state is saved at the time of creation of websocket
     * - thus creating global variables and saving state in them to access latest value of state
     * - useRef() can be used in replacement of this
     */
    filters = alarmFilters;
    entities = alarmEntities;
    playAlarmSound = alarmSound;
  }, [alarmEntities, alarmFilters, alarmSound]);
  useEffect(() => {
    let socket;
    function websocketConnect() {
      const url = `${
        APP_BASE_URL_ALARM_SOCKET + ALARM_WEBSOCKET_PATH
      }/ALARMS?customer_ids=${customerIdList.toString()}`;
      socket = new WebSocket(url);
      socket.onmessage = (event) => {
        const alarm = JSON.parse(event.data);
        if (!alarm) return;

        /** Alarm is present then update alarm */
        if (entities[alarm.id]) {
          dispatch(updateSingleAlarmRow(alarm));
          return;
        }

        if (alarm.clear_dt) {
          // if (shouldInsertIntoTable({ alarm, filters })) {
          //   /** Upsert One */
          //   dispatch(upsertSingleAlarmRow(alarm));
          //   return;
          // }
          dispatch(refetchAlarmsData);
          return;
        }

        /** New Alarm Generated show alert and sound notification */
        if (alarm.is_active && alarm.updated_dt === null) {
          // playNotificationSound();
          // toast((toastInfo) => <NewAlarmToast alarmInfo={alarm} dismiss={() => toast.dismiss(toastInfo.id)} />, {
          //   position: "top-right",
          //   duration: 8000,
          //   style: { maxWidth: "max-content" },
          // });

          if (shouldInsertIntoTable({ alarm, filters })) {
            /** Upsert One */
            // dispatch(upsertSingleAlarmRow(alarm));
            // return;
            dispatch(refetchAlarmsData);
            return;
          }
        }
      };
      // socket.onclose = () => {
      //   console.log("connection closed");
      //   if (connect) {
      //     reconnectTimeout = setTimeout(function () {
      //       console.log("timeout called");
      //       websocketConnect();
      //     }, 1000);
      //   }
      // };
      socket.onerror = function () {
        socket.close();
      };
    }
    if (connect) {
      websocketConnect();
    } else {
      if (socket) {
        socket.close();
        // clearTimeout(reconnectTimeout);
      }
    }
    return () => {
      // console.log("closing socket");
      socket?.close();
      // clearTimeout(reconnectTimeout);
    };
  }, [connect, customerIdList.length]);
}
