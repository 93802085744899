import { GENERIC_DEVICE_CONNECTIONS, RTU_HEALTH_STATUS } from "../../Common/EndPoints";
import { apiAlarms } from "../../features/api/apiAlarms";

type GetConnectedDevicesParams = {
  with_customer?: boolean;
  with_device_type?: boolean;
  with_device?: boolean;
  connection_types: string;
  customer_ids?: string;
};

type DeviceCustomer = { id: string; name: string; devices: null | any };

export type Device = { id: string; name: string; type: string; customer: DeviceCustomer; Alarms: null | any };

export type ConnectedDevice = {
  parent: Device;
  child: Device;
  connection_type: string;
  meta?: null | {
    outdoor_ref?: number;
    indoor_receive?: { key: string; min: string; max: string }[];
    outdoor_realtime?: { key: string; min: string; max: string }[];
    loc?: string;
  };
};

export type GetConnectedDeviceResponse = ConnectedDevice[];

export const cable_monitoring_apis = apiAlarms.injectEndpoints({
  endpoints: (build) => ({
    getConnectedDevices: build.query<GetConnectedDeviceResponse, GetConnectedDevicesParams>({
      query: (params) => {
        return {
          url: GENERIC_DEVICE_CONNECTIONS,
          method: "GET",
          params,
        };
      },
      providesTags: [],
    }),

  }),
});
