import { useEffect, useState, createContext } from "react";
import axios from "axios";
import { APP_BASE_URL, GET_ATTACHMENTS, UPLOAD_MULTIPLE_ATTACHMENTS } from "../../Common/EndPoints";
import { Outlet, useLocation } from "react-router-dom";
export const AssetsData = createContext({});
const USER_ID = localStorage.getItem("userId");
export const AssetsProvider = () => {
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [attachmentsEdit, setAttachmentsEdit] = useState(false);
  const location = useLocation();
  const defaultState = location?.state?.d;
  const [deviceId, setDeviceId] = useState(defaultState?.deviceId ?? null);
  const [editMode, setEditMode] = useState();
  const [formId, setFormId] = useState("");
  async function uploadAttachments(fetchedDeviceId, attachmentsToUpload, responseId) {
    const uploadData = attachmentsToUpload ?? attachmentsData;
    let FILE_TYPES = "";
    let FILE_EXTENSIONS = "";

    const files = new FormData();
    let uploadAttachments = 0;
    uploadData.forEach(({ file, fileType, uploadedOnServer }) => {
      /**
       * if file is not uploaded on server then only attach it to the api
       */
      if (!uploadedOnServer) {
        uploadAttachments += 1;
        FILE_TYPES += fileType + ",";
        FILE_EXTENSIONS += file.type + ",";
        files.append("files", file);
      }
    });

    /**
     * For removing the comma(`,`) from the end of FILE_TYPES and FILE_EXTENSIONS
     */
    FILE_TYPES = FILE_TYPES.slice(0, -1);
    FILE_EXTENSIONS = FILE_EXTENSIONS.slice(0, -1);
    let url = `${APP_BASE_URL}${UPLOAD_MULTIPLE_ATTACHMENTS}?types=${FILE_TYPES}&extensions=${FILE_EXTENSIONS}&created_by=${USER_ID}&device_id=${
      fetchedDeviceId ?? deviceId
    }`;
    if (responseId) {
      url += `&response_id=${responseId}`;
    }
    if (uploadAttachments > 0) {
      return await axios
        .post(url, files)
        .then((res) => {
          getAttachmentsData();
          return res;
        })
        .catch((err) => err);
    } else {
      // throw Object.assign(new Error("No New Attachments Added"), { code: 402 });
    }
  }
  function getAttachmentsData() {
    setAttachmentsData([]);
    if (!deviceId) return;
    let API_URL = `${APP_BASE_URL}${GET_ATTACHMENTS}?device_id=${deviceId}`;
    if (!attachmentsEdit) {
      if (formId && formId !== "general_info") {
        API_URL += `&form_id=${formId}&general_info=false`;
      } else {
        API_URL += `&general_info=true`;
      }
    }
    axios
      .get(API_URL)
      .then(({ data: { data: attachments } }) => {
        const attachmentsData = attachments.map((attachment) => {
          return {
            ...attachment,
            file: "",
            fileError: false,
            fileType: attachment.type,
            fileTypeError: false,
            uploadedOnServer: true,
            loading: false,
          };
        });
        setAttachmentsData(attachmentsData);
      })
      .catch(() => {});
  }
  useEffect(() => {
    setAttachmentsData([]);
    getAttachmentsData();
  }, [editMode, attachmentsEdit, deviceId, formId]);
  return (
    <AssetsData.Provider
      value={{
        attachmentsData: attachmentsData,
        setAttachmentsData: (attachments) => {
          setAttachmentsData(attachments);
        },
        DEVICE_ID: deviceId,
        setDeviceId: (deviceId) => {
          setDeviceId(deviceId);
        },
        formId,
        setFormId: (value) => {
          setFormId(value);
        },
        setAttachmentsEdit: setAttachmentsEdit,
        isEditMode: (editMode) => {
          setEditMode(editMode);
        },
        uploadAttachments: uploadAttachments,
      }}
    >
      <Outlet />
    </AssetsData.Provider>
  );
};
export default AssetsProvider;
