import React, { useEffect } from 'react'
import { CUSTOMER_ID_DATA } from './Constant'

import { ReactComponent as NOQ } from "./../SVG/FAKE_NOQ.svg";
import { NOQ_MAPPING } from './mapping';

// let atrrMap = {
//     TC132T: 'NOQ_TC_132T',
//     PM138: 'NOQ_PM_138',
//     SGS46: 'NOQ_SG_S46',
//     SGC46: 'NOQ_SG_C46',
//     SH14: 'NOQ_SG_SH16',
//     SGSDD: 'NOQ_SG_SDD',
//     AXLNBS_DN1: 'NOQ_AC_UPBPAC1',
//     LCGNN89A: 'NOQ_LCG_NN89A'

// }




function SetAttribute() {
    let SVG_COMP = document.getElementById('main-svg')

    let NOT_PROVIDED_ID = []
    let notMachting = {}
    if (SVG_COMP) {
        Object.entries(NOQ_MAPPING).forEach(([key, value]) => {
            // let gearType = value.split('_')[1]
            if (key && key !== '') {
                let COMP = SVG_COMP.getElementById(key)
                if (COMP) {
                    COMP.setAttribute('edge-id', value)
                    let COMP_CHILD_ELEMENTS = COMP.querySelectorAll('[id]');
                    COMP_CHILD_ELEMENTS.forEach((child) => {
                        let childId = child.getAttribute('id')
                        if (childId.includes(key)) {
                            child.setAttribute('edge-id-selector', childId.replace(key, value))
                        }
                    })
                } else {
                    notMachting[key] = value
                }

            } else {
                NOT_PROVIDED_ID.push(value)
            }


        })

    }
    console.log('NOT_PROVIDED_ID', NOT_PROVIDED_ID)
    console.log('notMachting', notMachting)


    return (
        <div>
            <NOQ />
        </div>
    )
}

export default SetAttribute
