import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import axios from "axios";
import React, { createContext, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLocation, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import Loader from "../Loader/Loader";
import FieldTypeComponents from "./components/FieldTypeComponents";
import FormPreview from "./components/FormPreview";
import { ALLOWED_MODES } from "./Constats";
import "./FormCreator.scss";
import { toast } from "react-hot-toast";
import { APP_BASE_URL } from "../../Common/EndPoints";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { useGetDeviceTypesQuery } from "../../features/SearchFilters/searchFiltersSlice";
import { pageDetailsByUrl } from "./Forms/FormConstants";

export const FormContext = createContext({
  replaceFormData: (data) => { },
  manipulateFormData: (data) => { },
  manipulateReactiveFields: (id, value, allFields) => { },
  manipulateReactiveFieldsOfSection: (index, id, value, allFields) => { },
  formData: [],
  isAssetForm: "",
  formCreatorMode: "",
});

let intialFormData = [];
let initialConfirmationModal = {
  open: false,
  message: "",
  des: "",
};

function FormCreator() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageInfo, setPageInfo] = useImmer(location.state);
  const [loader, setLoader] = useState(false);
  const [formData, setFormaData] = useImmer(() => {
    return [...pageInfo.formJson];
  });
  const [confirmationModal, setConfirmationModal] = useImmer(initialConfirmationModal);
  const { data: gearTypesList = [] } = useGetDeviceTypesQuery(null);

  const manipulateFormData = (data, targetId) => {
    if (targetId) {
      let updatedFormData = [...formData];
      let updateIndex;
      updatedFormData.forEach((row, i) => {
        if (row.key === targetId) {
          updateIndex = i;
        }
      });

      let sections = updatedFormData[updateIndex]?.sectionFields ?? [];
      sections = [...sections, data];

      let rowTobeUpdate = { ...updatedFormData[updateIndex] };

      rowTobeUpdate["sectionFields"] = [...sections];
      updatedFormData[updateIndex] = rowTobeUpdate;
      setFormaData([...updatedFormData]);
    } else {
      setFormaData((prev) => {
        prev = prev.push(data);
      });
    }
  };

  function replaceObjectByKey(json, keyToReplace, newObject) {
    return json.map((obj) => {
      if (obj.key === keyToReplace) {
        return { ...newObject };
      }

      if (obj.sectionFields) {
        return { ...obj, sectionFields: replaceObjectByKey(obj.sectionFields, keyToReplace, newObject) };
      }

      return obj;
    });
  }
  const getManipulatedFormData = (id, value, allFields) => {
    return allFields.map((obj) => {
      if (obj.reactiveKey === id) {
        //write logic what to update in field if a fields reactive key match to given id
        if (obj.type === "reactive-dropdown" || obj.type === "reactive_dropdown") {
          //for reactive dropdown
          let reactiveValueObj = obj.reactiveValue;
          let newDefaultVAlue = reactiveValueObj[value]
          if (newDefaultVAlue) {
            return { ...obj, DEFAULT_VALUE: newDefaultVAlue, showInitially: true };
          } else {
            return { ...obj, DEFAULT_VALUE: [], showInitially: false };
          }
        } else {
          if (obj.reactiveValue.includes(value)) {
            return { ...obj, showInitially: true };
          } else {
            return { ...obj, showInitially: false };
          }
        }
        //  return { ...obj,label:value };
      }

      if (obj.sectionFields) {
        return { ...obj, sectionFields: getManipulatedFormData(id, value, obj.sectionFields) };
      }

      return obj;
    });
  };
  const manipulateReactiveFields = (id, value, allFields) => {
    let newFormData = getManipulatedFormData(id, value, allFields);
    setFormaData(newFormData);
    // return allFields.map((obj) => {
    //   if (obj.reactiveKey === id) {
    //     //write logic what to update in field if a fields reactive key match to given id
    //     if (obj.type === "reactive-dropdown" || obj.type === "reactive_dropdown") {
    //       //for reactive dropdown
    //       let reactiveValueObj = obj.reactiveValue
    //       let newDefaultVAlue = reactiveValueObj[value]
    //       if(newDefaultVAlue){
    //         return { ...obj,"DEFAULT_VALUE":newDefaultVAlue, showInitially:true };
    //       }else{
    //         return { ...obj,"DEFAULT_VALUE":[], showInitially:false };
    //       }
    //     } else{
    //       if(obj.reactiveValue.includes(value)){
    //         return { ...obj, showInitially:true };
    //       }else{
    //         return { ...obj, showInitially:false };
    //       }
    //     }
    //     //  return { ...obj,label:value };
    //   }

    //   if (obj.sectionFields) {
    //     return { ...obj, sectionFields: manipulateReactiveFields(id, value, obj.sectionFields) };
    //   }

    //   return obj;
    // });
    // let updatedReactiveFieldData = [...allFields];
    // let ractiveOptions;
    // updatedReactiveFieldData.forEach((row, i) => {
    //   let rowTobeUpdate = { ...row };
    //   if (row?.reactiveKey === id) {
    //     if (row.type === "reactive_section") {
    //       if (row.reactiveValue.includes(value)) {
    //         rowTobeUpdate["showInitially"] = true;
    //       } else {
    //         rowTobeUpdate["showInitially"] = false;
    //       }
    //     } else {
    //       if (Array.isArray(value)) {
    //         let reactiveOptionMultiple = [];
    //         value.forEach((key, i) => {
    //           rowTobeUpdate["showInitially"] = true;
    //           reactiveOptionMultiple = [...reactiveOptionMultiple, ...row.reactiveValue[key]];
    //         });
    //         rowTobeUpdate["DEFAULT_VALUE"] = [...reactiveOptionMultiple];
    //       } else if (row.reactiveValue[value]) {
    //         ractiveOptions = row.reactiveValue[value];
    //         rowTobeUpdate["showInitially"] = true;
    //         rowTobeUpdate["DEFAULT_VALUE"] = [...ractiveOptions];
    //       } else {
    //         rowTobeUpdate["showInitially"] = false;
    //         rowTobeUpdate["DEFAULT_VALUE"] = [];
    //       }
    //     }

    //     updatedReactiveFieldData[i] = rowTobeUpdate;
    //     setFormaData([...updatedReactiveFieldData]);
    //   }
    // });
    // setFormaData([...updatedReactiveFieldData]);
  };

  const manipulateReactiveFieldsOfSection = (index, id, value, allFields) => {
    let newSectionFields = [...allFields];
    newSectionFields.forEach((row, i) => {
      let rowTobeUpdate = { ...row };
      let newFormData = [...formData];
      if (row.reactiveKey === id && row.reactiveValue[value]) {
        rowTobeUpdate.DEFAULT_VALUE = row.reactiveValue[value];
        rowTobeUpdate.showInitially = true;
      } else {
        rowTobeUpdate.showInitially = false;
      }
      newSectionFields[i] = rowTobeUpdate;
      let formDataSection = { ...newFormData[index] };
      formDataSection["sectionFields"] = [...newSectionFields];
      newFormData[index] = formDataSection;
      setFormaData([...newFormData]);
    });
  };
  const replaceFormData = (newFormData) => {
    setFormaData(newFormData);
  };
  const handleSubmitJSON = (mode) => {
    let USER_ID = localStorage.getItem("userId");
    let needApproval = false;
    let params;
    if (pageInfo.isAssetForm) {
      params = {
        name: pageInfo.name,
        active: pageInfo.published,
        deviceTypeId: pageInfo.device_type_ids.id,
      };
    } else {
      let newFormName = pageInfo.device_type_ids?.value + ' ' + '-' + ' ' + pageInfo.name
      params = {
        createdBy: USER_ID,
        name: newFormName.toUpperCase(),
        needApproval: needApproval,
        active: pageInfo.published,
      };
    }
    let url = `${APP_BASE_URL}${pageInfo.postUrl}`;

    let crudStatus = {
      success: "created successfully",
      error: "failed to create",
    };
    let data = formData

    let apiRequest = axios.post;

    if (mode === "edit") {
      if (pageInfo.isAssetForm) {
        params = {
          name: pageInfo.name,
          active: pageInfo.published,
          deviceTypeId: pageInfo.device_type_ids.id,
          formId: pageInfo.formId,
        };
      } else {
        let newFormName = pageInfo.device_type_ids?.value + ' ' + '-' + ' ' + pageInfo.name
        params = {
          name: newFormName.toUpperCase(),
          needApproval: needApproval,
          active: pageInfo.published,
          formId: pageInfo.formId,
        };
        apiRequest = axios.put;
      }

      crudStatus = {
        success: "updated successfully",
        error: "failed to update",
      };

      url = `${APP_BASE_URL}${pageInfo.putUrl}`;
    }
    if (pageInfo.name.length > 0) {
      setLoader(true);
      apiRequest(url, data, {
        params,
        paramsSerializer: function (params) {
          let result = "";
          Object.keys(params).forEach((key) => {
            result += `${key}=${params[key]}&`;
          });
          return result.substring(0, result.length - 1);
        },
      })
        .then((res) => {
          setLoader(false);
          localStorage.setItem("form-crud-status", crudStatus.success);
          navigate(pageInfo.pageUrl);
        })
        .catch((err) => {
          setLoader(false);
          let errMsg = err?.response?.data?.error?.message;
          errMsg = errMsg.length > 20 ? errMsg.slice(0, 19) + "..." : errMsg
          toast.error(errMsg);
          // const errMsg = err?.response?.data?.error?.message;
          // localStorage.setItem("form-crud-status", errMsg.length > 20 ? errMsg.slice(0, 19) + "..." : errMsg);
          // navigate(pageInfo.pageUrl);
        });
    } else {
      toast.error("Please enter form name");
    }
  };

  const toggleEditMode = (status) => {
    // let updatedName = { ...pageInfo.name };
    // updatedName.editabel = status;
    if (status) {
      setPageInfo((prev) => {
        prev.mode = "edit";
        // prev.name = updatedName;
      });
    } else {
      setPageInfo((prev) => {
        prev.mode = "view";
        // prev.name = updatedName;
      });
    }
  };

  const getGeartypeListForAssetForm = (gearTypes) => {
    let newList = []
    if (gearTypes) {
      gearTypes.forEach((type) => {
        let gearType = { id: type.value.toUpperCase(), value: type.value }
        newList.push(gearType)
      })
    }
    return newList
  }
  return (
    <FormContext.Provider
      value={{
        manipulateFormData: manipulateFormData,
        manipulateReactiveFields: manipulateReactiveFields,
        formData: formData,
        manipulateReactiveFieldsOfSection: manipulateReactiveFieldsOfSection,
        isAssetForm: pageInfo.isAssetForm,
        replaceFormData: replaceFormData,
        formCreatorMode: pageInfo.mode,
      }}
    >
      <div className="form-creator-main-body">
        {loader && <Loader />}
        {confirmationModal && (
          <ConfirmationModal
            open={confirmationModal.open}
            onClose={() => {
              setConfirmationModal(initialConfirmationModal);
            }}
            onAction={(confirmation) => {
              if (confirmation) {
                handleSubmitJSON(pageInfo.mode);
              }
              setConfirmationModal(initialConfirmationModal);
            }}
            message={confirmationModal.message}
            des={confirmationModal.des}
          />
        )}
        <div className="form-creator-header">
          <div className="form-fields">
            <input
              className="formCreaterHeadingInput"
              value={pageInfo.name}
              type={"text"}
              placeholder="ENTER FORM NAME"
              onChange={(e) => {
                setPageInfo((prev) => {
                  prev.name = e.target.value.toUpperCase();
                });
              }}
              disabled={!ALLOWED_MODES.editHeading.includes(pageInfo.mode)}
            ></input>

            <Autocomplete
              size="small"
              className="auto-complete"
              disableCloseOnSelect
              options={pageInfo.isAssetForm ? gearTypesList : getGeartypeListForAssetForm(gearTypesList)}
              renderInput={(params) => (
                <TextField size="small" {...params} label="Asset Type" placeholder="Asset Type" />
              )}
              getOptionLabel={(gearType) => gearType.value}
              value={pageInfo?.device_type_ids}
              onChange={(_, newValue) => {
                setPageInfo((prev) => {
                  prev.device_type_ids = newValue;
                });
              }}
              disabled={!ALLOWED_MODES.editHeading.includes(pageInfo.mode)}
            />

          </div>

          {ALLOWED_MODES.editToggle.includes(pageInfo.mode) && pageInfo.allowUpdateForm && (
            <div className="toggle-switch">
              <span>Edit Mode:</span>
              <Switch
                onChange={(e) => {
                  toggleEditMode(e.target.checked);
                }}
              />
            </div>
          )}
        </div>
        <div className="form-creator-body">
          <DndProvider backend={HTML5Backend}>
            {ALLOWED_MODES.formComponents.includes(pageInfo.mode) && <FieldTypeComponents />}
            <FormPreview formJson={formData} />
            {/* <DragLIst/> */}
          </DndProvider>
          {/* <DraftPopUp
            open={draftPopup.open}
            action={(action) => {
              draftAction(action);
            }}
          /> */}
        </div>
        {ALLOWED_MODES.actionButton.includes(pageInfo.mode) && (
          <div className="form-creator-footer">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      setPageInfo((prev) => {
                        prev.published = e.target.checked;
                      });
                    }}
                    checked={pageInfo.published}
                  />
                }
                label="Publish"
              />
            </FormGroup>
            <Button
              onClick={() => {
                if (!pageInfo.name || pageInfo.name.length === 0) {
                  toast.error("Please enter form name");

                } else if (!pageInfo.device_type_ids) {
                  toast.error("Please select a asset type");
                }
                else {
                  setConfirmationModal((prev) => {
                    prev.open = true;
                    prev.message = "Create Form";
                    prev.des = "Do you want to save the form ?";
                  });
                }
              }}
              variant="contained"
            >
              {pageInfo.mode === "create" ? "Create" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </FormContext.Provider>
  );
}

export default FormCreator;
