import axios from "axios";
import React, { useContext, useEffect } from "react";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import { useImmer } from "use-immer";
import { APP_BASE_URL, GET_MAINTENACE_ASSET, GET_MAINTENANCE_COUNT } from "../../../../Common/EndPoints";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./MaintenanceDashBoard.scss";
import { color } from "@mui/system";
import MaintenanceInfo from "./MaintenanceInfo";
import { IconButton } from "@mui/material";
import { ActiveAlarmsData } from "../../../Alarms/ActiveAlarms";
import NoData from "../../../../Utils/NoDataComponent.js/NoData";
import { useNavigate } from "react-router";
import { extractMasterRoleIds } from "../../../../Utils/Utils";
ChartJS.register(ChartDataLabels);

const initialCounts = {
  UPCOMING: 0,
  DUE: 0,
  TODAY: 0,
  OVERDUE: 0,
};

const TYPE_COLOR = {
  OVERDUE: "red",
  TODAY: "#7AC142",
  UPCOMING: "blue",
  DUE: "orange",
};

// const TYPE_COLOR = {
//     OVERDUE: '#FB0007',
//     TODAY: '#7AC142',
//     UPCOMING: '#F47A1F',
//     DUE: '#4daf00'
// }

const INITIAL_PIE_DATA = {
  labels: [],
  datasets: [
    {
      label: "Maintenance Count",
      data: [],
      backgroundColor: [],
      hoverOffset: 4,
    },
  ],
};

const CHART_OPTIONS = (navigateToMaintenance) => {
  // const navigate = useNavigate()
  return {
    maintainAspectRatio: true,
    responsive: true,
    onClick: function (chartDAta) {
      let maintenanceType = chartDAta.chart.tooltip.body[0].lines[0].split(":")[0];
      // console.log(maintenanceType)
      navigateToMaintenance(maintenanceType)
      // navigate('/assets')
      // setMaintenaceInfoModel((prev) => {
      //   prev.open = true;
      //   // prev.info = RESPONSE
      //   prev.type = maintenanceType;
      // });
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        formatter: function (count, chart, b) {
          let totalCount = chart?.dataset.data.reduce((accum, currentValue) => accum + currentValue);
          let countPer = (count / totalCount) * 100;
          return countPer > 20 ? count : "";
        },
      },
      tooltip: {
        yAlign: "center",
        xAlign: "left",
        responsive: true,
      },
    },
  };
};
// const CUOUNT_TYPES = ['OVERDUE', 'DUE', 'UPCOMING']
const CUOUNT_TYPES = ["OVERDUE", "DUE"];

const DashCountRow = ({ type, count, actionBtn }) => {
  return (
    <div className="dash-count-card">
      <div className="count-type">
        <span style={{ backgroundColor: TYPE_COLOR[type] }} className="colour-box"></span>
        <span>{type}</span>
      </div>
      <div className="count " style={{ color: TYPE_COLOR[type] }}>
        {count ? count : 0}
      </div>
      <IconButton
        disabled={!count > 0}
        // style={{ color:!count>0?'#e2e2e2':'#858484' }}
        onClick={() => {
          actionBtn(type);
        }}
        className={`act-icon ${count > 0 ? "" : "notAllowed"}`}
      >
        <MoreHorizIcon />
      </IconButton>
      {/* <div onClick={() => { actionBtn(type) }} className='act-icon pointer'><MoreHorizIcon /></div> */}
    </div>
  );
};
const initialMaintenaceInfoModel = {
  open: false,
  info: [],
  type: "",
};
function MaintenanceDashBoard() {
  const navigate = useNavigate()
  const [count, setCount] = useImmer(initialCounts);
  const [pieData, setPieData] = useImmer(INITIAL_PIE_DATA);
  const [maintenaceInfoModel, setMaintenaceInfoModel] = useImmer(initialMaintenaceInfoModel);
  const { customerIdList } = useContext(ActiveAlarmsData);
  useEffect(() => {
    // let lastIndex = CUOUNT_TYPES.length - 1
    // CUOUNT_TYPES.forEach((type, i) => {
    //     getCounts(type)
    // })
    if (customerIdList.length > 0) {
      getCounts();
    }
  }, [customerIdList]);

  // useEffect(() => {
  //     if (count.OVERDUE !== 0 || count.TODAY !== 0 || count.DUE !== 0 || count.UPCOMING !== 0 ) {
  //         let newPieData = {
  //             labels: [
  //                 'OVERDUE',
  //                 // 'Today',
  //                 'DUE',
  //                 // 'UPCOMING'
  //             ],
  //             datasets: [{
  //                 label: 'Maintenance Count',
  //                 // data: [15, 10, 5],
  //                 data: [count.OVERDUE, count.DUE],
  //                 // data: [count.OVERDUE, count.DUE, count.UPCOMING],
  //                 // data: [count.OVERDUE, count.TODAY, count.DUE, count.UPCOMING],
  //                 backgroundColor: [
  //                     TYPE_COLOR.OVERDUE, TYPE_COLOR.DUE
  //                 ],
  //                 // backgroundColor: [
  //                 //     TYPE_COLOR.OVERDUE, TYPE_COLOR.DUE, TYPE_COLOR.UPCOMING
  //                 // ],
  //                 // backgroundColor: [
  //                 //     TYPE_COLOR.OVERDUE, TYPE_COLOR.TODAY, TYPE_COLOR.DUE, TYPE_COLOR.UPCOMING
  //                 // ],
  //                 hoverOffset: 4
  //             }]
  //         };
  //         setPieData(newPieData)
  //     } else {
  //         setPieData(INITIAL_PIE_DATA)
  //     }

  // }, [count])

  // const getCounts = (countType) => {
  const getCounts = (countType) => {
    // let url = `${APP_BASE_URL}${GET_MAINTENANCE_COUNT}?status=${countType}`
    let roleHr = JSON.parse(localStorage.getItem('roleHierarchies'))
    let MASTER_ROLE_IDS = extractMasterRoleIds(roleHr)
    let url = `${APP_BASE_URL}${GET_MAINTENANCE_COUNT}`;
    let params = {
      customerIds: customerIdList.toString(),
      roleIdList: MASTER_ROLE_IDS.toString()
    };
    axios.get(url, { params }).then((res) => {
      // {
      //     labels: [],
      //     datasets: [{
      //         label: 'Maintenance Count',
      //         data: [],
      //         backgroundColor: [],
      //         hoverOffset: 4
      //     }]
      // };
      const COUNT = res.data.data;
      let newPieData;
      let newLabels = ["OVERDUE", "DUE"];
      let newDataSets = [
        {
          label: "Maintenance Count",
          // data: [15, 10, 5],
          data: [COUNT.overdue, COUNT.due],
          // data: [count.OVERDUE, count.DUE, count.UPCOMING],
          // data: [count.OVERDUE, count.TODAY, count.DUE, count.UPCOMING],
          backgroundColor: [TYPE_COLOR.OVERDUE, TYPE_COLOR.DUE],
          // backgroundColor: [
          //     TYPE_COLOR.OVERDUE, TYPE_COLOR.DUE, TYPE_COLOR.UPCOMING
          // ],
          // backgroundColor: [
          //     TYPE_COLOR.OVERDUE, TYPE_COLOR.TODAY, TYPE_COLOR.DUE, TYPE_COLOR.UPCOMING
          // ],
          hoverOffset: 4,
        },
      ];
      if ((COUNT.overdue !== 0 && COUNT.overdue) || (COUNT.due !== 0 && COUNT.due)) {
        newPieData = {
          labels: newLabels,
          datasets: newDataSets,
        };
      } else {
        newPieData = INITIAL_PIE_DATA;
      }

      setPieData(newPieData);
      setCount((prev) => {
        prev.OVERDUE = COUNT.overdue;
        prev.UPCOMING = COUNT.upcoming;
        prev.DUE = COUNT.due;
      });
    });
  };
  const handleActionBtn = (type) => {
    // setMaintenaceInfoModel((prev) => { prev.open = true })
    // let url = `${APP_BASE_URL}${GET_MAINTENACE_ASSET}?page=1&pageSize=30&status=${type}`
    // axios.get(url).then((res) => {
    //     let RESPONSE = res.data.data.content
    setMaintenaceInfoModel((prev) => {
      prev.open = true;
      // prev.info = RESPONSE
      prev.type = type;
    });
    // })
  };
  const navigateToMaintenance = (maintenanceType) => {
    sessionStorage.setItem('selecetdAsstTab', 'maintenance')
    navigate('/assets', { state: maintenanceType })
  }
  return (
    // <div className="maintenace-dashBoard-container card">
    <>
      {/* <div className="card-heading">Periodic Maintenance Dashboard</div> */}
      <div className="chart-container">
        {pieData.datasets[0].data.length > 0 ? (
          <Chart
            className="pie-count-chart cursor-pointer"
            type="doughnut"
            data={pieData}
            options={CHART_OPTIONS(navigateToMaintenance)}
          />
        ) : (
          <NoData msg={`Not enough data`} />
        )}
      </div>
      <div className="card-container">
        {CUOUNT_TYPES.map((type, i) => {
          return (
            <DashCountRow
              key={i}
              type={type}
              count={count[type]}
              actionBtn={(type) => {
                navigateToMaintenance(type);
              }}
            />
          );
        })}
      </div>
      {maintenaceInfoModel.open && (
        <MaintenanceInfo
          open={maintenaceInfoModel.open}
          onClose={() => {
            setMaintenaceInfoModel(initialMaintenaceInfoModel);
          }}
          type={maintenaceInfoModel.type}
        />
      )}
    </>
    // </div>
  );
}

export default MaintenanceDashBoard;
