import { trimAssetName } from "../../Common/EndPoints";

const commonTableColumns = [
  {
    field: "stn_code",
    headerName: "Station",
    sortable: true,
    flex: 1,
    minWidth: 90,
  },
  {
    field: "cnt",
    headerName: "Event Count",
    sortable: true,
    flex: 1,
    minWidth: 100,
  },
];

const categoryDetals = {
  id: "failures-by-category",
  name: "Failures by Category",
  dependentOn: "category",
  getUrl: "/alarm/customer_category_count",
  tableColumns: [...commonTableColumns, { field: "category", headerName: "Category Type", sortable: true, width: 120 }],
};
const alarmTypeDetals = {
  id: "failures-by-alarmType",
  name: "Failures by Alarm Type",
  dependentOn: "alarmType",
  getUrl: "/alarm/customer_alarm_count",
  tableColumns: [...commonTableColumns, { field: "type", headerName: "Alarm Type", sortable: true, flex: 2 }],
};
const gearTypeDetals = {
  id: "failures-by-gearType",
  name: "Failures by GearType",
  dependentOn: "gearType",
  getUrl: "/alarm/customer_devicetype_count",
  tableColumns: [
    ...commonTableColumns,
    { field: "device_type_label", headerName: "Gear Type", sortable: true, width: 120 },
  ],
};
const devicesDetals = {
  id: "failures-by-devices",
  name: "Failures By Devices",
  dependentOn: "devices",
  getUrl: "/alarm/customer_devicetype_device_count",
  tableColumns: [
    ...commonTableColumns,
    { field: "device_type_label", headerName: "Gear Type", sortable: true, minWidth: 100, flex: 1 },
    {
      field: "device_name",
      headerName: "Device Names",
      sortable: true,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        let trimmedDeviceName = trimAssetName(params?.row?.device_name)
        return trimmedDeviceName
      },
    },
  ],
};

export const DetailsByUrl = {
  "/reports/failures-by-category": categoryDetals,
  "/reports/failures-by-alarmType": alarmTypeDetals,
  "/reports/failures-by-gearType": gearTypeDetals,
  "/reports/failures-by-devices": devicesDetals,
};
export const RedirectionByUrl = {
  "/reports/failures-by-category": "/reports/failures-by-devices",
  "/reports/failures-by-alarmType": "/reports/failures-by-devices",
  "/reports/failures-by-gearType": "/reports/failures-by-devices",
  "/reports/failures-by-devices": "/reports/failures-by-alarmType",
};
