import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Chip, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const OverflowTip = ({ children }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);
  return (
    <Tooltip
      componentsProps={{ tooltip: { sx: { backgroundColor: "#F5F5F5" } } }}
      title={<div className="overflow-tooltip-hover">{children}</div>}
      disableHoverListener={!isOverflowed}
      disableInteractive
    >
      <div ref={textElementRef} className="overflow-tooltip">
        {children}
      </div>
    </Tooltip>
  );
};
export const USERS_COLUMNS = (setState, allowUpdateUser) => [
  {
    field: "createdTime",
    headerName: "Created Time",
    sortable: true,
    //     headerAlign: "center",
    //   align: "center",
    minWidth: 180,
    type: "date",
    valueFormatter: (param) => {
      if (param.value && param.value > 0) {
        const value = Number(param.value);
        const date = new Date(value);
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = dd + "/" + mm + "/" + yyyy;
        const time = date.toLocaleTimeString();
        return formattedToday + " " + time;
      } else {
        return "-";
      }
    },
  },
  {
    field: "name",
    headerName: "Name",
    //     headerAlign: "center",
    //   align: "center",
    sortable: true,
    width: 120,
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    //     headerAlign: "center",
    //   align: "center",
    sortable: true,
    flex: 1,
    width: 150,
  },
  {
    field: "stations",
    headerName: "Stations",
    //     headerAlign: "center",
    //   align: "center",
    sortable: true,
    flex: 2,
    width: 180,
    renderCell: (params) => {
      const _sortedValues = [...params.value]?.sort() ?? [];
      const _id = params.row.id;
      const stations = _sortedValues?.map((station, index) => (
        <Chip
          className="users__table__station-chip"
          key={"station" + _id + index}
          label={station}
          size="small"
          variant="filled"
        />
      ));
      return <OverflowTip>{stations}</OverflowTip>;
    },
  },
  {
    field: "authority",
    headerName: "Role",
    //     headerAlign: "center",
    //   align: "center",
    sortable: true,
    width: 120,
  },
  {
    field: "editActions",
    type: "actions",
    width: 80,
    hideable: false,
    getActions: (params) =>
      allowUpdateUser
        ? [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => {
                setState((previousState) => {
                  previousState.selectedRow = params.row;
                  previousState.userActionLabel = "Edit User";
                  previousState.showUserModal = true;
                });
              }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                setState((previousState) => {
                  previousState.showDeleteModal = true;
                  previousState.id = params.id;
                });
              }}
            />,
          ]
        : [],
  },
];
