import axios from "axios";
import { Button, ButtonGroup } from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import { FormField, showSection } from "./FormRender";
import { AssetsData } from "../AssetsProvider";
import {
  APP_BASE_URL,
  GET_DEVICE_TYPE_ASSET,
  GET_ASSET_FORM,
  ASSET_FORM,
  ASSET_ADDITIONAL_INFO,
  APP_BASE_URL_ALARM,
  GENERIC_ATTACHMENT,
} from "../../../Common/EndPoints";
import { getGeneralInfoForm, notifyAssetUpdate } from "./assetRegisterUtils";
import AttachPopUp from "./AttachPopUp";
import AfterSubmitPopup from "./AfterSubmitPopup";
import "./AssetRegister.scss";

export const Assets = () => {
  const location = useLocation();
  const defaultState = location?.state?.d;
  const defaultValues = defaultState ?? {};
  const navigate = useNavigate();
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [showAfterSubmissionPopup, setShowAfterSubmissionPopup] = useState(false);
  const { attachmentsData, DEVICE_ID, setDeviceId, uploadAttachments, setAttachmentsData, setFormId } =
    useContext(AssetsData);
  const editMode = useMemo(() => (DEVICE_ID || defaultState ? true : false), [DEVICE_ID, defaultState]);
  const generalInfoData = useRef({ id: "general_info", name: "General Info", data: [] });
  const [form, setForm] = useState(generalInfoData.data);
  const form_keys_map = useMemo(() => {
    const _keys = {};
    form?.forEach((field) => (_keys[field.key] = field));
    return _keys;
  }, [form]);
  const [subTabList, setSubTabList] = useState([]);
  const formsList = useMemo(() => [generalInfoData.current, ...subTabList], [subTabList]);
  const formMethods = useForm({ mode: "onSubmit", defaultValues });
  const deviceTypeCollectionMapIds = useRef(null);
  const [selectedTab, setSelectedTab] = useState("general_info");
  const formNavigation = useMemo(() => {
    let current_tab = null;
    let indexFound = 0;
    for (const form of formsList) {
      if (form.id === selectedTab) {
        current_tab = form;
        break;
      }
      indexFound++;
    }
    const nextFormIndex = indexFound + 1;
    const next_tab = nextFormIndex >= formsList.length ? null : formsList[nextFormIndex];
    return { current_tab, next_tab };
  }, [formsList, selectedTab]);
  const customerNameToId = useRef(null);
  const formValues = useRef();
  const responseId = useRef();

  const { handleSubmit, control, reset, getValues } = formMethods;
  const gearTypeValue = useWatch({ name: "gearType", control });
  function handleTabChange(tab) {
    setAttachmentsData([]);
    const _tab = tab === "additional_info" ? subTabList?.[0] : tab;
    if (selectedTab === _tab.id) return;
    formValues.current = { ...formValues.current, [selectedTab]: getValues() };
    setSelectedTab(_tab.id);
    setForm(_tab.data);
    if (_tab.id !== "general_info") {
      setFormId(_tab.id);
    } else {
      setFormId(null);
    }
    const storedValues = formValues.current[_tab.id];
    // console.log("stored values", storedValues);
    if (editMode && _tab.id !== "general_info" && !storedValues) {
      const url = `${APP_BASE_URL}${ASSET_ADDITIONAL_INFO}`;
      const form_id = _tab.id !== "general_info" ? _tab.id : null;
      const params = { device_id: DEVICE_ID, form_id };
      axios.get(url, { params }).then((res) => {
        const formResponseValues = res.data.data?.[0]?.data;
        // console.log("form response values", formResponseValues);
        responseId.current = res.data.data?.[0]?.id;
        reset(formResponseValues ?? storedValues);
      });
    } else {
      reset(storedValues ?? {});
    }
  }

  function handleCloseAttachmentPopup() {
    setShowAttachmentPopup(false);
  }

  function formSubmit(_values) {
    const values = {};
    form.forEach((field) => {
      if (field.type === "reactive-section" || field.type === "reactive_section") {
        const reactiveValue = field.reactiveValue;
        const parentKey = field.reactiveKey;
        const parentValues = _values[parentKey];
        if (showSection({ parentValues, reactiveValue })) {
          field.sectionFields.forEach((_field) => {
            if (_values[_field.key]) values[_field.key] = _values[_field.key];
          });
        }
        return;
      }
      if (field.type === "section") {
        field.sectionFields.forEach((_field) => {
          if (_values[_field.key]) values[_field.key] = _values[_field.key];
        });
        return;
      }
      if (_values[field.key]) values[field.key] = _values[field.key];
    });
    if (selectedTab === "general_info") {
      const { zone, division, station, gear_name, lat, lon, gearType } = values;
      const name = `${zone}_${division}_${station}_${gear_name}`;
      const type_id = deviceTypeCollectionMapIds.current?.[gearType].type_id;
      const customer_id = customerNameToId.current[station]?.toString();

      const params = { name, type_id, customer_id, lat, lon };

      let apiRequest = axios.post;
      let url = `${APP_BASE_URL}${ASSET_FORM}`;
      if (editMode) {
        apiRequest = axios.put;
        url += `/${DEVICE_ID}`;
      }

      apiRequest(url, values, { params }).then(
        (res) => {
          let deviceId = res.data.data.deviceId;
          setDeviceId(deviceId);
          // notifyAssetUpdate();
          if (attachmentsData?.length !== 0) {
            uploadAttachments(deviceId, attachmentsData, null).then((res) => {
              toast.success("Attachments Uploaded Successfully");
            });
          }
          setShowAfterSubmissionPopup(true);
        },
        (err) => {
          const errorMessage = err?.response?.data?.error?.message ?? "Something went wrong";
          toast.error(errorMessage);
        },
      );
      return;
    }
    if (!DEVICE_ID) {
      toast.error("Please fill General Info Form first");
      return;
    }
    let url = `${APP_BASE_URL}${ASSET_ADDITIONAL_INFO}`;
    const device_id = DEVICE_ID;
    let userId = localStorage.getItem("userId");
    const created_by = userId;
    const form_id = selectedTab;
    let params = {};
    let apiRequest = axios.post;
    if (responseId.current) {
      apiRequest = axios.put;
      const id = responseId.current;
      const updated_by = userId;
      const status = "PENDING";
      url += "/" + id;
      params = { id, updated_by, status };
    } else {
      params = { device_id, created_by, form_id };
    }
    const formValues = { ...values };
    const promises = [];
    form.forEach((field) => {
      if (field.type === "attachment") {
        if (formValues[field.key]?.length > 0) {
          const files = [...formValues[field.key]];
          const uploadedFilesIds = [];
          const uploadFiles = new FormData();
          files.forEach((file) => {
            if (file instanceof File) {
              uploadFiles.append("files", file);
            } else {
              uploadedFilesIds.push(file);
            }
          });
          const user_id = localStorage.getItem("userId");
          const params = { entity_id: field.key, entity: "form", category: form_id, user_id };
          const promise = axios.post(APP_BASE_URL_ALARM + GENERIC_ATTACHMENT, uploadFiles, { params }).then((res) => {
            formValues[field.key] = [...uploadedFilesIds, ...res?.data?.map((file) => file.id)];
            return res.data;
          });
          promises.push(promise);
        }
      }
    });
    Promise.all(promises).then((attachments) => {
      // console.log("attachments", attachments);
      apiRequest(url, formValues, { params }).then(
        (res) => {
          if (attachmentsData?.length !== 0) {
            let deviceId = res.data.data.deviceId;
            uploadAttachments(deviceId, attachmentsData, responseId.current).then((res) => {
              toast.success("Attachments Uploaded Successfully");
            });
          }
          setShowAfterSubmissionPopup(true);
        },
        (err) => {
          const errorMessage = err?.response?.data?.error?.message ?? "Something went wrong";
          toast.error(errorMessage);
        },
      );
    });
  }

  useEffect(() => {
    setAttachmentsData([]);
    const params = { with_map: true };
    axios.get(`${APP_BASE_URL}${GET_DEVICE_TYPE_ASSET}`, { params }).then(
      (res) => {
        const _mapIds = {};
        const deviceTypesList = res.data.data.map((_deviceType) => {
          _mapIds[_deviceType.label] = {
            type_id: _deviceType.typeid,
            collection_map_id: _deviceType?.collectionMaps?.[0]?.collectionmapid,
          };
          return _deviceType.label;
        });
        deviceTypeCollectionMapIds.current = _mapIds;
        let permissions = localStorage.getItem("permissions");
        if (permissions) permissions = JSON.parse(permissions);
        const { form, customer_name_to_id } = getGeneralInfoForm(deviceTypesList, permissions, editMode);
        customerNameToId.current = customer_name_to_id;
        generalInfoData.current.data = form;
        setForm(form);

        if (defaultState?.gearType) {
          setSubTabData(defaultState?.gearType);
          setDeviceId(defaultState?.deviceId);
        }
      },
      (err) => {
        toast.error("Error Loading Form");
      },
    );

    return () => {
      setAttachmentsData([]);
      setDeviceId(null);
    };
  }, []);

  useEffect(() => {
    if (!gearTypeValue || selectedTab !== "general_info") return;
    setSubTabData(gearTypeValue);
  }, [gearTypeValue]);

  function setSubTabData(_collection_map_id) {
    const collection_map_id = deviceTypeCollectionMapIds?.current?.[_collection_map_id]?.collection_map_id;
    if (!collection_map_id) {
      setSubTabList([]);
      return;
    }
    axios
      .get(`${APP_BASE_URL}${GET_ASSET_FORM}`, {
        params: { collection_map_id: collection_map_id, publish: true },
      })
      .then((res) => setSubTabList(res.data.data));
  }

  function handleNavigateToNextForm(status) {
    setShowAfterSubmissionPopup(false);
    if (status) {
      const _nextTab = formNavigation.next_tab;
      handleTabChange(_nextTab);
      return;
    }
    navigate("/assets");
  }

  const formRender = form?.map((field) => <FormField key={field.key} fieldData={field} />);
  const providerProps = { form_keys_map, editMode };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        style={{ display: "block", marginBottom: "1rem" }}
      >
        <Button
          variant={selectedTab === "general_info" ? "contained" : "outlined"}
          onClick={() => handleTabChange(generalInfoData.current)}
        >
          General Info
        </Button>
        {subTabList.length > 0 && (
          <Button
            variant={selectedTab !== "general_info" ? "contained" : "outlined"}
            onClick={() => handleTabChange("additional_info")}
          >
            Additional Info
          </Button>
        )}
      </ButtonGroup>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        style={{ display: "block", marginBottom: "1rem" }}
      >
        {selectedTab !== "general_info" &&
          subTabList.map((_subTab) => (
            <Button
              variant={selectedTab === _subTab.id ? "contained" : "outlined"}
              key={_subTab.id}
              onClick={() => handleTabChange(_subTab)}
            >
              {_subTab.name}
            </Button>
          ))}
      </ButtonGroup>
      <FormProvider {...formMethods} {...providerProps}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="assets__asset-register__form flex flex-col gap-2">{formRender}</div>
          <div className="flex justify-between" style={{ marginTop: "1rem" }}>
            <div className="attachBtn">
              <Button className="btn" variant="contained" onClick={() => setShowAttachmentPopup(true)}>
                <span> Attachments</span>
              </Button>
            </div>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
      {showAttachmentPopup && (
        <AttachPopUp
          open={true}
          label={"Add Attachments"}
          onClose={handleCloseAttachmentPopup}
          attachmentsEdit={false}
          inAdditionalForm={selectedTab !== "general_info"}
        />
      )}

      {showAfterSubmissionPopup && (
        <AfterSubmitPopup
          open={true}
          currentForm={formNavigation.current_tab?.name ?? null}
          nextForm={formNavigation.next_tab?.name ?? null}
          handleConfirMation={handleNavigateToNextForm}
        />
      )}
    </>
  );
};
export default Assets;
