import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import './DeleteModal.scss'
import { Transition } from "./ModalConstants";

const DeleteModal = ({ open, onClose, willDelete, deleteMessageFor,loading}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        className='mainDialog'
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className='flex dialogTitle' style={{borderBottom:'1px solid '}} id="responsive-dialog-title" >
          DELETE
          <CloseIcon
            className="pointer"
            sx={{ ml: "auto" }}
            onClick={() => {
              onClose(false);
            }}
          />
        </DialogTitle>

        <DialogContent className="dialogContent" style={{padding:'10px !important'}}>
          Do You Want to Delete This {deleteMessageFor} ?
        </DialogContent>

        <DialogActions className='dialogBtn'>
          <Button variant="outlined" autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            autoFocus
            onClick={willDelete}
          >
            {loading?<CircularProgress style={{width:'22px', height:'22px'}}/>:'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
