import { useRef, useContext, useEffect, useState } from "react";
import { AssetsData } from "../AssetsProvider";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  // Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  // Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { useImmer } from "use-immer";
import axios from "axios";
import "./AttachPopUp.scss";
// import Snackbar from "@mui/material/Snackbar";
import {
  APP_BASE_URL_ALARM,
  APP_BASE_URL,
  // UPLOAD_MULTIPLE_ATTACHMENTS,
  // UPLOAD_SINGLE_ATTACHMENT,
  // GET_ATTACHMENTS,
  DELETE_ATTACHMENT,
  GET_ATTACHMENT_BY_ID,
} from "../../../Common/EndPoints";
import { Transition } from "../../../Modal/ModalConstants";

/** Component Level Constants */
const ALLOWED_FILE_EXTENSIONS = ".png, .jpg, .jpeg, .webp";
const EXTENSION_NOT_SUPPORTED_MESSAGE = `Supported file extensions are ${ALLOWED_FILE_EXTENSIONS} for Gear Type Image`;

const initialState = {
  file: null,
  fileError: "",
  fileType: "",
  fileTypeError: false,
  uploadedOnServer: false,
  loading: false,
};

const attachmentTypes = [
  "Technical specs",
  "Data sheets",
  "user manual",
  "Maintenance manual",
  "RDSO specs",
  "Inspection report",
  "Drawing",
  "SIP",
  "CCP",
  "Maintenance schedule",
  "Gear Type Image",
];

/**
 * Filters an array to check if each object in an array contains the file extensions in the const ALLOWED_FILE_EXTENSIONS
 * @param {FileList {name: String}[] } FileList - The FileList to filter extensions from
 * @returns {boolean}
 */
function fileExtensionsCheck(FileList) {
  const bool = Array.from(FileList).every(({ name }) => {
    const nameSplit = "." + name.split(".")[1];
    const fileExtensionsArray = ALLOWED_FILE_EXTENSIONS.split(",").map((element) => element.trim());
    /** Split name into name and extensions from '.' then check if extension matches with the allowed extensions */
    if (fileExtensionsArray.includes(nameSplit)) return true;
    return false;
  });
  return bool;
}

export function AttachPopUp({
  open,
  label,
  onClose,
  attachmentsEdit,
  attachmentsUploadedMessage,
  inAdditionalForm,
  // popupSubmit
  // getAttachmentData,
  // attachmentData,
  // uploadedOnServer,
}) {
  const {
    attachmentsData,
    setAttachmentsData,
    // setDeviceId,
    // isEditMode,
    uploadAttachments,
    setAttachmentsEdit,
  } = useContext(AssetsData);
  const [state, setState] = useImmer(initialState);
  const [tableData, setTableData] = useImmer([]);
  const [uploadingLoading, setUploadingLoading] = useState(false);

  useEffect(() => {
    setTableData(attachmentsData);
  }, [attachmentsData]);
  const fileInput = useRef();

  const validate = () => {
    let isValid = false;
    if (state.file && state.fileType) {
      isValid = true;
    }
    setState((draft) => {
      draft.fileTypeError = !draft.fileType;
    });
    return isValid;
  };

  const onAdd = () => {
    if (validate()) {
      if (state.fileType !== "Gear Type Image" || fileExtensionsCheck(state.file)) {
        // const formData = new FormData();
        // formData.append("file", state.file[0]);
        // formData.append("docExtension", state.file[0].type);
        // formData.append("documentName", state.file[0].name);
        // formData.append("userId", localStorage.getItem("userId"));
        // const config = {
        //   headers: {
        //     "content-type": "multipart/form-data",
        //   },
        // };
        // console.log("form data", formData);

        // setTableData((prev) => [...prev, state]);
        // let rowData = []

        const rowData = Array.from(state["file"]).map((file) => {
          return { ...state, file: file };
        });
        // console.log("rowData", rowData);
        setState(initialState);
        fileInput.current.value = "";
        setTableData((previousValue) => [...previousValue, ...rowData]);
        // setAttachmentsData(tableData);

        //api call for upload
        // axios.post('http://rdpms.com:9091/api/Document/uploadFile', formData,config)
        // console.log("state", state);
        // getTableData(state);
      } else {
        setState((previousState) => {
          previousState.fileError = EXTENSION_NOT_SUPPORTED_MESSAGE;
        });
        fileInput.current.value = "";
      }
    } else {
      if (state.fileError) {
        fileInput.current.value = "";
        alert("File Types not supported");
      } else alert("Please Fill all required fields");
    }
  };

  const onDone = () => {
    if (tableData.length > 0) {
      // console.log('ondone true')
      // getAttachmentData(tableData);
      setAttachmentsData(tableData);
      if (attachmentsEdit) {
        setUploadingLoading(true);
        // console.log("table data", tableData);
        uploadAttachments(null, tableData)
          .then(({ data }) => {
            attachmentsUploadedMessage("success", `Uploaded ${data.length} Attachments Successfully`);
          })
          .catch((err) => {
            if (err.message) {
              // attachmentsUploadedMessage("warning", err.message);
            } else attachmentsUploadedMessage("error", "Failed to Upload Attachments");
          })
          .then(() => {
            setUploadingLoading(false);
            onClose(false);
          });
      } else {
        onClose(false);
      }
    } else {
      onClose(false);
    }
  };
  function handleRemoveRow(index, uploadedOnServer, attachmentId) {
    let removeRow = [...tableData];
    // removeRow[index]["loading"] = true;
    removeRow[index] = { ...removeRow[index], loading: true };
    // removeRow[index].loading = true;
    setTableData(removeRow);

    /**
     * bug: what is the need to write removedRow = [...removeRow]? but code doesn't works without it
     */
    let removedRow = [...removeRow];
    if (!uploadedOnServer) {
      removedRow.splice(index, 1);
      setTableData(removedRow);
    } else {
      const API_URL = `${APP_BASE_URL}${DELETE_ATTACHMENT}/${attachmentId}`;
      let deleteCount = 0;
      axios
        .delete(API_URL)
        .then((res) => {
          deleteCount += 1;
          removedRow.splice(index, 1);
          setTableData(removedRow);
          attachmentsUploadedMessage("success", `Attachments deleted Successfully`);
        })
        .catch(() => {
          // removedRow[index]["loading"] = false;
          removedRow[index] = { ...removedRow[index], loading: false };
          setTableData(removedRow);
        });
      // setAttachmentsData(tableData);
      // .then(() => {
      //   console.log("final then of delete attachment");
      //   // removedRow[index]["loading"] = false;
      // });
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="responsive-dialog-title" className="flex">
          {label}
          <CloseIcon
            className="pointer"
            sx={{ ml: "auto" }}
            onClick={() => {
              onClose(false);
            }}
          />
        </DialogTitle>
        <DialogContent className="mainContentContainer" dividers>
          <div className="contentContainer">
            <div className=" typeSelect">
              <FormControl fullWidth>
                <InputLabel id="type-select-label">Select Type *</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="demo-simple-select"
                  error={state.fileTypeError}
                  value={state.fileType}
                  label="Select Type *"
                  required
                  // size="small"
                  onChange={({ target: { value } }) => {
                    // if (
                    //   value !== "Gear Type Image" ||
                    //   !fileInput.current.value ||
                    //   fileExtensionsCheck(fileInput.current.value)
                    // ) {
                    setState((previousState) => {
                      previousState.fileError = "";
                      previousState.fileType = value;
                    });
                    // } else {
                    //   fileInput.current.value = "";
                    // }
                  }}
                >
                  {attachmentTypes.map((type, index) => {
                    return (
                      <MenuItem key={"attachment-type" + index} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })}

                  {/* <MenuItem value={"tender-doc"}>Tender Doc</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            {/* <div> */}
            <div className="fileSelect">
              {/* <InputLabel id="standard-label">Document Upload*</InputLabel> */}
              <input
                required
                // error={true}
                multiple
                onChange={({ target: { files } }) => {
                  if (state.fileType !== "Gear Type Image" || fileExtensionsCheck(files)) {
                    setState((previousState) => {
                      previousState.fileError = "";
                    });
                    setState((previousState) => {
                      previousState.file = files;
                    });
                  } else {
                    setState((previousState) => {
                      previousState.fileError = EXTENSION_NOT_SUPPORTED_MESSAGE;
                      previousState.file = null;
                    });
                  }
                }}
                // value={}
                ref={fileInput}
                type="file"
                id="myfile"
                name="myfile"
                accept={state.fileType !== "Gear Type Image" ? "*.*" : ALLOWED_FILE_EXTENSIONS}
                // value={state.file}
                // multiple
                className="pointer"
                style={{ padding: "0" }}
              />
            </div>
            {/* </div> */}
            <div className="btnContainer">
              <Button variant="contained" autoFocus onClick={onAdd}>
                ADD
              </Button>
            </div>
          </div>
          <div style={{ color: "red" }}>{state.fileError && <div>{state.fileError}</div>}</div>

          <hr />
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  {/* <th>Doc Type</th> */}
                  <th>Name</th>
                  <th>Category</th>
                  {/* <th>Size</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData.map(({ fileType, file, name, uploadedOnServer, loading, id, response_id, path }, index) => {
                  return (
                    <tr key={index}>
                      <td>{fileType}</td>
                      {/* <td>{row.docType}</td> */}
                      <td>
                        <div className="attachment-popup__table__file-name">{file?.["name"] ?? name ?? ""}</div>
                      </td>
                      <td>{response_id || inAdditionalForm ? "Additional Info" : "General Info"}</td>
                      {/* <td>{file?.["size"] / 1000 ?? 0} KB</td> */}
                      {/* <td>{row.size / 1000} KB</td> */}

                      <td>
                        <div className="flex justify-between">
                          {uploadedOnServer ? (
                            <a
                              href={`${APP_BASE_URL}${GET_ATTACHMENT_BY_ID}/${id}?isDownload=true`}
                              // target="_blank"
                            >
                              <IconButton>
                                <DownloadIcon />
                              </IconButton>
                            </a>
                          ) : (
                            <div></div>
                          )}
                          {loading ? (
                            <div className="lds-ring lds-ring--black">
                              <div></div>
                            </div>
                          ) : (
                            <IconButton
                              className="pointer"
                              onClick={() => handleRemoveRow(index, uploadedOnServer, id)}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* <div>{selectedFiles.files[0]}</div> */}
        </DialogContent>
        <DialogActions>
          <div className="attach-popup-actions flex alignCenter">
            {uploadingLoading && (
              <div className="flex alignCenter">
                <div>Uploading</div>
                <div className="lds-ring">
                  <div></div>
                </div>
              </div>
            )}
            <Button variant="contained" autoFocus onClick={onDone}>
              DONE
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AttachPopUp;
