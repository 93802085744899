import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Transition } from "../../../Modal/ModalConstants";
import { Chart as ChartJS, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
 } from "chart.js";
import { Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import "./BhmsChartPopup.scss";
import Link from '@mui/material/Link';

ChartJS.register( annotationPlugin, CategoryScale, 
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      }
    },
  };
  

function BhmsChartPopup({ open, data, deviceTypeId, onClose }) {
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    console.log("BhmsChartPopup --> ", data);
    let labels = [];
    for(let i=0;i<196;i++) {
      labels = [...labels, `Cell ${(i+1)}`]
    }
    const localData = {
      labels,
      datasets: [
        {
          label: 'Voltage (V)',
          data: labels.map((label, index) => Number(data?.data?.[`V${index+1}`])/1000),
          borderColor: 'rgb(106, 133, 229)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }      
      ]
    };
    setGraphData(localData);

  },[data]);
  
  const handleHistoryClick = (keyName) => {
      console.log("handleHistoryClick --> ", keyName , " deviceTypeId: ", deviceTypeId, "bhmsData: ", data);
      let [_stn, _deviceType, _deviceName] = data.data.PARENT_DEVICE.split("_"); //MVPO_BHMS_UPS1
      let deviceDetails = _deviceType + "_"+ _stn +"-"+_deviceName;
      const url = `/telemetry-history/${data.deviceId}_${deviceDetails}_${keyName}`;
      const searchParams = {
        device_type_id: deviceTypeId,
        start_date: new Date(1702402463067).toISOString(),
        end_date: new Date().toISOString()
      };
      console.log("handleHistoryClick --> url: ", url, " searchParams: ", searchParams);
      window.open(`${url}?device_type_id=${searchParams.device_type_id}&start_date=${searchParams.start_date}&end_date=${searchParams.end_date}&`, "_blank").focus();
  }
  
  return (
    <Dialog className="dialog-bhms-telemetry-view"
      fullScreen
      open={data.open}
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="responsive-dialog-title" className="flex">
      {data.viewType === "graphView" && <>{`Voltage Current Graph (${data.deviceName})`}</>}
      {data.viewType === "tabularView" && <>{`Battery Health Monitoring System (${data.deviceName})`}</>}
        <CloseIcon className="pointer" sx={{ ml: "auto" }} onClick={onClose}/>
      </DialogTitle>
      <DialogContent dividers>
        
      {data.viewType === "graphView" && <Chart type="line" options={options} data={graphData} /> }
      {data.viewType === "tabularView" && <div className="tabular-view">
        <table className="table-container">
          <thead>
            <tr className="without-heading">
              <td className="label" colSpan={3}>Battery Voltage</td>
              <td className="value" colSpan={1}><Link href="#" underline="hover" onClick={() => handleHistoryClick('VBAT')}>{Number(data?.data?.VBAT ?? 0)/1000} V</Link></td>
              <td colSpan={13}><span></span></td>
              <td className="label" colSpan={1}>Time</td>
              <td className="value" colSpan={2}>{new Date(Number(data?.data?.lastActivityTime))?.toLocaleString("en-GB")}</td>
            </tr>
            <tr className="without-heading">
              <td className="label" colSpan={3}>Battery Charging Current</td>
              <td className="value" colSpan={1}><Link href="#" underline="hover" onClick={() => handleHistoryClick('IC')}>{Number(data?.data?.IC ?? 0)/1000} A</Link></td>
              <td colSpan={1}><span></span></td>
              <td className="label" colSpan={3}>Battery Discharging Current</td>
              <td className="value" colSpan={1}><Link href="#" underline="hover" onClick={() => handleHistoryClick('ID')}>{Number(data?.data?.ID ?? 0)/1000} A</Link></td>
              <td colSpan={1}><span></span></td>
              <td className="label" colSpan={2}>Battery TH</td>
              <td className="value" colSpan={1}><Link href="#" underline="hover" onClick={() => handleHistoryClick('TEMP')}>{Number(data?.data?.TEMP ?? 0)/1000} °C</Link></td>
              <td colSpan={3}><span></span></td>
              <td className="label" colSpan={2}>Room TH</td>
              <td className="value" colSpan={1}><Link href="#" underline="hover" onClick={() => handleHistoryClick('RH')}>{Number(data?.data?.RH ?? 0)/1000} °C</Link></td>
            </tr>
            <tr className="heading">
              <td colSpan={20}>Battery Voltages(V)</td>
            </tr>
            {Array.from({length: 20}, (_, index) => index).map((_,rowIndex) => {
              return (<tr>
                    {[0,1,2,3,4,5,6,7,8,9].map((item,colIndex) => {
                    let cellValue = (colIndex*20)+rowIndex+1;
                    if(cellValue > 192) {
                      return (<>
                        <td className="label"><span></span></td>
                        <td className="value"><span></span></td>
                      </>);
                    }
                    let cellVoltage = Number(data?.data?.[`V${cellValue}`])/1000;
                    return (<>
                        <td className={`label ${cellVoltage <= data.threshholdVoltage ? 'error' : ''}`}>Cell {cellValue}</td>
                        <td className={`value ${cellVoltage <= data.threshholdVoltage ? 'error' : ''}`}><Link href="#" underline="hover" onClick={() => handleHistoryClick('V'+cellValue)}>{cellVoltage}</Link></td>
                      </>);              
                  })}
                </tr>);
            })}
            <tr>
              
            </tr>
          </thead>

        </table>
      </div> }        
      </DialogContent>      
      <DialogActions>
        <Button variant="contained" autoFocus onClick={onClose}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BhmsChartPopup;
