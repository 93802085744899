import { Autocomplete, Box, Button, ButtonGroup, Chip, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import {
  APP_BASE_URL,
  ASSET_MAINTENANCE_TABLE_API,
  GET_DEVICE_TYPE_ASSET,
  trimAssetName,
  tsToDateFormatter,
  tsToDateTimeFormatter,
} from "../../../../Common/EndPoints";
import DataTable from "../../../DataGrid/DataGrid";
import { assetMaintenaceColumns } from "../../DeviceConstants";
import "./MaintenaceTable";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ActiveAlarmsData } from "../../../Alarms/ActiveAlarms";
import { GridActionsCellItem } from "@mui/x-data-grid";
import HistoryIcon from "@mui/icons-material/History";
import { useLocation, useNavigate } from "react-router";
import { PeridioCityTerminologies } from "../MaintenanceConstants";
import { extractMasterRoleIds } from "../../../../Utils/Utils";
import { extractAllowedUserRoleList } from "../../../../Utils/Utils";
import { AutocompleteStationListPop } from "../../../AutocompleteStationListPop/AutocompleteStationListPop";

const initialTableState = {
  loader: true,
  rowsData: [],
  statusCount: {
    DUE: 0,
    OVERDUE: 0,
    DONE: 0,
    ALL: 0,
  },
};
const initialPaginantion = {
  pageIndex: 0,
  pageSize: 20,
  totalRowCount: 0,
};
const MS_IN_A_DAY = 86400000;
// const initialFilters =

const initialFilters = (navState) => {
  return {
    // startTs: new Date().getTime() - MS_IN_A_DAY * 2,
    startTs: null,
    endTs: null,
    status: navState,
    scheduleCode: null,
    deviceTypeIds: null,
    maintenanceType: null,
    customerIds: [],
  };
};
const initialDropdowns = {
  deviceTypeDD: [],
  scheduleCodeDD: [],
  scheduleStatusDD: [
    { value: "DUE", label: "DUE" },
    { value: "DONE", label: "DONE" },
    { value: "OVERDUE", label: "OVERDUE" },
  ],
  maintenaceCategoryDD: [
    { value: "SCHEDULED", label: "SCHEDULED" },
    { value: "ONDEMAND", label: "ONDEMAND" },
  ],
};
const scheduleStatusBtns = [
  { value: "DUE", label: "DUE" },
  { value: "DONE", label: "DONE" },
  { value: "OVERDUE", label: "OVERDUE" },
  { value: null, label: "ALL" },
];
const statusBtnColorModel = {
  DUE: {
    color: "orange",
    backColor: "white",
    hoverColor: "white",
    hoverBackColor: "orange",
  },
  OVERDUE: {
    color: "red",
    backColor: "white",
    hoverColor: "white",
    hoverBackColor: "red",
  },
  DONE: {
    color: "green",
    backColor: "white",
    hoverColor: "white",
    hoverBackColor: "green",
  },
  ALL: {
    color: "blue",
    backColor: "white",
    hoverColor: "white",
    hoverBackColor: "blue",
  },
};


function MaintenaceTable({ navState }) {
  // 
  let roleHr = JSON.parse(localStorage.getItem('roleHierarchies'))
  let MASTER_ROLE_IDS = extractMasterRoleIds(roleHr)
  const navigate = useNavigate();
  const [maintTableState, setMaintTableState] = useImmer(initialTableState);
  const [paginantion, setPaginantion] = useImmer(initialPaginantion);
  const [filters, setFilters] = useImmer(initialFilters(navState));
  const [dropDowns, setDropDowns] = useImmer(initialDropdowns);

  const { customerIdList, selectedCustomersIdName = [] } = useContext(ActiveAlarmsData);

  const permittedCustomerIds = useMemo(() => {
    const _list = selectedCustomersIdName;
    if (!_list) return [];
    return _list.map((customer) => ({ id: customer.customerId, value: customer.customerName }));
  }, [selectedCustomersIdName?.length]);

  //   useLayoutEffect(()=>{
  // if(!RoleIdList){
  //   RoleIdList= extractAllowedUserRoleList(roleHierarchies)
  // }
  //   },[])
  const getRoleIdList = useMemo(() => {
    let roleHierarchies = JSON.parse(localStorage.getItem("roleHierarchies"));
    let list = extractAllowedUserRoleList(roleHierarchies)
    console.log('list', list)
    return list

  }, [])
  useEffect(() => {
    setFilters((state) => {
      state.customerIds = state.customerIds.filter((customer) => customerIdList.includes(customer.id));
    });
  }, [customerIdList]);

  useEffect(() => {
    setMaintTableState((state) => {
      state.loader = true;
    });
    let url = `${APP_BASE_URL}${ASSET_MAINTENANCE_TABLE_API}?page=${paginantion.pageIndex + 1}&pageSize=${paginantion.pageSize
      }&withResponse=true`;
    const _customerIds = filters.customerIds
      ?.map((customer) => customer.id)
      ?.filter((id) => customerIdList.includes(id));
    const customerIds = _customerIds.length > 0 ? _customerIds.toString() : customerIdList.toString();
    let roleIdList = filters?.roleId?.length > 0 ? filters.roleId.map((role) => role.id).toString() : MASTER_ROLE_IDS.toString()
    let params = {
      ...filters,
      status: filters?.status ?? null,
      maintenanceType: filters?.maintenanceType?.value ?? null,
      customerIds,
      deviceTypeIds: filters?.deviceTypeIds?.value ?? null,
      // roleIdList: MASTER_ROLE_IDS.toString(),
      withScheduleAssignment: true,
      roleIdList
    };
    delete params["roleId"];

    // console.log('filtyers', filters)
    axios.get(url, { params }).then((res) => {
      setPaginantion((state) => {
        state.totalRowCount = res.data.data.totalElements;
      });
      const genericMapCount = res.data.data.genericMap[0];
      const totalCount = Object.values(genericMapCount).reduce((accum, value) => {
        return accum + value;
      }, 0);
      const statusCountObj = { ...initialTableState.statusCount, ...genericMapCount, ALL: totalCount };
      let Response = res.data.data.content.map((row, i) => {
        let assetName = trimAssetName(row.gearName);
        return { ...row, id: i, gearName: assetName, periodicity: PeridioCityTerminologies[row.periodicity] ?? "", designation: row.designation ? row.designation : (row.scheduleAssignment.userRoleName ?? '-') };
      });
      console.log('maint data 0', Response)
      setMaintTableState((state) => {
        state.rowsData = Response;
        state.loader = false;
        state.statusCount = statusCountObj;
      });
    });
  }, [paginantion.pageIndex, paginantion.pageSize, filters]);

  useEffect(() => {
    getDeviceTypes();
  }, []);

  const assetMaintenaceColumns = useMemo(() => {
    return [
      {
        field: "editActions",
        type: "actions",
        minWidth: 80,
        flex: 1,
        hideable: false,
        getActions: (params) => [
          <Tooltip placement="bottom" title="Asset maintenance history" disableInteractive>
            <GridActionsCellItem
              icon={<HistoryIcon />}
              label="Aseet maintenance history"
              onClick={(event) => {
                let navigationState = {
                  asset: {
                    assetName: params.row.gearName,
                    assetId: params.row.assetId,
                  },
                  tsFilters: {
                    startTs: filters.startTs ?? null,
                    endTs: filters.endTs ?? null,
                  },
                  scheduleCode: params.row.scheduleCode,
                };

                navigate("/maintenanace-history", { state: navigationState });
              }}
            />
          </Tooltip>,
        ],
      },
      {
        field: "gearName",
        headerName: "Asset Name",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "scheduleCode",
        headerName: "Schedule Code",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "maintenanceCategory",
        headerName: "Maintenance Category",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "periodicity",
        headerName: "Periodicity",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "status",
        headerName: "Schedule Status",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "expectedDt",
        headerName: "Next Due Date",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
        ...tsToDateFormatter,
      },
      {
        field: "completedDt",
        headerName: "Last Done Date",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
        ...tsToDateTimeFormatter,
      },
      {
        field: "completedByName",
        headerName: "Last Done By",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
      {
        field: "designation",
        headerName: "Designation",
        sortable: true,
        minWidth: 110,
        hideable: true,
        flex: 1,
      },
    ];
  }, []);

  const getDeviceTypes = () => {
    let url = `${APP_BASE_URL}${GET_DEVICE_TYPE_ASSET}`;
    axios.get(url).then((res) => {
      let deviceTypes = res.data.data.map((type) => {
        return {
          value: type.typeid,
          label: type.type,
          alias: type.label,
        };
      });
      setDropDowns((prev) => {
        prev.deviceTypeDD = deviceTypes;
      });
    });
  };

  const handleChange = (value, stateName) => {
    setFilters((prev) => {
      prev[stateName] = value;
    });
  };

  return (
    <>
      <div className="maintenanceTabFilters">
        <Autocomplete
          size="small"
          className="filter-input auto-complete"
          multiple
          disableCloseOnSelect
          options={permittedCustomerIds}
          renderInput={(params) => (
            <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
          )}
          getOptionLabel={(customer) => customer.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={filters?.customerIds}
          onChange={(_, newValue) => {
            handleChange(newValue, "customerIds");
          }}
          renderTags={(value, getTagProps) => {
            return (
              <div>
                <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
                {value.length > 1 && (
                  <AutocompleteStationListPop title={value.map((stn) => stn?.value ?? "").toString()}>
                    <Chip size="small" label={`+${value.length - 1}`} />
                  </AutocompleteStationListPop>
                )}
              </div>
            );
          }}
        />
        <Autocomplete
          className="filter-input"
          size="small"
          disablePortal
          id="combo-box-demo"
          value={filters.deviceTypeIds ?? null}
          options={dropDowns.deviceTypeDD}
          getOptionLabel={(params) => {
            return params.alias;
          }}
          onChange={(_, newValue) => {
            handleChange(newValue, "deviceTypeIds");
          }}
          // sx={{ width: 300 }}
          renderInput={(params) => <TextField size="small" {...params} label="Asset Type" />}
        />
        {/* <Autocomplete
          className="filter-input"
          size="small"
          disablePortal
          id="combo-box-demo"
          value={filters.status ?? null}
          options={dropDowns.scheduleStatusDD}
          onChange={(_, newValue) => {
            handleChange(newValue, "status");
          }}
          // sx={{ width: 300 }}
          renderInput={(params) => <TextField size="small" {...params} label="Schedule Status" />}
        /> */}
        <Autocomplete
          className="filter-input"
          size="small"
          disablePortal
          id="combo-box-demo"
          value={filters.maintenanceType ?? null}
          options={dropDowns.maintenaceCategoryDD}
          onChange={(_, newValue) => {
            handleChange(newValue, "maintenanceType");
          }}
          // sx={{ width: 300 }}
          renderInput={(params) => <TextField size="small" {...params} label="Maintenance Type" />}
        />
        <Autocomplete
          className="filter-input"
          size="small"
          disablePortal
          id="combo-box-demo"
          value={filters.roleId ?? []}
          options={getRoleIdList}
          onChange={(_, newValue) => {
            handleChange(newValue, "roleId");
          }}
          getOptionLabel={(role) => role.value}
          multiple
          // sx={{ width: 300 }}
          renderInput={(params) => <TextField size="small" {...params} label="User Role" />}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            size="small"
            // disableFuture
            className="filter-input"
            // sx={{}}
            label="From Date"
            openTo="day"
            views={["year", "month", "day"]}
            value={filters?.startTs}
            onChange={(newValue) => {
              if (Date.parse(newValue && newValue["$d"])) {
                let ts = newValue["$d"].getTime();
                handleChange(ts, "startTs");
              } else {
                if (newValue === null) {
                  handleChange(newValue, "startTs");
                }
              }
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <DatePicker
            // disableFuture
            className="filter-input"
            label="To Date"
            openTo="day"
            views={["year", "month", "day"]}
            value={filters?.endTs}
            onChange={(newValue) => {
              if (Date.parse(newValue && newValue["$d"])) {
                let ts = newValue["$d"].getTime();
                handleChange(ts, "endTs");
              } else {
                if (newValue === null) {
                  handleChange(newValue, "endTs");
                }
              }
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        <Button variant="outlined" onClick={() => setFilters(initialFilters(null))}>
          Clear Filters
        </Button>
      </div>
      <div className="status-filter-count-btn-group">
        <ButtonGroup variant="outlined" aria-label="outlined primary button group">
          {scheduleStatusBtns.map((btn) => {
            let btnStyle = {
              color: statusBtnColorModel[btn.label].color,
              backgroundColor: statusBtnColorModel[btn.label].backColor,
              fontWeight: "600",
              // ":hover": { backgroundColor: statusBtnColorModel[btn.label].hoverBackColor, color: statusBtnColorModel[btn.label].hoverColor },
            };
            if (btn.value === filters.status) {
              btnStyle = {
                backgroundColor: statusBtnColorModel[btn.label].hoverBackColor,
                color: statusBtnColorModel[btn.label].hoverColor,
                ":hover": {
                  backgroundColor: statusBtnColorModel[btn.label].hoverBackColor,
                  color: statusBtnColorModel[btn.label].hoverColor,
                },
              };
            }
            return (
              <Button
                sx={btnStyle}
                onClick={() => {
                  handleChange(btn.value, "status");
                }}
                key={btn.label}
              >{`${btn.label}(${maintTableState.statusCount[btn.label]})`}</Button>
            );
          })}
        </ButtonGroup>
      </div>
      <div className="maintenanceTabTable">
        <DataTable
          tableInfo={{
            id: `devices-table`,
            label: `Devices`,
            requireAuthSign: false,
            timestamp: { startTs: null, endTs: null },
          }}
          loading={maintTableState.loader}
          rows={maintTableState.rowsData}
          columns={assetMaintenaceColumns}
          paginationMode={"server"}
          // rowCount={50}
          rowCount={paginantion.totalRowCount}
          pageIndex={paginantion.pageIndex}
          pageChange={(index) => {
            setPaginantion((prev) => {
              prev.pageIndex = index;
            });
          }}
          pageSize={paginantion.pageSize}
          pageSizeChange={(size) => {
            setPaginantion((prev) => {
              prev.pageSize = size;
            });
          }}
        />
      </div>
    </>
  );
}

export default MaintenaceTable;
