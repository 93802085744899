import axios from "axios";
import { APP_BASE_URL, GET_USER_ASSIGNED_ASSETS, GET_USER_LIST } from "../../Common/EndPoints";

export function getUsersWithAssets() {
  return axios.get(`${APP_BASE_URL}${GET_USER_LIST}`).then(({ data: { data } }) => data);
}

export function getUserIdList(usersList) {
  const userArray = [];
  for (let userId in usersList) {
    userArray.push(userId);
  }
  return userArray;
}

export function getModalTitle({ type, usersList, user1, user2 }) {
  switch (type) {
    case "Transfer": {
      return `This will transfer following assets from ${usersList[user1]} to ${usersList[user2]}`;
    }
    case "Reclaim": {
      return `This will reclaim all assets from ${usersList[user2]} to ${usersList[user1]}`;
    }
    default: {
      return "";
    }
  }
}
export function getUserAssignedAssets(userId) {
  return axios.get(`${APP_BASE_URL}${GET_USER_ASSIGNED_ASSETS}${userId}`).then(({ data: { data } }) => data);
}
