import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export const FMEView = ({ data, handleRemove_ }: any) => {
  return (
    <div className="alarm__fmea-view">
      <div className="fmea-list">
        {data.map((fmea: any, index: number) => {
          return (
            <div key={"fmea-list-item" + fmea.id} className="fmea-list__row">
              <div className="data--container">
                <p className="mode">
                  <span>Mode:</span> {fmea?.mode?.value}
                </p>
                <p className="cause">
                  <span>Cause:</span> {fmea?.cause?.value}
                </p>
                <p className="effect">
                  <span>Effect:</span> {fmea?.effect?.value}
                </p>
              </div>
              {handleRemove_ ? (
                <RemoveCircleOutlineIcon className="cursor-pointer" onClick={() => handleRemove_?.(index)}>
                  clear
                </RemoveCircleOutlineIcon>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FMEView;
