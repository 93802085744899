import { createTheme } from "@mui/system";


export const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#673ab7;',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#673ab7;',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });
  
  export const themeBgColor = '#1976d2';