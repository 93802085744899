const utilisedAssetTableColumnPM = [
  {
    field: "assetName",
    headerName: "Asset Name",
    sortable: true,
    width: 130,
    flex: 1,
  },
  {
    field: "total_ot",
    headerName: "Total Runtime",
    sortable: true,
    width: 130,
    flex: 1,
  },
  {
    field: "avg_ot",
    headerName: "Average Runtime(S)",
    sortable: true,
    width: 130,
    flex: 1,
    vallueGetter: (params) => {
    }
  },
  {
    field: "cnt_obstruction_op",
    headerName: "Obstruction Count",
    sortable: true,
    width: 130,
    flex: 1,
  },
  {
    field: "cnt_normal_op",
    headerName: "Normal Count",
    sortable: true,
    width: 130,
    flex: 1,
  },
]
const utilisedAssetTableColumnTC = [
  {
    field: "assetName",
    headerName: "Asset Name",
    sortable: true,
    width: 130,
    flex: 1,
  },
  {
    field: "total_occupied_duration",
    headerName: "Total Occupied Duration",
    sortable: true,
    width: 130,
    flex: 1,
  },


  {
    field: "cnt_occupied",
    headerName: "Ocuupied Count",
    sortable: true,
    width: 130,
    flex: 1,
  },
]

export const mostUtilizedTableColumn = {
  'point-machine': utilisedAssetTableColumnPM,
  'track-circuit': utilisedAssetTableColumnTC
}
export const barChartHightModulus = 95 / 40

export const UtilizationLabel = {
  'track-circuit': 'Track Circuit Utilization',
  'point-machine': 'Point Machine Utilization'
}

export function convertSecondsToDuration(seconds) {

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let duration = '';
  if (hours > 0) {
    duration += hours + 'h ';
  }
  if (minutes > 0) {
    duration += minutes + 'm ';
  }
  if (remainingSeconds > 0) {
    duration += remainingSeconds + 's';
  }
  return duration.trim();
}

export function convertSecondsToHours(seconds) {
  const hours = Math.floor(seconds / 3600);
  return hours;
}

export function convertHoursToDuration(hours) {
  const minutes = Math.floor(hours * 60);
  const remainingSeconds = Math.floor((hours * 3600) % 60);

  let duration = '';
  if (hours > 0) {
    duration += hours + 'h ';
  }
  if (minutes > 0) {
    duration += minutes + 'm ';
  }
  if (remainingSeconds > 0) {
    duration += remainingSeconds + 's';
  }

  return duration.trim();
}