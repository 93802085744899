import { useDispatch, useSelector } from "react-redux";
import { getAlarmNotificationSound, updateAlarmNotificationSound } from "../../../features/Alarms/alarmsSlice";
import { Tooltip } from "@mui/material";

export const AlarmNotificationSound = () => {
  const alarmSound = useSelector(getAlarmNotificationSound);
  const dispatch = useDispatch();
  function toggleSoundChange() {
    dispatch(updateAlarmNotificationSound(!alarmSound));
  }

  const renderIcon = alarmSound ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        <path d="M1 13.857v-3.714a2 2 0 0 1 2-2h2.9a1 1 0 0 0 .55-.165l6-3.956a1 1 0 0 1 1.55.835v14.286a1 1 0 0 1-1.55.835l-6-3.956a1 1 0 0 0-.55-.165H3a2 2 0 0 1-2-2Z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.5 7.5S19 9 19 11.5s-1.5 4-1.5 4m3-11S23 7 23 11.5s-2.5 7-2.5 7"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 15 15">
      <path
        fill="currentColor"
        d="m3.5 10.494l.257-.429l-.119-.07H3.5v.5Zm0-5.996v.5h.138l.12-.071l-.258-.429Zm5-2.998H9a.5.5 0 0 0-.757-.429L8.5 1.5Zm0 11.992l-.257.429A.5.5 0 0 0 9 13.492h-.5Zm2.94-7.763l-.354-.353l-.707.707l.354.354l.706-.708Zm2.12 3.534l.354.353l.707-.707l-.354-.353l-.707.707Zm.708-2.826l.353-.354l-.707-.707l-.353.353l.707.708Zm-3.535 2.119l-.354.353l.707.707l.354-.353l-.707-.707ZM3.5 9.994h-2v1h2v-1Zm-2 0a.499.499 0 0 1-.5-.5H0c0 .83.671 1.5 1.5 1.5v-1Zm-.5-.5V5.498H0v3.998h1Zm0-3.997c0-.276.223-.499.5-.499v-1c-.829 0-1.5.67-1.5 1.5h1Zm.5-.499h2v-1h-2v1Zm2.257-.071l5-2.998l-.514-.858l-5 2.998l.514.858ZM8 1.5v11.992h1V1.5H8Zm.757 11.563l-5-2.998l-.514.858l5 2.998l.514-.858Zm1.976-6.626l2.827 2.826l.707-.707l-2.828-2.827l-.707.708Zm2.828-.708l-2.828 2.827l.707.707l2.828-2.826l-.707-.708Z"
      />
    </svg>
  );
  const tooltipTitle = `${alarmSound ? "Hide" : "Play"} Notification Sound`;

  return (
    <Tooltip placement="left" title={tooltipTitle} disableInteractive>
      <div className="cursor-pointer" style={{ lineHeight: "0" }} onClick={toggleSoundChange}>
        {renderIcon}
      </div>
    </Tooltip>
  );
};

export default AlarmNotificationSound;
