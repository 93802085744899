import React from 'react'
import { trimAssetName } from '../../../Common/EndPoints'
import './GisPopup.scss'



function GisPopup({ deviceData }) {
    const { latitude, longitude, name, deviceType, deviceid, additionaldetail } = deviceData
    const { serial, make, model } = additionaldetail
    const [stnName, assetName] = trimAssetName(name).split('-')
    // console.log(deviceData)
    const info = {
        "NAME": assetName,
        'Asset Type': deviceType.type,
        "Station": stnName,
        'Cordinates': `${latitude}°, ${longitude}°`,
        'Serial Number': serial ?? '-',
        "Make": make ?? '-',
        "Model": model ?? '-'
    }
    console.log(info)
    return (

        <div className='gis-popup-container'>
            {Object.entries(info).map(([key, val]) => {
                return <div className='infoRow'><span className='key'>{key}</span><span className='value'>{val}</span></div>
            })}
        </div>
    )
}

export default GisPopup
