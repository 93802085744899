import React, { useLayoutEffect, useState } from 'react'
import './YardTooltip.scss'
import { ColumnKeys, ColumnsType } from '../Constant/Constant';
const COLUMN_TYPES = ColumnsType();
const COLUMN_KEYS = ColumnKeys();

const KEY_ALIAS = {
  DRYCH: "STATUS",
};

function columnValue(data, key) {
  if (key === "TPR") {
    console.log("tooltip key", data, key);
  }
  switch (COLUMN_TYPES[key]) {
    case "divide": {
      if (!data[key] || data[key] === "***") return "***";
      else return Number(data[key]) / 1000;
    }
    case "simple": {
      return data[key] ?? "***";
    }
    default:
      return "***";
  }
}
function YardTooltip({ uniqueId, packet, specialTooltipCase }) {
    console.log('tool', uniqueId, packet, specialTooltipCase)

    const [toolTipKeys, seTooltipKeys] = useState([]);

    const gearType = packet.PARENT_DEVICE.split("_")[1];
    useLayoutEffect(() => {
        if (packet && packet?.PARENT_DEVICE) {
            if (specialTooltipCase) {
              console.log("specialTooltipCase", specialTooltipCase);
              seTooltipKeys(specialTooltipCase.keys);
            } else {
              if (gearType === "SG") {
                const subGear = packet.SUBGEAR;
                seTooltipKeys(COLUMN_KEYS[gearType][subGear === "SHUNT" || subGear === "HG" ? subGear : "NORMAL"]);
              } else if (gearType === "BHMS") {
                seTooltipKeys(COLUMN_KEYS[uniqueId]);
              } else {
                seTooltipKeys(COLUMN_KEYS[gearType + packet.STN] ?? COLUMN_KEYS[gearType]);
              }
            }
            // console.log('in tool', gearType, packet)

        }
    }, [packet]);
    return (
      <div id="yard-tooltip" className={gearType + packet.STN === "PMVS" ? "PMVS" : ""}>
        <div className="row">
          <span className="flex-1">NAME</span> <span className="flex-1">{packet?.NAME ?? "no data"}</span>{" "}
        </div>
        <div className="row">
          <span className="flex-1">LOC</span> <span className="flex-1">{packet?.LOC}</span>{" "}
        </div>

        {packet &&
          toolTipKeys &&
          toolTipKeys.map((key) => {
            return (
              <div className="row dataRow">
                <span className="flex-1">{KEY_ALIAS?.[key] ?? key}</span>{" "}
                <span className="flex-1">{columnValue(packet, key)}</span>{" "}
              </div>
            );
          })}
        <div className="row">
          <span className="flex-1">DATE</span> <span className="flex-1">{packet?.DATE}</span>{" "}
        </div>
        <div className="row">
          <span className="flex-1">TIME</span> <span className="flex-1">{packet?.TIME.split("-").join(":")}</span>{" "}
        </div>
      </div>
    );
}

export default YardTooltip
