import React, { useEffect } from "react";
import Loader from "../../Loader/Loader";
import {
  getAnnotaionsForPointGraph,
  getMaxValueOfSgYaxis,
  getTransformedSgHistorydata,
  retrieveTransformedValue,
} from "./BhmsGraphUtils";
import { useImmer } from "use-immer";
import { DUMMY_RES, STATIC_KEYS_INFO, TOTAL_POSSIBLE_PM_KEYS } from "./BhmsGraphConfig";
import { Line } from "react-chartjs-2";

const BhmsHistoryGraph: React.FC<any> = ({ loading, graphState, columns, deviceId, liveData }) => {
  // console.log("graphState", graphState);
  const graph_raw_data = graphState?.data?.data?.data?.content ?? [];
  console.log("graph_raw_data", graph_raw_data);

  return (
    <div style={{ height: "100%" }}>
      <PointHistoryGraph historyData={graphState?.data?.data?.data?.content ?? []} isLoading={loading} />
    </div>
  );
};

const initChartData = {
  labels: [],
  datasets: [
    {
      label: "No Data Available",
      data: [],
      borderColor: "rgba(75,192,192,1)",
      borderWidth: 2,
      fill: false,
    },
  ],
};
type StaticKeyType = keyof typeof STATIC_KEYS_INFO;
const PointHistoryGraphComponent: React.FC<any> = ({ transformedApiHistoryData, keysVirtualThreshold, spanGaps }) => {
  const [pointChartData, setPointChartData] = useImmer(initChartData);

  const pointChartAnnotations = getAnnotaionsForPointGraph(keysVirtualThreshold);

  useEffect(() => {
    const keyDataObj: any = {};
    let pointChartLabelTimeStamps: any = [];
    Object.entries(transformedApiHistoryData).forEach(([ts, keyValPacket]: any) => {
      pointChartLabelTimeStamps.push(new Date(Number(ts)).toLocaleString("en-GB"));
      function pushDataForKey(key: string) {
        if (keyDataObj[key]) {
          keyDataObj[key].push(keyValPacket[key]);
        } else {
          keyDataObj[key] = [keyValPacket[key]];
        }
      }
      TOTAL_POSSIBLE_PM_KEYS.forEach((key) => {
        if (Array.isArray(key)) {
          for (const _key of key) {
            pushDataForKey(_key);
          }
        } else {
          pushDataForKey(key);
        }
      });
    });

    function getChartDataSet(key: StaticKeyType) {
      return {
        label: STATIC_KEYS_INFO?.[key]?.label ?? key,
        data: keyDataObj[key],
        dataKey: key,
        borderColor: STATIC_KEYS_INFO?.[key]?.lineColor ?? "grey",
        backgroundColor: STATIC_KEYS_INFO?.[key]?.lineColor ?? "grey",
        borderWidth: 1.3,
        fill: false,
        pointRadius: 1.5,
        pointHoverRadius: 1.5,
        spanGaps: spanGaps,
      };
    }
    const pointChartDataSet: any[] = [];

    for (const key of TOTAL_POSSIBLE_PM_KEYS) {
      if (Array.isArray(key)) {
        for (const _key of key) {
          pointChartDataSet.push(getChartDataSet(_key));
        }
      } else {
        pointChartDataSet.push(getChartDataSet(key));
      }
    }

    setPointChartData((prev) => {
      prev.labels = pointChartLabelTimeStamps;
      prev.datasets = pointChartDataSet;
    });
  }, [transformedApiHistoryData]);

  const pointChartOption: any = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
      callbacks: {
        intersect: function () { },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear",
        position: "left",
        max: getMaxValueOfSgYaxis(),
        min: 0,
        scaleOverride: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 2,
          font: {
            size: 10,
          },
          autoSkip: false,
          callback: (value: any) => {
            let tickLabel = "";
            Object.entries(keysVirtualThreshold).forEach(([key, virtualThresholds]: any) => {
              const startTickVal = virtualThresholds.virtualMin;
              const endTick = virtualThresholds.virtualMax;
              const tickValForKey = (startTickVal + endTick) / 2;
              const tickUnit = STATIC_KEYS_INFO?.[key]?.unit ?? "";
              const maxtickValueToBeShownOnGraph = STATIC_KEYS_INFO?.[key]?.actualUnitsForYaxis ?? "";
              if (startTickVal === value) {
                tickLabel = `${0} ${tickUnit}`;
              }
              if (endTick === value) {
                tickLabel = `${maxtickValueToBeShownOnGraph} ${tickUnit}`;
              }
              if (STATIC_KEYS_INFO?.[key]?.custom_range) {
                if (startTickVal === value) {
                  tickLabel = `${STATIC_KEYS_INFO?.[key]?.custom_range.min} ${tickUnit}`;
                }
                if (endTick === value) {
                  tickLabel = `${STATIC_KEYS_INFO?.[key]?.custom_range.max} ${tickUnit}`;
                }
              }
              if (tickValForKey === value) {
                tickLabel = STATIC_KEYS_INFO?.[key]?.box_label ?? STATIC_KEYS_INFO?.[key]?.label ?? key;
              }
            });

            return tickLabel;
          },
        },
      },
    },
    plugins: {
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
        align: "right",
      },
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const key: any = context.dataset.dataKey;
            const value = context.raw;
            const formattedValue = retrieveTransformedValue(key, value, keysVirtualThreshold);
            return `${STATIC_KEYS_INFO?.[key]?.label ?? key} : ${formattedValue}`;
          },
        },
      },
      datalabels: {
        formatter: function () {
          return "";
        },
      },
      annotation: {
        annotations: pointChartAnnotations,
      },
    },
  };
  console.log("pointChartData", pointChartData);
  return <Line data={pointChartData} options={pointChartOption} />;
};

const PointHistoryGraph: React.FC<any> = ({ historyData, isLoading, columns }) => {
  if (isLoading === true || isLoading === undefined) {
    return <Loader />;
  } else {
    const { transformHistoryData, keysVirtualThreshold } = getTransformedSgHistorydata(historyData);
    console.log("transformHistoryData", transformHistoryData);
    return (
      <div className="historyChartViewComponentContainer" style={{ height: "100%" }}>
        <PointHistoryGraphComponent
          transformedApiHistoryData={transformHistoryData}
          keysVirtualThreshold={keysVirtualThreshold}
          spanGaps={true}
        />
      </div>
    );
  }
};

export default BhmsHistoryGraph;
