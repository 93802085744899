import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  resetFormValues,
  resetRulesFormErrors,
  ruleFormErrorsSelector,
  ruleFormValuesSelector,
  ruleTypeSelector,
  updateRulesActionType,
  updateRulesFormErrors,
  updateRulesFormValues,
  useCreateRuleMutation,
  useEditRuleMutation,
} from "../../alarmRulesSlice";

const FLOAT_VALUES = /^[+-]?\d+(\.\d+)?$/;

function validateForm(formValues) {
  const errorObject = {};
  let errorsFound = false;
  Object.entries(formValues).forEach(([key, value]) => {
    if (key === "clear_keys" && formValues.clear_keys.length === 0) return;
    if (key === "clear_meta" && formValues.clear_meta.length === 0) return;
    if (key === "create_condition" && formValues.create_keys.length < 2) return;
    if (key === "clear_condition" && formValues.clear_keys.length < 2) return;
    if (key === "mqtt_subgear") return;
    if (key === "device_ids") return;
    if (!Array.isArray(value)) {
      //to avoid value === false or value === 0 which we may need in form values
      if (value === null || value === "" || value === undefined) {
        errorObject[key] = true;
        errorsFound = true;
      } else {
        errorObject[key] = false;
      }
    } else {
      let error = false;
      if (value.length === 0) {
        error = true;
        errorsFound = true;
      }
      value.forEach((field) => {
        Object.entries(field).forEach(([_key, value]) => {
          if (_key === "min" || _key === "max") {
            if (!value?.toString().match(FLOAT_VALUES) && isNaN(value)) {
              error = true;
              errorsFound = true;
            }
          }
          //to avoid value === false or value === 0 which we may need in form values
          if (value === null || value === "" || value === undefined) {
            error = true;
            errorsFound = true;
          }
        });
      });
      errorObject[key] = error;
    }
  });
  if (!errorsFound) return 200;
  else return errorObject;
}

function transformFormValues(formValues) {
  const newObject = { ...formValues };
  if (newObject.create_meta) {
    const tempObj = {};
    newObject.create_meta.forEach((field) => {
      tempObj[field.key] = field.value;
    });
    newObject.create_meta = tempObj;
  }
  if (newObject.clear_meta) {
    const tempObj = {};
    newObject.clear_meta.forEach((field) => {
      tempObj[field.key] = field.value;
    });
    newObject.clear_meta = tempObj;
  }
  if (newObject.customer_id) {
    newObject.customer_id = newObject.customer_id.customerId;
  }
  if (newObject.device_type_id) {
    newObject.device_type_id = newObject.device_type_id.id;
  }
  if (newObject.clear_keys) {
    newObject.clear_keys = newObject.clear_keys.map((field) => ({
      ...field,
      min: parseFloat(field.min),
      max: parseFloat(field.max),
    }));
  } else {
    delete newObject.clear_keys;
  }
  if (newObject.create_keys) {
    newObject.create_keys = newObject.create_keys.map((field) => ({
      ...field,
      min: parseFloat(field.min),
      max: parseFloat(field.max),
    }));
  }

  return newObject;
}

export function useRulesFormValues() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ruleType = useSelector(ruleTypeSelector);
  const formValues = useSelector(ruleFormValuesSelector);
  const formErrors = useSelector(ruleFormErrorsSelector);
  const [createRule] = useCreateRuleMutation();
  const [editRule] = useEditRuleMutation();
  const location = useLocation();
  const actionButtonText = location.pathname === "/alarm-rules/edit" ? "Edit Rule" : "Create Rule";
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  function closeConfirmationPopup() {
    setShowConfirmationPopup(false);
  }

  function openConfirmationPopup() {
    setShowConfirmationPopup(true);
  }

  useEffect(() => {
    if (location.pathname === "/alarm-rules/edit") {
      dispatch(updateRulesActionType("edit"));
    } else if (location.pathname === "/alarm-rules/create") {
      dispatch(updateRulesActionType("create"));
    }
  }, []);

  const handleFilterChange = useCallback((field, value) => {
    const updateValue = { [field]: value };
    if (field === "device_type_id") {
      updateValue.mqtt_subgear = null;
      updateValue.device_ids = [];
    }
    if (field === "customer_id") {
      updateValue.device_ids = [];
    }
    dispatch(updateRulesFormValues(updateValue));
  }, []);

  const getSubGearParams = useMemo(() => {
    const params = { relations: [] };
    if (formValues.device_type_id) {
      params.device_type_id = formValues.device_type_id.id;
      params.relations.push("DEVICE_TYPE");
      params.device_type_ids = [formValues.device_type_id.id];
    }
    if (formValues.customer_id) {
      params.customer_id = formValues.customer_id.customerId;
      params.relations.push("CUSTOMER");
      params.customer_ids = [formValues.customer_id.customerId];
    }
    return { params };
  }, [formValues.customer_id, formValues.device_type_id]);

  const submitRule = useCallback(() => {
    const submitValues = transformFormValues(formValues);
    let apiRequest;
    let reqParams;

    let device_ids = [];
    if (submitValues.device_ids) {
      device_ids = submitValues.device_ids.map((device) => device.id);
      delete submitValues.device_ids;
    }

    if (ruleType === "create") {
      apiRequest = createRule;
      reqParams = { device_ids, body: submitValues };
    } else if (ruleType === "edit") {
      apiRequest = editRule;
      reqParams = { id: formValues.id, body: submitValues, device_ids };
    }
    apiRequest(reqParams).then((res) => {
      if (res?.error) {
        const _errorMsg = res?.error?.data ?? "Something went wrong! Try again later";
        toast.error(_errorMsg.replace("Entity", "Rule"));
        console.error("submit form", res);
        return;
      }
      toast.success(`Rule ${ruleType} success`);
      dispatch(resetFormValues());
      dispatch(updateRulesActionType("view"));
      navigate("/alarm-rules");
    });
  }, [formValues]);

  const handleSubmit = useCallback(() => {
    dispatch(resetRulesFormErrors());
    const validate = validateForm(formValues);
    if (validate === 200) {
      openConfirmationPopup();
    } else {
      dispatch(updateRulesFormErrors(validate));
    }
  }, [formValues]);

  return useMemo(
    () => ({
      formValues,
      handleFilterChange,
      handleSubmit,
      formErrors,
      actionButtonText,
      showConfirmationPopup,
      closeConfirmationPopup,
      submitRule,
      getSubGearParams,
    }),
    [
      formValues,
      getSubGearParams,
      handleFilterChange,
      handleSubmit,
      formErrors,
      actionButtonText,
      showConfirmationPopup,
      submitRule,
    ],
  );
}
