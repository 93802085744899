import { Autocomplete, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import './AffectedGearTrain.scss'
import { APP_BASE_URL, TRAIN_LIST_API } from '../../../../Common/EndPoints';
import axios from 'axios';


function DetainedTrains({ initialDetainedTrains, getDetainedTrains }) {
    const [detainedTrains, setDetainedTrains] = useImmer(initialDetainedTrains)
    const [trainList, setTrainLIst] = useState([])
    useEffect(() => {
        getTrainList()
    }, [])
    useEffect(() => {
        let detainedTrainList = []
        detainedTrains.forEach((data) => {
            if (data.detention && data.hqPunctality && data.rlyBdPunctuality) {
                detainedTrainList.push(data)
            }
        })
        getDetainedTrains(detainedTrainList)
    }, [detainedTrains])
    const getTrainList = () => {
        let url = `${APP_BASE_URL}${TRAIN_LIST_API}`
        axios.get(url).then((res) => {
            let RESPONSE = res.data.data.content
            let trainList = RESPONSE.map((train) => {
                return { id: train, value: train }
            })
            setTrainLIst(trainList)
        })
    }
    const handleChange = (index, key, value) => {
        setDetainedTrains((prev) => {
            prev[index][key] = value
        })
    }
    const handleClickAdd = () => {
        let pushObj = { trainNumber: null, detention: '', hqPunctality: '', rlyBdPunctuality: '' }
        setDetainedTrains((prev) => {
            prev = prev.push(pushObj)
        })
    }
    const handleRemoveField = (index) => {

        setDetainedTrains((prev) => {
            prev = prev.splice(index, 1)
        })
    }
    return (
        <div className="dynamicFieldRender">
            <Button onClick={() => handleClickAdd()} className="addFieldActionBtn" variant="contained">
                ADD Detained Trains +
            </Button>
            <div className="fields">
                {detainedTrains.map((field, index) => {
                    return (
                        <div key={index} className="fieldRow detainedTrains">
                            <Autocomplete
                                size="small"
                                className="form-input autocomplete"
                                value={field.trainNumber ?? null}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(event, newValue) => {
                                    handleChange(index, 'trainNumber', newValue);
                                }}
                                options={trainList}
                                getOptionLabel={(params) => {
                                    return params.value;
                                }}
                                renderInput={(params) => (
                                    <TextField size="small" {...params} label="Train Number" placeholder=" Select Train Number" />
                                )}
                            />
                            <TextField
                                className="form-input textfield"
                                value={field.detention ?? ''}
                                // onChange={(e) => {
                                //     handleChange(index, 'detention', e.target.value);
                                // }}
                                onChange={(e) => {
                                    const regex = /^\d*\.?\d{0,}$/;
                                    let inputValue = e.target.value
                                    if (regex.test(inputValue)) {
                                        handleChange(index, 'detention', inputValue);
                                    }

                                }}
                                size="small"
                                label="Detention (in minutes)"
                                placeholder="Detention (in minutes)"
                            />
                            <FormControl size="small" >
                                <InputLabel id="standard-label">In HQ Punctuality</InputLabel>
                                <Select
                                    labelId="select-standard-label"
                                    id="select-standard"
                                    value={field.hqPunctality}
                                    onChange={(e) => {
                                        handleChange(index, 'hqPunctality', e.target.value);
                                    }}
                                    label="In HQ Punctuality"
                                >

                                    <MenuItem key={"YES"} value={'YES'}>
                                        {'YES'}
                                    </MenuItem>
                                    <MenuItem key={'NO'} value={'NO'}>
                                        {'NO'}
                                    </MenuItem>

                                </Select>
                            </FormControl>
                            <FormControl size="small" >
                                <InputLabel id="standard-label">In Rly Bd Punctuality</InputLabel>
                                <Select
                                    labelId="select-standard-label"
                                    id="select-standard"
                                    value={field.rlyBdPunctuality}
                                    onChange={(e) => {
                                        handleChange(index, 'rlyBdPunctuality', e.target.value);
                                    }}
                                    label="In Rly Bd Punctuality"
                                >

                                    <MenuItem key={"YES"} value={'YES'}>
                                        {'YES'}
                                    </MenuItem>
                                    <MenuItem key={'NO'} value={'NO'}>
                                        {'NO'}
                                    </MenuItem>

                                </Select>
                            </FormControl>
                            <IconButton onClick={() => handleRemoveField(index)} aria-label="delete" className="deleteRow">
                                <CancelRoundedIcon />
                            </IconButton>
                        </div>
                    )
                })}
            </div>
        </div >
    )
}

export default DetainedTrains
