import React, { useState } from "react";
import DataTable from "../../../DataGrid/DataGrid";
import { setTemporaryAlarmFilters } from "../../../../features/Alarms/alarmsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import './StatusCountTable.scss'

function StatusCountTable({ rows, column, id, classId }) {
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCellClick = (rowParams) => {
    if (rowParams.field !== "healthy" && rowParams.field !== "station" && rowParams.field !== "deviceType") {
      // let filterParams = {
      //     severity: rowParams.field.toUpperCase()
      // }
      let filterParam = {
        severity: rowParams.field.toUpperCase(),
        limit: 20,
        offset: 0,
        pageIndex: 0,
        is_active: true,
        categories: null,
        device_ids: null,
        device_type_ids: null,
        alarm_types: null,
        // customer_ids: null,
        from_ts: null,
        to_ts: null,
      };
      if (rowParams.row.typeId) {
        filterParam = {
          ...filterParam,
          device_type_ids: [{ id: rowParams.row.typeId, value: rowParams.row.deviceType }],
        };
      }
      if (rowParams.row.customerId) {
        filterParam = {
          ...filterParam,
          customer_ids: [{ id: rowParams.row.customerId, value: rowParams.row.station }],
        };
      }
      dispatch(setTemporaryAlarmFilters(filterParam));
      navigate("/alarms");
    }
    if (rowParams.field === "healthy") { navigate('/telemetry') }
  };
  return (
    // <div className={classId}>
    <DataTable
      key="station-wise-status-count-table"
      loading={false}
      tableInfo={{
        id: id,
        label: id,
        requireAuthSign: false,
      }}
      pageSize={pageSize}
      pageSizeChange={(size) => {
        setPageSize(size);
      }}
      rows={rows}
      columns={column}
      onCellClick={(rowParams) => {
        handleCellClick(rowParams);
      }}
    />
    // </div>

  );
}

export default StatusCountTable;
