import React, { useContext, useMemo } from "react";
import { SfrContext } from "../../SFR";
import "./SfrFilters.scss";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { ActiveAlarmsData } from "../../../Alarms/ActiveAlarms";
import { useImmer } from "use-immer";
import { FIXED_DROPDOWNS, FIXED_REACTIVE_DROPDOWNS_COF, UESR_ROLE_DROPDOWNS, allowedUserRoleIds, rectificationStatusDropdowns } from "../Constants";
import { APP_BASE_URL, GET_ALL_USER } from "../../../../Common/EndPoints";
import { useSelector } from "react-redux";
import { selectedCustomersIdsSelector } from "../../../../features/authentication/auth-slice";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AutocompleteStationListPop } from "../../../AutocompleteStationListPop/AutocompleteStationListPop";

function hasDropdownCuurentValue(ddList, ddValue) {
  let hasValue = false;
  ddList.forEach((dd) => {
    if (dd.id === ddValue?.id) {
      hasValue = true;
    }
  });

  return hasValue;
}

function SfrFilters() {
  const { updateSfrFilters } = useContext(SfrContext);
  const { selectedCustomersIdName = [] } = useContext(ActiveAlarmsData);
  const [filterState, setFilterState] = useImmer({});
  const customerIdList = useSelector(selectedCustomersIdsSelector)
  const permittedCustomerIds = useMemo(() => {
    const _list = selectedCustomersIdName;
    if (!_list) return [];
    return _list.map((customer) => ({ id: customer.customerId, value: customer.customerName }));
  }, [selectedCustomersIdName?.length]);
  console.log("filterState", filterState);

  const getUsersList = useMemo(() => {
    if (filterState.filterCustomerId || customerIdList.length > 0) {
      let list = [];
      // let url = `${APP_BASE_URL}${GET_USER_LIST}`;
      let url = `${APP_BASE_URL}${GET_ALL_USER}`;
      let params = {
        customerIds: filterState?.filterCustomerId?.length > 0 ? filterState?.filterCustomerId?.map((customer) => customer.id).toString() : customerIdList.toString(),
        roleIdList: allowedUserRoleIds.toString()
      }
      axios.get(url, { params }).then((res) => {
        console.log('users', res.data.data)
        let USERS = res.data.data
        USERS.forEach((user) => {
          list.push({ id: user.id, value: user.firstName + ' ' + user.lastName })
        })

        console.log('list', list)
      });
      return list;
    } else {
      return []
    }

  }, [filterState.filterCustomerId]);

  const getCOFDropDowns = useMemo(() => {
    let COF_LIST = [];
    if (filterState?.departement) {
      COF_LIST = FIXED_REACTIVE_DROPDOWNS_COF[filterState?.departement?.value];
    }
    if (filterState?.failureCause && !hasDropdownCuurentValue(COF_LIST, filterState?.failureCause)) {
      setFilterState((prev) => {
        prev.failureCause = null;
      });
    }

    return COF_LIST;
  }, [filterState.departement]);

  const setFilters = (filters) => {
    let params = {
      ...filters,
      status: filters?.status?.value ?? null,
      filterCustomerId:
        filters?.filterCustomerId?.map((customer) => {
          return customer.id;
        }) ?? [],
      repairedByIds: filters?.repairedByIds?.length > 0 ? filters?.repairedByIds?.map((user) => user.id)?.toString() : null,
      departement: filters?.departement?.value ?? null,
      failureCause: filters?.failureCause?.value ?? null
    };
    updateSfrFilters(params);
    // console.log(params)
  };

  const handleChange = (stateName, value) => {
    setFilterState((prev) => {
      prev[stateName] = value
    })
  }
  return (
    <div className="sfr-filters">
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        multiple
        disableCloseOnSelect
        options={permittedCustomerIds}
        renderInput={(params) => (
          <TextField className="filter-input" size="small" {...params} label="Station" placeholder="Station" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(customer) => customer.value}
        value={filterState?.filterCustomerId ?? []}
        onChange={(_, newValue) => {
          setFilterState((state) => {
            state.filterCustomerId = newValue;
          });
        }}
        renderTags={(value, getTagProps) => { 
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && (
                <AutocompleteStationListPop title={value.map((stn) => stn?.value ?? "").toString()}>
                  <Chip size="small" label={`+${value.length - 1}`} />
                </AutocompleteStationListPop>
              )}
            </div>
          );
        }}
      />
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        options={rectificationStatusDropdowns}
        renderInput={(params) => (
          <TextField className="filter-input" size="small" {...params} label="Status" placeholder="Status" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(customer) => customer.value}
        value={filterState?.status ?? null}
        onChange={(_, newValue) => {
          setFilterState((state) => {
            state.status = newValue;
          });
        }}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
      />
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        multiple
        options={getUsersList}
        renderInput={(params) => (
          <TextField className="filter-input" size="small" {...params} label="Repaired By" placeholder="Repared By" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(customer) => customer.value}
        value={filterState?.repairedByIds ?? []}
        onChange={(_, newValue) => {
          setFilterState((state) => {
            state.repairedByIds = newValue;
          });
        }}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
      />
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        options={FIXED_DROPDOWNS.department}
        renderInput={(params) => (
          <TextField className="filter-input" size="small" {...params} label="Department" placeholder="Department" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(customer) => customer.value}
        value={filterState?.departement ?? null}
        onChange={(_, newValue) => {
          setFilterState((state) => {
            state.departement = newValue;
          });
        }}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
      />
      <Autocomplete
        size="small"
        className="filter-input auto-complete"
        options={getCOFDropDowns}
        renderInput={(params) => (
          <TextField className="filter-input" size="small" {...params} label="Cause Of Failure" placeholder="Cause Of Failure" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(customer) => customer.value}
        value={filterState?.failureCause ?? null}
        onChange={(_, newValue) => {
          setFilterState((state) => {
            state.failureCause = newValue;
          });
        }}
        renderTags={(value, getTagProps) => {
          return (
            <div>
              <Chip size="small" variant="outlined" label={value[0].value} {...getTagProps(0)} />
              {value.length > 1 && <Chip size="small" label={`+${value.length - 1}`} />}
            </div>
          );
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DateTimePicker
          className="form-input dateTimeField"
          label={"Failure Time(From)"}
          value={filterState.failureOnStartTs ?? null}
          onChange={(newValue) => {
            if (Date.parse(newValue && newValue["$d"])) {
              let ts = newValue["$d"].getTime();
              handleChange("failureOnStartTs", ts);
            } else {
              if (newValue === null) {
                handleChange("failureOnStartTs", newValue);
              }
            }
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <DateTimePicker
          className="form-input dateTimeField"
          label={"Failure Time(To)"}
          value={filterState.failureOnEndTs ?? null}
          onChange={(newValue) => {
            if (Date.parse(newValue && newValue["$d"])) {
              let ts = newValue["$d"].getTime();
              handleChange("failureOnEndTs", ts);
            } else {
              if (newValue === null) {
                handleChange("failureOnEndTs", newValue);
              }
            }
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
        />

      </LocalizationProvider>

      <Button
        variant="contained"
        onClick={() => {
          setFilters(filterState);
        }}
      >
        Filter
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          setFilterState({})
          setFilters({});
        }}
      >
        Clear
      </Button>
    </div>
  );
}

export default SfrFilters;
