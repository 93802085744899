import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  APP_BASE_URL_ALARM,
  GENERIC_ATTACHMENTS,
  GET_ALARM_BY_ID,
  GET_GROUP_BY_COUNT,
  GET_PAGINATED_ALARMS,
  PATCH_ACK_ALARM,
  PATCH_CLEAR_ALARM,
} from "../../Common/EndPoints";
import { apiAlarms } from "../api/apiAlarms";
import { getAlarmAttachmentURL, transformFilterRequestParams } from "./alarmUtils";

export const DEFAULT_ALARM_FILTERS = {
  limit: 20,
  offset: 0,
  pageIndex: 0,
  is_active: true,
  severity: null,
  categories: null,
  device_ids: null,
  device_type_ids: null,
  alarm_types: null,
  customer_ids: [],
  from_ts: null,
  to_ts: null,
  remarks: "",
  is_acked: null,
};

const alarmsAdapter = createEntityAdapter({
  selectId: (alarm) => alarm.id,
});

var alarmNotificationSound = localStorage.getItem("alarmNotificationSound");
if (alarmNotificationSound) alarmNotificationSound = JSON.parse(alarmNotificationSound);
else alarmNotificationSound = true;

const initialState = alarmsAdapter.getInitialState({
  alarmModal: {
    isModalOpen: false,
    modalType: "", // ack|clear|multi_ack|multi_clear|info
    alarmId: "",
  },
  alarmFilters: DEFAULT_ALARM_FILTERS,
  temporaryFilters: null,
  alarmSelectedRows: [],
  totalRecords: 0,
  alarmNotificationSound: alarmNotificationSound,
});

export const extendedAlarmsAPIs = apiAlarms.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedAlarms: builder.query({
      query: (params) => {
        return {
          url: `${GET_PAGINATED_ALARMS}`,
          params: transformFilterRequestParams({
            ...params,
            with_customer: true,
            with_device: true,
            with_device_type: true,
            with_fmea: true,
            with_ack_user: true,
          }),
        };
      },
      providesTags: ["paginatedAlarms"],
      keepUnusedDataFor: 0,
    }),
    getAlarmById: builder.query({
      query: (id) => {
        return {
          url: GET_ALARM_BY_ID + id,
          params: {
            with_customer: true,
            with_device: true,
            with_device_type: true,
            with_ack_user: true,
            with_fmea: true,
          },
        };
      },
    }),
    getGroupByCount: builder.query({
      query: (params) => {
        return {
          url: `${GET_GROUP_BY_COUNT}`,
          params: transformFilterRequestParams({ ...params, col_name: "severity" }),
        };
      },
      providesTags: ["paginatedAlarms"],
      keepUnusedDataFor: 0,
    }),
    ackAlarm: builder.mutation({
      query({ params, body }) {
        const bodyString = JSON.stringify(body);
        return {
          url: `${PATCH_ACK_ALARM}`,
          method: "PATCH",
          params,
          body: bodyString,
        };
      },
      invalidatesTags: ["paginatedAlarms"],
    }),
    clearAlarm: builder.mutation({
      query({ params, body }) {
        const bodyString = JSON.stringify(body);
        return {
          url: `${PATCH_CLEAR_ALARM}`,
          method: "PATCH",
          params,
          body: bodyString,
        };
      },
      invalidatesTags: ["paginatedAlarms"],
    }),
    uploadAttachments: builder.mutation({
      query({ params, body }) {
        return {
          url: `${GENERIC_ATTACHMENTS}`,
          method: "POST",
          params: { ...params, entity: "alarm" },
          body,
        };
      },
      invalidatesTags: ["paginatedAlarms"],
    }),
    getAlarmAttachments: builder.query({
      query: (params) => {
        return {
          url: `${GENERIC_ATTACHMENTS}`,
          params: { ...params, entity: "alarm" },
        };
      },
      providesTags: ["paginatedAlarms"],
      transformResponse: (response) => {
        const IMG_TYPES = ["PNG", "JPG", "JPEG", "SVG"];
        const img_urls = [];
        const img_attachments = [];
        const other_attachments = [];

        for (const _img of response) {
          if (IMG_TYPES.includes(_img.extension.toUpperCase())) {
            img_urls.push(getAlarmAttachmentURL(_img.path));
            img_attachments.push({ ..._img, path: getAlarmAttachmentURL(_img.path) });
          } else {
            other_attachments.push(_img);
          }
        }
        return { img_urls, other_attachments, img_attachments };
      },
    }),
  }),
});

export const {
  useGetPaginatedAlarmsQuery,
  useGetAlarmByIdQuery,
  useGetGroupByCountQuery,
  useAckAlarmMutation,
  useClearAlarmMutation,
  useUploadAttachmentsMutation,
  useGetAlarmAttachmentsQuery,
} = extendedAlarmsAPIs;

const alarmSlice = createSlice({
  name: "alarm",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.alarmModal.isModalOpen = true;
      state.alarmModal.modalType = action.payload.modalType;
      state.alarmModal.alarmId = action.payload.alarmId;
    },
    closeModal: (state) => {
      state.alarmModal.isModalOpen = false;
    },
    updateAlarmFilters: (state, { payload }) => {
      const updatedPayload = { ...state.alarmFilters, ...payload };
      // for (const key in payload) {
      //   if (payload[key] === null || payload[key] === undefined || payload[key] === "") delete updatedPayload[key];
      // }
      state.alarmFilters = updatedPayload;
    },
    setAlarmFilters: (state, { payload }) => {
      state.alarmFilters = payload;
    },
    clearAlarmFilters: (state) => {
      state.alarmFilters = DEFAULT_ALARM_FILTERS;
    },
    updateAlarmsSelectedRows: (state, { payload }) => {
      state.alarmSelectedRows = payload;
    },
    updateSingleAlarmRow: (state, { payload }) => {
      alarmsAdapter.updateOne(state, payload);
    },
    upsertSingleAlarmRow: (state, { payload }) => {
      alarmsAdapter.upsertOne(state, payload);
    },
    updateAlarmNotificationSound: (state, { payload }) => {
      localStorage.setItem("alarmNotificationSound", payload);
      state.alarmNotificationSound = payload;
    },
    updateTemporaryAlarmFilters: (state, { payload }) => {
      const updatedPayload = { ...state.temporaryFilters, ...payload };
      state.temporaryFilters = updatedPayload;
    },
    setTemporaryAlarmFilters: (state, { payload }) => {
      state.temporaryFilters = payload;
    },
    clearTemporaryFilters: (state) => {
      state.temporaryFilters = null;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(extendedAlarmsAPIs.endpoints.getPaginatedAlarms.matchFulfilled, (state, { payload }) => {
      state.totalRecords = payload.totalRecords;
      alarmsAdapter.setAll(state, payload.alarms);
    });
  },
});
export const {
  openModal,
  closeModal,
  updateAlarmFilters,
  setAlarmFilters,
  clearAlarmFilters,
  updateAlarmsSelectedRows,
  updateSingleAlarmRow,
  upsertSingleAlarmRow,
  updateAlarmNotificationSound,
  updateTemporaryAlarmFilters,
  setTemporaryAlarmFilters,
  clearTemporaryFilters,
} = alarmSlice.actions;

export default alarmSlice.reducer;

export const alarmModalSelector = (state) => state.alarm.alarmModal;
export const alarmFiltersSelector = (state) => state.alarm.alarmFilters;
export const selectedAlarmRows = (state) => state.alarm.alarmSelectedRows;
export const getAlarmNotificationSound = (state) => state.alarm.alarmNotificationSound;
export const temporaryAlarmFiltersSelector = (state) => state.alarm.temporaryFilters;
export const refetchAlarmsData = apiAlarms.util.invalidateTags(["paginatedAlarms"]);

export const { selectEntities: selectAlarmEntities } = alarmsAdapter.getSelectors((state) => state.alarm);
