import jsPDF from "jspdf";

import { bitcommLogo } from "../DataGrid/PdfImages";

export const generatePDF = (arr) => {
  const doc = new jsPDF();

  // Header
  const columns = [
    "Cable",
    "ELD Status",
    "Location",
    "Gear",
    "Health",
    "Impacted Gear",
    "Indoor Source",
    "Outdoor Realtime",
    "Outdoor Reference",
    "Indoor Received",
  ];

  // Data
  const rows = [];

  arr.forEach((item) => {
    const row = [
      item.cable,
      item.eld_status,
      item.location.join("\n"),
      item.gear.join("\n"),
      item.health.join("\n"),
      item.impacted_gear.join("\n"),
      item.indoor_source,
      item.outdoor_realtime.join("\n"),
      item.outdoor_reference.join("\n"),
      item.indoor_receive.join("\n"),
    ];
    rows.push(row);
  });

  // AutoTable configuration
  const tableConfig = {
    head: [columns],
    body: rows,
    startY: 20,
    margin: { left: 2 },
    theme: "grid",
    tableWidth: "wrap", // or a specific value if needed
    columnWidth: "wrap", // or a specific value if needed
    bodyStyles: { valign: "middle", halign: "center" }, // Vertical alignment for all cells
    styles: { cellPadding: 2, fontSize: 6, overflow: "linebreak" }, // Adjust as needed
    showHead: "everyPage",
    columnStyles: {
      0: { overflow: "linebreak" }, // Set linebreak for the first column (Cable)
    },
    didDrawPage: function (data) {
      if (data.pageNumber === 1) {
        //top heading and logo
        doc.addImage(bitcommLogo, "JPEG", 10, 5, 25, 9);
        doc.setFontSize(15);
        doc.text("Cable Monitoring Data", 80, 10);

        doc.setFontSize(7);
        doc.setTextColor(45);
        let genratedTime = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
        doc.text("Generated Time: " + genratedTime, 165, 18);
      }
    },
  };

  doc.autoTable(tableConfig);

  // Save the PDF
  doc.save("table-export.pdf");
};
