import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";
import Badge from "@mui/material/Badge";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ClickAwayListener, FormControlLabel, Popper, Skeleton, Switch, Tooltip, styled } from "@mui/material";

import { userIdSelector } from "../../../features/authentication/auth-slice";
import {
  useGetNotificationCountQuery,
  useLazyGetAlarmNotificationQuery,
  useMarkNotificationsReadMutation,
} from "../../../features/api/api-notifications";
import "./alarm-notifications.scss";

function getMarginRight(count: number) {
  if (count >= 100) return "mr-6";
  if (count >= 10) return "mr-4";
  return "mr-2";
}

export const AlarmsNotifications = () => {
  const userId = useSelector(userIdSelector);
  const params = { user_id: userId };
  const popperRef = useRef<HTMLDivElement>(null);

  const { data: count } = useGetNotificationCountQuery(params, { skip: userId ? false : true });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  function handlePopperClose() {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <>
      <Badge
        onClick={handleClick}
        aria-describedby={id}
        badgeContent={count}
        color="secondary"
        className={`ml-2 ${getMarginRight(count)}`}
      >
        <NotificationsIcon className="cursor-pointer" />
      </Badge>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} ref={popperRef} placement="bottom-end">
        <ClickAwayListener onClickAway={handlePopperClose}>
          <div className="alarm-notifications-popper">
            <NotificationsList handlePopperClose={handlePopperClose} />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export type AlarmNotificationItemProps = {
  id: string;
  msg_title: string;
  msg_description: string;
  msg_dt: string;
  msg_payload: { alarm_id: string; customer_id: string; type: "ALARM" | string };
  interacted: boolean;
};
type AlarmNotificationListProps = AlarmNotificationItemProps[];

type FetchNotificationsListParams = {
  offset: number;
  user_id: string;
  limit: number;
  uninteracted?: boolean;
};

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;

type NotificationsListProps = {
  handlePopperClose: () => void;
};

const NotificationsList = (props: NotificationsListProps) => {
  const { handlePopperClose } = props;
  const userId = useSelector(userIdSelector);

  const listOffset = useRef(DEFAULT_OFFSET);
  const [data, setData] = useState<AlarmNotificationListProps>([]);
  const showUnread = useRef<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const itemsContainerRef = useRef<HTMLDivElement>(null);

  const [fetchNotificationsList, notificationListResult] = useLazyGetAlarmNotificationQuery({});
  const [markNotifications] = useMarkNotificationsReadMutation();

  const { isFetching } = notificationListResult;

  const [canFetchData, setCanFetchData] = useState<boolean>(true);

  function onScrollToBottom() {
    if (itemsContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = itemsContainerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        if (!canFetchData) return;
        listOffset.current += DEFAULT_LIMIT;
        fetchData();
      }
    }
  }

  useEffect(() => {
    fetchData(true);

    const listInnerElement = itemsContainerRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScrollToBottom);

      return () => {
        listInnerElement.removeEventListener("scroll", onScrollToBottom);
      };
    }
  }, []);

  async function fetchData(reset_data: boolean = false) {
    const params: FetchNotificationsListParams = {
      offset: listOffset.current,
      user_id: userId,
      limit: DEFAULT_LIMIT,
      uninteracted: showUnread.current,
    };
    if (!showUnread.current) delete params.uninteracted;

    // returns null when no data is present
    const _data: AlarmNotificationListProps | null = await fetchNotificationsList(params).unwrap();
    if (!_data) {
      setCanFetchData(false);
      return;
    }
    setData((state) => (reset_data ? _data : [...state, ..._data]));
  }

  function handleShowUnread(event: React.ChangeEvent<HTMLInputElement>) {
    listOffset.current = DEFAULT_OFFSET;
    showUnread.current = event.target.checked;

    itemsContainerRef.current?.scrollTo({ top: 0 });

    setCanFetchData(true);
    fetchData(true);
  }

  function handleNotificationClick(alarm: AlarmNotificationItemProps) {
    if (!isAlarm(alarm)) return;
    const alarm_id = alarm.msg_payload.alarm_id;
    if (!alarm_id) return;
    searchParams.set("show_info", alarm_id);
    setSearchParams(searchParams);
    handlePopperClose();
    markNotifications({ ids: alarm.id, user_id: userId });
  }

  async function handleMarkRead(alarm: AlarmNotificationItemProps, index: number) {
    const params = { ids: alarm.id, mark_type: "interacted", user_id: userId };
    const response: any = await markNotifications(params);
    if (response.error) {
      toast.error(response.error?.data ?? "Something Went Wrong");
      return;
    }
    setData((state) => {
      const _state = [...state];
      _state[index] = { ...state[index], interacted: true };
      return _state;
    });
  }

  function isAlarm(alarm: AlarmNotificationItemProps) {
    if (alarm.msg_payload.type !== "ALARM") return false;
    return true;
  }

  return (
    <div className="alarm-notifications-popper__notifications-list--container">
      <div className="popper--heading flex items-center justify-between">
        <h3 className="container--title">Alarms Notifications</h3>
        <FormControlLabel
          className="switch-label"
          control={<Switch size="small" checked={showUnread.current} onChange={handleShowUnread} />}
          label="Only show unread"
        />
      </div>
      <div className="items--container" ref={itemsContainerRef}>
        {data?.map((alarm: AlarmNotificationItemProps, index: number) => (
          <div
            className={`item-row ${isAlarm(alarm) && "cursor-pointer"} ${alarm.interacted && "item-row--read"}`}
            key={alarm.id}
            onClick={() => handleNotificationClick(alarm)}
          >
            <div className="alarm-notification__row--title">
              <span>{alarm.msg_title}</span>
              {!alarm.interacted && (
                <Tooltip onClick={() => handleMarkRead(alarm, index)} title="Mark as read" placement="right">
                  <span className="unread-icon" />
                </Tooltip>
              )}
            </div>
            <div className="alarm-notification__row--date">{moment(alarm.msg_dt).fromNow()}</div>
            <div className="alarm-notification__row--description">
              <KeyboardDoubleArrowRightIcon /> <span>{alarm.msg_description}</span>
            </div>
          </div>
        ))}
        <DataLoadingContainer canFetchData={canFetchData} isFetching={isFetching} />
      </div>
    </div>
  );
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"}`,
  borderRadius: 6,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
  width: 490,
}));

type LoadingContainerProps = {
  canFetchData: boolean;
  isFetching: boolean;
};

const DataLoadingContainer = (props: LoadingContainerProps) => {
  const { canFetchData = true, isFetching = true } = props;

  if (!canFetchData) return <>No new notifications to show!</>;

  if (isFetching)
    return (
      <div className="loading-skeleton">
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "60%" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30%" }} />
        <Skeleton variant="text" sx={{ fontSize: "2rem", width: "95%" }} />
      </div>
    );
  return <></>;
};

export default AlarmsNotifications;
