import { Badge, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import "./FieldAnalytics.scss";
import RangePreviewNumeric from "./RangePreviewNumeric";
import RangePreviewString from "./RangePreviewString";

const severityTabs = ["Normal",  "Warning","Critical",];
const initialAnalyticsData ={
 type:'',
 ranges:{}
}
function FieldAnalytics({ analyticsType,getAnalyticsData }) {
  const [activeTab, setActiveTab] = useState("Normal");
  const [analyticsData, setAnalyticsData] = useImmer(initialAnalyticsData)
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  useEffect(()=>{
    getAnalyticsData(analyticsData)
  },[analyticsData])
  const getRangeObj =(rangeObj)=>{
    let newRanges = {...analyticsData.ranges,...rangeObj}
      setAnalyticsData((prev)=>{
        prev.ranges = newRanges
        prev.type =analyticsType
      })
  

  }
  return (
    <>
      <div className="severity-tabs">
        {severityTabs.map((tab) => {
          return (
          
            <div
              onClick={() => {
                handleTabClick(tab);
              }}

              className={`tab pointer ${activeTab === tab ? 'active' +" "+tab : ""}`}
            >
                  {/* <Badge badgeContent={4} color="primary"> */}
                  {tab}
                {/* </Badge> */}
          
            </div>
          );
        })}
      </div>
      {analyticsType === "numeric" && (
        <>
          {" "}
          <div className={`rangePreview ${activeTab === "Normal" ? "show" : "hide"}`}>
            <RangePreviewNumeric getRangeObj={getRangeObj} rangeType={"normal"} />
          </div>
          <div className={`rangePreview ${activeTab === "Warning" ? "show" : "hide"}`}>
            <RangePreviewNumeric getRangeObj={getRangeObj} rangeType={"warning"} />
          </div>
          <div className={`rangePreview ${activeTab === "Critical" ? "show" : "hide"}`}>
            <RangePreviewNumeric getRangeObj={getRangeObj} rangeType={"critical"} />
          </div>
        </>
      )}
        {analyticsType === "categorical" && (
        <>
          {" "}
          <div className={`rangePreview ${activeTab === "Normal" ? "show" : "hide"}`}>
            <RangePreviewString getRangeObj={getRangeObj}g rangeType={"normal"} />
          </div>
          <div className={`rangePreview ${activeTab === "Warning" ? "show" : "hide"}`}>
            <RangePreviewString getRangeObj={getRangeObj} rangeType={"warning"} />
          </div>
          <div className={`rangePreview ${activeTab === "Critical" ? "show" : "hide"}`}>
            <RangePreviewString getRangeObj={getRangeObj} rangeType={"critical"} />
          </div>
       
        </>
      )}
    </>
  );
}

export default FieldAnalytics;
