import { useEffect, useContext, useState, useCallback, useRef } from "react";
import { Box } from "@mui/system";
import DataTable from "../DataGrid/DataGrid";
import { useImmer } from "use-immer";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import MemoryIcon from "@mui/icons-material/Memory";
import { Button, Tooltip, Typography } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import TimelineIcon from "@mui/icons-material/Timeline";
import {
  GET_LATEST_TELEMETRY,
  TELEMETRY_SOCKET_URL,
  APP_BASE_URL,
  GET_DEVICE_BY_CUSTOMER_ID,
  GET_UNIVERSAL_DEVICE_KEYS,
  trimAssetName,
  APP_BASE_URL_SOCKET,
  GET_LAST_ENTRY_TS,
} from "../../Common/EndPoints";
import DataCard from "../Card/DataCard";
import { GridActionsCellItem } from "@mui/x-data-grid";
import HistoryIcon from "@mui/icons-material/History";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import axios from "axios";
import "./Telemetry.scss";
import PointChartPopup from "./PointOperationChart/PointChartPopup";
import {
  FIXED_COLUMNS,
  INITIAL_TELEMETRY_CIRCUIT_VIEW_MODAL_STATE,
  PM_COLUMNS,
  TC_COLUMNS,
  convertToHigherUnit,
  getCellColor,
  initialPointChartPopData,
  isValidNum,
} from "./Constant";
import TcCharts from "./TcCharts/TcCharts";
import moment from "moment";
import { toast } from "react-hot-toast";
import CableMonitoring from "../cable-monitoring/CableMonitoring";
import BhmsWrapper from "../bhms/BhmsWrapper";
import TelemetryCircuitViewModal from "./circuit-view/TelemetryCircuitView";
const Device = {
  IPS: "IPS",
  TC: "TRACK",
  SG: "SG",
  PM: "PM",
  BAT: "BAT",
  PMD: "PMD",
  SHUNT: "SHUNT",
  BB: "BB",
  AC: "AC",
  CableMonitoring: "ELD",
  BHMS: "BHMS",
};

// const SG_TELEMETRY_TABLE_CONFIG = [
//   { label: "MAIN", id: 0, subgears: [] },
//   { label: "SHUNT", id: 1, subgears: [] },
//   { label: "CALLING", id: 2, subgears: [] },
//   { label: "ROUTE INDICATOR", id: 3, subgears: [] },
// ];
const SG_TELEMETRY_TABLE_CONFIG = window['sg_telemetry_table_config']
const SG_SUBTYPE = [
  { label: "MAIN", value: "NORMAL", id: 0 },
  { label: "SHUNT", value: "SHUNT", id: 1 },
  { label: "CALLING", value: "CALLING", id: 2 },
  { label: "ROUTE INDICATOR", value: "ROUTE_INDICATOR", id: 3 },
];

const Telemetry = () => {
  const initialState = {
    data: [],
    deviceTypeList: [],
    selectedDevice: "",
    gridColumns: [],
    deviceType: sessionStorage.getItem("telemetry-selected-device-type") ?? null,
    deviceTypeLabel: sessionStorage.getItem("telemetry-selected-device-type-label") ?? null,
    typeId: sessionStorage.getItem("telemetry__selected-device-type--id") ?? null,
    ipsData: [], //ips data will be filled here from telemetry api
    loader: true,
  };
  const raw_telemetry_data = useRef({});
  let navigate = useNavigate();
  const [telemetryState, setTelemetryState] = useImmer(initialState);
  const [sgColumns, setSgColumns] = useImmer({});

  const [pointChartPop, setPointChartPop] = useImmer(initialPointChartPopData);
  const [circuitViewModal, setCircuitViewModal] = useImmer(INITIAL_TELEMETRY_CIRCUIT_VIEW_MODAL_STATE);
  const [tcCharts, setTcCharts] = useImmer({ open: false });
  const [pageSize, setPageSize] = useState(20);
  const [initialHiddenColumns, setIntialHiddenColumns] = useState({});
  const { customerIdList, newUserData } = useContext(ActiveAlarmsData);
  const [selectedStations, setSelectedStations] = useState(localStorage.getItem("STN"));
  const userId = localStorage.getItem("userId");
  const [selectedBhmsSubTab, setSelectedBhmsSubTab] = useState("graphical");
  let telemetryData = [];
  let selectedDeviceType;
  let ipsData = [];

  useEffect(() => {
    const controller = new AbortController();
    if (customerIdList.length > 0) {
      getDeviceTypes(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [customerIdList]);

  useEffect(() => {
    const controller = new AbortController();
    if (customerIdList.length > 0 && telemetryState.deviceTypeList.length > 0) {
      getHeaderAndLatestTelemetry(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [customerIdList, telemetryState.deviceType, telemetryState.deviceTypeList]);

  useEffect(() => {
    const coloumnsToHide = {};
    telemetryState.gridColumns.forEach((column) => {
      if (column.hide) {
        coloumnsToHide[column.field] = false;
      }
    });

    setIntialHiddenColumns(coloumnsToHide);
  }, [telemetryState.gridColumns]);


  useEffect(() => {
    if (customerIdList.length > 0 && JSON.stringify(newUserData) !== "{}") {
      const selectedSTation = [];
      newUserData.customerDetails.forEach((st) => {
        if (customerIdList.includes(st.customerId)) {
          selectedSTation.push(st.customerName);
        }
      });
      setSelectedStations(selectedSTation);
    }
  }, [customerIdList, newUserData]);
  const getDeviceTypes = (signal) => {
    const url = `${APP_BASE_URL}${GET_DEVICE_BY_CUSTOMER_ID}`;
    axios
      .post(url, customerIdList, { signal })
      .then((res) => {
        if (res?.data?.data) {
          // let deviceTypes = res?.data?.data.map((type) => type.label);
          let deviceTypes = res?.data?.data;
          deviceTypes.reverse();
          setTelemetryState((state) => {
            state.deviceTypeList = deviceTypes;
            state.deviceTypeLabel =
              sessionStorage.getItem("telemetry-selected-device-type-label") ?? deviceTypes[0].label;
            state.deviceType = sessionStorage.getItem("telemetry-selected-device-type") ?? deviceTypes[0].type;
            state.typeId = sessionStorage.getItem("telemetry__selected-device-type--id") ?? deviceTypes[0].typeId;
          });
        }
      })
      .catch(() => {
        setTelemetryState((state) => {
          state.loader = false;
          state.deviceTypeList = [];
        });
      });
  };
  const getLiveTelemetryData = (dt) => {
    window.wsTelemetry?.close();
    const url = `${APP_BASE_URL_SOCKET}/${userId}`;
    const webSocketParam = {
      filter: {
        geartype: dt,
      },
    };
    window.wsTelemetry = new WebSocket(url);

    /* minor bug(not a breaking bug but rather for knowledge): Unable to loop through telemetryState variables in websocket 
    on message and for ips and non ips device types looping through normal variables is possible stored in 
    ipsData and gridData respectively
    
    Similar to issues: 
      - https://stackoverflow.com/questions/70350017/react-hooks-variables-not-changed-in-websocket-onmessage-callback-but-jsx-show-c
      - https://stackoverflow.com/questions/70232251/how-to-get-current-telemetryState-value-inside-websocket-onmessage
    
    Possible Reason:
      - Websocket on connection stores telemetryState value as received and serves them only on message and not the current value of the telemetryState
    
    Possible Solutions:
      - Establish websocket connection after telemetryState is set
      - Establish new web socket connection each time a new message is received
      
    */

    window.wsTelemetry.addEventListener("open", (event) => {
      // don't limit websocket data by device type when fetching for cable monitoring
      if (dt === Device.CableMonitoring) return;
      window.wsTelemetry.send(JSON.stringify(webSocketParam));
    });
    window.wsTelemetry.onmessage = (res) => {
      if (res?.data) {
        let data = JSON.parse(res.data);
        if (selectedDeviceType === Device.CableMonitoring) {
          const device_id_of_received_packet = data.deviceId;
          const packet_data = data?.dataLists?.[0];
          raw_telemetry_data.current[device_id_of_received_packet] = {
            ...raw_telemetry_data.current[device_id_of_received_packet],
            ...packet_data,
          };
          // console.log("telemetryData", telemetryData);
          const new_telemetry = telemetryData.map((row) => {
            if (row.deviceId !== device_id_of_received_packet) return row;
            // console.log('device_id_match',row, packet_data)
            const dataLists = [...row.dataLists, packet_data];
            // row = { ...row, dataLists };
            // row.dataLists.push(packet_data);
            const updated_row = { ...row, dataLists };
            console.log("device_id_match", row, packet_data, updated_row);
            return updated_row;
          });
          telemetryData = new_telemetry;
          // console.log("new_telemetry", new_telemetry);

          setTelemetryState((state) => {
            state.data = new_telemetry;
          });
          return;
        }
        if (selectedDeviceType === Device.IPS) {
          const isIpsType = data?.data[0]?.dataLists[0]?.data["GEARTYPE"][0]?.value === Device.IPS;
          if (isIpsType) {
            const ipsCardData = data.data.map((dt) => {
              return {
                label: dt.deviceName,
                value: dt.dataLists[0]?.data["V1"][0]?.value,
              };
            });

            // as mentioned above unable to loop through telemetryState.ipsData but working fine with normal variable ipsData
            const updatedData = ipsData.map((row) => {
              let updatedObj = {};

              /* minor bug: unable to initialize updatedObj with row or even directly update row with row.value = ... 
              
              giving error: Uncaught TypeError: Cannot assign to read only property 'value' of object '#<Object>'
              */
              ipsCardData.forEach((data) => {
                if (data.label.includes(row.label)) {
                  updatedObj.value = data.value;
                }
              });
              return { ...row, ...updatedObj };
            });
            setTelemetryState((draft) => {
              draft.ipsData = updatedData;
            });
          }
        } else {
          let webSocketPacket = { ...data };
          if (webSocketPacket.eventType) {
            let newTelemetryData = [];
            if (webSocketPacket.eventType === "TESTMODE") {
              let newTelemetryData = telemetryData.map((row) => {
                if (row.deviceId === webSocketPacket.deviceId) {
                  let newRow = { ...row, testMode: webSocketPacket.testmode };
                  return newRow;
                } else {
                  return row;
                }
              });
              telemetryData = newTelemetryData;
              setTelemetryState((draft) => {
                draft.data = newTelemetryData;
              });
            }
          } else {
            data = data?.dataLists?.[0]?.data;
            let station = data?.STN[0]?.value;

            if (selectedStations.includes(station)) {
              let deviceName = data.STN[0].value + "-" + data.NAME[0].value;
              if (data.NAME[0].value === "UD") {
                deviceName = `${data.STN[0].value}-SUD`;
              }
              if (data.NAME[0].value === "DD") {
                deviceName = `${data.STN[0].value}-SDD`;
              }
              if (data.NAME[0].value === "NN89") {
                deviceName = `${data.STN[0].value}-NN89A`;
              }
              if (data.NAME[0].value === "NN90") {
                deviceName = `${data.STN[0].value}-NN90A`;
              }

              let eventType = data?.EVENTTYPE?.[0];
              let pointOprationTimeKey = {};
              if (eventType && eventType?.value === "4") {
                pointOprationTimeKey.pointOprationTime = Number(eventType.ts);
              }
              /** Update only that row whose data got fetched from the WebSocket */
              const updatedData = telemetryData.map((row) => {
                if (row["NAME"] === deviceName) {
                  const _datetime = Number(data?.["TIME"]?.[0]?.ts);
                  let dynamicRow = {
                    // lastActivityTime: _datetime,
                    lastActivityTime: moment(_datetime).format("DD/MM/YYYY HH:mm:ss"),
                    // lastActivityTime:  getFormattedDateTime(Number(data?.["TIME"]?.[0]?.ts)),
                  };

                  Object.entries(data).map(([key, value]) => {
                    dynamicRow[key] = value?.[0]?.value;
                  });
                  /** merge the new data received with the old data object
                   * just in case the new data doesn't contains all the columns
                   */
                  dynamicRow = { ...row, ...dynamicRow, NAME: deviceName, ...pointOprationTimeKey };
                  return dynamicRow;
                } else {
                  return row;
                }
              });
              // updatedData.lastActivityTime = moment(updatedData.lastActivityTime).format("DD-MM-YYYY HH:mm:ss");
              //set telemetryData to be updated to the new data
              telemetryData = updatedData;
              setTelemetryState((draft) => {
                draft.data = updatedData;
              });
            }
          }
        }
      }
    };
  };

  function getDeviceType(deviceType) {
    if (deviceType === "PMD") return "PM";
    if (deviceType === "ELD") return null;

    return deviceType;
  }

  const getLatestTelemetry = (deviceType, signal) => {
    let payload = {
      customerIds: customerIdList,
      deviceCategory: getDeviceType(deviceType),
      status: "ALL",
    };
    selectedDeviceType = deviceType;

    axios
      .post(`${APP_BASE_URL}${GET_LATEST_TELEMETRY}`, payload, { signal })
      .then((res) => {
        if (res?.data?.data) {
          let gridData = [];
          if (deviceType === Device.CableMonitoring) {
            /**
             * inserting id in each device because code breaks when gear types are switched from cable to any other
             * in only those gear types where MUI table is shown
             * because in MUI table each row need an id
             * and it will take some time for new data to be fetched for different gear type
             * so either need to reset data when gear types are switched which is optimal and should be done
             * but implementing this for now!
             */
            const _data = res.data.data.map((packet) => ({ id: packet.deviceId, ...packet }));
            telemetryData = _data;
            setTelemetryState((state) => {
              state.data = _data;
            });
          } else if (deviceType === Device.IPS) {
            const RESPONSE = res?.data?.data;
            if (RESPONSE) {
              let parsedResponse = RESPONSE.map((data) => {
                let desiredOuterPacketData = {
                  lastUpdateTs: data.ts,
                  trimmedAssetName: trimAssetName(data.deviceName),
                  deviceId: data.deviceId,
                };
                let mergedDataList = {};
                data.dataLists.forEach((datalist) => {
                  let packet = datalist.data;
                  let parsedPacket = {};
                  Object.entries(packet).forEach(([key, value]) => {
                    parsedPacket[key] = value?.[0]?.value ?? "";
                  });
                  mergedDataList = { ...parsedPacket };
                });
                return { ...desiredOuterPacketData, ...mergedDataList };
              });
              const ipsCardData = parsedResponse.map((dt) => {
                return {
                  // the items we defined here will be passed into datacard from entire json
                  label: dt.trimmedAssetName,
                  value: dt?.["V1"],
                  deviceId: dt.deviceId,
                  deviceType: Device.IPS,
                  lastActivityTime: dt.lastUpdateTs,
                };
              });

              setTelemetryState((draft) => {
                draft.ipsData = ipsCardData;
              });
              ipsData = ipsCardData;
            }
          } else {
            const raw_data_map = {};
            res?.data?.data?.forEach((data, index) => {
              const device_id = data.deviceId;
              raw_data_map[device_id] = {};
              for (const packet of data.dataLists) {
                const data = packet.data;
                raw_data_map[device_id] = { ...raw_data_map[device_id], ...data };
              }

              let finalRow = {
                id: index,
                deviceId: data.deviceId,
                testMode: data.isTestMode,
                // NAME: data.deviceName,
                deviceType: deviceType,
              };
              let nameCode = trimAssetName(data.deviceName);
              // let nameCode = data.deviceName;
              let zoneDivCode = "";
              if (nameCode.split("_").length >= 4) {
                zoneDivCode = nameCode.split("_")[0] + "-" + nameCode.split("_")[1];
                // nameCode = nameCode.split("_").splice(2).join("-");
              }

              if (data?.dataLists?.length) {
                let recentActivityTime = 0;
                data?.dataLists.forEach((item) => {
                  let dynamicRow = {
                    lastActivityTime: Number(item.data?.["TIME"]?.[0]?.ts),
                  };

                  let eventType = item?.data?.EVENTTYPE?.[0];

                  let pointOprationTimeKey = {};
                  if (eventType?.value === "4") {
                    pointOprationTimeKey.pointOprationTime = Number(eventType.ts);
                  }
                  Object.entries(item.data).map(([key, value]) => {
                    dynamicRow[key] = value[0].value;
                  });

                  if (dynamicRow.lastActivityTime > recentActivityTime) {
                    recentActivityTime = dynamicRow.lastActivityTime;
                    finalRow = { ...finalRow, ...dynamicRow, ...pointOprationTimeKey };
                  } else {
                    finalRow = { ...dynamicRow, ...finalRow, ...pointOprationTimeKey };
                  }
                });
              }
              finalRow["NAME"] = nameCode;
              finalRow["ZONE_DIV"] = zoneDivCode;
              finalRow.lastActivityTime = moment(finalRow.lastActivityTime, "x").format("DD/MM/YYYY HH:mm:ss");
              gridData.push(finalRow);
            });
            raw_telemetry_data.current = raw_data_map;
            console.log("raw_telemetry_data", raw_data_map);

            setTelemetryState((draft) => {
              draft.data = gridData;
            });
            telemetryData = gridData;
          }
        } else {
          setTelemetryState((draft) => {
            draft.ipsData = [];
          });
        }
      })
      .then(() => {
        setTelemetryState((draft) => {
          draft.loader = false;
        });
        getLiveTelemetryData(deviceType);
      })
      .catch(() => {
        setTelemetryState((draft) => {
          draft.loader = false;
        });
      });
  };
  const getSgColumns = (subTableInfo) => {
    let url = `${APP_BASE_URL}${GET_UNIVERSAL_DEVICE_KEYS}`;

    let apiParams = {
      customerIds: customerIdList.toString(),
      type: "SG",
      subTypeId: subTableInfo.id,
    };
    axios
      .get(url, { params: apiParams })
      .then((res) => {
        let obj = {};
        let columns = [];
        if (res.data.data) {
          res.data.data.forEach((row) => {
            if (obj[row.key]) {
              obj[row.key]["critical"].push([row.cMin, row.cMax]);
              obj[row.key]["warning"].push([row.wMin, row.wMax]);
            } else {
              obj[row.key] = {
                key: row.key,
                alias: row.alias,
                unit: row.unit,
                critical: [[row.cMin, row.cMax]],
                warning: [[row.wMin, row.wMax]],
                order: row.order,
              };
            }
          });
          Object.values(obj).forEach((column, i) => {
            columns.push({
              key: i,
              field: column.key,
              headerName: `${column.alias} (${column.unit !== "mv" ? column.unit : "V"})`,
              sortable: true,
              flex: 1,
              minWidth: 150,
              verticalAlign: "center",
              order: column.order,
              resizeable: true,
              unit: column.unit,
              cellClassName: (params) => {
                return getCellColor(params.value, column.critical, column.warning);
              },
              valueGetter: (param) => {
                let value = param.value;
                return convertToHigherUnit(value, column.unit);
              },
            });
          });
          if (res.data.data[0].order) {
            columns = columns.sort((a, b) => {
              return a.order - b.order;
            });
          }
        }

        columns = [
          {
            field: "historyActions",
            type: "actions",
            flex: 0.1,
            hideable: false,
            getActions: (params) => [
              <Tooltip placement="right-start" title={"History"} disableInteractive>
                <GridActionsCellItem
                  icon={<HistoryIcon />}
                  label="History"
                  onClick={() => {
                    handleHistoryClick(params, "SG");
                    // const _navigateURL = `/telemetry-history/${params.row.deviceId}_${"SG"}_${params.row.NAME}(${params.row.SUBTYPE
                    //   })`;
                    // const searchParams = {
                    //   device_type: telemetryState.typeId,
                    //   subType: params.row.SUBTYPE === 'MAIN' ? 'NORMAL' : params.row.SUBTYPE
                    // };
                    // navigate({
                    //   pathname: _navigateURL,
                    //   search: createSearchParams(searchParams).toString(),
                    // });
                  }}
                />
              </Tooltip>,
            ],
          },
          ...FIXED_COLUMNS,
          ...columns,
        ];
        setSgColumns((state) => {
          state[subTableInfo.id] = columns ?? [];
        });
      })
      .catch(() => {
        setSgColumns((state) => {
          state[subTableInfo.id] = FIXED_COLUMNS;
        });
      });
  };
  const handleHistoryClick = (params, deviceType) => {
    let historyToastId = toast.loading("Fetching history");
    let cols = telemetryState.gridColumns;

    let getLastTsUrl = `${APP_BASE_URL}${GET_LAST_ENTRY_TS}`;
    let getLastTsParam = {
      deviceId: params.row.deviceId,
    };

    axios
      .get(getLastTsUrl, { params: getLastTsParam })
      .then((res) => {
        let deviceLastDataTs = res.data.data;

        let deviceName = encodeURIComponent(params.row.NAME);
        const url = `/telemetry-history/${params.row.deviceId}_${deviceType}_${deviceName}`;
        const searchParams = {
          device_type_id: telemetryState.typeId,
          start_date: new Date(deviceLastDataTs - 86400000).toISOString(),
          // end_date: new Date(deviceLastDataTs).toISOString(),
        };
        navigate({
          pathname: url,
          search: createSearchParams(searchParams).toString(),
        });
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.error?.message ?? "Failed to load history";
        toast.error(errorMessage);
      })
      .then(() => {
        toast.dismiss(historyToastId);
      });
  };
  const dynamicColumns = (deviceType, signal) => {
    setTelemetryState((draft) => {
      draft.gridColumns = [];
      draft.loader = true;
      draft.data = [];
    });

    let url = `${APP_BASE_URL}${GET_UNIVERSAL_DEVICE_KEYS}?customerIds=${customerIdList.toString()}&type=${deviceType}`;
    if (deviceType === "SG" && SG_TELEMETRY_TABLE_CONFIG) {
      SG_TELEMETRY_TABLE_CONFIG.forEach((subTableInfo) => {
        getSgColumns(subTableInfo);
      });
      getLatestTelemetry(deviceType, signal);

      localStorage.setItem("columns", JSON.stringify(sgColumns));
    } else {
      axios
        .get(url, { signal })
        .then((res) => {
          let obj = {};
          let columns = [];
          if (res.data.data.length > 0) {
            res.data.data.forEach((row) => {
              if (obj[row.key]) {
                obj[row.key]["critical"].push([row.cmin, row.cmax]);
                obj[row.key]["warning"].push([row.wmin, row.wmax]);
              } else {
                obj[row.key] = {
                  key: row.key,
                  alias: row.alias,
                  unit: row.unit,
                  critical: [[row.cmin, row.cmax]],
                  warning: [[row.wmin, row.wmax]],
                  order: row.order,
                };
              }
            });
            Object.values(obj).forEach((column, i) => {
              let headerName = column.alias ?? column.key;
              if (column?.unit && column?.unit !== "-" && column?.unit !== " " && !column?.unit?.includes("*")) {
                headerName = `${headerName} (${column.unit})`;
              }

              columns.push({
                key: i,
                field: column.key,
                headerName: headerName,
                sortable: true,
                flex: 1,
                minWidth: 150,
                verticalAlign: "center",
                resizeable: true,
                order: column.order,
                unit: column.unit,
                cellClassName: (params) => {
                  if (column.unit === "" || column.unit === null || column.unit === "-") {
                    if (params.value === "Free") {
                      return "blue";
                    } else if (params.value === "Occupied") {
                      return "orange";
                    } else if (params.value === "Occupied") {
                      return "orange";
                    }
                  } else {
                    return getCellColor(params.value, column.critical, column.warning);
                  }
                },
                valueGetter: (param) => {
                  let value = param?.value ?? null;
                  if (isValidNum(value)) {
                    value = Number(value);
                    const unit = column.unit;
                    if (unit && !unit?.startsWith("m")) {
                      value = (value / 1000).toFixed(2)
                    }
                  }
                  return value ?? '-'
                },
              });
            });
            if (res.data.data[0].order) {
              columns = columns.sort((a, b) => {
                return a.order - b.order;
              });
            }

            // if (deviceType === "BHMS") {
            //   let sortableColumns = [];
            //   let unSortableColumns = [];
            //   columns.forEach((a) => {
            //     if (Number(a.field.split("").splice(1).join(""))) {
            //       sortableColumns.push(a);
            //     } else {
            //       unSortableColumns.push(a);
            //     }
            //   });
            //   let sortedColumns = sortableColumns.sort((a, b) => {
            //     return (
            //       Number(a.field.split("").splice(1).join("")) -
            //       Number(b.field.split("").splice(1).join(""))
            //     );
            //   });

            //   columns = [...sortedColumns, ...unSortableColumns];
            // }
          }

          // if (deviceType === "TC") {
          //   columns = [...TC_COLUMNS, ...columns];
          // }

          columns = [
            {
              field: "historyActions",
              type: "actions",
              flex: 0.1,
              hideable: false,
              getActions: (params) => [
                <Tooltip placement="right-start" title={"History"} disableInteractive>
                  <GridActionsCellItem
                    icon={<HistoryIcon />}
                    label="History"
                    onClick={() => {
                      handleHistoryClick(params, deviceType);
                      // let cols = telemetryState.gridColumns;
                      // let deviceName = encodeURIComponent(params.row.NAME);
                      // const url = `/telemetry-history/${params.row.deviceId}_${deviceType}_${deviceName}`;
                      // // navigate(url, {
                      // //   state: { a: cols },
                      // // });
                      // const searchParams = {
                      //   device_type_id: telemetryState.typeId,
                      // };
                      // navigate({
                      //   pathname: url,
                      //   search: createSearchParams(searchParams).toString(),
                      //   // state: { a: cols },
                      // });
                    }}
                  />
                </Tooltip>,
              ],
            },
            ...FIXED_COLUMNS,
            ...columns,
          ];
          console.log("deviceType", deviceType);
          if (deviceType === Device.TC) {
            columns = [
              {
                field: "pm-chart",
                type: "actions",
                flex: 0.1,
                hideable: false,
                getActions: (params) => [
                  <Tooltip placement="right-start" title={"Circuit View"} disableInteractive>
                    <span>
                      <GridActionsCellItem
                        icon={<MemoryIcon />}
                        label="circuit-view-icon"
                        onClick={() => {
                          console.log("params", params);
                          const device_id = params.row.deviceId;
                          setCircuitViewModal((state) => {
                            state.open = true;
                            state.device_id = device_id;
                            state.gear_type = deviceType;
                          });
                        }}
                      />
                    </span>
                  </Tooltip>,
                ],
              },
              ...columns,
              ...PM_COLUMNS,
            ];
          }
          if (deviceType === Device.PM) {
            columns = [
              {
                field: "pm-chart",
                type: "actions",
                flex: 0.1,
                hideable: false,
                getActions: (params) => [
                  <Tooltip
                    placement="right-start"
                    title={
                      !params.row.EVENTDATA1 || !params.row.EVENTTIME
                        ? "Data Unavailable for graph"
                        : "Point Operation Graph"
                    }
                    disableInteractive
                  >
                    <span>
                      <GridActionsCellItem
                        icon={<TimelineIcon />}
                        label="pm-chart"
                        disabled={!params.row.EVENTDATA1 || !params.row.EVENTTIME}
                        onClick={() => {
                          if (params.row.EVENTDATA1 && params.row.EVENTTIME) {
                            setPointChartPop((prev) => {
                              prev.open = true;
                              prev.machineData = {
                                eventData: params.row.EVENTDATA1 ?? [],
                                eventTime: params.row.EVENTTIME ?? [],
                              };
                              prev.deviceId = params.row.deviceId;
                              prev.deviceName = params.row.NAME;
                              prev.currentTs = params.row.pointOprationTime;
                            });
                          }
                        }}
                      />
                    </span>
                  </Tooltip>,
                  <Tooltip placement="right-start" title={"Circuit View"} disableInteractive>
                    <span>
                      <GridActionsCellItem
                        icon={<MemoryIcon />}
                        label="circuit-view-icon"
                        onClick={() => {
                          console.log("params", params);
                          const device_id = params.row.deviceId;
                          setCircuitViewModal((state) => {
                            state.open = true;
                            state.device_id = device_id;
                            state.gear_type = deviceType;
                          });
                        }}
                      />
                    </span>
                  </Tooltip>,
                ],
              },
              ...columns,
              ...PM_COLUMNS,
            ];
          }
          setTelemetryState((draft) => {
            draft.gridColumns = columns ?? [];
          });
        })
        .then(() => {
          getLatestTelemetry(deviceType, signal);
        })
        .catch(() => {
          setTelemetryState((draft) => {
            draft.gridColumns = FIXED_COLUMNS;
          });
          getLatestTelemetry(deviceType, signal);
        });
    }
  };
  const getHeaderAndLatestTelemetry = (signal) => {
    let deviceType = telemetryState.deviceType ?? telemetryState.deviceTypeList[0].label;
    // let deviceType = telemetryState.deviceType ?? DEVICE_TYPES[0];

    if (telemetryState.deviceType === null) {
      setTelemetryState((state) => {
        state.deviceType = telemetryState.deviceTypeList[0].label;
        // draft.deviceType = DEVICE_TYPES[0];
      });
    }
    // ------- FISRT WE GET HEADER PART THEN CALL TELEMETRY API--------
    if (deviceType === "IPS") {
      getLatestTelemetry(deviceType, signal);
    } else {
      dynamicColumns(deviceType, signal);
    }
  };

  const TC_CHART_BTN = document.getElementById("TC_RADAR_BTN");
  if (TC_CHART_BTN) {
    TC_CHART_BTN.addEventListener("click", function (e) {
      setTcCharts((state) => {
        state.open = true;
      });
    });
  }

  const handleCircuitViewModalClose = useCallback(() => {
    setCircuitViewModal(INITIAL_TELEMETRY_CIRCUIT_VIEW_MODAL_STATE);
  }, []);

  return (
    <>
      {telemetryState.deviceType === "TC" && tcCharts.open && (
        <TcCharts
          telemetryData={telemetryState.data}
          close={() => {
            setTcCharts((state) => {
              state.open = false;
            });
          }}
          avalibleStation={selectedStations}
        />
      )}
      <div
        style={{ position: telemetryState.deviceType === "TC" && tcCharts.open ? "fixed" : "static" }}
        className="telemetry"
      >
        <div className="telemetry-header">
          <div className="telemetry-device-buttons flex flexWrap">
            {telemetryState.deviceTypeList.map((type, index) => (
              <Button
                variant="contained"
                className="telemetry-device-button"
                key={index + type}
                onClick={() => {
                  setTelemetryState((state) => {
                    state.deviceType = type.type;
                    state.deviceTypeLabel = type.label;
                    state.typeId = type.typeId;
                  });
                  sessionStorage.setItem("telemetry-selected-device-type", type.type);
                  sessionStorage.setItem("telemetry-selected-device-type-label", type.label);
                  sessionStorage.setItem("telemetry__selected-device-type--id", type.typeId);
                }}
                color={telemetryState.deviceType === type.type ? "primary" : "secondary"}
              >
                {type.label}
              </Button>
            ))}
          </div>
        </div>
        {telemetryState.deviceType === Device.IPS ? (
          <Box className="telemetry-device-ips flex flexWrap">
            {telemetryState.ipsData ? (
              telemetryState.ipsData.map((card, index) => {
                /*card is the desired data we want to pass in datacard
              hence card is passed as prop in data so if we want to use desired data we will use "data" keyword.*/
                return <DataCard key={card.value === Number ? card.value + index : 0 + index} data={card} />;
              })
            ) : (
              <DataTable
                loading={telemetryState.loader}
                className="telemetryTable"
                rows={[]}
                columns={telemetryState.gridColumns}
              />
            )}
          </Box>
        ) : telemetryState.deviceType === "SG" || telemetryState.deviceTypeList[0] === "SG" ? (
          <div class="telemetryTableArea" style={{ display: "flex", flexDirection: "column", rowGap: "1em", padding: '1em', overflow: 'auto' }}>
            {SG_TELEMETRY_TABLE_CONFIG && SG_TELEMETRY_TABLE_CONFIG.map((subTableInfo, i) => {
              const { label, id, subgears } = subTableInfo
              let _telemetryData = telemetryState.data
              if (subgears) {
                _telemetryData = _telemetryData.filter((dataRow) => {
                  return subgears.includes(dataRow.SUBGEAR)
                })
              }
              return <div className="sg-multi-table-area">
                <div className="telemetry-active-device flex">
                  <StackedLineChartIcon className="telemetry-active-device-icon" />
                  <Typography component="span" className="telemetry-active-device-text">
                    {label} Live Data
                  </Typography>
                </div>
                <div className="telemetryTableArea sg-table-area">
                  <DataTable
                    tableInfo={{
                      id: `telemetry-${telemetryState.deviceType}-table`,
                      label: `Telemetry ${telemetryState.deviceType}`,
                      requireAuthSign: false,
                    }}
                    pageSize={pageSize}
                    pageSizeChange={(size) => {
                      setPageSize(size);
                    }}
                    style={{ height: "40em !important" }}
                    loading={telemetryState.loader}
                    // className="telemetryTable"
                    rows={_telemetryData}
                    columns={sgColumns[id] ?? []}
                    columnVisibilityModel={initialHiddenColumns}
                    rowColor={(params) => {
                      return params.row.testMode ? "changeRowColor" : "";
                    }}
                    customPagination={true}
                  />
                </div>
              </div>
            })}
          </div>
        ) : telemetryState.deviceType === Device.CableMonitoring ? (
          <>
            <div className="telemetry-active-device flex">
              {telemetryState.deviceTypeLabel && (
                <>
                  <StackedLineChartIcon className="telemetry-active-device-icon" />
                  <Typography component="span" className="telemetry-active-device-text">
                    {telemetryState.deviceTypeLabel ?? telemetryState.deviceTypeList[0].label} Live Data
                  </Typography>
                </>
              )}
            </div>
            <div className="telemetryTableArea">
              <CableMonitoring state={telemetryState} />
            </div>
          </>
        ) : telemetryState.deviceType === Device.BHMS ? (
          <>
            <div className="telemetry-active-device flex">
              {telemetryState.deviceTypeLabel && (
                <>
                  <StackedLineChartIcon className="telemetry-active-device-icon" />
                  <Typography component="span" className="telemetry-active-device-text">
                    {telemetryState.deviceTypeLabel ?? telemetryState.deviceTypeList[0].label} Live Data
                  </Typography>
                </>
              )}
            </div>
            <div style={{ height: "100%" }} className="flex flex-col">
              <div className="flex items-center gap-2 p-2">
                <Button
                  variant={selectedBhmsSubTab === "graphical" ? "contained" : "outlined"}
                  onClick={() => setSelectedBhmsSubTab("graphical")}
                >
                  Graphical
                </Button>
                <Button
                  variant={selectedBhmsSubTab === "tabular" ? "contained" : "outlined"}
                  onClick={() => setSelectedBhmsSubTab("tabular")}
                >
                  Tabular
                </Button>
              </div>
              <div className="telemetryTableArea">
                {selectedBhmsSubTab === "graphical" ? (
                  <BhmsWrapper state={telemetryState} />
                ) : (
                  <DataTable
                    tableInfo={{
                      id: `telemetry-${telemetryState.deviceType}-table`,
                      label: `Telemetry ${telemetryState.deviceType}`,
                      requireAuthSign: false,
                    }}
                    pageSize={pageSize}
                    pageSizeChange={(size) => {
                      setPageSize(size);
                    }}
                    loading={telemetryState.loader}
                    className="telemetryTable"
                    rows={telemetryState.data}
                    columns={telemetryState.gridColumns}
                    columnVisibilityModel={initialHiddenColumns}
                  />
                )}
              </div>
            </div>
            {/* <div className="telemetryTableArea">
          </div> */}
          </>
        ) : (
          <>
            <div className="telemetry-active-device flex fullHeight">
              {telemetryState.deviceTypeLabel && (
                <>
                  <StackedLineChartIcon className="telemetry-active-device-icon" />
                  <Typography component="span" className="telemetry-active-device-text">
                    {telemetryState.deviceTypeLabel ?? telemetryState.deviceTypeList[0].label} Live Data
                  </Typography>
                </>
              )}
            </div>
            <div className="telemetryTableArea">
              <DataTable
                tableInfo={{
                  id: `telemetry-${telemetryState.deviceType}-table`,
                  label: `Telemetry ${telemetryState.deviceType}`,
                  requireAuthSign: false,
                }}
                pageSize={pageSize}
                pageSizeChange={(size) => {
                  setPageSize(size);
                }}
                loading={telemetryState.loader}
                className="telemetryTable"
                rows={telemetryState.data}
                columns={telemetryState.gridColumns}
                columnVisibilityModel={initialHiddenColumns}
                rowColor={(params) => {
                  if (telemetryState.deviceType === "PM") {
                    // return params.row.OT > 6000 ? "otColor" : "";
                  } else {
                    // return params.row.testMode ? "testModeColor" : "";
                  }
                }}
              />
            </div>
          </>
        )}
        {circuitViewModal.open && (
          <TelemetryCircuitViewModal {...circuitViewModal} handleClose={handleCircuitViewModalClose} />
        )}
        {pointChartPop.open && (
          <PointChartPopup
            open={pointChartPop.open}
            machineData={pointChartPop.machineData}
            deviceId={pointChartPop.deviceId}
            currentTs={pointChartPop.currentTs}
            deviceName={pointChartPop.deviceName}
            onClose={() => {
              setPointChartPop((prev) => {
                prev.open = false;
              });
            }}
          />
        )}
      </div>
    </>
  );
};
export default Telemetry;
