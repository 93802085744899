import { useContext, useState } from "react";
import { GridColumns } from "@mui/x-data-grid";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import DataTable from "../DataGrid/DataGrid";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { RTUHealth, RTUHealthPacket, health_monitoring_api } from "./HealthMonitoringAPI";

const DEFAULT_FILTERS = {
  index: 0,
  pageSize: 20,
  //   status: null,
  //   is_verified: null,
  //   token_like: null
};

type HealthMonitoringFiltersType = {
  index: number;
  pageSize: number;
  status?: "ONLINE" | "OFFLINE";
  is_verified?: boolean;
  token_like?: string;
};

interface HealthMonitoringFiltersProps {
  state: HealthMonitoringFiltersType;
  handleFilterChange: (key: keyof HealthMonitoringFiltersType, value: string | boolean | null) => void;
  resetFilters: () => void;
  rtu_health: RTUHealth[];
}
const HealthMonitoringFilters: React.FC<HealthMonitoringFiltersProps> = ({
  state,
  handleFilterChange,
  resetFilters,
  rtu_health,
}) => {
  return (
    <div className="flex w-full gap-2 items-center mb-4 bg-white p-2" style={{ borderRadius: "0.5rem" }}>
      <TextField
        label="Token"
        style={{ width: "30rem" }}
        placeholder="Token"
        size="small"
        value={state.token_like ?? ""}
        onChange={(event) => handleFilterChange("token_like", event.target.value?.toUpperCase())}
      />
      <FormControl size="small" style={{ width: "20rem" }}>
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          label="Status"
          labelId="status-label"
          value={state.status ?? ""}
          onChange={(event) => handleFilterChange("status", event.target.value)}
        >
          <MenuItem value={"ONLINE"}>ONLINE</MenuItem>
          <MenuItem value={"OFFLINE"}>OFFLINE</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" style={{ width: "20rem" }}>
        <InputLabel id="verified-label">Verified</InputLabel>
        <Select
          label="Verified"
          labelId="verified-label"
          value={state.is_verified ?? ""}
          onChange={(event) => handleFilterChange("is_verified", event.target.value === "verified" ? true : false)}
        >
          <MenuItem value={"verified"}>Verified</MenuItem>
          <MenuItem value={"not-verified"}>Not Verified</MenuItem>
        </Select>
      </FormControl>
      <Button onClick={resetFilters} variant="outlined">
        Clear
      </Button>
      <div
        style={{ padding: "0 0.5rem", gap: "1rem", width: "max-content" }}
        className="flex justify-between items-center"
      >
        {rtu_health.map((health_packet) => (
          <div key={health_packet.col_name}>
            {health_packet.col_name} : {health_packet.cnt}
          </div>
        ))}
      </div>
    </div>
  );
};

export const HEALTH_MONITORING_COLUMNS: GridColumns<RTUHealthPacket> = [
  {
    field: "token",
    headerName: "Token",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 155,
    hideable: true,
    flex: 1,
  },
  {
    field: "formatted_date",
    headerName: "Last Updated Time",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 155,
    hideable: true,
    flex: 1,
  },
  {
    field: "location",
    headerName: "Location",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 155,
    hideable: true,
    flex: 1,
    valueGetter: function (row_data) {
      const location = row_data.row.data.LOC;
      return location;
    },
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 155,
    hideable: true,
    flex: 1,
    renderCell: function (params) {
      const value = params.row.status;
      const bg_color = value === "OFFLINE" ? "red" : "green";
      return (
        <div
          style={{ backgroundColor: bg_color, height: "100%", color: "white", fontWeight: "bold" }}
          className="w-full h-full flex items-center justify-center"
        >
          {value}
        </div>
      );
    },
  },
];

export const HEALTH_MONITORING_TABLE_INFO = {
  id: `rtu-health-monitoring-table`,
  label: `RTU Health Monitoring`,
  requireAuthSign: false,
  timestamp: { startTs: null, endTs: null },
};

const DEFAULT_DATA = { items: [], totalRecords: 0 };

const HealthMonitoring = () => {
  const [filters, setFilters] = useState<HealthMonitoringFiltersType>(DEFAULT_FILTERS);
  const { customerIdList }: any = useContext(ActiveAlarmsData);
  const { index, pageSize, ...rest_filters } = filters;
  const { data = DEFAULT_DATA, isFetching: loading } = health_monitoring_api.useGetPaginatedRTUHealthQuery(
    {
      limit: filters.pageSize,
      offset: filters.index * filters.pageSize,
      customer_ids: customerIdList.toString(),
      ...rest_filters,
    },
    { pollingInterval: 30000 },
  );
  const { data: RtuHealthStatus = [], isFetching: fetchingRtuHealthStatus } =
    health_monitoring_api.useGetRtuHealthStatusQuery(
      {
        limit: filters.pageSize,
        offset: filters.index * filters.pageSize,
        customer_ids: customerIdList.toString(),
        ...rest_filters,
      },
      { pollingInterval: 30000 },
    );

  const rows = data?.items;
  const rowCount = data?.totalRecords;

  function handleFilterChange(key: keyof HealthMonitoringFiltersType, value: string | boolean | null) {
    setFilters((state) => ({ ...state, [key]: value }));
  }

  function handleFiltersReset() {
    setFilters(DEFAULT_FILTERS);
  }

  return (
    <div className="p-2">
      <HealthMonitoringFilters
        state={filters}
        handleFilterChange={handleFilterChange}
        resetFilters={handleFiltersReset}
        rtu_health={RtuHealthStatus}
      />
      <div style={{ height: "100%", backgroundColor: "white", padding: "0.5rem" }}>
        <DataTable
          tableInfo={HEALTH_MONITORING_TABLE_INFO}
          loading={loading}
          rows={rows}
          columns={HEALTH_MONITORING_COLUMNS}
          pageChange={(index: number) => setFilters((state) => ({ ...state, index }))}
          pageSizeChange={(pageSize: number) => setFilters((state) => ({ ...state, index: 0, pageSize }))}
          pageIndex={filters.index}
          pageSize={filters.pageSize}
          rowCount={rowCount}
          paginationMode="server"
          showFilterButton={false}
        />
      </div>
    </div>
  );
};

export default HealthMonitoring;
