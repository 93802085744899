import React, { useEffect } from "react";
import Loader from "../../../Loader/Loader";
import { useLocation } from "react-router";
import { GROUP_CONFIG, KEYS_CONFIG } from "./keyGroupingConfig";
import {
    getChartLabelAndDatasets,
    getGroupWiseMinMax,
    getGroupedChartAnnotation,
    getMaxValueOfYaxis,
    getParsedAndSortedHistoryData,
    getTransformedHistoryData,
    retrieveTransformedValue,
} from "./keyGroupChartUtils";
import { Line } from "react-chartjs-2";
import { useImmer } from "use-immer";


const initChartData = {
    labels: [],
    datasets: [
        {
            label: "No Data Available",
            data: [],
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 2,
            fill: false,
        },
    ],
};

const KeyGroupedChartComponent = (props) => {
    const { transformedHistoryData, totalGroupes, totalKeys, groupWiseVirtualMinMax } = props;
    const [keyGroupChartData, setKeyGroupChartData] = useImmer(initChartData);
    const MAX_VALUE_FOR_Y_AXIS = getMaxValueOfYaxis(totalGroupes)
    const groupedChartAnnotations = getGroupedChartAnnotation(groupWiseVirtualMinMax, MAX_VALUE_FOR_Y_AXIS, totalGroupes);
    useEffect(() => {
        const { dataSets, timestampChartLabels } = getChartLabelAndDatasets(transformedHistoryData, totalKeys);
        setKeyGroupChartData((prev) => {
            prev.labels = timestampChartLabels;
            prev.datasets = dataSets;
        });
    }, [transformedHistoryData]);

    const keyGroupChartOption = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
            callbacks: {
                intersect: function () { },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                type: "linear",
                position: "left",
                max: MAX_VALUE_FOR_Y_AXIS,
                min: 0,
                scaleOverride: true,
                grid: {
                    display: false,
                },
                ticks: {
                    stepSize: 2,
                    font: {
                        size: 14,

                        fontStyle: 'bold',
                    },
                    autoSkip: false,
                    callback: (value) => {
                        let tickLabel = "";
                        Object.entries(totalGroupes).forEach(([groupIndex, groupInfo]) => {
                            const { actualUnitsForYaxis, unit } = groupInfo;
                            const virtualMinMAx = groupWiseVirtualMinMax[groupIndex];
                            const startTickVal = virtualMinMAx.virtualMin;
                            const endTick = virtualMinMAx.virtualMax;
                            if (startTickVal === value) {
                                tickLabel = `${0} ${unit}`;
                            }
                            if (endTick === value) {
                                tickLabel = `${actualUnitsForYaxis} ${unit}`;
                            }
                        });
                        return tickLabel;
                    },
                },
            },
        },
        plugins: {
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
                align: "right",
            },
            legend: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const key = context.dataset.dataKey;
                        const value = context.raw;
                        const formattedValue = retrieveTransformedValue(key, value, groupWiseVirtualMinMax, totalGroupes);
                        return `${totalKeys?.[key]?.label ?? key} : ${formattedValue}`;
                    },
                },
            },
            datalabels: {
                formatter: function () {
                    return "";
                },
            },
            annotation: {
                annotations: groupedChartAnnotations,
            },
        },
    };
    return <Line data={keyGroupChartData} options={keyGroupChartOption} />;
};

function KeyGroupedHistoryChart(props) {
    let location = useLocation();
    // const deviceType = location.pathname.split("/")[2].split("_")[1];
    const { loading, graphState, deviceType } = props;
    const GROUPS = GROUP_CONFIG?.[deviceType] ?? null;
    const TOTAL_KEYS = KEYS_CONFIG?.[deviceType] ?? null;

    if (loading) {
        return <Loader />;
    } else {
        if (GROUPS && TOTAL_KEYS) {
            const groupWiseVirtualMinMax = getGroupWiseMinMax(GROUPS);
            const rawData = graphState?.data?.data?.data?.content ?? [];
            const groupWiseParseData = getTransformedHistoryData(rawData, groupWiseVirtualMinMax, GROUPS, TOTAL_KEYS);

            return (
                <KeyGroupedChartComponent
                    transformedHistoryData={groupWiseParseData}
                    totalGroupes={GROUPS}
                    totalKeys={TOTAL_KEYS}
                    groupWiseVirtualMinMax={groupWiseVirtualMinMax}
                />
            );
        } else {
            return <div>No config found</div>;
        }
    }
}

export default KeyGroupedHistoryChart;
