import { useSearchParams } from "react-router-dom";
import { AlarmNotificationMessageProps } from "./notification-toast-types";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";

type AlarmNotificationToastProps = {
  message: AlarmNotificationMessageProps;
  toast_id: string;
};

export const NotificationToast = (props: AlarmNotificationToastProps) => {
  const { message, toast_id } = props;

  const type = message.data?.type;

  const [searchParams, setSearchParams] = useSearchParams();

  function handleToastClose() {
    toast.dismiss(toast_id);
  }

  function handleToastClick() {
    if (type !== "ALARM") return;
    const alarm_id = message.data.alarm_id;
    if (!alarm_id) return;
    searchParams.set("show_info", alarm_id);
    setSearchParams(searchParams);
    handleToastClose();
  }

  return (
    <div style={{ width: "26rem" }} className="flex gap-1">
      <div className={"flex flex-col gap-2 " + (type === "ALARM" ? "cursor-pointer" : "")} onClick={handleToastClick}>
        <div className="alarm-notification__row--title">{message.notification.title}</div>
        <div className="alarm-notification__row--description">{message.notification.body}</div>
      </div>
      <CloseIcon className="cursor-pointer" onClick={handleToastClose} />
    </div>
  );
};
export default NotificationToast;
