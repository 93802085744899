import React, { useContext } from 'react'
import './EditToolBox.scss'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useImmer } from "use-immer";
import { FormContext } from '../../FormCreator';
import { getNodeByType } from '../../Constats';

function removeFieldByKey(jsonArray, keyToRemove) {
  for (let i = 0; i < jsonArray.length; i++) {
    const field = jsonArray[i];
    if (field.key === keyToRemove) {
      jsonArray.splice(i, 1);
      i--;
    } else if (field.sectionFields) {
      removeFieldByKey(field.sectionFields, keyToRemove);
    }
  }
  return jsonArray;
}



function EditToolBox({ index, fieldInfo, onEditClick }) {
  const { formData, replaceFormData } = useContext(FormContext)
  const handleDelete = (i, fieldInfo) => {
    let newFormData = [...formData]
    newFormData = removeFieldByKey(newFormData, fieldInfo.key)
    // newFormData.splice(i,1)
    replaceFormData(newFormData)
  }
  const handleEditClick = (e, fieldInfo) => {
    e.stopPropagation()
    let nodeInfo = getNodeByType[fieldInfo.type]
    console.log('nodeinfo', nodeInfo)
    onEditClick(nodeInfo, null, fieldInfo)
  }
  return (
    <div className='editToolBox'>
      <EditIcon onClick={(e) => { handleEditClick(e, fieldInfo) }} />
      <DeleteIcon onClick={(e) => { handleDelete(index, fieldInfo) }} />
    </div>
  )
}

export default EditToolBox