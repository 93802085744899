import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { bitcommLogo } from "./PdfImages";
import {
  DataGrid,
  GridRowId,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarQuickFilter,
  GridRowHeightParams,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import "./DataGrid.scss";
import { Box, Button, Tooltip } from "@mui/material";

import * as React from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import {
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  gridVisibleColumnDefinitionsSelector,
  GridApi,
} from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { ClientFilterPanel } from "./Components/FilterPanel/ClientFilterPanel";
import { toast } from "react-hot-toast";
type Props = {
  tableInfo?: {
    id: string;
    label: string;
    requireAuthSign: boolean;
    timestamp: any;
  };
  rows: any[];
  columns: any[];
  onRowClick?: (e: any) => void;
  onCellClick?: (e: any) => void;
  pageChange?: any;
  pageSizeChange?: any;
  paginationMode?: "client" | "server";
  rowCount?: number;
  pageSize?: number;
  pageIndex?: number;
  loading?: boolean;
  filterChange?: any;
  customFilterPanel?: any;
  filterModel?: any;
  columnGroupingModel?: any[];
  columnVisibilityModel?: any;
  handleVisibilityModelChange?: (visibility_model: GridColumnVisibilityModel) => void;
  rowColor?: any;
  selectionModel?: any;
  showFilterButton?: boolean;
  selectionModelChange?: () => void;
  serverSideExport?: any;
  hideExportPDF?: boolean;
};
const DEFAULT_PAGE_SIZE = 20;
export const NoData = () => {
  return (
    <Box className="dataGridLoading flex justifyCenter alignCenter fullWidth fullHeight">No Data Available...</Box>
  );
};

const getJson = (apiRef: React.MutableRefObject<GridApi>, tableInfo: any) => {
  const toastId = toast.loading("Exporting...");
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  let visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  const visibleColumns = gridVisibleColumnDefinitionsSelector(apiRef);
  const _columns: any = {};
  for (const _field of visibleColumns) {
    _columns[_field.field] = _field.headerName;
  }
  visibleColumnsField = visibleColumnsField.filter((column: any) => {
    return !["historyActions", "info", "rosterActions", "editActions"].includes(column);
  });

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row: Record<string, any> = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  const logoForPdf = bitcommLogo;

  const columnsForPdf = visibleColumnsField.map((column: string) => {
    let label = _columns[column];
    label = (label.charAt(0).toUpperCase() + label.slice(1)).replace(/_/g, " ");
    return { header: label, dataKey: column };
  });

  const doc: any = new jsPDF(`${columnsForPdf.length < 8 ? "p" : "l"}`, "pt", "a4");
  // const doc: any = new jsPDF('l', "pt", "a4");
  const tsToDateConverter = (ts: any) => {
    return new Date(ts).toLocaleDateString() + " " + new Date(ts).toLocaleTimeString();
  };
  let header: any = [];
  if (tableInfo.id === "maintenance-roaster-table") {
    let GroupingHeader: any = [
      {
        content: "GENERAL INFORMATION",
        colSpan: 4,
        styles: { halign: "center", fillColor: [22, 160, 133] },
      },
      {
        content: "PERIODIC MAINTENANCE",
        colSpan: 6,
        styles: { halign: "center", fillColor: [22, 160, 133] },
      },
      {
        content: "PREDICTIVE MAINTENANCE",
        colSpan: 7,
        styles: { halign: "center", fillColor: [22, 160, 133] },
      },
    ];
    let columnsForGrouping = columnsForPdf.map((item) => {
      return item.header;
    });
    header.push(GroupingHeader, columnsForGrouping);
  }
  autoTable(doc, {
    head: header,
    body: data,
    columns: columnsForPdf,

    headStyles: {
      font: "times",
      fontStyle: "bold",
    },
    startY: 85,
    margin: { left: 5 },
    showHead: "everyPage",
    styles: {
      /**
       * @returns {number} font size according to the number of columns we have
       */
      fontSize:
        visibleColumnsField.length < 8
          ? 10
          : visibleColumnsField.length < 15
          ? 8
          : visibleColumnsField.length < 19
          ? 8
          : 100, //for non roster
      cellWidth: (doc.internal.pageSize.width - 10) / visibleColumnsField.length,
    },

    didParseCell: function (data) {
      data.cell.styles.lineColor = 170;
      data.cell.styles.lineWidth = 1;
      data.cell.styles.cellPadding = visibleColumnsField.length > 17 ? 1 : 5;
      if (data.section === "body" && data.cell.raw === "DUE") {
        data.cell.styles.textColor = "orange";
      }
      if (data.section === "body" && data.cell.raw === "OVERDUE") {
        data.cell.styles.textColor = "red";
      }
      if (data.section === "body" && data.cell.raw === "DONE") {
        data.cell.styles.textColor = "green";
      }
      if (data.section === "body" && data.column.dataKey === "alarm") {
        data.cell.styles.fontSize = 6.6;
        data.cell.styles.valign = "middle";
      }
    },
    didDrawPage: function (data) {
      // let title = location.pathname.split("/")[1].toUpperCase() + " " + "DATA";

      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.setFontSize(10);
      doc.setTextColor(45);
      if (data.pageNumber === 1) {
        //top heading and logo
        doc.addImage(logoForPdf, "JPEG", 10, 5, 78, 32);
        doc.setFontSize(22);
        doc.text(
          tableInfo.label + " " + "Data",
          doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(tableInfo.label) * doc.internal.getFontSize()) / 2 -
            22,
          30,
        );

        doc.setFontSize(10);
        doc.setTextColor(45);
        //left part

        // let Stations = "Stations:  " + localStorage.getItem("STN");
        // let zoneText = "Zone:  " + userStationDetail.zone;
        // let divisionText = "Divison:  " + userStationDetail.division;
        // let stnText = "Station:  " + userStationDetail.station;
        // if (tableInfo?.timestamp?.startTs && tableInfo?.timestamp?.endTs) {
        //   doc.text(
        //     Stations,
        //     doc.internal.pageSize.width / 2 - (doc.getStringUnitWidth(Stations) * doc.internal.getFontSize()) / 2,
        //     65,
        //   );
        //   // doc.text(
        //   //   "From Date:  " + tsToDateConverter(tableInfo.timestamp.startTs),
        //   //   8,
        //   //   60
        //   // );
        //   // doc.text(
        //   //   "To Date:  " + tsToDateConverter(tableInfo.timestamp.endTs),
        //   //   8,
        //   //   72
        //   // );
        //   // //middle part
        //   // doc.text(
        //   //   zoneText,
        //   //   doc.internal.pageSize.width / 2 -
        //   //     (doc.getStringUnitWidth(zoneText) * doc.internal.getFontSize()) /
        //   //       2,
        //   //   52
        //   // );
        //   // doc.text(
        //   //   divisionText,
        //   //   doc.internal.pageSize.width / 2 -
        //   //     (doc.getStringUnitWidth(divisionText) *
        //   //       doc.internal.getFontSize()) /
        //   //       2,
        //   //   65
        //   // );
        //   // doc.text(
        //   //   stnText,
        //   //   doc.internal.pageSize.width / 2 -
        //   //     (doc.getStringUnitWidth(stnText) * doc.internal.getFontSize()) /
        //   //       2,
        //   //   77
        //   // );
        // } else {
        //   //middle part
        //   doc.text(Stations, data.settings.margin.left + 25, 65);
        //   // doc.text(zoneText, data.settings.margin.left + 25, 52);
        //   // doc.text(divisionText, data.settings.margin.left + 25, 65);
        //   // doc.text(stnText, data.settings.margin.left + 25, 77);
        // }
        //right part
        let genratedTime = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
        doc.text("Generated Time: ", doc.internal.pageSize.width - 140, 60);
        doc.text(genratedTime, doc.internal.pageSize.width - 150, 72);
      }
    },
  });

  const title = ``;
  // const heading = `<div style="width: 100%; display:flex; justify-content: center;margin:5px 0px;font-weight: 500;font-size: 19px;"><div style="margin: 0px; padding: 0px;" >${title} Data</div></div>`;
  // const heading = title;

  doc.html(title, {
    callback: function (pdf: any) {
      addFooters(pdf, tableInfo);
      pdf.save(tableInfo.label + "  " + "DATA.pdf");
      toast.dismiss(toastId);
    },
    x: 0,
    y: 0,
  });
};
//(doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);

const addFooters = (doc: any, tableInfo: any) => {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(10);
  doc.setTextColor(45);
  doc.setPage(pageCount);
  var footerText = "Documentation generated by Bitcomm Technologies Pvt. Ltd.";
  let linkText = "www.bitcommtechnologies.com";
  var footerAuth = "Authorised Signature";

  doc.text(
    footerText,
    doc.internal.pageSize.width / 2 - (doc.getStringUnitWidth(footerText) * doc.internal.getFontSize()) / 2,
    doc.internal.pageSize.getHeight() - 30,
  );
  doc.line(
    150,
    doc.internal.pageSize.getHeight() - 25,
    doc.internal.pageSize.width - 150,
    doc.internal.pageSize.getHeight() - 25,
    "F",
  );

  if (tableInfo.requireAuthSign) {
    doc.text(footerAuth, doc.internal.pageSize.width - 120, doc.internal.pageSize.getHeight() - 40);
    doc.rect(doc.internal.pageSize.width - 115, doc.internal.pageSize.getHeight() - 35, 80, 26, "S");
  }
  doc.setTextColor(25, 118, 210);
  doc.textWithLink(
    linkText,
    doc.internal.pageSize.width / 2 - (doc.getStringUnitWidth(linkText) * doc.internal.getFontSize()) / 2,
    doc.internal.pageSize.getHeight() - 12,
    { url: "https://www.bitcommtechnologies.com" },
  );
};
const PdfExportMenuItem = (props: any) => {
  const apiRef = useGridApiContext();
  const { hideMenu, tableInfo } = props;

  return (
    <MenuItem
      onClick={() => {
        getJson(apiRef, tableInfo);
        // Hide the export menu after the || option.label === ""export
        hideMenu?.();
      }}
    >
      Export as PDF
    </MenuItem>
  );
};
export const CustomToolbarExport = ({
  csvOptions,
  printOptions,
  tableInfo,
  serverSideExport,
  hideExportPDF,
  visibility,
  ...rest
}: any) => {
  if (serverSideExport) {
    return (
      <GridToolbarExportContainer>
        {!hideExportPDF && <MenuItem onClick={() => serverSideExport("pdf",visibility)}>Export as PDF</MenuItem>}
        <MenuItem onClick={() => serverSideExport("csv",visibility)}>Export as CSV</MenuItem>
      </GridToolbarExportContainer> 
    );
  }
  return (
    <GridToolbarExportContainer {...rest}>
      <GridCsvExportMenuItem options={csvOptions} />
      {/* <GridPrintExportMenuItem options={printOptions} /> */}
      {!hideExportPDF && <PdfExportMenuItem tableInfo={tableInfo} />}
    </GridToolbarExportContainer>
  );
};
export const CustomToolbar = (props: any) => {
  const {
    showQuickFilter,
    fullScreen,
    showFilterButton,
    tableInfo,
    tcGraphButton,
    serverSideExport,
    hideExportPDF,
    visibility,
    ...rest
  } = props;
  return (
    <GridToolbarContainer className="custom-toolbar flex spaceBetween" {...rest}>
      <div>
        <GridToolbarColumnsButton />
        {showFilterButton && <GridToolbarFilterButton />}
        <GridToolbarDensitySelector />
        <CustomToolbarExport hideExportPDF={hideExportPDF} tableInfo={tableInfo} serverSideExport={serverSideExport} visibility={visibility}/>
        {tableInfo.id === "telemetry-TC-table" && (
          <>
            <Tooltip placement="bottom" title={"TC CHARTS"} disableInteractive>
              <Button id="TC_RADAR_BTN" className="TC-CHART-BTN">
                TC Charts
              </Button>
            </Tooltip>
          </>
        )}
      </div>
      <div>
        <span>{showQuickFilter && <GridToolbarQuickFilter />}</span>
        <span>
          <Button
            className="fullscreen-icon"
            onClick={() => {
              fullScreen();
            }}
          >
            <FullscreenIcon />
          </Button>
        </span>
      </div>
    </GridToolbarContainer>
  );
};
export function DataTable({
  tableInfo,
  rows,
  columns,
  onRowClick,
  onCellClick,
  rowColor,
  pageChange,
  pageSizeChange,
  paginationMode,
  rowCount,
  pageSize,
  pageIndex,
  loading,
  customFilterPanel,
  filterChange,
  filterModel,
  columnGroupingModel,
  columnVisibilityModel,
  handleVisibilityModelChange,
  selectionModel,
  selectionModelChange,
  showFilterButton = true,
  serverSideExport,
  hideExportPDF = false,
}: Props) {
  const [fullScreen, setFullScreen] = useState(false);
  var columnsInStorage = localStorage.getItem("data-grid-columns-visibility");
  var [columnsVisibility, setColumnsVisibility]: any = React.useState({});
  const [columnFilter, setColumnFilter] = useState([{ columnField: "", columnValue: "" }]);
  const [tableRows, setTableRows]: any = useState([]);
  const [columnDisplayName, setColumnDisplayName]: any = useState({});

  React.useEffect(() => {
    filterRows(columnFilter);
  }, [columnFilter, rows]); //, rows

  React.useEffect(() => {
    //console.log("useEffect --> columnDisplayName --> ", columnDisplayName, columns);
    if (Object.keys(columnDisplayName).length < 3 && columns.length > 3) {
      let ele: any = {};
      columns.forEach((item) => {
        ele[item.field] = {
          displayName: item.headerName,
          unit: item.unit,
          isConverted: ["v", "a", "s"].includes((item.unit ?? "NA").toLowerCase()),
        };
      });
      setColumnDisplayName(ele);
    }
  }, [columnDisplayName, columns]);

  /**
   * filters the table rows for client side filtering
   */
  const filterRows = React.useCallback(
    (filterValues: any) => {
      const filteredRows = rows.filter((rowToFilter) => {
        let valueIsPresent = true;
        for (let i = 0; i < filterValues.length; i++) {
          let isConvertedValue = false;
          isConvertedValue = columnDisplayName?.[filterValues[i]?.columnField ?? ""]?.isConverted ?? false;
          //console.log("isConvertedValue --> ", isConvertedValue, filterValues[i], columnDisplayName);
          let searchValue =
            typeof rowToFilter?.[filterValues[i]?.columnField] === "boolean" ||
            typeof rowToFilter?.[filterValues[i]?.columnField] === "number"
              ? rowToFilter?.[filterValues[i]?.columnField].toString()
              : rowToFilter?.[filterValues[i]?.columnField];

          if (isConvertedValue) {
            searchValue = (Number(searchValue) / 1000 === 0 ? 0 : (Number(searchValue) / 1000).toFixed(2)).toString();
          }
          /**
           * if columnField or columnValue is empty, null or undefined then continue this loop
           */
          if (!filterValues[i].columnValue || !filterValues[i].columnField) continue;
          if (!valueIsPresent) break;
          if (!searchValue?.toUpperCase()?.includes(filterValues[i]?.columnValue?.toUpperCase())) {
            valueIsPresent = false;
          }
        }
        return valueIsPresent;
      });
      setTableRows(filteredRows);
    },
    [columnFilter, rows],
  );

  React.useEffect(() => {
    const storedColumnsVisibility = columnsInStorage
      ? tableInfo?.id
        ? JSON.parse(columnsInStorage)[tableInfo.id] ?? {}
        : {}
      : {};
    // if (!columnVisibilityModel) {
    //   setColumnsVisibility(newColumnsVisibility);
    // } else {
    //   setColumnsVisibility(columnVisibilityModel);
    // }
    const storedColumnsVisibilityLength = Object.keys(storedColumnsVisibility).length;
    const _visibility_model = storedColumnsVisibilityLength > 0 ? storedColumnsVisibility : columnVisibilityModel ?? {};
    setColumnsVisibility(_visibility_model);
  }, [tableInfo, columnVisibilityModel]);

  function columnVisibilityModelChange(visibilityModel: GridColumnVisibilityModel) {
    console.log("visibility_model", visibilityModel);
    setColumnsVisibility(visibilityModel);
    handleVisibilityModelChange?.(visibilityModel);
    if (!tableInfo?.id) return;
    var parsedColumns = {};
    if (columnsInStorage) {
      parsedColumns = JSON.parse(columnsInStorage);
    }
    parsedColumns = {
      ...parsedColumns,
      [tableInfo.id]: visibilityModel,
    };
    localStorage.setItem("data-grid-columns-visibility", JSON.stringify(parsedColumns));
  }
  return (
    <div className={fullScreen ? "data-table-fullScreen" : "fullWidth data-table"}>
      <DataGrid
        filterModel={filterModel}
        loading={loading}
        rows={tableRows}
        columns={columns}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={pageSize ?? DEFAULT_PAGE_SIZE}
        page={pageIndex ?? undefined}
        filterMode={paginationMode ?? "client"}
        onFilterModelChange={filterChange}
        rowCount={rowCount ?? undefined}
        hideFooterSelectedRowCount={true}
        // getRowHeight={({ id, densityFactor }: GridRowHeightParams) => {
        //   if ((id as number) % 2 === 0) {
        //     return 60 * densityFactor;
        //   }
        // }}
        experimentalFeatures={{ columnGrouping: true }}
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: NoData,
          FilterPanel: customFilterPanel ?? ClientFilterPanel,
        }}
        columnGroupingModel={columnGroupingModel}
        componentsProps={{
          toolbar: {
            showQuickFilter: paginationMode ? paginationMode === "client" : true,
            showFilterButton: showFilterButton,
            fullScreen: () => {
              setFullScreen(!fullScreen);
            },
            tableInfo: tableInfo,
            serverSideExport: paginationMode === "server" && serverSideExport ? serverSideExport : null,
            hideExportPDF: hideExportPDF ? hideExportPDF : false,
            visibility: columnsVisibility,
            // tcGraphButton: ()=>{
            //   console.log('clicked')
            // }
          },
          filterPanel: {
            columnFilter: columnFilter,
            totalColumns: columns,
            visibility: columnsVisibility,
            filterSearch: (filterValues: any) => {
              //setColumnFilter(filterValues.map((item: any) => ({...item, isConvertedValue: columnDisplayName[item.columnField]})));
              setColumnFilter(filterValues);
            },
          },
        }}
        paginationMode={paginationMode ?? "client"}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        onPageChange={pageChange}
        onPageSizeChange={pageSizeChange}
        selectionModel={selectionModel}
        // onSelectionModelChange={(selection) => {
        //   if (selection.length > 1) {
        //     const selectionSet = new Set(selectionModel);
        //     const result = selection.filter((s) => !selectionSet.has(s));
        //     setSelectionModel(result);
        //   } else {
        //     setSelectionModel(selection);
        //   }

        // }}
        onSelectionModelChange={selectionModelChange}
        checkboxSelection={selectionModel ? true : false}
        columnVisibilityModel={columnsVisibility}
        onColumnVisibilityModelChange={columnVisibilityModelChange}
        getRowClassName={rowColor}
        disableSelectionOnClick={true}
        density={"compact"}
      />
    </div>
  );
}
export default DataTable;
