import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import RailwayAlertIcon from "@mui/icons-material/RailwayAlert";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import LanIcon from "@mui/icons-material/Lan";
import PeopleIcon from "@mui/icons-material/People";
import AlarmIcon from "@mui/icons-material/Alarm";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import PlaceIcon from '@mui/icons-material/Place';
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import InsightsIcon from '@mui/icons-material/Insights';

import {
  ASSET_ASSIGNMENT_ICON,
  FORM_ASSIGNMENT_ICON,
  REPORTS_ICON,
  ASSET_TRANSFER_ICON,
  ALARM_RULES_ICON,
  UTILISATION_ICON,
  SFR_ICON,
} from "../../ICONS/Icons";

// export const ApplicationRoutes = [
//   {
//     label: "Home",
//     url: "home",
//     icon: <HomeIcon />,
//     name: "Dashboard",
//   },
//   {
//     label: "Alarms",
//     url: "alarms",
//     icon: <RailwayAlertIcon />,
//     name: "Alarms",
//   },
//   {
//     label: "Reports",
//     url: "#",
//     icon: REPORTS_ICON,
//     name: "Reports",
//   },
//   {
//     label: "Telemetry",
//     url: "telemetry",
//     icon: <LanIcon />,
//     name: "Telemetry",
//   },
//   {
//     label: "Yard View",
//     url: "yard-view",
//     icon: <AnalyticsIcon />,
//     name: "YardView",
//   },
//   {
//     label: "Assets",
//     url: "assets",
//     icon: <DevicesOtherIcon />,
//     name: "Assets",
//   },
//   // {
//   //   label: "Maintenance Roster",
//   //   url: "maintenance-roster",
//   //   icon: <EngineeringIcon />,
//   // },
// ];

// export const forAdmin = [
//   // {
//   //   label: "Rules",
//   //   url: "rules",
//   //   icon: <IntegrationInstructionsIcon />,
//   // },
//   {
//     label: "User",
//     url: "user",
//     icon: <PersonPinIcon />,
//     name: "Users",
//   },
//   {
//     label: "Form Creator",
//     url: "#",
//     // url: "form-creator",
//     icon: <DynamicFormIcon />,
//     name: "FormCreator",
//   },
//   {
//     label: "Form Assignment",
//     url: "form-assignment",
//     icon: FORM_ASSIGNMENT_ICON,
//     name: "Forms",
//   },
//   {
//     label: "Asset Assignment",
//     url: "asset-assignment",
//     icon: ASSET_ASSIGNMENT_ICON,
//     name: "AssetAssignment",
//   },
//   {
//     label: "Asset Transfer",
//     url: "asset-transfer",
//     icon: ASSET_TRANSFER_ICON,
//     name: "AssetTransfer",
//   },
// ];

// const fontStyle = { fontSize: 25 };
// export const Dashboard_Tiles_User = [
//   {
//     label: "Alarms",
//     url: "alarms",
//     icon: <AlarmIcon sx={fontStyle} />,
//   },
//   {
//     label: "Telemetry",
//     url: "telemetry",
//     icon: <LanIcon sx={fontStyle} />,
//   },
//   {
//     label: "Yard View",
//     url: "yard-view",
//     icon: <AnalyticsIcon sx={fontStyle} />,
//   },
//   // {
//   //   label: "Maintenance Roster",
//   //   url: "maintenance-roster",
//   //   icon: <EngineeringIcon sx={fontStyle} />,
//   // },
//   {
//     label: "Assets",
//     url: "assets",
//     icon: <DevicesOtherIcon sx={fontStyle} />,
//   },
// ];

// export const Dashboard_Tiles_Admin = [
//   {
//     label: "Alarms",
//     url: "alarms",
//     icon: <AlarmIcon sx={fontStyle} />,
//   },
//   {
//     label: "Telemetry",
//     url: "telemetry",
//     icon: <LanIcon sx={fontStyle} />,
//   },
//   {
//     label: "Yard View",
//     url: "yard-view",
//     icon: <AnalyticsIcon sx={fontStyle} />,
//   },
//   {
//     label: "Assets",
//     url: "assets",
//     icon: <DevicesOtherIcon sx={fontStyle} />,
//   },
//   {
//     label: "User",
//     url: "user",
//     icon: <PersonPinIcon />,
//   },
//   // {
//   //   label: "Maintenance Roster",
//   //   url: "maintenance-roster",
//   //   icon: <EngineeringIcon sx={fontStyle} />,
//   // },
//   // {
//   //   label: "Rules",
//   //   url: "rules",
//   //   icon: <IntegrationInstructionsIcon />,
//   // },
// ];

export const sidebarTiles: any = {
  entities: {
    DASHBOARD: { url: "home", icon: <HomeIcon />, label: "Home" },
    ALARM: { url: "alarms", icon: <RailwayAlertIcon />, label: "Alarms" },
    REPORT: { url: "#", icon: REPORTS_ICON, label: "Reports", showPopover: true },
    ASSET_UTILIZATION: { url: "#", icon: UTILISATION_ICON, label: "Asset Utilization", showPopover: true },
    TREND: { url: "trend", icon: <InsightsIcon />, label: "Trend View" },
    TELEMETRY: { url: "telemetry", icon: <LanIcon />, label: "Telemetry" },
    YARDVIEW: { url: "yard-view", icon: <AnalyticsIcon />, label: "Yard View" },
    ASSET: { url: "assets", icon: <DevicesOtherIcon />, label: "Assets" },
    HEALTH_MONITORING: { url: "health-monitoring", icon: <MonitorHeartIcon />, label: "Health Monitoring" },
    GIS: { url: "gis", icon: <PlaceIcon />, label: "Gis View" },
    SFR: { url: "failure-report", icon: SFR_ICON, label: "Signal Failure Report" },
    USER: { url: "user", icon: <PersonPinIcon />, label: "User" },
    FORM: { url: "#", icon: <DynamicFormIcon />, label: "Form Creator", showPopover: true },
    FORM_ASSIGNMENT: { url: "form-assignment", icon: FORM_ASSIGNMENT_ICON, label: "Form Assignment" },
    ASSET_ASSIGNMENT: { url: "asset-assignment", icon: ASSET_ASSIGNMENT_ICON, label: "Asset Assignment" },
    ASSET_TRANSFER: { url: "asset-transfer", icon: ASSET_TRANSFER_ICON, label: "Asset Transfer" },
    ALARM_RULE: { url: "alarm-rules", icon: ALARM_RULES_ICON, label: "Alarm Rules" },
  },
};
sidebarTiles.ids = Object.keys(sidebarTiles.entities).map((key) => key);

export const PopOverContent = {
  "Form Creator": [
    { label: "Maintenance Form", url: "forms/maintenance", state: null },
    { label: "Asset Form", url: "forms/asset", state: null },
  ],

  Reports: [
    { label: "Failures by Category", url: "reports/failures-by-category", state: { navOrigin: "navigation" } },
    { label: "Failures by Alarm Type", url: "reports/failures-by-alarmType", state: { navOrigin: "navigation" } },
    { label: "Failures by Gear Type", url: "reports/failures-by-gearType", state: { navOrigin: "navigation" } },
    { label: "Failures by Devices", url: "reports/failures-by-devices", state: { navOrigin: "navigation" } },
  ],
  "Asset Utilization": [
    { label: "Point Machine Utilization", url: "utilization/point-machine", state: null },
    { label: "Track Circuit Utilization", url: "utilization/track-circuit", state: null },
  ],
};
