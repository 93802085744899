// import $ from "jquery";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { memo, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import DataTable from "../DataGrid/DataGrid";
import HistoryGraph from "./HistoryGraph";
import { APP_BASE_URL, GET_UNIVERSAL_DEVICE_KEYS, EXPORT_HISTORY, APP_BASE_URL_SOCKET } from "../../Common/EndPoints";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import "./HistoryTable.scss";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import moment from "moment";
import { toast } from "react-hot-toast";
import { getAssetSubTypes, getTelemetryHistory } from "./HistoryUtils";
import { GridActionsCellItem } from "@mui/x-data-grid";
import TimelineIcon from "@mui/icons-material/Timeline";
import PointChartPopup from "./PointOperationChart/PointChartPopup";
import { DEVICETYPE_WITH_GROUPED_KEY_HISTORY_GRAPH, FIXED_COLUMNS, convertToHigherUnit, initialPointChartPopData, isValidNum } from "./Constant";
import { downloadFile } from "../../Utils/Utils";
import BhmsHistoryGraph from "./BHMS/BhmsHistoryGraph";
import KeyGroupedHistoryChart from "./HistoryGraphViews/KeyGroupedHistoryChart/KeyGroupedHistoryChart";

// import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
const TABS_LIST = ["table", "graph"];
const DEFAULT_HISTORY_SUB_TABS_LIST = [{
  "subtype": "ALL",
  "typeId": "69a18cc9-88d4-4b9a-87ac-4f58fceec109",
  "mapedJsonKey": "ALL",
  "id": null
}];
const DEFAULT_NUMBER_OF_EVENTS = 500;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_INDEX = 0;

const isValidToPush = (subtype, SELECTED_SUBTAB_REF) => {
  let isValid = false;
  const selectedSubtab = SELECTED_SUBTAB_REF.current.selecetdTab;
  if (selectedSubtab === "ALL") {
    isValid = true;
  } else {
    if (selectedSubtab === SELECTED_SUBTAB_REF.current.mapping[subtype]) {
      isValid = true;
    }
  }
  return isValid;
};

// const SUBTAB_SUBTYPE_MAPPING = {}

const HistoryGraphComponent = memo(({ loading, graphState, state, deviceId, liveData, deviceType }) => {
  const props = {
    loading: loading,
    graphState: graphState,
    columns: state.gridColumns,
    startTs: state.startTs,
    endTs: state.endTs,
    deviceId: deviceId,
    liveData: liveData,
  };
  if (deviceType === "BHMS") {
    return <BhmsHistoryGraph {...props} />;
  }
  if (DEVICETYPE_WITH_GROUPED_KEY_HISTORY_GRAPH.includes(deviceType)) {
    const _props = { ...props, deviceType: deviceType };
    return <KeyGroupedHistoryChart {..._props} />;
  }
  return <HistoryGraph {...props}></HistoryGraph>;
});

function getInitialVisibilityModel() {
  const columnsInStorage = localStorage.getItem("data-grid-columns-visibility");
  const storedColumnsVisibility = columnsInStorage ? JSON.parse(columnsInStorage)["telemetry-history-table"] ?? {} : {};
  const storedColumnsVisibilityLength = Object.keys(storedColumnsVisibility).length;
  const _visibility_model = storedColumnsVisibilityLength > 0 ? storedColumnsVisibility : {};
  return _visibility_model;
}

export default function HistoryTable() {
  // const startDate = params.get("start_date") ?? moment().subtract(1, "days").toISOString();
  // const endDate = params.get("end_date") ?? new Date().toISOString();

  // const startDate = _startDateInURL ? moment(_startDateInURL) : moment().startOf("day").subtract(1, "days");
  // const endDate = _endDateInURL ? moment(_endDateInURL) : moment();
  const [visibilityModel, setVisibilityModel] = useState(getInitialVisibilityModel);
  const SELECTED_SUBTAB_REF = useRef();
  const params = new URLSearchParams(document.location.search);
  const _startDateInURL = params.get("start_date");
  const _endDateInURL = params.get("end_date");
  const startDate = _startDateInURL ? moment(_startDateInURL) : null;
  const endDate = _endDateInURL ? moment(_endDateInURL) : null;
  const initialState = {
    rowsData: [],
    dataForFilter: [],
    gridColumns: [],
    startTs: startDate,
    endTs: endDate,
    startTsError: false,
    endTsError: false,
  };
  const [state, setState] = useImmer(initialState);
  //for date range picker
  let id = useLocation();
  const userId = localStorage.getItem("userId");
  const deviceId = id.pathname.split("/")[2].split("_")[0];
  const deviceType = id.pathname.split("/")[2].split("_")[1];
  const _deviceName = id.pathname.split("/")[2].split("_")[2];
  const deviceName = decodeURIComponent(_deviceName);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [rowCount, setRowCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("graph");
  const [subTabsList, setSubTabsList] = useState(DEFAULT_HISTORY_SUB_TABS_LIST);
  const [selectedSubTab, setSelectedSubTab] = useState(subTabsList[0].subtype);
  const [loading, setLoading] = useState(false);
  const [liveData, setLiveData] = useState(null);
  const { customerIdList } = useContext(ActiveAlarmsData);
  const rowsData = useRef([]);
  const [graphState, setGraphState] = useImmer({ data: [], chart: "live" });
  const numberOfEvents = useRef(DEFAULT_NUMBER_OF_EVENTS);
  const [pointChartPop, setPointChartPop] = useImmer(initialPointChartPopData);
  const [deviceKeys, setDeviceKeys] = useState([]);

  useEffect(() => {
    const _typeIdInURL = params.get("device_type_id");
    const typeId = _typeIdInURL;
    const subtypeKeymap = {};
    if (!typeId) return;
    getAssetSubTypes({ typeId }).then(
      (res) => {
        const _tabs = res.data?.data?.map((_sub_type) => {
          subtypeKeymap[_sub_type.mapedJsonKey] = _sub_type.subtype;
          return _sub_type;
        });
        SELECTED_SUBTAB_REF.current = {
          selecetdTab: DEFAULT_HISTORY_SUB_TABS_LIST[0].subtype,
          mapping: subtypeKeymap,
        };
        setSubTabsList([...DEFAULT_HISTORY_SUB_TABS_LIST, ..._tabs]);
      },
      (err) => {
        toast.error("Error fetching Sub Types");
      },
    );
  }, []);
  useEffect(() => {
    const controller = new AbortController();
    telemetryWebSocketConnection();
    getHistoryByTs(selectedSubTab, null, controller.signal);
    return () => {
      controller.abort();
      window.wsTelemetry?.close();
    };
  }, [pageIndex, pageSize, selectedSubTab, selectedTab]);

  useEffect(() => {
    rowsData.current = state.rowsData ?? [];
  }, [state.rowsData]);

  useEffect(() => {
    if (customerIdList.length > 0) dynamicColumns(selectedSubTab);
  }, [customerIdList, selectedSubTab]);

  /** Function for Fetching Table Columns Dynamically from API */
  const dynamicColumns = (selectedSubTab) => {
    let subType = params.get("subType");
    // console.log("selectedSubTab", subType, selectedSubTab, subTabsList);
    const url = `${APP_BASE_URL}${GET_UNIVERSAL_DEVICE_KEYS}`;
    const _subTypeId = subTabsList.find((sub) => sub.subtype === selectedSubTab);
    let apiParams = {
      customerIds: customerIdList.toString(),
      type: deviceType,
      subTypeId: _subTypeId.id,
    };
    if (subType) {
      apiParams = { ...apiParams, subType: subType === "ROUTE_INDICATOR" ? "CALLING" : subType };
    }
    axios.get(url, { params: apiParams }).then((res) => {
      let obj = {};
      setDeviceKeys(res.data.data);
      res.data.data.forEach((row) => {
        if (obj[row.key]) {
          obj[row.key]["critical"].push([row.cMin, row.cMax]);
          obj[row.key]["warning"].push([row.wMin, row.wMax]);
        } else {
          obj[row.key] = {
            key: row.key,
            alias: row.alias,
            unit: row.unit,
            order: row.order,
            critical: [[row.cMin, row.cMax]],
            warning: [[row.wMin, row.wMax]],
          };
        }
      });

      let columns = Object.values(obj).map((column, i) => {
        let headerName = column.alias ?? column.key;
        if (column?.unit && column?.unit !== "-" && column?.unit !== " " && !column?.unit?.includes("*")) {
          headerName = `${headerName} (${column.unit})`;
        }
        return {
          key: i,
          field: column.key,
          headerName: headerName,
          sortable: true,
          flex: 1,
          minWidth: 150,
          unit: column.unit,
          verticalAlign: "center",
          order: column.order,
          resizeable: true,
          cellClassName: (params) => {
            if (column.unit === "" || column.unit === null || column.unit === "-") {
              if (params.value === "Free") {
                return "blue";
              } else if (params.value === "Occupied") {
                return "orange";
              } else if (params.value === "Occupied") {
                return "orange";
              }
            } else {
              return getCellColor(params.value, column.critical, column.warning);
            }
          },
          valueGetter: (param) => {
            let value = param?.value ?? null;
            if (isValidNum(value)) {
              value = Number(value);
              const unit = column.unit;
              if (unit && !unit?.startsWith("m")) {
                value = (value / 1000).toFixed(2)
              }
            }
            return value ?? '-'
          },
        };
      });
      columns = columns.sort((a, b) => {
        return a.order - b.order;
      });

      // if (deviceType === "TC") {
      //   columns = [
      //     {
      //       field: "trackStatus",
      //       headerName: "Track Status",
      //       sortable: true,
      //       minWidth: 100,
      //       valueGetter: (param) => {
      //         const value = Number(param.row.VR);
      //         if (!isNaN(value) && value) {
      //           if (param.row.VR < 1200) return "Occupied";
      //           else return "Free";
      //         } else {
      //           return "-";
      //         }
      //       },
      //       cellClassName: (param) => {
      //         if (param.value === "Free") {
      //           return "blue";
      //         } else if (param.value === "Occupied") {
      //           return "orange";
      //         }
      //       },
      //     },
      //     {
      //       field: "energization",
      //       headerName: "Energization %",
      //       sortable: true,
      //       type: "string",
      //       minWdth: 160,
      //       valueGetter: (param) => {
      //         const value = param.row.VR;
      //         if (value > 0) {
      //           const eng = (value / 1400) * 100;
      //           return eng.toFixed(0) + "%";
      //         }
      //         return "-";
      //       },
      //       cellClassName: (param) => {
      //         let eng = 0;
      //         const value = param.row.VR;
      //         if (value > 0) {
      //           eng = (value / 1400) * 100;
      //         }
      //         if ((eng > 300 || eng < 100) && eng > 10) {
      //           return "red";
      //         } else if (eng > 280 || eng < 180) {
      //           return "orange";
      //         } else return "blue";
      //       },
      //     },
      //     {
      //       field: "chargerStatus",
      //       headerName: "Charger Status",
      //       sortable: true,
      //       minWidth: 140,
      //       valueGetter: (param) => {
      //         const value = Number(param.row.ICO);
      //         if (!isNaN(value) && value) {
      //           if (value > 1800) return "Boost Mode";
      //           else return "Trickle Mode";
      //         } else {
      //           return "-";
      //         }
      //       },
      //     },
      //     ...columns,
      //   ];
      // }

      // if (deviceType === "IPS") {
      //   columns = [
      //     {
      //       field: "V1",
      //       headerName: "V1",
      //       sortable: true,
      //       width: 140,
      //       minWidth: 100,
      //       flex: 0.5,
      //       valueGetter: (param) => {
      //         return param.value / 1000;
      //       },
      //     },
      //   ];
      // }

      columns = [
        {
          field: "lastActivityTime",
          headerName: "Last Activity Time",
          sortable: true,
          align: "center",
          headerAlign: "center",
          flex: 1,
          minWidth: 200,
          valueFormatter: (params) => {
            let TS = params.value;
            if (TS && TS > 0) {
              const value = Number(TS);
              const date = new Date(value);
              const yyyy = date.getFullYear();
              let mm = date.getMonth() + 1; // Months start at 0!
              let dd = date.getDate();
              if (dd < 10) dd = "0" + dd;
              if (mm < 10) mm = "0" + mm;
              const formattedToday = dd + "/" + mm + "/" + yyyy;
              const time = date.toLocaleTimeString();
              return formattedToday + " " + time;
            } else {
              return "-";
            }
          },
        },
        {
          field: "NAME",
          headerName: "NAME",
          sortable: true,
          width: 140,
          align: "center",
          headerAlign: "center",
          minWidth: 100,
          flex: 0.5,
          valueGetter: (param) => {
            return param.row.STN + "-" + param.row.NAME;
          },
        },
        {
          field: "LOC",
          headerName: "Location",
          sortable: true,
          hide: false,
          hideable: true,
          minWidth: 100,
          flex: 0.5,
        },
        ...columns,
      ];

      if (deviceType === "PM") {
        columns = [
          {
            field: "pm-chart",
            type: "actions",
            flex: 0.1,
            hideable: false,
            getActions: (params) => [
              <Tooltip
                placement="right-start"
                title={
                  !params.row.EVENTDATA1 || !params.row.EVENTTIME
                    ? "Data Unavailable for Graph"
                    : "Point Operation Graph"
                }
                disableInteractive
              >
                <span>
                  <GridActionsCellItem
                    icon={<TimelineIcon />}
                    label="pm-chart"
                    disabled={!params.row.EVENTDATA1 || !params.row.EVENTTIME}
                    onClick={() => {
                      if (params.row.EVENTDATA1 && params.row.EVENTTIME) {
                        setPointChartPop((prev) => {
                          prev.open = true;
                          prev.machineData = {
                            eventData: params.row.EVENTDATA1 ?? [],
                            eventTime: params.row.EVENTTIME ?? [],
                          };
                          prev.deviceId = params.row.deviceId;
                          prev.deviceName = params.row.NAME;
                          prev.currentTs = params.row.lastActivityTime;
                        });
                      }
                    }}
                  />
                </span>
              </Tooltip>,
            ],
          },
          ...columns,
        ];
      }
      setState((draft) => {
        draft.data = [];
        draft.gridColumns = columns ?? [];
      });
    });
  };
  // const convertToHigherUnit = (value, unit) => {
  //   if (isNaN(value) || value < 0) return "-";
  //   if (["v"].includes(unit)) return Number(value) / 1000;
  //   /** if unit is not 'MV' then return value as it is */
  //   if (!["MV"].includes(unit.toUpperCase())) return value;
  //   return value;
  // };
  const getCellColor = (value, criticalRange, warningRange) => {
    for (let i in criticalRange) {
      let range = criticalRange[i];
      if (range[0] < value && range[1] > value) {
        return "red";
      }
    }

    for (let i in warningRange) {
      let range = warningRange[i];
      if (range[0] < value && range[1] > value) {
        return "orange";
      }
    }
    return "blue";
  };

  const getHistoryByTs = (subType, reset, signal) => {
    setLoading(true);

    let startTs = null;
    let endTs = null;
    let page = DEFAULT_PAGE_INDEX + 1;

    if (!reset) {
      startTs = state.startTs ? new Date(state.startTs).getTime() : null;
      endTs = state.endTs ? new Date(state.endTs).getTime() : null;
      page = pageIndex + 1;
    }
    const subtype = subType !== "ALL" ? subType : null;
    const params = {
      deviceId,
      endTs,
      startTs,
      isModelView: true,
      page,
      pageSize,
      subtype,
    };

    if (selectedTab === "graph") {
      params.page = 1;
      params.pageSize = numberOfEvents.current;
      delete params.subtype;
    }

    getTelemetryHistory(params, signal)
      .then(
        (res) => {
          if (selectedTab === "graph") return res;
          const _data = res?.data?.data;
          const _totalCount = _data.totalElements ?? 0;
          const _content = _data.content;

          const _rowData = [];
          _content.forEach((row, index) =>
            _rowData.push({ ...row?.data?.[0]?.values, id: index, lastActivityTime: row?.data?.[0]?.dateTime }),
          );
          return { _rowData, _totalCount };
        },
        (err) => {
          if (err.response) {
            toast.error(err?.response?.data?.error?.message ?? "Something Went Wrong! Try again Later");
          }
        },
      )
      .then((res) => {
        setLoading(false);
        if (selectedTab === "table") {
          setState((state) => {
            state.rowsData = res?._rowData ?? [];
          });
        } else {
          const chartType = endTs !== null ? "fixed" : "live";
          setGraphState((state) => {
            state.data = res ?? [];
            state.chart = chartType;
          });
        }
        setRowCount(res?._totalCount ?? 0);
      });
  };

  /** Establish Websocket Connection*/
  const telemetryWebSocketConnection = () => {
    const url = `${APP_BASE_URL_SOCKET}/${userId}`;
    const webSocketParam = {
      filter: {
        geartype: deviceType,
      },
    };
    window.wsTelemetry = new WebSocket(url);
    window.wsTelemetry.addEventListener("open", (event) => {
      window.wsTelemetry.send(JSON.stringify(webSocketParam));
    });
    window.wsTelemetry.onmessage = (res) => {
      if (res?.data) {
        let data = JSON.parse(res.data);
        const device_name_of_received_packet = data?.deviceName;
        data = data?.dataLists?.[0]?.data;
        let socketDeviceName = data?.STN[0].value + "-" + data?.NAME[0].value;
        let socketSubtype = data?.SUBGEAR[0].value;

        //if the device name from websocket does not matches the device name we are viewing then return function
        if (device_name_of_received_packet !== deviceName) return;

        setLiveData(data);

        //only update and show live websocket data if we are on page 1
        if (pageIndex !== 0) return;

        //if data is empty and is loading then do not set websocket data
        if (rowsData.current.length === 0) return;

        // console.log('ref', SELECTED_SUBTAB_REF)

        //if packet is not valid for seleceted subtype
        if (!isValidToPush(socketSubtype, SELECTED_SUBTAB_REF)) return;

        let newRow = {
          lastActivityTime: Number(data?.["DATE"]?.[0]?.ts),
          name: socketDeviceName,
          id: rowsData.current.length + 1,
        };
        Object.entries(data).forEach(([key, value]) => {
          newRow[key] = value[0].value;
        });
        const updatedData = [newRow, ...rowsData.current];

        //set telemetryData to be updated to the new data
        rowsData.current = updatedData;
        setState((state) => {
          state.rowsData = updatedData;
        });
      }
    };
  };

  function handleDateTimeChange(type, date) {
    setState((state) => {
      state[type] = date;
      state[`${type}Error`] = false;
    });
  }

  function getExportKeys() {
    const final_key_set = new Set();
    for (const device_key of deviceKeys) {
      if (visibilityModel[device_key.key] === false) continue;
      final_key_set.add(device_key.key);
    }
    return Array.from(final_key_set);
  }

  function exportFromAPI(type) {
    const subtype = selectedSubTab !== "ALL" ? selectedSubTab : null;
    const export_keys = getExportKeys();
    const _payload = {
      deviceId: deviceId,
      exportType: type.toUpperCase(),
      startTs: new Date(state.startTs).getTime(),
      endTs: state.endTs ? new Date(state.endTs).getTime() : null,
      customerIds: customerIdList.toString(),
      subtype,
      keys: export_keys.toString(),
    };
    if (_payload.startTs) {
      const toastId = toast.loading("Exporting...");

      axios
        .get(`${APP_BASE_URL}${EXPORT_HISTORY}`, { params: _payload, responseType: "blob" })
        .then(
          (response) => {
            const name = `${deviceType}_${deviceName}_History.${type}`;
            const data = response.data;
            downloadFile({ name, data });
          },
          async (err) => {
            const text = JSON.parse(await err.response.data.text());

            toast.error(text.error);
          },
        )
        .then(() => {
          toast.dismiss(toastId);
        });
    } else {
      toast.error("Please Select a Start Date to Export History");
    }
  }

  function handleVisibilityModelChange(visibility_model) {
    console.log("visibility_model", visibility_model);
    setVisibilityModel(visibility_model);
  }

  return (
    <div className="telemetry-history">
      <Stack
        className="telemetry-history-header flex flexWrap alignCenter spaceBetween flexDirectionRow"
        component="form"
        noValidate
        spacing={""}
      >
        <div className="flex alignCenter flexWrap">
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {TABS_LIST.map((_tab) => (
              <Button
                key={_tab}
                variant={selectedTab === _tab ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTab(_tab);
                }}
              >
                {_tab}
              </Button>
            ))}
          </ButtonGroup>
        </div>

        <div className="flex gap-2 alignCenter flexWrap">
          {selectedTab === "graph" && (
            <div className="flex-1">
              <FormControl fullWidth style={{ width: "10rem" }}>
                <InputLabel id="number-of-events-label">Number of Events</InputLabel>
                <Select
                  labelId="number-of-events-label"
                  onChange={(event) => {
                    numberOfEvents.current = event.target.value;
                    getHistoryByTs(null, false);
                  }}
                  defaultValue={DEFAULT_NUMBER_OF_EVENTS}
                  size="small"
                  label="Number of Events"
                >
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                  <MenuItem value={2000}>2000</MenuItem>
                  <MenuItem value={3000}>3000</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DateTimePicker
              label="From Date-Time*"
              value={state.startTs}
              onChange={(date) => handleDateTimeChange("startTs", date)}
              renderInput={(params) => (
                <TextField size="small" {...params} error={state.startTsError} type="datetime-local" />
              )}
            />
            <DateTimePicker
              label="To Date-Time*"
              value={state.endTs}
              onChange={(date) => handleDateTimeChange("endTs", date)}
              renderInput={(params) => (
                <TextField size="small" {...params} error={state.endTsError} type="datetime-local" />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="outlined"
            onClick={() => {
              setState((state) => {
                state.startTs = null;
                state.endTs = null;
                state.startTsError = false;
                state.endTsError = false;
              });
              getHistoryByTs(selectedSubTab, true);
            }}
          >
            Clear
          </Button>
          <Button variant="contained" onClick={() => getHistoryByTs(selectedSubTab)}>
            Search
          </Button>
        </div>
      </Stack>
      <div className="telemetry-history-main fullWidth">
        {selectedTab === "table" ? (
          <>
            <div className="flex items-center flexWrap">
              {subTabsList.map((_subTab) => (
                <button
                  className={
                    "telemetry-history__tabs--button " +
                    (selectedSubTab === _subTab.subtype ? "telemetry-history__tabs--selected" : "")
                  }
                  variant="text"
                  onClick={() => {
                    setPageIndex(DEFAULT_PAGE_INDEX);
                    setPageSize(DEFAULT_PAGE_SIZE);
                    setSelectedSubTab(_subTab.subtype);
                    SELECTED_SUBTAB_REF.current.selecetdTab = _subTab.subtype;
                  }}
                  key={_subTab.subtype}
                >
                  {_subTab.subtype}
                </button>
              ))}
            </div>
            <DataTable
              handleVisibilityModelChange={handleVisibilityModelChange}
              tableInfo={{
                id: `telemetry-history-table`,
                label: `Telemetry ${deviceName}`,
                requireAuthSign: false,
                timestamp: { startTs: state.startTs, endTs: state.endTs },
              }}
              loading={loading}
              rows={state.rowsData}
              columns={state.gridColumns}
              pageChange={(index) => {
                setPageIndex(index);
              }}
              pageSizeChange={(size) => {
                setPageSize(size);
                setPageIndex(DEFAULT_PAGE_INDEX);
              }}
              pageIndex={pageIndex}
              pageSize={pageSize}
              paginationMode={"server"}
              rowCount={rowCount}
              rowColor={(params) => {
                if (deviceType === "PM") {
                  return params.row.OT > 6000 ? "otColor" : "";
                }
              }}
              serverSideExport={exportFromAPI}
            />
          </>
        ) : (
          <HistoryGraphComponent
            loading={loading}
            graphState={graphState}
            state={state}
            deviceId={deviceId}
            liveData={liveData}
            deviceType={deviceType}
          />
        )}
      </div>
      {pointChartPop.open && (
        <PointChartPopup
          open={pointChartPop.open}
          machineData={pointChartPop.machineData}
          deviceId={pointChartPop.deviceId}
          currentTs={pointChartPop.currentTs}
          deviceName={pointChartPop.deviceName}
          onClose={() => {
            setPointChartPop((prev) => {
              prev.open = false;
            });
          }}
          disableFilter
        />
      )}
    </div>
  );
}
