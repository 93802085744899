import { IconButton, Button, TextField, Select, MenuItem } from "@mui/material";
import { Close } from "@mui/icons-material";
import { memo, useState } from "react";
import "./ClientFilterPanel.scss";

/**
 *
 * @returns The buttons responsible for taking actions on form, like adding rows, clearing filter and searching
 */
export const FilterActionButtons = ({
  handleAddRow,
  handleClear,
  handleSearch,
}) => {
  return (
    <div className="client-filter-action-buttons flex spaceBetween alignCenter">
      <Button
        variant="outlined"
        onClick={() => {
          handleAddRow();
        }}
      >
        + Add Filter
      </Button>
      <div>
        <Button
          variant="outlined"
          onClick={() => {
            handleClear();
          }}
        >
          Clear
        </Button>
        <Button
          className="client-filter-panel-button"
          variant="contained"
          color="primary"
          onClick={() => {
            handleSearch();
          }}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export const FilterRow = ({ row, updateFilters, columnFields, removeRow }) => {
  return (
    <div className="client-filter-search-row flex alignCenter spaceBetween">
      <Select
        className="client-filter-column-field"
        variant="standard"
        displayEmpty
        renderValue={
          row?.columnField !== "" ? undefined : () => "Search Column"
        }
        onChange={({ target: { value } }) => {
          updateFilters({ columnField: value, columnValue: row?.columnValue });
        }}
        value={row.columnField ?? null}
      >
        {columnFields.map((field, index) => {
          if (field.headerName) {
            return (
              <MenuItem
                key={field.field + index * 100 + index}
                value={field.field}
              >
                {field.headerName}
              </MenuItem>
            );
          }
        })}
      </Select>
      <TextField
        variant="standard"
        placeholder="Filter Value"
        value={row.columnValue}
        onChange={({ target: { value } }) => {
          updateFilters({ columnField: row.columnField, columnValue: value });
        }}
      />
      <IconButton
        onClick={() => {
          removeRow();
        }}
      >
        <Close />
      </IconButton>
    </div>
  );
};

export const ClientFilterPanel = (props) => {
  const { totalColumns, filterSearch, columnFilter } = props;
  const [searchRows, setSearchRows] = useState(columnFilter);
  function handleAddRow() {
    setSearchRows((previousState) => [
      ...previousState,
      { columnField: "", columnValue: "" },
    ]);
  }
  function handleRemoveRow(index) {
    let copyArray = [...searchRows];
    copyArray.splice(index, 1);
    if (copyArray.length === 0) {
      copyArray = [{ columnField: "", columnValue: "" }];
    }
    setSearchRows(copyArray);
  }
  function updateFilters(updateValue, index) {
    let updateArray = [...searchRows];
    updateArray[index] = updateValue;
    setSearchRows(updateArray);
  }
  function handleClear() {
    setSearchRows([{ columnField: "", columnValue: "" }]);
    filterSearch([{ columnField: "", columnValue: "" }]);
  }
  function handleSearch() {
    filterSearch(searchRows);
  }
  return (
    <div className="client-filter-panel fullWidth">
      <div className="client-filter-panel-fields-container flex flexWrap spaceEvenly">
        <div className="client-filter-panel-fields-container">
          {searchRows.map((row, index) => {
            return (
              <FilterRow
                key={"filterRow" + index}
                row={row}
                index={index}
                columnFields={totalColumns}
                removeRow={() => {
                  handleRemoveRow(index);
                }}
                updateFilters={(updatedRow) => {
                  updateFilters(updatedRow, index);
                }}
              />
            );
          })}
        </div>
      </div>
      <FilterActionButtons
        handleAddRow={handleAddRow}
        handleClear={handleClear}
        handleSearch={handleSearch}
      />
    </div>
  );
};
export default memo(ClientFilterPanel);