import { useEffect, useContext, useState } from "react";
import { Box } from "@mui/system";
import DataTable from "../DataGrid/DataGrid";
import { useImmer } from "use-immer";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import { Button, Tooltip, Typography } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import TimelineIcon from "@mui/icons-material/Timeline";
import {
  APP_BASE_URL, GET_DEVICE_BY_CUSTOMER_ID, GET_UNIVERSAL_DEVICE_KEYS,
  APP_BASE_URL_SOCKET, APP_BASE_URL_TREND, GET_TREND_FEATURE, GET_TREND_LATEST,
} from "../../Common/EndPoints";
import DataCard from "../Card/DataCard";
import { GridActionsCellItem } from "@mui/x-data-grid";
import HistoryIcon from "@mui/icons-material/History";
import { ActiveAlarmsData } from "../Alarms/ActiveAlarms";
import TableViewIcon from "@mui/icons-material/TableView";
import axios from "axios";
import "./Trend.scss";
import PointChartPopup from "./PointOperationChart/PointChartPopup";
import BhmsChartPopup from "./BHMSChart/BhmsChartPopup";
import {
  FIXED_COLUMNS,
  convertToHigherUnit,
  getCellColor,
  initialPointChartPopData,
  initialBhmsChartPopData,
  trimDeviceName, demoData
} from "./Constant";
import TrendFilters from './TrendFilters';
import HistoryGraph from './HistoryGraph';
import { getAssetSubTypes, getTrendHistory } from "./HistoryUtils";
import { filter } from "rxjs";
import Slider from '@mui/material/Slider';


const Trend = () => {
  const initialState = {
    data: [],
    deviceTypeList: [],
    selectedDevice: "",
    gridColumns: [],
    deviceType: sessionStorage.getItem("telemetry-selected-device-type") ?? null,
    deviceTypeLabel: sessionStorage.getItem("telemetry-selected-device-type-label") ?? null,
    typeId: sessionStorage.getItem("telemetry__selected-device-type--id") ?? null,
    ipsData: [], //ips data will be filled here from telemetry api
    loader: true,
  };
  const initFilters = {
    days: 30,
    startTs: (new Date()).getTime() - 30*(24*60*60*1000), //30 Days before
    endTs: new Date().getTime()
  };
  let navigate = useNavigate();
  const [trendState, setTrendState] = useImmer(initialState);
  const [filters, setFilters] = useImmer(initFilters);
  const [pageSize, setPageSize] = useState(20);
  const [initialHiddenColumns, setIntialHiddenColumns] = useState({});
  const { customerIdList, newUserData } = useContext(ActiveAlarmsData);
  const [selectedStations, setSelectedStations] = useState(localStorage.getItem("STN"));
  const userId = localStorage.getItem("userId");
  let selectedDeviceType;
  const [graphState, setGraphState] = useImmer({ data: [], chart: "live" });
  const [rowCount, setRowCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("Graph");
  const allowedDeviceTypes = ["PM","TRACK"];


  useEffect(() => {
    const controller = new AbortController();
    if (customerIdList.length > 0) {
      getDeviceTypes(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [customerIdList]);

  useEffect(() => {
    console.log("Trend --> customerId and deviceId changed  --> ", filters);  
    //if((filters.deviceIds ?? []).length > 0 && (filters.customerIds ?? []).length > 0) {
      const controller = new AbortController();  
      dynamicColumns(controller.signal);
      //getHeaderAndLatestTelemetry(controller.signal);
    //}
  }, [filters.customerIds, filters.deviceIds]);
  useEffect(() => {
    setFilters(draft => {
     draft.startTs = (new Date()).getTime() - (filters.days)*(24*60*60*1000);
     draft.endTs =  new Date().getTime();
    });
   }, [filters.days]);
   useEffect(() => {
    const controller = new AbortController();
    console.log("Trend --> Data selection changed  --> ", filters, new Date(filter.startTs), new Date(filter.endTs));  
    if((filters.data ?? []).length > 0) {
      loadHistoryOfSelectedData(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [filters.data, filters.startTs, filters.key]);


  useEffect(() => {
    const coloumnsToHide = {};
    trendState.gridColumns.forEach((column) => {
      if (column.hide) {
        coloumnsToHide[column.field] = false;
      }
    });
    setIntialHiddenColumns(coloumnsToHide);
  }, [trendState.gridColumns]);

  useEffect(() => {
    if (customerIdList.length > 0 && JSON.stringify(newUserData) !== "{}") {
      const selectedSTation = [];
      newUserData.customerDetails.forEach((st) => {
        if (customerIdList.includes(st.customerId)) {
          selectedSTation.push(st.customerName);
        }
      });
      setSelectedStations(selectedSTation);
    }
  }, [customerIdList, newUserData]);

  const getDeviceTypes = (signal) => {
    const url = `${APP_BASE_URL}${GET_DEVICE_BY_CUSTOMER_ID}`;
    axios
      .post(url, customerIdList, { signal })
      .then((res) => {
        if (res?.data?.data) {
          let deviceTypes = res?.data?.data;
          deviceTypes = [...deviceTypes, ...demoData.deviceType];
          deviceTypes = deviceTypes.filter(item => allowedDeviceTypes.includes(item.type))
          console.log("deviceTypes --> ", deviceTypes);
          deviceTypes.reverse();
          setTrendState((state) => {
            state.deviceTypeList = deviceTypes;
            state.deviceTypeLabel =
              sessionStorage.getItem("telemetry-selected-device-type-label") ?? deviceTypes[0].label;
            state.deviceType = sessionStorage.getItem("telemetry-selected-device-type") ?? deviceTypes[0].type;
            state.typeId = sessionStorage.getItem("telemetry__selected-device-type--id") ?? deviceTypes[0].typeId;
          });
        }
      })
      .catch(() => {
        setTrendState((state) => {
          state.loader = false;
          state.deviceTypeList = [];
        });
      });
  };
  const getLatestTrendsState = (signal) => {    
    let trackDeviceTypeId = (trendState?.deviceTypeList ?? []).filter(ele => ele.type === 'TRACK')?.[0]?.typeId ?? '';
    let deviceTypeId = filters.deviceIds.map(ele => ele.typeId).join(",");
    let deviceLabel = filters.deviceIds.map(ele => ele.type).join(",");
    let customerIds = filters.customerIds.map(ele => ele.id).join(",");
    
    let payload = {
      customer_ids: customerIds,  //added one sample cusomter id for which data is there.    
      device_type_ids: deviceTypeId,
      limit: 100,
      offset: 0,
      order_by: 'device_name',
      order_by_type: 'asc'
    };

    let url = `${APP_BASE_URL_TREND}${GET_TREND_LATEST}?limit=${payload.limit}&offset=${payload.offset}&order_by_type=${payload.order_by_type}&order_by=${payload.order_by}&customer_ids=${payload.customer_ids}&device_type_ids=${payload.device_type_ids}`;
    console.log("trend --> getLatestTrendsState --> trendState --> ", deviceLabel, trendState, url);
    
    axios.get(`${url}`, { signal }) 
      .then((res) => {
        console.log("trend --> getLatestTrendsState --> res.data.items --> ", res?.data?.items);
        if (res?.data?.items) {
          let gridData = [];          
          res?.data?.items?.forEach((data, index) => {
            let finalRow = {
              id: index,
              deviceId: data.device_id,
              //NAME: data.deviceName,
              deviceTypeId: data.device_type_id,
              deviceTypeLabel: data.device_type_label,
              customerId: data.customer_id,
              customerCode: data.customer_code,
              ts: (new Date(data.last_updated_on)).getTime()
            };
            let nameCode = trimDeviceName(data.device_name).name;
            let zoneDivCode = trimDeviceName(data.device_name).zoneDiv;
            finalRow["NAME"] = nameCode;
            finalRow["ZONE_DIV"] = zoneDivCode;
            finalRow["LOC"] = zoneDivCode;
            finalRow.lastActivityTime = finalRow.ts;

            (data.features ?? []).forEach((item) => {
              let key = item.key;
              if(finalRow.deviceTypeId === trackDeviceTypeId) {
                key = key + "_" + item.state
              }
              finalRow = { ...finalRow, [key]: item.value };
            });           

            gridData.push(finalRow);
          });
          console.log("trend --> getLatestTrendsState --> gridData --> ", gridData);

          setTrendState((draft) => {
            draft.data = gridData;
          });
        } else {
          setTrendState((draft) => {
            draft.ipsData = [];
          });
        }
      })
      .then(() => {
        setTrendState((draft) => {
          draft.loader = false;
        });
      })
      .catch(() => {
        setTrendState((draft) => {
          draft.loader = false;
        });
      });
  };
  const handleHistoryClick = (params) => {
    console.log("handleHistoryClick --> ",params, filters);
  
    const url = `/trend-history/${params.row.device_id}_${params.row.device_type_label}_${params.row.NAME}`; 
    const oneDay = 86400000; 
    const toDate = new Date();
    const searchParams = {
      device_type_id: filters.deviceIds[0].typeId,
      start_date: new Date(toDate.getTime() - (oneDay*30)).toISOString(),
      end_date: toDate.toISOString(),
    };
    navigate({
      pathname: url,
      search: createSearchParams(searchParams).toString(),
    });


  };
  const dynamicColumns = (signal) => {
    setTrendState((draft) => {
      draft.gridColumns = [];
      draft.loader = true;
      draft.data = [];
      draft.historyData = [];
      draft.apiKeys = undefined;
    });
    setFilters((draft) => {
      draft.keyState = undefined;
      draft.key = undefined
      draft.data = undefined;
    });
    if((filters.deviceIds ?? []).length === 0 || (filters.customerIds ?? []).length === 0) {
      return;
    }
    let trackDeviceTypeId = (trendState?.deviceTypeList ?? []).filter(ele => ele.type === 'TRACK')?.[0]?.typeId ?? '';
    let url = `${APP_BASE_URL_TREND}${GET_TREND_FEATURE}?device_type_ids=${filters.deviceIds.map(ele => ele.typeId).join(",")}`;
    console.log("trend --> getHeaderAndLatestTelemetry --> url --> ", url);
    
    axios.get(url, { signal })
      .then((res) => {
        let columns = [];
        let obj = {};
        //console.log("trend --> dynamicColumns --> res --> ", res.data);
        if ((res?.data ?? []).length > 0) {
          res.data.forEach((row) => {
            let key = row.key;
            let feature = row.feature;
            if(trackDeviceTypeId === row.device_type_id) {
              key = key + "_"+row.state;
              feature = feature + " " + row.state;
            }
            obj[key] = { key: key, alias: feature, unit: row.unit, state: row.state, order: row.order };
          });
          console.log("trend --> obj --> ", obj);
          Object.values(obj).forEach((column, i) => {
            columns.push({
              key: i,
              field: column.key,
              headerName: `${column.alias} (${column.unit})`,
              //headerName: `${column.key} (${column.state})`,
              sortable: true,
              flex: 1,
              minWidth: 150,
              verticalAlign: "center",
              resizeable: true,
              order: column.order,
              cellClassName: (params) => {
                return '';
              },
              valueGetter: (param) => {
                let value = param.value;
                if (column.unit === "" || column.unit === null || column.unit === "-" || column.unit === "%") {
                  return value;
                } else {
                  return convertToHigherUnit(value, column.unit);
                }
              },
            });
          });
          if (res.data[0].order) {
            columns = columns.sort((a, b) => {
              return a.order - b.order;
            });
          }
        }

        columns = [
          {
            field: "historyActions",
            type: "actions",
            flex: 0.1,
            minWidth: 100,
            hideable: false,
            getActions: (params) => [
              <Tooltip placement="right-start" title={"History"} disableInteractive>
                <GridActionsCellItem
                  icon={<HistoryIcon />}
                  label="History"
                  onClick={() => {
                    handleHistoryClick(params);
                  }}
                />
              </Tooltip>,
            ],
          },
          ...FIXED_COLUMNS,
          ...columns,
        ];
       
        setTrendState((draft) => {
          draft.gridColumns = columns ?? [];
          draft.apiKeys = Object.values(obj);
        });
      })
      .then(() => {
        getLatestTrendsState(signal);
      })
      .catch(() => {
        setTrendState((draft) => {
          draft.gridColumns = FIXED_COLUMNS;
        });
        getLatestTrendsState(signal);
      });
  };
  const getHeaderAndLatestTelemetry = (signal) => {
    dynamicColumns(signal);
  };
  const loadHistoryOfSelectedData = (signal) => {
    const params = {
      device_ids: filters.data.map(ele => ele.deviceId).join(","),
      from_ts: filters.startTs,
      to_ts: filters.endTs,
      limit: 100,
      offset: 0, 
    };

    console.log("getTelemetryHistory --> params : ", params);
    getTrendHistory(params, signal)
      .then(
        (res) => { 
          console.log("getTelemetryHistory --> response : ", res?.data);
          const _data = res?.data?.items ?? [];
          const _totalCount = _data.length;
          
          const _rowData = [];
          _data.forEach((row, index) => {
            row.values = {};
            row.features.forEach(column => {
              let key = column.key;
              if(row.device_type_label === "Track Circuit") {
                key = key + "_" +column.state
              }
              row[key] = column.value;
              row.values[key] = column.value;  //for graph
            });
            row.lastActivityTime = new Date(row.last_updated_on).getTime();
            row.ts = row.lastActivityTime;
            row.id = index;
            let nameCode = trimDeviceName(row.device_name).name;
            let zoneDivCode = trimDeviceName(row.device_name).zoneDiv; 
            row["NAME"] = nameCode;
            row["ZONE_DIV"] = zoneDivCode;
            row["LOC"] = zoneDivCode; 
            _rowData.push(row);
          });
          console.log("getTelemetryHistory --> _rowData : ", _rowData);
          //if (selectedTab === "Graph") return {data: {items: _rowData}};
          return { _rowData, _totalCount, data: {items: _rowData} };
        },
        (err) => {
          if (err.response) {
            console.log(err?.response?.data?.error?.message ?? "Something Went Wrong! Try again Later");
          }
        },
      )
      .then((res) => {
        //setLoading(false);
        const chartType = filters.fromTs !== null && filters.toTs !== null ? "fixed" : "live";
        setTrendState((draft) => {
          draft.historyData =  res?._rowData ?? [];
        });
        setGraphState((state) => {
          state.data = res ?? [];
          state.chart = chartType;
        });
        setRowCount(res?._totalCount ?? 0);
      });
  }

  //console.log("telemetry_state", trendState);

  return (
    <>
      <div style={{ position: "static" }} className="trend-view">

        <div className="telemetry-header">
          <div className="telemetry-filters flex flexWrap">
            <TrendFilters filters={filters} setFilters={setFilters} trendState={trendState}/>
          </div>
        </div>
        <>
          {(trendState.historyData ?? []).length > 0 && <div className="buttonGroup"> 
            <Button variant={`${selectedTab === 'Table' ? 'contained' : 'outlined'}`} onClick={() => setSelectedTab("Table")}>Table view</Button>
            <Button variant={`${selectedTab === 'Graph' ? 'contained' : 'outlined'}`} onClick={() => setSelectedTab("Graph")}>Graph View</Button>
          </div>}
          {selectedTab === "Graph" && (trendState.historyData ?? []).length > 0 &&  <>
            <div className={"result-container"}>
              <div className={"slider-vertical"}>
                
              </div>
              <div className={"graph-container"}>
                <div className={"section-graph"}>
                  <HistoryGraph
                    loading={false}
                    graphState={graphState}
                    columns={trendState.gridColumns}
                    deviceId={filters?.data?.[0]?.deviceId ?? ''}
                    visibleKeys={filters.key}
                  ></HistoryGraph>
                </div>

                <div className={"slider-days"}>
                  <Slider 
                    aria-label="Days" defaultValue={filters.days} getAriaValueText={(value) => `${value}`}  valueLabelDisplay="auto" 
                    shiftStep={1} step={1} min={10} max={50} marks={[...Array(41).keys()].map(item => ({value: item+10, label: item+10}))}
                    onChange={(e) => {
                      //console.log("slider event --> ", e);
                      setFilters(draft => {
                        draft.days = e.target.value;
                      });
                    }}    
                  />
                </div>
              </div>
            </div>
          </>
          }
          {selectedTab === "Table" && 
            <>
              <div className="telemetry-active-device flex fullHeight">
                {trendState.deviceTypeLabel && (
                  <>
                    <StackedLineChartIcon className="telemetry-active-device-icon" />
                    <Typography component="span" className="telemetry-active-device-text">
                      {filters.deviceIds.map(ele => ele.type).join(", ")} Trends (Cluster View)
                    </Typography>
                  </>
                )}
              </div>
              <div className="telemetryTableArea">
                <DataTable
                  tableInfo={{
                    id: `telemetry-${trendState.deviceType}-table`,
                    label: `Telemetry ${trendState.deviceType}`,
                    requireAuthSign: false,
                  }}
                  pageSize={pageSize}
                  pageSizeChange={(size) => {
                    setPageSize(size);
                  }}
                  loading={trendState.loader}
                  className="telemetryTable"
                  rows={trendState.historyData ?? trendState.data}
                  columns={trendState.gridColumns}
                  columnVisibilityModel={initialHiddenColumns}
                  rowColor={(params) => {
                    return params.row.testMode ? "testModeColor" : "";
                  }}
                />
              </div>
            </>
          }
          </>
      </div>
    </>
  );
};
export default Trend;
