import { useCallback, useEffect, useMemo, useState } from "react";
import { getUserIdList, getUsersWithAssets } from "./AssetTransferUtils";

const DEFAULT_STATE = {
  user1: null,
  user2: null,
  user1error: false,
  user2error: false,
  type: null, // Transfer | Reclaim
  modalOpen: false,
};

export function useAssetTransferSelector() {
  const [selectedUsersState, setSelectedUsersState] = useState(DEFAULT_STATE);
  const actionState = useMemo(() => selectedUsersState, [selectedUsersState]);
  const [usersList, setUsersList] = useState();
  const usersIdList = useMemo(() => getUserIdList(usersList), [usersList]);

  useEffect(() => {
    getUsersWithAssets().then((res) => setUsersList(res));
  }, []);

  const handleModalClose = useCallback(() => {
    setSelectedUsersState((prev) => ({ ...prev, modalOpen: false }));
  }, []);

  const handleModalToggle = useCallback(
    (type) => {
      if (actionState.user1 && actionState.user2) {
        setSelectedUsersState((prev) => ({ ...prev, type: type, modalOpen: true }));
      } else {
        const user1error = actionState.user1 ? false : true;
        const user2error = actionState.user2 ? false : true;
        setSelectedUsersState((prev) => ({ ...prev, user1error: user1error, user2error: user2error }));
      }
    },
    [actionState.user1, actionState.user2],
  );

  const setUserValue = useCallback((user, value) => {
    setSelectedUsersState((prev) => ({ ...prev, [user]: value, [`${user}error`]: false }));
  }, []);

  return useMemo(
    () => ({ actionState, handleModalClose, handleModalToggle, setUserValue, usersList, usersIdList }),
    [actionState, handleModalClose, handleModalToggle, setUserValue, usersIdList, usersList],
  );
}
