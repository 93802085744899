import { trimAssetName } from "../../../Common/EndPoints";
import { DATE_VALIDATION_GROUPS } from "./Constants";

export function getDurationInMinutes(startTimestamp, endTimestamp) {
    const startDate = new Date(startTimestamp);
    const endDate = new Date(endTimestamp);

    // Calculate the difference in milliseconds between the two timestamps
    const durationInMilliseconds = endDate - startDate;

    // Convert milliseconds to minutes
    const durationInMinutes = Math.floor(durationInMilliseconds / 60000);

    return durationInMinutes;
}

export function getMinMaxRangeOfDate(dateStateType, dateState) {
    let dateStateArray = DATE_VALIDATION_GROUPS[dateStateType];
    const index = dateStateArray.indexOf(dateState);

    if (index === -1) {
        return [dateStateArray, []]; // Return the original dateStateArray and an empty dateStateArray if the dateState is not found
    }

    const firstArray = dateStateArray.slice(0, index);
    const secondArray = dateStateArray.slice(index + 1);

    return [firstArray, secondArray];
}

function excludeKeys(object, keysToExclude) {
    const result = {};

    for (let key in object) {
        if (!keysToExclude.includes(key)) {
            result[key] = object[key];
        }
    }

    return result;
}

export const getDesiredFormat = (response) => {
    let RESPONSE = { ...response };
    let responseOuterBody = {
        customerId: RESPONSE?.customerId?.id ?? "",
        deviceId: RESPONSE?.deviceId?.id ?? '',
        division: RESPONSE?.division?.id ?? '',
        dtCreated: new Date().getTime(),
        failureEntryNo: RESPONSE?.failureEntryNo ?? '',
        failureOn: RESPONSE?.failureOn ?? '',
        //   genericAttachmentId: "string",
        make: RESPONSE?.make ?? '',
        model: RESPONSE?.model ?? '',
        // name: RESPONSE.deviceId.value,
        // repairedBy: '73965d62-de33-4299-8021-4a22505486b9',
        repairedBy: RESPONSE?.repairedBy?.id ?? '',
        repairedOn: RESPONSE?.repairedOn ?? '',
        rfid: RESPONSE?.rfid ?? '',
        serialNumber: RESPONSE?.serialNumber ?? '',
        status: RESPONSE?.status?.id ?? '',
        // stncode: RESPONSE.customerId.value,
        zone: response?.zone?.value ?? '',
    };

    let responseInnerBody = excludeKeys(RESPONSE, ['id', ...Object.keys(responseOuterBody)]);

    responseInnerBody.causeOfFailure = responseInnerBody?.causeOfFailure?.value ?? '';
    responseInnerBody.department = responseInnerBody?.department?.value ?? '';
    responseInnerBody.disconnectionMemoIssued = responseInnerBody?.disconnectionMemoIssued?.value ?? '';
    responseInnerBody.relayRoomOpened = responseInnerBody?.relayRoomOpened?.value ?? '';
    responseInnerBody.rectificationBy = RESPONSE?.repairedBy ?? '';
    responseInnerBody.detainedTrains = responseInnerBody?.detainedTrains.map((trains) => {
        return {
            ...trains,
            trainNumber: trains?.trainNumber?.value,
        };
    });

    return { ...responseOuterBody, data: JSON.stringify(responseInnerBody) };
};

export const formatDataForFailureForm = (failureData) => {
    let FAILURE_DATA = { ...failureData };
    let keysFromOuterResponse = {
        ...FAILURE_DATA,
        customerId: { id: FAILURE_DATA.customerId, value: FAILURE_DATA.stnName },
        deviceId: { id: FAILURE_DATA.deviceId, value: trimAssetName(FAILURE_DATA.deviceName) },
        status: { id: FAILURE_DATA.status, value: FAILURE_DATA.status },
        zone: { id: FAILURE_DATA.zone, value: FAILURE_DATA.zone },
        division: { id: FAILURE_DATA.division, value: FAILURE_DATA.division },
    };
    const _innerData = JSON.parse(FAILURE_DATA.data);
    console.log(_innerData);

    let keysFromInnerResponse = {
        ..._innerData,
        department: { id: _innerData.department, value: _innerData.department },
        causeOfFailure: { id: _innerData.causeOfFailure, value: _innerData.causeOfFailure },
        disconnectionMemoIssued: { id: _innerData.disconnectionMemoIssued, value: _innerData.disconnectionMemoIssued },
        relayRoomOpened: { id: _innerData.relayRoomOpened, value: _innerData.relayRoomOpened },
        detainedTrains: _innerData.detainedTrains.map((data) => {
            return {
                ...data,
                trainNumber: { id: data.trainNumber, value: data.trainNumber },
            };
        }),
        repairedBy: _innerData.rectificationBy,
    };
    let finalFormData = { ...keysFromOuterResponse, ...keysFromInnerResponse };
    let notRequiredKeys = ["data", "genericAttachmentId", "dtCreated", "deviceName", "stnName"];
    finalFormData = excludeKeys(finalFormData, notRequiredKeys);
    return finalFormData;
};

export function convertToSentenceCase(text) {
    // Convert the text to lowercase and split it into an array of words
    const words = text.toLowerCase().split(' ');

    // Capitalize the first letter of each word
    const sentenceCaseWords = words.map(word => {
        // Capitalize the first letter of the word
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord;
    });

    // Join the words back into a sentence
    const sentenceCaseText = sentenceCaseWords.join(' ');

    return sentenceCaseText;
}
